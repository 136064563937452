import { Injectable } from '@angular/core'
import { AuthService } from '@cryptr/cryptr-angular'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class CryptrAuthenticationService {
  authenticated: Subject<string>

  constructor(private authService: AuthService) {
    this.authenticated = new Subject()
    this.authenticated.next('unknown')

    this.authService
      .authenticationInProgress()
      .subscribe(async (isInProgress) => {
        if (isInProgress == true) {
          // WAIT
          this.authenticated.next('unknown')
        } else {
          let isAuthenticated = await this.authService.isAuthenticated()
          if (isAuthenticated) this.authenticated.next('logged_in')
          else this.authenticated.next('logged_out')
        }
      })
  }

  getAuthService() {
    return this.authService
  }

  async checkCryptrAuth(self, next) {
    let hasCheckedCryptrAuth = false

    return this.authService
      .authenticationInProgress()
      .subscribe(async (isInProgress) => {
        if (isInProgress == true) {
          // WAIT
          console.log('Cryptr auth in progress')
        } else {
          if (hasCheckedCryptrAuth == false) {
            hasCheckedCryptrAuth = true
            console.log('Cryptr auth done')
            let isAuthenticated = await this.authService.isAuthenticated()
            await next(self, isAuthenticated)
          }
        }
      })
  }

  authenticationInProgress() {
    return this.authService.authenticationInProgress()
  }

  isAuthenticated() {
    return this.authenticated
  }

  logout(next) {
    this.authService.logOut(next)
  }
}
