<div class="text-left">
  <div
    style="
      padding-top: 15px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
    "
  >
    <div>
      <form [formGroup]="themeSwitcherForm">
        <input
          type="checkbox"
          class="theme-switcher"
          name="isDark"
          formControlName="isDark"
          (change)="refreshTheme()"
        />
      </form>
    </div>
  </div>
</div>
