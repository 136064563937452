import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersDealsAPIService {
  constructor(private http: HttpClient) {}

  async getDeals(publisherId) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/deals/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getDeal(publisherId, dealId) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/deals/' + dealId

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async postDeal(publisherId, dealJson) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/deals/'

    var res = await this.http
      .post<any>(url, dealJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateDeal(publisherId, dealId, dealJson) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/deals/' + dealId

    var res = await this.http
      .post<any>(url, dealJson, { observe: 'response' })
      .toPromise()
    return res
  }
}
