import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class AdvertisersTasksAPIService {
  constructor(private http: HttpClient) {}

  async getTasks(workspaceId) {
    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/tasks/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async postTask(workspaceId, taskJson) {
    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/tasks/'

    var res = await this.http
      .post<any>(url, taskJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateTask(workspaceId, taskId, taskJson) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/tasks/' + taskId

    var res = await this.http
      .post<any>(url, taskJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteTask(workspaceId, taskId) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/tasks/' + taskId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async postComment(workspaceId, taskId, commentJson) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/tasks/' +
      taskId +
      '/comments/'

    var res = await this.http
      .post<any>(url, commentJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateComment(workspaceId, taskId, commentId, commentJson) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/tasks/' +
      taskId +
      '/comments/' +
      commentId

    var res = await this.http
      .post<any>(url, commentJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteComment(workspaceId, taskId, commentId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/tasks/' +
      taskId +
      '/comments/' +
      commentId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
