<div class="right-row">
    <div class="row no-margin">
        <div class="col-md-6 col-12 no-padding">
            <div class="text-bold" style="color: #FFFFFF; background-color: #ffad00; padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;" *ngIf="title != undefined">{{title}}</div>
        </div>
        <div class="col-md-6 col-12" style="margin: auto">
            <div style="margin-right: 0px; display: flex; flex-direction: row-reverse"  *ngIf="user != undefined">
                <div style="display: flex; flex-direction: column; margin-right: 8px; padding-left: 30px; border-left-color: #666666; border-left-style: solid; border-left-width: 1px;">
                    <img class="cursor" [src]="user?.infos?.profilePictureURL" style="height: 40px; width: 40px; border-radius: 20px; margin-top: auto; margin-bottom: auto; object-fit: cover"
                        (click)="toggleUserMenu()">
                    <div class="row text-lato text-bold submodal-down" style="position: absolute; margin-top: 60px; z-index: 10; margin-left: -180px; width: 250px"
                        *ngIf="showMenu == true">
                        <div class="col-12 submenu">
                            <div (click)="goToProfile()">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-settings.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small cursor" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        {{ 'NAVBAR.OPTION_PROFILE' | translate }}</div>
                            </div>

                            <!--div (click)="showThing('settings')">
                                    <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                        <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-wheel.png"
                                                style="width: 20px"></span>
                                    </div>
                                    <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        Paramètres</div>
                                </div-->

                            <!--div (click)="showThing('workspaces')" *ngIf="isFull == true">
                                    <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                        <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-wheel.png"
                                                style="width: 20px"></span>
                                    </div>
                                    <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        Mes Workspaces</div>
                                </div>
                            <div (click)="showThing('manage-members')" *ngIf="isFull == true">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-wheel.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                    Gérer les membres</div>
                            </div>

                            <div (click)="showThing('invite-members')" *ngIf="isFull == true">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-wheel.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                    Inviter des personnes</div>
                            </div-->
                            <!--div (click)="showThing('billing')">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-cart.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                    Facturation</div>
                            </div-->
                            <!--div (click)="showThing('support')">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-help-white.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                    FAQ / Support</div>
                            </div-->
                            <div (click)="showThing('logout')">
                                <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                    <span class="text-medium text-bold" style="color: #000000"><img src="./assets/images/icon-logout.png"
                                            style="width: 20px"></span>
                                </div>
                                <div class="text-lato text-small cursor" style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        {{ 'NAVBAR.OPTION_LOGOUT' | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; margin-right: 8px; padding-left: 30px; border-left-color: #666666; border-left-style: solid; border-left-width: 1px;">
                    <h3 class="text-lato text-bold text-small no-margin-bottom" style="color: white; text-transform: uppercase; text-align: right">{{user?.infos?.fullName}}</h3>
                    <p class="text-lato text-small no-margin-bottom" style="color: #999999; text-align: right">{{user?.infos?.email}}</p>
                </div>
                <!--div style="display: flex; flex-direction: row; margin: auto; margin-right: 8px; ">
                    <div style="position: relative">
                        <img style="width: 28px; height: 28px; margin-right: 24px" src="./assets/images/icon-bubble.png">
                        <span style="position: absolute; top: -10px; right: -3px; height: 18px; width: 18px; margin-left: 8px; margin-right: 8px; padding-left: 2.5px; padding-right: 2.5px; padding-top: 1.5px; padding-bottom: 1.5px; color: #ffad00; font-size: 9px; border-radius: 9px; border-color: #ffad00; border-width: 1px; border-style: solid">27</span>
                    </div>
                    <div style="position: relative">
                        <img style="width: 28px; height: 28px; margin-right: 24px" src="./assets/images/icon-clock.png">
                        <span style="position: absolute; top: -10px; right: -3px; height: 18px; width: 18px; margin-left: 8px; margin-right: 8px; padding-left: 2.5px; padding-right: 2.5px; padding-top: 1.5px; padding-bottom: 1.5px; color: #ffad00; font-size: 9px; border-radius: 9px; border-color: #ffad00; border-width: 1px; border-style: solid">10</span>
                    </div>

                </div-->
            </div>
        </div>
    </div>
</div>