import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { ModalService } from '../../../../../../_services/modal.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  selector: 'campaign-create-step-1',
  templateUrl: 'campaign-create-step-1.component.html',
  styleUrls: ['./campaign-create-step-1.component.scss'],
})
export class CampaignCreateStep1Component implements OnInit {
  newCampaignForm: FormGroup

  keywords: any[]
  locations: any[]

  countries: any
  states: any
  statesToIterate: any

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {
    this.keywords = this.campaignCreationBufferService.campaignBeingCreated().keywords
    this.locations = this.campaignCreationBufferService.campaignBeingCreated().locations
    this.countries = this.getCountries()
    this.statesToIterate = []
  }

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({
      keyword: [''],
      country: [''],
      area: [''],
    })

    this.countries = this.getCountries()
    this.states = this.campaignReferentialService.getStates()
  }

  addLocation() {
    var candidate_country = this.newCampaignForm.controls.country.value
    var candidate_area = this.newCampaignForm.controls.area.value

    if (candidate_country) {
      if (candidate_area) {
      } else {
        candidate_area = 0
      }
      var toPush = {
        country: this.countries[candidate_country].name,
        state: this.states[candidate_area].name,
      }

      if (this.indexOfLocation(toPush) === -1) {
        this.locations.push(toPush)
      }
    }
  }

  indexOfLocation(location) {
    var index = -1
    for (var i = 0; i < this.locations.length; i++) {
      if (
        this.locations[i].country === location.country &&
        this.locations[i].state === location.state
      ) {
        index = i
        break
      }
    }
    return index
  }

  removeLocation(location) {
    var i = this.locations.indexOf(location)
    this.locations.splice(i, 1)
  }

  addKeyword() {
    //if (this.newPipeForm.valid) {
    var candidate = this.newCampaignForm.controls.keyword.value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.keywords.indexOf(candidate) === -1) {
          this.keywords.push(candidate)
          this.newCampaignForm.controls['keyword'].reset()
        }
      }
      //}
    }
  }

  removeKeyword(keyword) {
    var i = this.keywords.indexOf(keyword)
    this.keywords.splice(i, 1)
  }

  getCountries() {
    return this.campaignReferentialService.getCountries()
  }

  /*async getStates() {
    if (this.newCampaignForm.controls.country.value === '') return []

    var statesList = (await this.campaignReferentialService.getStates()).filter(
      (state) => {
        return state.country.id == this.newCampaignForm.controls.country.value
      }
    )
    return statesList
  }*/

  async getStates() {
    if (this.newCampaignForm.controls.country.value === '') return

    let states = await this.campaignReferentialService.getStates()
    var statesList = states.filter((state) => {
      return state.country.id == this.newCampaignForm.controls.country.value
    })

    this.statesToIterate = statesList
  }

  resetStates() {
    //this.getStates()
  }

  /*importLocations() {
        this.locations = []
        for (var i = 0; i < this.project.brand.products[0].locations.length; i++) {
            this.locations.push({
                country: this.project.brand.products[0].locations[i].country,
                state: this.project.brand.products[0].locations[i].area,
            })
        }
    }*/

  onSubmitProject() {
    this.addKeyword()
    this.addLocation()

    this.campaignCreationBufferService.campaignBeingCreated().locations = this.locations
    this.campaignCreationBufferService.campaignBeingCreated().keywords = this.keywords

    this.campaignCreationBufferService.moveForward()
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }
}
