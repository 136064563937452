import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { APIService, PublishersProfileAPIService } from 'src/app/_services'
import { DateHelper } from '../../../../../_helpers/date.helpers'

@Component({
  templateUrl:
    'publisher-profile-professional-profile-certifications.component.html',
  selector: 'publisher-profile-professional-profile-certifications',
  styleUrls: [
    './publisher-profile-professional-profile-certifications.component.scss',
  ],
})
export class PublisherProfileProfessionalProfileCertificationsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>() // This emits an event true if we need parent components to display modal overlay and an event false if we need to remove this overlay

  certificationForm: FormGroup // This form has to manage new certification and certification update
  isValid: boolean // This boolean says is certification is valid or not
  loading = false
  showModal: boolean
  showAskConfirmation: boolean

  certifications: any[]
  certification: any
  certificationId: any
  selectedData: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initCertificationForm()
    this.initValues()
    this.showAskConfirmation = false
  }

  initCertificationForm() {
    this.certificationForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      authorityForm: this.formBuilder.group({
        authority: '',
      }),
      startDate: [undefined],
      endDate: [undefined],
      licenseNumberForm: this.formBuilder.group({
        licenseNumber: '',
      }),
    })
  }

  initValues() {
    this.getCertifications()
  }

  ngOnDestroy() {}

  hasCertifications() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.certifications !=
        undefined
      )
    } catch (e) {
      return false
    }
  }

  getCertifications() {
    if (this.hasCertifications() == true)
      return this.publisher.publisher.professionalProfile.certifications
    else return undefined
  }

  getNumberOfCertifications() {
    let objects = this.getCertifications()
    if (objects) return objects.length
    return 0
  }

  getStartDate(certification) {
    let startDate = DateHelper.convertDoubleToReadableDate(
      certification.timePeriod.startDate
    )
    return startDate
  }

  getEndDate(certification) {
    let endDate = DateHelper.convertDoubleToReadableDate(
      certification.timePeriod.endDate
    )
    return endDate
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.certificationForm.controls.titleForm['controls'].title.value
      )

      let authorityField = this.readTextValue(
        this.certificationForm.controls.authorityForm['controls'].authority
          .value
      )

      let startDateField
      if (this.certificationForm.controls.startDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('Start date field can not be empty')
      } else {
        startDateField = new Date(
          this.certificationForm.controls.startDate.value
        ).getTime()
      }

      let endDateField
      if (this.certificationForm.controls.endDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('End date field can not be empty')
      } else {
        endDateField = new Date(
          this.certificationForm.controls.endDate.value
        ).getTime()
      }

      let licenseNumberField = this.readTextValue(
        this.certificationForm.controls.licenseNumberForm['controls']
          .licenseNumber.value
      )

      if (
        titleField != undefined &&
        this.certificationForm.controls.titleForm['controls'].title.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        authorityField != undefined &&
        this.certificationForm.controls.authorityForm['controls'].authority
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        licenseNumberField != undefined &&
        this.certificationForm.controls.licenseNumberForm['controls']
          .licenseNumber.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let certifications_infos = {
          name: titleField,
          authority: authorityField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          licenseNumber: licenseNumberField,
        }

        let res = await this.apiService.addCertificationInformation(
          this.publisher.publisher._id,
          certifications_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.loading = false
          this.successfullyAdded = true
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteCertification(certification) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      this.certificationId = certification._id

      let res = await this.apiService.deleteCertificationInformation(
        this.publisher.publisher._id,
        this.certificationId
      )

      if (res.status == 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyRemoved = true
        this.loading = false
        this.closeModal()
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async updateChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.certificationForm.controls.titleForm['controls'].title.value
      )

      let authorityField = this.readTextValue(
        this.certificationForm.controls.authorityForm['controls'].authority
          .value
      )

      let startDateField = new Date(
        this.certificationForm.controls.startDate.value
      ).getTime()

      let endDateField = new Date(
        this.certificationForm.controls.endDate.value
      ).getTime()

      let licenseNumberField = this.readTextValue(
        this.certificationForm.controls.licenseNumberForm['controls']
          .licenseNumber.value
      )

      if (titleField == '') {
        titleField = this.selectedData.name
      }

      if (authorityField == '') {
        authorityField = this.selectedData.authority
      }

      if (licenseNumberField == '') {
        licenseNumberField = this.selectedData.licenseNumber
      }

      if (this.isValid == true) {
        this.isValid = true

        let certifications_infos = {
          name: titleField,
          authority: authorityField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          licenseNumber: licenseNumberField,
        }

        let res = await this.apiService.updateCertificationInformation(
          this.publisher.publisher._id,
          this.selectedData._id,
          certifications_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addCertification() {
    this.selectedData = undefined
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateCertification(certificaton) {
    this.selectedData = certificaton
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  saveOrUpdateCertification() {
    if (this.selectedData != undefined) this.updateChanges()
    else this.saveChanges()
    this.closeModal()
  }

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  closeConfirmationModal() {
    try {
      this.showAskConfirmation = false
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  changeStartDate(event) {
    this.certificationForm.controls.startDate.setValue(event.target.value)
  }

  changeEndDate(event) {
    this.certificationForm.controls.endDate.setValue(event.target.value)
  }

  updateModal() {
    if (this.selectedData == undefined) {
      // ADD
      this.prepareModalForAddition()
    } else {
      // UPDATE
      this.prepareModalForUpdate()
    }
  }

  confirmation() {
    this.showAskConfirmation = true
    document.body.style.overflow = 'hidden'
  }

  prepareModalForAddition() {
    this.initCertificationForm()
  }

  prepareModalForUpdate() {
    this.certificationForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: this.selectedData.name,
      }),
      authorityForm: this.formBuilder.group({
        authority: this.selectedData.authority,
      }),
      startDate: [new Date(this.selectedData.timePeriod.startDate)],
      endDate: [new Date(this.selectedData.timePeriod.endDate)],
      licenseNumberForm: this.formBuilder.group({
        licenseNumber: this.selectedData.licenseNumber,
      }),
    })
  }

  clearInputField() {
    this.initCertificationForm()
  }
}
