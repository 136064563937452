<div id="anchor6">
  <p class="text-lato text-bold text-darkgrey font-title summary-title">
    {{ 'CAMPAIGN.CREATE.STEP_6_TITLE' | translate }}
  </p>
  <div class="text-left" id="summary-container">
    <p>
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_1' | translate }}
      <span
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().id == undefined
        "
        >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_1_CREATE' | translate }}</span
      ><span
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().id != undefined
        "
        >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_1_UPDATE' | translate }}</span
      >
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_2' | translate }}
      <span class="text-pink">{{
        campaignCreationBufferService.campaignBeingCreated().title
      }}</span>
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_3' | translate }}
      <span class="text-pink">{{
        campaignCreationBufferService.getProject()?.title
      }}</span
      >.
      <br />
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_4' | translate }}
      <span class="text-pink"
        >{{
          getDate(
            campaignCreationBufferService.campaignBeingCreated().date_start
          )
        }}
      </span>
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_5' | translate }}
      <span class="text-pink">{{
        getDate(campaignCreationBufferService.campaignBeingCreated().date_end)
      }}</span
      >.
      <br />
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_6' | translate }} :<span
        *ngFor="
          let location of campaignCreationBufferService.campaignBeingCreated()
            .locations;
          let i = index
        "
        class="text-pink"
      >
        {{ location.country | translate }}, {{ location.state | translate
        }}<span
          *ngIf="
            i <
            campaignCreationBufferService.campaignBeingCreated().locations
              .length -
              1
          "
        >
          -
        </span></span
      >
      <br />
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_7' | translate }}
      <span
        *ngFor="
          let keyword of campaignCreationBufferService.campaignBeingCreated()
            .keywords;
          let i = index
        "
        class="text-pink"
      >
        {{ keyword
        }}<span
          *ngIf="
            i <
            campaignCreationBufferService.campaignBeingCreated().keywords
              .length -
              1
          "
          >,</span
        ></span
      >.
      <br />
      <span
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().compensation ===
          'cash'
        "
        >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_8' | translate }}
        <span class="text-pink"
          >{{ campaignCreationBufferService.campaignBeingCreated().budget }}
          {{ preferencesManager.getPreferredCurrency().symbol }}</span
        >.</span
      >
      <span
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().compensation ===
          'free_products'
        "
        >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_8_PRODUCTS_1' | translate
        }}<span class="text-pink"
          >{{
            campaignCreationBufferService.campaignBeingCreated()
              .numberOfFreeProducts
          }} </span
        >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_8_PRODUCTS_2' | translate }}
        <br />{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_8_PRODUCTS_3' | translate
        }}<br /><span class="text-pink text-html text-input">{{
          campaignCreationBufferService.campaignBeingCreated()
            .productDescription
        }}</span>
      </span>
      <br />
      {{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_9' | translate }}
      <span class="text-pink"
        >{{
          campaignCreationBufferService.campaignBeingCreated().type.text
            | translate
        }}
      </span>
    </p>

    <p>
      <b
        ><span class="text-pink"
          >{{ 'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_12' | translate }}
        </span></b
      ><br />
      <!--quill-view
        [content]="campaignCreationBufferService.campaignBeingCreated().brief"
        format="html"
        theme="snow"
        [sanitize]="true"
      ></quill-view-->
    </p>
    <div class="ql-container ql-snow" style="border-width: 0">
      <div
        class="ql-editor"
        [innerHTML]="campaignCreationBufferService.campaignBeingCreated().brief"
      ></div>
    </div>
    <p>
      <b
        ><span class="text-pink">{{
          'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_13' | translate
        }}</span> </b
      ><span
        *ngFor="
          let do_i of campaignCreationBufferService.campaignBeingCreated().do
        "
        ><br />&nbsp;- {{ do_i }}</span
      >
    </p>
    <p>
      <b
        ><span class="text-pink">{{
          'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_14' | translate
        }}</span> </b
      ><span
        *ngFor="
          let dont_i of campaignCreationBufferService.campaignBeingCreated()
            .dont
        "
        ><br />&nbsp;- {{ dont_i }}</span
      >
    </p>
    <p>
      <b
        ><span class="text-pink">{{
          'CAMPAIGN.CREATE.STEP_6_INSTRUCTIONS_15' | translate
        }}</span></b
      >
    </p>
    <div class="row v-align moodboard-container">
      <div class="card-columns">
        <div
          *ngFor="
            let image of campaignCreationBufferService.getMoodboardSourcePaths()
          "
          class="card show-img no-border"
        >
          <img
            [src]="image.img"
            class="card-img-top"
            *ngIf="image.img != undefined"
          />
          <img
            [src]="image.imgPath"
            class="card-img-top"
            *ngIf="image.imgPath != undefined"
          />
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="newCampaignForm" (ngSubmit)="onSubmitProject()">
    <div class="row">
      <div class="col-12">
        <input
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.STEP_6_ACTION_CREATE' | translate }}"
          *ngIf="
            campaignCreationBufferService.campaignBeingCreated().id == undefined
          "
        />
        <input
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.STEP_6_ACTION_UPDATE' | translate }}"
          *ngIf="
            campaignCreationBufferService.campaignBeingCreated().id != undefined
          "
        />
      </div>
    </div>
  </form>
</div>
