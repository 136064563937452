import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { Opportunity } from '../../_models/opportunity/opportunity.model'
import { PublishersOpportunitiesAPIService } from '../../_services/api/publishers.opportunities.api.service'

@Injectable()
export class PublishersOpportunityResolver implements Resolve<Opportunity> {
  constructor(
    private apiService: APIService,
    private opportunitiesApiService: PublishersOpportunitiesAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<Opportunity> {
    try {
      let user = await this.apiService.getMyProfile()
      let publisherId = user?.body?.publisher?._id
      const opportunityId = route.params['opportunityId']
      let request = await this.opportunitiesApiService.getOpportunity(
        publisherId,
        opportunityId
      )
      if (request.body != undefined && request.body.opportunity != undefined)
        return request.body.opportunity
      return undefined
    } catch (e) {
      return undefined
    }
  }
}
