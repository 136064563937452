import { Injectable } from '@angular/core'
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import {
  AuthService,
  AuthGuard as CryptrAuthGuard,
} from '@cryptr/cryptr-angular'

import { TokenService } from './../_services/token.service'

import { AuthenticationService } from './../_services/authentication/authentication.service'
import { CryptrAuthenticationService } from './../_services/authentication/cryptr.authentication.service'
import { combineLatest, filter, map } from 'rxjs/operators'
import { from, Observable } from 'rxjs'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private cryptrAuthenticationService: CryptrAuthenticationService
  ) {}

  // CHECK IF WE WANT TO KEEP THE QUERY PARAM RETURN URL IN THIS CASE
  // IF LOGGED IN AND ASKS FOR A ROUTE WHERE LOGIN IS REQUIRED, EVERYTHING IS FINE
  // IF LOGGED IN AND ASKS FOR A ROUTE WHERE LOGIN IS NOT REQUIRRED (TYPICALLY LOGIN OR SIGN UP, LOAD THE HOME PAGE - USER ALREADY LOGGED IN)
  // IF NOT LOGGED IN AND ASKS FOR A ROUTE WHERE LOGIN IS REQUIRED, GO TO LOG IN PAGE
  // IF NOT LOGGED IN AND ASKS FOR A ROUTE WHARE LOGIN IS NOT REQUIRED, LOAD THE ASKED PAGE
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const requiresLogin = route.data.requiresLogin || false

      if (requiresLogin) {
        if (this.authenticationService.guessAuthProvider() === 'fns') {
          return this.canActivateRouteWithLoginAndInternalAuthentication(
            state.url
          )
            .then((canActivate) => {
              return canActivate
            })
            .catch((error) => {
              console.log(error)
              return false
            })
        } else {
          return this.canActivateRouteWithLoginAndCryptrAuthentication(
            state.url
          )
          /*return new CryptrAuthGuard(
            this.cryptrAuthenticationService.getAuthService()
          )
            .canActivate(route, state)
            .toPromise()*/
        }
      } else {
        if (this.authenticationService.guessAuthProvider() === 'fns') {
          return from(
            this.canActivateRouteWithoutLoginAndInternalAuthentication(
              state.url,
              route.data.canGoIfLoggedIn
            )
              .then((canActivate) => {
                return canActivate
              })
              .catch((error) => {
                console.log(error)
                return false
              })
          )
        } else {
          return from(
            this.canActivateRouteWithoutLoginAndCryptrAuthentication(
              state.url,
              route.data.canGoIfLoggedIn
            )
          )
        }
      }
    } catch (e) {
      console.log(e)
      return false
    }
    //await this.tokenService.examineToken();
  }

  async canActivateRouteWithLoginAndInternalAuthentication(stateUrl) {
    if (localStorage.getItem('currentUser')) {
      var result = await this.tokenService.examineToken()
      return result
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: stateUrl },
    })

    return false
  }

  async canActivateRouteWithoutLoginAndInternalAuthentication(
    stateUrl,
    canGoIfLoggedIn
  ) {
    if (localStorage.getItem('currentUser')) {
      if (canGoIfLoggedIn == true) return true

      // logged in so go to Home page
      this.router.navigate(['/'], { queryParams: { returnUrl: stateUrl } })
      return false
    }
    return true
  }

  canActivateRouteWithLoginAndCryptrAuthentication(stateUrl) {
    return this.cryptrAuthenticationService
      .getAuthService()
      .fullAuthenticateProcess(stateUrl, (isAuthenticated, stateUrl) => {
        console.log(isAuthenticated)
        if (isAuthenticated == true) {
          // DO NOTHING
          return true
        } else {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: stateUrl },
          })

          return false
        }
      })
  }

  canActivateRouteWithoutLoginAndCryptrAuthentication(
    stateUrl,
    canGoIfLoggedIn
  ) {
    return this.cryptrAuthenticationService
      .getAuthService()
      .fullAuthenticateProcess(stateUrl, (isAuthenticated, stateUrl) => {
        console.log(isAuthenticated)
        if (isAuthenticated == true) {
          // DO NOTHING
          if (canGoIfLoggedIn == true) return true

          // logged in so go to Home page
          this.router.navigate(['/'], { queryParams: { returnUrl: stateUrl } })
          return false
        } else {
          return true
        }
      })
  }
}
