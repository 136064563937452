<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunities-title">
      <!--publisher-section-intro
        title="{{ 'OPPORTUNITIES' | translate }}"
        description="{{
          'Here is a list of current campaigns. Click on a campaign to have details about the underlying brand and its expectations.'
            | translate
        }}"
      ></publisher-section-intro-->
      <publisher-section-intro
        title="{{ 'KEYWORD_PLAN_EDIT.PAGE_TITLE' | translate }}"
        description=" "
      ></publisher-section-intro>
    </div>
    <div id="opportunities-wrappers">
      <div
        class="display-block col-direction section-container"
        style="justify-content: center"
      >
        <div class="one-edge-shadow section-container-inner max-w-80pt">
          <div class="row">
            <form [formGroup]="newAudienceForm" style="width: 100%">
              <div class="row">
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field">
                      <div class="input-title text-bold">
                        {{ 'KEYWORD_PLANS.PLAN_FORM_TITLE_TEXT' | translate }}
                      </div>
                      <div class="input-value">
                        <input-field
                          class="input-field"
                          type="text"
                          placeholder="{{
                            'KEYWORD_PLANS.PLAN_FORM_TITLE_PLACEHOLDER'
                              | translate
                          }}"
                          name="title"
                          relatedControlName="title"
                          formGroupName="titleForm"
                          [parentGroup]="newAudienceForm"
                        >
                        </input-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field">
                      <div class="input-title text-bold">
                        {{
                          'KEYWORD_PLANS.PLAN_FORM_SEO_KEYWORDS_TITLE'
                            | translate
                        }}
                      </div>
                      <div class="input-value">
                        <div style="display: flex; flex-direction: row">
                          <input-field
                            class="input-field"
                            type="text"
                            placeholder="{{
                              'KEYWORD_PLANS.PLAN_FORM_SEO_KEYWORD_PLACEHOLDER'
                                | translate
                            }}"
                            name="keyword"
                            relatedControlName="keyword"
                            formGroupName="keywordForm"
                            [parentGroup]="newAudienceForm"
                            style="width: calc(100% - 270px)"
                          >
                          </input-field>
                          <input
                            style="
                              width: 36px;
                              height: 36px;
                              margin-left: 10px;
                              margin-right: 0px;
                              padding: 0px;
                              font-size: 24px;
                              padding-bottom: 3px;
                            "
                            class="btn btn-primary btn-big text-lato text-bold"
                            (click)="addKeyword()"
                            value="+"
                            readonly="readonly"
                          />
                          <div class="separator">OR</div>
                          <input
                            style="display: none"
                            type="file"
                            (change)="changeSEOKeywordFileListener($event)"
                            accept=".csv, .txt"
                            #seoFileInput
                          />
                          <input
                            class="btn text-lato text-bold card-title no-margin"
                            style="
                              background-color: #ff006c;
                              color: white;
                              font-size: 13px;
                            "
                            value="{{
                              'KEYWORD_PLANS.PLAN_FORM_SEO_IMPORT_FILE'
                                | translate
                            }}"
                            (click)="seoFileInput.click()"
                            readonly="readonly"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div
                      *ngIf="keywords.length > 0"
                      style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                      "
                    >
                      <div *ngFor="let keyword of keywords">
                        <div style="display: flex; flex-direction: row">
                          <div
                            class="col-auto"
                            style="
                              white-space: nowrap;
                              border-radius: 4px;
                              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                              background-color: #f7f7f7;
                              padding-left: 18px;
                              padding-right: 18px;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              margin-left: 6px;
                              margin-right: 6px;
                              margin-top: 6px;
                              margin-bottom: 6px;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div
                                class="text-lato"
                                style="
                                  font-size: 15px;
                                  font-weight: bold;
                                  color: #30313e;
                                "
                              >
                                {{ keyword }}
                              </div>
                              <div>
                                <input
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 10px;
                                    margin-right: 0px;
                                    padding: 0px;
                                    font-size: 10px;
                                    padding-bottom: 2px;
                                  "
                                  class="btn btn-primary btn-big text-lato text-bold"
                                  value="x"
                                  (click)="removeKeyword(keyword)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field">
                      <div class="input-title text-bold">
                        {{
                          'KEYWORD_PLANS.PLAN_FORM_SOCIAL_KEYWORDS_TITLE'
                            | translate
                        }}
                      </div>
                      <div class="input-value">
                        <div style="display: flex; flex-direction: row">
                          <input-field
                            class="input-field"
                            type="text"
                            placeholder="{{
                              'KEYWORD_PLANS.PLAN_FORM_SOCIAL_KEYWORD_PLACEHOLDER'
                                | translate
                            }}"
                            name="socialKeyword"
                            relatedControlName="socialKeyword"
                            formGroupName="socialKeywordForm"
                            [parentGroup]="newAudienceForm"
                            style="width: calc(100% - 46px)"
                          >
                          </input-field>
                          <input
                            style="
                              width: 36px;
                              height: 36px;
                              margin-left: 10px;
                              margin-right: 0px;
                              padding: 0px;
                              font-size: 24px;
                              padding-bottom: 3px;
                            "
                            class="btn btn-primary btn-big text-lato text-bold"
                            (click)="addSocialKeyword()"
                            value="+"
                            readonly="readonly"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div
                      *ngIf="socialKeywords.length > 0"
                      style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                      "
                    >
                      <div *ngFor="let keyword of socialKeywords">
                        <div style="display: flex; flex-direction: row">
                          <div
                            class="col-auto"
                            style="
                              white-space: nowrap;
                              border-radius: 4px;
                              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                              background-color: #f7f7f7;
                              padding-left: 18px;
                              padding-right: 18px;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              margin-left: 6px;
                              margin-right: 6px;
                              margin-top: 6px;
                              margin-bottom: 6px;
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div
                                class="text-lato"
                                style="
                                  font-size: 15px;
                                  font-weight: bold;
                                  color: #30313e;
                                "
                              >
                                {{ keyword.text }}
                              </div>
                              <div>
                                <input
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 10px;
                                    margin-right: 0px;
                                    padding: 0px;
                                    font-size: 10px;
                                    padding-bottom: 2px;
                                  "
                                  class="btn btn-primary btn-big text-lato text-bold"
                                  value="x"
                                  (click)="removeSocialKeyword(keyword)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field">
                      <div class="input-title text-bold">
                        {{
                          'KEYWORD_PLANS.PLAN_FORM_SEO_LANGUAGE_TITLE'
                            | translate
                        }}
                      </div>
                      <div class="input-value">
                        <input
                          class="input-field autocomplete-field input-text"
                          type="text"
                          placeholder="{{
                            'KEYWORD_PLANS.PLAN_FORM_SEO_LANGUAGE_PLACEHOLDER'
                              | translate
                          }}"
                          name="language"
                          formControlName="language"
                          [matAutocomplete]="autocompleteLanguage"
                        />
                        <mat-autocomplete
                          #autocompleteLanguage="matAutocomplete"
                        >
                          <mat-option
                            *ngFor="
                              let language of filteredLanguagesOptions | async
                            "
                            [value]="language"
                          >
                            {{ language }}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field">
                      <div class="input-title text-bold">
                        {{
                          'KEYWORD_PLANS.PLAN_FORM_SEO_LOCATION_TITLE'
                            | translate
                        }}
                      </div>
                      <div class="input-value">
                        <input
                          class="input-field autocomplete-field input-text"
                          type="text"
                          placeholder="{{
                            'KEYWORD_PLANS.PLAN_FORM_SEO_LOCATION_PLACEHOLDER'
                              | translate
                          }}"
                          name="location"
                          formControlName="location"
                          [matAutocomplete]="autocompleteLocation"
                        />
                        <mat-autocomplete
                          #autocompleteLocation="matAutocomplete"
                        >
                          <mat-option
                            *ngFor="
                              let location of filteredLocationsOptions | async
                            "
                            [value]="location"
                          >
                            {{ location }}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" style="margin-top: 31px">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field" style="padding-top: 0px">
                      <input
                        class="btn text-lato text-bold card-title no-margin"
                        style="
                          background-color: #ff006c;
                          color: white;
                          font-size: 13px;
                        "
                        type="submit"
                        (click)="updateAudience()"
                        value="{{
                          'KEYWORD_PLAN_EDIT.SAVE_BUTTON' | translate
                        }}"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12" *ngIf="showError">
                  <div class="section-body text-small text-darkgrey">
                    <div class="container-field" style="padding-top: 0px">
                      <div
                        class="alert text-lato text-pink"
                        style="padding-left: 0; padding-right: 0"
                      >
                        {{ errorText | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
