<div id="anchor2">
  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
    style="margin-top: 0px !important"
  >
    <div class="row">
      <div class="col-12">
        <p
          class="text-lato text-bold text-darkgrey font-title"
          style="margin-top: 14px; padding-bottom: 14px"
        >
          {{ 'CAMPAIGN.CREATE.STEP_2_TITLE_1' | translate }}
        </p>
      </div>
      <div class="col-md-6 col-12 center-mobile text-center">
        <mat-form-field class="input-text text-lato text-medium text-darkgrey">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="{{ 'CAMPAIGN.CREATE.STEP_2_START_DATE' | translate }}"
            name="date_start"
            formControlName="date_start"
            [min]="campaignCreationBufferService.getCampainMinimalLaunchDate()"
            (dateChange)="changeDateStart($event)"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6 col-12 center-mobile text-center">
        <mat-form-field class="input-text text-lato text-medium text-darkgrey">
          <input
            matInput
            [matDatepicker]="pickerEnd"
            placeholder="{{ 'CAMPAIGN.CREATE.STEP_2_END_DATE' | translate }}"
            name="date_end"
            formControlName="date_end"
            [min]="campaignCreationBufferService.getCampainMinimalFinishDate()"
            (dateChange)="changeDateEnd($event)"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <p class="text-lato text-bold text-darkgrey font-title" style="">
          {{ 'CAMPAIGN.CREATE.STEP_2_TITLE_2' | translate }}
        </p>
      </div>
      <ul class="nav select-menu">
        <li
          class="nav-item"
          (click)="selectCompensation('free_products')"
          [class.active]="
            campaignCreationBufferService.campaignBeingCreated()
              .compensation === 'free_products'
          "
        >
          <a class="nav-link text-lato">{{
            'COMMON.FREE_PRODUCTS_OR_ADVANTAGES' | translate
          }}</a>
        </li>
        <li
          class="nav-item"
          (click)="selectCompensation('cash')"
          [class.active]="
            campaignCreationBufferService.campaignBeingCreated()
              .compensation === 'cash'
          "
        >
          <a class="nav-link text-lato">{{
            'COMMON.SPONSORED_POSTS' | translate
          }}</a>
        </li>
      </ul>

      <div
        class="col-12"
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().compensation ===
          'cash'
        "
      >
        <div class="col-12">
          <input
            type="range"
            class="custom-range"
            id="budget"
            name="budget"
            formControlName="budget"
            [min]="getCampaignMinimalBudget()"
            [max]="getCampaignMaximalBudget()"
            [step]="getCampaignBudgetStep()"
            (change)="changeBudget($event)"
          />
        </div>
        <div
          class="row"
          style="width: 70%; margin-left: auto; margin-right: auto"
        >
          <div class="col-12" style="">
            <span style="float: left">{{ getCampaignMinimalBudget() }}</span>
            <span style="float: right">{{ getCampaignMaximalBudget() }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="row v-align">
            <div class="col-12">
              <label style="margin-top: 15px; margin-right: 20px">{{
                'CAMPAIGN.CREATE.STEP_2_BUDGET' | translate
              }}</label>
              <input
                style="width: 90px"
                type="number"
                class="custom-range"
                class="input-text text-lato text-medium"
                [min]="getCampaignMinimalBudget()"
                [max]="getCampaignMaximalBudget()"
                [step]="getCampaignBudgetStep()"
                formControlName="budgetField"
                (change)="changeBudgetField($event)"
                placeholder="{{ 'CAMPAIGN.CREATE.STEP_2_BUDGET' | translate }}"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12"
        *ngIf="
          campaignCreationBufferService.campaignBeingCreated().compensation ===
          'free_products'
        "
      >
        <div
          class="row"
          style="
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
          "
        >
          <div class="col-12">
            <label style="margin-top: 15px; margin-right: 20px">{{
              'CAMPAIGN.CREATE.STEP_2_NUMBER_OF_PRODUCTS' | translate
            }}</label>
            <input
              style="width: auto; text-align: right"
              type="number"
              class="custom-range"
              class="input-text text-lato text-medium"
              min="0"
              max="1000"
              formControlName="numberOfFreeProducts"
              (change)="updateNumberOfFreeProducts()"
            />
          </div>
        </div>
        <div
          class="row"
          style="
            margin-top: 10px;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
          "
        >
          <div class="col-12">
            <label style="margin-top: 15px; margin-right: 20px">{{
              'CAMPAIGN.CREATE.STEP_2_PRODUCTS_DESCRIPTION' | translate
            }}</label>
            <textarea
              id="productDescription"
              class="form-control input-text text-lato text-small text-darkgrey place-holder-brief"
              name="productDescription"
              placeholder="Description des produits"
              formControlName="productDescription"
              style="width: calc(100% - 46px); padding: 0px"
              rows="5"
              (change)="updateProductDescription()"
            >
            </textarea>
          </div>
        </div>
      </div>

      <div class="col-12">
        <input
          style="margin-top: 34px"
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
        />
      </div>
    </div>
  </form>
</div>
