import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { CalendarView, CalendarEvent, DAYS_OF_WEEK } from 'angular-calendar'
import { startOfDay } from 'date-fns'

import { DateHelper } from './../../../../_helpers/date.helpers'

let addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

@Component({
  templateUrl: 'dashboard-metrics.component.html',
  selector: 'dashboard-metrics',
  styleUrls: ['./dashboard-metrics.component.scss'],
})
export class DashboardMetricsComponent implements OnInit {
  viewDate: Date = new Date()
  view: CalendarView = CalendarView.Month
  CalendarView = CalendarView
  weekStartsOn = DAYS_OF_WEEK.MONDAY

  events: CalendarEvent[] = [
    {
      start: startOfDay(addDays(new Date(), 1)),
      title:
        '[Foxy Nerds] - Article "Why having a strong influencer agency partner is important"',
    },
    {
      start: startOfDay(addDays(new Date(), 10)),
      title: '[CallTime] - End of lead nurturing campaign',
    },
  ]

  constructor(private router: Router) {}

  async ngOnInit() {}

  ngOnDestroy() {}

  setView(view: CalendarView) {
    this.view = view
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    //this.openAppointmentList(date)
  }

  convertDoubleToReadableDate(date) {
    return DateHelper.convertDoubleToReadableDate(date)
  }
}
