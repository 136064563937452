import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { USER_ROLE } from './_helpers/referential/user.role'
import { AuthGuard, RoleGuard } from './_guards'

import { JourneyStartComponent } from './_components/private/journey_start'

import { AdvertiserDashboardComponent } from './_components/private/advertisers/dashboard'
import { AdvertiserOpportunitiesComponent } from './_components/private/advertisers/opportunities'
import { AdvertiserOpportunityEditComponent } from './_components/private/advertisers/opportunity-edit'
import { AdvertiserDealsComponent } from './_components/private/advertisers/deals'
import { AdvertiserCollaborationsComponent } from './_components/private/advertisers/collaborations'
import { AdvertiserMessagesComponent } from './_components/private/advertisers/messages'
import { AdvertiserWorkspacesComponent } from './_components/private/advertisers/workspaces'
import { AdvertiserWorkspaceSettingsComponent } from './_components/private/advertisers/workspace-settings'
import { AdvertiserBrandEditComponent } from './_components/private/advertisers/brand-edit'
import { AdvertiserBrandViewComponent } from './_components/private/advertisers/brand-view'
import { AdvertiserPublishersListComponent } from './_components/private/advertisers/publishers-list'
import { AdvertiserBillingComponent } from './_components/private/advertisers/billing'
import { AdvertiserAudiencesComponent } from './_components/private/advertisers/audiences'
import { AdvertiserAudienceComponent } from './_components/private/advertisers/audience'
import { AdvertiserAudienceEditComponent } from './_components/private/advertisers/audience-edit'
import { AdvertiserOpportunityInfosComponent } from './_components/private/advertisers/opportunity-infos'

import { ProjectCreateComponent } from './_components/private/projects/project_create'
import { ProjectSettingsComponent } from './_components/private/projects/project_settings'
import { ProjectSettingsEditComponent } from './_components/private/projects/project_settings_edit'
import { ProjectBrandAuthorityComponent } from './_components/private/projects/project_brand_authority'
import { ProjectBillingComponent } from './_components/private/projects/project_billing'
import { ProjectBillingEditComponent } from './_components/private/projects/project_billing_edit'

import { CampaignCreateComponent } from './_components/private/campaigns/campaign_create'
import { CampaignAnalysisComponent } from './_components/private/campaigns/campaign_analysis'
import { CampaignBriefComponent } from './_components/private/campaigns/campaign_brief'
import { CampaignPublicationsComponent } from './_components/private/campaigns/campaign_publications'
import { CampaignScalersComponent } from './_components/private/campaigns/campaign_scalers'
import { CampaignAllComponent } from './_components/private/campaigns/campaign_all'
import { CampaignPdfComponent } from './_components/private/campaigns/campaign_pdf'

import { ScalerProfileComponent } from './_components/private/scaler_profile'

import { UserResolver } from './_resolvers/user.resolver'
import { AdvertisersTasksResolver } from './_resolvers/advertisers/advertisers.tasks.resolver'
import { AdvertisersPublishersResolver } from './_resolvers/advertisers/advertisers.publishers.resolver'
import { AdvertisersRelationshipsResolver } from './_resolvers/advertisers/advertisers.relationships.resolver'
import { AdvertisersRelationshipRequestsResolver } from './_resolvers/advertisers/advertisers.requests.resolver'
import { AdvertisersConversationsResolver } from './_resolvers/advertisers/advertisers.conversations.resolver'
import { AdvertisersAudienceResolver } from './_resolvers/advertisers/advertisers.audience.resolver'
import { AdvertisersAudiencesResolver } from './_resolvers/advertisers/advertisers.audiences.resolver'
import { AdvertisersOffersResolver } from './_resolvers/advertisers/advertisers.offers.resolver'
import { AdvertisersOfferResolver } from './_resolvers/advertisers/advertisers.offer.resolver'

const BRANDS_ROUTES: Routes = [
  {
    path: 'journey/start',
    component: JourneyStartComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/create',
    component: ProjectCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces',
    component: AdvertiserWorkspacesComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/dashboard',
    component: AdvertiserDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/keywords/plans',
    component: AdvertiserAudiencesComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver, plans: AdvertisersAudiencesResolver },
  },
  {
    path: 'workspaces/:workspaceId/keywords/plans/:planId',
    component: AdvertiserAudienceComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: {
      user: UserResolver,
      plan: AdvertisersAudienceResolver,
    },
  },
  {
    path: 'workspaces/:workspaceId/keywords/plans/:planId/edit',
    component: AdvertiserAudienceEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: {
      user: UserResolver,
      plan: AdvertisersAudienceResolver,
    },
  },
  {
    path: 'workspaces/:workspaceId/campaigns',
    component: AdvertiserOpportunitiesComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver, campaigns: AdvertisersOffersResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/new',
    component: AdvertiserOpportunityEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      isNew: true,
    },
    resolve: { user: UserResolver, plans: AdvertisersAudiencesResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId',
    component: AdvertiserOpportunityInfosComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
    },
    resolve: { user: UserResolver, offer: AdvertisersOfferResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/edit',
    component: AdvertiserOpportunityEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      isNew: false,
    },
    resolve: {
      user: UserResolver,
      plans: AdvertisersAudiencesResolver,
      offer: AdvertisersOfferResolver,
    },
  },
  {
    path: 'workspaces/:workspaceId/deals',
    component: AdvertiserDealsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/collaborations',
    component: AdvertiserCollaborationsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver, taskData: AdvertisersTasksResolver },
  },
  {
    path: 'workspaces/:workspaceId/messages',
    component: AdvertiserMessagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: {
      user: UserResolver,
      conversations: AdvertisersConversationsResolver,
      relationships: AdvertisersRelationshipsResolver,
    },
  },
  {
    path: 'workspaces/:workspaceId/settings',
    component: AdvertiserWorkspaceSettingsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/settings/edit',
    component: ProjectSettingsEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/brand',
    component: AdvertiserBrandViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/brand/edit',
    component: AdvertiserBrandEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/authority',
    component: ProjectBrandAuthorityComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/billing',
    component: AdvertiserBillingComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      members: ['owner', 'admin'],
    },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/billing/edit',
    component: ProjectBillingEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      members: ['owner', 'admin'],
    },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaign/new',
    component: CampaignCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/brief',
    component: CampaignBriefComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/edit',
    component: CampaignCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/analytics',
    component: CampaignAnalysisComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/publications',
    component: CampaignPublicationsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/scalers',
    component: CampaignScalersComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/scalers/:scalerId',
    component: ScalerProfileComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/all',
    component: CampaignAllComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/campaigns/:campaignId/pdf',
    component: CampaignPdfComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'workspaces/:workspaceId/partners',
    component: AdvertiserPublishersListComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: {
      user: UserResolver,
      publishers: AdvertisersPublishersResolver,
      relationships: AdvertisersRelationshipsResolver,
      requests: AdvertisersRelationshipRequestsResolver,
    },
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(BRANDS_ROUTES, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppBrandsV2RoutingModule {}
