<div class="no-margin bg-advertiser-nav-bar">
  <alerts [alerts]="alertService.getAlerts()"></alerts>
  <div class="topnav">
    <!--img
      class="brand-logo clickable d-none d-lg-block"
      src="./assets/images/fns_logo_horizontal.png"
      (click)="loadDashboard()"
    /-->
    <img
      class="brand-logo-head d-block"
      src="./assets/images/fns_logo_head.png"
    />
    <!--a
      (click)="loadDashboard()"
      class="clickable"
      [ngClass]="{ 'tab-select': whatIsTheTab() === 'dashboard' }"
      >Dashboard</a
    -->
    <a
      (click)="loadAudiences()"
      class="clickable"
      [ngClass]="{ 'tab-select': whatIsTheTab() === 'plans' }"
      >{{ 'NAV.KEYWORD_PLANS' | translate }}</a
    >
    <a
      (click)="loadOpportunities()"
      class="clickable"
      [ngClass]="{ 'tab-select': whatIsTheTab() === 'campaigns' }"
      >{{ 'NAV.CAMPAIGNS' | translate }}</a
    >
    <!--a
      (click)="loadDeals()"
      class="clickable"
      [ngClass]="{ 'tab-select': whatIsTheTab() === 'deals' }"
      >Deals</a
    -->
    <a
      (click)="loadCollaborations()"
      class="clickable"
      [ngClass]="{ 'tab-select': whatIsTheTab() === 'planning' }"
      >{{ 'NAV.PLANNING' | translate }}</a
    >
    <div class="topnav-right clickable">
      <!--theme-switcher></theme-switcher-->
      <div class="d-flex avatar-menu" *ngIf="user != undefined">
        <a
          (click)="loadMessages()"
          class="clickable menu-icon"
          [ngClass]="{ 'tab-select': whatIsTheTab() === 'messages' }"
          ><img src="./assets/images/picto/picto-messages.png"
        /></a>
        <a
          (click)="loadPartners()"
          class="clickable menu-icon"
          [ngClass]="{ 'tab-select': whatIsTheTab() === 'partners' }"
          ><img src="./assets/images/picto/picto-partners.png"
        /></a>
        <div
          id="user-name"
          class="d-none d-lg-block user-name"
          (click)="toggleUserMenu($event)"
        >
          <div>{{ userFirstName }} {{ userLastName }}</div>
          <div class="workspace-name" *ngIf="shouldDisplayWorkspaceName()">
            Workspace: {{ getWorkspaceName() }}
          </div>
        </div>
        <div>
          <img
            class="cursor avatar"
            [src]="user?.infos?.profilePictureURL"
            (click)="toggleUserMenu($event)"
            *ngIf="shouldDisplayAvatarLetters() == false"
          />

          <div
            class="avatar-letters img-circle"
            (click)="toggleUserMenu($event)"
            *ngIf="shouldDisplayAvatarLetters() == true"
          >
            {{ getFirstLetter(user?.infos?.firstname)
            }}{{ getFirstLetter(user?.infos?.lastname) }}
          </div>
          <div class="row submodal-down" *ngIf="showMenu == true">
            <div class="col-12 submenu">
              <!--div class="d-flex flex-column">
                <h3
                  class="text-lato text-bold text-small no-margin-bottom text-right submenu-name"
                >
                  {{ user?.infos?.fullName }}
                </h3>
                <p
                  class="text-lato text-small no-margin-bottom text-right submenu-email"
                >
                  {{ user?.infos?.email }}
                </p>
              </div-->
              <div (click)="goToProfile()">
                <div class="submenu-icon submenu-profile">
                  <img
                    [src]="user?.infos?.profilePictureURL"
                    *ngIf="shouldDisplayAvatarLetters() == false"
                  />
                  <div
                    class="avatar-letters img-circle avatar-dark"
                    *ngIf="shouldDisplayAvatarLetters() == true"
                  >
                    {{ getFirstLetter(user?.infos?.firstname)
                    }}{{ getFirstLetter(user?.infos?.lastname) }}
                  </div>
                </div>
                <div class="text-lato text-small cursor submenu-title">
                  <p class="full-name text-bold text-small">
                    {{ user.infos.fullName }}
                  </p>
                  <p class="email text-lightgrey text-xsmall">
                    {{ user.infos.email }}
                  </p>
                  <p class="go-to-profile text-pink text-xsmall">
                    {{ 'NAV.SEE_MY_PROFILE' | translate }}
                  </p>
                </div>
              </div>
              <!--div (click)="goToSettings()">
                <div class="submenu-icon">
                  <img src="./assets/images/icon-settings.png" />
                </div>
                <div class="text-lato text-small cursor submenu-title">
                  {{ 'Preferences' }}
                </div>
              </div-->

              <div (click)="goToMyBrand()">
                <div class="submenu-icon">
                  <img src="./assets/images/picto/picto-brand.png" />
                </div>
                <div
                  id="my-brand"
                  class="text-lato text-small cursor submenu-title"
                >
                  {{ 'NAV.MY_BRAND' | translate }}
                </div>
              </div>

              <div (click)="goToSettings()">
                <div class="submenu-icon">
                  <img src="./assets/images/icon-settings.png" />
                </div>
                <div
                  id="workspaces"
                  class="text-lato text-small cursor submenu-title"
                >
                  {{ 'NAV.WORKSPACE_SETTINGS' | translate }}
                </div>
              </div>

              <div (click)="goToWorkspaces()">
                <div class="submenu-icon">
                  <img src="./assets/images/picto/picto-switch-workspace.png" />
                </div>
                <div
                  id="workspaces"
                  class="text-lato text-small cursor submenu-title"
                >
                  {{ 'NAV.SWITCH_WORKSPACE' | translate }}
                </div>
              </div>

              <div (click)="goToBilling()">
                <div class="submenu-icon">
                  <img src="./assets/images/picto/picto-billing.png" />
                </div>
                <div
                  id="billing"
                  class="text-lato text-small cursor submenu-title"
                >
                  {{ 'NAV.BILLING' | translate }}
                </div>
              </div>

              <!--div (click)="goToSupport()">
                <div class="submenu-icon">
                  <img src="./assets/images/icon-help-white.png" />
                </div>
                <div class="text-lato text-small cursor submenu-title">
                  {{ 'FAQ / Support' }}
                </div>
              </div-->

              <div (click)="showThing('logout')">
                <div class="submenu-icon">
                  <img src="./assets/images/icon-logout.png" />
                </div>
                <div
                  id="logout"
                  class="text-lato text-small cursor submenu-title"
                >
                  {{ 'NAVBAR.OPTION_LOGOUT' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
