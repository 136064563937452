<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-cart.png" style="width: 40px; padding-left: 10px; padding-right: 10px;">
            <span style="position: absolute; top: 24px;">GERER LES MEMBRES</span>
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png"
                    style="width: 16px" (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato" style="color: #484c50; font-size: 26px; margin-bottom: 12px">
                        Membres actuels de l'espace de travail
                    </div>

                </div>
                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;">
                    <div class="col-12 text-lato"
                        style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-lato text-small" style="border-width: 0px">Nom</th>
                                    <th scope="col" class="text-lato text-small" style="border-width: 0px">Type de
                                        compte</th>
                                    <th scope="col" class="text-lato text-small" style="border-width: 0px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let member of members">
                                    <th scope="row" class="text-lato text-medium" style="vertical-align: middle">
                                        {{member.user.fullName}}
                                        <br><span class="text-small" style="color: #999999">{{member.user.email}}</span>
                                    </th>
                                    <td class="text-lato text-small" style="vertical-align: middle;" *ngIf="member.right === 'owner'"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ff006c;"></span>Propriétaire
                                        principal</td>
                                        <td class="text-lato text-small" style="vertical-align: middle;" *ngIf="member.right === 'admin'"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ffad00;"></span>Administrateur
                                        d'espace de
                                        travail</td>
                                        <td class="text-lato text-small" style="vertical-align: middle;" *ngIf="member.right === 'member'"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #484c50;"></span>Membre
                                        régulier</td>
                                    <td class="text-lato text-medium" style="vertical-align: middle"></td>
                                </tr>
                                <!--tr>
                                    <th scope="row" class="text-lato text-medium" style="vertical-align: middle">
                                        Nicolas Bruneau
                                        <br><span class="text-small" style="color: #999999">nico@foxynerds.studio</span>
                                    </th>
                                    <td class="text-lato text-small" style="vertical-align: middle;"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ff006c;"></span>Propriétaire
                                        principal</td>
                                    <td class="text-lato text-medium" style="vertical-align: middle"></td>
                                </tr>

                                <tr>
                                    <th scope="row" class="text-lato text-medium" style="vertical-align: middle">
                                        Frédéric Bruneau
                                        <br><span class="text-small" style="color: #999999">fred@foxynerds.studio</span>
                                    </th>
                                    <td class="text-lato text-small" style="vertical-align: middle"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ffad00;"></span>Administrateur
                                        d'espace de
                                        travail</td>
                                    <td class="text-lato text-medium" style="vertical-align: middle"></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-lato text-medium" style="vertical-align: middle">
                                        Laetitia Mouvielle
                                        <br><span class="text-small"
                                            style="color: #999999">laetitia@foxynerds.studio</span></th>
                                    <td class="text-lato text-small" style="vertical-align: middle"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #484c50;"></span>Membre
                                        régulier</td>
                                    <td class="text-lato text-medium" style="vertical-align: middle"></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-lato text-medium" style="vertical-align: middle">Tomàs
                                        Valencia
                                        <br><span class="text-small"
                                            style="color: #999999">tomas@foxynerds.studio</span></th>
                                    <td class="text-lato text-small" style="vertical-align: middle"><span
                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #484c50;"></span>Membre
                                        régulier</td>
                                    <td class="text-lato text-medium" style="vertical-align: middle"></td>
                                </tr-->
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>