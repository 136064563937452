import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { APIService, PublishersProfileAPIService } from 'src/app/_services'
import { DateHelper } from '../../../../../_helpers/date.helpers'

@Component({
  templateUrl: 'publisher-profile-professional-profile-awards.component.html',
  selector: 'publisher-profile-professional-profile-awards',
  styleUrls: ['./publisher-profile-professional-profile-awards.component.scss'],
})
export class PublisherProfileProfessionalProfileAwardsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  awardsForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  awards: any[]
  award: any
  awardId: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)
    this.awardsForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      date: [undefined],
      descriptionForm: this.formBuilder.group({
        description: '',
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getAwards()
    this.sortArray()
  }

  ngOnDestroy() {}

  hasAwards() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.awardsAndHonors !=
        undefined
      )
    } catch (e) {
      return false
    }
  }

  getAwards() {
    if (this.hasAwards() == true) {
      this.awards = this.publisher.publisher.professionalProfile.awardsAndHonors
      return this.awards
    } else return undefined
  }

  getNumberOfAwards() {
    let objects = this.getAwards()
    if (objects) return objects.length
    return 0
  }

  getDate(information) {
    /*let awardDate = DateHelper.convertDoubleToReadableDate(
      this.publisher.publisher.professionalProfile.awardsAndHonors.date
    )*/
    let awardDate = DateHelper.convertDoubleToReadableDate(information.date)
    return awardDate
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.awardsForm.controls.titleForm['controls'].title.value
      )

      let descriptionField = this.readTextValue(
        this.awardsForm.controls.descriptionForm['controls'].description.value
      )

      let dateField = new Date(this.awardsForm.controls.date.value).getTime()

      if (
        titleField != undefined &&
        this.awardsForm.controls.titleForm['controls'].title.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        descriptionField != undefined &&
        this.awardsForm.controls.descriptionForm['controls'].description
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Description field can not be empty')
      }

      if (
        dateField != undefined &&
        this.awardsForm.controls.date.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Date field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let award_infos = {
          name: titleField,
          description: descriptionField,
          date: dateField,
        }

        let res = await this.apiService.addAwardInformation(
          this.publisher.publisher._id,
          award_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.loading = false
          this.successfullyAdded = true
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
      this.closeModal()
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteAward(award) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let awardId = award._id

      let res = await this.apiService.deleteAwardInformation(
        this.publisher.publisher._id,
        awardId
      )

      if (res.status == 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.closeModalIfNoExpertise()
        this.successfullyRemoved = true
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addAward() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateAward() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  changeDate(event) {
    this.awardsForm.controls.date.setValue(event.target.value)
  }

  sortArray() {
    let sortAwards = this.awards.sort((a, b) => b.date - a.date)
    return sortAwards
  }

  clearInputField() {
    this.awardsForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      date: [undefined],
      descriptionForm: this.formBuilder.group({
        description: '',
      }),
    })
  }

  closeModalIfNoExpertise() {
    if (this.getAwards().length == 0) {
      this.closeModal()
    }
  }
}
