import { Injectable } from '@angular/core'

@Injectable()
export class PublisherStateService {
  constructor() {}

  getPublisherState(publisher) {
    let state = {
      profile: {
        filled: 0,
      },
      billing: {
        filled: 0,
      },
    }

    if (this.hasBillingInformation(publisher) == true)
      state['billing']['filled'] = 1

    if (this.hasFullProfileBioInformation(publisher) == true)
      state['profile']['filled'] += 1 / 3

    if (this.hasProfileContentInformation(publisher) == true)
      state['profile']['filled'] += 1 / 3

    if (this.hasProfileProfessionalInformation(publisher) == true)
      state['profile']['filled'] += 1 / 3

    return state
  }

  hasBillingInformation(publisher) {
    try {
      return publisher?.publisher?.billingInformation != undefined
    } catch (e) {
      return false
    }
  }

  getBillingInformation(publisher) {
    if (this.hasBillingInformation(publisher) == true)
      return publisher.publisher.billingInformation
    else return undefined
  }

  hasProfileBioInformation(publisher) {
    try {
      return publisher?.publisher?.infos != undefined
    } catch (e) {
      return false
    }
  }

  hasFullProfileBioInformation(publisher) {
    try {
      if (this.hasProfileBioInformation(publisher) == false) return false
      else {
        let infos = publisher?.publisher?.infos
        let hasAtLeastOneInformation = false
        if (infos.biography != undefined && infos.biography !== '')
          hasAtLeastOneInformation = true
        if (infos.proposal != undefined && infos.proposal !== '')
          hasAtLeastOneInformation = true
        if (infos.values != undefined && infos.values !== '')
          hasAtLeastOneInformation = true

        return hasAtLeastOneInformation
      }
    } catch (e) {
      return false
    }
  }

  hasProfileContentInformation(publisher) {
    try {
      return publisher?.publisher?.profileBioInformation != undefined
    } catch (e) {
      return false
    }
  }

  hasProfileProfessionalInformation(publisher) {
    try {
      return publisher?.publisher?.profileProfessionalInformation != undefined
    } catch (e) {
      return false
    }
  }
}
