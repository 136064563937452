<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 10"
  *ngIf="showSomething == true"
>
  <div
    class="col-12 col-lg-3 d-none d-lg-flex left-menu"
    id="modal-left"
    style="flex: 1; opacity: 0.65; background-color: #000000; z-index: 98"
    (click)="closeModal()"
  ></div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0; z-index: 100"
    *ngIf="toShow === 'profile'"
  >
    <modal-profile></modal-profile>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'settings'"
  >
    <modal-settings></modal-settings>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'billing'"
  >
    <modal-billing></modal-billing>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'support'"
  >
    <modal-support></modal-support>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'workspaces'"
  >
    <modal-my-workspaces></modal-my-workspaces>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'invite-members'"
  >
    <modal-workspace-invitations></modal-workspace-invitations>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0"
    *ngIf="toShow === 'manage-members'"
  >
    <modal-workspace-members></modal-workspace-members>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: #ffffff; padding: 0; z-index: 100"
    *ngIf="toShow === 'social-accounts'"
  >
    <modal-accounts projectId="{{ this.projectId }}"></modal-accounts>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
    *ngIf="toShow === 'logout'"
  >
    <modal-logout></modal-logout>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
    *ngIf="toShow === 'kill-campaign'"
  >
    <modal-kill-campaign campaign="{{ this.campaignId }}"></modal-kill-campaign>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="
      background-color: rgba(0, 0, 0, 0.65);
      height: 100vh;
      padding: 0;
      z-index: 100;
    "
    *ngIf="toShow === 'alert'"
  >
    <modal-alert></modal-alert>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="
      background-color: rgba(0, 0, 0, 0.65);
      height: 999999vh;
      padding: 0;
      z-index: 100;
    "
    *ngIf="toShow === 'confirm'"
  >
    <modal-confirm
      campaign="{{ this.campaignId }}"
      action="{{ this.action }}"
      topic="{{ this.proposalId }}"
    >
    </modal-confirm>
  </div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="
      background-color: rgba(0, 0, 0, 0.65);
      height: 999999vh;
      padding: 0;
      z-index: 100;
    "
    *ngIf="toShow === 'info'"
  >
    <modal-info
      campaign="{{ this.campaignId }}"
      action="{{ this.action }}"
      topic="{{ this.proposalId }}"
    ></modal-info>
  </div>
</div>
<div
  style="position: absolute; top: 0; width: 100vw; height: 100vh; z-index: 9"
  *ngIf="showSomething == true"
  (click)="closeModal()"
></div>
