<div class="display-block col-direction section-container clickable">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row text-align-center">
      <div class="col-4 padding-top-15">
        <img
          class="hint"
          src="./../../../../../../assets/images/icon-bubble.png"
        />
        <p class="text-darkgrey text-xsmall padding-top-15">
          You have 3 new messages
        </p>
      </div>
      <div class="col-4 padding-top-15">
        <img
          class="hint"
          src="./../../../../../../assets/images/icon-clock.png"
        />
        <p class="text-darkgrey text-xsmall padding-top-15">
          You have 2 campaign(s) launched
        </p>
        <p class="text-darkgrey text-xsmall">
          You have 1 new campaign(s) launched
        </p>
        <p class="text-darkgrey text-xsmall">You have 1 campaign(s) finished</p>
      </div>
      <div class="col-4 padding-top-15">
        <img
          class="hint"
          src="./../../../../../../assets/images/icon_idea.png"
        />
        <p class="text-darkgrey text-xsmall padding-top-15">
          You have 3 new campaign proposed by your favorite brands
        </p>
      </div>
    </div>
  </div>
</div>
