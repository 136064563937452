import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'dashboard-ending-deal.component.html',
  selector: 'dashboard-ending-deal',
  styleUrls: ['./dashboard-ending-deal.component.scss'],
})
export class DashboardEndingDealComponent implements OnInit {
  constructor(private router: Router) {}

  async ngOnInit() {}

  ngOnDestroy() {}
}
