import { Component, OnInit, Input } from '@angular/core'

import { ModalService } from '../../../_services/modal.service'

import { APIService } from '../../../_services/api.service'

@Component({
  templateUrl: 'modal-kill-campaign.component.html',
  selector: 'modal-kill-campaign',
  styleUrls: ['./modal-kill-campaign.component.scss'],
})
export class ModalKillCampaignComponent implements OnInit {
  @Input('campaign') campaign: any

  constructor(
    private modalService: ModalService,
    private apiService: APIService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('kill-campaign')
  }

  async killCampaign() {
    await this.apiService.deleteCampaign(this.campaign)
    this.closeModal()
    this.modalService.sendEvent('campaign-killed')
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
