import { Component, Input, OnInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { TaskReferentialService } from 'src/app/_services/referential/task.referential.service'

import { TasksFilterService } from '../../../../_services/collaborations/tasks.filter.service'
import { TaskSynchronisationService } from 'src/app/_services/collaborations/task.synchronisation.service'

@Component({
  templateUrl: 'collaborations-filter.component.html',
  selector: 'collaborations-filter',
  styleUrls: ['./collaborations-filter.component.scss'],
})
export class CollaborationsFilterComponent implements OnInit {
  @Input('collaborationsData') collaborationsData: any

  showTaskStatusMenu: boolean
  selectedTaskStatusToShow: any

  showFilterMenu: boolean
  selectedFilter: any

  showSortMenu: boolean
  selectedSort: any

  showBrandMenu: boolean
  selectedBrand: any
  selectedBrandToRemove: any

  taskMenu: any[]
  filterMenu: any[]
  sortMenu: any[]
  brandMenu: any[]
  brands: any[]

  filterForThisWeekIsSelected: boolean
  selectedFilterToRemove: any

  constructor(
    private router: Router,
    private tasksFilterService: TasksFilterService,
    private taskReferentialService: TaskReferentialService,
    private taskSynchronisationService: TaskSynchronisationService
  ) {
    this.showTaskStatusMenu = false
    this.showFilterMenu = false
    this.showSortMenu = false
    this.showBrandMenu = false
    this.filterForThisWeekIsSelected = false
    this.selectedFilterToRemove = ''
  }

  async ngOnInit() {
    this.tasksFilterService.resetFilters()
    this.taskMenu = this.taskReferentialService.getTaskFilters()
    this.filterMenu = this.taskReferentialService.getTaskFilterOptions()
    this.sortMenu = this.taskReferentialService.getTaskSortingOptions()
    this.getBrandMenu()
    if (this.selectedBrand === undefined) this.selectedBrand = 'Brand'

    this.selectedTaskStatusToShow = this.taskMenu[0]
    this.selectedFilter = { name: 'Filter' }
    this.selectedSort = 'Sort'
  }

  ngOnDestroy() {}

  // get tasks to get brands and sort by brands
  async getBrandMenu() {
    this.brandMenu = []
    await this.taskSynchronisationService
      .getEditableTasks()
      .subscribe((tasks) => {
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].brand !== undefined) {
            this.brandMenu.push(tasks[i].brand)
            const unique = Array.from(
              new Set(this.brandMenu.map((a) => a.id))
            ).map((id) => {
              return this.brandMenu.find((a) => a.id === id)
            })
            this.brandMenu = unique
          }
        }
      })
  }

  toggleTaskMenu() {
    this.showTaskStatusMenu = !this.showTaskStatusMenu
  }

  toggleFilterMenu() {
    this.showFilterMenu = !this.showFilterMenu
  }

  toggleSortMenu() {
    this.showSortMenu = !this.showSortMenu
  }

  toggleBrandMenu() {
    this.showBrandMenu = !this.showBrandMenu
  }

  closeMenu() {
    this.showTaskStatusMenu = false
    this.showFilterMenu = false
    this.showSortMenu = false
    this.showBrandMenu = false
  }

  @HostListener('document:click', ['$event.target.id'])
  documentClickMenu(id: string) {
    // your click logic
    if (id !== 'tasks') this.showTaskStatusMenu = false
    if (id !== 'filter') this.showFilterMenu = false
    if (id !== 'sort') this.showSortMenu = false
    if (id !== 'brand') this.showBrandMenu = false
  }

  filterTaskStatus(status) {
    this.selectedTaskStatusToShow = status
    if (status.key === 'all') this.removeFilter('status')
    else this.filterCollaborationKanban('status', status.key, 'eq')
  }

  selectFilter(filter) {
    this.selectedFilter = filter

    // if filter choice is my tasks
    if (
      filter.name === 'My tasks' &&
      this.selectedFilterToRemove !== 'My tasks'
    ) {
      this.removeFilter('dueDateThisWeek')
      this.removeFilter('dueDateNextWeek')
      this.filterCollaborationKanban('assignee', filter.filter.value, 'eq')
      this.selectedFilterToRemove = 'My tasks'
    } else if (
      filter.name == 'My tasks' &&
      this.selectedFilterToRemove === 'My tasks'
    ) {
      this.removeFilter('assignee')
      this.selectedFilterToRemove = ''
      this.selectedFilter = { name: 'Filter' }
    }

    // if filter choice is due for this week
    if (
      filter.name === 'Due for this week' &&
      this.selectedFilterToRemove !== 'Due for this week'
    ) {
      this.removeFilter('assignee')
      this.removeFilter('dueDateNextWeek')
      this.filterCollaborationKanban('dueDateThisWeek', filter.filter.value, '')
      this.selectedFilterToRemove = 'Due for this week'
    } else if (
      filter.name == 'Due for this week' &&
      this.selectedFilterToRemove === 'Due for this week'
    ) {
      this.removeFilter('dueDateThisWeek')
      this.selectedFilterToRemove = ''
      this.selectedFilter = { name: 'Filter' }
    }

    // if filter choice is due for next week
    if (
      filter.name === 'Due for next week' &&
      this.selectedFilterToRemove !== 'Due for next week'
    ) {
      this.removeFilter('assignee')
      this.removeFilter('dueDateThisWeek')
      this.filterCollaborationKanban('dueDateNextWeek', filter.filter.value, '')
      this.selectedFilterToRemove = 'Due for next week'
    } else if (
      filter.name == 'Due for next week' &&
      this.selectedFilterToRemove === 'Due for next week'
    ) {
      this.removeFilter('dueDateNextWeek')
      this.selectedFilterToRemove = ''
      this.selectedFilter = { name: 'Filter' }
    }
  }

  selectSortToShow(sort) {
    this.selectedSort = sort
    if (sort.name === 'all') this.removeFilter('sort')
    else this.filterCollaborationKanban('sort', sort.name, 'eq')
  }

  // Filter by brands
  selectBrandToShow(brand) {
    this.selectedBrand = brand.title
    if (brand._id === this.selectedBrandToRemove) {
      this.removeFilter('brand')
      this.selectedBrand = 'Brand'
      this.selectedBrandToRemove = undefined
    } else {
      this.filterCollaborationKanban('brand', brand, '')
      this.selectedBrandToRemove = brand._id
    }
  }

  removeFilter(key) {
    this.tasksFilterService.removeFilter(key)
  }

  filterCollaborationKanban(key, value, operator) {
    let data = {
      key: key,
      value: value,
      operator: operator,
      brands: this.brandMenu,
    }

    this.tasksFilterService.addFilter(data)
  }
}
