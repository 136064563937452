import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl:
    'publisher-profile-professional-profile-publications.component.html',
  selector: 'publisher-profile-professional-profile-publications',
  styleUrls: [
    './publisher-profile-professional-profile-publications.component.scss',
  ],
})
export class PublisherProfileProfessionalProfilePublicationsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  publicationsForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)
    this.publicationsForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      authorForm: this.formBuilder.group({
        author: '',
      }),
      dateForm: this.formBuilder.group({
        date: '',
      }),
      urlForm: this.formBuilder.group({
        url: '',
      }),
      summaryForm: this.formBuilder.group({
        summary: '',
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {}

  ngOnDestroy() {}

  hasPublications() {
    try {
      return (
        this?.publisher?.publisher?.profileProfessionalInformation
          ?.publications != undefined
      )
    } catch (e) {
      return false
    }
  }

  getPublications() {
    if (this.hasPublications() == true)
      return this.publisher.publisher.profileProfessionalInformation
        .publications
    else return undefined
  }

  getNumberOfPublications() {
    let positions = this.getPublications()
    if (positions) return positions.length
    return 0
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.alertService.resetAlerts()
    } catch (e) {
      console.log(e)
    }
  }

  addPublication() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  validatePublication() {}

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
