<div style="width: 100%; margin-left: -21px; padding-right: 0px; margin-bottom: 15px;">
    <div class="row">
        <div class="col-12"
            style="background-color: #212029; margin-left: 6px; padding-top: 14px; padding-bottom: 14px; padding-left: 25px; padding-right: 25px"
            (click)="toggleMenu()">
            <div style="display: flex; flex-direction: row; position: relative;">
                <div style="margin-top: auto; margin-bottom: auto">
                    <img class="cursor" style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%;"
                        [src]="picUrl">
                </div>
                <div
                    style="margin-top:auto; margin-right: 0px; padding-left: 8px; padding-right: 8px; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                    <div class="text-xsmall" style="color: white">{{ 'COMMON.BRAND' | translate }} </div>
                    <div class="text-bold text-large cursor"
                        style="white-space: normal; overflow: hidden; color: white">
                        {{name}}</div>
                </div>
                <div style="margin-right: 0px; display: flex; flex-direction: row-reverse;float: right;"
                    *ngIf="user != undefined">
                    <div
                        style="display: flex; flex-direction: column; margin-right: 8px; padding-left: 3px; border-left-color: #666666; border-left-width: 1px;">
                        <img class="cursor" [src]="user?.infos?.profilePictureURL"
                            style="height: 40px; width: 40px; border-radius: 20px; margin-top: auto; margin-bottom: auto; object-fit: cover"
                            (click)="toggleUserMenu($event)">
                        <div class="row text-lato text-bold submodal-down"
                            style="position: absolute; margin-top: 60px; z-index: 10; margin-left: -180px; width: 250px"
                            *ngIf="showMenuProfile == true">
                            <div class="col-12 submenu">
                                <div (click)="goToProfile();stopEvent($event)">
                                    <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                        <span class="text-medium text-bold" style=" color: #000000"><img
                                                src="./assets/images/icon-settings.png" style="width: 20px"></span>
                                    </div>
                                    <div class="text-lato text-small cursor"
                                        style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        {{ 'NAV.MY_PROFILE' | translate }}</div>
                                </div>
                                <div (click)="showThing('logout');stopEvent($event)">
                                    <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                                        <span class="text-medium text-bold" style="color: #000000"><img
                                                src="./assets/images/icon-logout.png" style="width: 20px"></span>
                                    </div>
                                    <div class="text-lato text-small cursor"
                                        style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 30px); width: calc(100% - 30px); text-align: right">
                                        {{ 'NAV.LOGOUT' | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-lato text-bold modal-down no-margin"
            style="background-color: #212029; margin-left: 6px; padding-top: 14px; padding-bottom: 14px; padding-left: 25px; padding-right: 25px; width: 100%;"
            *ngIf="showMenu == true">
            <div class="col-12 project">
                <div (click)="toNewProject()">
                    <div style="margin-top: auto; margin-bottom: auto; margin-right: 14px">
                        <span class="text-medium text-bold"
                            style="color: #ff006c; background-color: white; width: 24px; height: 24px; padding-left: 6px; padding-right: 6px; border-radius: 50%">+</span>
                    </div>
                    <div class="text-small"
                        style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                        {{ 'NAV.CREATE_NEW_BRAND_SPACE' | translate }}</div>
                    <div>
                        <img style="width: 24px; border-radius: 50%" src="./assets/images/icon-arrow-right-white.png">
                    </div>
                </div>
            </div>
            <div class="col-12 project" *ngFor="let project of cacheService.projects; let i = index">
                <div (click)="toAProject(project._id)">
                    <div>
                        <img style="width: 24px; height: 24px; object-fit: cover; border-radius: 50%; margin-right: 14px"
                            [src]="project.pictureURL">
                    </div>
                    <div class="text-small"
                        style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                        {{project.title}}</div>
                    <div>
                        <img style="width: 24px; border-radius: 50%" src="./assets/images/icon-arrow-right.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12"
            style="background-color: #30313e; margin-left: 6px; margin-right: 15px; padding-top: 14px; padding-bottom: 14px; padding-left: 25px;"
            (click)="toggleMenu2()">
            <div style="padding-left: 0px; display: flex; flex-direction: row">
                <div class="col-6 text-bold"
                    style="color: #FFFFFF; background-color: #707070; padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; font-size: 25px; height: fit-content; width: fit-content; text-align: center"
                    *ngIf="title != undefined">{{title}}</div>
                <div class="col-6" style="opacity: 0.4;text-align: right">
                    <img style="width: 24px;margin-right: 10px" src="./assets/images/icon-expand-white.png">
                </div>
            </div>
        </div>
        <div class="row text-lato text-bold modal-down no-margin;"
            style="background-color: #212029; margin-left: 15px; padding-top: 14px; padding-bottom: 14px; width:-webkit-fill-available"
            *ngIf="showMenu2 == true">

            <div class="text-lato text-medium text-white no-margin" style="padding-left: 16px; padding-right: 16px;">
                    {{ 'NAV.BRAND_SPACE' | translate }}
            </div>
            <div class="box text-lato"
                style="color: white; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;">
                <div (click)="goToDashboard()" class="cursor">
                    <div
                        style="padding-top:20px; padding-bottom: 20px; padding-left: 16px; padding-right: 16px; border-color: rgba(255, 255, 255, 0.04); border-top-width: 1px; border-bottom-width: 1px; border-top-style: solid; border-bottom-style: solid; display: flex; flex-direction: row; width: 100vw;">
                        <div style="opacity: 0.4; margin-top: auto; margin-bottom: auto">
                            <!--img style="width: 20px; margin-right: 14px" src="./assets/images/icon-twitter-white.png"-->
                            <img style="width: 20px; margin-right: 14px" src="./assets/images/icon-star.png">
                        </div>
                        <div class="text-small text-bold"
                            style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                            {{ 'NAV.DASHBOARD' | translate }}
                        </div>
                    </div>
                </div>
                <div (click)="goToSettings()" class="cursor">
                    <div
                        style="padding-top:20px; padding-bottom: 20px; padding-left: 16px; padding-right: 16px; border-color: rgba(255, 255, 255, 0.04); border-top-width: 1px; border-bottom-width: 1px; border-top-style: solid; border-bottom-style: solid; display: flex; flex-direction: row; width: 100vw;">
                        <div style="opacity: 0.4; margin-top: auto; margin-bottom: auto">
                            <!--img style="width: 20px; margin-right: 14px" src="./assets/images/icon-twitter-white.png"-->
                            <img style="width: 20px; margin-right: 14px"
                                src="./assets/images/icon-project-settings-bold.png">
                        </div>
                        <div class="text-small text-bold"
                            style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                            {{ 'NAV.SETTINGS' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-lato text-bold modal-down no-margin;"
            style="background-color: #212029; margin-left: 15px; padding-top: 14px; padding-bottom: 14px; width:-webkit-fill-available"
            *ngIf="showMenu2 == true">
            <div class="text-lato text-medium text-white no-margin" style="padding-left: 16px; padding-right: 16px;">
                    {{ 'COMMON.CAMPAIGN' | translate }}
            </div>
            <div class="box text-lato"
                style="color: white; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;">
                <div *ngFor="let campaign of campaigns; let i = index" (click)="goToAll(campaign)" class="cursor">
                    <div
                        style="padding-top:20px; padding-bottom: 20px; padding-left: 16px; padding-right: 16px; border-color: rgba(255, 255, 255, 0.04); border-top-width: 1px; border-bottom-width: 1px; border-top-style: solid; border-bottom-style: solid; display: flex; flex-direction: row; width: 100vw;">
                        <div style="opacity: 0.4; margin-top: auto; margin-bottom: auto">
                            <!--img style="width: 20px; margin-right: 14px" src="./assets/images/icon-twitter-white.png"-->
                            <img style="width: 20px; margin-right: 14px" src="./assets/images/icon-instagram-white.png">
                        </div>
                        <div class="text-small text-bold"
                            style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                            {{campaign.title}}
                            <!--span style="margin-left: 8px; margin-right: 8px; padding-left: 4px; padding-right: 4px; padding-top: 4px; padding-bottom: 4px; color: #ffad00; font-size: 9px; border-radius: 10px; border-color: #ffad00; border-width: 1px; border-style: solid"
                                    *ngIf="pipeService.getNumberOfNotifications(pipe) > 0">{{pipeService.getNumberOfNotifications(pipe)}}</span-->
                        </div>
                    </div>
                </div>
                <div (click)="createCampaign()" class="cursor"
                    style="padding-top:12px; padding-bottom: 12px; padding-left: 16px; padding-right: 16px; background-color: #212029; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; display: flex; flex-direction: row;">
                    <div style="margin-top: auto; margin-bottom: auto">
                        <img style="width: 25px; margin-right: 14px" src="./assets/images/icon-add.png">
                    </div>
                    <div class="text-small text-bold"
                        style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 61px); width: calc(100% - 61px); color: #ff006c">
                        {{ 'NAV.CREATE_NEW_CAMPAIGN' | translate }}
                    </div>
                    <div style="opacity: 1">
                        <img style="width: 17px; margin-top: auto; margin-bottom: auto"
                            src="./assets/images/icon-arrow-right.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>