import * as moment from 'moment'

export class DateHelper {
  static DEFAULT_FORMAT = 'DD/MM/YYYY'
  static MESSAGE_FORMAT = 'DD/MM/YYYY HH:mm'

  static convertDoubleToReadableDate(date, format = undefined) {
    if (format == undefined) format = this.DEFAULT_FORMAT

    var dt = moment(date).format()

    return moment(date).format(format)
  }

  static convertDoubleToReadableDateForMessage(date, format = undefined) {
    if (format == undefined) format = this.MESSAGE_FORMAT

    var dt = moment(date).format()

    return moment(date).format(format)
  }

  static convertToTimestamp(date) {
    return moment(date).valueOf()
  }

  public static getCurrentWeek() {
    let currentDate = moment()
    let weekStart = currentDate.clone().startOf('isoWeek')
    let thisWeek = []

    for (let i = 0; i <= 6; i++) {
      thisWeek.push(moment(weekStart).add(i, 'days').format('x'))
    }
    return thisWeek
  }

  public static getNextWeek() {
    let date = moment().add(1, 'weeks').startOf('isoWeek')
    let weekStart = date.clone().startOf('isoWeek')
    let nextWeek = []

    for (let i = 0; i <= 6; i++) {
      nextWeek.push(moment(weekStart).add(i, 'days').format('x'))
    }
    return nextWeek
  }
}
