<div class="container">
  <form [formGroup]="currentTaskForm">
    <div class="header">
      <span>
        <button
          class="btn-mark"
          (click)="switchMark()"
          [ngClass]="{
            finished: markValue === 'Completed'
          }"
        >
          <!--img alt="validated" /-->
          {{ markValue }}
        </button>
      </span>
      <span id="section-right">
        <span>
          <img
            class="img-attachement"
            src="../../../../assets//images/icon-attachment.png"
            alt="attachement"
            title="Attach file"
          />
        </span>
        <span>
          <img
            (click)="deleteTask()"
            class="img-trash"
            src="../../../../assets//images/icon-trash.png"
            alt="delete"
            title="delete task"
          />
        </span>
        <span>
          <img
            class="img-section-right"
            src="../../../../assets//images/icon-arrow-right.png"
            alt="close modal"
            (click)="closeModal()"
            title="Close details"
          />
        </span>
      </span>
    </div>
    <div class="body-section">
      <div class="row">
        <div class="col-11" (click)="showBorderField()">
          <input-field
            id="title"
            (input)="saveTaskNameOnInputChange($event)"
            [ngClass]="{
              'show-border': taskNameShowBorder === 'show',
              'hide-border': taskNameShowBorder === 'hide'
            }"
            class="input-field hide-border"
            type="text"
            placeholder="Name of the task"
            name="name"
            relatedControlName="name"
            formGroupName="nameForm"
            [parentGroup]="currentTaskForm"
          ></input-field>
        </div>
      </div>
      <div class="row campaigns-section">
        <div class="col-2">{{ 'Step' }}</div>
        <!--div class="col-2">{{ 'Campaign' }}</div>
        <div class="col-3">
          <div class="clickable">
            <div class="d-flex avatar-menu">
              <div>
                <div
                  id="campaignName"
                  title="Click to change campaign"
                  (click)="toggleCampaignNameMenu()"
                >
                  {{ campaignNameNow }}
                  <img
                    id="campaignName"
                    class="arrow-toggle-menu"
                    src="../../../../assets//images/icon-arrow-right.png"
                  />
                </div>

                <div
                  class="row submodal-down"
                  *ngIf="showCampaignNameMenu == true"
                >
                  <div class="col-6 submenu campaign-submenu">
                    <div
                      class="row"
                      *ngFor="let campaign of campaignName"
                      (click)="saveCampaignName(campaign.name)"
                    >
                      <div class="col-1 icon-check-position">
                        <img
                          *ngIf="campaignNameNow === campaign.name"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                          alt="assignee placeholder"
                        />
                      </div>
                      <div
                        class="col-9 text-lato text-xxsmall cursor submenu-title"
                      >
                        {{ campaign.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div-->
        <div class="col-4">
          <div class="clickable">
            <div class="d-flex avatar-menu">
              <div>
                <div
                  id="stepStatus"
                  title="click to change campaign status"
                  (click)="toggleStepMenu()"
                >
                  {{ taskReferentialService.getTaskStepText(getTask().step) }}
                  <img
                    id="stepStatus"
                    class="arrow-toggle-menu"
                    src="../../../../assets//images/icon-arrow-right.png"
                  />
                </div>

                <div
                  class="row submodal-down"
                  *ngIf="showStepStatusMenu == true"
                >
                  <div class="col-6 submenu campaign-submenu">
                    <div
                      class="row"
                      *ngFor="let status of stepStatus"
                      (click)="updateStep(status.key)"
                    >
                      <div class="col-1 icon-check-position">
                        <img
                          *ngIf="getTask().step === status.key"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                        />
                      </div>
                      <div
                        class="col-9 text-lato text-xxsmall cursor submenu-title"
                      >
                        {{ status.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row deals-section">
        <div class="col-2">{{ 'Deal' }}</div>
        <div class="col-10">
          <div class="clickable">
            <div class="d-flex avatar-menu">
              <div>
                <div
                  id="dealName"
                  title="Click to change campaign"
                  (click)="toggleDealNameMenu()"
                >
                  <img
                    *ngIf="undefined"
                    [src]="undefined"
                    id="assignee"
                    class="icons-submenu"
                    alt="deal avatar picture"
                  />
                  {{ '--' }}
                  <img
                    id="dealName"
                    class="arrow-toggle-menu"
                    src="../../../../assets//images/icon-arrow-right.png"
                  />
                </div>

                <div class="row submodal-down" *ngIf="showDealNameMenu == true">
                  <div class="col-6 submenu campaign-submenu">
                    <div
                      class="row"
                      *ngFor="let deal of getDeals()"
                      (click)="
                        saveDealData(
                          deal.proposal.name,
                          deal._id,
                          deal?._brand?.pictureURL
                        )
                      "
                    >
                      <div class="col-1 icon-check-position">
                        <img
                          *ngIf="undefined === deal.proposal.name"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                        />
                      </div>
                      <div
                        class="col-9 text-lato text-xxsmall cursor submenu-title"
                      >
                        <img
                          [src]="deal?._brand?.pictureURL"
                          id="assignee"
                          class="icons-submenu"
                          alt="deal avatar picture"
                        />
                        {{ deal.proposal.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row assignee-section">
        <div class="col-2">{{ 'Brand' }}</div>
        <!-- If task no assignee -->
        <div class="col-10">
          <div class="clickable">
            <div class="d-flex avatar-menu">
              <div>
                <div
                  id="brand"
                  title="Click to change assignee"
                  (click)="toggleBrandMenu()"
                >
                  <img
                    *ngIf="brandChoose === 'No brand'"
                    id="brand"
                    class="icons"
                    src="../../../../assets//images/img-placeholder.png"
                    alt="brand placeholder"
                  />
                  <img
                    *ngIf="brandChoose !== 'No brand'"
                    [src]="user?.infos?.profilePictureURL"
                    id="brand"
                    class="icons"
                    alt="brand avatar picture"
                  />
                  {{ brandChoose }}
                </div>
                <div class="row submodal-down" *ngIf="showBrandMenu == true">
                  <div class="col-4 submenu">
                    <div
                      class="row"
                      *ngFor="let brand of getBrands()"
                      (click)="updateBrand(brand._id)"
                    >
                      <div
                        class="col-1 marge-icons-selected icon-check-position"
                      >
                        <img
                          *ngIf="brandChoose === brand.title"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                          alt="brand placeholder"
                        />
                      </div>
                      <div class="col-1 marge-avatar">
                        <img
                          [src]="brand.pictureURL"
                          id="brand"
                          class="icons-submenu"
                          alt="user avatar picture"
                        />
                      </div>
                      <div
                        class="col-8 text-lato text-xxsmall cursor submenu-title-assignee hv-center"
                        id="brand"
                        value="testInfluencer"
                      >
                        {{ brand.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row assignee-section">
        <div class="col-2">{{ 'Assignee' }}</div>
        <!-- If task no assignee -->
        <div class="col-10">
          <div class="clickable">
            <div class="d-flex avatar-menu">
              <div>
                <div
                  id="assignee"
                  title="Click to change assignee"
                  (click)="toggleAssigneeMenu()"
                >
                  <img
                    *ngIf="assigneeTo === 'No assignee'"
                    id="assignee"
                    class="icons"
                    src="../../../../assets//images/img-placeholder.png"
                    alt="assignee placeholder"
                  />
                  <img
                    *ngIf="assigneeTo !== 'No assignee'"
                    [src]="user?.infos?.profilePictureURL"
                    id="assignee"
                    class="icons"
                    alt="user avatar picture"
                  />
                  {{ assigneeTo }}
                </div>

                <div class="row submodal-down" *ngIf="showAssigneeMenu == true">
                  <div class="col-4 submenu">
                    <div
                      class="row"
                      *ngFor="let assignee of assignees"
                      (click)="saveAssignee(assignee.name, assignee.type)"
                    >
                      <div
                        class="col-1 marge-icons-selected icon-check-position"
                      >
                        <img
                          *ngIf="assigneeTo === assignee.name"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                          alt="assignee placeholder"
                        />
                      </div>
                      <div
                        class="col-1 marge-avatar"
                        *ngIf="assignee.type === 'advertiser'"
                      >
                        <img
                          src="../../../../assets//images/img-placeholder.png"
                          id="assignee"
                          class="icons-submenu"
                          alt="user avatar picture"
                        />
                      </div>
                      <div
                        class="col-1 marge-avatar"
                        *ngIf="assignee.type === 'publisher'"
                      >
                        <img
                          [src]="user?.infos?.profilePictureURL"
                          id="assignee"
                          class="icons-submenu"
                          alt="user avatar picture"
                        />
                      </div>
                      <div
                        class="col-8 text-lato text-xxsmall cursor submenu-title-assignee hv-center"
                        id="influencer"
                        value="testInfluencer"
                      >
                        {{ assignee.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- if task assignee -->
      </div>
      <div class="row date-section">
        <div class="col-2">{{ 'Due date' }}</div>
        <div class="col-3 date-field-border">
          <mat-form-field
            class="input-text text-lato text-xxsmall text-darkgrey date-field"
          >
            <input
              matInput
              [matDatepicker]="picker"
              name="dueDate"
              formControlName="dueDate"
              (dateChange)="changeDate($event)"
              autocomplete="off"
              parentGroup="currentTaskForm"
            />
            <mat-placeholder class="placeholder">Due date</mat-placeholder>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- if taask assignee -->
        <!--div class="col-10">
              <img src="" alt="user-avatar">
          </div-->
      </div>
      <div class="row priority-section">
        <div class="col-2">{{ 'Priority' }}</div>
        <div class="col-10">
          <div>
            <div class="d-flex avatar-menu">
              <div>
                <div
                  class="clickable"
                  title="Click to change priority"
                  id="priority"
                  (click)="togglePriorityMenu()"
                >
                  {{
                    taskReferentialService.getTaskPriorityText(
                      getTask().priority
                    )
                  }}
                  <img
                    id="priority"
                    class="arrow-toggle-menu"
                    src="../../../../assets//images/icon-arrow-right.png"
                  />
                </div>

                <div class="row submodal-down" *ngIf="showPriorityMenu == true">
                  <div class="col-5 submenu">
                    <div
                      class="row"
                      *ngFor="let priority of priorities"
                      (click)="updatePriority(priority.key)"
                    >
                      <div class="col-1 icon-check-position">
                        <img
                          *ngIf="getTask().priority === priority.key"
                          class="icons-selected"
                          src="../../../../assets//images/icon-check.png"
                          alt="assignee placeholder"
                        />
                      </div>
                      <div
                        class="col-9 text-lato text-xxsmall cursor submenu-title"
                        value="testInfluencer"
                      >
                        {{ priority.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row description-section">
        <div class="col-2">Description</div>
        <div class="col-10" (click)="showDescriptionBorderField()">
          <input-text-area
            (input)="saveDascriptionOnInputChange($event)"
            id="description"
            identifier="description"
            [ngClass]="{
              'description-show-border': taskDescriptionShowBorder === 'show',
              'description-hide-border': taskDescriptionShowBorder === 'hide'
            }"
            class="input-field description-hide-border"
            type="text"
            placeholder="add more details to this task..."
            name="description"
            relatedControlName="description"
            formGroupName="descriptionForm"
            [parentGroup]="currentTaskForm"
          >
          </input-text-area>
        </div>
      </div>
    </div>
    <div class="comment-section">
      <div class="history-section">
        <div class="row">
          <div class="col-1">
            <!--img
              src="../../../../assets//images/img-placeholder.png"
              alt="placeholder"
            /-->
          </div>
          <div class="col-11">
            <span class="user-created"> </span>
            <span class="created-date">created this task </span>
          </div>
        </div>
        <div class="row" *ngFor="let comment of commentsArray">
          <div class="col-1"></div>
          <div class="col-11">
            <span class="created-date"
              >{{ comment.owner }} post comment
              {{ readDate(comment.createdAt) }}</span
            >
          </div>
          <div class="col-1"></div>
          <div class="col-11">
            <span class="user-created">{{ comment.text }}</span>
          </div>
          <!--div class="col-1">
            <img
              src="../../../../assets//images/img-placeholder.png"
              alt="placeholder"
            />
          </div-->
          <!-- if taask assignee -->
          <!--div class="col-10">
          <img src="" alt="user-avatar">
      </div-->
          <!--div class="col-11">
            <span class="user-created">{{ 'userName' }} created this task</span>
            <span class="created-date">{{ 'created_date' }}</span>
          </div-->
          <!--div class="col-1"></div>
          <div class="col-11">
            <span class="event-in-task"
              >{{ 'userName' }} added to {{ 'campaign.title' }}</span
            >
            <span class="created-date">{{ 'created_date' }}</span>
          </div-->
        </div>
      </div>
      <div class="textarea-section">
        <div class="row">
          <div class="col-1">
            <img
              src="../../../../assets//images/img-placeholder.png"
              alt="placeholder"
            />
          </div>
          <div class="col-10 change-textarea">
            <input-text-area
              id="comment"
              identifier="comment"
              (input)="saveCommentOnInputChange($event)"
              class="input-field comment-field"
              type="text"
              placeholder="Ask a question or post an update..."
              name="comment"
              relatedControlName="comment"
              formGroupName="commentForm"
              [parentGroup]="currentTaskForm"
            >
            </input-text-area>
          </div>
        </div>
      </div>
    </div>
    <!--div class="footer">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-7 text-xxsmall text-lightgrey">
        Collaborators
        <img
          src="../../../../assets//images/img-placeholder.png"
          alt="placeholder"
        />
      </div>
      <div class="col-3 leave-section text-xxsmall text-lightgrey">
        <img
          class="img-clock"
          src="../../../../assets//images/icon-clock.png"
          alt="placeholder"
        />
        Leave task
      </div>
      <div class="col-1"></div>
    </div>
  </div-->
  </form>
</div>

<!-- Modal confirmation -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalConfirm == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalConfirmation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddinformation"
      aria-hidden="false"
      (click)="closeModalConfirm()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato text-align-center"
                    id="modalConfirmation"
                  >
                    {{ 'are you sure you want to brand ?' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModalConfirm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row confirmation-btn">
            <button
              type="button"
              class="col-6 btn btn-primary two-btn-left-radius text-lato btn-text"
              (click)="closeModalConfirm()"
            >
              {{ 'YES' | translate }}
            </button>
            <button
              type="button"
              class="col-6 btn btn-delete two-btn-right-radius text-lato btn-text"
              (click)="closeModalConfirm()"
            >
              {{ 'NO' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
