<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-12 campaign-title text-pink text-bold text-large">
              <img
                class="brand-logo-thumbnail"
                [src]="opportunity._brand?.brand?.brand_logo_path"
                *ngIf="opportunity._brand?.brand?.brand_logo_path != undefined"
              />
              <img
                class="brand-logo-thumbnail"
                [src]="'./assets/images/fns_logo_head.png'"
                *ngIf="opportunity._brand?.brand?.brand_logo_path == undefined"
              />
              {{ opportunity._brand?.brand?.name }}
              <span
                *ngFor="
                  let account of opportunity._brand?.brand?.digital_accounts
                "
                class="network-logo"
                ><a [href]="account.link" class="network-link" target="_blank">
                  <img
                    [src]="getDigitalAccountImagePath(account.type)"
                    class="account-image"
                /></a>
              </span>

              <br />
              <span
                class="brand text-small text-darkgrey"
                style="font-weight: normal"
              >
                {{ opportunity._brand?.brand?.baseline }}</span
              >
              <div class="right-stuck">
                <span class="badge badge-secondary">{{
                  getIndustry(opportunity._brand?.brand?.industry)
                }}</span>
              </div>
            </div>
            <!--div class="col-12 padding-top-15"-->
            <!-- prettier-ignore -->
            <!--div class="input-title text-bold text-pink text-large">Industry
              </div-->
            <!-- prettier-ignore -->
            <!--div class="text-brand campaign-brief">{{ getIndustry(opportunity._brand?.brand?.industry) }}
              </div-->
            <!--/div-->
            <div
              class="col-12 padding-top-25"
              *ngIf="opportunity._brand?.brand?.products?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">Company's products & services
              </div>
              <div *ngFor="let product of opportunity._brand?.brand?.products">
                <!-- prettier-ignore -->
                <div class="text-brand campaign-brief">{{ product.description }}</div>
                <!-- prettier-ignore -->
                <div class="input-subtitle text-brand campaign-brief">Commercial Target</div>
                <!-- prettier-ignore -->
                <div class="text-brand campaign-brief">{{ product.target }}</div>
                <!-- prettier-ignore -->
                <div class="input-subtitle text-brand campaign-brief">Audience Location</div>
                <!-- prettier-ignore -->
                <span class="text-brand campaign-brief audience-location" *ngFor="let location of product.locations">{{ location.country | translate }}, {{ location.area | translate }}</span>
              </div>
            </div>
            <div class="col-12 padding-top-25">
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">Company presentation
              </div>
              <!-- prettier-ignore -->
              <div class="text-brand campaign-brief">{{ opportunity._brand?.brand?.description }}
              </div>
            </div>
            <div class="col-12 padding-top-25">
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">Core Values
              </div>
              <!-- prettier-ignore -->
              <div class="text-brand campaign-brief">{{ opportunity._brand?.brand?.values_description }}
              </div>
            </div>
            <div
              class="col-12 padding-top-25"
              *ngIf="opportunity._brand?.brand?.advocates_accounts?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">Advocates
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      class="text-lato text-small"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'PROJECT.MY_BRAND.NETWORK' | translate }}
                    </th>
                    <th
                      class="text-lato text-small"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'PROJECT.MY_BRAND.ACCOUNT' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let advocate of opportunity._brand?.brand
                        ?.advocates_accounts
                    "
                  >
                    <td
                      class="text-lato text-small"
                      scope="row"
                      style="vertical-align: middle"
                    >
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-web.png"
                        *ngIf="advocate.type === 'web'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-twitter.png"
                        *ngIf="advocate.type === 'twitter'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-facebook.png"
                        *ngIf="advocate.type === 'facebook'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-instagram.png"
                        *ngIf="advocate.type === 'instagram'"
                      />
                      <img
                        style="
                          width: 15px;
                          margin-left: 2.5px;
                          margin-right: 14px;
                        "
                        src="./assets/images/socials-youtube.png"
                        *ngIf="advocate.type === 'youtube'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-linkedin.png"
                        *ngIf="advocate.type === 'linkedin'"
                      />
                    </td>
                    <td
                      class="text-lato text-small"
                      style="vertical-align: middle"
                    >
                      <a target="_blank">{{ advocate.link }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="col-12 padding-top-25"
              *ngIf="opportunity._brand?.brand?.marketing_resources?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">{{ 'PROJECT.MY_BRAND.MARKETING_MATERIALS' | translate }}
              </div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="text-lato text-small"
                        style="border-width: 0px"
                      >
                        {{ 'PROJECT.MY_BRAND.FILE_TITLE' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-small"
                        style="
                          border-width: 0px;
                          text-align: right;
                          padding-right: 20px;
                        "
                      >
                        {{ 'PROJECT.MY_BRAND.FILE' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let resource of getMarketingResources()">
                      <td
                        class="text-lato text-small"
                        style="vertical-align: middle"
                      >
                        {{ resource.name }}
                      </td>
                      <td
                        scope="row"
                        class="text-lato text-small"
                        style="vertical-align: middle; text-align: right"
                      >
                        <a
                          download="filename"
                          target="_blank"
                          [href]="resource.link"
                          ><img
                            style="width: 40px"
                            src="./assets/images/icon_download.png"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
