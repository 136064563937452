import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { PublishersProfileAPIService } from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'

@Component({
  templateUrl:
    'publisher-profile-professional-profile-spoken-languages.component.html',
  selector: 'publisher-profile-professional-profile-spoken-languages',
  styleUrls: [
    './publisher-profile-professional-profile-spoken-languages.component.scss',
  ],
})
export class PublisherProfileProfessionalProfileSpokenLanguagesComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  spokenLanguagesForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  languages: any[]
  language: any
  languageId: any

  suggestedLanguages: any[]
  languageNameControl = new FormControl()
  filteredOptions: Observable<any[]>

  languageStatuses: any[]

  successfullyAdded: boolean
  successfullyRemoved: boolean
  proficiency: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private campaignReferentialService: CampaignReferentialService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)

    this.spokenLanguagesForm = this.formBuilder.group({
      spokenLanguage: '',
      languageProficiency: '',
    })

    this.languageStatuses = this.campaignReferentialService.getLanguageStatuses()

    this.suggestedLanguages = []

    this.filteredOptions = this.spokenLanguagesForm
      .get('spokenLanguage')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      )
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {}

  ngOnDestroy() {}

  hasSpokenLanguages() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.spokenLanguages !=
        undefined
      )
    } catch (e) {
      return false
    }
  }

  getSpokenLanguages() {
    if (this.hasSpokenLanguages() == true)
      return this.publisher.publisher.professionalProfile.spokenLanguages
    else return undefined
  }

  getNumberOfSpokenLanguages() {
    let positions = this.getSpokenLanguages()
    if (positions) return positions.length
    return 0
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let languageField = this.readTextValue(
        this.spokenLanguagesForm.get('spokenLanguage').value
      )

      if (
        languageField != undefined &&
        this.spokenLanguagesForm.get('spokenLanguage').valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Language field can not be empty')
      }

      let languageProficiency = this.spokenLanguagesForm.get(
        'languageProficiency'
      ).value

      if (languageProficiency === '') {
        this.isValid = false
        this.proficiency = false
        this.alertService.pushErrorAlert(
          'You have to select a language proficiency'
        )
      }

      if (this.isValid == true) {
        this.isValid = true

        let language_infos = {
          code: this.getCorrespondingLanguageKey(languageField),
          status: languageProficiency,
        }

        let res = await this.apiService.addSpokenLanguageInformation(
          this.publisher.publisher._id,
          language_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.publisher.publisher = res.body.publisher
          this.makeNotEditable()
          this.successfullyAdded = true
          this.loading = false
          this.clearInputField()
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.clearInputField()
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteSpokenLanguage(language) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let languageId = language._id

      let res = await this.apiService.deleteSpokenLanguageInformation(
        this.publisher.publisher._id,
        languageId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.makeNotEditable()
        this.closeModalIfNoExpertise()
        this.successfullyRemoved = true
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addSpokenLanguage() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateSpokenLanguage() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  validateSpokenLanguage() {}

  closeModal() {
    try {
      this.showModal = false
      this.showModalUpdate = false
      this.showModalEvent.emit(false)
      document.body.style.overflow = ''
      this.successfullyAdded = undefined
      this.successfullyRemoved = undefined
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  getCorrespondingLanguageKey(language: string) {
    let languages = this.campaignReferentialService.getLanguages()
    let result = undefined
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].text.toLowerCase() === language.toLowerCase()) {
        result = languages[i].key
        return result
      }
    }

    return result
  }

  getCorrespondingLanguageName(language: string) {
    let languages = this.campaignReferentialService.getLanguages()
    let result = undefined
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].key.toLowerCase() === language.toLowerCase()) {
        result = languages[i].text
        return result
      }
    }

    return result
  }

  getCorrespondingStatusText(code: string) {
    let result = undefined
    for (let i = 0; i < this.languageStatuses.length; i++) {
      if (this.languageStatuses[i].key.toLowerCase() === code.toLowerCase()) {
        result = this.languageStatuses[i].text
        return result
      }
    }

    return result
  }

  private _filter(value: string): string[] {
    let filteredLanguages = this.getSuggestedLanguages()
    let result = []
    for (let i = 0; i < filteredLanguages.length; i++) {
      result.push(filteredLanguages[i].text)
    }
    const filterValue = value.toLowerCase()
    return result.filter(
      (language) => language.toLowerCase().indexOf(filterValue) === 0
    )
  }

  getSuggestedLanguages() {
    let suggestedLanguages = this.campaignReferentialService.getLanguages()

    let languages = this.getSpokenLanguages()
    if (languages == undefined) {
      return suggestedLanguages
    }

    let checker = []
    for (let i = 0; i < languages.length; i++) {
      checker.push(languages[i].code)
    }

    let result = []
    for (let i = 0; i < suggestedLanguages.length; i++) {
      const index = checker.indexOf(suggestedLanguages[i].key)
      if (index == -1) {
        result.push(suggestedLanguages[i])
      }
    }

    return result
  }

  closeModalIfNoExpertise() {
    if (this.getSpokenLanguages().length == 0) {
      this.closeModal()
    }
  }

  clearInputField() {
    this.spokenLanguagesForm = this.formBuilder.group({
      spokenLanguage: '',
      languageProficiency: '',
    })
  }
}
