<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div class="form-title">
      <publisher-section-intro
        title="{{ 'MY_BRAND.TITLE' | translate }}"
        description="{{ 'MY_BRAND.SUBTITLE' | translate }}"
      ></publisher-section-intro>
    </div>
    <div style="max-width: 80%; margin: auto">
      <div
        class="display-block col-direction section-container justify-content-center"
      >
        <div
          class="col-12 one-edge-shadow form-background-color"
          style="padding-left: 40px; padding-right: 40px"
        >
          <div class="row d-flex flex-row">
            <div class="text-lato form-container-section-left">
              <img
                class="brand-logo-thumbnail"
                [src]="project?.brand?.brand_logo_path"
              />
            </div>
            <div
              id="brand-description"
              class="padding-top-15 d-flex flex-column"
            >
              <div class="d-flex flex-row">
                <div class="mr-auto input-title text-pink text-large">
                  {{ project?.brand?.name }}
                </div>
                <div>
                  <span
                    class="btn text-lato text-bold card-title no-margin"
                    style="
                      background-color: #ff006c;
                      color: white;
                      font-size: 13px;
                      margin-left: 20px !important;
                    "
                    (click)="editBrand()"
                    >{{ 'MY_BRAND.EDIT' | translate }}</span
                  >
                </div>
              </div>
              <div
                class="text-xsmall text-darkgrey padding-top-15 padding-bottom-15 text-input"
              >
                {{ project?.brand?.baseline }}
              </div>
            </div>
          </div>
          <!--div class="row justify-content-center padding-bottom-15">
            <div class="col-12 padding-top-15">
              <div class="input-title text-bold text-pink text-large">
                {{ 'MY_BRAND.INDUSTRY' | translate }}
              </div>
              <div class="left-text padding-top-15 text-xsmall text-input">
                <div class="text-brand campaign-brief">{{ getIndustry(project?.brand?.industry) }}</div>
              </div>
            </div>
          </div-->
          <div class="row justify-content-center padding-bottom-15">
            <div class="col-12 padding-top-15">
              <div class="input-title text-bold text-pink text-large">
                {{ 'MY_BRAND.INDUSTRIES' | translate }}
              </div>
              <div class="left-text padding-top-15 text-xsmall text-input">
                <!-- prettier-ignore -->
                <div class="text-brand campaign-brief">
                  {{ getIndustryList(project?.brand) }}
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center padding-bottom-15">
            <div class="col-12 padding-top-15">
              <div class="input-title text-bold text-pink text-large">
                {{ 'MY_BRAND.DESCRIPTION' | translate }}
              </div>
              <!--div class="left-text padding-top-15 text-xsmall text-input">
                {{ project?.brand?.baseline }}
              </div-->
              <!--div
                class="left-text padding-top-15 padding-bottom-15 text-xsmall text-input"
              >
                {{ project?.brand?.description }}
              </div-->
              <div
                class="left-text padding-top-15 padding-bottom-15 text-xsmall text-input"
              >
                <quill-view-html
                  [content]="project?.brand?.description"
                  theme="snow"
                ></quill-view-html>
              </div>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div class="col-12 padding-top-15">
              <div class="input-title text-bold text-pink text-large">
                {{ 'MY_BRAND.CORE_VALUES' | translate }}
              </div>
              <!--div
                class="left-text padding-top-15 padding-bottom-15 text-xsmall text-input"
              >
                {{ project?.brand?.values_description }}
              </div-->
              <div
                class="left-text padding-top-15 padding-bottom-15 text-xsmall text-input"
              >
                <quill-view-html
                  [content]="project?.brand?.values_description"
                  theme="snow"
                ></quill-view-html>
              </div>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div class="col-12 padding-top-15">
              <div class="input-title text-bold text-pink text-large">
                {{ 'MY_BRAND.PRODUCT' | translate }}
              </div>
              <div
                *ngFor="let product of project?.brand?.products"
                class="left-text padding-top-15 text-xsmall text-input"
              >
                <!-- prettier-ignore -->
                <!--div class="text-brand campaign-brief">{{ product.description }}</div-->
                <div class="text-brand campaign-brief">
                  <quill-view-html
                    [content]="product.description"
                    theme="snow"
                  ></quill-view-html>
                </div>
                <!-- prettier-ignore -->
                <div class="input-subtitle text-brand campaign-brief">{{ 'MY_BRAND.COMMERCIAL_TARGET' | translate }}</div>
                <!-- prettier-ignore -->
                <!--div class="text-brand campaign-brief">{{ product.target }}</div-->

                <div class="text-brand campaign-brief">
                  <quill-view-html
                    [content]="product.target"
                    theme="snow"
                  ></quill-view-html>
                </div>
                <!-- prettier-ignore -->
                <div class="input-subtitle text-brand campaign-brief">{{ 'MY_BRAND.AUDIENCE_LOCATION' | translate }}</div>
                <!-- prettier-ignore -->
                <span class="text-brand campaign-brief audience-location" *ngFor="let location of product.locations">{{ location.country | translate }}, {{ location.area | translate }}</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div
              class="col-12 padding-top-15"
              *ngIf="project?.brand?.advocates_accounts?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">{{ 'MY_BRAND.CORPORATE_LINKS' | translate }}
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      class="text-lato text-xsmall"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.NETWORK' | translate }}
                    </th>
                    <th
                      class="text-lato text-xsmall"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.ACCOUNT' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let account of project?.brand?.digital_accounts">
                    <td
                      class="text-lato text-xsmall"
                      scope="row"
                      style="vertical-align: middle"
                    >
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-web.png"
                        *ngIf="account.type === 'web'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-twitter.png"
                        *ngIf="account.type === 'twitter'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-facebook.png"
                        *ngIf="account.type === 'facebook'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-instagram.png"
                        *ngIf="account.type === 'instagram'"
                      />
                      <img
                        style="
                          width: 15px;
                          margin-left: 2.5px;
                          margin-right: 14px;
                        "
                        src="./assets/images/socials-youtube.png"
                        *ngIf="account.type === 'youtube'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-linkedin.png"
                        *ngIf="account.type === 'linkedin'"
                      />
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      <a target="_blank" [href]="account.link">{{
                        account.link
                      }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div
              class="col-12 padding-top-15"
              *ngIf="project?.brand?.advocates_accounts?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">{{ 'MY_BRAND.ADVOCATES' | translate }}
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      class="text-lato text-xsmall"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.NETWORK' | translate }}
                    </th>
                    <th
                      class="text-lato text-xsmall"
                      scope="col"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.ACCOUNT' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let advocate of project?.brand?.advocates_accounts"
                  >
                    <td
                      class="text-lato text-xsmall"
                      scope="row"
                      style="vertical-align: middle"
                    >
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-web.png"
                        *ngIf="advocate.type === 'web'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-twitter.png"
                        *ngIf="advocate.type === 'twitter'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-facebook.png"
                        *ngIf="advocate.type === 'facebook'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-instagram.png"
                        *ngIf="advocate.type === 'instagram'"
                      />
                      <img
                        style="
                          width: 15px;
                          margin-left: 2.5px;
                          margin-right: 14px;
                        "
                        src="./assets/images/socials-youtube.png"
                        *ngIf="advocate.type === 'youtube'"
                      />
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-linkedin.png"
                        *ngIf="advocate.type === 'linkedin'"
                      />
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      <a target="_blank" [href]="advocate.link">{{
                        advocate.link
                      }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div
              class="col-12 padding-top-15"
              *ngIf="project?.brand?.marketing_resources?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">{{ 'MY_BRAND.MARKETING_MATERIALS' | translate }}
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.FILE_TITLE' | translate }}
                    </th>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.AVAILABILITY' | translate }}
                    </th>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="
                        border-width: 0px;
                        text-align: right;
                        padding-right: 20px;
                      "
                    >
                      {{ 'MY_BRAND.FILE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let resource of getMarketingResources()">
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      {{ resource.name }}
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      {{ getMarketingResourceAvailabilityText(resource) }}
                    </td>
                    <td
                      scope="row"
                      class="text-lato text-xsmall"
                      style="vertical-align: middle; text-align: right"
                    >
                      <a
                        download="filename"
                        target="_blank"
                        [href]="resource.link"
                        ><img
                          style="width: 40px"
                          src="./assets/images/icon_download.png"
                      /></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center padding-bottom-15">
            <div
              class="col-12 padding-top-15"
              *ngIf="project?.brand?.marketing_links?.length > 0"
            >
              <!-- prettier-ignore -->
              <div class="input-title text-bold text-pink text-large">{{ 'MY_BRAND.LINKS' | translate }}
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    ></th>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.LINK_NAME_TITLE' | translate }}
                    </th>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.LINK_TITLE' | translate }}
                    </th>
                    <th
                      scope="col"
                      class="text-lato text-xsmall"
                      style="border-width: 0px"
                    >
                      {{ 'MY_BRAND.AVAILABILITY' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let resource of getMarketingLinks()">
                    <td
                      class="text-lato text-xsmall"
                      scope="row"
                      style="vertical-align: middle"
                    >
                      <img
                        style="width: 20px; margin-right: 14px"
                        src="./assets/images/socials-web.png"
                      />
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      <a
                        target="_blank"
                        [href]="resource.link"
                        class="text-lato text-xsmall clickable"
                        >{{ resource.name }}</a
                      >
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      <a
                        target="_blank"
                        [href]="resource.link"
                        class="text-lato text-xsmall clickable"
                        >{{ resource.link }}</a
                      >
                    </td>
                    <td
                      class="text-lato text-xsmall"
                      style="vertical-align: middle"
                    >
                      {{ getMarketingResourceAvailabilityText(resource) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
