<div class="container-fluid full-height bg-default">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'COMMON.ANALYTICS' | translate }}" style="width:100%; text-transform: uppercase"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block d-none d-lg-block" id="content">
            <div>

                <nav-bar class="display-none" [full]="true" title="{{ 'COMMON.ANALYTICS' | translate }}"></nav-bar>

                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <campaign-summary></campaign-summary>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-right: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF" *ngIf="campaign.target !== 'free_products'">{{ 'CAMPAIGN.BUDGET.BUDGET_TEXT_1' | translate }}{{ preferencesManager.getPreferredCurrency().symbol }}{{ 'CAMPAIGN.BUDGET.BUDGET_TEXT_2' | translate }}</span>
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF" *ngIf="campaign.target === 'free_products'">{{ 'CAMPAIGN.BUDGET.BUDGET_TEXT_1' | translate }}{{ 'COMMON.PRODUCTS_PER_DAY' | translate }})</span>
                                <!--img style="width: 20px; height: 20px; margin-left: 6px; margin-top: 0px"
                                    src="./assets/images/icon-help.png"-->
                                <br>
                            </div>
                            <div class="col-12" style="margin-top: 20px">
                                <div class="chart-container d-xl-none">
                                    <canvas id="budget-chart-small" width="795" height="390"></canvas>
                                    
                                </div>
                                <div class="chart-container d-none d-xl-flex">
                                    <canvas id="budget-chart-big" width="795" height="590"></canvas>
                                   
                                </div>
                            </div>

                            <div class="col-12 text-lato text-medium"
                                        style="margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white"
                                        *ngIf="publications == undefined ||publications.length == 0">
                                        {{ 'CAMPAIGN.BUDGET.ERROR_NOT_ENOUGH_DATA' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-left: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{ 'CAMPAIGN.BUDGET.LOCATION_TEXT' | translate }}</span>
                                <!--img style="width: 20px; height: 20px; margin-left: 6px; margin-top: 0px" src="./assets/images/icon-help.png"-->
                            </div>
                            <div col="col-12" style="width: 100%; margin-top: 20px">
                                <div id="vmap" class="vmap" style="height: 265px;"></div>
                            </div>
                            <div class="col-12 text-lato text-medium"
                                        style="margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white"
                                        *ngIf="publications == undefined ||publications.length == 0">
                                        {{ 'CAMPAIGN.BUDGET.ERROR_NOT_ENOUGH_DATA' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-right: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9" style="height: 100%">
                            <div class="col-12 no-margin" style="height: fit-content">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{ 'Impressions (per week)' | translate }}</span>
                            </div>
                            <!--div class="col-3 no-margin" style="margin: auto; margin-top: 0px; margin-bottom: 0px">
                            </div-->
                            <div class="col-12" style="margin-top: 20px">
                                <div class="chart-container d-xl-none"
                                    style="display: flex; align-items: center; justify-content: center">
                                    <canvas id="analytics-chart-small" width="795" height="390"></canvas>
                                </div>
                                <div class="chart-container d-none d-xl-flex"
                                    style="display: flex; align-items: center; justify-content: center">
                                    <canvas id="analytics-chart-big" width="795" height="390"></canvas>
                                </div>
                            </div>
                            <div class="col-12 text-lato text-medium"
                                        style="margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white"
                                        *ngIf="publications == undefined ||publications.length == 0">
                                        {{ 'Used budget (€ per week)' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-right: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9" style="height: 100%">
                            <div class="col-12 no-margin" style="height: fit-content">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{ 'Interactions (per week)' | translate }}</span>
                            </div>
                            <!--div class="col-3 no-margin" style="margin: auto; margin-top: 0px; margin-bottom: 0px">
                            </div-->
                            <div class="col-12" style="margin-top: 20px">
                                <div class="chart-container d-xl-none"
                                    style="display: flex; align-items: center; justify-content: center">
                                    <canvas id="analytics-interactions-chart-small" width="795" height="390"></canvas>
                                </div>
                                <div class="chart-container d-none d-xl-flex"
                                    style="display: flex; align-items: center; justify-content: center">
                                    <canvas id="analytics-interactions-chart-big" width="795" height="390"></canvas>
                                </div>
                            </div>
                            <div class="col-12 text-lato text-medium"
                                        style="margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white"
                                        *ngIf="publications == undefined ||publications.length == 0">
                                        {{ 'CAMPAIGN.BUDGET.ERROR_NOT_ENOUGH_DATA' | translate }}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</div>


<modal campaignId="{{id}}"></modal>