import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { Router, ActivatedRoute } from '@angular/router'
import { StorageManagementService } from 'src/app/_services'
import { AlertService } from '../../../../_services/alert.service'

@Component({
  templateUrl: 'advertiser-opportunity-edit.component.html',
  styleUrls: ['./advertiser-opportunity-edit.component.scss'],
})
export class AdvertiserOpportunityEditComponent implements OnInit, OnDestroy {
  project: any
  offer: any
  isNew: boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private formBuilder: FormBuilder,
    private storageManagementService: StorageManagementService
  ) {
    this.isNew = this.route.snapshot.data.isNew || false
    this.offer = route.snapshot.data.offer

    let filtered = this.route.snapshot.data.user.body.projects.filter(
      (project) =>
        project._id === this.storageManagementService.getCurrentBrandspaceId()
    )

    if (filtered != undefined && filtered.length > 0) this.project = filtered[0]
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  addOpportunity() {}

  getTitle() {
    if (this.isNew) return 'OPPORTUNITIES.NEW_CAMPAIGN_TITLE'
    else return 'OPPORTUNITIES.EDIT_CAMPAIGN_TITLE'
  }
}
