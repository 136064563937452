import { Component, OnInit } from '@angular/core'

import { FormBuilder, FormGroup } from '@angular/forms'

import { ModalService } from './../../../../../../_services/modal.service'
import { CampaignReferentialService } from './../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from './../../../../../../_services/buffers/campaign.creation.buffer.service'

import { Network } from 'src/app/_models/referential/network.model'
import { CampaignType } from 'src/app/_models/referential/campaign_type.model'

@Component({
  selector: 'campaign-create-step-0',
  templateUrl: 'campaign-create-step-0.component.html',
  styleUrls: ['./campaign-create-step-0.component.scss'],
})
export class CampaignCreateStep0Component implements OnInit {
  newCampaignForm: FormGroup

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({
      title: [this.campaignCreationBufferService.campaignBeingCreated().title],
    })
  }

  selectTarget(target) {
    this.campaignCreationBufferService.setCampaignType(target)
  }

  updateTitle() {
    var candidate = this.newCampaignForm.controls.title.value

    if (candidate != undefined) {
      candidate = candidate.trim()
      this.campaignCreationBufferService.campaignBeingCreated().title = candidate
    }
  }

  onSubmitProject() {
    this.updateTitle()

    this.campaignCreationBufferService.moveForward()
  }

  toggleNetwork(network: Network) {
    this.campaignCreationBufferService.toggleNetwork(network)
  }

  getNetworks() {
    return this.campaignReferentialService.getNetworks()
  }

  getNetworkImage(network: Network) {
    let result = this.campaignCreationBufferService.isNetworkSelected(network)

    if (result == true) return network.img_selected
    else return network.img
  }

  getCampaignTypeImage(campaignType: CampaignType) {
    return campaignType.info ===
      this.campaignCreationBufferService.getCampaignType().info
      ? campaignType.img_selected
      : campaignType.img
  }

  activateSelectedBubbleClass(campaignType: CampaignType) {
    return (
      campaignType.info ===
      this.campaignCreationBufferService.getCampaignType().info
    )
  }
}
