import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-deal.component.html',
  styleUrls: ['./publisher-deal.component.scss'],
})
export class PublisherDealComponent
  implements OnInit, AfterViewInit, OnDestroy {
  deal: any

  displayModalBackground: boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.displayModalBackground = false
    this.deal = route.snapshot.data.deal
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  manageModalEvent(newValue) {
    this.displayModalBackground = newValue
  }
}
