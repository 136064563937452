let industries = [
  { key: 'accountancy', value: 'Accountancy' },
  { key: 'accessibility', value: 'Accessibility' },
  {
    key: 'advertising_public_relations',
    value: 'Advertising & Public Relations',
  },
  { key: 'aerospace', value: 'Aerospace' },
  { key: 'agriculture_farming', value: 'Agriculture & Farming' },
  { key: 'air_transport', value: 'Air Transport' },
  { key: 'artificial_intelligence', value: 'Artificial intelligence' },
  { key: 'arts', value: 'Arts' },
  { key: 'automotive', value: 'Automotive' },
  { key: 'beauty_fashion', value: 'Beauty & Fashion' },
  { key: 'big_data_cloud_computing', value: 'Big Data & Cloud Computing' },
  { key: 'blockchain', value: 'Blockchain' },
  { key: 'business_entrepreneurship', value: 'Business & Entrepreneurship' },
  { key: 'children_family', value: 'Children & Family' },
  { key: 'construction', value: 'Construction' },
  { key: 'defense', value: 'Defense' },
  { key: 'education', value: 'Education' },
  { key: 'electronics', value: 'Electronics' },
  { key: 'energy', value: 'Energy' },
  { key: 'entertainment', value: 'Entertainment' },
  { key: 'environment', value: 'Environment' },
  {
    key: 'financial_services_investment',
    value: 'Financial Services & Investment',
  },
  { key: 'healthcare', value: 'Healthcare' },
  { key: 'home_garden', value: 'Home & Garden' },
  { key: 'hospitality', value: 'Hospitality' },
  { key: 'human_resources', value: 'Human Resources' },
  { key: 'insurance_risk_management', value: 'Insurance & Risk Management' },
  { key: 'logistics_transport', value: 'Logistics & Transport' },
  { key: 'manufacturing', value: 'Manufacuring' },
  {
    key: 'marketing_business_development',
    value: 'Marketing & Business Development',
  },
  { key: 'media_news', value: 'Media & News' },
  { key: 'mining', value: 'Mining' },
  { key: 'movies_tv_shows', value: 'Movies & TV Shows' },
  { key: 'music', value: 'Music' },
  { key: 'personal_care_services', value: 'Personal Care Services' },
  { key: 'pets', value: 'Pets' },
  { key: 'pharmacy', value: 'Pharmacy' },
  { key: 'photography', value: 'Photography' },
  { key: 'productivity', value: 'Productivity' },
  { key: 'public_sector', value: 'Public Sector' },
  { key: 'restaurants_food_groceries', value: 'Restaurants, Food & Groceries' },
  { key: 'retail', value: 'Retail' },
  { key: 'sales', value: 'Sales' },
  { key: 'science_engineering', value: 'Science & Engineering' },
  { key: 'self_development', value: 'Self Development' },
  { key: 'software_programming', value: 'Software Programming' },
  { key: 'sports_fitness_yoga', value: 'Sports, Fitness & Yoga' },
  { key: 'telecommunication', value: 'Telecommunication' },
  { key: 'travel_tourism', value: 'Travel & Tourism' },
  { key: 'video_games', value: 'Video Games' },
]

export class IndustryHelper {
  static getIndustries() {
    return industries
  }

  static getPopularIndustries() {
    return [
      { key: 'artificial_intelligence', value: 'Artificial intelligence' },
      { key: 'big_data_cloud_computing', value: 'Big Data & Cloud Computing' },
      {
        key: 'business_entrepreneurship',
        value: 'Business & Entrepreneurship',
      },
      {
        key: 'marketing_business_development',
        value: 'Marketing & Business Development',
      },
      { key: 'productivity', value: 'Productivity' },
      { key: 'sales', value: 'Sales' },
      { key: 'science_engineering', value: 'Science & Engineering' },
      { key: 'software_programming', value: 'Software Programming' },
    ]
  }

  static getIndustryText(key) {
    let result = ''

    for (var i = 0; i < industries.length; i++) {
      if (industries[i].key === key) {
        result = industries[i].value
        break
      }
    }

    return result
  }
}
