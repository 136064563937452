import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { APIService } from 'src/app/_services'
import { PublishersTasksAPIService } from '../api/publishers.tasks.api.service'
import * as moment from 'moment'
import { EditableTask } from 'src/app/_models/task/editable.task.model'
import { Task } from 'src/app/_models/task/task.model'

import { TaskSocketService } from '../sockets/tasks.socket.service'

@Injectable()
export class TaskSynchronisationService {
  //  private editableTasks = new BehaviorSubject<EditableTask[]>([])
  //  private editedTask = new BehaviorSubject<EditableTask>(undefined)

  private deals = new BehaviorSubject<any[]>([])
  private brands = new BehaviorSubject<any[]>([])

  constructor(
    private apiService: APIService,
    private tasksAPIService: PublishersTasksAPIService,
    private taskSocketService: TaskSocketService
  ) {}

  disconnect() {
    this.taskSocketService.disconnect()
  }

  async updateEditableTasks(tasks: any[]) {
    let editableTasks = []
    for (let i = 0; i < tasks.length; i++) {
      let editableTask = new EditableTask()
      editableTask.task = Task.buildTask(tasks[i])
      editableTasks.push(editableTask)
    }

    this.getEditableTasks().next(editableTasks)
    await this.taskSocketService.joinSocketTasks(
      this.getEditableTasks().getValue()
    )
  }

  getEditableTasks(): BehaviorSubject<EditableTask[]> {
    return this.taskSocketService.getEditableTasks()
  }

  getEditedTask(): BehaviorSubject<EditableTask> {
    return this.taskSocketService.getEditedTask()
  }

  updateDeals(deals: any[]) {
    this.deals.next(deals)
  }

  getDeals(): BehaviorSubject<any[]> {
    return this.deals
  }

  updateBrands(brands: any[]) {
    this.brands.next(brands)
  }

  getBrands(): BehaviorSubject<any> {
    return this.brands
  }

  public async editTask({ taskId, step }) {
    if (taskId != undefined && taskId !== '') {
      let editedTask = this.getEditableTasks()
        .getValue()
        .filter((object) => {
          return object.taskId === taskId
        })
        .reduce((acc, object) => {
          return object
        })
      editedTask.step = step
      this.getEditedTask().next(editedTask)
      await this.synchronize()
    } else {
      let editedTask = new EditableTask()
      editedTask.step = step
      this.getEditedTask().next(editedTask)
    }
  }

  updateField(field, value) {
    this.getEditedTask().getValue()[field] = value
    this.getEditedTask().next(this.getEditedTask().getValue())
  }

  buildDataFromOveriddenValues(task: EditableTask) {
    let data = {
      name: task.name,
      description: task.description,
      dueDate: task.dueDate,
      priority: task.priority,
      assignee: task.assignee,
      deal: task.task.deal,
      step: task.step,
      status: task.status,
    }

    return data
  }

  async synchronize() {
    await this.taskSocketService.synchronize()
  }

  async deleteTask(taskId) {
    try {
      let res = await this.tasksAPIService.deleteTask(
        await this.getPublisherId(),
        taskId
      )
      // Check if currentTask exist id taskArraySub and delete item
      for (var i = this.getEditableTasks().getValue().length - 1; i >= 0; i--) {
        if (this.getEditableTasks().getValue()[i].taskId === taskId) {
          this.getEditableTasks().getValue().splice(i, 1)
          this.getEditableTasks().next(this.getEditableTasks().getValue())
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async getPublisherId() {
    let user = await this.apiService.getMyProfile()
    return user?.body?.publisher?._id
  }

  async getPublisher() {
    let user = await this.apiService.getMyProfile()
    return user?.body?.user?.infos
  }

  filterBrands() {
    let brands = []
    this.getEditableTasks().subscribe((tasks) => {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].brand !== undefined) {
          brands.push(tasks[i].brand)
          const unique = Array.from(new Set(brands.map((a) => a.id))).map(
            (id) => {
              return brands.find((a) => a.id === id)
            }
          )
          brands = unique
        }
      }
    })
    return brands
  }
}
