<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Spoken languages</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addSpokenLanguage()"></add-button>
        <edit-button
          class="edit-language"
          (click)="updateSpokenLanguage()"
          *ngIf="getNumberOfSpokenLanguages() > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center text-darkgrey spoken-language-section">
      <div class="no-language" *ngIf="getNumberOfSpokenLanguages() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>Share the languages you can speak</div>
        <div></div>
        <tiny-cta
          (click)="addSpokenLanguage()"
          value="ADD YOUR FIRST LANGUAGE"
        ></tiny-cta>
      </div>
    </div>
    <div
      class="row center text-darkgrey spoken-language-section"
      *ngFor="let language of getSpokenLanguages()"
    >
      <div class="col-12 text-medium text-bold no-padding">
        {{ getCorrespondingLanguageName(language.code) }}
      </div>
      <div class="col-12 text-small no-padding">
        {{ getCorrespondingStatusText(language.status) }}
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddLanguage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddLanguage"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddLanguageTitle">
                    {{ 'Add a language' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="spokenLanguagesForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Language name</div>
                  <div class="input-value container-field">
                    <input
                      class="input-field autocomplete-field"
                      type="text"
                      placeholder="Language name"
                      name="spokenLanguage"
                      formControlName="spokenLanguage"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let language of filteredOptions | async"
                        [value]="language"
                      >
                        {{ language }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Language proficiency</div>
                  <div class="input-value container-field">
                    <select
                      name="languageProficiency"
                      formControlName="languageProficiency"
                      id="language-proficiency-select"
                      class="custom-select content-width"
                    >
                      <option value="">-- Please choose an option --</option>
                      <option
                        [value]="status.key"
                        *ngFor="let status of languageStatuses"
                      >
                        {{ status.text | translate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your spoken language have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your spoken language was not added, please try again
              </div>
              <div class="text-pink text-xxsmall" *ngIf="proficiency == false">
                You have to select a language proficiency
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="saveChanges()"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateLanguage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateLanguage"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato"
                    id="modalUpdateLanguageTitle"
                  >
                    {{ 'Delete language' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner language-edit-container">
            <form [formGroup]="spokenLanguagesForm">
              <div class="row center padding-language-section">
                <span
                  class="text-lato text-small text-darkgrey languages-section"
                  *ngFor="let language of getSpokenLanguages()"
                >
                  <span class="language-field"
                    >{{ getCorrespondingLanguageName(language.code) }} -
                    {{ getCorrespondingStatusText(language.status) }}
                    <span
                      class="icon-delete"
                      (click)="deleteSpokenLanguage(language)"
                      >x</span
                    ></span
                  >
                </span>
              </div>
            </form>
            <div
              class="text-darkgrey text-xxsmall"
              *ngIf="successfullyRemoved == true"
            >
              Your spoken language have been successfully removed
            </div>
            <div
              class="text-pink text-xxsmall"
              *ngIf="successfullyRemoved == false"
            >
              Your spoken language was not removed, please try again
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
