import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { APIService, PublishersProfileAPIService } from 'src/app/_services'
import { DateHelper } from '../../../../../_helpers/date.helpers'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  templateUrl:
    'publisher-profile-professional-profile-educations.component.html',
  selector: 'publisher-profile-professional-profile-educations',
  styleUrls: [
    './publisher-profile-professional-profile-educations.component.scss',
  ],
})
export class PublisherProfileProfessionalProfileEducationsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  profileDataForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showAskConfirmation: boolean

  informations: any[]
  information: any
  informationId: any
  selectedData: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  // search bar autocomplete
  degreeControl = new FormControl()
  degrees: string[] = ['License', 'Master', 'Master2']
  filteredOptions: Observable<string[]>

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initCertificationForm()
    this.initValues()
    this.showAskConfirmation = false

    this.filteredOptions = this.degreeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    )
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    let toReturn = this.degrees.filter(
      (degrees) => degrees.toLowerCase().indexOf(filterValue) === 0
    )
    return toReturn
  }

  initCertificationForm() {
    this.profileDataForm = this.formBuilder.group({
      schoolNameForm: this.formBuilder.group({
        schoolName: '',
      }),
      fieldOfStudyForm: this.formBuilder.group({
        fieldOfStudy: '',
      }),
      startDate: [undefined],
      endDate: [undefined],
      degreeForm: this.formBuilder.group({
        degree: '',
      }),
      activitiesForm: this.formBuilder.group({
        activities: '',
      }),
      notesForm: this.formBuilder.group({
        notes: '',
      }),
    })
  }

  initValues() {
    this.getInformations()
  }

  ngOnDestroy() {}

  hasEducations() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.educations != undefined
      )
    } catch (e) {
      return false
    }
  }

  getInformations() {
    if (this.hasEducations() == true)
      return this.publisher.publisher.professionalProfile.educations
    else return undefined
  }

  getNumberOfInformations() {
    let objects = this.getInformations()
    if (objects) return objects.length
    return 0
  }

  getStartDate(information) {
    let startDate = DateHelper.convertDoubleToReadableDate(
      information.timePeriod.startDate
    )
    return startDate
  }

  getEndDate(information) {
    if (information.timePeriod.endDate != undefined) {
      var endDate = DateHelper.convertDoubleToReadableDate(
        information.timePeriod.endDate
      )
      return 'End Date ' + endDate
    } else {
      endDate = 'In Progress'
      return endDate
    }
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let schoolNameField = this.readTextValue(
        this.profileDataForm.controls.schoolNameForm['controls'].schoolName
          .value
      )

      let fieldOfStudyField = this.readTextValue(
        this.profileDataForm.controls.fieldOfStudyForm['controls'].fieldOfStudy
          .value
      )

      let startDateField
      if (this.profileDataForm.controls.startDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('Start date field can not be empty')
      } else {
        startDateField = new Date(
          this.profileDataForm.controls.startDate.value
        ).getTime()
      }

      let endDateField
      if (this.profileDataForm.controls.endDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('End date field can not be empty')
      } else {
        endDateField = new Date(
          this.profileDataForm.controls.endDate.value
        ).getTime()
      }

      let degreeField = this.readTextValue(
        this.profileDataForm.controls.degreeForm['controls'].degree.value
      )

      let activitiesField = this.readTextValue(
        this.profileDataForm.controls.activitiesForm['controls'].activities
          .value
      )

      let notesField = this.readTextValue(
        this.profileDataForm.controls.notesForm['controls'].notes.value
      )

      if (
        schoolNameField != undefined &&
        this.profileDataForm.controls.schoolNameForm['controls'].schoolName
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('School name field can not be empty')
      }

      if (
        fieldOfStudyField != undefined &&
        this.profileDataForm.controls.fieldOfStudyForm['controls'].fieldOfStudy
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Study title field can not be empty')
      }

      if (
        degreeField != undefined &&
        this.profileDataForm.controls.degreeForm['controls'].degree.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Degree field can not be empty')
      }

      if (
        activitiesField != undefined &&
        this.profileDataForm.controls.activitiesForm['controls'].activities
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Activities field can not be empty')
      }

      if (
        notesField != undefined &&
        this.profileDataForm.controls.notesForm['controls'].notes.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Notes field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          schoolName: schoolNameField,
          fieldOfStudy: fieldOfStudyField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          degree: degreeField,
          activities: activitiesField,
          notes: notesField,
        }

        let res = await this.apiService.addEducationInformation(
          this.publisher.publisher._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteInformation(information) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      this.informationId = information._id

      let res = await this.apiService.deleteEducationInformation(
        this.publisher.publisher._id,
        this.informationId
      )

      if (res.status == 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyRemoved = true
        this.loading = false
        this.closeModal()
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async updateChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let schoolNameField = this.readTextValue(
        this.profileDataForm.controls.schoolNameForm['controls'].schoolName
          .value
      )

      let fieldOfStudyField = this.readTextValue(
        this.profileDataForm.controls.fieldOfStudyForm['controls'].fieldOfStudy
          .value
      )

      let startDateField = new Date(
        this.profileDataForm.controls.startDate.value
      ).getTime()

      let endDateField = new Date(
        this.profileDataForm.controls.endDate.value
      ).getTime()

      let degreeField = this.readTextValue(
        this.profileDataForm.controls.degreeForm['controls'].degree.value
      )

      let activitiesField = this.readTextValue(
        this.profileDataForm.controls.activitiesForm['controls'].activities
          .value
      )

      let notesField = this.readTextValue(
        this.profileDataForm.controls.notesForm['controls'].notes.value
      )

      if (
        schoolNameField != undefined &&
        this.profileDataForm.controls.schoolNameForm['controls'].schoolName
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        fieldOfStudyField != undefined &&
        this.profileDataForm.controls.fieldOfStudyForm['controls'].fieldOfStudy
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        degreeField != undefined &&
        this.profileDataForm.controls.degreeForm['controls'].degree.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        activitiesField != undefined &&
        this.profileDataForm.controls.activitiesForm['controls'].activities
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        notesField != undefined &&
        this.profileDataForm.controls.notesForm['controls'].notes.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          school: schoolNameField,
          fieldOfStudy: fieldOfStudyField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          degree: degreeField,
          activities: activitiesField,
          notes: notesField,
        }

        let res = await this.apiService.updateEducationInformation(
          this.publisher.publisher._id,
          this.selectedData._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addInformation() {
    this.selectedData = undefined
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateInformation(information) {
    this.selectedData = information
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  saveOrUpdateInformation() {
    if (this.selectedData != undefined) this.updateChanges()
    else this.saveChanges()
    this.closeModal()
  }

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  closeConfirmationModal() {
    try {
      this.showAskConfirmation = false
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  changeStartDate(event) {
    this.profileDataForm.controls.startDate.setValue(event.target.value)
  }

  changeEndDate(event) {
    this.profileDataForm.controls.endDate.setValue(event.target.value)
  }

  updateModal() {
    if (this.selectedData == undefined) {
      // ADD
      this.prepareModalForAddition()
    } else {
      // UPDATE
      this.prepareModalForUpdate()
    }
  }

  confirmation() {
    this.showAskConfirmation = true
    document.body.style.overflow = 'hidden'
  }

  prepareModalForAddition() {
    this.initCertificationForm()
  }

  prepareModalForUpdate() {
    this.profileDataForm = this.formBuilder.group({
      schoolNameForm: this.formBuilder.group({
        schoolName: this.selectedData.schoolName,
      }),
      fieldOfStudyForm: this.formBuilder.group({
        fieldOfStudy: this.selectedData.fieldOfStudy,
      }),
      startDate: [new Date(this.selectedData.timePeriod.startDate)],
      endDate: [new Date(this.selectedData.timePeriod.endDate)],
      degreeForm: this.formBuilder.group({
        degree: this.selectedData.degree,
      }),
      activitiesForm: this.formBuilder.group({
        activities: this.selectedData.activities,
      }),
      notesForm: this.formBuilder.group({
        notes: this.selectedData.notes,
      }),
    })
  }

  clearInputField() {
    this.initCertificationForm()
  }
}
