import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../../_services/api.service'

@Component({
  templateUrl: 'password_recovery.component.html',
  styleUrls: ['./password_recovery.component.scss'],
})
export class PasswordRecoveryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  submitted = false
  recoveryForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasSent = false

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService
  ) {
    this.hasSent = false
  }

  ngOnInit() {
    this.hasSent = false
    this.recoveryForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f() {
    return this.recoveryForm.controls
  }

  async onSubmit() {
    try {
      this.submitted = true

      // stop here if form is invalid
      if (this.recoveryForm.invalid) {
        return
      }

      this.loading = true

      var res = await this.apiService.resetPassword(this.f.email.value)
      if (res.status == 200) {
        this.loading = false
        this.hasSent = true
      }
      if (res.status == 400) {
        this.loading = false
        this.hasSent = false
      }
    } catch (e) {
      this.loading = false
      this.hasSent = false
    }
  }
}
