<div class="col-12 d-block" id="modal-menu" *ngIf="showModal == true">
  <div
    class="modal fade modal-overlay"
    id="modalMakeAnOffer"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalMakeAnOffer"
    aria-hidden="false"
    (click)="closeModal()"
    style="overflow-x: hidden; overflow-y: auto; margin-top: 40px; z-index: 100"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      style="max-width: calc(80% - 75px)"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content modal-container">
        <div class="modal-header">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h5 class="modal-title text-lato" id="modalMakeAnOfferTitle">
                  {{ 'Make an offer' | translate }}
                </h5>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="section-container-inner">
          <form [formGroup]="offerForm">
            <div class="row">
              <div class="col-6">
                <div class="text-small text-darkgrey">
                  <div class="input-title">Campaign</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field input-no-border"
                      type="text"
                      placeholder="Campaign"
                      name="campaignName"
                      relatedControlName="campaignName"
                      formGroupName="campaignNameForm"
                      [parentGroup]="offerForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div class="col-6 field" style="margin-bottom: 0px">
                <div
                  class="section-body text-small text-darkgrey"
                  style="margin-bottom: 0px"
                >
                  <div class="input-title">Offer valid until</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey container-field separator-width"
                    style="margin-bottom: 0px"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerExpiryDate"
                      name="expiryDate"
                      formControlName="expiryDate"
                      (dateChange)="changeExpiryDate($event)"
                      autocomplete="off"
                      [min]="minOpportunityDate"
                      [max]="maxOpportunityDate"
                    />

                    <mat-placeholder class="placeholder"
                      >Expiry date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerExpiryDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerExpiryDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 text-lato">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title">Campaign Actions</div>

                  <div class="input-value container-field">
                    <form [formGroup]="newActionForm">
                      <select
                        id="action"
                        class="form-control input-text text-lato input-text text-darkgrey form-select separator-width"
                        name="action"
                        formControlName="action"
                      >
                        <option value="" disabled selected>
                          {{ 'Select a campaign action to add' | translate }}
                        </option>
                        <option
                          *ngFor="let action of availableActions"
                          [value]="action.key"
                        >
                          {{ action.value | translate }}
                        </option>
                      </select>
                      <div class="text-right">
                        <span
                          class="btn text-lato text-bold card-title no-margin circle-plus"
                          (click)="addCampaignAction()"
                          >+</span
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                class="col-12 text-lato"
                *ngFor="let action of actions; let index = index"
              >
                <form [formGroup]="action.form">
                  <div class="section-body text-small text-darkgrey">
                    <div class="row">
                      <div class="col-12 text-lato">
                        <div class="input-title">
                          Action #{{ index + 1 }} - {{ action.type.value }}
                          <div
                            class="text-right"
                            style="position: absolute; top: -30px; right: 0"
                          >
                            <span
                              class="btn text-lato text-bold card-title no-margin circle-plus"
                              (click)="removeCampaignAction(action)"
                              >-</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-6 text-lato">
                        <div class="input-value container-field">
                          <div class="input-title" style="margin-bottom: 7px">
                            Unit price - Currency: Euro (EUR) €
                          </div>
                          <div class="input-value container-field">
                            <div class="input-field">
                              <div>
                                <input
                                  type="number"
                                  placeholder="Amount"
                                  class="input-text text-lato text-medium text-darkgrey"
                                  id="amount"
                                  name="amount"
                                  formControlName="amount"
                                  autocomplete="off"
                                  (change)="updateAmount($event)"
                                  step="any"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-lato">
                        <div class="input-value container-field">
                          <div class="input-title" style="margin-bottom: 7px">
                            Frequency
                          </div>
                          <div class="input-value container-field">
                            <select
                              id="action"
                              class="form-control input-text text-lato input-text text-darkgrey form-select"
                              name="frequency"
                              formControlName="frequency"
                              (change)="changeActionFrequency(action, index)"
                            >
                              <option
                                *ngFor="let item of frequencies"
                                [value]="item.key"
                              >
                                {{ item.value }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 field"
                        style="margin-bottom: 0px"
                        *ngIf="actionHasRange(action)"
                      >
                        <div
                          class="section-body text-small text-darkgrey"
                          style="margin-bottom: 0px"
                        >
                          <div class="input-title">Action date range</div>
                          <mat-form-field
                            class="input-text text-lato text-darkgrey container-field separator-width"
                            style="margin-bottom: 0px"
                          >
                            <!--input
                              class="text-xsmall"
                              matInput
                              [matDatepicker]="rangePicker"
                              name="actionDateRange"
                              formControlName="actionDateRange"
                              (dateChange)="changeActionDateRange($event)"
                              autocomplete="off"
                              [min]="minOpportunityDate"
                              [max]="maxOpportunityDate"
                            /-->

                            <mat-date-range-input
                              [rangePicker]="rangePicker"
                              [min]="minOpportunityDate"
                              [max]="maxOpportunityDate"
                            >
                              <input
                                matStartDate
                                name="actionDateRangeStart"
                                formControlName="actionDateRangeStart"
                              />
                              <input
                                matEndDate
                                name="actionDateRangeEnd"
                                formControlName="actionDateRangeEnd"
                              />
                            </mat-date-range-input>

                            <mat-placeholder class="placeholder"
                              >Select a date range</mat-placeholder
                            >
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="rangePicker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                            </mat-date-range-picker>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="input-title margin-top-20">Description</div>
                    <div class="input-value container-field">
                      <input-field
                        class="input-field"
                        type="text"
                        placeholder="Add important details like features, options, or measurements"
                        name="description"
                        relatedControlName="description"
                        formGroupName="descriptionForm"
                        [parentGroup]="action.form"
                      >
                      </input-field>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 text-lato">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title">Notes</div>
                  <div class="input-value container-field">
                    <input-text-area
                      class="input-field"
                      type="text"
                      placeholder="Notes"
                      name="description"
                      relatedControlName="description"
                      formGroupName="descriptionForm"
                      [parentGroup]="offerForm"
                    >
                    </input-text-area>
                  </div>
                </div>
              </div>
              <!--div class="col-6 text-lato">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title" style="margin-bottom: 7px">
                    Amount (€)
                  </div>
                  <div class="input-value container-field">
                    <div class="input-field">
                      <div>
                        <input
                          type="number"
                          placeholder="Amount"
                          class="input-text text-lato text-medium text-darkgrey"
                          id="amount"
                          name="amount"
                          formControlName="amount"
                          autocomplete="off"
                          (change)="updateAmount($event)"
                          step="any"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 field">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title">Valid until</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey container-field"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerExpiryDate"
                      name="expiryDate"
                      formControlName="expiryDate"
                      (dateChange)="changeExpiryDate($event)"
                      autocomplete="off"
                    />

                    <mat-placeholder class="placeholder"
                      >Expiry date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerExpiryDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerExpiryDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div-->
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                class="btn btn-secondary two-btn-left-radius text-lato btn-text"
                (click)="saveDraft()"
              >
                {{ 'SAVE AS DRAFT' | translate }}
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-primary two-btn-right-radius text-lato btn-text"
                (click)="sendOffer()"
              >
                {{ 'SEND OFFER' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
