<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="dashboard-title">
      <publisher-section-intro
        title="{{ 'PROFILE' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="dashboard-profile-wrapper">
      <dashboard-profile [state]="state"></dashboard-profile>
    </div>
    <!--div id="dashboard-activities-wrapper">
      <dashboard-activities></dashboard-activities>
    </div-->
    <!--div id="dashboard-forecast-wrapper">
      <dashboard-forecast></dashboard-forecast>
    </div-->
    <div id="dashboard-title">
      <publisher-section-intro
        title="{{ 'DEALS' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="dashboard-forecast-wrapper">
      <dashboard-forecast></dashboard-forecast>
    </div>
    <div id="dashboard-title">
      <publisher-section-intro
        title="{{ 'CALENDAR' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="dashboard-metrics-wrapper">
      <dashboard-metrics></dashboard-metrics>
    </div>
    <!--div id="dashboard-wrappers">
      <dashboard-ending-deal></dashboard-ending-deal>
    </div>
    <div id="dashboard-wrappers">
      <dashboard-begining-deal></dashboard-begining-deal>
    </div-->
  </div>
</div>
<!--loader *ngIf="loading == true"></loader-->
