import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { Deal } from '../../_models/deal/deal.model'
import { PublishersDealsAPIService } from '../../_services/api/publishers.deals.api.service'

@Injectable()
export class PublishersDealsResolver implements Resolve<Deal[]> {
  constructor(
    private apiService: APIService,
    private dealApiService: PublishersDealsAPIService
  ) {}
  async resolve(): Promise<Deal[]> {
    try {
      let user = await this.apiService.getMyProfile()
      let publisherId = user?.body?.publisher?._id
      let request = await this.dealApiService.getDeals(publisherId)
      if (request.body != undefined && request.body.deals != undefined)
        return request.body.deals
      return []
    } catch (e) {
      return []
    }
  }
}
