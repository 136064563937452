import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  templateUrl: 'deal-menu.component.html',
  selector: 'deal-menu',
  styleUrls: ['./deal-menu.component.scss'],
})
export class DealMenuComponent implements OnInit {
  selectedItem: string
  @Output() selectedMenuItemEvent = new EventEmitter<string>()

  constructor(private router: Router) {
    this.selectedItem = 'summary'
    this.selectedMenuItemEvent.emit(this.selectedItem)
  }

  async ngOnInit() {}

  ngOnDestroy() {}

  select(value) {
    this.selectedItem = value
    this.selectedMenuItemEvent.emit(this.selectedItem)
  }
}
