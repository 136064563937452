import { Component, OnInit, Injectable } from '@angular/core'

import { ModalService } from '../../../_services'

import { CampaignCreationBufferService } from '../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  templateUrl: 'modal-alert.component.html',
  selector: 'modal-alert',
  styleUrls: ['./modal-alert.component.scss'],
})
@Injectable()
export class ModalAlertComponent implements OnInit {
  campaign: any

  campaignName: any
  account: any
  budget: any
  numberOfFreeProducts: number
  productDescription: string
  compensation: string
  date_start: any
  date_end: any
  difference: any
  do: any
  dont: any
  brief: any
  understood: any
  toShow: any
  showSomething: any
  understoodDate: any

  constructor(
    private modalService: ModalService,
    /*private campaignCreate : CampaignCreateComponent*/
    public campaignCreationService: CampaignCreationBufferService
  ) {
    this.campaignName = undefined
    this.account = undefined
    this.budget = undefined
    this.compensation = undefined
    this.numberOfFreeProducts = 0
    this.compensation = undefined
    this.date_start = undefined
    this.date_end = undefined
    this.difference = undefined
    this.do = undefined
    this.dont = undefined
    this.brief = undefined
    this.understood = false
    this.toShow = undefined
    this.showSomething = false
    this.understoodDate = false
  }

  ngOnInit() {
    //this.syncWithService()

    this.modalService.getModals().subscribe(
      (x) => {
        //console.log('Observer got a next value z: ' + x)
        //this.syncWithService()
        this.toShow = x
        this.showSomething = !this.showSomething
      },
      (err) => {
        //console.error('Observer got an error: ' + err)
      },
      () => {
        //console.log('Observer got a complete notification')
      }
    )
  }

  ngOnDestroy() {}

  closeModalAlert() {
    this.modalService.toggleModal('alert')
  }

  closeModal() {
    this.modalService.toggleModal(this.toShow)
  }

  iUnderstand() {
    this.understood = true
    this.modalService.sendEvent('validate')
  }

  iUnderstandDate() {
    this.understoodDate = true
  }

  showUnderstood() {}

  stopPropagation(event) {
    event.stopPropagation()
  }

  /*syncWithService() {
    this.campaignName = this.campaignCreationService.getValue().campaign_name
    this.account = this.campaignCreationService.getValue().account
    this.budget = this.campaignCreationService.getValue().budget
    this.compensation = this.campaignCreationService.getValue().compensation
    this.numberOfFreeProducts = this.campaignCreationService.getValue().numberOfFreeProducts
    this.productDescription = this.campaignCreationService.getValue().productDescription
    this.date_start = this.campaignCreationService.getValue().date_start
    this.date_end = this.campaignCreationService.getValue().date_end
    this.do = this.campaignCreationService.getValue().do
    this.dont = this.campaignCreationService.getValue().dont
    this.brief = this.campaignCreationService.getValue().brief
    this.difference = this.campaignCreationService.getValue().difference
    this.step = this.campaignCreationService.getStep()

    this.campaignCreationService.setUnderstoodDate(this.understoodDate)
  }*/
}
