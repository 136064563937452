import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { Deal } from '../../_models/deal/deal.model'
import { PublishersDealsAPIService } from '../../_services/api/publishers.deals.api.service'

@Injectable()
export class PublishersDealResolver implements Resolve<Deal> {
  constructor(
    private apiService: APIService,
    private dealApiService: PublishersDealsAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<Deal> {
    try {
      let user = await this.apiService.getMyProfile()
      let publisherId = user?.body?.publisher?._id
      const dealId = route.params['dealId'] || route.params['offerId']
      let request = await this.dealApiService.getDeal(publisherId, dealId)
      if (request.body != undefined && request.body.deal != undefined)
        return request.body.deal
      return undefined
    } catch (e) {
      return undefined
    }
  }
}
