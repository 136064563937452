import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { ModalService } from '../../../_services/modal.service'

@Component({
  templateUrl: 'modal-billing.component.html',
  selector: 'modal-billing',
  styleUrls: ['./modal-billing.component.scss'],
})
export class ModalBillingComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('profile')
  }
}
