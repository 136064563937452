<div class="top-menu">
  <div class="text-lato text-medium container-top-menu">
    <img src="./assets/images/icon_copyright.png" />{{
      'MY_BRAND_EDIT.TITLE' | translate
    }}
    <span class="text-right">
      <span class="top-text text-lato text-grey text-small"
        ><a (click)="stepBack()"
          ><span
            class="btn btn-outline-primary left-margin text-button top-menu-close"
            >{{ 'COMMON.TEXT_CLOSE_WINDOW' | translate }}</span
          ></a
        ></span
      >
    </span>
  </div>
</div>
<div style="position: sticky; top: 64px; z-index: 99">
  <alerts [alerts]="alertService.getAlerts()"></alerts>
</div>
<div class="content-width-backgroundcolor">
  <div class="content-page">
    <form class="margin-v-39" [formGroup]="myBrandForm" style="margin-top: 0px">
      <div class="display-block col-direction section-container">
        <div class="col-12 col-md-3 margin-section-right">
          <section-title
            title="MY_BRAND_EDIT.TITLE"
            description="MY_BRAND_EDIT.INSTRUCTIONS"
          ></section-title>
        </div>
        <div class="col-12 col-md-9 margin-section-left">
          <div class="row form-container">
            <div class="col-8 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.LOGO' | translate }}
              <p
                class="text-lato text-small text-lightgrey form-container-section-right-picture"
              >
                {{ 'MY_BRAND_EDIT.PICTURE_INSTRUCTIONS_LINE_0' | translate }}
                {{ 'MY_BRAND_EDIT.PICTURE_INSTRUCTIONS_LINE_1' | translate }}
              </p>
            </div>
            <div class="col-4 text-lato form-container-section-right">
              <img
                class="no-picture"
                [src]="brand_logo_url"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == false"
              />
              <img
                class="content-width"
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == true && selectedFile == undefined"
              />
              <img
                class="no-picture"
                [src]="imageSrc"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == true && selectedFile != undefined"
              />
            </div>
          </div>
          <div class="row display-none">
            <div class="col-12 display-none">
              <input
                class="display-none"
                type="file"
                (change)="onFileChanged($event)"
                accept="image/*"
                #fileInput
              />
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.TITLE' | translate }}
              <p
                class="text-lato text-small text-pink"
                *ngIf="hasBrandNameError == true"
              >
                {{ 'MY_BRAND_EDIT.ERROR_BRANDNAME_EMPTY' | translate }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <input-field
                class="input-text"
                type="text"
                placeholder="{{
                  'MY_BRAND_EDIT.BRAND_NAME_PLACEHOLDER' | translate
                }}"
                name="brandName"
                relatedControlName="brandName"
                formGroupName="brandNameForm"
                [parentGroup]="myBrandForm"
              >
              </input-field>
            </div>
          </div>
          <!---div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.INDUSTRY' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <div class="separator-field">
                <select
                  name="industry"
                  formControlName="industry"
                  id="industry-select"
                  class="custom-select content-width"
                >
                  <option value="">-- Please choose an option --</option>
                  <option
                    [value]="industry.key"
                    *ngFor="let industry of industries"
                  >
                    {{ industry.value }}
                  </option>
                </select>
              </div>
            </div>
          </div-->
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.INDUSTRIES' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <div class="row">
                <div class="form-group col-md-10 text-center">
                  <select
                    name="newIndustry"
                    formControlName="newIndustry"
                    id="industry-select"
                    class="form-control custom-select input-text text-lato text-darkgrey font-size-14"
                  >
                    <option value="">-- Please choose an option --</option>
                    <option
                      [value]="industry.key"
                      *ngFor="let industry of industries"
                    >
                      {{ industry.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-2 text-center">
                  <span
                    class="btn text-lato text-bold card-title no-margin circle-plus"
                    (click)="addIndustry()"
                    >+</span
                  >
                </div>
                <div class="col-12">
                  <div
                    *ngIf="currentIndustries?.length > 0"
                    class="container-location"
                  >
                    <div *ngFor="let industry of currentIndustries">
                      <div class="flex-location">
                        <div class="col-auto col-location">
                          <div class="center-location">
                            <div class="text-lato text-location">
                              {{ getIndustry(industry.key) }}
                            </div>
                            <div>
                              <input
                                class="btn btn-primary btn-big text-lato text-bold remove-button"
                                value="x"
                                (click)="removeIndustry(industry)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.BASELINE' | translate }}
              <p class="text-lato text-small text-lightgrey form-subtitle-text">
                {{ 'MY_BRAND_EDIT.BASELINE_INSTRUCTIONS' | translate }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <div class="separator-field-no-padding">
                <textarea
                  class="input-text"
                  name="baseline"
                  placeholder="{{
                    'MY_BRAND_EDIT.BASELINE_PLACEHOLDER' | translate
                  }}"
                  formControlName="baseline"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.DESCRIPTION' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <!--div class="separator-field-no-padding">
                <textarea
                  class="input-text"
                  name="description"
                  placeholder="{{
                    'MY_BRAND_EDIT.DESCRIPTION_PLACEHOLDER' | translate
                  }}"
                  formControlName="description"
                  rows="8"
                ></textarea>
              </div-->
              <div class="separator-field-no-padding">
                <quill-editor
                  formControlName="description"
                  placeholder="{{
                    'MY_BRAND_EDIT.DESCRIPTION_PLACEHOLDER' | translate
                  }}"
                  (onContentChanged)="updateDescription()"
                  class="ql-container"
                  [modules]="getEditorModules('description')"
                  [preserveWhitespace]="true"
                  (onEditorCreated)="initQuill($event, 'description')"
                >
                </quill-editor>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.CORE_VALUES' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <!--div class="separator-field-no-padding">
                <textarea
                  class="input-text"
                  name="values"
                  placeholder="{{
                    'MY_BRAND_EDIT.CORE_VALUES_PLACEHOLDER' | translate
                  }}"
                  formControlName="values"
                  rows="8"
                ></textarea>
              </div-->
              <div class="separator-field-no-padding">
                <quill-editor
                  formControlName="values"
                  placeholder="{{
                    'MY_BRAND_EDIT.CORE_VALUES_PLACEHOLDER' | translate
                  }}"
                  class="ql-container"
                  [modules]="getEditorModules('values')"
                  [preserveWhitespace]="true"
                  (onEditorCreated)="initQuill($event, 'values')"
                >
                </quill-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="display-block col-direction section-container">
        <div class="col-12 col-md-3 margin-section-right">
          <section-title
            title="{{ 'MY_BRAND_EDIT.PRODUCTS_SERVICES_TITLE' | translate }}"
            description="{{
              'MY_BRAND_EDIT.PRODUCTS_SERVICES_DESCRIPTION' | translate
            }}"
          ></section-title>
        </div>
        <div class="col-12 col-md-9 margin-section-left">
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.PRODUCTS_SERVICES_SOLUTION_TITLE' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <!--div class="separator-field-no-padding">
                <textarea
                  class="input-text"
                  name="products"
                  placeholder="{{
                    'MY_BRAND_EDIT.PRODUCTS_SERVICES_SOLUTION_PLACEHOLDER'
                      | translate
                  }}"
                  formControlName="products"
                  rows="8"
                ></textarea>
              </div-->

              <div class="separator-field-no-padding">
                <quill-editor
                  id="products"
                  formControlName="products"
                  placeholder="{{
                    'MY_BRAND_EDIT.PRODUCTS_SERVICES_SOLUTION_PLACEHOLDER'
                      | translate
                  }}"
                  class="ql-container"
                  [modules]="getEditorModules('products')"
                  [preserveWhitespace]="true"
                  (onEditorCreated)="initQuill($event, 'products')"
                >
                </quill-editor>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.COMMERCIAL_TARGET_TITLE' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <!--div class="separator-field-no-padding">
                <textarea
                  class="input-text"
                  name="salesTarget"
                  placeholder="{{
                    'MY_BRAND_EDIT.COMMERCIAL_TARGET_PLACEHOLDER' | translate
                  }}"
                  formControlName="salesTarget"
                  rows="8"
                ></textarea>
              </div-->

              <div class="separator-field-no-padding">
                <quill-editor
                  formControlName="salesTarget"
                  placeholder="{{
                    'MY_BRAND_EDIT.COMMERCIAL_TARGET_PLACEHOLDER' | translate
                  }}"
                  class="ql-container"
                  [modules]="getEditorModules('salesTarget')"
                  [preserveWhitespace]="true"
                  (onEditorCreated)="initQuill($event, 'salesTarget')"
                >
                </quill-editor>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.MARKET_LOCATION_TITLE' | translate }}
            </div>
            <div class="col-8 text-lato container-field container-field">
              <div class="row">
                <div class="form-group col-md-5 text-center">
                  <select
                    id="country"
                    class="form-control custom-select input-text text-lato text-darkgrey font-size-14"
                    name="country"
                    formControlName="country"
                    (change)="resetState()"
                  >
                    <option value="" disabled selected>
                      {{ 'CAMPAIGN.CREATE.STEP_1_COUNTRY' | translate }}
                    </option>
                    <!--option>France</option>
                                          <option>Belgique</option-->
                    <option
                      *ngFor="let country of countries"
                      [value]="country.id"
                    >
                      {{ country.name | translate }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-5 text-center">
                  <select
                    id="country"
                    class="form-control custom-select input-text text-lato text-darkgrey font-size-14"
                    name="area"
                    formControlName="area"
                    (change)="changeCountry()"
                  >
                    <option value="" disabled selected>
                      {{ 'CAMPAIGN.CREATE.STEP_1_AREA' | translate }}
                    </option>
                    <!--option>Hauts de France</option>
                                          <option>Ile de France</option-->
                    <option
                      *ngFor="let state of getStates()"
                      [value]="state.id"
                    >
                      {{ state.name | translate }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-2 text-center">
                  <span
                    class="btn text-lato text-bold card-title no-margin circle-plus"
                    (click)="addLocation()"
                    >+</span
                  >
                </div>
                <div class="col-12">
                  <div *ngIf="locations.length > 0" class="container-location">
                    <div *ngFor="let location of locations">
                      <div class="flex-location">
                        <div class="col-auto col-location">
                          <div class="center-location">
                            <div class="text-lato text-location">
                              {{ location.country | translate }},
                              {{ location.area | translate }}
                            </div>
                            <div>
                              <input
                                class="btn btn-primary btn-big text-lato text-bold remove-button"
                                value="x"
                                (click)="removeLocation(location)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="display-block col-direction section-container">
        <div class="col-12 col-md-3 margin-section-right">
          <section-title
            title="MY_BRAND_EDIT.ON_THE_WEB"
            description="MY_BRAND_EDIT.INSTRUCTIONS"
          ></section-title>
        </div>
        <div class="col-12 col-md-9 margin-section-left">
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.CORPORATE_LINKS' | translate }}
            </div>

            <div class="col-8 text-lato container-field-no-padding">
              <div class="container">
                <div class="row" *ngIf="links.length == 0">
                  <div class="col-12 text-lato no-link-text">
                    {{ 'MY_BRAND_EDIT.NO_LINK' | translate }}
                  </div>
                </div>
                <table class="table" *ngIf="links.length > 0">
                  <thead>
                    <tr>
                      <th scope="col" class="text-lato text-small table-box">
                        {{ 'MY_BRAND_EDIT.NETWORK' | translate }}
                      </th>
                      <th scope="col" class="text-lato text-small table-box">
                        {{ 'MY_BRAND_EDIT.ACCOUNT' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-small top-menu-close"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let link of links">
                      <td scope="row" class="text-lato text-xsmall align-table">
                        <img
                          class="img-width"
                          src="./assets/images/socials-web.png"
                          *ngIf="link.type === 'web'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-twitter.png"
                          *ngIf="link.type === 'twitter'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-facebook.png"
                          *ngIf="link.type === 'facebook'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-instagram.png"
                          *ngIf="link.type === 'instagram'"
                        />
                        <img
                          class="img-width-margin"
                          src="./assets/images/socials-youtube.png"
                          *ngIf="link.type === 'youtube'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-linkedin.png"
                          *ngIf="link.type === 'linkedin'"
                        />
                      </td>
                      <td class="text-lato text-xsmall align-table">
                        <a target="_blank">{{ link.link }}</a>
                      </td>
                      <td
                        class="text-lato text-xsmall remove-link"
                        (click)="removeWebLink(link)"
                      >
                        {{ 'MY_BRAND_EDIT.REMOVE_LINK' | translate }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 text-lato add-link-text">
                    {{ 'MY_BRAND_EDIT.ADD_LINK' | translate }}
                  </div>
                  <div class="col-4 text-lato choose-link">
                    <div>
                      <select
                        class="custom-select my-1 mr-sm-2"
                        id="typeOfLink"
                        formControlName="typeOfLink"
                      >
                        <option value="choose" selected>
                          {{ 'MY_BRAND_EDIT.CHOOSE' | translate }}
                        </option>
                        <option value="web">Web</option>
                        <option value="twitter">Twitter</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="youtube">Youtube</option>
                        <option value="linkedin">Linkedin</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 text-lato container-field">
                    <input-field
                      class="input-text"
                      type="text"
                      placeholder="{{
                        'MY_BRAND_EDIT.LINK_PLACEHOLDER' | translate
                      }}"
                      name="link"
                      relatedControlName="link"
                      formGroupName="linkForm"
                      [parentGroup]="myBrandForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-2 text-lato link-container">
                    <div class="text-right">
                      <span
                        class="btn text-lato text-bold card-title no-margin circle-plus"
                        (click)="addWebLink()"
                        >+</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.ADVOCATES' | translate }}
            </div>

            <div class="col-8 text-lato container-field-no-padding">
              <div class="container">
                <div class="row" *ngIf="links.length == 0">
                  <div class="col-12 text-lato no-link-text">
                    {{ 'MY_BRAND_EDIT.NO_LINK' | translate }}
                  </div>
                </div>
                <table class="table" *ngIf="links.length > 0">
                  <thead>
                    <tr>
                      <th scope="col" class="text-lato text-xsmall table-box">
                        {{ 'MY_BRAND_EDIT.NETWORK' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall table-box table-box"
                      >
                        {{ 'MY_BRAND_EDIT.ACCOUNT' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let advocate of advocates">
                      <td scope="row" class="text-lato text-xsmall align-table">
                        <img
                          class="img-width"
                          src="./assets/images/socials-web.png"
                          *ngIf="advocate.type === 'web'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-twitter.png"
                          *ngIf="advocate.type === 'twitter'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-facebook.png"
                          *ngIf="advocate.type === 'facebook'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-instagram.png"
                          *ngIf="advocate.type === 'instagram'"
                        />
                        <img
                          class="img-width-margin"
                          src="./assets/images/socials-youtube.png"
                          *ngIf="advocate.type === 'youtube'"
                        />
                        <img
                          class="img-width"
                          src="./assets/images/socials-linkedin.png"
                          *ngIf="advocate.type === 'linkedin'"
                        />
                      </td>
                      <td class="text-lato text-xsmall align-table">
                        <a target="_blank">{{ advocate.link }}</a>
                      </td>
                      <td
                        class="text-lato text-xsmall remove-link"
                        (click)="removeAdvocate(advocate)"
                      >
                        {{ 'MY_BRAND_EDIT.REMOVE_LINK' | translate }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 text-lato add-link-text">
                    {{ 'MY_BRAND_EDIT.ADD_LINK' | translate }}
                  </div>
                  <div class="col-4 text-lato choose-link">
                    <div>
                      <select
                        class="custom-select my-1 mr-sm-2"
                        id="typeOfLink"
                        formControlName="typeOfAdvocateLink"
                      >
                        <option value="choose" selected>
                          {{ 'MY_BRAND_EDIT.CHOOSE' | translate }}
                        </option>
                        <option value="web">Web</option>
                        <option value="twitter">Twitter</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="youtube">Youtube</option>
                        <option value="linkedin">Linkedin</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 text-lato container-field">
                    <input-field
                      class="input-text"
                      type="text"
                      placeholder="{{
                        'MY_BRAND_EDIT.LINK_PLACEHOLDER' | translate
                      }}"
                      name="link"
                      relatedControlName="advocateLink"
                      formGroupName="advocateLinkForm"
                      [parentGroup]="myBrandForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-2 text-lato link-container">
                    <div class="text-right">
                      <span
                        class="btn text-lato text-bold card-title no-margin circle-plus"
                        (click)="addAdvocate()"
                        >+</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="display-block col-direction section-container">
        <div class="col-12 col-md-3 margin-section-right">
          <section-title
            title="{{ 'MY_BRAND_EDIT.MARKETING_MATERIALS' | translate }}"
            description="{{
              'MY_BRAND_EDIT.MARKETING_MATERIALS_DESCRIPTION' | translate
            }}"
          ></section-title>
        </div>
        <div class="col-12 col-md-9 margin-section-left">
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.MARKETING_MATERIALS' | translate }}
            </div>

            <div class="col-8 text-lato container-field-no-padding">
              <div class="container">
                <div class="row" *ngIf="resources.length == 0">
                  <div class="col-12 text-lato no-resources">
                    {{ 'MY_BRAND_EDIT.NO_MARKETING_MATERIAL' | translate }}
                  </div>
                </div>
                <table class="table" *ngIf="resources.length > 0">
                  <thead>
                    <tr>
                      <th scope="col" class="text-lato text-xsmall table-box">
                        {{ 'MY_BRAND_EDIT.FILE_TITLE' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      >
                        {{ 'MY_BRAND_EDIT.FILE_AVAILABILITY' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      ></th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let resource of resources">
                      <td class="text-lato text-xsmall align-table">
                        <a target="_blank">{{ resource.name }}</a>
                      </td>
                      <td class="text-lato text-xsmall align-table">
                        <span *ngIf="resource.isPublic == true">{{
                          'MY_BRAND_EDIT.PUBLIC_MARKETING_MATERIAL' | translate
                        }}</span>
                        <span *ngIf="resource.isPublic == false">{{
                          'MY_BRAND_EDIT.PRIVATE_MARKETING_MATERIAL' | translate
                        }}</span>
                      </td>
                      <td class="text-lato text-xsmall remove-link">
                        <a
                          download="filename"
                          target="_blank"
                          [href]="resource.link"
                        >
                          {{
                            'MY_BRAND_EDIT.DOWNLOAD_MARKETING_MATERIAL'
                              | translate
                          }}
                        </a>
                      </td>
                      <td
                        class="text-lato text-xsmall remove-link"
                        (click)="removeResource(resource.social_id)"
                      >
                        {{
                          'MY_BRAND_EDIT.REMOVE_MARKETING_MATERIAL' | translate
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 text-lato add-doc">
                    {{ 'MY_BRAND_EDIT.ADD_DOCUMENT' | translate }}
                  </div>

                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-text"
                      type="text"
                      placeholder="{{
                        'MY_BRAND_EDIT.DOCUMENT_NAME' | translate
                      }}"
                      name="resourceName"
                      relatedControlName="resourceName"
                      formGroupName="resourceNameForm"
                      [parentGroup]="myBrandForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-5 text-lato container-field">
                    <div>
                      <input
                        type="file"
                        name="myFile"
                        class="max-width"
                        (change)="onresourceChanged($event)"
                        formControlName="resourceFile"
                        accept="image/*, application/pdf"
                      />
                    </div>
                  </div>
                  <div class="col-2 text-lato container-field">
                    <div class="form-checkbox">
                      <span class="bigcheck">
                        <label class="bigcheck">
                          <input
                            type="checkbox"
                            class="bigcheck"
                            name="resourceIsPublic"
                            formControlName="resourceIsPublic"
                          />
                          <span class="bigcheck-target"></span>
                          Public
                        </label>
                      </span>
                    </div>
                  </div>
                  <div class="col-2 text-lato link-container">
                    <div class="text-right">
                      <span
                        class="btn text-lato text-bold card-title no-margin circle-plus"
                        (click)="addResource()"
                        >+</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'MY_BRAND_EDIT.LINKS' | translate }}
            </div>

            <div class="col-8 text-lato container-field-no-padding">
              <div class="container">
                <div class="row" *ngIf="externalLinks.length == 0">
                  <div class="col-12 text-lato no-resources">
                    {{ 'MY_BRAND_EDIT.NO_EXTERNAL_LINK' | translate }}
                  </div>
                </div>
                <table class="table" *ngIf="externalLinks.length > 0">
                  <thead>
                    <tr>
                      <th scope="col" class="text-lato text-xsmall table-box">
                        {{ 'MY_BRAND_EDIT.LINK_TITLE' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      >
                        {{ 'MY_BRAND_EDIT.FILE_AVAILABILITY' | translate }}
                      </th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      ></th>
                      <th
                        scope="col"
                        class="text-lato text-xsmall top-menu-close"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let link of externalLinks">
                      <td class="text-lato text-xsmall align-table">
                        <a target="_blank">{{ link.name }}</a>
                      </td>
                      <td class="text-lato text-xsmall align-table">
                        <span *ngIf="link.isPublic == true">{{
                          'MY_BRAND_EDIT.PUBLIC_MARKETING_MATERIAL' | translate
                        }}</span>
                        <span *ngIf="link.isPublic == false">{{
                          'MY_BRAND_EDIT.PRIVATE_MARKETING_MATERIAL' | translate
                        }}</span>
                      </td>
                      <td
                        class="text-lato text-xsmall remove-link"
                        (click)="removeExternalLink(link)"
                      >
                        {{
                          'MY_BRAND_EDIT.REMOVE_MARKETING_MATERIAL' | translate
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-12 text-lato add-doc">
                    {{ 'MY_BRAND_EDIT.ADD_LINK' | translate }}
                  </div>

                  <div class="col-4 text-lato container-field">
                    <input-field
                      class="input-text"
                      type="text"
                      placeholder="{{
                        'MY_BRAND_EDIT.EXTERNAL_LINK_NAME' | translate
                      }}"
                      name="externalLinkName"
                      relatedControlName="externalLinkName"
                      formGroupName="externalLinkNameForm"
                      [parentGroup]="myBrandForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-4 text-lato container-field">
                    <input-field
                      class="input-text"
                      type="text"
                      placeholder="{{
                        'MY_BRAND_EDIT.EXTERNAL_LINK_VALUE' | translate
                      }}"
                      name="externalLink"
                      relatedControlName="externalLink"
                      formGroupName="externalLinkForm"
                      [parentGroup]="myBrandForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-2 text-lato container-field">
                    <div class="form-checkbox">
                      <span class="bigcheck">
                        <label class="bigcheck">
                          <input
                            type="checkbox"
                            class="bigcheck"
                            name="externalLinkIsPublic"
                            formControlName="externalLinkIsPublic"
                          />
                          <span class="bigcheck-target"></span>
                          Public
                        </label>
                      </span>
                    </div>
                  </div>
                  <div class="col-2 text-lato link-container">
                    <div class="text-right">
                      <span
                        class="btn text-lato text-bold card-title no-margin circle-plus"
                        (click)="addExternalLink()"
                        >+</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="container-section-btn">
    <div class="section-btn">
      <plain-button
        value="{{ 'MY_BRAND_EDIT.SAVE_BUTTON' | translate }}"
        (click)="onSubmit()"
      >
      </plain-button>
    </div>
  </div>
</div>
<loader *ngIf="loading == true"></loader>
