<div class="display-block col-direction section-container">
  <div
    class="max-w-80pt one-edge-shadow section-container-inner clickable"
    (click)="loadCampaignPage()"
  >
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-12 campaign-title text-pink text-bold text-large">
              <img
                class="brand-logo-thumbnail"
                [src]="opportunity._brand?.brand?.brand_logo_path"
                *ngIf="opportunity._brand?.brand?.brand_logo_path != undefined"
              />
              <img
                class="brand-logo-thumbnail"
                [src]="'./assets/images/fns_logo_head.png'"
                *ngIf="opportunity._brand?.brand?.brand_logo_path == undefined"
              />
              {{ getCampaign().title }}
              <span
                class="badge badge-primary"
                [ngClass]="{
                  'badge-primary': getCampaignStatus() === 'LIVE',
                  'badge-disabled':
                    getCampaignStatus() === 'CANCELLED' ||
                    getCampaignStatus() === 'DRAFT'
                }"
                >{{ getCampaignStatus() }}</span
              >
              <br />
              <span class="brand text-small text-darkgrey">
                By {{ opportunity._brand?.brand.name }}</span
              >

              <div class="right-stuck">
                <span
                  *ngFor="let network of getCampaign().networks"
                  class="network-logo"
                >
                  <img
                    [src]="getNetworkThumbnailImagePath(network.identifier)"
                  />
                </span>
                <tiny-cta
                  class="clickable"
                  value="DETAILS"
                  (click)="loadCampaignPage()"
                ></tiny-cta>
                <span>
                  <img
                    class="icon-favorite"
                    [src]="
                      isFavorite()
                        ? './assets/images/icons/icon_star_full_pink.png'
                        : './assets/images/icons/icon_star_empty_pink.png'
                    "
                    (click)="toggleFavorite()"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <i
            ><b>{{ getCampaignDateSentence() }}</b></i
          >
        </div>

        <div class="section-body text-small text-darkgrey">
          <div
            class="campaign-brief"
            [innerHTML]="getCampaign().brief.text"
          ></div>
        </div>
        <div class="section-footer text-xsmall text-darkgrey">
          <span class="badge action badge-disabled badge-bigger"
            >Blog Writing</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
