import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersTasksAPIService {
  constructor(private http: HttpClient) {}

  async getTasks(publisherId) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/tasks/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async postTask(publisherId, taskJson) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/tasks/'

    var res = await this.http
      .post<any>(url, taskJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateTask(publisherId, taskId, taskJson) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/tasks/' + taskId

    var res = await this.http
      .post<any>(url, taskJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteTask(publisherId, taskId) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/tasks/' + taskId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async postComment(publisherId, taskId, commentJson) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/tasks/' +
      taskId +
      '/comments/'

    var res = await this.http
      .post<any>(url, commentJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateComment(publisherId, taskId, commentId, commentJson) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/tasks/' +
      taskId +
      '/comments/' +
      commentId

    var res = await this.http
      .post<any>(url, commentJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteComment(publisherId, taskId, commentId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/tasks/' +
      taskId +
      '/comments/' +
      commentId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
