<div class="box one-edge-shadow" (click)="loadDealPage()">
  <div class="box-title text-small">
    <div>
      <img class="brand-image" [src]="deal._brand.brand.brand_logo_path" />
    </div>
    <div class="text-title">
      <span scroll="no" class="text-pink">{{ deal.proposal.name }}</span
      ><span scroll="no">By {{ deal._brand.brand.name }}</span>
    </div>
  </div>

  <!--div class="opportunity-date text-xxsmall">
    Date of proposal:
    {{ convertDoubleToReadableDate(deal.proposal.proposalDate) }}
  </div-->
  <div class="deal-quote">
    <div class="box-subtitle">Quote</div>
    <div class="box-content-quote text-darkgrey text-small">
      {{ computeQuote() }}
    </div>
  </div>
  <div class="deal-description">
    <div class="box-subtitle">Description</div>
    <div class="box-content box-content-description text-darkgrey text-small">
      {{ deal.proposal.description }}
    </div>
  </div>

  <div class="footer">
    <div class="deal-date text-xxsmall">
      Proposal valid until:
      {{ convertDoubleToReadableDate(deal.proposal.expiryDate) }}
    </div>
    <div class="message-img">
      <span
        class="badge badge-position"
        [ngClass]="{
          'badge-selected': deal.status === 'SELECTED',
          'badge-draft': deal.status === 'DRAFT',
          'badge-proposed': deal.status === 'PROPOSED',
          'badge-negociated': deal.status === 'NEGOCIATED',
          'badge-accepted': deal.status === 'ACCEPTED',
          'badge-finished': deal.status === 'FINISHED',
          'badge-refused': deal.status === 'REFUSED'
        }"
        style="text-transform: uppercase"
      >
        {{ deal.status }}
      </span>
    </div>
  </div>
</div>
