<div
  class="col-12 d-flex flex-column v-align full-height"
  id="onboarding-container"
>
  <div class="text-center center margin-h-public-form">
    <div>
      <div style="margin-bottom: auto; margin-top: auto">
        <img style="width: 240px" src="./assets/images/icon-pipe-created.png" />
      </div>
      <h1
        class="text-lato text-bold text-big text-darkgrey line-height-115"
        style="
          font-size: 38px;
          margin-bottom: 31px;
          margin-left: 26.5px;
          margin-right: 26.5px;
          margin-top: 67px;
        "
      >
        {{ 'SIGNUP.TITLE_LINE_1_STEP_4' | translate }}
      </h1>

      <div class="row">
        <div class="col-12" style="margin-top: 31px">
          <plain-button
            id="access-btn"
            value="{{ 'SIGNUP.TEXT_BUTTON_STEP_4' | translate }}"
            (click)="goToDashboard()"
          >
          </plain-button>
        </div>
      </div>
    </div>
  </div>
</div>
