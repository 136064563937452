<div style="width: 100%" (click)="stopPropagation($event)">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-settings.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
            {{ 'MODAL_ACCOUNTS.ADD_SOCIAL_ACCOUNT' | translate }}
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png"
                    style="width: 16px" (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato text-bold"
                        style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                        {{ 'MODAL_ACCOUNTS.INSTRUCTIONS_TITLE' | translate }}
                    </div>
                    <div class="col-12 text-lato"
                        style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                        {{ 'MODAL_ACCOUNTS.INSTRUCTIONS_TEXT' | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; align-self: center; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12">
                <div class="text-center center margin-h">
                    <div>
                        <div class="margin-v-39">
                            <div class="row">
                                <div class="col-12">
                                    <div
                                        style="display: flex; justify-content: center; flex-direction: row; margin-top: 60px; margin-left: 100px; margin-right: 100px">

                                        <div
                                            style="margin-left: 26.5px; margin-right: 26.5px; margin-bottom: 26.5px; width: fit-content">
                                            <div class="logo" [class.selected]="accounts.instagram.selected == true"
                                                (click)="connectInstagram()">
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px"
                                                    y="0px" viewBox="0 0 19.9 19.9"
                                                    style="enable-background:new 0 0 19.9 19.9;" xml:space="preserve">
                                                    <style type="text/css">
                                                        .st0 {
                                                            fill: none;
                                                        }
                                                    </style>
                                                    <defs>
                                                    </defs>
                                                    <path class="st0" d="M14.4,0H5.5C2.5,0,0,2.5,0,5.5v8.9c0,3,2.5,5.5,5.5,5.5h8.9c3,0,5.5-2.5,5.5-5.5V5.5C19.9,2.5,17.4,0,14.4,0z
                                                            M18.1,14.4c0,2.1-1.7,3.7-3.7,3.7H5.5c-2.1,0-3.7-1.7-3.7-3.7V5.5c0-2.1,1.7-3.7,3.7-3.7h8.9c2.1,0,3.7,1.7,3.7,3.7L18.1,14.4
                                                           L18.1,14.4z M9.9,4.8c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C15.1,7.1,12.8,4.8,9.9,4.8z M9.9,13.3
                                                           c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C13.3,11.8,11.8,13.3,9.9,13.3z M15.3,3.3
                                                           c-0.7,0-1.3,0.6-1.3,1.3c0,0.3,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9
                                                           c0-0.3-0.1-0.7-0.4-0.9C16,3.5,15.6,3.3,15.3,3.3z" />
                                                </svg>
                                            </div>
                                            <div>
                                                <p class="text-lato text-small text-darkgrey" style="margin-top: 15px"
                                                    *ngIf="accounts.instagram.infos.account != undefined">
                                                    {{accounts.instagram.infos.account}}</p>
                                                <p class="text-lato text-small text-darkgrey" style="margin-top: 15px"
                                                    *ngIf="accounts.instagram.infos.account == undefined">{{ 'MODAL_ACCOUNTS.ACCOUNT_NOT_CONNECTED' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                        <input class="btn btn-primary btn-big text-lato text-bold" type="submit"
                                        value="{{ 'MODAL_ACCOUNTS.MODAL_CLOSE' | translate }}" (click)="closeModal()">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>