import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService, StorageManagementService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'

import { CurrencyHelper } from '../../../../_helpers/referential/currency.helper'

@Component({
  templateUrl: 'advertiser-billing.component.html',
  styleUrls: ['./advertiser-billing.component.scss'],
})
export class AdvertiserBillingComponent
  implements OnInit, AfterViewInit, OnDestroy {
  project: any

  loading = false

  constructor(
    private route: ActivatedRoute,
    public alertService: AlertService,
    private storageManagementService: StorageManagementService
  ) {
    let filtered = this.route.snapshot.data.user.body.projects.filter(
      (project) =>
        project._id === this.storageManagementService.getCurrentBrandspaceId()
    )

    if (filtered != undefined && filtered.length > 0) this.project = filtered[0]
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {}
}
