<div class="row" style="max-width: 100%; align-self: flex-start">
  <div id="team-selector" class="col-12 left-row">
    <div class="box-margin text-lato" style="color: white; width: 100%">
      <div
        class="cursor"
        style="display: flex; flex-direction: row; position: relative"
        (click)="toggleMenu()"
      >
        <div style="margin-top: auto; margin-bottom: auto">
          <img
            style="
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 50%;
              border-style: solid;
              border-width: 1px;
              border-color: #999999;
            "
            [src]="picUrl"
          />
        </div>
        <div
          style="
            margin-top: auto;
            margin-right: 0px;
            padding-left: 8px;
            padding-right: 8px;
            max-width: calc(100% - 68px);
            width: calc(100% - 68px);
          "
        >
          <div class="text-xsmall">{{ 'COMMON.WORKSPACE' | translate }}</div>
          <div
            class="text-bold text-large"
            style="white-space: normal; overflow: hidden"
          >
            {{ name }}
          </div>
        </div>
        <div style="margin-bottom: 0px">
          <img
            style="width: 17px; border-radius: 50%"
            src="./assets/images/icon-arrow-down-white.png"
          />
        </div>
      </div>

      <div
        class="row text-lato text-bold modal-down"
        style="
          position: relative;
          margin-top: 20px;
          z-index: 10;
          margin-right: -25px;
          overflow-y: scroll;
        "
        *ngIf="showMenu == true"
      >
        <div class="col-12 project cursor">
          <div (click)="toNewProject()" style="margin-right: 10px">
            <div
              style="margin-top: auto; margin-bottom: auto; margin-right: 14px"
            >
              <span
                class="text-medium text-bold"
                style="
                  color: #ff006c;
                  background-color: white;
                  width: 24px;
                  height: 24px;
                  padding-left: 6px;
                  padding-right: 6px;
                  border-radius: 50%;
                "
                >+</span
              >
            </div>
            <div
              class="text-small"
              style="
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 68px);
                width: calc(100% - 68px);
              "
            >
              {{ 'LEFT_MENU.CREATE_NEW_WORKSPACE' | translate }}
            </div>
            <div>
              <img
                style="width: 24px; border-radius: 50%"
                src="./assets/images/icon-arrow-right-white.png"
              />
            </div>
          </div>
        </div>
        <div
          class="col-12 project"
          *ngFor="let project of projects; let i = index"
        >
          <div (click)="toAProject(project._id)" style="margin-right: 10px">
            <div>
              <img
                class="cursor"
                style="
                  width: 24px;
                  height: 24px;
                  object-fit: cover;
                  border-radius: 50%;
                  margin-right: 14px;
                "
                [src]="project.pictureURL"
              />
            </div>
            <div
              class="text-small cursor"
              style="
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 68px);
                width: calc(100% - 68px);
              "
            >
              {{ project.title }}
            </div>
            <div>
              <img
                class="cursor"
                style="width: 24px; border-radius: 50%"
                src="./assets/images/icon-arrow-right.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="dashboard-selector"
    class="col-12 left-row box-margin cursor"
    style="margin-top: 25px"
    (click)="goToDashboard()"
  >
    <div
      class="box box-bg text-lato text-bold text-small"
      style="color: white; display: flex; flex-direction: row"
    >
      <div>
        <img
          style="width: 24px; border-radius: 50%; margin-right: 14px"
          src="./assets/images/icon-star.png"
        />
      </div>
      <div
        class="text-small"
        style="
          margin-top: auto;
          margin-bottom: auto;
          max-width: calc(100% - 68px);
          width: calc(100% - 68px);
        "
      >
        {{ 'LEFT_MENU.DASHBOARD' | translate }}
      </div>
      <div style="opacity: 0.4">
        <img
          style="width: 24px; border-radius: 50%"
          src="./assets/images/icon-arrow-right-white.png"
        />
      </div>
    </div>
  </div>

  <!--div id="timeline-selector" class="col-12 left-row box-margin cursor" style="margin-top: 25px"
        (click)="goToTimeline()">
        <div class="box box-bg text-lato text-bold text-small" style="color: white; display: flex; flex-direction: row">
            <div>
                <img style="width: 24px; border-radius: 50%; margin-right: 14px" src="./assets/images/icon-star.png">
            </div>
            <div class="text-small"
                style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                Timeline
            </div>
            <div style="opacity: 0.4">
                <img style="width: 24px; border-radius: 50%" src="./assets/images/icon-arrow-right-white.png">
            </div>
        </div>
    </div-->

  <div
    id="brand-selector"
    class="col-12 left-row box-margin cursor"
    style="margin-top: 5px"
    (click)="goToBrand()"
  >
    <div
      class="box box-bg text-lato text-bold text-small"
      style="color: white; display: flex; flex-direction: row"
    >
      <div>
        <img
          style="width: 24px; border-radius: 50%; margin-right: 14px"
          src="./assets/images/icon_copyright.png"
        />
      </div>
      <div
        class="text-small"
        style="
          margin-top: auto;
          margin-bottom: auto;
          max-width: calc(100% - 68px);
          width: calc(100% - 68px);
        "
      >
        {{ 'LEFT_MENU.MY_BRAND' | translate }}
      </div>
      <div style="opacity: 0.4">
        <img
          style="width: 24px; border-radius: 50%"
          src="./assets/images/icon-arrow-right-white.png"
        />
      </div>
    </div>
  </div>
  <!---div id="brand-authority-selector" class="col-12 left-row box-margin cursor" style="margin-top: 5px"
        (click)="goToBrandAuthority()">
        <div class="box box-bg text-lato text-bold text-small" style="color: white; display: flex; flex-direction: row">
            <div>
                <img style="width: 24px; border-radius: 50%; margin-right: 14px" src="./assets/images/icon_brand_authority_menu.png">
            </div>
            <div class="text-small"
                style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                {{ 'Digital Brand Authority' | translate }}
            </div>
            <div style="opacity: 0.4">
                <img style="width: 24px; border-radius: 50%" src="./assets/images/icon-arrow-right-white.png">
            </div>
        </div>
    </div-->
  <div
    id="settings-selector"
    class="col-12 left-row box-margin cursor"
    style="margin-top: 5px"
    (click)="goToBilling()"
    *ngIf="isAdmin == true"
  >
    <div
      class="box box-bg text-lato text-bold text-small"
      style="color: white; display: flex; flex-direction: row"
    >
      <div>
        <img
          style="width: 24px; border-radius: 50%; margin-right: 14px"
          src="./assets/images/icon-bills.png"
        />
      </div>
      <div
        class="text-small"
        style="
          margin-top: auto;
          margin-bottom: auto;
          max-width: calc(100% - 68px);
          width: calc(100% - 68px);
        "
      >
        {{ 'LEFT_MENU.BILLING' | translate }}
      </div>
      <div style="opacity: 0.4">
        <img
          style="width: 24px; border-radius: 50%"
          src="./assets/images/icon-arrow-right-white.png"
        />
      </div>
    </div>
  </div>
  <div
    id="settings-selector"
    class="col-12 left-row box-margin cursor"
    style="margin-top: 5px"
    (click)="goToSettings()"
  >
    <div
      class="box box-bg text-lato text-bold text-small"
      style="color: white; display: flex; flex-direction: row"
    >
      <div>
        <img
          style="width: 24px; border-radius: 50%; margin-right: 14px"
          src="./assets/images/icon-project-settings-bold.png"
        />
      </div>
      <div
        class="text-small"
        style="
          margin-top: auto;
          margin-bottom: auto;
          max-width: calc(100% - 68px);
          width: calc(100% - 68px);
        "
      >
        {{ 'LEFT_MENU.SETTINGS' | translate }}
      </div>
      <div style="opacity: 0.4">
        <img
          style="width: 24px; border-radius: 50%"
          src="./assets/images/icon-arrow-right-white.png"
        />
      </div>
    </div>
  </div>
  <!--div id="settings-selector" class="col-12 left-row box-margin cursor" style="margin-top: 5px"
        (click)="goToSettings()">
        <div class="box box-bg text-lato text-bold text-small" style="color: white; display: flex; flex-direction: row">
            <div>
                <img style="width: 24px; border-radius: 50%; margin-right: 14px" src="./assets/images/icon-project-settings-bold.png">
            </div>
            <div class="text-small"
                style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                {{ 'LEFT_MENU.SETTINGS' | translate }}
            </div>
            <div style="opacity: 0.4">
                <img style="width: 24px; border-radius: 50%" src="./assets/images/icon-arrow-right-white.png">
            </div>
        </div>
    </div-->
  <div id="number-of-pipes" class="col-12 left-row">
    <div
      class="box-margin text-lato text-small"
      style="color: white; padding-left: 15px"
    >
      {{ numberOfCampaigns() }} {{ 'COMMON.CAMPAIGN_S' | translate }}
    </div>
  </div>
  <div id="project-list" class="col-12 left-row box-margin">
    <div
      class="box box-bg text-lato"
      style="
        color: white;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
      "
    >
      <div
        (click)="createCampaign()"
        class="cursor"
        style="
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 16px;
          padding-right: 16px;
          background-color: #212029;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          display: flex;
          flex-direction: row;
        "
      >
        <div style="margin-top: auto; margin-bottom: auto">
          <img
            style="width: 25px; margin-right: 14px"
            src="./assets/images/icon-add.png"
          />
        </div>
        <div
          class="text-small text-bold"
          style="
            margin-top: auto;
            margin-bottom: auto;
            max-width: calc(100% - 61px);
            width: calc(100% - 61px);
            color: #ff006c;
          "
        >
          {{ 'LEFT_MENU.CREATE_NEW_CAMPAIGN' | translate }}
        </div>
        <div style="opacity: 1">
          <img
            style="width: 17px; margin-top: auto; margin-bottom: auto"
            src="./assets/images/icon-arrow-right.png"
          />
        </div>
      </div>
      <div
        *ngIf="campaigns == ''"
        style="
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 16px;
          padding-right: 16px;
          background-color: #212029;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          display: flex;
          flex-direction: row;
        "
      >
        <div
          class="text-xsmall"
          style="
            margin-top: auto;
            margin-bottom: auto;
            max-width: calc(100% - 28px);
            width: calc(100% - 28px);
          "
        >
          <form>
            <input
              type="text"
              placeholder="{{ 'LEFT_MENU.SEARCH' | translate }}"
              class="text-xsmall"
              style="
                color: white;
                background-color: transparent;
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 28px);
                width: calc(100% - 28px);
                border-width: 0px;
              "
              (input)="onSearchChange($event.target)"
            />
          </form>
        </div>
        <div style="opacity: 0.4">
          <img
            class="cursor"
            style="width: 17px"
            src="./assets/images/icon-search.png"
          />
        </div>
      </div>
      <div
        *ngIf="campaigns != ''"
        style="
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 16px;
          padding-right: 16px;
          background-color: #212029;
          display: flex;
          flex-direction: row;
        "
      >
        <div
          class="text-xsmall"
          style="
            margin-top: auto;
            margin-bottom: auto;
            max-width: calc(100% - 28px);
            width: calc(100% - 28px);
          "
        >
          <form>
            <input
              type="text"
              placeholder="{{ 'LEFT_MENU.SEARCH' | translate }}"
              class="text-xsmall"
              style="
                color: white;
                background-color: transparent;
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 28px);
                width: calc(100% - 28px);
                border-width: 0px;
              "
              (input)="onSearchChange($event.target)"
            />
          </form>
        </div>
        <div style="opacity: 0.4">
          <img
            class="cursor"
            style="width: 17px"
            src="./assets/images/icon-search.png"
          />
        </div>
      </div>
      <div *ngFor="let campaign of getCampaigns(filter); let i = index">
        <div
          style="
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 16px;
            padding-right: 16px;
            border-color: rgba(255, 255, 255, 0.04);
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-top-style: solid;
            border-bottom-style: solid;
            display: flex;
            flex-direction: row;
          "
        >
          <div style="opacity: 0.4; margin-top: auto; margin-bottom: auto">
            <!--img style="width: 20px; margin-right: 14px" src="./assets/images/icon-twitter-white.png"-->
            <img
              style="width: 20px; margin-right: 14px"
              src="./assets/images/icon_audience_2_grey.png"
              *ngIf="campaign.target === 'audience_growth'"
            />
            <img
              style="width: 20px; margin-right: 14px"
              src="./assets/images/icon_handshake_grey.png"
              *ngIf="campaign.target === 'find_ambassadors'"
            />
            <img
              style="width: 20px; margin-right: 14px"
              src="./assets/images/icon_sales_grey.png"
              *ngIf="campaign.target === 'sales_boost'"
            />
          </div>
          <div
            (click)="goToBrief(campaign); showSubMenu(i, true)"
            class="text-small text-bold cursor"
            style="
              margin-top: auto;
              margin-bottom: auto;
              max-width: calc(100% - 68px);
              width: calc(100% - 68px);
            "
          >
            {{ campaign.title }}
            <!--span style="margin-left: 8px; margin-right: 8px; padding-left: 4px; padding-right: 4px; padding-top: 4px; padding-bottom: 4px; color: #ffad00; font-size: 9px; border-radius: 10px; border-color: #ffad00; border-width: 1px; border-style: solid"
                            *ngIf="pipeService.getNumberOfNotifications(pipe) > 0">{{pipeService.getNumberOfNotifications(pipe)}}</span-->
          </div>
          <div>
            <img
              style="width: 28px; margin-top: 5px"
              src="./assets/images/noun_dots.png"
            />
          </div>
          <div
            class="cursor"
            style="opacity: 0.4"
            *ngIf="
              getCampaignOptions(i) &&
              getCampaignOptions(i).visible == false &&
              getShowDropDown(i).visible == false
            "
            (click)="showSubMenu(i, true)"
          >
            <img
              style="width: 24px; margin-top: 6px"
              src="./assets/images/icon-expand-white.png"
            />
          </div>
          <div
            class="cursor"
            style="opacity: 0.4"
            *ngIf="
              (getCampaignOptions(i) &&
                getCampaignOptions(i).visible == true) ||
              getShowDropDown(i).visible == true
            "
            (click)="showSubMenu(i, false)"
          >
            <img
              style="width: 24px"
              src="./assets/images/icon-collapse-white.png"
            />
          </div>
        </div>
        <div
          style="
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 16px;
            padding-right: 16px;
            border-color: rgba(255, 255, 255, 0.04);
            border-top-width: 0px;
            border-bottom-width: 1px;
            border-top-style: solid;
            border-bottom-style: solid;
            display: flex;
            flex-direction: column;
            background-color: rgba(86, 87, 110, 0.25);
          "
          *ngIf="
            (getCampaignOptions(i) && getCampaignOptions(i).visible == true) ||
            getShowDropDown(i).visible == true
          "
        >
          <div
            (click)="goToBrief(campaign)"
            style="
              display: flex;
              flex-direction: row;
              padding-top: 2.5px;
              padding-bottom: 2.5px;
            "
          >
            <div style="margin-top: auto; margin-bottom: auto">
              <img
                class="cursor"
                style="width: 20px; margin-right: 14px"
                src="./assets/images/icon-audience.png"
              />
            </div>
            <div
              class="text-xsmall cursor"
              style="
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 28px);
                width: calc(100% - 28px);
              "
            >
              {{ 'COMMON.BRIEF' | translate }}
            </div>
          </div>
          <!--div (click)="goToScalers(campaign)"
                        style="display: flex; flex-direction: row; padding-top: 2.5px; padding-bottom: 2.5px">
                        <div style="margin-top: auto; margin-bottom: auto">
                            <img class="cursor" style="width: 20px; margin-right: 14px"
                                src="./assets/images/icon_scalers_menu.png">
                        </div>
                        <div class="text-xsmall cursor"
                            style="margin-top: auto; margin-bottom: auto; max-width: calc(100% - 28px); width: calc(100% - 28px)">
                            {{ 'Campaign Scalers' | translate }}
                        </div>
                    </div-->
          <div
            (click)="goToAnalytics(campaign)"
            style="
              display: flex;
              flex-direction: row;
              padding-top: 2.5px;
              padding-bottom: 2.5px;
            "
          >
            <div style="margin-top: auto; margin-bottom: auto">
              <img
                class="cursor"
                style="width: 20px; margin-right: 14px"
                src="./assets/images/icon_dollar.png"
              />
            </div>
            <div
              class="text-xsmall cursor"
              style="
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 28px);
                width: calc(100% - 28px);
              "
            >
              {{ 'COMMON.ANALYTICS' | translate }}
            </div>
          </div>
          <div
            (click)="goToPublications(campaign)"
            style="
              display: flex;
              flex-direction: row;
              padding-top: 2.5px;
              padding-bottom: 2.5px;
            "
          >
            <div style="margin-top: auto; margin-bottom: auto">
              <img
                class="cursor"
                style="width: 20px; margin-right: 14px"
                src="./assets/images/icon_photos.png"
              />
            </div>
            <div
              class="text-xsmall cursor"
              style="
                margin-top: auto;
                margin-bottom: auto;
                max-width: calc(100% - 28px);
                width: calc(100% - 28px);
              "
            >
              {{ 'COMMON.PUBLICATIONS' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
