import { environment } from '../../environments/environment'

import { Injectable } from '@angular/core'
import { SocketIoConfig, Socket } from 'ngx-socket-io'

const socketCollaborationsConfig: SocketIoConfig = {
  url: environment.apiUrl + '/sockets/collaborations',
  //url: 'http://localhost:3001/sockets/collaborations',
  options: {
    reconnect: true,
    forceNew: true,
    secure: true,
  },
}

export class CollaborationsSocket extends Socket {
  constructor(token) {
    let config = {
      ...socketCollaborationsConfig,
      options: {
        ...socketCollaborationsConfig.options,
        auth: {
          token: token,
        },
      },
    }
    super(config)
  }
}
