import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { Authenticable } from '../../_models/authenticable/authenticable.model'

import { APIService } from './../api.service'
import { InternalAuthenticationService } from '../authentication/internal.authentication.service'

/**
 *
 *
 * @export
 * @class SignupBufferService
 */
@Injectable()
export class SignupBufferService {
  /**
   *
   *
   * @private
   * @memberof SignupBufferService
   */
  private state = undefined

  /**
   *
   *
   * @private
   * @memberof SignupBufferService
   */
  private subject = new BehaviorSubject(new Authenticable())

  /**
   *
   *
   * @private
   * @type {number}
   * @memberof SignupBufferService
   */
  private step: number = 0

  /**
   *
   *
   * @private
   * @type {string}
   * @memberof SignupBufferService
   */
  private urlToLoadAfterSignup: string = undefined

  /**
   *
   *
   * @private
   * @type {boolean}
   * @memberof SignupBufferService
   */
  private thisEmailIsAlreadyAssociatedToAnAccount: boolean = false

  /**
   *
   *
   * @private
   * @type {File}
   * @memberof SignupBufferService
   */
  private pictureProfileFile: File = undefined

  /**
   * Creates an instance of SignupBufferService.
   * @param {APIService} apiService
   * @param {InternalAuthenticationService} authenticationService
   * @memberof SignupBufferService
   */
  public constructor(
    private apiService: APIService,
    private authenticationService: InternalAuthenticationService
  ) {
    this.init()
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  public init() {
    this.initValues()
    this.initState()
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  public initValues(resetStep: boolean = true) {
    this.subject.next(new Authenticable())
    if (resetStep == true) this.step = 0
    this.urlToLoadAfterSignup = undefined
    this.thisEmailIsAlreadyAssociatedToAnAccount = false
    this.pictureProfileFile = undefined
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  public initState() {
    this.state = {
      step_0: {
        userChecked: false,
        usedCheckedHasError: false,
        userCheckedHasAnAccount: false,
        launchedRequestInvitation: false,
        launchedRequestInvitationHasError: false,
      },
      step_1: {
        codeChecked: false,
        codeCheckedHasError: false,
        codeIsValid: false,
      },
      step_3: {
        registrationRequired: false,
        registrationHasError: false,
      },
    }
  }

  /**
   *
   *
   * @return {*}  {Observable<Authenticable>}
   * @memberof SignupBufferService
   */
  public subscribeToUserBeingCreated(): Observable<Authenticable> {
    return this.subject.asObservable()
  }

  /**
   *
   *
   * @return {*}  {Authenticable}
   * @memberof SignupBufferService
   */
  public userBeingCreated(): Authenticable {
    return this.subject.value
  }

  /**
   *
   *
   * @return {*}  {number}
   * @memberof SignupBufferService
   */
  public getStep(): number {
    return this.step
  }

  /**
   *
   *
   * @param {number} step
   * @memberof SignupBufferService
   */
  public setStep(step: number) {
    this.step = step
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  public stepForward(): void {
    this.step++
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  public stepBack(): void {
    this.step--
  }

  /**
   *
   *
   * @return {*}
   * @memberof SignupBufferService
   */
  public getState() {
    return this.state
  }

  /**
   *
   *
   * @return {*}  {string}
   * @memberof SignupBufferService
   */
  public getUrlToLoadAfterSignup(): string {
    return this.urlToLoadAfterSignup
  }

  /**
   *
   *
   * @param {string} urlToLoadAfterSignup
   * @memberof SignupBufferService
   */
  public setUrlToLoadAfterSignup(urlToLoadAfterSignup: string): void {
    this.urlToLoadAfterSignup = urlToLoadAfterSignup
  }

  /**
   *
   *
   * @return {*}  {File}
   * @memberof SignupBufferService
   */
  public getPictureProfileFile(): File {
    return this.pictureProfileFile
  }

  /**
   *
   *
   * @param {File} pictureProfileFile
   * @memberof SignupBufferService
   */
  public setPictureProfileFile(pictureProfileFile: File): void {
    this.pictureProfileFile = pictureProfileFile
  }

  /**
   *
   *
   * @return {*}  {boolean}
   * @memberof SignupBufferService
   */
  public isThisEmailIsAlreadyAssociatedToAnAccount(): boolean {
    return this.thisEmailIsAlreadyAssociatedToAnAccount
  }

  /**
   *
   *
   * @param {boolean} thisEmailIsAlreadyAssociatedToAnAccount
   * @memberof SignupBufferService
   */
  public setThisEmailIsAlreadyAssociatedToAnAccount(
    thisEmailIsAlreadyAssociatedToAnAccount: boolean
  ): void {
    this.thisEmailIsAlreadyAssociatedToAnAccount = thisEmailIsAlreadyAssociatedToAnAccount
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  async checkUser() {
    try {
      let email = this.subject.value.getUser().email
      let res = await this.apiService.checkUserName(email)
      if (res.status == 200) {
        if (res.body.exists == true) {
          this.state['step_0'] = {
            userChecked: true,
            usedCheckedHasError: false,
            userCheckedHasAnAccount: true,
            launchedRequestInvitation: false,
            launchedRequestInvitationHasError: false,
          }
        } else {
          this.state['step_0'] = {
            userChecked: true,
            usedCheckedHasError: false,
            userCheckedHasAnAccount: false,
            launchedRequestInvitation: false,
            launchedRequestInvitationHasError: false,
          }
        }
      }
      if (res.status == 400) {
      }
    } catch (e) {
      this.state['step_0'] = {
        userChecked: true,
        usedCheckedHasError: true,
        userCheckedHasAnAccount: false,
        launchedRequestInvitation: false,
        launchedRequestInvitationHasError: false,
      }
    }
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  async launchRequestInvitation() {
    try {
      let email = this.subject.value.getUser().email
      await this.apiService.requestNewWorkspaceInvitation(email)
      this.state['step_0']['launchedRequestInvitation'] = true
      this.state['step_0']['launchedRequestInvitationHasError'] = false
    } catch (e) {
      this.state['step_0']['launchedRequestInvitation'] = true
      this.state['step_0']['launchedRequestInvitationHasError'] = true
    }
  }

  /**
   *
   *
   * @param {*} code
   * @memberof SignupBufferService
   */
  async confirmNewWorkspaceInvitation(code) {
    try {
      let email = this.subject.value.getUser().email
      let res = await this.apiService.confirmNewWorkspaceInvitation(email, code)
      if (res.status == 200) {
        if (res.body.status == 1) {
          this.state['step_1'] = {
            codeChecked: true,
            codeCheckedHasError: false,
            codeIsValid: true,
          }
        } else {
          this.state['step_1'] = {
            codeChecked: true,
            codeCheckedHasError: false,
            codeIsValid: false,
          }
        }
      } else {
        this.state['step_1'] = {
          codeChecked: true,
          codeCheckedHasError: true,
          codeIsValid: false,
        }
      }
    } catch (e) {
      this.state['step_1'] = {
        codeChecked: true,
        codeCheckedHasError: true,
        codeIsValid: false,
      }
    }
  }

  /**
   *
   *
   * @memberof SignupBufferService
   */
  async registerUser() {
    try {
      var infos = {
        email: this.subject.value.getUser().email,
        lastname: this.subject.value.getUser().lastName,
        firstname: this.subject.value.getUser().firstName,
        password: this.subject.value.getPassword(),
      }

      var res = await this.apiService.createAccount(
        infos,
        this.pictureProfileFile
      )
      if (res.status == 200) {
        this.state['step_3'] = {
          registrationRequired: true,
          registrationHasError: false,
        }

        var token = res.headers.get('x-auth')
        var refresh_token = res.headers.get('h-auth')
        let language = undefined
        if (res.body.language)
          localStorage.setItem('language', res.body.language)

        this.authenticationService.storeUserCredentials(
          this.userBeingCreated().getUser().email,
          token,
          refresh_token,
          language
        )
        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            username: this.userBeingCreated().getUser().email,
            token: token,
          })
        )
      }
    } catch (e) {
      this.state['step_3'] = {
        registrationRequired: true,
        registrationHasError: true,
      }
    }
  }
}
