import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class TasksFilterService {
  private filters = new BehaviorSubject<any>([])

  constructor() {}

  addFilter(filter: any) {
    let filters = this.filters.getValue()

    let hasBeenAdded = false

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].key === filter.key) {
        filters[i] = filter
        hasBeenAdded = true
        break
      }
    }

    if (hasBeenAdded == false) filters.push(filter)

    this.filters.next(filters)
  }

  removeFilter(key) {
    let filters = this.filters.getValue()
    let newFilters = []
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].key !== key) {
        newFilters.push(filters[i])
      }
    }

    this.filters.next(newFilters)
  }

  resetFilters() {
    this.filters.next([])
  }

  getFilters(): BehaviorSubject<any> {
    return this.filters
  }
}
