import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { ModalService } from '../../../_services/modal.service'
import { APIService } from '../../../_services/api.service'

@Component({
  templateUrl: 'modal-my-workspaces.component.html',
  selector: 'modal-my-workspaces',
  styleUrls: ['./modal-my-workspaces.component.scss'],
})
export class ModalMyWorkspacesComponent implements OnInit {
  workspaces: any
  current_workspace: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private modalService: ModalService
  ) {
    this.workspaces = []
  }

  async ngOnInit() {
    var res = await this.apiService.getMyWorkspaces()
    if (res.body) {
      this.workspaces = res.body
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('profile')
  }

  selectWorkspace(workspace) {
    this.router.navigate(['/home]'])
  }

  createWorkspace() {
    this.router.navigate(['/workspaces/new'])
  }

  isVisible(workspace) {
    if (workspace._id === this.current_workspace._id) return '1'
    else return '0'
  }
}
