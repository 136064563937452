import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'input-textarea.component.html',
  selector: 'input-text-area',
  styleUrls: ['./input-textarea.component.scss'],
})
export class InputTextAreaComponent implements OnInit {
  @Input('placeholder') placeholder: string
  @Input('name') name: string
  @Input('relatedControlName') relatedControlName: string
  @Input('formGroupName') formGroupName: string
  @Input('parentGroup') parentGroup: FormGroup
  @Input('identifier') identifier: string
  @Input('value') value: string
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
