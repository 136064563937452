import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { ConversationsSynchronisationService } from 'src/app/_services/conversations/conversations.synchronisation.service'

@Component({
  templateUrl: 'advertiser-messages.component.html',
  styleUrls: ['./advertiser-messages.component.scss'],
})
export class AdvertiserMessagesComponent
  implements OnInit, AfterViewInit, OnDestroy {
  selectedMenuItem: string

  conversations: any

  constructor(
    private router: Router,
    public alertService: AlertService,
    private route: ActivatedRoute,
    private conversationsSynchronisationService: ConversationsSynchronisationService
  ) {
    this.conversations = route.snapshot.data.conversations
    console.log(this.conversations)
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    await this.conversationsSynchronisationService.updateConversations(
      this.conversations
    )
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}
}
