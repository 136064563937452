<div class="container-fluid full-height bg-default">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'COMMON.DASHBOARD' | translate }}" style="width:100%; text-transform: uppercase"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{ 'COMMON.BRIEF' | translate }}"></nav-bar>
                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <campaign-summary></campaign-summary>
                    </div>
                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <div class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #ffad00">{{ 'CAMPAIGN.BRIEF.BRIEF_TITLE' | translate }}</div>
                            </div>
                            <div class="col-3 no-margin text-center"
                                style="display: flex; align-items: center; justify-content: center">
                                <span class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #ffad00">{{ 'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD' | translate }}</span>
                            </div>
                            <div class="col-3 no-margin text-right"
                                style="display: flex; align-items: center;">
                                <span class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #ffad00">{{ 'CAMPAIGN.BRIEF.BRIEF_BUDGET' | translate }}</span>
                            </div>
                            <div class="col-12" style="margin-top: 5px; margin-bottom: 5px">

                            </div>
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <div class="text-lato text-bold text-small card-title no-margin" style="color: #ffffff; white-space: pre-wrap;">
                                    {{campaign.brief.text}}</div>
                            </div>
                            <div class="col-3 no-margin text-center"
                                style="display: flex; align-items: center; justify-content: center">
                                <div class="text-lato text-bold text-small card-title no-margin" style="color: #ffffff">
                                    {{ 'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD_TEXT_START' | translate }} {{date_start}}<br>{{ 'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD_TEXT_END' | translate }} {{date_end}}</div>
                            </div>
                            <div class="col-3 no-margin text-right"
                                style="display: flex; align-items: center; justify-content: flex-end">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #ffffff" *ngIf="campaign.compensation === 'cash' || campaign.compensation == undefined">{{campaign.total_budget}} {{preferencesManager.getPreferredCurrency().symbol}}</span>
                                    <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #ffffff" *ngIf="campaign.compensation === 'free_products'">{{campaign.total_budget}} {{ 'COMMON.PRODUCT_S' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-right: 12.5px; max-width: calc(50% - 12.5px)"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{ 'CAMPAIGN.BRIEF.BRIEF_TARGET' | translate }}</span>
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                            </div>
                            <div class="col-12">
                                <div class="row no-margin" *ngIf="isLoading == false">
                                    <div class="col-12 tab-4" style="color: white; text-align: left">
                                        {{target | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 light-box margin-top-25 dashboard-mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-left: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{'CAMPAIGN.BRIEF.BRIEF_AUDIENCE' | translate}}</span>
                                <!--img style="width: 20px; height: 20px; margin-left: 6px; margin-top: 0px" src="./assets/images/icon-help.png"-->
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                                <!--div style="margin-right: 0px; display: flex; flex-direction: row-reverse">
                                        <img class="option-image" src="./assets/images/icon-star-pink.png">
                                    </div-->
                            </div>
                            <div class="col-12">
                                <div class="row no-margin" *ngIf="isLoading == false">
                                    <div class="col-12 tab-4" style="color: white; text-align: left">
                                        <div class="row no-margin margin-5" *ngIf="isLoading == false">
                                            <div class="col keyword" *ngFor="let tag of campaign.filters">
                                                <span *ngIf="tag.type == 'hashtag'">@</span>{{tag.content}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-right: 12.5px; max-width: calc(50% - 12.5px)"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #ffad00">{{'CAMPAIGN.BRIEF.BRIEF_DO' | translate}}</span>
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                            </div>
                            <div class="col-12">
                                <div class="row no-margin" *ngIf="isLoading == false">
                                    <div class="col-12 tab-4" style="color: white; text-align: left">
                                        <ul>
                                            <li *ngFor="let do of campaign.do">
                                                {{do.text}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-left: 12.5px; max-width: calc(50% - 12.5px)">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #ff006c">{{'CAMPAIGN.BRIEF.BRIEF_DONT' | translate}}</span>
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                            </div>
                            <div class="col-12">
                                <div class="row no-margin" *ngIf="isLoading == false">
                                    <div class="col-12 tab-4" style="color: white; text-align: left">
                                        <ul>
                                            <li *ngFor="let dont of campaign.dont">
                                                {{dont.text}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px;"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: white">{{'CAMPAIGN.BRIEF.BRIEF_LOCATION' | translate}}</span>
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                            </div>
                            <div class="col-12">
                                <div class="row no-margin" *ngIf="isLoading == false">
                                    <div class="col-12 tab-4" style="color: white; text-align: left">
                                        <div class="row no-margin margin-5" *ngIf="isLoading == false && campaign.locations != undefined && campaign.locations.length > 0">
                                            <div class="col keyword" *ngFor="let location of campaign.locations">
                                                {{location.country | translate }}, {{location.area | translate }}
                                            </div>
                                        </div>
                                        <ul *ngIf="isLoading == false && (campaign.locations == undefined || (campaign.locations != undefined && campaign.locations.length == 0))">
                                            <li>
                                                {{'CAMPAIGN.BRIEF.BRIEF_LOCATION_COUNTRY' | translate}} {{campaign.location.country | translate }}
                                            </li>
                                            <li>
                                                {{'CAMPAIGN.BRIEF.BRIEF_LOCATION_STATE' | translate}} {{campaign.location.area | translate }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px;"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: white">{{'CAMPAIGN.BRIEF.BRIEF_MOODBOARD' | translate}}</span>
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                            </div>
                            <div class="col-12">
                                <div class="row v-align margin-v-15"
                                    style="width:70%; margin-left: auto;margin-right: auto;">
                                    <div class="card-columns" style="">
                                        <!--div class="card text-center">
                                            <div class="card-body">
                                                <p class="card-text">Ajoutez des photos<br> à votre moodboard</p>
                                                <input
                                                    style="width: 36px; height: 36px; margin-left: 10px; margin-right: 0px; padding: 0px"
                                                    class="btn btn-primary btn-big text-lato text-bold" value="+"
                                                    readonly="readonly" (click)="addPicture()">
                                            </div>
                                        </div-->
                                        <div *ngFor="let image of moodboard" class="card" style="">
                                            <img [src]="image.path" class="card-img-top" alt="...">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{'CAMPAIGN.BUDGET.BUDGET_TITLE' | translate}}"></nav-bar>

                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <!--div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px;">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">Localisation de l'audience touchée</span>
                                
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                                
                            </div>
                            <div col="col-12" style="width: 100%">
                                <div id="vmap" class="vmap" style="height: 265px;"></div>
                            </div>
                        </div>
                    </div-->
                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px;">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-9 no-margin">
                                <span class="text-lato text-bold text-medium" style="color: #FFFFFF">{{'CAMPAIGN.PUBLICATIONS.PUBLICATIONS_TITLE' | translate}}</span>
                                <!--img style="width: 20px; height: 20px; margin-left: 6px; margin-top: 0px" src="./assets/images/icon-help.png"-->
                            </div>
                            <div class="col-3 no-margin" style="margin: auto">
                                <!--div style="margin-right: 0px; display: flex; flex-direction: row-reverse">
                                                <img class="option-image" src="./assets/images/icon-star-pink.png">
                                            </div-->
                            </div>
                            <div col="col-12" style="width: 100%; margin: 20px; padding: 10px;">
                                    <div class="row"
                                    style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px">
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center; color: #ff006c">
                                        {{'CAMPAIGN.PUBLICATIONS.PUBLICATIONS_DATE' | translate}}
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_photos.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_like.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon-bubble.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_impression.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_dollar.png">
                                    </div>

                                </div>
                                <div class="row"
                                    style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px" *ngFor="let publication of campaign.publications">
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{getDateString(publication.content.timestamp)}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center; 
                                        padding-top: 20px;
                                        padding-bottom: 20px;">
                                        <div class="card">
                                            <img [src]="publication.content.media_url"
                                                class="card-img-top" alt="...">
                                        </div>
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.content.like_count}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.content.comments_count}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{getImpressions(publication)}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.value}} {{preferencesManager.getPreferredCurrency().symbol}}
                                    </div>

                                </div>
                                <div class="row" *ngIf="campaign.publications == undefined || campaign.publications.length == 0">
                                    <div class="col-12 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white">
                                        {{'CAMPAIGN.PUBLICATIONS.PUBLICATIONS_NO_PUBLICATION_TEXT' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <modal></modal>