<div id="anchor5">
  <form
    class=""
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
    style="margin-bottom: 0px !important"
  >
    <div class="row">
      <div class="form-group col-md-12">
        <p class="text-lato text-bold text-big text-darkgrey">
          {{ 'CAMPAIGN.CREATE.STEP_5_TITLE_1' | translate }}
        </p>
        <p class="text-lato text-medium text-lightgrey">
          {{ 'CAMPAIGN.CREATE.STEP_5_SUBTITLE_1' | translate }}
        </p>
        <div
          class="row v-align"
          style="
            overflow: scroll;
            height: 350px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div class="card-columns" style="">
            <div class="card text-center">
              <div class="card-body" (click)="fileInput.click()">
                <p class="card-text">
                  {{ 'CAMPAIGN.CREATE.STEP_5_INSTRUCTIONS' | translate }}
                </p>
                <div
                  style="
                    width: 36px;
                    height: 36px;
                    margin-right: 0px;
                    padding-top: 1px;
                    padding-left: 10px;
                    font-size: 24px;
                  "
                  class="btn btn-primary btn-big text-lato text-bold"
                >
                  +
                </div>
                <input
                  style="
                    width: 36px;
                    height: 36px;
                    margin-left: 10px;
                    margin-right: 0px;
                    padding: 0px;
                    display: none;
                  "
                  class="btn btn-primary btn-big text-lato text-bold"
                  type="file"
                  multiple="multiple"
                  (change)="onFileChanged($event)"
                  accept="image/*"
                  #fileInput
                />
              </div>
            </div>

            <div
              *ngFor="
                let image of campaignCreationBufferService.getMoodboardSourcePaths()
              "
              style="position: relative"
            >
              <div
                class="card show-img"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  border-width: 0px;
                "
              >
                <img
                  [src]="image.img"
                  class="card-img-top"
                  style="margin-bottom: 15px"
                  *ngIf="image.img != undefined"
                />
                <img
                  [src]="image.imgPath"
                  class="card-img-top"
                  style="margin-bottom: 15px"
                  *ngIf="image.imgPath != undefined"
                />
              </div>
              <div style="">
                <input
                  style="
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                    margin-right: 0px;
                    padding: 0px;
                    font-size: 10px;
                    padding-bottom: 2px;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                  "
                  class="btn btn-primary btn-big text-lato text-bold"
                  value="x"
                  (click)="removeFile(image)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <input
          style="margin-top: 34px"
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
        />
      </div>
    </div>
  </form>
</div>
