import { Injectable } from '@angular/core'
import * as csv from 'csvtojson'

@Injectable()
export class SEOKeywordsCSVFileParsingService {
  constructor() {}

  async parseData(data: string) {
    const jsonArray = await csv({
      noheader: false,
      delimiter: 'auto',
      trim: true,
    }).fromString(data)

    let keywords = []
    if (jsonArray != undefined) {
      for (let i = 0; i < jsonArray.length; i++) {
        if (
          jsonArray[i]['Keyword'] != null &&
          jsonArray[i]['Keyword'].length > 0
        )
          keywords.push(jsonArray[i]['Keyword'])
      }
    }

    return keywords
  }
}
