import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersProfileAPIService {
  constructor(private http: HttpClient) {}

  async updateGeneralInformation(publisherId, infos, pictureProfile) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/profile/'

    const formData: FormData = new FormData()
    if (pictureProfile)
      formData.append('profilePic', pictureProfile, pictureProfile.name)
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async addAwardInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/awards/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateAwardInformation(publisherId, awardId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/awards/' + awardId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteAwardInformation(publisherId, awardId) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/awards/' + awardId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addCertificationInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/certifications/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateCertificationInformation(publisherId, certificationId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/certifications/' +
      certificationId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteCertificationInformation(publisherId, certificationId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/certifications/' +
      certificationId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addEducationInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/educations/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateEducationInformation(publisherId, educationId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/educations/' +
      educationId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteEducationInformation(publisherId, educationId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/educations/' +
      educationId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addPatentInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/patents/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updatePatentInformation(publisherId, patentId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/patents/' + patentId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deletePatentInformation(publisherId, patentId) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/patents/' + patentId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addPositionInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/positions/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updatePositionInformation(publisherId, positionId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/positions/' +
      positionId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deletePositionInformation(publisherId, positionId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/positions/' +
      positionId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addPublicationInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/publications/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updatePublicationInformation(publisherId, publicationId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/publications/' +
      publicationId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deletePublicationInformation(publisherId, publicationId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/publications/' +
      publicationId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addSkillInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/skills/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateSkillInformation(publisherId, skillId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/skills/' + skillId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteSkillInformation(publisherId, skillId) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/skills/' + skillId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addSpokenLanguageInformation(publisherId, infos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/languages/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateSpokenLanguageInformation(publisherId, spokenLanguageId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/languages/' +
      spokenLanguageId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteSpokenLanguageInformation(publisherId, spokenLanguageId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/languages/' +
      spokenLanguageId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addExpertiseInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/content/expertises/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateExpertiseInformation(publisherId, expertiseId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/expertises/' +
      expertiseId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteExpertiseInformation(publisherId, expertiseId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/expertises/' +
      expertiseId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addHashtagInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/content/hashtags/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateHashtagInformation(publisherId, hashtagId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/hashtags/' +
      hashtagId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteHashtagInformation(publisherId, hashtagId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/hashtags/' +
      hashtagId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addLanguageInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/content/languages/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateLanguageInformation(publisherId, languageId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/languages/' +
      languageId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteLanguageInformation(publisherId, languageId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/languages/' +
      languageId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async addNetworkInformation(publisherId, infos) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/content/networks/'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateNetworkInformation(publisherId, networkId, infos) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/networks/' +
      networkId

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteNetworkInformation(publisherId, networkId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/content/networks/' +
      networkId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
