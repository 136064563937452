<div
  class="display-block col-direction section-container clickable"
  (click)="loadDealPage()"
>
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-9 campaign-title text-pink text-bold text-large">
              <img
                class="brand-logo-thumbnail"
                [src]="deal._brand?.brand?.brand_logo_path"
              />
              {{ deal.proposal.name }}
              <span
                class="badge badge-primary"
                [ngClass]="{
                  'badge-proposed': getDealStatus() === 'PROPOSED',
                  'badge-disabled':
                    getDealStatus() === 'DRAFT' ||
                    getDealStatus() === 'DECLINED' ||
                    getDealStatus() === 'FINISHED' ||
                    getDealStatus() === 'CANCELLED',
                  'badge-primary': getDealStatus() === 'ACCEPTED'
                }"
                >{{ getDealStatus() }}</span
              >
              <br />
              <span class="brand text-small text-darkgrey">
                By {{ deal._brand?.brand?.name }}</span
              >
            </div>
            <div class="col-3 flex-end">
              <div>
                <tiny-cta
                  class="clickable"
                  value="DETAILS"
                  (click)="loadDealPage()"
                ></tiny-cta>
              </div>
            </div>
          </div>
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          Offer date : {{ getCreatedDate() }} - Expire date :
          {{ getExpireDate() }}
        </div>
        <div class="section-body text-small text-darkgrey">
          <div
            class="campaign-brief"
            [innerHTML]="deal.proposal.description"
          ></div>
        </div>
        <div class="section-footer text-xsmall text-darkgrey">
          <span
            class="badge action badge-disabled badge-bigger"
            *ngFor="let keyword of deal._scope.filters.content"
          >
            {{ keyword }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
