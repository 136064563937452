import { Injectable } from '@angular/core'

import { InternalAuthenticationService } from './authentication/internal.authentication.service'

import jwt_decode from 'jwt-decode'
import * as moment from 'moment'

@Injectable()
export class TokenService {
  constructor(private authenticationService: InternalAuthenticationService) {}

  getRoleInToken() {
    let currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      let user = JSON.parse(currentUser)
      try {
        let decoded_token = jwt_decode(user.token)
        return decoded_token['role']
      } catch (e) {}
    }

    return undefined
  }

  async examineToken() {
    let currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      let user = JSON.parse(currentUser)
      try {
        let decoded_token = jwt_decode(user.token)

        let now = moment().valueOf() / 1000

        // IF TOKEN EXPIRES IN MORE THAN 15 MINUTES, DO NOTHING
        let remaining_time = decoded_token['exp'] - now
        if (remaining_time > 15 * 60) {
          return true
        } else {
          // TOKEN ABOUT TO EXPIRE OR EXPIRED
          // TRY TO RENEW IT USING REFRESH TOKEN
          // IF NO REFRESH TOKEN OR REFRESH TOKEN EXPIRED OR SERVER RESPONDS WITH IMPOSSIBILITY TO REFRESH
          // LOG OUT USER

          let refresh_token_str = localStorage.getItem('h-token')
          let refresh_token = JSON.parse(refresh_token_str)
          let decoded_refresh_token = jwt_decode(refresh_token.token)
          let refresh_remaining_time = decoded_refresh_token['exp'] - now
          if (refresh_remaining_time > 0) {
            let res = await this.authenticationService.refreshToken(
              refresh_token.token
            )
            if (res.status == 200) {
              let token = res.headers.get('x-auth')
              let new_refresh_token = res.headers.get('h-auth')
              localStorage.setItem(
                'currentUser',
                JSON.stringify({ username: user.username, token: token })
              )
              localStorage.setItem(
                'h-token',
                JSON.stringify({ token: new_refresh_token })
              )
              return true
            } else if (res.status == 400) {
              this.authenticationService.logout()
              return false
            } else return false
          } else {
            this.authenticationService.logout()
            return false
          }
        }
      } catch (Error) {
        this.authenticationService.logout()
        return false
      }
    }
    return false
  }
}
