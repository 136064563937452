import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'

import { CurrencyHelper } from './../../../../_helpers/referential/currency.helper'

@Component({
  templateUrl: 'publisher-settings.component.html',
  styleUrls: ['./publisher-settings.component.scss'],
})
export class PublisherSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  publisher: any

  loading = false

  constructor(
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.publisher = this.route.snapshot.data.user.body
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {}
}
