import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { ModalService } from '../../../../../../_services/modal.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  selector: 'campaign-create-step-5',
  templateUrl: 'campaign-create-step-5.component.html',
  styleUrls: ['./campaign-create-step-5.component.scss'],
})
export class CampaignCreateStep5Component implements OnInit {
  newCampaignForm: FormGroup

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({})
  }

  onSubmitProject() {
    this.campaignCreationBufferService.moveForward()
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }

  onFileChanged(event) {
    var initialNumber = this.campaignCreationBufferService.getMoodboardFiles()
      .length
    for (var i = 0; i < event.target.files.length; i++) {
      const reader = new FileReader()
      reader.onload = (e) => {
        var index =
          this.campaignCreationBufferService.getMoodboardFiles().length -
          initialNumber
        this.campaignCreationBufferService
          .getMoodboardSourcePaths()
          .push({ img: reader.result })
        this.campaignCreationBufferService
          .getMoodboardFiles()
          .push(event.target.files[index])
      }
      reader.readAsDataURL(event.target.files[i])
    }
  }

  removeFile(image) {
    var i = this.campaignCreationBufferService
      .getMoodboardFiles()
      .indexOf(image)
    this.campaignCreationBufferService.getMoodboardFiles().splice(i, 1)

    var j = this.campaignCreationBufferService
      .getMoodboardSourcePaths()
      .indexOf(image)
    this.campaignCreationBufferService.getMoodboardSourcePaths().splice(j, 1)
  }
}
