import { Theme } from './symbols'

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    backgroundColorPage: '#f7f7f7',
    backgroundColorForm: 'white',
    textColor: '#484c50',
  },
}
