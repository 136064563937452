import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { SignupBufferService } from './../../../../../_services/buffers/signup.buffer.service'

@Component({
  selector: 'signup-step-2',
  templateUrl: 'signup-step-2.component.html',
  styleUrls: ['./signup-step-2.component.scss'],
})
export class SignupStep2Component implements OnInit, AfterViewInit, OnDestroy {
  profileForm: FormGroup

  isValid: boolean

  selectedProfile: File
  profileSrc: any

  constructor(
    private formBuilder: FormBuilder,
    private signupBufferService: SignupBufferService
  ) {
    this.profileSrc = undefined

    this.isValid = true
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      profileUrl: ['', Validators.required],
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  onSubmitUser() {
    var candidate_firstname = this.profileForm.controls.firstname.value
    var candidate_name = this.profileForm.controls.name.value

    this.isValid = true

    if (candidate_firstname != undefined) {
      candidate_firstname = candidate_firstname.trim()
      if (candidate_firstname.length > 0) {
        this.signupBufferService
          .userBeingCreated()
          .getUser().firstName = candidate_firstname
      } else {
        this.isValid = false
      }
    } else {
      this.isValid = false
    }

    if (candidate_name != undefined) {
      candidate_name = candidate_name.trim()
      if (candidate_name.length > 0) {
        this.signupBufferService
          .userBeingCreated()
          .getUser().lastName = candidate_name
      } else {
        this.isValid = false
      }
    } else {
      this.isValid = false
    }

    if (this.isValid) this.signupBufferService.stepForward()
  }

  onProfileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.profileSrc = reader.result

      this.selectedProfile = event.target.files[0]
      this.signupBufferService.setPictureProfileFile(this.selectedProfile)
    }

    reader.readAsDataURL(event.target.files[0])
  }

  stepBack() {
    this.signupBufferService.stepBack()
  }
}
