import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
} from '@angular/core'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../../_services'
import { CacheService } from '../../../../_services/cache.service'

import { environment } from '../../../../../environments/environment'

declare var FB: any

@Component({
  templateUrl: 'project_create.component.html',
  styleUrls: ['./project_create.component.scss'],
})
export class ProjectCreateComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any
  submitted = false
  isValid: boolean
  newProjectForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasAccount = false

  step: any
  project_name: any
  project: any
  project_id: any

  selectedFile: File
  imageSrc: any

  members: any[]

  creation: any

  accounts: any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private cacheService: CacheService,
    private location: Location,
    private _ngZone: NgZone
  ) {
    this.step = 0
    this.project_name = undefined
    this.project = undefined
    this.project_id = undefined
    this.loading = false
    this.hasAccount = false
    this.imageSrc = undefined
    this.members = []

    this.isValid = true

    this.accounts = {
      twitter: {
        infos: {},
        params: {},
        selected: false,
      },
      instagram: {
        infos: {},
        params: {},
        selected: false,
      },
    }
  }

  ngOnInit() {
    this.initializeFbLogin()

    this.newProjectForm = this.formBuilder.group({
      title: [''],
      picUrl: [''],
      pipe_title: [''],
      member_email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      keyword: [''],
    })

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  onSubmitProject() {
    if (this.step == 0) {
      var candidate = this.newProjectForm.controls.title.value

      if (candidate != undefined) {
        candidate = candidate.trim()
        if (candidate.length > 2) {
          this.project_name = candidate
          this.isValid = true
        } else {
          this.isValid = false
        }
      } else {
        this.isValid = false
      }
    }

    if (this.step == 1) {
      this.registerEverything()
    }

    if (this.isValid && this.step < 1) this.step++
  }

  addMember() {
    if (this.newProjectForm.valid) {
      var candidate = this.newProjectForm.controls.member_email.value
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.members.indexOf(candidate) === -1) {
          this.members.push(candidate)
          this.newProjectForm.controls['member_email'].reset()
        }
      }
    }
  }

  removeMember(email) {
    var i = this.members.indexOf(email)
    this.members.splice(i, 1)
  }

  async registerEverything() {
    try {
      var infos = {
        title: this.project_name,
        members: this.members,
        social_media: {},
      }

      if (this.accounts.instagram.selected == true) {
        infos.social_media = {
          instagram: {
            login: this.accounts.instagram.params.account.username,
            social_id: this.accounts.instagram.params.account.social_id,
            infos: {
              token: this.accounts.instagram.params.token,
              account: this.accounts.instagram.params.account,
            },
          },
        }
      }

      this.loading = true
      this.creation = undefined

      var res = await this.apiService.createProject(infos, this.selectedFile)
      if (res.status == 200) {
        this.loading = false
        this.step++
        this.creation = res
      }
    } catch (error) {
      this.loading = false
    }
  }

  goToDashboard() {
    this.sub = this.route.params.subscribe(async (params) => {
      var projects = await this.cacheService.getProjects()
      for (var i = 0; i < projects.length; i++) {
        if (projects[i].title == this.project_name) {
          this.project_id = projects[i]._id
        }
      }

      if (environment.name === 'ADVERTISER')
        this.router.navigate(['projects/' + this.project_id + '/dashboard'])
      else
        this.router.navigate(['workspaces/' + this.project_id + '/dashboard'])
    })
  }

  onFileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.imageSrc = reader.result
      this.selectedFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
  }

  stepBack() {
    if (this.step > 0) this.step--
    else this.location.back()
  }

  initializeFbLogin() {
    ;(window as any).fbAsyncInit = function () {
      FB.init({
        appId: '830528690628233',
        cookie: true,
        xfbml: true,
        version: 'v3.3',
      })
      FB.AppEvents.logPageView()
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  async connectInstagram() {
    if (this.accounts.instagram.selected == false) {
      var _this = this
      FB.login(
        async (response) => {
          if (response.authResponse) {
            //login success
            //login success code here
            //redirect to home page
            var token = response.authResponse.accessToken
            var result = await this.apiService.registerInstagramLonglifeToken(
              token
            )
            this._ngZone.run(() => {
              var account_index = 0
              this.accounts.instagram.infos = {
                token: result.body.token.access_token,
                account:
                  '@' + result.body.accounts.accounts[account_index].username,
              }
              this.accounts.instagram.params = {
                token: {
                  access_token: result.body.token.access_token,
                  expires_in: result.body.token.expires_in,
                  token_type: result.body.token.token_type,
                },
                account: {
                  social_id: result.body.accounts.accounts[account_index].id,
                  display_name:
                    result.body.accounts.accounts[account_index].name,
                  username:
                    result.body.accounts.accounts[account_index].username,
                  profile_picture_url:
                    result.body.accounts.accounts[account_index]
                      .profile_picture_url,
                },
              }
              this.accounts.instagram.selected = true
            })
          } else {
          }
        },
        {
          scope:
            'public_profile,manage_pages,instagram_basic,instagram_manage_insights',
          return_scopes: true,
          enable_profile_selector: false,
        }
      )
    } else {
      this.accounts.instagram.selected = false
      this.accounts.instagram.infos = {}
    }
  }

  connectTwitter() {
    if (this.accounts.twitter.selected == false) {
      this.accounts.twitter.selected = true
      this.accounts.twitter.infos = {
        token: 'adsghfs',
        account: '@mini_5_9',
      }
    } else {
      this.accounts.twitter.selected = false
      this.accounts.twitter.infos = {}
    }
  }
}
