<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunities-title">
      <!--publisher-section-intro
        title="{{ 'OPPORTUNITIES' | translate }}"
        description="{{
          'Here is a list of current campaigns. Click on a campaign to have details about the underlying brand and its expectations.'
            | translate
        }}"
      ></publisher-section-intro-->
      <publisher-section-intro
        title="{{ offer.name }}"
        description=" "
      ></publisher-section-intro>
    </div>
    <div id="opportunities-wrappers">
      <opportunity-thumbnail [opportunity]="offer"></opportunity-thumbnail>
    </div>
  </div>
</div>
