import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { PublishersProfileAPIService } from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'
import { DataService } from '../../../../_services/data.service'

@Component({
  templateUrl: 'publisher-profile-biography.component.html',
  selector: 'publisher-profile-biography',
  styleUrls: ['./publisher-profile-biography.component.scss'],
})
export class PublisherProfileBiographyComponent implements OnInit {
  @Input('publisher') publisher: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  myProfileBioForm: FormGroup
  isValid: boolean
  loading = false

  hasChangedProfilePicture: boolean
  selectedProfile: File
  profileSrc: any

  showModal: boolean

  isEditingAbout: boolean
  isEditingBio: boolean
  isEditingValues: boolean
  isEditingInterests: boolean

  firstNameField: any
  lastNameField: any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService,
    private dataService: DataService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)

    this.profileSrc = undefined
    this.hasChangedProfilePicture = false
    this.myProfileBioForm = this.formBuilder.group({
      firstNameForm: this.formBuilder.group({
        firstName: { value: '', disabled: false },
      }),
      lastNameForm: this.formBuilder.group({
        lastName: { value: '', disabled: false },
      }),
      emailForm: this.formBuilder.group({
        email: new FormControl(
          { value: '', disabled: false },
          Validators.compose([
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
      aboutForm: this.formBuilder.group({
        about: { value: '', disabled: false },
      }),
      biographyForm: this.formBuilder.group({
        biography: { value: '', disabled: false },
      }),
      valuesForm: this.formBuilder.group({
        values: { value: '', disabled: false },
      }),
      interestsForm: this.formBuilder.group({
        interests: { value: '', disabled: false },
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.isEditingAbout = false
    this.isEditingBio = false
    this.isEditingValues = false
    this.isEditingInterests = false

    this.initValues()
  }

  initValues() {
    this.hasChangedProfilePicture = false

    this.myProfileBioForm = this.formBuilder.group({
      firstNameForm: this.formBuilder.group({
        firstName: {
          value: this.publisher.user.infos.firstname,
          disabled: false,
        },
      }),
      lastNameForm: this.formBuilder.group({
        lastName: {
          value: this.publisher.user.infos.lastname,
          disabled: false,
        },
      }),
      emailForm: this.formBuilder.group({
        email: new FormControl(
          {
            value: this.publisher.user.infos.email,
            disabled: false,
          },
          Validators.compose([
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
      aboutForm: this.formBuilder.group({
        about: {
          value: this.hasProfileBioInformation()
            ? this.getProfileBioInformation().proposal
            : '',
          disabled: false,
        },
      }),
      biographyForm: this.formBuilder.group({
        biography: {
          value: this.hasProfileBioInformation()
            ? this.getProfileBioInformation().biography
            : '',
          disabled: false,
        },
      }),
      valuesForm: this.formBuilder.group({
        values: {
          value: this.hasProfileBioInformation()
            ? this.getProfileBioInformation().values
            : '',
          disabled: false,
        },
      }),
      interestsForm: this.formBuilder.group({
        interests: {
          value: this.hasProfileBioInformation()
            ? this.getProfileBioInformation().interests
            : '',
          disabled: false,
        },
      }),
    })
  }

  ngOnDestroy() {}

  hasProfileBioInformation() {
    try {
      return this?.publisher?.publisher?.infos != undefined
    } catch (e) {
      return false
    }
  }

  getProfileBioInformation() {
    if (this.hasProfileBioInformation() == true)
      return this.publisher.publisher.infos
    else return undefined
  }

  getNumberOfInformations() {
    let objects = this.getProfileBioInformation()
    console.log(objects)
    if (objects) return objects.length
    return 0
  }

  hasFullProfileBioInformation() {
    try {
      if (this.hasProfileBioInformation() == false) return false
      else {
        let infos = this.publisher?.publisher?.infos
        let hasAtLeastOneInformation = false
        if (this.hasAbout()) hasAtLeastOneInformation = true
        if (this.hasBiography()) hasAtLeastOneInformation = true
        if (this.hasValues()) hasAtLeastOneInformation = true

        return hasAtLeastOneInformation
      }
    } catch (e) {
      return false
    }
  }

  hasAbout() {
    try {
      if (this.hasProfileBioInformation() == false) return false
      else {
        let infos = this.publisher?.publisher?.infos
        if (infos.proposal != undefined && infos.proposal !== '') return true
        return false
      }
    } catch (e) {
      return false
    }
  }

  hasBiography() {
    try {
      if (this.hasProfileBioInformation() == false) return false
      else {
        let infos = this.publisher?.publisher?.infos
        if (infos.biography != undefined && infos.biography !== '') return true
        return false
      }
    } catch (e) {
      return false
    }
  }

  hasValues() {
    try {
      if (this.hasProfileBioInformation() == false) return false
      else {
        let infos = this.publisher?.publisher?.infos
        if (infos.values != undefined && infos.values !== '') return true
        return false
      }
    } catch (e) {
      return false
    }
  }

  makeEditable() {
    this.alertService.resetAlerts()
    //this.myProfileBioForm.enable()
    return
  }

  makeNotEditable() {
    //this.myProfileBioForm.disable()
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let firstName = this.readTextValue(
        this.myProfileBioForm.controls.firstNameForm['controls'].firstName.value
      )

      if (
        firstName != undefined &&
        this.myProfileBioForm.controls.firstNameForm['controls'].firstName
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('First name can not be empty')
      }

      let lastName = this.readTextValue(
        this.myProfileBioForm.controls.lastNameForm['controls'].lastName.value
      )
      if (
        lastName != undefined &&
        this.myProfileBioForm.controls.lastNameForm['controls'].lastName
          .valid == false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Last name can not be empty')
      }

      let email = this.readTextValue(
        this.myProfileBioForm.controls.emailForm['controls'].email.value
      )
      if (
        email != undefined &&
        this.myProfileBioForm.controls.emailForm['controls'].email.valid ==
          false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Email not valid')
      }

      let about_line = this.readTextValue(
        this.myProfileBioForm.controls.aboutForm['controls'].about.value
      )

      let biography_line = this.readTextValue(
        this.myProfileBioForm.controls.biographyForm['controls'].biography.value
      )

      let values_line = this.readTextValue(
        this.myProfileBioForm.controls.valuesForm['controls'].values.value
      )

      let interests_line = this.readTextValue(
        this.myProfileBioForm.controls.interestsForm['controls'].interests.value
      )

      this.firstNameField = firstName
      this.lastNameField = lastName

      if (this.firstNameField === '' || this.lastNameField === '') {
        this.isValid = false
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_bio_infos = {
          firstname: firstName,
          lastname: lastName,
          email: email,
          proposal: about_line,
          biography: biography_line,
          values: values_line,
          interests: interests_line,
        }

        let res = await this.apiService.updateGeneralInformation(
          this.publisher.publisher._id,
          profile_bio_infos,
          this.selectedProfile
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeNotEditable()
          this.publisher.publisher = res.body.publisher
          this.publisher.user = res.body.user
          await this.sendUserName()
          this.closeModal()
          this.loading = false
        } else {
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  onFileChanged(event) {
    this.hasChangedProfilePicture = true
    this.selectedProfile = event.target.files[0]

    const reader = new FileReader()
    reader.onload = (e) => (this.profileSrc = reader.result)

    reader.readAsDataURL(this.selectedProfile)
  }

  doNothing() {}

  getAbout() {
    if (this.hasProfileBioInformation())
      return this.getProfileBioInformation().proposal
    else return 'N/A'
  }

  getBiography() {
    if (this.hasProfileBioInformation())
      return this.getProfileBioInformation().biography
    else return 'N/A'
  }

  getInterests() {
    if (this.hasProfileBioInformation())
      return this.getProfileBioInformation().interests
    else return 'N/A'
  }

  getValues() {
    if (this.hasProfileBioInformation())
      return this.getProfileBioInformation().values
    else return 'N/A'
  }

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
  }

  updateBiography() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  /*changeToEdit(event) {
    let section = event.currentTarget.id
    if (section === 'about') this.isEditingAbout = true
    if (section === 'bio') this.isEditingBio = true
    if (section === 'values') this.isEditingValues = true
    if (section === 'interests') this.isEditingInterests = true
  }*/

  async sendUserName() {
    let userName = {
      firstName: this.myProfileBioForm.controls.firstNameForm['controls']
        .firstName.value,
      lastName: this.myProfileBioForm.controls.lastNameForm['controls'].lastName
        .value,
    }
    this.dataService.sendProfileData(userName)
  }
}
