<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="publishers-title">
      <publisher-section-intro
        title="{{ 'PENDING INVITATIONS' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="publishers-list">
      <div class="display-block container-list">
        <div class="row">
          <div
            class="col-12"
            *ngIf="requests == undefined || requests.length == 0"
          >
            <div class="text-wrapper">There is no pending invitation.</div>
          </div>
          <div
            class="col-12 col-md-3 publisher-wrapper"
            *ngFor="let request of requests"
          >
            <div class="publisher-picture">
              <img
                [src]="request?.couple?.publisher?.infos?.profilePictureURL"
              />
            </div>
            <div class="publisher-title">
              {{ request?.couple?.publisher?.user?.infos?.fullName }}
            </div>
            <div class="publisher-infos">
              <span
                class="btn text-lato text-bold"
                (click)="cancelRequest(request)"
                >CANCEL REQUEST</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="publishers-title">
      <publisher-section-intro
        title="{{ 'BRAND PARTNERS' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="publishers-list">
      <div class="display-block container-list">
        <div class="row">
          <div
            class="col-12"
            *ngIf="partners == undefined || partners.length == 0"
          >
            <div class="text-wrapper">You don't have brand partners.</div>
          </div>
          <div
            class="col-12 col-md-3 publisher-wrapper"
            *ngFor="let partner of partners"
          >
            <div class="publisher-picture">
              <img [src]="partner._publisher.infos.profilePictureURL" />
            </div>
            <div class="publisher-title">
              {{ partner._publisher.user.infos.fullName }}
            </div>
            <div class="publisher-infos">
              <span
                class="btn text-lato text-bold"
                (click)="turnToFriend(partner)"
                >TURN TO FRIEND</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="publishers-title">
      <publisher-section-intro
        title="{{ 'BRAND FRIENDS' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="publishers-list">
      <div class="display-block container-list">
        <div class="row">
          <div
            class="col-12"
            *ngIf="friends == undefined || friends.length == 0"
          >
            <div class="text-wrapper">You don't have brand friends.</div>
          </div>
          <div
            class="col-12 col-md-3 publisher-wrapper"
            *ngFor="let friend of friends"
          >
            <div class="publisher-picture">
              <img [src]="friend._publisher.infos.profilePictureURL" />
            </div>
            <div class="publisher-title">
              {{ friend._publisher.user.infos.fullName }}
            </div>
            <div class="publisher-infos">
              <span
                class="btn text-lato text-bold"
                (click)="turnToPartner(friend)"
                >TURN TO PARTNER</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
