<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page no-padding-bottom-board">
    <div
      class="row one-edge-shadow sticky-deal-menu"
      style="background-color: white"
    >
      <!--div id="deals-title">
        <publisher-section-intro
          title="{{ 'DEALS' | translate }}"
          description="{{
            'Fill in your profile to get relevant opportunities' | translate
          }}"
        ></publisher-section-intro>
      </div-->
      <div class="col-12" id="deals-menu-wrappers">
        <div>
          <deal-menu
            (selectedMenuItemEvent)="manageMenuItem($event)"
          ></deal-menu>
        </div>
      </div>
      <!--div class="col-12" id="deals-forecast-wrappers">
        <div>
          <deal-forecast></deal-forecast>
        </div>
      </div-->
    </div>
    <div id="deals-summary-wrapper" *ngIf="selectedMenuItem === 'summary'">
      <deal-forecast></deal-forecast>
    </div>
    <div id="deals-kanban-wrapper" *ngIf="selectedMenuItem === 'board'">
      <deal-kanban [deals]="deals"></deal-kanban>
    </div>
    <div id="deals-kanban-wrapper" *ngIf="selectedMenuItem === 'conversations'">
      <!--deal-kanban [deal]="deal"></deal-kanban-->
    </div>
    <!--div id="deals-wrappers">
      <deal-thumbnail *ngFor="let deal of deals" [deal]="deal"></deal-thumbnail>
    </div-->
  </div>
</div>
<!--loader *ngIf="loading == true"></loader-->
