<!--div class="display-block col-direction section-container clickable">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row padding-top-15 padding-bottom-15">
      <div class="col-4 text-align-center">
        <p class="text-darkgrey text-medium">Earnings</p>
        <circle-progress
          [percent]="60"
          [outerStrokeColor]="'#ff006c'"
          [units]="'€'"
        ></circle-progress>
        <div class="padding-top-15 text-small">
          Previsionnal Earnings : 10000€
        </div>
      </div>
      <div class="col-4 text-align-center">
        <p class="text-darkgrey text-medium">Publications</p>
        <circle-progress
          [percent]="70"
          [outerStrokeColor]="'#ffad00'"
          [showUnits]="false"
        ></circle-progress>
        <div class="padding-top-15 text-small">
          Previsionnal publications : 10
        </div>
      </div>
      <div class="col-4 text-align-center">
        <p class="text-darkgrey text-medium">Deals</p>
        <circle-progress
          [percent]="90"
          [outerStrokeColor]="'#09cced'"
          [showUnits]="false"
        ></circle-progress>
        <div class="padding-top-15 text-small">Active deals : 10</div>
      </div>
    </div>
  </div>
</div-->
<div class="display-block col-direction section-container clickable">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12" style="margin-bottom: 20px">
        <div class="text-xlarge text-bold">Revenues</div>
        <!--div class="text-xsmall">Date range: in the last 30 days</div-->
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-xsmall text-pink">Potential</div>
            <div class="text-xlarge text-bold">{{ '4 700 €' }}</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-xsmall text-pink">Signed orders</div>
            <div class="text-xlarge text-bold">{{ '1 200 €' }}</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-xsmall text-pink">Pending payment</div>
            <div class="text-xlarge text-bold">{{ '487.35 €' }}</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box last-figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-xsmall text-pink">Paid</div>
            <div class="text-xlarge text-bold">{{ '15 786.13 €' }}</div>
          </div>
        </div>
      </div>
      <div class="col-12" style="margin-top: 45px">
        <canvas
          baseChart
          width="400"
          height="120"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
        >
        </canvas>
        <div class="text-medium text-bold" style="text-align: center">
          Monthly revenues
        </div>
      </div>
    </div>
    <!--div>
      <div class="progress-bar-title">
        <div class="progress text-bold">
          <div role="progressbar" style="width: 60%" class="text-bold">
            FINISHED
          </div>
          <div role="progressbar" style="width: 30%" class="text-bold">
            ACCEPTED
          </div>
          PENDING
        </div>
      </div>
      <div class="section-progress-ca">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: 60%"
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            720€
          </div>
          <div
            class="progress-bar bg-success"
            role="progressbar"
            style="width: 30%"
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            360€
          </div>
          <div
            class="progress-bar pending"
            role="progressbar"
            style="width: 10%"
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            120€
          </div>
        </div>
      </div>
    </div-->
  </div>
</div>
