/**
 *
 *
 * @export
 * @class Country
 */
export class Country {
  /**
   * id is an instance will contain the id of country
   * @type {number}
   * @memberof Country
   */
  id: number

  /**
   * name is an instance will contain the name of country
   * @type {string}
   * @memberof Country
   */
  name: string

  /**
   * Creates an instance of Country.
   * @param {*} [values=undefined]
   * @memberof Country
   */
  constructor(values = undefined) {
    if (values == undefined) {
      this.id = 0
      this.name = ''
    } else {
      this.id = values['id']
      this.name = values['name']
    }
  }

  /**
   * Returns a builder insrtance, build with id and country of country selected
   * @static
   * @return {*}
   * @memberof Country
   */
  static getBuilder() {
    class Builder {
      id: number
      name: string
      constructor() {}
      withIdentifier(id: number = -1): Builder {
        this.id = id
        return this
      }
      withName(name: string): Builder {
        this.name = name
        return this
      }
      build(): Country {
        return new Country(this)
      }
    }
    return Builder
  }
}
