import { environment } from './../environments/environment'

import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { Injectable, NgModule } from '@angular/core'
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { ChartsModule } from 'ng2-charts'
import { SocketIoModule } from 'ngx-socket-io'

import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppCommonRoutingModule } from './app-routing-common.module'
import { AppPublishersRoutingModule } from './app-routing-publishers.module'
import { AppBrandsRoutingModule } from './app-routing-brands.module'
import { AppBrandsV2RoutingModule } from './app-routing-brands-v2.module'

let AppRoutingModule =
  environment.role === 'publisher'
    ? AppPublishersRoutingModule
    : environment.name === 'ADVERTISER'
    ? AppBrandsRoutingModule
    : AppBrandsV2RoutingModule

//import { AuthModule, AuthHttpInterceptor } from '@cryptr/cryptr-angular'
import { QuillModule } from 'ngx-quill'

import { AppComponent } from './app.component'

import { AuthGuard, RoleGuard } from './_guards'

import { LanguageInterceptor } from './_helpers/language.interceptor.'
import { JwtInterceptor } from './_helpers'

import { PublicMultipleColumnsLayoutComponent } from './_components/public/public-multiple-columns-layout'
import { LoginComponent } from './_components/public/login'
import { SignupComponent } from './_components/public/signup'

import { SignupStep0Component } from './_components/public/signup/steps/step_0'
import { SignupStep1Component } from './_components/public/signup/steps/step_1'
import { SignupStep2Component } from './_components/public/signup/steps/step_2'
import { SignupStep3Component } from './_components/public/signup/steps/step_3'
import { SignupStep4Component } from './_components/public/signup/steps/step_4'

import { PasswordRecoveryComponent } from './_components/public/password/password_recovery'
import { PasswordChangeComponent } from './_components/public/password/password_change'

import { HomeComponent } from './_components/private/home'
import { JourneyStartComponent } from './_components/private/journey_start'
import { ProfileComponent } from './_components/private/profile'
import { ProfileInfluencerComponent } from './_components/private/publishers/profile-influencer'

import { ProjectCreateComponent } from './_components/private/projects/project_create'
import { ProjectDashboardComponent } from './_components/private/projects/project_dashboard'
import { ProjectSettingsComponent } from './_components/private/projects/project_settings'
import { ProjectSettingsEditComponent } from './_components/private/projects/project_settings_edit'
import { ProjectBrandComponent } from './_components/private/projects/project_brand'
import { ProjectBrandEditComponent } from './_components/private/projects/project_brand_edit'
import { ProjectBrandAuthorityComponent } from './_components/private/projects/project_brand_authority'
import { ProjectBillingComponent } from './_components/private/projects/project_billing'
import { ProjectBillingEditComponent } from './_components/private/projects/project_billing_edit'

import { CampaignCreateComponent } from './_components/private/campaigns/campaign_create'
import { CampaignCreateStep0Component } from './_components/private/campaigns/campaign_create/steps/step_0/campaign-create-step-0.component'
import { CampaignCreateStep1Component } from './_components/private/campaigns/campaign_create/steps/step_1/campaign-create-step-1.component'
import { CampaignCreateStep2Component } from './_components/private/campaigns/campaign_create/steps/step_2/campaign-create-step-2.component'
import { CampaignCreateStep3Component } from './_components/private/campaigns/campaign_create/steps/step_3/campaign-create-step-3.component'
import { CampaignCreateStep4Component } from './_components/private/campaigns/campaign_create/steps/step_4/campaign-create-step-4.component'
import { CampaignCreateStep5Component } from './_components/private/campaigns/campaign_create/steps/step_5/campaign-create-step-5.component'
import { CampaignCreateStep6Component } from './_components/private/campaigns/campaign_create/steps/step_6/campaign-create-step-6.component'
import { CampaignCreateStep7Component } from './_components/private/campaigns/campaign_create/steps/step_7/campaign-create-step-7.component'
import { CloseViewComponent } from './_components/private/campaigns/campaign_create/nav/close-view'
import { DraftModeManagerComponent } from './_components/private/campaigns/campaign_create/nav/draft-mode-manager'
import { CampaignCreationProgressBar } from './_components/private/campaigns/campaign_create/nav/campaign-create-progress-bar'
import { AlertFieldNotCorrectComponent } from './_components/private/campaigns/campaign_create/modal/alert-field-not-correct'
import { CampaignAnalysisComponent } from './_components/private/campaigns/campaign_analysis'
import { CampaignBriefComponent } from './_components/private/campaigns/campaign_brief'
import { CampaignPublicationsComponent } from './_components/private/campaigns/campaign_publications'
import { CampaignScalersComponent } from './_components/private/campaigns/campaign_scalers'
import { CampaignAllComponent } from './_components/private/campaigns/campaign_all/campaign_all.component'
import { CampaignPdfComponent } from './_components/private/campaigns/campaign_pdf/campaign_pdf.component'

import { ScalerProfileComponent } from './_components/private/scaler_profile'

import { PublisherBillingComponent } from './_components/private/publishers/billing'
import { PublisherCollaborationsComponent } from './_components/private/publishers/collaborations'
import { PublisherDashboardComponent } from './_components/private/publishers/dashboard'
import { PublisherDealComponent } from './_components/private/publishers/deal'
import { PublisherDealsComponent } from './_components/private/publishers/deals'
import { PublisherFaqComponent } from './_components/private/publishers/faq'
import { BrandInfoComponent } from './_components/private/publishers/brand'
import { PublisherOfferComponent } from './_components/private/publishers/offer'
import { PublisherOpportunitiesComponent } from './_components/private/publishers/opportunities'
import { PublisherOpportunityComponent } from './_components/private/publishers/opportunity'
import { PublisherProfileComponent } from './_components/private/publishers/profile'
import { PublisherSettingsComponent } from './_components/private/publishers/settings'

import { AdvertiserDashboardComponent } from './_components/private/advertisers/dashboard'
import { AdvertiserAudiencesComponent } from './_components/private/advertisers/audiences'
import { AdvertiserAudienceComponent } from './_components/private/advertisers/audience'
import { AdvertiserAudienceEditComponent } from './_components/private/advertisers/audience-edit'
import { AdvertiserOpportunitiesComponent } from './_components/private/advertisers/opportunities'
import { AdvertiserOpportunityEditComponent } from './_components/private/advertisers/opportunity-edit'
import { AdvertiserOpportunityInfosComponent } from './_components/private/advertisers/opportunity-infos'
import { AdvertiserDealsComponent } from './_components/private/advertisers/deals'
import { AdvertiserCollaborationsComponent } from './_components/private/advertisers/collaborations'
import { AdvertiserMessagesComponent } from './_components/private/advertisers/messages'
import { AdvertiserWorkspacesComponent } from './_components/private/advertisers/workspaces'
import { AdvertiserWorkspaceSettingsComponent } from './_components/private/advertisers/workspace-settings'
import { AdvertiserBrandEditComponent } from './_components/private/advertisers/brand-edit'
import { AdvertiserBrandViewComponent } from './_components/private/advertisers/brand-view'
import { AdvertiserPublishersListComponent } from './_components/private/advertisers/publishers-list'
import { AdvertiserBillingComponent } from './_components/private/advertisers/billing'

import { ErrorComponent } from './_components/errors/error'

import { InputFieldComponent } from './_directives/forms/input-field'
import { InputTextAreaComponent } from './_directives/forms/input-textarea'
import { PlainButtonComponent } from './_directives/forms/plain-button'
import { CryptrButtonComponent } from './_directives/forms/cryptr-button'
import { TinyCTAComponent } from './_directives/forms/tiny-cta'
import { EditButtonComponent } from './_directives/forms/edit-button'
import { AddButtonComponent } from './_directives/forms/add-button'
import { SectionTitleComponent } from './_directives/forms/section-title'
import { InputDateComponent } from './_directives/forms/input-date'
import { AddTaskComponent } from './_directives/publishers/collaborations/add-task'

import { AlertComponent } from './_directives//alerts/alert'
import { AlertsComponent } from './_directives/alerts/alerts'

import { LoaderComponent } from './_directives/utils/loader'
import { LogoCenterComponent } from './_directives/utils/logo-center'
import { CopyrightComponent } from './_directives/utils/copyright'
import { ThemeSwitcherComponent } from './_directives/utils/theme-switcher'

import { MobileNavBarComponent } from './_directives/navigation/mobile-nav-bar'
import { NavBarComponent } from './_directives//navigation/nav-bar'
import { LeftMenuComponent } from './_directives//navigation/left-menu'

import { ModalComponent } from './_directives/modals/modal'
import { ModalProfileComponent } from './_directives/modals/modal-profile'
import { ModalSettingsComponent } from './_directives/modals/modal-settings'
import { ModalBillingComponent } from './_directives/modals/modal-billing'
import { ModalSupportComponent } from './_directives/modals/modal-support'
import { ModalLogoutComponent } from './_directives/modals/modal-logout'
import { ModalConfirmComponent } from './_directives/modals/modal-confirm'
import { ModalInfoComponent } from './_directives/modals/modal-info'
import { ModalMyWorkspacesComponent } from './_directives/modals/modal-my-workspaces'
import { ModalWorkspaceInvitationsComponent } from './_directives/modals/modal-workspace-invitations'
import { ModalWorkspaceMembersComponent } from './_directives/modals/modal-workspace-members'
import { ModalAccountsComponent } from './_directives/modals/modal-accounts'
import { ModalAlertComponent } from './_directives/modals/modal-alert'
import { ModalKillCampaignComponent } from './_directives/modals/modal-kill-campaign'

import { CampaignSummaryComponent } from './_directives/campaigns/campaign-summary'

import { AdvertiserNavBarComponent } from './_directives/advertisers/navigation/advertiser-nav-bar'
import { NetworkGraphComponent } from './_directives/advertisers/network-graph/network-graph.component'
import { NetworkListComponent } from './_directives/advertisers/network-list/network-list.component'
import { BrandBillingInformationsComponent } from './_directives/advertisers/billing/billing-informations'
import { BrandBillingHistoryComponent } from './_directives/advertisers/billing/billing-history'
import { AdvertiserOpportunityInformationsComponent } from './_directives/advertisers/opportunities/opportunity-informations'
import { AdvertiserOpportunityThumbnailComponent } from './_directives/advertisers/opportunities/opportunity-thumbnail'
import { AdvertiserOpportunityDetailsComponent } from './_directives/advertisers/opportunities/opportunity-details'
import { AdvertiserPlanReachSummaryComponent } from './_directives/advertisers/plans/plan-reach-summary'

import { PublisherNavBarComponent } from './_directives/publishers/navigation/publisher-nav-bar'
import { PublisherSectionIntroComponent } from './_directives/publishers/common/section-intro'

import { CampaignSearchComponent } from './_directives/publishers/campaigns/campaign-search'
import { CampaignThumbnailComponent } from './_directives/publishers/campaigns/campaign-thumbnail'
import { CampaignDetailsComponent } from './_directives/publishers/campaigns/campaign-details'
import { BrandDetailsComponent } from './_directives/publishers/campaigns/brand-details'
import { MakeAnOfferComponent } from './_directives/publishers/campaigns/make-an-offer'

import { DealThumbnailComponent } from './_directives/publishers/deals/deal-thumbnail'
import { DealDetailsComponent } from './_directives/publishers/deals/deal-details'
import { DealKanbanComponent } from './_directives/publishers/deals/deal-kanban'
import { DealKanbanItemComponent } from './_directives/publishers/deals/deal-kanban-item'
import { DealForecastComponent } from './_directives/publishers/deals/deal-forecast'
import { DealMenuComponent } from './_directives/publishers/deals/deal-menu'

import { CollaborationMenuComponent } from './_directives/publishers/collaborations/collaboration-menu'
import { CollaborationsKanbanComponent } from './_directives/publishers/collaborations/collaborations-kanban'
import { CollaborationsFilterComponent } from './_directives/publishers/collaborations/collaboration-filter'
import { CollaborationsKanbanItemComponent } from './_directives/publishers/collaborations/collaborations-kanban-item'
import { CollaborationsConversationsMenuComponent } from './_directives/publishers/collaborations/collaboration-conversations-menu'
import { CollaborationsConversationComponent } from './_directives/publishers/collaborations/collaboration-conversation'

import { DashboardActivitiesComponent } from './_directives/publishers/dashboard/dashboard-activities'
import { DashboardBeginingDealComponent } from './_directives/publishers/dashboard/dashboard-begining-deal'
import { DashboardEndingDealComponent } from './_directives/publishers/dashboard/dashboard-ending-deal'
import { DashboardForecastComponent } from './_directives/publishers/dashboard/dashboard-forecast'
import { DashboardMetricsComponent } from './_directives/publishers/dashboard/dashboard-metrics'
import { DashboardProfileComponent } from './_directives/publishers/dashboard/dashboard-profile'

import { PublisherProfileBiographyComponent } from './_directives/publishers/profile/biography'
import { PublisherProfileProfessionalProfileComponent } from './_directives/publishers/profile/professional-profile'
import { PublisherProfileProfessionalProfilePositionsComponent } from './_directives/publishers/profile/professional-profile/positions'
import { PublisherProfileProfessionalProfileAwardsComponent } from './_directives/publishers/profile/professional-profile/awards'
import { PublisherProfileProfessionalProfileCertificationsComponent } from './_directives/publishers/profile/professional-profile/certifications'
import { PublisherProfileProfessionalProfileEducationsComponent } from './_directives/publishers/profile/professional-profile/educations'
import { PublisherProfileProfessionalProfileSpokenLanguagesComponent } from './_directives/publishers/profile/professional-profile/spoken-languages'
import { PublisherProfileProfessionalProfilePatentsComponent } from './_directives/publishers/profile/professional-profile/patents'
import { PublisherProfileProfessionalProfilePublicationsComponent } from './_directives/publishers/profile/professional-profile/publications'
import { PublisherProfileProfessionalProfileSkillsComponent } from './_directives/publishers/profile/professional-profile/skills'
import { PublisherProfileContentDescriptionComponent } from './_directives/publishers/profile/content-description'
import { PublisherProfileContentDescriptionExpertisesComponent } from './_directives/publishers/profile/content-description/expertises'
import { PublisherProfileContentDescriptionLanguagesComponent } from './_directives/publishers/profile/content-description/languages'
import { PublisherProfileContentDescriptionHashtagsPositionsComponent } from './_directives/publishers/profile/content-description/hashtags'
import { PublisherProfileContentDescriptionNetworksComponent } from './_directives/publishers/profile/content-description/networks'

import { PublisherBillingInformationsComponent } from './_directives/publishers/billing/billing-informations'

import { PublisherPreferencesFormComponent } from './_directives/publishers/settings/preferences-form'

import { AuthModule, AuthHttpInterceptor } from '@cryptr/cryptr-angular'

import {
  APIService,
  ModalService,
  StorageManagementService,
  PublishersBillingAPIService,
  PublishersPreferencesAPIService,
  PublishersOpportunitiesAPIService,
  PublishersDealsAPIService,
  PublishersTasksAPIService,
  PublishersProfileAPIService,
  AuthenticationService,
  InternalAuthenticationService,
  CryptrAuthenticationService,
  AdvertisersTasksAPIService,
  AdvertisersPublishersAPIService,
  AdvertisersRelationshipsAPIService,
  AdvertisersConversationsAPIService,
  AdvertisersAudiencesAPIService,
  AdvertisersOffersAPIService,
  SEOKeywordsCSVFileParsingService,
} from './_services'
import { ConversationsMockService } from './_services/mock/conversations.mock.service'
import { TwitterNetworksMockService } from './_services/mock/networks.mock.service'
import { AlertService } from './_services/alert.service'
import { CacheService } from './_services/cache.service'
import { CampaignCreationBufferService } from './_services/buffers/campaign.creation.buffer.service'
import { CampaignReferentialService } from './_services/referential/campaign.referential.service'
import { TaskReferentialService } from './_services/referential/task.referential.service'
import { DataService } from './_services/data.service'
import { TaskSynchronisationService } from './_services/collaborations/task.synchronisation.service'
import { TasksFilterService } from './_services/collaborations/tasks.filter.service'
import { TaskSocketService } from './_services/sockets/tasks.socket.service'
import { ConversationsSynchronisationService } from './_services/conversations/conversations.synchronisation.service'
import { ConversationSocketService } from './_services/sockets/conversations.socket.service'
import { TokenService } from './_services/token.service'
import { PreferencesManager } from './_services/preferences.manager.service'
import { SignupBufferService } from './_services/buffers/signup.buffer.service'
import { ExternalRequestService } from './_services/external.requests.service'
import { ThemeModule } from './_services/theme/theme.module'

import { UserResolver } from './_resolvers/user.resolver'
import { PublishersOpportunitiesResolver } from './_resolvers/publishers/publishers.opportunities.resolver'
import { PublishersOpportunityResolver } from './_resolvers/publishers/publishers.opportunity.resolver'
import { PublishersDealsResolver } from './_resolvers/publishers/publishers.deals.resolver'
import { PublishersDealResolver } from './_resolvers/publishers/publishers.deal.resolver'
import { PublishersTasksResolver } from './_resolvers/publishers/publishers.tasks.resolver'
import { AdvertisersTasksResolver } from './_resolvers/advertisers/advertisers.tasks.resolver'
import { AdvertisersPublishersResolver } from './_resolvers/advertisers/advertisers.publishers.resolver'
import { AdvertisersRelationshipsResolver } from './_resolvers/advertisers/advertisers.relationships.resolver'
import { AdvertisersRelationshipRequestsResolver } from './_resolvers/advertisers/advertisers.requests.resolver'
import { AdvertisersConversationsResolver } from './_resolvers/advertisers/advertisers.conversations.resolver'
import { AdvertisersAudienceResolver } from './_resolvers/advertisers/advertisers.audience.resolver'
import { AdvertisersAudiencesResolver } from './_resolvers/advertisers/advertisers.audiences.resolver'
import { AdvertisersOffersResolver } from './_resolvers/advertisers/advertisers.offers.resolver'
import { AdvertisersOfferResolver } from './_resolvers/advertisers/advertisers.offer.resolver'

import { TranslationLoader } from './_loaders/translation/translation-loader'
import { lightTheme } from './_services/theme/light-theme'
import { darkTheme } from './_services/theme/dark-theme'
import { PublisherStateService } from './_services/publishers/publisher.state.service'

import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'

import { DragDropModule } from '@angular/cdk/drag-drop'

import { NgCircleProgressModule } from 'ng-circle-progress'

// AoT requires an exported function for factories
// If you want i18n to be managed locally
/*
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
*/

// If you want i18n to be managed on server
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    PublicMultipleColumnsLayoutComponent,
    LoginComponent,
    SignupComponent,
    SignupStep0Component,
    SignupStep1Component,
    SignupStep2Component,
    SignupStep3Component,
    SignupStep4Component,
    PasswordRecoveryComponent,
    PasswordChangeComponent,
    HomeComponent,
    ProfileComponent,
    ProfileInfluencerComponent,
    JourneyStartComponent,
    ProjectCreateComponent,
    ProjectDashboardComponent,
    ProjectSettingsComponent,
    ProjectSettingsEditComponent,
    ProjectBrandComponent,
    ProjectBrandEditComponent,
    ProjectBrandAuthorityComponent,
    ProjectBillingComponent,
    ProjectBillingEditComponent,
    CampaignCreateComponent,
    CampaignCreateStep0Component,
    CampaignCreateStep1Component,
    CampaignCreateStep2Component,
    CampaignCreateStep3Component,
    CampaignCreateStep4Component,
    CampaignCreateStep5Component,
    CampaignCreateStep6Component,
    CampaignCreateStep7Component,
    CloseViewComponent,
    DraftModeManagerComponent,
    CampaignCreationProgressBar,
    AlertFieldNotCorrectComponent,
    CampaignAnalysisComponent,
    CampaignBriefComponent,
    CampaignPublicationsComponent,
    CampaignScalersComponent,
    ScalerProfileComponent,
    CampaignSummaryComponent,
    CampaignPdfComponent,
    ErrorComponent,
    InputFieldComponent,
    InputTextAreaComponent,
    InputDateComponent,
    AddTaskComponent,
    PlainButtonComponent,
    CryptrButtonComponent,
    TinyCTAComponent,
    EditButtonComponent,
    AddButtonComponent,
    SectionTitleComponent,
    AlertComponent,
    AlertsComponent,
    LoaderComponent,
    LogoCenterComponent,
    CopyrightComponent,
    ThemeSwitcherComponent,
    MobileNavBarComponent,
    NavBarComponent,
    LeftMenuComponent,
    ModalComponent,
    ModalProfileComponent,
    ModalSettingsComponent,
    ModalBillingComponent,
    ModalSupportComponent,
    ModalConfirmComponent,
    ModalInfoComponent,
    ModalLogoutComponent,
    ModalMyWorkspacesComponent,
    ModalWorkspaceInvitationsComponent,
    ModalWorkspaceMembersComponent,
    ModalKillCampaignComponent,
    CampaignAllComponent,
    ModalAccountsComponent,
    ModalAlertComponent,
    AdvertiserDashboardComponent,
    AdvertiserOpportunitiesComponent,
    AdvertiserDealsComponent,
    AdvertiserCollaborationsComponent,
    AdvertiserMessagesComponent,
    AdvertiserWorkspacesComponent,
    AdvertiserWorkspaceSettingsComponent,
    AdvertiserBrandEditComponent,
    AdvertiserBrandViewComponent,
    AdvertiserAudiencesComponent,
    AdvertiserAudienceComponent,
    AdvertiserAudienceEditComponent,
    AdvertiserPublishersListComponent,
    AdvertiserBillingComponent,
    AdvertiserOpportunityEditComponent,
    AdvertiserOpportunityInfosComponent,
    AdvertiserNavBarComponent,
    AdvertiserOpportunityInformationsComponent,
    AdvertiserOpportunityThumbnailComponent,
    AdvertiserOpportunityInformationsComponent,
    AdvertiserPlanReachSummaryComponent,
    NetworkGraphComponent,
    NetworkListComponent,
    BrandBillingInformationsComponent,
    BrandBillingHistoryComponent,
    PublisherNavBarComponent,
    PublisherBillingComponent,
    PublisherCollaborationsComponent,
    PublisherDashboardComponent,
    PublisherDealComponent,
    PublisherDealsComponent,
    BrandInfoComponent,
    PublisherFaqComponent,
    PublisherOfferComponent,
    PublisherOpportunitiesComponent,
    PublisherOpportunityComponent,
    PublisherProfileComponent,
    PublisherSettingsComponent,
    DealThumbnailComponent,
    CampaignSearchComponent,
    CampaignThumbnailComponent,
    CampaignDetailsComponent,
    BrandDetailsComponent,
    MakeAnOfferComponent,
    DealThumbnailComponent,
    DealDetailsComponent,
    DealKanbanComponent,
    DealKanbanItemComponent,
    DealForecastComponent,
    DealMenuComponent,
    CollaborationMenuComponent,
    CollaborationsKanbanComponent,
    CollaborationsFilterComponent,
    CollaborationsKanbanItemComponent,
    CollaborationsConversationsMenuComponent,
    CollaborationsConversationComponent,
    DashboardActivitiesComponent,
    DashboardBeginingDealComponent,
    DashboardEndingDealComponent,
    DashboardForecastComponent,
    DashboardMetricsComponent,
    DashboardProfileComponent,
    PublisherProfileBiographyComponent,
    PublisherProfileContentDescriptionComponent,
    PublisherProfileContentDescriptionExpertisesComponent,
    PublisherProfileContentDescriptionHashtagsPositionsComponent,
    PublisherProfileContentDescriptionLanguagesComponent,
    PublisherProfileContentDescriptionNetworksComponent,
    PublisherProfileProfessionalProfileComponent,
    PublisherProfileProfessionalProfilePositionsComponent,
    PublisherProfileProfessionalProfileAwardsComponent,
    PublisherProfileProfessionalProfileCertificationsComponent,
    PublisherProfileProfessionalProfileSpokenLanguagesComponent,
    PublisherProfileProfessionalProfileEducationsComponent,
    PublisherProfileProfessionalProfilePatentsComponent,
    PublisherProfileProfessionalProfilePublicationsComponent,
    PublisherProfileProfessionalProfileSkillsComponent,
    PublisherBillingInformationsComponent,
    PublisherSectionIntroComponent,
    PublisherPreferencesFormComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppPublishersRoutingModule,
    AppBrandsRoutingModule,
    AppBrandsV2RoutingModule,
    AppCommonRoutingModule,
    DragDropModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light',
    }),
    AuthModule.forRoot(environment.cryptrConfig),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animation: true,
      animationDuration: 900,
      backgroundColor: '#30313e',
      showZeroOuterStroke: false,
      showInnerStroke: false,
      unitsColor: '#ffffff',
      titleColor: '#ffffff',
      titleFontSize: '30',
      showSubtitle: false,
      backgroundPadding: -16,
      outerStrokeLinecap: 'initial',
      renderOnClick: false,
    }),
    ChartsModule,
    SocketIoModule,
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    AlertService,
    ConversationsMockService,
    TwitterNetworksMockService,
    APIService,
    StorageManagementService,
    SEOKeywordsCSVFileParsingService,
    PublishersOpportunitiesAPIService,
    PublishersDealsAPIService,
    PublishersTasksAPIService,
    AdvertisersTasksAPIService,
    AdvertisersPublishersAPIService,
    AdvertisersRelationshipsAPIService,
    AdvertisersConversationsAPIService,
    AdvertisersAudiencesAPIService,
    AdvertisersOffersAPIService,
    PublishersBillingAPIService,
    PublishersPreferencesAPIService,
    PublishersProfileAPIService,
    AuthenticationService,
    InternalAuthenticationService,
    CryptrAuthenticationService,
    CacheService,
    CampaignCreationBufferService,
    CampaignReferentialService,
    TaskReferentialService,
    DataService,
    TaskSynchronisationService,
    TaskSocketService,
    TasksFilterService,
    ConversationsSynchronisationService,
    ConversationSocketService,
    ModalService,
    PublisherStateService,
    PreferencesManager,
    TokenService,
    SignupBufferService,
    ExternalRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    UserResolver,
    PublishersOpportunitiesResolver,
    PublishersOpportunityResolver,
    PublishersDealsResolver,
    PublishersDealResolver,
    PublishersTasksResolver,
    AdvertisersTasksResolver,
    AdvertisersPublishersResolver,
    AdvertisersRelationshipsResolver,
    AdvertisersRelationshipRequestsResolver,
    AdvertisersConversationsResolver,
    AdvertisersAudienceResolver,
    AdvertisersAudiencesResolver,
    AdvertisersOffersResolver,
    AdvertisersOfferResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
