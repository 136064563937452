import { Country } from './country.model'

/**
 *
 *
 * @export
 * @class State
 */
export class State {
  /**
   * id is an instance will contain id of country
   * @type {number}
   * @memberof State
   */
  id: number

  /**
   * name is an instance will contain name of country
   * @type {string}
   * @memberof State
   */
  name: string

  /**
   * country is an instance will countain the country
   * @type {Country}
   * @memberof State
   */
  country: Country

  /**
   * Creates an instance of State.
   * @param {*} [values=undefined]
   * @memberof State
   */
  constructor(values = undefined) {
    if (values == undefined) {
      this.id = 0
      this.name = ''
      this.country = undefined
    } else {
      this.id = values['id']
      this.name = values['name']
      this.country = values['country']
    }
  }

  /**
   * Returns a builder instance, build with id, name of country and country
   * @static
   * @return {*}
   * @memberof State
   */
  static getBuilder() {
    class Builder {
      id: number
      name: string
      country: Country
      constructor() {}
      withIdentifier(id: number = -1): Builder {
        this.id = id
        return this
      }
      withName(name: string): Builder {
        this.name = name
        return this
      }
      withCountry(country: Country): Builder {
        this.country = country
        return this
      }
      build(): State {
        return new State(this)
      }
    }
    return Builder
  }
}
