import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../../_services/index'
import { DataService } from './../../../../_services/data.service'

import { DateHelper } from './../../../../_helpers/date.helpers'

import { PreferencesManager } from './../../../../_services/preferences.manager.service'

@Component({
  selector: 'app-campaign_pdf',
  templateUrl: './campaign_pdf.component.html',
  styleUrls: ['./campaign_pdf.component.scss'],
})
export class CampaignPdfComponent implements OnInit {
  isLoading: boolean
  id: string
  project_id: string
  private sub: any

  keywords: any

  campaign: any
  project: any

  target: any
  date_start: any
  date_end: any

  moodboard: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private preferencesManager: PreferencesManager
  ) {
    this.isLoading = true

    this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_NOT_DEFINED'
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['campaignId']
      this.project_id = params['projectId']

      this.campaign = this.dataService.getCampaign(this.id) // await this.cacheService.getCampaign(this.id)
      this.project = this.dataService.getProject(this.project_id) // await this.cacheService.getProject(this.project_id)

      if (this.campaign.target === 'audience_growth') {
        this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_AUDIENCE_GROWTH'
      }

      if (this.campaign.target === 'audience_conversion') {
        this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_AUDIENCE_CONVERSION'
      }

      if (this.campaign.target === 'sales_boost') {
        this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_SALES_BOOST'
      }

      this.date_start = DateHelper.convertDoubleToReadableDate(
        this.campaign.startDate
      )
      this.date_end = DateHelper.convertDoubleToReadableDate(
        this.campaign.endDate
      )

      this.moodboard = this.campaign.moodboard

      this.isLoading = false
    })
  }

  ngAfterViewInit() {
    var __this = this
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
