import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersProfileAPIService } from 'src/app/_services'

@Component({
  templateUrl: 'publisher-profile-content-description-hashtags.component.html',
  selector: 'publisher-profile-content-description-hashtags',
  styleUrls: [
    './publisher-profile-content-description-hashtags.component.scss',
  ],
})
export class PublisherProfileContentDescriptionHashtagsPositionsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  hashtagsForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  hashtags: any[]
  hashtag: any
  hashtagId: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    this.hashtagsForm = this.formBuilder.group({
      nameForm: this.formBuilder.group({
        name: '',
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getHashtags()
  }

  ngOnDestroy() {}

  hasHashtags() {
    try {
      return (
        this?.publisher?.publisher?.contentDescription?.mainHashtags !=
        undefined
      )
    } catch (e) {
      return false
    }
  }

  getHashtags() {
    if (this.hasHashtags() == true) {
      this.hashtags = this.publisher.publisher.contentDescription.mainHashtags
      return this.hashtags
    } else return undefined
  }

  getNumberOfHashtags() {
    let hashtags = this.getHashtags()
    if (hashtags == undefined) return 0

    return hashtags.length
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let hashtagField = this.readTextValue(
        this.hashtagsForm.controls.nameForm['controls'].name.value
      )

      if (
        hashtagField != undefined &&
        this.hashtagsForm.controls.nameForm['controls'].name.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Hashtag field can not be empty')
      }

      if (hashtagField.charAt(0) != '#') {
        hashtagField =
          '#' + this.hashtagsForm.controls.nameForm['controls'].name.value
      }

      if (this.isValid == true) {
        this.isValid = true

        let expertise_infos = {
          tag: hashtagField,
        }

        let res = await this.apiService.addHashtagInformation(
          this.publisher.publisher._id,
          expertise_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeNotEditable()
          this.clearInputField()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteHashtag(hashtag) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let hashtagId = hashtag._id

      let res = await this.apiService.deleteHashtagInformation(
        this.publisher.publisher._id,
        hashtagId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.makeNotEditable()
        this.publisher.publisher = res.body.publisher
        this.closeModalIfNoExpertise()
        this.successfullyRemoved = true
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addHashtag() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateHashtag() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  validateHashtag() {}

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  clearInputField() {
    this.hashtagsForm = this.formBuilder.group({
      nameForm: this.formBuilder.group({
        name: '',
      }),
    })
  }

  closeModalIfNoExpertise() {
    if (this.getHashtags().length == 0) {
      this.closeModal()
    }
  }
}
