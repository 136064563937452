<div [formGroup]="parentGroup">
  <div [formGroupName]="formGroupName">
    <textarea
      type="text"
      [placeholder]="placeholder"
      class="input-text text-lato text-medium text-darkgrey"
      [id]="identifier"
      [name]="name"
      [formControlName]="relatedControlName"
      autocomplete="off"
      rows="8"
      >{{ value }}</textarea
    >
  </div>
</div>
