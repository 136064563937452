import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'

import { CampaignType } from './../../_models/referential/campaign_type.model'
import { Network } from './../../_models/referential/network.model'
import { State } from './../../_models/referential/state.model'
import { Country } from './../../_models/referential/country.model'

import { environment } from './../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class CampaignReferentialService {
  private targets: CampaignType[]
  private networks: Network[]

  private countries: Country[]
  private states: State[]

  public constructor(private http: HttpClient) {
    this.initService()
  }

  public async initService() {
    this.initTargets()
    this.initNetworks()
    await this.initCountriesAndStates()
  }

  public initTargets() {
    this.targets = CampaignReferentialService.getTargets()
  }

  public getTargets(): CampaignType[] {
    if (this.targets == [] || this.targets == undefined) this.initTargets()
    return this.targets
  }

  public static getTargets(): CampaignType[] {
    /*return [
      {
        id: '1',
        text: 'COMMON.TARGET_FIND_AMBASSADORS',
        info: 'find_ambassadors',
        img: '../../assets/images/icon_handshake_pink.png',
        img_selected: '../../assets/images/icon_handshake_white.png',
      },
      {
        id: '2',
        text: 'COMMON.TARGET_AUDIENCE_GROWTH',
        info: 'audience_growth',
        img: '../../assets/images/icon_audience_2_pink.png',
        img_selected: '../../assets/images/icon_audience_2_white.png',
      },
      {
        id: '3',
        text: 'COMMON.TARGET_SALES_BOOST',
        info: 'sales_boost',
        img: '../../assets/images/icon_sales_pink.png',
        img_selected: '../../assets/images/icon_sales_white.png',
      },
    ]*/
    return [
      {
        id: '1',
        text: 'OPPORTUNITIES.CAMPAIGN_TYPE_DISCOVERY',
        info: 'discovery',
        img: '../../assets/images/icon_audience_2_pink.png',
        img_selected: '../../assets/images/icon_audience_2_white.png',
      },
      {
        id: '2',
        text: 'OPPORTUNITIES.CAMPAIGN_TYPE_COLLABORATION',
        info: 'collaboration',
        img: '../../assets/images/icon_handshake_pink.png',
        img_selected: '../../assets/images/icon_handshake_white.png',
      },
    ]
  }

  public initNetworks() {
    this.networks = CampaignReferentialService.getNetworks()
  }

  public getNetworks(): Network[] {
    if (this.networks == [] || this.networks == undefined) this.initNetworks()
    return this.networks
  }

  public static getNetworks(): Network[] {
    return [
      {
        id: 'web',
        name: 'Website',
        img: '../../assets/images/icons/icon_web_unselected.png',
        img_selected: '../../assets/images/icons/icon_web_selected.png',
      },
      {
        id: 'twitter',
        name: 'Twitter',
        img: '../../assets/images/icons/icon_twitter_unselected.png',
        img_selected: '../../assets/images/icons/icon_twitter_selected.png',
      },
      {
        id: 'linkedin',
        name: 'LinkedIn',
        img: '../../assets/images/icons/icon_linkedin_unselected.png',
        img_selected: '../../assets/images/icons/icon_linkedin_selected.png',
      },
      {
        id: 'facebook',
        name: 'Facebook',
        img: '../../assets/images/icons/icon_facebook_unselected.png',
        img_selected: '../../assets/images/icons/icon_facebook_selected.png',
      },
      {
        id: 'youtube',
        name: 'YouTube',
        img: '../../assets/images/icons/icon_youtube_unselected.png',
        img_selected: '../../assets/images/icons/icon_youtube_selected.png',
      },
      {
        id: 'medium',
        name: 'Medium',
        img: '../../assets/images/icons/icon_medium_unselected.png',
        img_selected: '../../assets/images/icons/icon_medium_selected.png',
      },
      {
        id: 'podcast',
        name: 'Podcast',
        img: '../../assets/images/icons/icon_podcast_unselected.png',
        img_selected: '../../assets/images/icons/icon_podcast_selected.png',
      },
      {
        id: 'instagram',
        name: 'Instagram',
        img: '../../assets/images/icons/icon_instagram_unselected.png',
        img_selected: '../../assets/images/icons/icon_instagram_selected.png',
      },
    ]
  }

  async initCountriesAndStates() {
    try {
      var json = await this.http
        .get(BACKEND_URL + '/public/i18n/en.json')
        .toPromise()

      if (json != undefined) {
        this.countries = []
        this.states = []
        if (
          json['COUNTRIES'] &&
          json['COUNTRIES']['LIST'] &&
          json['COUNTRIES']['STATES']
        ) {
          var i_country = 0
          var i_state = 0
          for (var key in json['COUNTRIES']['LIST']) {
            let countryToAdd = new (Country.getBuilder())()
              .withIdentifier(i_country)
              .withName('COUNTRIES.LIST.' + key)
              .build()

            for (var state in json['COUNTRIES']['STATES']) {
              if (state.startsWith(key) || state === 'EVERYWHERE') {
                let stateToAdd = new (State.getBuilder())()
                  .withIdentifier(i_state)
                  .withName('COUNTRIES.STATES.' + state)
                  .withCountry(countryToAdd)
                  .build()
                this.states.push(stateToAdd)
                i_state++
              }
            }

            this.countries.push(countryToAdd)
            i_country++
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  public async getStates(): Promise<State[]> {
    if (this.states == undefined || this.states == [])
      await this.initCountriesAndStates()
    return this.states
  }

  public async getCountries(): Promise<Country[]> {
    if (this.countries == undefined || this.countries == [])
      await this.initCountriesAndStates()
    return this.countries
  }

  public getProgressSteps() {
    return [
      {
        step: '1',
        name: 'CAMPAIGN.CREATE.STEPS_1',
        value: '0',
      },
      {
        step: '2',
        name: 'CAMPAIGN.CREATE.STEPS_2',
        value: '1',
      },
      {
        step: '3',
        name: 'CAMPAIGN.CREATE.STEPS_3',
        value: '2',
      },
      {
        step: '4',
        name: 'CAMPAIGN.CREATE.STEPS_4',
        value: '3',
      },
      {
        step: '5',
        name: 'CAMPAIGN.CREATE.STEPS_5',
        value: '4',
      },
      {
        step: '6',
        name: 'CAMPAIGN.CREATE.STEPS_6',
        value: '5',
      },
      {
        step: '7',
        name: 'CAMPAIGN.CREATE.STEPS_7',
        value: '6',
      },
    ]
  }

  public getLocations() {
    return [
      { key: 'global', text: 'Global' },
      { key: 'fr', text: 'France' },
      { key: 'uk', text: 'United Kingdom' },
      { key: 'us', text: 'USA' },
    ]
  }

  public getLanguages() {
    return [
      { key: 'en', text: 'English' },
      { key: 'fr', text: 'French' },
    ]
  }

  public getLanguageStatuses() {
    return [
      { key: 'elementary', text: 'Elementary proficiency' },
      { key: 'limited-working', text: 'Limited working proficiency' },
      { key: 'professional-working', text: 'Professional working proficiency' },
      {
        key: 'full-professional',
        text: 'Full professional working proficiency',
      },
      { key: 'native-or-bilingual', text: 'Native or bilingual proficiency' },
    ]
  }
}
