<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div class="content-page">
      <div id="deals-title">
        <publisher-section-intro title="DEAL SUMMARY"></publisher-section-intro>
      </div>
      <div id="deal-wrappers">
        <deal-details
          [deal]="deal"
          (showModalEvent)="manageModalEvent($event)"
        ></deal-details>
      </div>
    </div>
  </div>
  <!--loader *ngIf="loading == true"></loader-->
</div>

<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 10"
  *ngIf="displayModalBackground == true"
>
  <div
    class="col-12 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
  ></div>
</div>
