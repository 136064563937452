<div class="container-fluid">
  <div class="row" [ngClass]="ltr == true ? 'flex-ltr' : 'flex-rtl'">
    <div class="col-12 col-lg-7 d-none d-lg-flex no-padding">
      <logo-center></logo-center>
    </div>
    <div class="col-12 col-lg-5 d-flex full-height no-padding hv-center">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
