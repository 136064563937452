<div class="col-12 d-flex flex-column">
  <div class="text-right" style="padding-top: 15px">
    <!--span class="top-text text-lato text-grey text-small">Vous n'avez pas de compte ?<span class="btn btn-outline-primary left-margin text-button">CONTACTEZ
                            NOUS</span></span-->
    <span class="top-text text-lato text-grey text-small"
      ><a (click)="stepBack()"
        ><span
          class="btn btn-outline-primary left-margin text-button"
          style="border-width: 0 !important"
          >{{ 'SIGNUP.TEXT_GO_BACK' | translate }}</span
        ></a
      ></span
    >
  </div>
</div>
<div
  class="col-12 d-flex flex-column v-align full-height"
  style="align-items: center"
>
  <div>
    <img style="width: 240px" src="./assets/images/fns_logo.png" />
  </div>
  <h1
    class="text-lato text-bold text-big text-darkgrey line-height-115"
    style="
      margin-left: 26.5px;
      margin-right: 26.5px;
      margin-top: 67px;
      margin-bottom: 31px;
      font-size: 38px;
    "
  >
    {{ 'SIGNUP.TITLE_LINE_1_STEP_3' | translate }}
  </h1>
  <p class="text-lato text-medium margin-v-30">
    {{ 'SIGNUP.TITLE_LINE_2_STEP_3' | translate }} <br /><br /><span
      style="color: #949499"
      >{{ 'SIGNUP.TEXT_LINE_1_STEP_3' | translate }}</span
    >
  </p>
  <div class="row" style="text-align: center">
    <form
      class="margin-v-39 max-w-400"
      [formGroup]="passwordForm"
      (ngSubmit)="createPassword()"
    >
      <div class="row">
        <div class="col-12">
          <input
            type="password"
            name="password"
            id="password"
            formControlName="password"
            style="text-align: center"
            class="input-text text-lato text-medium text-darkgrey"
            placeholder="{{ 'SIGNUP.INPUT_PASSWORD_PLACEHOLDER' | translate }}"
          />
        </div>
        <div class="col-12" *ngIf="isValid == false">
          <p class="text-lato text-medium" style="color: #949499">
            {{ 'SIGNUP.ERROR_STEP_3_PASSWORD_TOO_SHORT' | translate }}
          </p>
        </div>
        <div class="col-12" style="margin-top: 31px">
          <input
            class="btn btn-primary btn-big text-lato text-bold"
            type="submit"
            value="{{ 'SIGNUP.TEXT_BUTTON_NEXT_STEP_3' | translate }}"
          />
        </div>
      </div>
    </form>
  </div>
</div>
