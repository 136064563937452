import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core'

import { TaskSynchronisationService } from '../../../../_services/collaborations/task.synchronisation.service'
import { DataService } from '../../../../_services/data.service'
import { DateHelper } from '../../../../_helpers/date.helpers'
import { TaskReferentialService } from 'src/app/_services/referential/task.referential.service'
import { FormBuilder } from '@angular/forms'
import { EditableTask } from 'src/app/_models/task/editable.task.model'

@Component({
  templateUrl: 'add-task.component.html',
  selector: 'add-task',
  styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit {
  @Output() showModalEvent = new EventEmitter<boolean>()

  currentTaskForm: any

  user: any
  influencerName: any

  showAssigneeMenu: boolean
  showCampaignNameMenu: boolean
  showDealNameMenu: boolean
  showBrandMenu: boolean
  showStepStatusMenu: boolean
  showPriorityMenu: boolean
  showModalConfirm: boolean

  taskNameShowBorder: any
  taskDescriptionShowBorder: any

  marks: any[]
  assignees: any[]
  campaignName: any[]
  stepStatus: any[]
  priorities: any[]
  assigness: any[]
  commentsArray: any[]
  brands: any[]

  markValue: any
  status: any
  assigneeTo: any
  brandChoose: any
  dueDate: any
  campaignNameNow: any
  description: any
  comment: any

  constructor(
    private taskSynchronisationService: TaskSynchronisationService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    public taskReferentialService: TaskReferentialService
  ) {
    this.showAssigneeMenu = false
    this.showCampaignNameMenu = false
    this.showDealNameMenu = false
    this.showBrandMenu = false
    this.showStepStatusMenu = false
    this.showPriorityMenu = false
    this.showModalConfirm = false

    //this.markValue = 'Mark complete'
    this.campaignNameNow = 'No campaign'

    this.taskNameShowBorder = 'hide'
    this.taskDescriptionShowBorder = 'hide'

    this.marks = taskReferentialService.getTaskCompletionStatuses()
    this.stepStatus = taskReferentialService.getTaskStepStatuses()
    this.priorities = taskReferentialService.getTaskPriorities()
    this.assignees = taskReferentialService.getTaskAssignees()
  }

  async ngOnInit() {
    // Initialize form with currentTaskForm from task.service
    this.initForm()

    this.user = this.dataService.getMe()
    this.influencerName = this.user.infos.fullName

    // Init assignee publisher
    this.taskReferentialService.setTaskAssigneePublisherName(
      this.user.infos.fullName
    )

    this.sendCurrentTaskId()
    this.initValues()

    this.taskSynchronisationService.getEditedTask().subscribe((task) => {
      this.fillFormWithTask(task)
    })
  }

  ngOnDestroy() {}

  initValues() {
    /*if (this.currentTaskForm.controls.assignee.value != '') {
      this.assigneeTo = this.currentTaskForm.controls.assignee.value
    } else {
      this.assigneeTo = 'No assignee'
    }*/

    this.fillFormWithTask(this.getTask())

    this.assigneeTo = this.taskReferentialService.getTaskAssigneeText(
      this.getTask().assignee
    )
    if (this.assigneeTo == undefined) this.assigneeTo = 'No assignee'

    if (this.brandChoose == undefined) this.brandChoose = 'No brand'

    /*if (this.currentTask != undefined)
      this.dealNameNow = this.currentTask._scope.title
    if (this.currentTask != undefined)
      this.dealPicture = this.currentTask._brand.pictureURL*/

    /*this.commentsArray = this.taskSynchronisationService
      .getCommentsArray()
      .getValue()*/
    this.getBrands()
  }

  initForm() {
    this.currentTaskForm = this.formBuilder.group({
      //mark: '',
      //campaignName: '',
      nameForm: this.formBuilder.group({
        name: '',
      }),
      assignee: 'publisher',
      dueDate: [undefined],
      step: 'backlog',
      priority: 'not_set',
      descriptionForm: this.formBuilder.group({
        description: '',
      }),
      commentForm: this.formBuilder.group({
        comment: '',
      }),
      deal: '',
      brand: '',
    })
  }

  fillFormWithTask(task: EditableTask) {
    // Set name
    this.currentTaskForm.controls.nameForm['controls'].name.setValue(task.name)
    // Set step
    this.currentTaskForm.controls.step.setValue(task.step)
    // Set dueDate
    this.currentTaskForm.controls.dueDate.setValue(new Date(task.dueDate))
    // Set priority
    this.currentTaskForm.controls.priority.setValue(task.priority)
    // Set assignee
    this.currentTaskForm.controls.assignee.setValue(task.assignee)
    // Set description
    this.currentTaskForm.controls.descriptionForm[
      'controls'
    ].description.setValue(task.description)
    // Set comments
    //this.currentTaskForm.controls.commentForm['controls'].comment.setValue(task.comments)

    // Set Deal id
    this.currentTaskForm.controls.deal.setValue(task.deal)

    // Set status
    this.getStatus(task.status)

    // Set brand
    this.currentTaskForm.controls.brand.setValue(task.brand)
  }

  getTask() {
    return this.taskSynchronisationService.getEditedTask().getValue()
  }

  async updateStep(key) {
    this.taskSynchronisationService.updateField('step', key)
    await this.taskSynchronisationService.synchronize()
  }

  async updatePriority(key) {
    this.taskSynchronisationService.updateField('priority', key)
    await this.taskSynchronisationService.synchronize()
  }

  async updateAssignee(key) {
    this.taskSynchronisationService.updateField('assignee', key)
    this.assigneeTo = this.currentTaskForm.controls.assignee.value
    await this.taskSynchronisationService.synchronize()
  }

  async updateBrand(key) {
    this.confirmBrand()
    //this.taskSynchronisationService.updateField('brand', key)
    //this.brandChoose = this.currentTaskForm.controls.brand.value
    //await this.taskSynchronisationService.synchronize()
  }

  readDate(date) {
    return DateHelper.convertDoubleToReadableDate(date, 'MM/DD/YYYY')
  }

  async closeModal() {
    await this.taskSynchronisationService.synchronize()
    console.log('closing modal')
    this.showModalEvent.emit(false)
  }

  toggleAssigneeMenu() {
    this.showAssigneeMenu = true
  }

  toggleCampaignNameMenu() {
    this.showCampaignNameMenu = true
  }

  toggleDealNameMenu() {
    this.showDealNameMenu = true
  }

  toggleBrandMenu() {
    this.showBrandMenu = true
  }

  toggleStepMenu() {
    this.showStepStatusMenu = true
  }

  togglePriorityMenu() {
    this.showPriorityMenu = true
  }

  getStatus(status) {
    if (status === 'created') {
      this.markValue = 'Mark complete'
    } else if (status === 'completed') {
      this.markValue = 'Completed'
    }
  }

  async switchMark() {
    if (this.markValue === 'Mark complete') {
      this.markValue = 'Completed'
      this.taskSynchronisationService.updateField('status', 'completed')
      await this.taskSynchronisationService.synchronize()
    } else {
      this.markValue = 'Mark complete'
      this.taskSynchronisationService.updateField('status', 'created')
      await this.taskSynchronisationService.synchronize()
    }
  }

  showBorderField() {
    this.taskNameShowBorder = 'show'
  }

  showDescriptionBorderField() {
    this.taskDescriptionShowBorder = 'show'
  }

  @HostListener('click', ['$event.target.id'])
  documentClickPlaceholder(id: string) {
    // your click logic
    if (id === 'title') {
      this.taskNameShowBorder = 'show'
    }

    if (id === '' || id === 'title') {
      this.taskDescriptionShowBorder = 'hide'
    }

    if (id === 'description') {
      this.taskDescriptionShowBorder = 'show'
    }

    if (id === '' || id === 'description') {
      this.taskNameShowBorder = 'hide'
    }

    if (id != 'priority') this.showPriorityMenu = false
    if (id != 'assignee') this.showAssigneeMenu = false
    if (id != 'stepStatus') this.showStepStatusMenu = false
    if (id != 'campaignName') this.showCampaignNameMenu = false
    if (id != 'dealName') this.showDealNameMenu = false
    if (id != 'brand') this.showBrandMenu = false
  }

  sendCurrentTaskId() {}

  sendTaskToCheck() {
    // Ask to task.service to save task for checking
    //this.taskSynchronisationService.saveTaskForCheck()
    console.log('saving task for checking')
  }

  sendAssignee() {
    /*let data = {
      assignee: this.assigneeType,
    }
    console.log('assignee sent')*/
    //this.taskSynchronisationService.sendTaskAssignee(data)
  }

  sendDueDate() {
    let data = {
      dueDate: this.dueDate,
    }
    //this.taskSynchronisationService.sendTaskDueDate(data)
  }

  sendDealId() {
    /*let data = {
      dealId: this.dealIdNow,
    }*/
    //this.taskSynchronisationService.sendTaskDealId(data)
  }

  sendDescription() {
    let data = {
      description: this.description,
    }
    //this.taskSynchronisationService.sendTaskDescription(data)
  }

  sendComment() {
    let data = {
      text: this.comment,
    }
    //this.taskSynchronisationService.sendTaskComment(data)
  }

  // When user click inside field save task
  @HostListener('document:click', ['$event.target.id'])
  documentClickField(id: string) {
    if (id === 'title' || id === 'description' || id === 'comment') {
      console.log('click inside field')
    }
  }

  async saveTaskNameOnInputChange(searchValue: any) {
    this.taskSynchronisationService.updateField(
      'name',
      searchValue.target.value
    )
    await this.taskSynchronisationService.synchronize()
  }

  async saveDascriptionOnInputChange(searchValue: any) {
    this.taskSynchronisationService.updateField(
      'description',
      searchValue.target.value
    )
    await this.taskSynchronisationService.synchronize()
  }

  async saveCommentOnInputChange(searchValue: any) {
    this.taskSynchronisationService.updateField(
      'comments',
      searchValue.target.value
    )
    await this.taskSynchronisationService.synchronize()
  }

  changeDate(event) {
    this.taskSynchronisationService.updateField('dueDate', event.target.value)
    /*this.dueDate = event.target.value
    this.sendDueDate()
    this.sendCurrentTaskId()*/
  }

  saveAssignee(assignee, type) {
    /*this.assigneeTo = assignee
    this.assigneeType = type
    this.sendAssignee()
    this.sendCurrentTaskId()*/
  }

  saveStepStatus(status) {
    /*this.stepStatusNow = status
    this.sendStepStatus()
    this.sendCurrentTaskId()*/
  }

  saveDealData(name, id, picture) {
    /*this.dealNameNow = name
    this.dealIdNow = id
    this.dealPicture = picture
    this.sendDealId()
    this.sendCurrentTaskId()*/
  }

  savePriority(priority) {
    /*this.priorityNow = priority
    this.sendPriority()
    this.sendCurrentTaskId()*/
  }

  getCurrentTask() {
    // Get current task from API
    /*this.currentTask = this.taskSynchronisationService.getCurrentTask()
    this.currentTaskId = this.taskSynchronisationService
      .getCurrentTasksId()
      .getValue()*/
  }

  deleteTask() {
    this.taskSynchronisationService.deleteTask(this.getTask().taskId)
  }

  getDeals() {
    return this.taskSynchronisationService.getDeals().getValue()
  }

  getBrands() {
    //console.log(this.taskSynchronisationService.getBrands().getValue())
    return this.taskSynchronisationService.getBrands().getValue()
  }

  confirmBrand() {
    console.log('in confirm brand')
    this.showModalConfirm = true
    console.log(this.showModalConfirm)
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  closeModalConfirm() {
    this.showModalConfirm = false
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
