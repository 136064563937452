<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div class="form-title">
      <publisher-section-intro
        title="{{ 'PROJECT.SETTINGS.BIG_TITLE' | translate }}"
        description="{{ 'PROJECT.SETTINGS.SUBTITLE' | translate }}"
      ></publisher-section-intro>
    </div>
    <div
      class="display-block col-direction section-container justify-content-center"
    >
      <div
        class="row no-margin margin-top-25 justify-content-center content-wrapper"
      >
        <div
          class="col-12 one-edge-shadow form-background-color"
          style="
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 8px;
            padding-right: 8px;
          "
          *ngIf="isLoading == false"
        >
          <div class="row margin-v-15 margin-h-9">
            <div
              class="cursor"
              style="
                display: flex;
                flex-direction: row;
                position: relative;
                width: 100%;
              "
            >
              <div style="margin-top: auto; margin-bottom: auto">
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 50%;
                  "
                  [src]="project.pictureURL"
                />
              </div>
              <div
                style="
                  margin-top: auto;
                  margin-bottom: auto;
                  margin-right: 0px;
                  padding-left: 16px;
                  padding-right: 16px;
                  max-width: calc(100% - 68px);
                  width: calc(100% - 68px);
                "
              >
                <div
                  class="text-bold text-large"
                  style="white-space: normal; overflow: hidden"
                >
                  {{ project.title }}
                </div>
              </div>

              <div
                class="col-6 no-margin text-right"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <span
                  class="btn text-lato text-bold card-title no-margin"
                  style="
                    background-color: #ff006c;
                    color: white;
                    font-size: 13px;
                  "
                  (click)="editWorkspace()"
                  >{{ 'PROJECT.SETTINGS.EDIT' | translate }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 one-edge-shadow form-background-color margin-top-25 justify-content-center"
          style="
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 8px;
            padding-right: 8px;
          "
          *ngIf="isLoading == false"
        >
          <div class="row margin-v-15 margin-h-9">
            <div
              class="col-12 no-margin text-left"
              style="
                margin-top: 20px !important;
                margin-bottom: 20px !important;
              "
            >
              <div
                style="display: flex; flex-direction: row; align-items: center"
              >
                <div
                  class="text-lato text-bold text-medium card-title no-margin"
                  style="width: calc(100% - 26px)"
                >
                  {{ project.members.length }}
                  {{ 'COMMON.MEMBER_S' | translate }}
                  /
                  {{ getPendingInvitations().length }}
                  {{ 'COMMON.PENDING_INVITATION_S' | translate }}
                </div>
              </div>

              <div
                class="row"
                style="
                  margin-left: 0px;
                  margin-right: 0px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                *ngIf="membersAreVisibile == true || true"
              >
                <div
                  class="col-12 text-lato"
                  style="
                    margin-top: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-medium card-title"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'COMMON.MEMBERS' | translate }}
                  </div>
                </div>
                <div
                  class="col-12 text-lato"
                  style="
                    font-size: 18px;
                    padding-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_NAME_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{
                            'PROJECT.SETTINGS.COL_ACCOUNT_TYPE_TITLE'
                              | translate
                          }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let member of project.members; let i = index">
                        <td
                          scope="row"
                          class="text-lato text-small"
                          style="vertical-align: middle"
                        >
                          {{ member.user?.infos.fullName }}
                          <br /><span
                            class="text-small"
                            style="color: #999999"
                            >{{ member.user?.infos.email }}</span
                          >
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'owner'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ff006c;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_OWNER' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'admin'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ffad00;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_ADMIN' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'member'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_MEMBER' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="
                            vertical-align: middle;
                            color: #ff006c;
                            cursor: pointer;
                          "
                          *ngIf="
                            isMe(member.user._id) == false &&
                            member.right === 'member' &&
                            isAdmin == true
                          "
                          (click)="switchToAdmin(member.user)"
                        >
                          {{
                            'PROJECT.SETTINGS.SWITCH_TO_ADMIN_TEXT' | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="
                            vertical-align: middle;
                            color: #ff006c;
                            cursor: pointer;
                          "
                          *ngIf="
                            isMe(member.user._id) == false &&
                            member.right === 'admin' &&
                            isAdmin == true
                          "
                          (click)="switchToRegular(member.user)"
                        >
                          {{
                            'PROJECT.SETTINGS.SWITCH_TO_REGULAR_MEMNER_TEXT'
                              | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle; color: #ff006c"
                          *ngIf="
                            isMe(member.user._id) == true || isAdmin == false
                          "
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="row"
                style="
                  margin-left: 0px;
                  margin-right: 0px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                *ngIf="membersAreVisibile == true || true"
              >
                <div
                  class="col-12 text-lato"
                  style="
                    margin-top: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-medium card-title"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'COMMON.INVITATIONS' | translate }}
                  </div>
                </div>
                <div
                  class="col-12 text-lato"
                  style="
                    font-size: 18px;
                    padding-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-bold text-small"
                    style="width: calc(100% - 26px)"
                    *ngIf="
                      !project.invitations || project.invitations.length == 0
                    "
                  >
                    {{ 'PROJECT.SETTINGS.NO_INVITATION_TEXT' | translate }}
                  </div>
                  <table
                    class="table"
                    *ngIf="
                      project.invitations && project.invitations.length > 0
                    "
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_EMAIL_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_STATUS_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let invitation of project.invitations;
                          let i = index
                        "
                      >
                        <td
                          scope="row"
                          class="text-lato text-small"
                          style="vertical-align: middle"
                        >
                          {{ invitation.email }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'sent'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ffad00;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_SENT' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'accepted'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ff006c;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_ACCEPTED' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'declined'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_DECLINED' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'cancelled'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{
                            'COMMON.INVITATION_STATUS_CANCELLED' | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-medium"
                          style="vertical-align: middle"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="row"
                style="
                  margin-left: 0px;
                  margin-right: 0px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                *ngIf="membersAreVisibile == true || isAdmin == true"
              >
                <div
                  class="col-12 text-lato"
                  style="
                    margin-top: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-medium card-title"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'PROJECT.SETTINGS.ADD_A_MEMBER_TEXT' | translate }}
                  </div>
                  <div
                    class="text-lato card-title"
                    style="
                      width: calc(100% - 26px);
                      font-size: 13px;
                      font-weight: normal;
                    "
                  >
                    {{
                      'PROJECT.SETTINGS.ADD_A_MEMBER_EXPLANATION_TEXT'
                        | translate
                    }}
                  </div>
                  <form [formGroup]="addMemberForm">
                    <div
                      class="row"
                      style="
                        display: flex;
                        margin-top: 30px;
                        align-items: center;
                      "
                    >
                      <div class="col-9 no-margin text-left">
                        <input
                          type="email"
                          placeholder="{{
                            'PROJECT.SETTINGS.ADD_A_MEMBER_EMAIL_PLACEHOLDER_TEXT'
                              | translate
                          }}"
                          class="input-text text-lato text-small text-darkgrey"
                          name="member_email"
                          formControlName="member_email"
                          autocomplete="off"
                        />
                      </div>
                      <div class="col-3 no-margin text-right">
                        <span
                          class="btn text-lato text-bold card-title no-margin"
                          style="
                            background-color: #ff006c;
                            color: white;
                            font-size: 13px;
                          "
                          (click)="invitePeople()"
                          >{{
                            'PROJECT.SETTINGS.ADD_A_MEMBER_BUTTON' | translate
                          }}</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--div
          class="col-10 one-edge-shadow form-background-color margin-top-25 justify-content-center"
          style="
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 8px;
            padding-right: 8px;
          "
          *ngIf="isLoading == false"
        >
          <div class="row margin-v-15 margin-h-9">
            <div
              class="col-12 no-margin text-left"
              style="
                margin-top: 20px !important;
                margin-bottom: 20px !important;
              "
            >
              <div
                style="display: flex; flex-direction: row; align-items: center"
              >
                <div
                  class="text-lato text-bold text-medium card-title no-margin"
                  style="width: calc(100% - 26px)"
                >
                  Influenceurs
                </div>
              </div>

              <div
                class="row"
                style="
                  margin-left: 0px;
                  margin-right: 0px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                *ngIf="membersAreVisibile == true || true"
              >
                <div
                  class="col-12 text-lato"
                  style="
                    margin-top: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-medium card-title"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    Influenceurs actifs
                  </div>
                </div>
                <div
                  class="col-12 text-lato"
                  style="
                    font-size: 18px;
                    padding-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_NAME_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{
                            'PROJECT.SETTINGS.COL_ACCOUNT_TYPE_TITLE'
                              | translate
                          }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let member of project.members; let i = index">
                        <td
                          scope="row"
                          class="text-lato text-small"
                          style="vertical-align: middle"
                        >
                          {{ member.user?.infos.fullName }}
                          <br /><span
                            class="text-small"
                            style="color: #999999"
                            >{{ member.user?.infos.email }}</span
                          >
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'owner'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ff006c;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_OWNER' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'admin'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ffad00;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_ADMIN' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="member.right === 'member'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{ 'COMMON.PROJECT_MEMBER' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="
                            vertical-align: middle;
                            color: #ff006c;
                            cursor: pointer;
                          "
                          *ngIf="
                            isMe(member.user._id) == false &&
                            member.right === 'member' &&
                            isAdmin == true
                          "
                          (click)="switchToAdmin(member.user)"
                        >
                          {{
                            'PROJECT.SETTINGS.SWITCH_TO_ADMIN_TEXT' | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="
                            vertical-align: middle;
                            color: #ff006c;
                            cursor: pointer;
                          "
                          *ngIf="
                            isMe(member.user._id) == false &&
                            member.right === 'admin' &&
                            isAdmin == true
                          "
                          (click)="switchToRegular(member.user)"
                        >
                          {{
                            'PROJECT.SETTINGS.SWITCH_TO_REGULAR_MEMNER_TEXT'
                              | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle; color: #ff006c"
                          *ngIf="
                            isMe(member.user._id) == true || isAdmin == false
                          "
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="row"
                style="
                  margin-left: 0px;
                  margin-right: 0px;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
                *ngIf="membersAreVisibile == true || true"
              >
                <div
                  class="col-12 text-lato"
                  style="
                    margin-top: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-medium card-title"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'COMMON.INVITATIONS' | translate }}
                  </div>
                </div>
                <div
                  class="col-12 text-lato"
                  style="
                    font-size: 18px;
                    padding-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="text-lato text-bold text-small"
                    style="width: calc(100% - 26px)"
                    *ngIf="
                      !project.invitations || project.invitations.length == 0
                    "
                  >
                    {{ 'PROJECT.SETTINGS.NO_INVITATION_TEXT' | translate }}
                  </div>
                  <table
                    class="table"
                    *ngIf="
                      project.invitations && project.invitations.length > 0
                    "
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_EMAIL_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        >
                          {{ 'PROJECT.SETTINGS.COL_STATUS_TITLE' | translate }}
                        </th>
                        <th
                          scope="col"
                          class="text-lato text-small"
                          style="border-width: 0px"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let invitation of project.invitations;
                          let i = index
                        "
                      >
                        <td
                          scope="row"
                          class="text-lato text-small"
                          style="vertical-align: middle"
                        >
                          {{ invitation.email }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'sent'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ffad00;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_SENT' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'accepted'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #ff006c;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_ACCEPTED' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'declined'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{ 'COMMON.INVITATION_STATUS_DECLINED' | translate }}
                        </td>
                        <td
                          class="text-lato text-small"
                          style="vertical-align: middle"
                          *ngIf="invitation.status === 'cancelled'"
                        >
                          <span
                            style="
                              margin-right: 10px;
                              border-left-width: 3px;
                              border-left-style: solid;
                              border-left-color: #999999;
                            "
                          ></span
                          >{{
                            'COMMON.INVITATION_STATUS_CANCELLED' | translate
                          }}
                        </td>
                        <td
                          class="text-lato text-medium"
                          style="vertical-align: middle"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div-->
      </div>
    </div>
  </div>
</div>
