import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'

import { environment } from '../../environments/environment'

import { InternalAuthenticationService } from './authentication/internal.authentication.service'
import { Observable } from 'rxjs'

var BACKEND_URL = environment.apiUrl

var ROLE = environment.role

@Injectable()
export class APIService {
  user: any
  projects: any
  workspaces: any

  currentProject: any
  currentWorkspace: any

  constructor(
    private http: HttpClient,
    private authenticationService: InternalAuthenticationService
  ) {}

  reset() {
    this.user = undefined
    this.projects = undefined
    this.workspaces = undefined
    this.currentProject = undefined
    this.currentWorkspace = undefined
  }

  async checkUserName(username: string) {
    var url = BACKEND_URL + '/api/v1/users/check'
    var res = await this.http
      .post<any>(url, { email: username }, { observe: 'response' })
      .toPromise()
    return res
  }

  async checkUseRole(username: string, role: string) {
    var url = BACKEND_URL + '/api/v1/users/role/check'
    var res = await this.http
      .post<any>(url, { email: username, role: role }, { observe: 'response' })
      .toPromise()
    return res
  }

  async registerInstagramLonglifeToken(token: string) {
    var url = BACKEND_URL + '/api/v1/projects/token/instagram'
    var res = await this.http
      .post<any>(url, { token: token }, { observe: 'response' })
      .toPromise()
    return res
  }

  async registerInstagramAccount(projectId: any, infos: any) {
    var url = BACKEND_URL + '/api/v1/projects/accounts/instagram'
    var res = await this.http
      .post<any>(
        url,
        { projectId: projectId, social_media: infos },
        { observe: 'response' }
      )
      .toPromise()
    return res
  }

  async unregisterInstagramAccount(projectId: string, mediaId: string) {
    var url = BACKEND_URL + '/api/v1/projects/accounts/instagram'
    var res = await this.http
      .delete<any>(url, {
        observe: 'response',
        params: { projectId: projectId, socialId: mediaId },
      })
      .toPromise()
    return res
  }

  async requestNewWorkspaceInvitation(email: string) {
    var url = BACKEND_URL + '/api/v1/members/invite'
    var res = await this.http
      .post<any>(url, { email: email }, { observe: 'response' })
      .toPromise()
    return res
  }

  async confirmNewWorkspaceInvitation(email: string, code: string) {
    var url = BACKEND_URL + '/api/v1/members/confirm'
    var res = await this.http
      .post<any>(url, { email: email, code: code }, { observe: 'response' })
      .toPromise()
    return res
  }

  async resetPassword(email: string) {
    var url = BACKEND_URL + '/api/v1/users/password/forgot'
    var res = await this.http
      .post<any>(url, { email: email, role: ROLE }, { observe: 'response' })
      .toPromise()
    return res
  }

  async changePassword(password: string, token: string) {
    var url = BACKEND_URL + '/api/v1/users/password/recover'
    var res = await this.http
      .post<any>(
        url,
        { password: password, token: token },
        { observe: 'response' }
      )
      .toPromise()
    return res
  }

  async createEverything(infos: any, profile_pic: File, project_pic: File) {
    var url = BACKEND_URL + '/api/v1/workspace/new'
    const formData: FormData = new FormData()
    if (profile_pic)
      formData.append('profilePic', profile_pic, profile_pic.name)
    if (project_pic)
      formData.append('projectPic', project_pic, project_pic.name)
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async createAccount(infos: any, profile_pic: File) {
    var url = BACKEND_URL + '/api/v1/users/'
    const formData: FormData = new FormData()
    if (profile_pic)
      formData.append('profilePic', profile_pic, profile_pic.name)
    infos.role = ROLE
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateAccount(infos: any, profile_pic: File) {
    var url = BACKEND_URL + '/api/v1/users/me'
    const formData: FormData = new FormData()
    if (profile_pic)
      formData.append('profilePic', profile_pic, profile_pic.name)
    formData.append('infos', JSON.stringify(infos))

    let res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async createProject(infos: any, project_pic: File) {
    var url = BACKEND_URL + '/api/v1/projects'
    const formData: FormData = new FormData()
    if (project_pic) formData.append('picture', project_pic, project_pic.name)
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async createCampaign(infos: any, moodboard_pic: File[]) {
    var url = BACKEND_URL + '/api/v1/campaigns'
    const formData: FormData = new FormData()
    for (var i = 0; i < moodboard_pic.length; i++) {
      if (moodboard_pic) formData.append('pictures', moodboard_pic[i])
    }
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateCampaign(id: any, infos: any, moodboard_pic: File[]) {
    var url = BACKEND_URL + '/api/v1/campaigns/' + id
    const formData: FormData = new FormData()
    for (var i = 0; i < moodboard_pic.length; i++) {
      if (moodboard_pic) formData.append('pictures', moodboard_pic[i])
    }
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteCampaign(campaign_id: string) {
    var url = BACKEND_URL + '/api/v1/campaigns/' + campaign_id

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async createPipe(infos: any) {
    var url = BACKEND_URL + '/api/v1/pipes'
    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async getMyProfile() {
    try {
      var url = BACKEND_URL + '/api/v1/users/me/'
      var res = await this.http
        .get<any>(url, { observe: 'response' })
        .toPromise()
      return res
    } catch (e) {
      if (e.error.code == 400 && e.error.error == 'User not authenticated') {
        this.authenticationService.logout()
      }
      return undefined
    }
  }

  getMe(): Observable<HttpResponse<any>> {
    var url = BACKEND_URL + '/api/v1/users/me/'
    return this.http.get<any>(url, { observe: 'response' })
  }

  getMeBody(): Observable<HttpResponse<any>> {
    var url = BACKEND_URL + '/api/v1/users/me/'
    return this.http.get<any>(url)
  }

  async getProjectDashBoard(id) {
    var url = BACKEND_URL + '/api/v1/projects/' + id + '/dashboard'
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getMyProjects() {
    var url = BACKEND_URL + '/api/v1/projects/'
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getPipeInformations(id) {
    var url = BACKEND_URL + '/api/v1/pipes/' + id + '/audience'
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getMyWorkspaces() {
    var url = BACKEND_URL + '/api/v1/workspaces'
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getWorkspaceMembers(workspace_id) {
    var url = BACKEND_URL + '/api/v1/workspace/' + workspace_id + '/members'
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getInvitationDetails(id, code) {
    var url = BACKEND_URL + '/api/v1/invitation/' + id + '?token=' + code
    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async acceptInvitation(id, code) {
    var url = BACKEND_URL + '/api/v1/invitation/' + id + '/confirm'
    var res = await this.http
      .post<any>(url, { token: code }, { observe: 'response' })
      .toPromise()
    return res
  }

  async declineInvitation(id, code) {
    var url = BACKEND_URL + '/api/v1/invitation/' + id + '/decline'
    var res = await this.http
      .post<any>(url, { token: code }, { observe: 'response' })
      .toPromise()
    return res
  }

  async invitePeople(project_id, email) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/invite'
    var res = await this.http
      .post<any>(url, { email: email }, { observe: 'response' })
      .toPromise()
    return res
  }

  async getBrandReport(project_id) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/brand_report'

    var res = await this.http
      .get(url, {
        responseType: 'blob',
      })
      .toPromise()
    return res
  }

  async updateBrand(project_id, brand, brand_pic: File) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/brand'

    const formData: FormData = new FormData()
    if (brand_pic) formData.append('picture', brand_pic, brand_pic.name)
    formData.append('infos', JSON.stringify(brand))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async uploadDocument(project_id, document: File) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/brand/document'

    const formData: FormData = new FormData()
    if (document) formData.append('document', document, document.name)

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async uploadBrandImage(project_id, image: File) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/brand/image'

    const formData: FormData = new FormData()
    if (image) formData.append('image', image, image.name)

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateBillingInformations(project_id, billing_infos) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/billing'

    var res = await this.http
      .post<any>(url, billing_infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateWorkspace(project_id, infos, workspace_pic: File) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id

    const formData: FormData = new FormData()
    if (workspace_pic)
      formData.append('picture', workspace_pic, workspace_pic.name)
    formData.append('infos', JSON.stringify(infos))

    var res = await this.http
      .post<any>(url, formData, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateMemberStatus(project_id, infos) {
    var url = BACKEND_URL + '/api/v1/projects/' + project_id + '/member'

    var res = await this.http
      .post<any>(url, infos, { observe: 'response' })
      .toPromise()
    return res
  }

  async initCampaignExtras(campaign_id) {
    var url = BACKEND_URL + '/api/v1/campaigns/' + campaign_id + '/extras/init'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
