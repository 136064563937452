<!-- Modal -->
<div
  class="modal fade modal-overlay"
  id="modalLogout"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalLogout"
  aria-hidden="false"
  (click)="closeModal()"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    (click)="stopPropagation($event)"
  >
    <div class="modal-content modal-container">
      <div class="modal-header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5 class="modal-title text-lato" id="modalLogoutTitle">
                {{ 'MODAL_LOGOUT.MODAL_TITLE' | translate }}
              </h5>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-lato">
        {{ 'MODAL_LOGOUT.MODAL_TEXT_1' | translate }}
      </div>
      <div class="modal-body text-lato modal-text">
        {{ 'MODAL_LOGOUT.MODAL_TEXT_2' | translate }}
      </div>
      <div class="modal-body text-lato">
        {{ 'MODAL_LOGOUT.MODAL_TEXT_3' | translate }}<br /><span
          class="text-pink"
          >{{ user?.infos?.email }}</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary one-btn-radius text-lato btn-text"
          (click)="logout()"
        >
          {{ 'MODAL_LOGOUT.MODAL_OK_BUTTON' | translate }}
        </button>
        <!--button
          type="button"
          class="btn btn-secondary text-lato btn-text"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          {{ 'MODAL_LOGOUT.MODAL_NOK_BUTTON' | translate }}
        </button-->
      </div>
    </div>
  </div>
</div>
