import { TranslateLoader } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { environment } from './../../../environments/environment'

var TRANSLATION_LOCATION = environment.apiUrl + '/public/i18n/'

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    if (lang == undefined || lang === '') lang = 'en'

    return this.http.get(TRANSLATION_LOCATION + lang + '.json')
  }
}
