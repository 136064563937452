import { Injectable } from '@angular/core'
import {
  Conversation,
  ConversationStatus,
} from 'src/app/_models/conversation/conversation.model'

import * as moment from 'moment'
import {
  ConversationParticipant,
  ConversationParticipantRole,
  ConversationParticipantStatus,
} from 'src/app/_models/conversation/conversation.participant.model'

import {
  ConversationEvent,
  ConversationEventStatus,
  ConversationEventType,
} from 'src/app/_models/conversation/conversation.event.model'

@Injectable()
export class ConversationsMockService {
  private conversations: Conversation[]
  private currentConversationIndex: number

  public constructor() {
    this.initService()
  }

  public initService() {
    this.initConversations()
    this.currentConversationIndex = 0
  }

  private initConversations() {
    this.conversations = []

    /*let participant0 = new ConversationParticipant(
      '0',
      'Cassandra Ambari',
      'https://fns-assets-dev-buckets.s3.eu-west-1.amazonaws.com/picture_1619520075832.jpg',
      moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
      moment('05/06/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
      ConversationParticipantStatus.PRESENT,
      ConversationParticipantRole.PUBLISHER
    )

    let participant1 = new ConversationParticipant(
      '1',
      'Jacob Peralta',
      'https://static.wikia.nocookie.net/heros/images/b/b4/Jake_Peralta_Infobox.jpg/revision/latest/scale-to-width-down/620?cb=20201020111239&path-prefix=fr',
      moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
      moment('05/06/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
      ConversationParticipantStatus.PRESENT,
      ConversationParticipantRole.ADVERTISER
    )

    let participant2 = new ConversationParticipant(
      '2',
      'Raymond Holt',
      undefined,
      moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
      moment('05/06/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
      ConversationParticipantStatus.PRESENT,
      ConversationParticipantRole.ADVERTISER
    )

    let participant3 = new ConversationParticipant(
      '3',
      'Amy Santiago',
      'https://static.wikia.nocookie.net/brooklynnine-nine/images/7/78/S7_Melissa_Fumero_-_Amy_Santiago.png/revision/latest/scale-to-width-down/700?cb=20200312162615',
      moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
      moment('05/06/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
      ConversationParticipantStatus.PRESENT,
      ConversationParticipantRole.ADVERTISER
    )

    let conversation1 = new Conversation()
    let conversation2 = new Conversation()
    let conversation3 = new Conversation()

    conversation1.id = '1'
    conversation1.createdAt = moment(
      '02/05/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation1.updatedAt = moment(
      '05/06/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation1.status = ConversationStatus.LIVE
    conversation1.participants.push(participant0)
    conversation1.participants.push(participant1)
    conversation1.events.push(
      ConversationEvent.buildEvent({
        _id: '0',
        createdAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '1',
        author: participant1.id,
        status: 'published',
        type: 'text',
        text: 'Hello :-)',
        infos: undefined,
      })
    )
    conversation1.events.push(
      ConversationEvent.buildEvent({
        _id: '1',
        createdAt: moment('02/05/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '1',
        author: participant0.id,
        status: 'published',
        type: 'text',
        text: "Hello Jake! Comment se déroule l'enquête ?",
        infos: undefined,
      })
    )
    conversation1.events.push(
      ConversationEvent.buildEvent({
        _id: '2',
        createdAt: moment('02/05/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:01', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '1',
        author: participant0.id,
        status: 'published',
        type: 'text',
        text: "J'ai appris que tu allais être muté",
        infos: undefined,
      })
    )
    conversation1.events.push(
      ConversationEvent.buildEvent({
        _id: '3',
        createdAt: moment('02/05/2021 20:02', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:02', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '1',
        author: participant1.id,
        status: 'published',
        type: 'text',
        text: 'Nous avons un suspect',
        infos: undefined,
      })
    )
    conversation1.events.push(
      ConversationEvent.buildEvent({
        _id: '4',
        createdAt: moment('02/05/2021 20:03', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:03', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '1',
        author: participant1.id,
        status: 'published',
        type: 'text',
        text:
          "Le capitaine Papa Raymond Holt m'a demandé de prendre soin de Cheddar, je pense qu'il m'apprécie de plus en plus",
        infos: undefined,
      })
    )

    conversation2.id = '2'
    conversation2.createdAt = moment(
      '02/05/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation2.updatedAt = moment(
      '05/06/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation2.status = ConversationStatus.LIVE
    conversation2.participants.push(participant0)
    conversation2.participants.push(participant2)
    conversation2.events.push(
      ConversationEvent.buildEvent({
        _id: '0',
        createdAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: '2',
        author: participant0.id,
        status: 'published',
        type: 'text',
        text: 'Hello',
        infos: undefined,
      })
    )

    conversation3.id = '3'
    conversation3.createdAt = moment(
      '02/05/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation3.updatedAt = moment(
      '05/06/2021 20:00',
      'MM/DD/YYYY HH:mm'
    ).valueOf()
    conversation3.status = ConversationStatus.LIVE
    conversation3.participants.push(participant0)
    conversation3.participants.push(participant3)
    conversation3.events.push(
      ConversationEvent.buildEvent({
        _id: '0',
        createdAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        updatedAt: moment('02/05/2021 20:00', 'MM/DD/YYYY HH:mm').valueOf(),
        owner: conversation3.id,
        author: participant3.id,
        status: 'published',
        type: 'text',
        text: 'Do you need advice for your weeding?',
        infos: undefined,
      })
    )

    this.conversations.push(conversation1)
    this.conversations.push(conversation2)
    this.conversations.push(conversation3)*/
  }

  getConversations() {
    return this.conversations
  }

  getCurrentConversation() {
    return this.conversations[this.currentConversationIndex]
  }

  selectConversation(index) {
    this.currentConversationIndex = index
  }

  getConversationEventAuthor(conversationId: string, authorId: string) {
    return this.conversations
      .filter((conversation) => conversation.id === conversationId)[0]
      .participants.filter((participant) => participant.id === authorId)[0]
  }
}
