<div
  class="text-left"
  *ngIf="
    campaignCreationBufferService.getStep() < 7 &&
    ((campaignCreationBufferService.campaignBeingCreated() != undefined &&
      (campaignCreationBufferService.campaignBeingCreated().status ===
        'draft' ||
        campaignCreationBufferService.campaignBeingCreated().status ===
          'launched')) ||
      campaignCreationBufferService.campaignBeingCreated() == undefined)
  "
>
  <div
    style="
      padding-top: 15px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
    "
  >
    <div
      class="top-text text-lato text-grey text-small"
      style="margin-right: 20px; margin-top: 2px"
    >
      {{ 'CAMPAIGN.CREATE.DRAFT_MODE_TEXT' | translate }}
    </div>
    <div>
      <form [formGroup]="draftModeForm" (ngSubmit)="refreshDraftMode()">
        <input
          type="checkbox"
          class="switch_1"
          name="isDraft"
          formControlName="isDraft"
        />
      </form>
    </div>
  </div>
</div>
