<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12" style="margin-bottom: 20px">
        <div class="text-xlarge text-bold">Upcoming milestones</div>
        <!--div class="text-xsmall">Date range: in the last 30 days</div-->
      </div>
      <div class="col-12" *ngFor="let event of events">
        <div class="event-container">
          <img
            class="icon"
            src="./../../../../../assets/images//icon_calendar.png"
          />
          <div class="event-inner-container">
            <div class="event-date">
              {{ convertDoubleToReadableDate(event.start) }}
            </div>
            <div class="event-title">
              {{ event.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--div class="calendar-section center padding-top padding-bottom">
      <div class="row text-center">
        <div class="col-md-4">
          <div class="btn-group btn-left">
            <div
              class="btn btn-outline-secondary navigation-btn previous-btn"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
            >
              Previous
            </div>
            <div
              class="btn btn-outline-secondary navigation-btn"
              mwlCalendarToday
              [(viewDate)]="viewDate"
            >
              Today
            </div>
            <div
              class="btn btn-outline-secondary navigation-btn"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate"
            >
              Next
            </div>
          </div>
        </div>
        <div class="col-md-4 text-darkgrey">
          <h3>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h3>
        </div>
        <div class="col-md-4">
          <div class="btn-group btn-right">
            <div
              class="btn btn-outline-secondary navigation-btn"
              (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month"
            >
              Month
            </div>
            <div
              class="btn btn-outline-secondary navigation-btn"
              (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week"
            >
              Week
            </div>
          </div>
        </div>
      </div>
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          (dayClicked)="dayClicked($event.day)"
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="events"
          [weekStartsOn]="weekStartsOn"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [dayStartHour]="6"
          [dayEndHour]="20"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
        >
        </mwl-calendar-day-view>
      </div>
    </div-->
  </div>
</div>
