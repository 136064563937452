<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-12 campaign-title text-bold text-large">
              {{ 'OPPORTUNITIES.CAMPAIGN_CHARACTERISTICS' | translate }}

              <div class="right-stuck">
                <tiny-cta
                  class="clickable"
                  value="{{ 'OPPORTUNITIES.EDIT' | translate }}"
                  (click)="editOffer()"
                ></tiny-cta>
              </div>
            </div>
          </div>
        </div>

        <div class="campaign-brief text-small text-bold">
          {{ 'OPPORTUNITIES.CAMPAIGN_TYPE' | translate }}
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <b
            ><span
              *ngIf="opportunity.target === 'discovery'"
              style="max-width: 250px"
            >
              {{ 'OPPORTUNITIES.CAMPAIGN_TYPE_DISCOVERY' | translate }}
            </span>
            <span
              *ngIf="opportunity.target === 'collaboration'"
              style="max-width: 250px"
            >
              {{ 'OPPORTUNITIES.CAMPAIGN_TYPE_COLLABORATION' | translate }}
            </span></b
          >
        </div>

        <div class="campaign-brief text-small text-bold">
          {{ 'OPPORTUNITIES.CAMPAIGN_AUDIENCE' | translate }}
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <b>{{ opportunity._scope.name }}</b>
        </div>

        <div class="campaign-brief text-small text-bold">
          {{ 'OPPORTUNITIES.CAMPAIGN_DATE_RANGE' | translate }}
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <b>{{ getOpportunityDateSentence() }}</b>
        </div>

        <div class="section-body text-small text-darkgrey">
          <div class="campaign-brief text-bold">
            {{ 'OPPORTUNITIES.CAMPAIGN_BRIEF' | translate }}
          </div>
          <div class="campaign-brief" [innerHTML]="opportunity.brief"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12" style="margin-bottom: 20px">
        <div class="campaign-title text-bold text-large">
          {{ 'OPPORTUNITIES.CAMPAIGN_METRICS' | translate }}
        </div>
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-small text-pink">
              {{ 'OPPORTUNITIES.CAMPAIGN_METRICS_NB_OF_VIEWS' | translate }}
            </div>
            <div class="text-xlarge text-bold">N/A</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-small text-pink">
              {{ 'OPPORTUNITIES.CAMPAIGN_METRICS_NB_OF_INTERESTS' | translate }}
            </div>
            <div class="text-xlarge text-bold">N/A</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-small text-pink">
              {{
                'OPPORTUNITIES.CAMPAIGN_METRICS_NB_OF_GENERATED_MENTIONS'
                  | translate
              }}
            </div>
            <div class="text-xlarge text-bold">N/A</div>
          </div>
        </div>
      </div>
      <div class="col-3 figure-box last-figure-box">
        <div>
          <div class="text-small" style="text-align: center">
            <div class="text-small text-pink">
              {{ 'OPPORTUNITIES.CAMPAIGN_METRICS_NB_OF_REACH' | translate }}
            </div>
            <div class="text-xlarge text-bold">N/A</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
