import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'
import { TranslateService } from '@ngx-translate/core'
import { PreferencesManager } from 'src/app/_services/preferences.manager.service'
import { PublisherStateService } from 'src/app/_services/publishers/publisher.state.service'

@Component({
  templateUrl: 'publisher-dashboard.component.html',
  styleUrls: ['./publisher-dashboard.component.scss'],
})
export class PublisherDashboardComponent implements OnInit, OnDestroy {
  publisher: any
  state: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private preferencesManager: PreferencesManager,
    private dataService: DataService,
    public alertService: AlertService,
    public publisherStateService: PublisherStateService
  ) {
    console.log('dashboard')
    this.publisher = this.route.snapshot.data['user'].body
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.state = this.publisherStateService.getPublisherState(this.publisher)

    if (this.state.billing.filled == 0) {
      this.alertService.pushErrorAlert(
        'Before getting accepted for any campaign, you have to fill your payment information in Billing tab'
      )
    }
  }

  ngOnDestroy() {}
}
