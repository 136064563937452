import { ConversationEvent } from './conversation.event.model'
import {
  ConversationParticipant,
  ConversationParticipantRole,
  ConversationParticipantStatus,
} from './conversation.participant.model'

import { USER_ROLE } from 'src/app/_helpers/referential/user.role'
import { environment } from 'src/environments/environment'

const ROLE = environment.role

export enum ConversationStatus {
  UNKWONN = 'unknown',
  LIVE = 'live',
  ARCHIVED = 'archived',
}

export class Conversation {
  private _id: string
  private _createdAt: number
  private _updatedAt: number
  private _participants: ConversationParticipant[]
  private _status: ConversationStatus
  private _events: ConversationEvent[]
  private _brand: any

  constructor() {
    this._id = undefined
    this._createdAt = undefined
    this._updatedAt = undefined
    this._participants = []
    this._status = ConversationStatus.UNKWONN
    this._events = []
  }

  private registerId(id: string) {
    this._id = id
  }

  public get id(): string {
    return this._id
  }

  public set id(id: string) {
    this._id = id
  }

  public get createdAt(): number {
    return this._createdAt
  }

  public set createdAt(createdAt: number) {
    this._createdAt = createdAt
  }

  public get updatedAt(): number {
    return this._updatedAt
  }

  public set updatedAt(updatedAt: number) {
    this._updatedAt = updatedAt
  }

  public get participants(): ConversationParticipant[] {
    return this._participants
  }

  public get status(): ConversationStatus {
    return this._status
  }

  public set status(status: ConversationStatus) {
    this._status = status
  }

  public get events(): ConversationEvent[] {
    return this._events
  }

  public set brand(brand: any) {
    this._brand = brand
  }

  public get brand(): any {
    return this._brand
  }

  static buildConversation(data): Conversation {
    let conversation = new Conversation()

    console.log(data)

    conversation._id = data._id
    conversation._createdAt = data.createdAt
    conversation._updatedAt = data.updatedAt
    conversation._status = data.status

    conversation._participants = []
    conversation._brand = data?._brand?._id ? data?._brand?._id : data?._brand

    for (let i = 0; i < data?.participants?.length; i++) {
      let participant = new ConversationParticipant(
        data.participants[i]._id,
        data.participants[i].user,
        data.participants[i].joinedAt,
        data.participants[i].awareness,
        data.participants[i].status,
        data.participants[i].role
      )

      conversation._participants.push(participant)
    }

    conversation._events = []
    for (let i = 0; i < data?.events?.length; i++) {
      let event = ConversationEvent.buildEvent(data.events[i])
      conversation._events.push(event)
    }

    console.log(conversation)
    return conversation
  }

  static buildConversationDataForRegistration(
    relationship: any,
    user: any,
    timestamp: any
  ) {
    let myRole = ConversationParticipantRole.UNKNOWN
    let yourRole = ConversationParticipantRole.UNKNOWN

    if (ROLE === USER_ROLE.ADVERTISER) {
      myRole = ConversationParticipantRole.ADVERTISER
      yourRole = ConversationParticipantRole.PUBLISHER
    } else if (ROLE === USER_ROLE.PUBLISHER) {
      myRole = ConversationParticipantRole.PUBLISHER
      yourRole = ConversationParticipantRole.ADVERTISER
    }

    let me = {
      user: user.user._id,
      role: myRole,
      status: ConversationParticipantStatus.PRESENT,
      awareness: timestamp,
      joinedAt: timestamp,
    }

    let you = {
      user: relationship._publisher.user._id,
      role: yourRole,
      status: ConversationParticipantStatus.PRESENT,
      awareness: timestamp,
      joinedAt: timestamp,
    }

    let conversation = {
      participants: [me, you],
      status: ConversationStatus.LIVE,
      _brand: relationship._brand,
      events: [],
    }

    return conversation
  }
}
