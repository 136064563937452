import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common'

import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

import { STATE } from './../../../../../../_models/validation/state'

@Component(
    {
        selector: 'alert-field-not-correct',
        templateUrl: 'alert-field-not-correct.component.html',
        styleUrls: ['./alert-field-not-correct.component.scss']
    }
)
export class AlertFieldNotCorrectComponent implements OnInit {
    @Input('projectId') projectId: string

    constructor(public campaignCreationBufferService: CampaignCreationBufferService, private location: Location) {

    }

    ngOnInit() {

    }

    closeModal() {
        let state = this.campaignCreationBufferService.checkCampaignState()
        if (state === STATE.HAS_ERRORS)
            this.campaignCreationBufferService.hideAlert()
        else if (state === STATE.HAS_WARNINGS || state === STATE.IS_VALID)
            this.campaignCreationBufferService.goToNextStep()
    }

    stopPropagation(event) {
        event.stopPropagation()
    }
}