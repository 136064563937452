<publisher-profile-professional-profile-positions
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-positions>

<publisher-profile-professional-profile-educations
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-educations>

<publisher-profile-professional-profile-certifications
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-certifications>

<publisher-profile-professional-profile-skills
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-skills>

<publisher-profile-professional-profile-spoken-languages
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-spoken-languages>

<publisher-profile-professional-profile-awards
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-awards>

<!--publisher-profile-professional-profile-publications
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-publications-->

<!--publisher-profile-professional-profile-patents
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-professional-profile-patents-->

<!--div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <form [formGroup]="myProfileProfessionalForm">
      <div class="row">
        <div class="col-12 text-lato">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">Recommendations</div>
            <div class="input-value container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="Recommendations"
                name="recommendations"
                relatedControlName="recommendations"
                formGroupName="recommendationsForm"
                [parentGroup]="myProfileProfessionalForm"
              >
              </input-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div-->
