<div class="container-fluid" *ngIf="hasSent == false">
  <div class="row">
    <div
      class="col-12 col-lg-7 d-none d-lg-flex hv-center bg-default full-height"
    >
      <img class="brand-logo" src="./assets/images/fns_logo_horizontal.png" />
    </div>
    <div
      class="col-12 col-lg-5 d-flex flex-column v-align full-height"
      id="login-container"
    >
      <div class="text-right">
        <!--span class="top-text text-lato text-grey text-small">Vous n'avez pas de compte ?<span class="btn btn-outline-primary left-margin text-button">CONTACTEZ
                        NOUS</span></span-->
        <span class="top-text text-lato text-grey text-small"
          ><a [routerLink]="['/login']" id="back"
            ><span
              class="btn btn-outline-primary left-margin text-button"
              style="border-width: 0 !important"
              >{{ 'COMMON.TEXT_GO_BACK' | translate }}</span
            ></a
          ></span
        >
      </div>
      <div class="text-center center margin-h">
        <div>
          <img
            class="brand-logo d-lg-none"
            src="./assets/images/fns_logo.png"
          />
          <h1
            class="text-lato text-bold text-big text-darkgrey line-height-115"
          >
            {{ 'PASSWORD.RECOVERY.STEP_0_TITLE' | translate }}
          </h1>
          <p class="text-lato text-small text-lightgrey margin-v-39">
            {{ 'PASSWORD.RECOVERY.STEP_0_INSTRUCTIONS' | translate }}
          </p>
          <form
            class="margin-v-39 max-w-400"
            [formGroup]="recoveryForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="row">
              <div class="col-12">
                <input
                  id="email"
                  type="email"
                  placeholder="{{
                    'PASSWORD.RECOVERY.STEP_0_EMAIL_PLACEHOLDER' | translate
                  }}"
                  class="input-text text-lato text-medium text-darkgrey"
                  name="email"
                  formControlName="email"
                  autocomplete="off"
                />
              </div>
              <div class="col-12">
                <input
                  id="send"
                  class="btn btn-primary btn-big text-lato text-bold"
                  type="submit"
                  value="{{
                    'PASSWORD.RECOVERY.STEP_0_SUBMIT_BUTTON' | translate
                  }}"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <copyright></copyright>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="hasSent == true">
  <div class="row">
    <div
      class="col-12 col-lg-7 d-none d-lg-flex hv-center bg-default full-height"
    >
      <img class="brand-logo" src="./assets/images/fns_logo_horizontal.png" />
    </div>
    <div
      class="col-12 col-lg-5 d-flex flex-column v-align full-height"
      id="login-container"
    >
      <div class="text-right">
        <!--span class="top-text text-lato text-grey text-small">Vous n'avez pas de compte ?<span class="btn btn-outline-primary left-margin text-button">CONTACTEZ
                                NOUS</span></span-->
        <span class="top-text text-lato text-grey text-small"
          ><a [routerLink]="['/login']"
            ><span
              class="btn btn-outline-primary left-margin text-button"
              style="border-width: 0 !important"
              >{{ 'COMMON.TEXT_GO_BACK' | translate }}</span
            ></a
          ></span
        >
      </div>
      <div class="text-center center margin-h">
        <div>
          <img
            class="brand-logo d-lg-none"
            src="./assets/images/fns_logo.png"
          />
          <h1
            class="text-lato text-bold text-big text-darkgrey line-height-115"
          >
            {{ 'PASSWORD.RECOVERY.STEP_1_TITLE' | translate }}
          </h1>
          <p class="text-lato text-small text-lightgrey margin-v-39">
            {{ 'PASSWORD.RECOVERY.STEP_1_INSTRUCTIONS' | translate }}
          </p>
        </div>
      </div>
      <copyright></copyright>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>
