import { Deserializable } from '../deserializable/deserializable.model'

import { Campaign } from './../campaign/campaign.model'

import * as moment from 'moment'

export class Deal implements Deserializable {
  id: string
  _publisher: any
  _brand: any
  _scope: any
  _opportunity: any
  proposal: any
  createdAt: any
  updatedAt: any
  status: any

  constructor() {
    this.id = undefined
    this._publisher = undefined
    this._brand = undefined
    this._scope = undefined
    this._opportunity = undefined
    this.proposal = undefined
    this.status = 'draft'
    this.createdAt = undefined
    this.updatedAt = undefined
  }

  deserialize(input: any) {
    Object.assign(this, input)
    return this
  }
}
