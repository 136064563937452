import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fns-web-app'

  constructor(private translate: TranslateService) {
    var userLang = navigator.language

    translate.setDefaultLang('en')

    //translate.use('en')
    var language = localStorage.getItem('language')
    if (language) translate.use(language)
    else {
      language = AppComponent.findCorrespondingLanguage(userLang)
      translate.use(language)
    }
  }

  static findCorrespondingLanguage(language: string): string {
    if (language != undefined && language.length >= 2) {
      var potentialLanguage = language.substring(0, 2)
      if (potentialLanguage === 'fr') return 'fr'
    }

    return 'en'
  }
}
