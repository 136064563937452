import { Deserializable } from './../deserializable/deserializable.model'

import { Network } from '../referential/network.model'
import { CampaignType } from '../referential/campaign_type.model'

import * as moment from 'moment'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'

/**
 *
 *
 * @export
 * @class Campaign
 * @implements {Deserializable}
 */
export class Campaign implements Deserializable {
  /**
   *
   *
   * @type {string}
   * @memberof Campaign
   */
  id: string

  /**
   *
   *
   * @type {string}
   * @memberof Campaign
   */
  title: string

  /**
   *
   *
   * @type {CampaignType}
   * @memberof Campaign
   */
  type: CampaignType

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  account: any
  //member_email: any[]

  /**
   *
   *
   * @type {any[]}
   * @memberof Campaign
   */
  keywords: any[]

  /**
   *
   *
   * @type {any[]}
   * @memberof Campaign
   */
  locations: any[]

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  date_start: any

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  date_end: any

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  budget: any

  /**
   *
   *
   * @type {number}
   * @memberof Campaign
   */
  numberOfFreeProducts: number

  /**
   *
   *
   * @type {string}
   * @memberof Campaign
   */
  productDescription: string

  /**
   *
   *
   * @type {Network[]}
   * @memberof Campaign
   */
  networks: Network[]

  /**
   *
   *
   * @type {any[]}
   * @memberof Campaign
   */
  do: any[]

  /**
   *
   *
   * @type {any[]}
   * @memberof Campaign
   */
  dont: any[]

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  brief: any
  //isDraft: boolean

  /**
   *
   *
   * @type {string}
   * @memberof Campaign
   */
  compensation: string

  /**
   *
   *
   * @type {*}
   * @memberof Campaign
   */
  difference: any

  status: any

  /**
   * Creates an instance of Campaign.
   * @memberof Campaign
   */
  constructor() {
    this.id = undefined
    this.title = ''
    this.type = new CampaignType()
    this.networks = []
    this.locations = []
    this.keywords = []

    this.compensation = undefined
    this.numberOfFreeProducts = 0
    this.productDescription = ''
    this.budget = 0

    this.do = []
    this.dont = []

    this.brief = ''
    this.status = 'draft'
  }

  public isNetworkSelected(network: Network): boolean {
    return Network.indexOf(network, this.networks) !== -1
  }

  public selectNetwork(network: Network): void {
    if (Network.indexOf(network, this.networks) == -1) {
      this.networks.push(new Network(network))
    }
  }

  public unselectNetwork(network: Network): void {
    var i = Network.indexOf(network, this.networks)
    this.networks.splice(i, 1)
  }

  public getDuration() {
    if (this.date_start != undefined && this.date_end != undefined) {
      var start = moment(this.date_start, 'DDMMYYYY')
      var finish = moment(this.date_end, 'DDMMYYYY')
      this.difference = finish.diff(start, 'days')
      return this.difference
    } else return NaN
  }

  /**
   *
   *
   * @param {*} input
   * @return {*}
   * @memberof Campaign
   */
  deserialize(input: any) {
    Object.assign(this, input)
    return this
  }

  public static getCampaignMinimalBudget() {
    return 0
  }

  public static getCampaignMaximalBudget() {
    return 25000
  }

  public static getCampaignBudgetStep() {
    return 50
  }

  public static adaptCampaign(campaignJson: any): Campaign {
    let campaign = new Campaign()
    campaign.id = campaignJson._id
    campaign.title = campaignJson.title
    campaign.type = Campaign.convertCampaignType(campaignJson.target)
    campaign.networks = Campaign.convertNetworks(campaignJson.networks)

    campaign.locations = Campaign.convertLocations(campaignJson.locations)
    campaign.keywords = Campaign.convertKeywords(campaignJson.filters)

    campaign.date_start = new Date(campaignJson.startDate)
    campaign.date_end = new Date(campaignJson.endDate)

    campaign.compensation = campaignJson.compensation
    if (campaign.compensation === 'cash')
      campaign.budget = campaignJson.total_budget

    if (campaign.compensation === 'free_products') {
      campaign.productDescription = campaignJson.free_product_description
      campaign.numberOfFreeProducts = campaignJson.total_budget
    }

    campaign.do = Campaign.convertDoDont(campaignJson.do)
    campaign.dont = Campaign.convertDoDont(campaignJson.dont)

    campaign.brief = campaignJson.brief.text

    return campaign
  }

  private static convertKeywords(input) {
    let result = []
    for (let i = 0; i < input.length; i++) {
      result.push(input[i].content)
    }
    return result
  }

  private static convertDoDont(input) {
    let result = []
    for (let i = 0; i < input.length; i++) {
      result.push(input[i].text)
    }
    return result
  }

  private static convertLocations(input) {
    let result = []
    if (input != undefined) {
      for (var i = 0; i < input.length; i++) {
        result.push({ country: input[i].country, state: input[i].area })
      }
    }
    return result
  }

  private static convertCampaignType(input) {
    let result = CampaignReferentialService.getTargets().filter(
      (campaign_type) => input == campaign_type.info
    )

    if (result != undefined) return result[0]
    else return undefined
  }

  private static convertNetworks(input): Network[] {
    let result = CampaignReferentialService.getNetworks().filter((network) => {
      let test = false
      for (let i = 0; i < input.length; i++) {
        test ||= network.id == input[i].identifier
      }

      return test
    })
    return result.map((elt) => {
      return new Network(elt)
    })
  }
}
