import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from './../../../../_services/api.service'
import { CacheService } from './../../../../_services/cache.service'
import { DataService } from './../../../../_services/data.service'
import { ModalService } from './../../../../_services/modal.service'

import { ProjectHelper } from './../../../../_helpers/project.helper'

@Component({
  templateUrl: 'project_settings.component.html',
  styleUrls: ['./project_settings.component.scss'],
})
export class ProjectSettingsComponent
  implements OnInit, OnDestroy, AfterViewInit {
  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  membersAreVisibile: any

  socialMediaAreVisible: any

  budget: any[]
  total_budget: number
  use_budget: number

  before: boolean
  work: number
  after: boolean
  date: any[]
  duration: any[]
  remaining_day: any[]

  addMemberForm: FormGroup

  me: any
  isAdmin: boolean

  project: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private cacheService: CacheService,
    private dataService: DataService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {
    this.isLoading = true
    this.showSomething = false

    this.membersAreVisibile = false
    this.socialMediaAreVisible = false
    this.budget = []
    this.total_budget = 0
    this.use_budget = 0
    this.before = false
    this.work = undefined
    this.after = false
    this.date = []
    this.duration = []
    this.remaining_day = []

    this.isAdmin = false
    this.me = undefined
  }

  async ngOnInit() {
    this.addMemberForm = this.formBuilder.group({
      member_email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    })

    this.modalSub = this.modalService.getModals().subscribe(async (object) => {
      if (object === 'social-accounts-close') {
        if (this.id) {
          this.project = this.dataService.getProject(this.id)
          this.isLoading = false
        }
      }
    })
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['projectId']

      this.project = this.dataService.getProject(this.id)

      var res = await this.apiService.getMyProfile()
      if (res.body) {
        this.me = res.body
        this.isAdmin = ProjectHelper.isRegularOwnerOrAdmin(
          this.me.user._id,
          this.project
        )
      }

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  showMembers(toShow) {
    this.membersAreVisibile = toShow
  }

  showSocialMedia(toShow) {
    this.socialMediaAreVisible = toShow
  }

  numberOfMembers() {
    if (this.project && this.project.members) {
      return this.project.members.length
    } else return 0
  }

  numberOfSocialMediaAccounts() {
    if (
      this.project &&
      this.project.socialMedia &&
      this.project.socialMedia.instagram
    ) {
      return this.project.socialMedia.instagram.length
    } else return 0
  }

  getSocialMediaAccounts() {
    var accounts = []
    if (
      this.project.socialMedia.instagram &&
      this.project.socialMedia.instagram.length > 0
    )
      for (var i = 0; i < this.project.socialMedia.instagram.length; i++) {
        accounts.push(this.project.socialMedia.instagram[i])
      }
    return accounts
  }

  async removeSocialMedia(id, i) {
    var res = await this.apiService.unregisterInstagramAccount(this.id, id)
    if (res.status == 200) {
      this.project.socialMedia.instagram.splice(i, 1)
    }
  }

  linkSocialAccount() {
    this.showThing('social-accounts')
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }

  getPendingInvitations() {
    try {
      return this.project.invitations.filter(
        (invitation) => invitation.status === 'sent'
      )
    } catch {
      return []
    }
  }

  async invitePeople() {
    if (this.addMemberForm.valid) {
      var candidate = this.addMemberForm.controls.member_email.value
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        // SEND INVITATION
        var res = await this.apiService.invitePeople(
          this.project._id,
          candidate
        )
        // RESET FORM
        if (res.status == 200) {
          this.addMemberForm.controls['member_email'].reset()
          var projects = await this.cacheService.getProjects()
          for (var i = 0; i < projects.length; i++) {
            if (projects[i]._id == this.id) {
              this.project = projects[i]
              break
            }
          }
        }
      }
    }
  }

  editWorkspace() {
    this.router.navigate(['/projects/' + this.project._id + '/settings/edit'])
  }

  isMe(user_id) {
    return this.me.user._id === user_id
  }

  async changeMemberRight(user, right) {
    // SEND INVITATION
    var res = await this.apiService.updateMemberStatus(this.project._id, {
      member: user.email,
      right: right,
    })
    // RESET FORM
    if (res.status == 200) {
      var projects = await this.cacheService.getProjects()
      for (var i = 0; i < projects.length; i++) {
        if (projects[i]._id == this.id) {
          this.project = projects[i]
          break
        }
      }
    }
  }

  async switchToAdmin(user) {
    await this.changeMemberRight(user, 'admin')
  }

  async switchToRegular(user) {
    await this.changeMemberRight(user, 'member')
  }
}
