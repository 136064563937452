<div class="content-width" app-theme>
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="publisher-billing-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'PROJECT.MY_BILLING_EDIT.PART_1' | translate }}"
        description="{{ 'PROJECT.MY_BILLING_EDIT.INSTRUCTIONS' | translate }}"
      ></publisher-section-intro>
      <publisher-billing-informations
        [publisher]="publisher"
      ></publisher-billing-informations>
    </div>
  </div>
</div>
<loader *ngIf="loading == true"></loader>
