import { Component, OnInit } from '@angular/core'

import { FormBuilder } from '@angular/forms'
import { ConversationEventType } from 'src/app/_models/conversation/conversation.event.model'
import { Conversation } from 'src/app/_models/conversation/conversation.model'
import { ConversationsSynchronisationService } from 'src/app/_services/conversations/conversations.synchronisation.service'

@Component({
  templateUrl: 'collaborations-conversations-menu.component.html',
  selector: 'collaborations-conversations-menu',
  styleUrls: ['./collaborations-conversations-menu.component.scss'],
})
export class CollaborationsConversationsMenuComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private conversationsSynchronisationService: ConversationsSynchronisationService
  ) {}

  ngOnInit() {}

  getConversations() {
    let conversations = this.conversationsSynchronisationService
      .getConversations()
      .getValue()

    return conversations
  }

  getConversationUser(conversation: Conversation) {
    return conversation.participants[1]
  }

  shouldDisplayAvatarLetters(conversation: Conversation) {
    let user = this.getConversationUser(conversation)
    if (
      user.pictureUrl != null &&
      user.pictureUrl !== '' &&
      user.pictureUrl !== './assets/images/blank.png'
    ) {
      return false
    }
    return true
  }

  getConversationUserFirstLetters(conversation: Conversation) {
    return this.getFirstLetters(
      this.getConversationUser(conversation).displayName
    )
  }

  getFirstLetters(text: string) {
    return text.split(' ').reduce((accumulator, currentValue) => {
      return accumulator + currentValue.substr(0, 1)
    }, '')
  }

  getConversationLastMessage(conversation: Conversation) {
    if (conversation.events && conversation.events.length > 0) {
      let lastEvent = conversation.events[conversation.events.length - 1]

      if (lastEvent.type == ConversationEventType.TEXT) {
        let text = ''
        if (lastEvent.authorId === '0') text = 'You'
        else text = this.getConversationUser(conversation).displayName

        return text + ': ' + lastEvent.text
      }
    }

    return ''
  }

  selectConversation(index) {
    this.conversationsSynchronisationService.selectConversation(index)
  }
}
