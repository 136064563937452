<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-cart.png" style="width: 40px; padding-left: 10px; padding-right: 10px;">
            <span style="position: absolute; top: 24px;">MES WORKSPACES</span>
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png" style="width: 16px"
                    (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato" style="color: #484c50; font-size: 26px; margin-bottom: 12px">
                        Sélectionner un workspace pour y accéder
                    </div>
                </div>
                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div *ngFor="let workspace of workspaces ; let i = index" class="col-lg-3 col-md-6 col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <div style="margin-top: 20px; margin-bottom: 20px;" (click)="selectWorkspace(workspace)">
                            <div class="text-lato" style="font-weight: 100; background-image: linear-gradient(138deg, #56576e, #2a2836); color: #FFFFFF; margin: auto; width: 60px; height: 60px; border-radius: 5px; display: flex; justify-content: center; align-items: center">{{i}}</div>
                            <div style="width: 50px; padding: 5px; border-bottom-style: solid; border-bottom-width: 2px; border-bottom-color: #ff006c; margin: auto; display: flex; justify-content: center; align-items: center" [style.opacity]="isVisible(workspace)"></div>
                            <div class="text-lato" style="font-weight: 500; text-align: center; margin-top: 10px">{{workspace.title}}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                            <div style="margin-top: 20px; margin-bottom: 20px;" (click)="createWorkspace()">
                                    <div class="text-lato" style="font-weight: 100; background-image: linear-gradient(138deg, #56576e, #2a2836); color: #FFFFFF; margin: auto; width: 60px; height: 60px; border-radius: 5px; display: flex; justify-content: center; align-items: center">+</div>
                                    <div style="width: 50px; padding: 5px; border-bottom-style: solid; border-bottom-width: 2px; border-bottom-color: #ff006c; margin: auto; display: flex; justify-content: center; align-items: center; opacity: 0"></div>
                                    <div class="text-lato" style="font-weight: 500; text-align: center; margin-top: 10px">Nouveau Workspace</div>
                                </div>
                    </div>
                </div>

            </div>
        </div>

    </div>