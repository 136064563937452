import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { ModalService } from '../../../_services/modal.service'
import { AuthenticationService } from '../../../_services/authentication/authentication.service'
import { DataService } from 'src/app/_services/data.service'

@Component({
  templateUrl: 'modal-logout.component.html',
  selector: 'modal-logout',
  styleUrls: ['./modal-logout.component.scss'],
})
export class ModalLogoutComponent implements OnInit {
  user: any

  constructor(
    private router: Router,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private dataService: DataService
  ) {
    this.user = undefined
  }

  ngOnInit() {
    this.user = this.dataService.getMe()
  }

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('logout')
  }

  logout() {
    this.closeModal()
    this.authenticationService.logout()
    this.router.navigate(['/login'])
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
