import { Component, OnInit, Input } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { ModalService } from '../../../_services'
import { APIService } from './../../../_services'
import { CacheService } from '../../../_services/cache.service'
import { DataService } from '../../../_services/data.service'

@Component({
  templateUrl: 'mobile-nav-bar.component.html',
  selector: 'mobile-nav-bar',
  styleUrls: ['./mobile-nav-bar.component.scss'],
})
export class MobileNavBarComponent implements OnInit {
  @Input('full') isFull: boolean = false
  @Input('title') title: string

  name: any
  picUrl: any

  showMenu: boolean
  showMenu2: boolean
  showMenuProfile: boolean

  filter: any

  projects: any
  project_index: any

  campaigns: any
  campaignId: string

  showSomething: boolean

  user: any

  static staticCampaignOptions: any
  static projectId: any
  hasToReloadOptions: any

  id: string
  private sub: any

  getLink = MobileNavBarComponent.getLink

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    public cacheService: CacheService,
    private dataService: DataService,
    private modalService: ModalService
  ) {
    this.campaignId = undefined
    this.showMenuProfile = false
    this.showSomething = false
    this.user = undefined
  }

  async ngOnInit() {
    this.user = this.dataService.getMe()
    //        this.user = await this.cacheService.getMe()
    this.name = ''
    this.projects = []
    this.campaigns = []

    this.hasToReloadOptions = false

    this.project_index = 0

    this.sub = this.route.params.subscribe(async (params) => {
      if (params['projectId']) {
        this.id = params['projectId']
        if (this.id !== MobileNavBarComponent.projectId)
          this.hasToReloadOptions = true
        MobileNavBarComponent.projectId = this.id
      }

      await this.init()
    })
  }

  async ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe()
  }

  async init() {
    this.filter = ''

    if (
      MobileNavBarComponent.staticCampaignOptions == undefined ||
      this.hasToReloadOptions == true
    )
      MobileNavBarComponent.staticCampaignOptions = []

    this.showMenu = false
    this.showMenu2 = false
    this.showMenuProfile = false

    this.projects = this.dataService.getProjects()
    //        this.projects = await this.cacheService.getProjects();
    for (var i = 0; i < this.projects.length; i++) {
      if (this.projects[i]._id == this.id) {
        this.project_index = i
        break
      }
    }
    this.name = this.projects[this.project_index].title
    this.picUrl = this.projects[this.project_index].pictureURL
    this.campaigns = this.projects[this.project_index].campaigns

    if (
      MobileNavBarComponent.staticCampaignOptions.length == 0 ||
      this.hasToReloadOptions == true
    ) {
      var l = this.campaigns.length
      for (var i = 0; i < l; i++) {
        var option = { visible: false, crisis: false, nbOfNotifications: 0 }
        MobileNavBarComponent.staticCampaignOptions.push(option)
      }
    }
  }

  showSubMenu(i, toShow) {
    MobileNavBarComponent.staticCampaignOptions[i].visible = toShow
  }

  showThing(thing) {
    this.showMenuProfile = false
    this.modalService.toggleModal(thing)
  }

  stopEvent(event) {
    event.stopPropagation()
  }

  toggleMenu() {
    this.showMenu = !this.showMenu
  }

  toggleMenu2() {
    this.showMenu2 = !this.showMenu2
  }

  toggleUserMenu(event) {
    this.showMenuProfile = !this.showMenuProfile
    event.stopPropagation()
  }

  toAProject(project_id) {
    this.showMenu = false
    this.showMenu2 = false
    this.showMenuProfile = false
    this.router.navigate(['/projects/' + project_id + '/dashboard'])
  }

  toNewProject() {
    this.router.navigate(['/projects/create'])
  }

  onSearchChange(searchValue: string) {
    if (searchValue.length > 0) this.filter = searchValue
    else this.filter = ''
  }

  createCampaign() {
    this.router.navigate([
      '/projects/' + this.projects[this.project_index]._id + '/campaign/new',
    ])
  }

  numberOfCampaigns() {
    if (this.campaigns) return this.campaigns.length
    else return '0'
  }

  static getLink(campaign, subroute) {
    return (
      'projects/' +
      MobileNavBarComponent.projectId +
      '/campaigns/' +
      campaign._id +
      '/' +
      subroute
    )
  }

  getCampaignOptions() {
    return MobileNavBarComponent.staticCampaignOptions
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  confirmLogout() {
    this.toggleUserMenu(undefined)
    event.preventDefault()
    var modal = document.querySelector('.modal') // assuming you have only 1
    var html = document.querySelector('html')
    modal.classList.add('is-active')
    html.classList.add('has-activated-background')
  }

  logoutConfirmed() {
    this.logout()
  }

  logoutCancelled() {
    var modal = document.querySelector('.modal')
    var html = document.querySelector('html')
    modal.classList.remove('is-active')
    html.classList.remove('has-activated-background')
  }

  goToDashboard() {
    this.router.navigate(['projects/' + this.id + '/dashboard'])
  }

  goToSettings() {
    this.router.navigate(['projects/' + this.id + '/settings'])
  }

  goToAll(campaign) {
    this.router.navigate([
      'projects/' + this.id + '/campaigns/' + campaign._id + '/all',
    ])
  }

  goToProfile() {
    this.router.navigate(['/me'], {
      queryParams: { returnUrl: this.router.routerState.snapshot.url },
    })
  }
}
