<div class="content-width" (click)="stopPropagation($event)">
  <div class="top-menu">
    <div class="text-lato text-medium container-top-menu">
      <img src="./assets/images/icon-settings.png" />{{
        'MODAL_PROFILE.MODAL_TITLE' | translate
      }}
      <span
        ><img src="./assets/images/icon-close.png" (click)="closeModal()"
      /></span>
    </div>
  </div>
  <div class="content-page">
    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12 text-lato text-bold section-container-title">
            {{ 'MODAL_PROFILE.MODAL_TITLE_1' | translate }}
          </div>
          <div class="col-12 text-lato section-container-description">
            {{ 'MODAL_PROFILE.MODAL_TEXT_1' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="profileForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_PROFILE_PICTURE' | translate }}
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="
                me?.infos?.profilePictureURL == undefined &&
                hasChangedProfilePicture == false
              "
            >
              <img
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
              />
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="
                me?.infos?.profilePictureURL &&
                hasChangedProfilePicture == false
              "
            >
              <img
                [src]="me?.infos?.profilePictureURL"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="hasChangedProfilePicture == true"
            >
              <img
                [src]="profileSrc"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
          </div>
          <div class="row display-none">
            <div class="col-12 display-none">
              <input
                class="display-none"
                type="file"
                (change)="onFileChanged($event)"
                accept="image/*"
                #fileInput
              />
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_LAST_NAME' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="text"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_LAST_NAME' | translate
                  }}"
                  class="input-text"
                  name="lastName"
                  formControlName="lastName"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_FIRST_NAME' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="text"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_FIRST_NAME' | translate
                  }}"
                  class="input-text"
                  name="firstName"
                  formControlName="firstName"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_COMPANY' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="text"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_COMPANY' | translate
                  }}"
                  class="input-text"
                  name="company"
                  formControlName="company"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_EMAIL' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="email"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_EMAIL' | translate
                  }}"
                  class="input-text"
                  name="email"
                  formControlName="email"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_PHONE' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="tel"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_PHONE' | translate
                  }}"
                  class="input-text"
                  name="phoneNumber"
                  formControlName="phoneNumber"
                />
              </div>
            </div>
          </div>
          <div class="row margin-section">
            <div class="col-12" *ngIf="missingFieldsInProfile">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_1' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="incorrectEmail">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_INCORRECT_EMAIL_FORMAT' | translate }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12 text-lato text-bold section-container-title">
            {{ 'MODAL_PROFILE.MODAL_TITLE_3' | translate }}
          </div>
          <div class="col-12 text-lato section-container-description">
            {{ 'MODAL_PROFILE.MODAL_TEXT_3' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="preferencesForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}
            </div>
            <div class="col-6 text-lato container-field-language">
              <div class="separator-field-no-padding">
                <select
                  id="language"
                  class="form-control custom-select text-lato font-style-laguage"
                  name="language"
                  formControlName="language"
                  (change)="updateLanguage()"
                >
                  <option value="" disabled selected>
                    {{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}
                  </option>
                  <option
                    *ngFor="let language of getLanguages()"
                    [value]="language.id"
                  >
                    {{ language.name | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-top: 11px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px;">
                                <select id="currency"
                                    class="form-control custom-select text-lato" style="font-size: inherit; padding: 0px; border: 0px"
                                    name="currency" formControlName="currency"
                                    (change)="updateCurrency()">
                                    <option value="" disabled selected>{{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}</option>
                                    <option *ngFor="let currency of getCurrencies()" [value]="currency.id">{{currency.name | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div-->
        </form>
      </div>
    </div>

    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12 text-lato text-bold section-container-title">
            {{ 'MODAL_PROFILE.MODAL_TITLE_2' | translate }}
          </div>
          <div class="col-12 text-lato section-container-description">
            {{ 'MODAL_PROFILE.MODAL_TEXT_2' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="passwordForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENT_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="password"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_CURRENT_PASSWORD'
                      | translate
                  }}"
                  class="input-text"
                  name="currentPassword"
                  formControlName="currentPassword"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_NEW_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="password"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_NEW_PASSWORD' | translate
                  }}"
                  class="input-text"
                  name="newPassword"
                  formControlName="newPassword"
                />
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_CONFIRM_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <div class="separator-field">
                <input
                  type="password"
                  placeholder="{{
                    'MODAL_PROFILE.MODAL_PLACEHOLDER_CONFIRM_PASSWORD'
                      | translate
                  }}"
                  class="input-text"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                />
              </div>
            </div>
          </div>
          <div class="row margin-section">
            <div class="col-12" *ngIf="missingFieldsInPassword">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_2' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="passwordsMismatched">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_PASSWORDS_NOT_MATCHING' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="wrongPassword">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_INCORRECT_PASSWORD' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="passwordTooShort">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_PASSWORD_TOO_SHORT' | translate }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-section-btn">
    <div class="section-btn">
      <plain-button
        value="{{ 'MODAL_PROFILE.MODAL_OK_BUTTON' | translate }}"
        (click)="onSubmit(); passwordForm.reset()"
      >
      </plain-button>
    </div>
  </div>
</div>
