<div class="container-fluid full-height">

    <div class="row v-margin h-margin bg-default d-flex"
        style="padding-top: 20px; padding-bottom: 20px; align-items: center;">
        <div class="col-6">
            <img src="./assets/images/logo_fns.png" style="height: 40px">
        </div>
        <div class="col-6">
            <h1 style="text-align: right; color: white; font-size: 24px; margin-bottom: 0px" class="text-lato">CAMPAIGN BRIEF</h1>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12">
            <div class="right-row no-padding" style="margin-top: 20px">
                <h1 style="text-align: left; color: #212529; font-size: 24px; margin-bottom: 0px" class="text-lato">
                    Campaign <span style="color: #ff006c">{{campaign.title}}</span></h1>
                <h1 style="text-align: left; color: #212529; font-size: 24px; margin-bottom: 0px" class="text-lato">
                    Brand <span style="color: #ff006c">{{project.title}}</span></h1>
            </div>
        </div>
        <div class="col-12" style="margin-top: 40px">
            <div class="no-padding" style="margin-top: 20px">
                <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                    class="text-lato text-bold">
                    Main informations</h1>
                <div class="row">
                    <div class="col-6 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        Influencers selection start date: <span style="color: #ff006c">{{date_start}}</span>
                    </div>
                    <div class="col-6 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        Influencers selection end date: <span style="color: #ff006c">{{date_end}}</span>
                    </div>
                    <div class="col-6 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        Campaign type: <span
                            style="color: #ff006c">Affiliate marketing program</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="margin-top: 40px">
            <div class="no-padding" style="margin-top: 20px">
                <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                    class="text-lato text-bold">
                    Target audience</h1>
                <div class="row">
                    <div class="col-12 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        Location: 
                                <div *ngFor="let location of campaign.locations" style="color: #ff006c">
                                    {{location.country | translate }}, {{location.area | translate }}
                                </div>
                    </div>
                    <div class="col-12 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        Interests: <span style="color: #ff006c"
                            *ngFor="let filter of campaign.filters">{{filter.content}} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="margin-top: 40px">
            <div class="no-padding" style="margin-top: 20px">
                <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                    class="text-lato text-bold">
                    Campaign Brief</h1>
                <div class="row">
                    <div class="col-12 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">
                        <div class="text-lato"
                            style="color: #212529; white-space: pre-wrap; font-size: 16px">{{campaign.brief.text}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="margin-top: 40px; margin-bottom: 0px;">
            <div class="no-padding" style="margin-top: 20px">
                <div class="row">
                    <div class="col-6 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">

                        <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                            class="text-lato text-bold">
                            Do</h1>
                        <div class="row no-margin">
                            <div class="col-12 tab-4" style="color: #30313e; text-align: left">
                                <ul>
                                    <li *ngFor="let do of campaign.do">
                                        {{do.text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-lato"
                        style="padding-top: 12px; padding-bottom: 12px; font-size: 16px; color: #212529">

                        <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                            class="text-lato text-bold">
                            Don't</h1>
                        <div class="row no-margin">
                            <div class="col-12 tab-4" style="color: #30313e; text-align: left">
                                <ul>
                                    <li *ngFor="let dont of campaign.dont">
                                        {{dont.text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="margin-top: 40px; page-break-before: always">
            <div class="no-padding" style="margin-top: 20px">
                <div class="row margin-v-15 margin-h-9">
                        <h1 style="text-align: left; color: #30313e; font-size: 24px; margin-bottom: 0px;"
                        class="text-lato text-bold">
                        Moodboard</h1>
                    <div class="col-12">
                        <div class="row v-align margin-v-15" style="margin-left: auto;margin-right: auto;">
                            <div class="card-columns" style="">
                                <div *ngFor="let image of moodboard" class="card" style="">
                                    <img [src]="image.path" class="card-img-top" alt="...">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>