<div>
  <div class="btn btn-primary btn-big text-lato text-bold">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="margin-top: 3px; margin-right: 8px">
        <object
          data="/assets/images/cryptr/logo_symbol_pinkish_white.svg"
          width="16"
          height="19"
        ></object>
      </div>
      <div>1-CLICK LOGIN</div>
    </div>
  </div>
</div>
