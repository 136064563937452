<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunities-title">
      <publisher-section-intro
        title="{{ getTitle() | translate }}"
        description=" "
      ></publisher-section-intro>
    </div>
    <div id="advertiser-opportunity-edit" class="max-w-80pt">
      <opportunity-informations
        [brand]="project"
        [offer]="offer"
      ></opportunity-informations>
    </div>
  </div>
</div>
