import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersProfileAPIService } from 'src/app/_services'
import { Observable } from 'rxjs'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'
import { map, startWith } from 'rxjs/operators'
import { IndustryHelper } from 'src/app/_helpers/referential/industry.helper'

@Component({
  templateUrl:
    'publisher-profile-content-description-expertises.component.html',
  selector: 'publisher-profile-content-description-expertises',
  styleUrls: [
    './publisher-profile-content-description-expertises.component.scss',
  ],
})
export class PublisherProfileContentDescriptionExpertisesComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  expertisesForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  expertises: any[]
  expertise: any
  expertiseId: any

  suggestedExpertises: any[]
  expertiseNameControl = new FormControl()
  filteredOptions: Observable<any[]>

  successfullyAdded: boolean
  successfullyRemoved: boolean

  expertiseField: any

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService,
    private campaignReferentialService: CampaignReferentialService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)

    this.suggestedExpertises = []
    this.expertisesForm = new FormGroup({
      expertiseName: new FormControl(''),
    })

    this.filteredOptions = this.expertisesForm
      .get('expertiseName')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      )
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getExpertises()
    this.suggestedExpertises = this.getSuggestedExpertises()
  }

  ngOnDestroy() {}

  hasExpertises() {
    try {
      return (
        this?.publisher?.publisher?.contentDescription?.fieldsOfExpertise !=
        undefined
      )
    } catch (e) {
      return false
    }
  }

  getExpertises() {
    if (this.hasExpertises() == true) {
      this.expertises = this.publisher.publisher.contentDescription.fieldsOfExpertise
      return this.expertises
    } else return undefined
  }

  getNumberOfExpertises() {
    let expertises = this.getExpertises()
    if (expertises == undefined) return 0

    return expertises.length
  }

  getSuggestedExpertises() {
    let suggestedExpertises = IndustryHelper.getPopularIndustries()

    let expertises = this.getExpertises()
    if (expertises == undefined) {
      return suggestedExpertises
    }

    let checker = []
    for (let i = 0; i < expertises.length; i++) {
      checker.push(expertises[i].key)
    }

    let result = []
    for (let i = 0; i < suggestedExpertises.length; i++) {
      const index = checker.indexOf(suggestedExpertises[i].key)
      if (index == -1) {
        result.push(suggestedExpertises[i])
      }
    }

    return result
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async addExpertiseToProfile(expertise) {
    try {
      const index = this.suggestedExpertises.indexOf(expertise)
      if (index > -1) {
        this.suggestedExpertises.splice(index, 1)
      }

      this.loading = true

      this.alertService.resetAlerts()
      let res = await this.apiService.addExpertiseInformation(
        this.publisher.publisher._id,
        { key: expertise.key }
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyAdded = true
        this.makeNotEditable()
        this.loading = false
      } else {
        this.successfullyAdded = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let expertiseField = this.readTextValue(
        this.expertisesForm.get('expertiseName').value
      )
      this.expertiseField = expertiseField

      if (
        expertiseField != undefined &&
        this.expertisesForm.get('expertiseName').valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Expertise field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let expertise_infos = {
          key: this.formatExpertiseName(expertiseField),
        }

        let res = await this.apiService.addExpertiseInformation(
          this.publisher.publisher._id,
          expertise_infos
        )

        console.log(expertise_infos)

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeNotEditable()
          this.publisher.publisher = res.body.publisher
          this.clearInputField()
          this.successfullyAdded = true
          this.loading = false
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteExpertise(expertise) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let expertiseId = expertise._id

      let res = await this.apiService.deleteExpertiseInformation(
        this.publisher.publisher._id,
        expertiseId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.makeNotEditable()
        this.successfullyRemoved = true
        this.publisher.publisher = res.body.publisher
        this.closeModalIfNoExpertise()
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addExpertise() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
    this.suggestedExpertises = this.getSuggestedExpertises()
  }

  updateExpertise() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  validateExpertise() {}

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  private _filter(value: string): string[] {
    let filteredExpertises = this.getSuggestedExpertises()
    let result = []
    for (let i = 0; i < filteredExpertises.length; i++) {
      result.push(filteredExpertises[i].value)
    }
    const filterValue = value.toLowerCase()
    return result.filter((expertise) => {
      return expertise.toLowerCase().indexOf(filterValue) === 0
    })
  }

  getCorrespondingExpertiseKey(expertise: string) {
    let expertises = IndustryHelper.getIndustries()
    let result = undefined
    for (let i = 0; i < expertises.length; i++) {
      if (expertises[i].value.toLowerCase() === expertise.toLowerCase()) {
        result = expertises[i].key
        return result
      }
    }

    return result
  }

  getCorrespondingExpertiseName(expertise: string) {
    let expertises = IndustryHelper.getIndustries()
    let result = undefined
    for (let i = 0; i < expertises.length; i++) {
      if (
        expertises[i].key.toLowerCase() === expertise.toLowerCase() ||
        expertises[i].value.toLowerCase() === expertise.toLowerCase()
      ) {
        result = expertises[i].value
        return result
      }
    }

    return result
  }

  formatExpertiseName(expertise) {
    if (expertise === 'Artificial intelligence')
      return 'artificial_intelligence'
    else if (expertise === 'Big Data & Cloud Computing')
      return 'big_data_cloud_computing'
    else if (expertise === 'Business & Entrepreneurship')
      return 'business_entrepreneurship'
    else if (expertise === 'Marketing & Business Development')
      return 'marketing_business_development'
    else if (expertise === 'Productivity') return 'productivity'
    else if (expertise === 'Sales') return 'sales'
    else if (expertise === 'Science & Engineering') return 'science_engineering'
    else if (expertise === 'Software Programming') return 'software_programming'
    else {
      return undefined
    }
  }

  clearInputField() {
    this.expertisesForm = new FormGroup({
      expertiseName: new FormControl(''),
    })
  }

  closeModalIfNoExpertise() {
    if (this.getExpertises().length == 0) {
      this.closeModal()
    }
  }
}
