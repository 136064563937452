import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import * as moment from 'moment'

import { ConversationsSocket } from 'src/app/_sockets/conversations.socket'
import { Conversation } from 'src/app/_models/conversation/conversation.model'
import { USER_ROLE } from 'src/app/_helpers/referential/user.role'
import { environment } from 'src/environments/environment'
import { StorageManagementService } from '../storage.management.service'
import { ConversationEvent } from 'src/app/_models/conversation/conversation.event.model'

const ROLE = environment.role

@Injectable()
export class ConversationSocketService {
  private conversations = new BehaviorSubject<Conversation[]>([])
  private currentConversation = new BehaviorSubject<Conversation>(undefined)

  private socket: ConversationsSocket

  constructor(private storageManagementService: StorageManagementService) {
    this.socket = new ConversationsSocket(
      JSON.parse(localStorage.getItem('currentUser')).token
    )
  }

  disconnect() {
    this.socket.disconnect()
  }

  getConversations(): BehaviorSubject<Conversation[]> {
    return this.conversations
  }

  getConversation(): BehaviorSubject<Conversation> {
    return this.currentConversation
  }

  async joinConversationTasks(conversations: Conversation[]) {
    this.socket.connect()

    this.socket.on('conversations:conversation:get', (data) => {})

    this.socket.on('conversations:conversation:update', async (data) => {
      console.log('conversations:conversation:update')
      console.log(data)
      if (data != undefined && data.object != undefined) {
        let success = data.object.success
        let timestamp = data.object.timestamp

        if (data.action === 'post') {
          let updatedConversation = data.object.conversation

          if (ROLE === USER_ROLE.PUBLISHER) {
            let publisherId = this.storageManagementService.getCurrentPublisherId()

            this.socket.emit('publisher:conversations:join', {
              taskId: updatedConversation._id,
              publisherId: publisherId,
              token: JSON.parse(localStorage.getItem('currentUser')).token,
            })
          } else if (ROLE === USER_ROLE.ADVERTISER) {
            let brandspaceId = this.storageManagementService.getCurrentBrandspaceId()

            this.socket.emit('advertiser:conversations:join', {
              taskId: updatedConversation._id,
              brandspaceId: brandspaceId,
              token: JSON.parse(localStorage.getItem('currentUser')).token,
            })
          }

          await this.updateNewConversation(
            updatedConversation,
            timestamp,
            success
          )
        } else if (data.action === 'put') {
          let updatedConversation = data.object.updatedConversation
          await this.updateExistingConversation(
            updatedConversation,
            timestamp,
            success
          )
        }
      }
    })

    this.socket.on('conversations:conversation:event:update', async (data) => {
      console.log('conversations:conversation:event:update')
      console.log(data)
      if (data != undefined && data.object != undefined) {
        let success = data.object.success
        let timestamp = data.object.timestamp

        if (data.action === 'post') {
          let updatedConversationId = data.object.conversationId
          let conversationEvent = data.object.conversationEvent
          let builtConversationEvent = ConversationEvent.buildEvent(
            conversationEvent
          )
          if (ROLE === USER_ROLE.PUBLISHER) {
            /*let publisherId = this.storageManagementService.getCurrentPublisherId()

            this.socket.emit('publisher:conversations:join', {
              taskId: updatedConversation._id,
              publisherId: publisherId,
            })*/
          } else if (ROLE === USER_ROLE.ADVERTISER) {
            let brandspaceId = this.storageManagementService.getCurrentBrandspaceId()

            if (
              brandspaceId ===
              this.storageManagementService.getCurrentBrandspaceId()
            ) {
              this.currentConversation
                .getValue()
                .events.push(builtConversationEvent)
              this.currentConversation.next(this.currentConversation.getValue())
            }
          }
        }
      }
    })

    if (ROLE === USER_ROLE.PUBLISHER) {
      let publisherId = this.storageManagementService.getCurrentPublisherId()
      this.socket.emit('publisher:conversations:join', {
        publisherId: publisherId,
        token: JSON.parse(localStorage.getItem('currentUser')).token,
      })
      for (let i = 0; i < conversations.length; i++) {
        if (conversations[i].id) {
          this.socket.emit('publisher:conversations:join', {
            conversationId: conversations[i].id,
            publisherId: publisherId,
            token: JSON.parse(localStorage.getItem('currentUser')).token,
          })
        }
      }
    } else if (ROLE === USER_ROLE.ADVERTISER) {
      let brandspaceId = this.storageManagementService.getCurrentBrandspaceId()
      this.socket.emit('advertiser:conversations:brandspaces:join', {
        brandspaceId: brandspaceId,
        userId: this.storageManagementService.getCurrentUserId(),
        token: JSON.parse(localStorage.getItem('currentUser')).token,
      })
      for (let i = 0; i < conversations.length; i++) {
        if (conversations[i].id) {
          this.socket.emit('advertiser:conversations:join', {
            conversationId: conversations[i].id,
            brandspaceId: brandspaceId,
            userId: this.storageManagementService.getCurrentUserId(),
            token: JSON.parse(localStorage.getItem('currentUser')).token,
          })
        }
      }
    }
  }

  async updateNewConversation(updatedConversation, timestamp, success) {
    //    this.getConversation().getValue().flushOverridenValues(updatedConversation, timestamp)

    this.currentConversation.next(this.getConversation().getValue())
    this.conversations.getValue().push(this.currentConversation.getValue())
    this.conversations.next(this.conversations.getValue())
  }

  async updateExistingConversation(updatedConversation, timestamp, success) {
    //    this.getConversation().getValue().flushOverridenValues(updatedConversation, timestamp)
    this.currentConversation.next(this.getConversation().getValue())
    this.conversations.next(this.conversations.getValue())
    //    this.getEditedTask().getValue().unlockTaskForSave()

    if (success == true) {
      //if (this.currentConversation.getValue().shouldBeSave() == true)
      await this.synchronize()
    }
  }

  async synchronize() {
    // CASE #1: DEAL WITH A NEW TASK
    // CASE #2: UPDATE ON AN EXISTING TASK
    let success = false

    //if (this.getConversation().getValue().canBeSave() == true) {
    if (this.getConversation().getValue().id) {
      // UPDATE TASK
      let timestamp = moment().valueOf()

      /*this.getConversation().getValue().lockTaskForSave()
        let data = this.buildDataFromOveriddenValues(
          this.getConversation().getValue()
        )*/

      this.updateConversation(this.getConversation().getValue(), timestamp)
    } else {
      // CREATE TASK
      let timestamp = moment().valueOf()
      /*this.getConversation().getValue().lockTaskForSave()
        let data = this.buildDataFromOveriddenValues(
          this.getConversation().getValue()
        )*/

      this.createConversation(this.getConversation().getValue(), timestamp)
    }
    /*} else {
      // Do nothing
    }*/
  }

  createConversation(conversation: any, timestamp: any) {
    try {
      let data = this.buildDataFromOveriddenValues(conversation)

      if (ROLE === USER_ROLE.PUBLISHER) {
        this.socket.emit('publisher:conversations:add', {
          publisherId: this.storageManagementService.getCurrentPublisherId(),
          timestamp: timestamp,
          conversationInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      } else if (ROLE === USER_ROLE.ADVERTISER) {
        console.log('advertiser:conversations:add')
        this.socket.emit('advertiser:conversations:add', {
          brandspaceId: this.storageManagementService.getCurrentBrandspaceId(),
          timestamp: timestamp,
          conversationInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  updateConversation(conversation: Conversation, timestamp: any) {
    try {
      let data = this.buildDataFromOveriddenValues(conversation)

      if (ROLE === USER_ROLE.PUBLISHER) {
        this.socket.emit('publisher:conversations:edit', {
          conversationId: conversation.id,
          publisherId: this.storageManagementService.getCurrentPublisherId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      } else if (ROLE === USER_ROLE.ADVERTISER) {
        console.log('advertiser:conversations:edit')
        this.socket.emit('advertiser:conversations:edit', {
          conversationId: conversation.id,
          brandspaceId: this.storageManagementService.getCurrentBrandspaceId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  buildDataFromOveriddenValues(conversation: Conversation) {
    /*let data = {
      name: task.name,
      description: task.description,
      dueDate: task.dueDate,
      priority: task.priority,
      assignee: task.assignee,
      deal: task.task.deal,
      step: task.step,
      status: task.status,
    }

    return data*/
    return conversation
  }

  sendTextEvent(message: string) {
    console.log('sending text event')
    let timestamp = moment().valueOf()

    let prefix = ''
    if (ROLE === USER_ROLE.PUBLISHER) {
      prefix = 'publisher'
    } else {
      prefix = 'advertiser'
    }

    console.log(prefix + ':conversations:event:add')
    this.socket.emit(prefix + ':conversations:event:add', {
      conversationId: this.currentConversation.getValue().id,
      brandspaceId: this.currentConversation.getValue().brand,
      timestamp: timestamp,
      event: {
        type: 'text',
        status: 'published',
        text: message,
        sender: this.storageManagementService.getCurrentUserId(),
      },
      token: JSON.parse(localStorage.getItem('currentUser')).token,
    })
  }
}
