<div class="container-fluid full-height bg-default" [style.overflow]="getOverflow()" [style.height]="getHeight()">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'PROJECT.SETTINGS.TITLE' | translate }}" style="width:100%"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">

        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{ 'PROJECT.SETTINGS.TITLE' | translate }}"></nav-bar>
                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="cursor" style="display: flex; flex-direction: row; position: relative; width: 100%">
                                <div style="margin-top: auto; margin-bottom: auto">
                                    <img style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%; border-style: solid; border-width: 1px; border-color: #999999"
                                        [src]="project.pictureURL">
                                </div>
                                <div
                                    style="margin-top:auto; margin-bottom: auto; margin-right: 0px; padding-left: 16px; padding-right: 16px; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                                    <div class="text-bold text-large"
                                        style="white-space: normal; overflow: hidden; color:#FFFFFF">
                                        {{project.title}}</div>
                                </div>

                            <div class="col-6 no-margin text-right"
                            style="display: flex; align-items: center; justify-content: flex-end">
                            <span class="btn text-lato text-bold card-title no-margin"
                                style="background-color: #ff006c; color: white; font-size: 13px"
                                (click)="editWorkspace()">{{ 'PROJECT.SETTINGS.EDIT' | translate }}</span> 
                        </div>
                            </div>
                            <!--div class="col-12 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #FFFFFF">{{projects[project_index].title}}</span>
                            </!--div-->
                        </div>
                    </div>
                    <!--div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">
                                        {{numberOfSocialMediaAccounts()}}
                                        {{'PROJECT.SETTINGS.NUMBER_OF_SOCIAL_ACCOUNTS' | translate}}
                                    </div>
                                </div>

                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;"
                                    *ngIf="socialMediaAreVisible == true || true">
                                    <div class="col-12 text-lato"
                                        style="color: #FFFFFF; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'COMMON.SOCIAL_NETWORK' | translate}}</th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'COMMON.SOCIAL_ACCOUNT' | translate}}</th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let account of getSocialMediaAccounts() ; let i = index">
                                                    <td scope="row" class="text-lato text-small"
                                                        style="vertical-align: middle">
                                                        <img style="width: 20px; margin-right: 14px"
                                                            src="./assets/images/socials-instagram.png">
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle">
                                                        <a target="_blank"
                                                            [attr.href]="'https://www.instagram.com/' + account.infos.username">{{account.infos.display_name}}
                                                            (@{{account.infos.username}})</a>
                                                    </td>
                                                    <td class="text-lato text-small"
                                                        style="vertical-align: middle; text-align: right; color: #FD006A"
                                                        (click)="removeSocialMedia(account.social_id, i)">
                                                        {{'COMMON.UNLINK' | translate}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-6 no-margin" style="display: flex; align-items: center">
                                    </div>
                                    <div class="col-6 no-margin text-right"
                                        style="display: flex; align-items: center; justify-content: flex-end">
                                        <span class="btn text-lato text-bold card-title no-margin"
                                            style="background-color: #ff006c; color: white; font-size: 13px"
                                            (click)="linkSocialAccount()">{{'PROJECT.SETTINGS.MANAGE_ACCOUNTS_BUTTON' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div-->
                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">
                                        {{project.members.length}} {{'COMMON.MEMBER_S' | translate}}
                                        /
                                        {{getPendingInvitations().length}}
                                        {{'COMMON.PENDING_INVITATION_S' | translate}}
                                    </div>

                                    <!--div class="cursor" style="opacity: 0.4" *ngIf="membersAreVisibile == false"
                                        (click)="showMembers(true)">
                                        <img style="width: 24px;" src="./assets/images/icon-expand-white.png">
                                    </div>
                                    <div class="cursor" style="opacity: 0.4" *ngIf="membersAreVisibile == true"
                                        (click)="showMembers(false)">
                                        <img style="width: 24px;" src="./assets/images/icon-collapse-white.png">
                                    </div-->
                                </div>

                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;"
                                    *ngIf="membersAreVisibile == true || true">
                                    <div class="col-12 text-lato"
                                        style="margin-top: 20px;  padding-left: 0px; padding-right: 0px">
                                        <div class="text-lato text-medium card-title"
                                            style="color: #ff006c; width:calc(100% - 26px);">
                                            {{'COMMON.MEMBERS' | translate}}
                                        </div>
                                    </div>
                                    <div class="col-12 text-lato"
                                        style="color: #FFFFFF; font-size: 18px; padding-bottom: 20px; padding-left: 0px; padding-right: 0px;">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'PROJECT.SETTINGS.COL_NAME_TITLE' | translate}}</th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'PROJECT.SETTINGS.COL_ACCOUNT_TYPE_TITLE' | translate}}
                                                    </th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let member of project.members ; let i = index">
                                                    <td scope="row" class="text-lato text-small"
                                                        style="vertical-align: middle">
                                                        {{member.user?.infos.fullName}}
                                                        <br><span class="text-small"
                                                            style="color: #999999">{{member.user?.infos.email}}</span>
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="member.right === 'owner'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ff006c;"></span>{{'COMMON.PROJECT_OWNER' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="member.right === 'admin'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ffad00;"></span>{{'COMMON.PROJECT_ADMIN' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="member.right === 'member'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #999999;"></span>{{'COMMON.PROJECT_MEMBER' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle; color: #ff006c; cursor: pointer" *ngIf="isMe(member.user._id) == false && member.right === 'member' && isAdmin == true" (click)="switchToAdmin(member.user)">
                                                        {{'PROJECT.SETTINGS.SWITCH_TO_ADMIN_TEXT' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle; color: #ff006c; cursor: pointer" *ngIf="isMe(member.user._id) == false && member.right === 'admin' && isAdmin == true" (click)="switchToRegular(member.user)">
                                                        {{'PROJECT.SETTINGS.SWITCH_TO_REGULAR_MEMNER_TEXT' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle; color: #ff006c;" *ngIf="isMe(member.user._id) == true || isAdmin == false">
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;"
                                    *ngIf="membersAreVisibile == true || true">
                                    <div class="col-12 text-lato"
                                        style="margin-top: 20px;  padding-left: 0px; padding-right: 0px">
                                        <div class="text-lato text-medium card-title"
                                            style="color: #ff006c; width:calc(100% - 26px);">
                                            {{'COMMON.INVITATIONS' | translate}}
                                        </div>
                                    </div>
                                    <div class="col-12 text-lato"
                                        style="color: #FFFFFF; font-size: 18px; padding-bottom: 20px; padding-left: 0px; padding-right: 0px;">
                                        <div class="text-lato text-bold text-small"
                                            style="color: #ffffff; width:calc(100% - 26px)"
                                            *ngIf="!project.invitations || project.invitations.length == 0">
                                            {{'PROJECT.SETTINGS.NO_INVITATION_TEXT' | translate}}
                                        </div>
                                        <table class="table"
                                            *ngIf="project.invitations && project.invitations.length > 0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'PROJECT.SETTINGS.COL_EMAIL_TITLE' | translate}}</th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px">
                                                        {{'PROJECT.SETTINGS.COL_STATUS_TITLE' | translate}}</th>
                                                    <th scope="col" class="text-lato text-small"
                                                        style="border-width: 0px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let invitation of project.invitations ; let i = index">
                                                    <td scope="row" class="text-lato text-small"
                                                        style="vertical-align: middle">
                                                        {{invitation.email}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="invitation.status === 'sent'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ffad00;"></span>{{'COMMON.INVITATION_STATUS_SENT' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="invitation.status === 'accepted'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #ff006c;"></span>{{'COMMON.INVITATION_STATUS_ACCEPTED' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="invitation.status === 'declined'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #999999;"></span>{{'COMMON.INVITATION_STATUS_DECLINED' | translate}}
                                                    </td>
                                                    <td class="text-lato text-small" style="vertical-align: middle;"
                                                        *ngIf="invitation.status === 'cancelled'"><span
                                                            style="margin-right: 10px; border-left-width: 3px; border-left-style: solid; border-left-color: #999999;"></span>{{'COMMON.INVITATION_STATUS_CANCELLED' | translate}}
                                                    </td>
                                                    <td class="text-lato text-medium" style="vertical-align: middle">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;"
                                    *ngIf="membersAreVisibile == true || true">
                                    <div class="col-12 text-lato"
                                        style="margin-top: 20px;  padding-left: 0px; padding-right: 0px">
                                        <div class="text-lato text-medium card-title"
                                            style="color: #ff006c; width:calc(100% - 26px);">
                                            {{'PROJECT.SETTINGS.ADD_A_MEMBER_TEXT' | translate}}
                                        </div>
                                        <div class="text-lato card-title"
                                            style="color: #ffffff; width:calc(100% - 26px); font-size: 13px">
                                            {{'PROJECT.SETTINGS.ADD_A_MEMBER_EXPLANATION_TEXT' | translate}}
                                        </div>
                                        <form [formGroup]="addMemberForm">
                                            <div class="row"
                                                style="display: flex; margin-top: 30px; align-items:center">

                                                <div class="col-9 no-margin text-left">
                                                    <input type="email"
                                                        placeholder="{{'PROJECT.SETTINGS.ADD_A_MEMBER_EMAIL_PLACEHOLDER_TEXT' | translate}}"
                                                        class="input-text text-lato text-medium text-darkgrey"
                                                        name="member_email" formControlName="member_email"
                                                        autocomplete="off" style="color: #FFFFFF!important">
                                                </div>
                                                <div class="col-3 no-margin text-right">
                                                    <span class="btn text-lato text-bold card-title no-margin"
                                                        style="background-color: #ff006c; color: white; font-size: 13px;"
                                                        (click)="invitePeople()">{{'PROJECT.SETTINGS.ADD_A_MEMBER_BUTTON' | translate}}</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal projectId="{{id}}"></modal>