<div class="display-block col-direction section-container clickable">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-9 campaign-title text-pink text-bold text-large">
              <img
                class="brand-logo-thumbnail"
                [src]="deal?._brand?.brand?.brand_logo_path"
              />
              {{ deal.proposal.name }}
              <span
                class="badge badge-primary"
                [ngClass]="{
                  'badge-proposed': getDealStatus() === 'PROPOSED',
                  'badge-disabled':
                    getDealStatus() === 'DRAFT' ||
                    getDealStatus() === 'DECLINED' ||
                    getDealStatus() === 'FINISHED' ||
                    getDealStatus() === 'CANCELLED',
                  'badge-primary': getDealStatus() === 'ACCEPTED'
                }"
                >{{ getDealStatus() }}</span
              >
              <br />
              <span class="brand text-small text-darkgrey">
                By {{ deal._brand?.brand?.name }}</span
              >
            </div>
            <div class="col-3 flex-end">
              <edit-button (click)="updateDeal()"></edit-button>
            </div>
          </div>
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          Offer date : {{ getCreatedDate() }} - Expire date :
          {{ getExpireDate() }}
        </div>
        <div class="text-darkgrey text-xsmall section-budget">
          Budget : {{ deal._scope.total_budget }} €
        </div>
        <div class="text-darkgrey text-xsmall section-budget">
          Campaign Actions
          <div *ngFor="let action of deal.proposal.campaignActions">
            {{ action.type }}
            {{ action.budget }}
            {{ action.frequency }}
            {{ action.description }}
          </div>
        </div>
        <div class="section-body text-small text-darkgrey">
          <div
            class="campaign-brief"
            [innerHTML]="deal.proposal.description"
          ></div>
          <!--div>
            <span>{{ deal.proposal.campaignActions }}</span>
          </div-->
        </div>
        <div class="section-footer text-xsmall text-darkgrey">
          <span class="badge action badge-disabled badge-bigger"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div *ngIf="showModal == true">
  <div class="col-12 d-block" id="modal-menu">
    <div
      class="modal fade modal-overlay"
      id="modalEditDeal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEditDeal"
      aria-hidden="false"
      (click)="closeModal()"
      style="
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 40px;
        z-index: 100;
      "
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddAwardsTitle">
                    {{ 'Edit my offer' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="section-container-inner">
            <form [formGroup]="offerForm">
              <div class="row">
                <div class="col-6">
                  <div class="text-small text-darkgrey">
                    <div class="input-title">Campaign</div>
                    <div class="input-value container-field">
                      <input-field
                        class="input-field input-no-border"
                        type="text"
                        placeholder="Campaign"
                        name="campaignName"
                        relatedControlName="campaignName"
                        formGroupName="campaignNameForm"
                        [parentGroup]="offerForm"
                      >
                      </input-field>
                    </div>
                  </div>
                </div>
                <div class="col-6 field" style="margin-bottom: 0px">
                  <div
                    class="section-body text-small text-darkgrey"
                    style="margin-bottom: 0px"
                  >
                    <div class="input-title">Offer valid until</div>
                    <mat-form-field
                      class="input-text text-lato text-darkgrey container-field separator-width"
                      style="margin-bottom: 0px"
                    >
                      <input
                        class="text-xsmall"
                        matInput
                        [matDatepicker]="pickerExpiryDate"
                        name="expiryDate"
                        formControlName="expiryDate"
                        (dateChange)="changeExpiryDate($event)"
                        autocomplete="off"
                      />

                      <mat-placeholder class="placeholder"
                        >Expiry date</mat-placeholder
                      >
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerExpiryDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerExpiryDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">Campaign Actions</div>

                    <div class="input-value container-field">
                      <form [formGroup]="newActionForm">
                        <select
                          id="action"
                          class="form-control input-text text-lato input-text text-darkgrey form-select separator-width"
                          name="action"
                          formControlName="action"
                        >
                          <option value="" disabled selected>
                            {{ 'Select a campaign action to add' | translate }}
                          </option>
                          <option
                            *ngFor="let action of availableActions"
                            [value]="action.key"
                          >
                            {{ action.value | translate }}
                          </option>
                        </select>
                        <div class="text-right">
                          <span
                            class="btn text-lato text-bold card-title no-margin circle-plus"
                            (click)="addCampaignAction()"
                            >+</span
                          >
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 text-lato"
                  *ngFor="let action of actions; let index = index"
                >
                  <form [formGroup]="action.form">
                    <div class="section-body text-small text-darkgrey">
                      <div class="row">
                        <div class="col-12 text-lato">
                          <div class="input-title">
                            Action #{{ index + 1 }} - {{ action.type.value }}
                            <div
                              class="text-right"
                              style="position: absolute; top: -30px; right: 0"
                            >
                              <span
                                class="btn text-lato text-bold card-title no-margin circle-plus"
                                (click)="removeCampaignAction(action)"
                                >-</span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="col-6 text-lato">
                          <div class="input-value container-field">
                            <div class="input-title" style="margin-bottom: 7px">
                              Unit price - Currency: Euro (EUR) €
                            </div>
                            <div class="input-value container-field">
                              <div class="input-field">
                                <div>
                                  <input
                                    type="number"
                                    placeholder="Amount"
                                    class="input-text text-lato text-medium text-darkgrey"
                                    id="amount"
                                    name="amount"
                                    formControlName="amount"
                                    autocomplete="off"
                                    (change)="updateAmount($event)"
                                    step="any"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 text-lato">
                          <div class="input-value container-field">
                            <div class="input-title" style="margin-bottom: 7px">
                              Frequency
                            </div>
                            <div class="input-value container-field">
                              <select
                                id="action"
                                class="form-control input-text text-lato input-text text-darkgrey form-select"
                                name="frequency"
                                formControlName="frequency"
                              >
                                <option>
                                  {{ 'One-time' }}
                                </option>
                                <option>
                                  {{ 'Weekly' }}
                                </option>
                                <option>
                                  {{ 'Monthly' }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="input-title margin-top-20">Description</div>
                      <div class="input-value container-field">
                        <input-field
                          class="input-field"
                          type="text"
                          placeholder="Add important details like features, options, or measurements"
                          name="description"
                          relatedControlName="description"
                          formGroupName="descriptionForm"
                          [parentGroup]="action.form"
                        >
                        </input-field>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">Notes</div>
                    <div class="input-value container-field">
                      <input-text-area
                        class="input-field"
                        type="text"
                        placeholder="Notes"
                        name="description"
                        relatedControlName="description"
                        formGroupName="descriptionForm"
                        [parentGroup]="offerForm"
                      >
                      </input-text-area>
                    </div>
                  </div>
                </div>
                <!--div class="col-6 text-lato">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title" style="margin-bottom: 7px">
                    Amount (€)
                  </div>
                  <div class="input-value container-field">
                    <div class="input-field">
                      <div>
                        <input
                          type="number"
                          placeholder="Amount"
                          class="input-text text-lato text-medium text-darkgrey"
                          id="amount"
                          name="amount"
                          formControlName="amount"
                          autocomplete="off"
                          (change)="updateAmount($event)"
                          step="any"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 field">
                <div class="section-body text-small text-darkgrey">
                  <div class="input-title">Valid until</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey container-field"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerExpiryDate"
                      name="expiryDate"
                      formControlName="expiryDate"
                      (dateChange)="changeExpiryDate($event)"
                      autocomplete="off"
                    />

                    <mat-placeholder class="placeholder"
                      >Expiry date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerExpiryDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerExpiryDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div-->
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="one-btn-radius btn btn-primary text-lato btn-text"
              (click)="updateOffer()"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
