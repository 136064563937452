<div class="container-fluid full-height bg-default" [style.overflow]="getOverflow()" [style.height]="getHeight()">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'DIGITAL BRAND AUTHORITY' | translate }}" style="width:100%">
            </mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">

        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true"
                    title="{{ 'DIGITAL BRAND AUTHORITY' | translate }}"></nav-bar>
                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25" *ngIf="isLoading == false && project.brand == undefined">
                    <div class="col-12 no-margin">
                        <div class="text-bold text-medium" style="white-space: normal; overflow: hidden; color:#FFFFFF">
                            {{ 'PROJECT.MY_BRAND.NOTHING_TO_SHOW' | translate }}</div>
                        <div style="margin-top: 20px; text-align: center;">
                            <span class="btn text-lato text-bold card-title no-margin"
                                style="background-color: #ff006c; color: white; font-size: 13px"
                                (click)="editBrand()">{{ 'PROJECT.MY_BRAND.FILL_THE_INFORMATION' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="row no-margin margin-top-25" *ngIf="isLoading == false && project.brand != undefined">

                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; margin-top: 20px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="cursor"
                                style="display: flex; flex-direction: row; position: relative; width: 100%">
                                <div style="margin-top: auto; margin-bottom: auto">
                                    <img style="margin-top: -100px; width: 120px; height: 120px; object-fit: cover; border-style: solid; border-width: 2px; border-color: #FFFFFF"
                                        [src]="project.brand.brand_logo_path"
                                        *ngIf="project.brand.brand_logo_path != undefined">
                                    <img style="margin-top: -100px; width: 120px; height: 120px; object-fit: cover; border-style: solid; border-width: 2px; border-color: #FFFFFF"
                                        src="./assets/images/no_brand_pic_pink_grey.png"
                                        *ngIf="project.brand.brand_logo_path == undefined">
                                </div>
                                <div
                                    style="margin-top:auto; margin-bottom: auto; margin-right: 0px; padding-left: 16px; padding-right: 16px; max-width: calc(100% - 68px); width: calc(100% - 68px)">
                                    <div class="text-bold text-large"
                                        style="white-space: normal; overflow: hidden; color:#FFFFFF">
                                        {{project.brand.name}}</div>
                                    <div class=""
                                        style="white-space: normal; overflow: hidden; color:#FFFFFF; margin-top: 10px; text-align: justify; font-size: medium; line-height: 1.5rem;"
                                        *ngIf="project.brand.baseline != undefined">
                                        {{project.brand.name}} is actually seen as an <span
                                            style="color: #ff006c;">Expert</span><br><br>
                                        Experts have typically a low-to-medium traffic to their website and have
                                        low-to-medium interactions on social networks<br>
                                        They have regularly shared content on very specific topics for a short period of
                                        time and this content is shared by industry experts and early adopters.
                                        <br><br>
                                        Keeping sharing high added-value content and increasing brand awareness allow
                                        <span style="color: #ff006c">Expert</span> to turn into <span
                                            style="color: #ff006c">Key Player</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="isLoading == false">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; background-color: #30313e; padding-top: 5px; padding-bottom: 5px; padding-left: 18px; padding-right: 18px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;">WEB</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 light-box left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">
                                        {{ 'Last Week Traffic' | translate }}
                                    </div>
                                </div>
                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 25px; margin-bottom: 5px;">
                                    <div class="col-12 text-lato"
                                        style="color: #FFFFFF; padding: 20px; padding-left: 0px; padding-right: 0px;">
                                        <div
                                            style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                            <div class="chart-container">
                                                <canvas class="chartjs-doughnut"></canvas>
                                            </div>
                                            <div>
                                                <div style="margin-top: 5px; margin-bottom: 5px">
                                                    <span class="text-small">Total Traffic: </span>
                                                    <span
                                                        style="color : #ffffff"><b>{{utils.prettifyNumber(faker.getLastWeekTotalTraffic(id)).value}}</b></span>
                                                    <span><b>{{utils.prettifyNumber(faker.getLastWeekTotalTraffic(id)).unit}}</b></span>
                                                    <span class="text-small">&nbsp;visitors</span>
                                                </div>
                                                <div style="margin-top: 5px; margin-bottom: 5px">
                                                    <span class="text-small" style="color : #ff006c">Organic: </span>
                                                    <span><b>{{utils.prettifyNumber(faker.getLastWeekOrganicTrafficPercentage(id)).value | percent:'.2'}}</b></span>
                                                </div>
                                                <div style="margin-top: 5px; margin-bottom: 5px">
                                                    <span class="text-small" style="color : #ffffff">Ads: </span>
                                                    <span><b>{{utils.prettifyNumber(faker.getLastWeekAdsTrafficPercentage(id)).value | percent:'.2'}}</b></span>
                                                </div>
                                                <div style="margin-top: 5px; margin-bottom: 5px">
                                                    <span class="text-small" style="color : #ffad00">Referrals: </span>
                                                    <span><b>{{utils.prettifyNumber(faker.getLastWeekReferralsTrafficPercentage(id)).value | percent:'.2'}}</b></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">
                                        {{ 'Traffic History' | translate }}
                                    </div>
                                </div>
                                <div class="row"
                                    style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px;">
                                    <div class="col-12 text-lato"
                                        style="color: #FFFFFF; padding: 20px; padding-left: 0px; padding-right: 0px;">
                                        <div
                                            style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                            <div class="chart-container">
                                                <canvas class="chartjs-timeline" width="400" height="200"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="isLoading == false">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; background-color: #30313e; padding-top: 5px; padding-bottom: 5px; padding-left: 18px; padding-right: 18px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;">CONTENT</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Available content' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.content?.available_content?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.content?.available_content?.evolution}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Referrals' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.content?.referrals?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.content?.referrals?.evolution}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; border-bottom-right-radius: 0; border-bottom-left-radius: 0px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Keywords' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box"
                        style="border-top-right-radius: 0; border-top-left-radius: 0px; background-color: #3e3e4f;">

                        <div col="col-12" style="width: 100%;">
                            <div class="row"
                                style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px">
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px;">
                                    Keyword
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Global Volume (My Part)
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Competition
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Estimated CPC
                                </div>

                            </div>
                            <div class="row"
                                style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px"
                                *ngFor="let keyword of keywords">
                                
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{keyword.description.identifier}}
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{keyword.activity.overall}} ({{keyword.activity.me}})
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{keyword.activity.competition}}
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{keyword.activity.cpc}}
                                </div>

                            </div>
                            <div class="row"
                                *ngIf="hashtags == undefined || hashtags.length == 0">
                                <div class="col-12 text-lato text-medium"
                                    style="background-color: #00000030; margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white">
                                    {{'No keywords' | translate}}
                                </div>
                            </div>

                        </div>


                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="isLoading == false">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; background-color: #30313e; padding-top: 5px; padding-bottom: 5px; padding-left: 18px; padding-right: 18px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;">SOCIAL MEDIA</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-md-6 col-lg-6 light-box left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Number of publications' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.social_media?.number_of_publications?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.social_media?.number_of_publications?.evolution}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Current reach' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.social_media?.reach?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.social_media?.reach?.evolution | percent:'.2'}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Total Weekly Impressions' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.social_media?.impressions?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.social_media?.impressions?.evolution | percent:'.2'}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Referrals' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00; font-size: 36px">
                                    {{data?.analysis?.social_media?.referrals?.value}}</span>
                                <span class="text-lato text-bold card-title text-medium no-margin"
                                    style="color: #ff006c; font-size: 14px">
                                    <br>+{{data?.analysis?.social_media?.referrals?.evolution}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; border-bottom-right-radius: 0; border-bottom-left-radius: 0px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Hashtags' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box"
                        style="border-top-right-radius: 0; border-top-left-radius: 0px; background-color: #3e3e4f;">

                        <div col="col-12" style="width: 100%;">
                            <div class="row"
                                style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px">
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px;">
                                    Network
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Hashtag
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Volume
                                </div>
                                <div class="col-3 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Competition
                                </div>

                            </div>
                            <div class="row"
                                style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px"
                                *ngFor="let hashtag of hashtags">
                                
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    <img src='./assets/images/socials-linkedin.png' *ngIf="hashtag.description.network === 'linkedin'" style="width: 20px;">
                                    <img src='./assets/images/socials-twitter.png' *ngIf="hashtag.description.network === 'twitter'" style="width: 20px;"> 
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{hashtag.description.identifier}}
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{hashtag.activity.overall}}
                                </div>
                                <div class="col-3 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(25% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{hashtag.activity.competition}}
                                </div>

                            </div>
                            <div class="row"
                                *ngIf="hashtags == undefined || hashtags.length == 0">
                                <div class="col-12 text-lato text-medium"
                                    style="background-color: #00000030; margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white">
                                    {{'No hashtags' | translate}}
                                </div>
                            </div>

                        </div>


                    </div>

                    <div class="col-12 light-box margin-top-25"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px; border-bottom-right-radius: 0; border-bottom-left-radius: 0px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin" style="display: flex; align-items: baseline">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF; padding-top: 6px!important">
                                    {{ 'Advocates' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box"
                        style="border-top-right-radius: 0; border-top-left-radius: 0px; background-color: #3e3e4f;">

                        <div col="col-12" style="width: 100%;">
                            <div class="row"
                                style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px">
                                <div class="col-2 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px;">
                                    Network
                                </div>
                                <div class="col-6 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(50% - 2px); height: 40px; display: flex; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Advocate
                                </div>
                                <div class="col-2 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Refferals
                                </div>
                                <div class="col-2 text-lato text-medium"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 40px; display: flex; justify-content: center; align-items: center; color: #ff006c; font-weight: 200; font-size: 14px">
                                    Traffic
                                </div>

                            </div>
                            <div class="row"
                                style="color: white; text-align: left; margin-top: 3px; margin-bottom: 3px"
                                *ngFor="let advocate of advocates">
                                
                                <div class="col-2 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 60px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    <img src='./assets/images/socials-linkedin.png' *ngIf="advocate.description.network === 'linkedin'" style="width: 20px;">
                                    <img src='./assets/images/socials-twitter.png' *ngIf="advocate.description.network === 'twitter'" style="width: 20px;"> 
                                </div>
                                <div class="col-6 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(50% - 2px); height: 60px; display: flex; align-items: center; font-weight: 200; font-size: 14px;">
                                    <img [src]="advocate.description.pic_url" style="width: 40px; border-radius: 50%; margin-right: 10px">{{advocate.description.identifier}}
                                </div>
                                <div class="col-2 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 60px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{advocate.referrals.links}}
                                </div>
                                <div class="col-2 text-lato text-small"
                                    style="background-color: #2b2b37; margin-left: 1px; margin-right: 1px; max-width: calc(16.6666667% - 2px); height: 60px; display: flex; justify-content: center; align-items: center; font-weight: 200; font-size: 14px;">
                                    {{advocate.referrals.traffic}}
                                </div>

                            </div>
                            <div class="row"
                                *ngIf="hashtags == undefined || hashtags.length == 0">
                                <div class="col-12 text-lato text-medium"
                                    style="background-color: #00000030; margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white">
                                    {{'No advocates' | translate}}
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<modal projectId="{{id}}"></modal>