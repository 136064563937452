import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-opportunities.component.html',
  styleUrls: ['./publisher-opportunities.component.scss'],
})
export class PublisherOpportunitiesComponent
  implements OnInit, AfterViewInit, OnDestroy {
  opportunities: any[]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.opportunities = route.snapshot.data.opportunities
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}
}
