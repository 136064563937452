import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import * as moment from 'moment'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from '../../../../_services/api.service'
import { AlertService } from '../../../../_services/alert.service'
import { ModalService } from '../../../../_services/modal.service'
import { CampaignCreationBufferService } from './../../../../_services/buffers/campaign.creation.buffer.service'
import { LeftMenuComponent } from '../../../../_directives/navigation/left-menu'

import { DateHelper } from '../../../../_helpers/date.helpers'
import { CampaignHelper } from '../../../../_helpers/campaign.helper'

import { PreferencesManager } from './../../../../_services/preferences.manager.service'

import { FakerHelper } from './../../../../_helpers/faker.helper'
import { Utils } from './../../../../_helpers/utils.helper'

@Component({
  templateUrl: 'project_dashboard.component.html',
  styleUrls: ['./project_dashboard.component.scss'],
})
export class ProjectDashboardComponent
  implements OnInit, OnDestroy, AfterViewInit {
  utils = Utils

  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  projects: any
  project_index: any

  campaigns: any

  membersAreVisibile: any

  socialMediaAreVisible: any

  budget: any[]
  total_budget: number
  use_budget: number

  before: boolean
  work: number
  after: boolean
  date: any[]
  duration: any[]
  remaining_day: any[]

  me: any

  data: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    public alertService: AlertService,
    private modalService: ModalService,
    private campaignCreationBufferService: CampaignCreationBufferService,
    public preferencesManager: PreferencesManager
  ) {
    this.isLoading = true
    this.showSomething = false

    this.membersAreVisibile = false
    this.socialMediaAreVisible = false
    this.budget = []
    this.total_budget = 0
    this.use_budget = 0
    this.before = false
    this.work = undefined
    this.after = false
    this.date = []
    this.duration = []
    this.remaining_day = []

    this.data = FakerHelper.getBrandAuthorityData('')

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false
    }
  }

  async ngOnInit() {
    this.alertService.resetAlerts()

    this.route.data.subscribe((data: { user: any }) => {
      this.me = data.user.body.user
      //this.dataService.registerMe(data.user.body.user)
      //this.dataService.fetchProjects(data.user.body.projects)

      this.modalSub = this.modalService
        .getModals()
        .subscribe(async (object) => {
          if (object === 'social-accounts-close') {
            if (this.id) {
              this.projects = data.user.body.projects
              for (var i = 0; i < this.projects.length; i++) {
                if (this.projects[i]._id == this.id) {
                  this.project_index = i
                  break
                }
              }

              this.campaigns = this.projects[this.project_index].campaigns
              for (var i = 0; i < this.campaigns.length; i++) {
                this.campaigns[
                  i
                ].start_date = DateHelper.convertDoubleToReadableDate(
                  this.campaigns[i].startDate
                )
                this.campaigns[
                  i
                ].end_date = DateHelper.convertDoubleToReadableDate(
                  this.campaigns[i].endDate
                )
              }
              this.isLoading = false
            }
          }
        })
      this.sub = this.route.params.subscribe(async (params) => {
        this.id = params['projectId']

        this.data = FakerHelper.getBrandAuthorityData(this.id)

        this.projects = data.user.body.projects
        //this.projects = await this.cacheService.getProjects();
        for (var i = 0; i < this.projects.length; i++) {
          if (this.projects[i]._id == this.id) {
            this.project_index = i
            break
          }
        }

        var percent = []
        this.campaigns = this.projects[this.project_index].campaigns
        for (var i = 0; i < this.campaigns.length; i++) {
          this.campaigns[i].start_date = DateHelper.convertDoubleToReadableDate(
            this.campaigns[i].startDate
          )
          this.campaigns[i].end_date = DateHelper.convertDoubleToReadableDate(
            this.campaigns[i].endDate
          )

          var start = moment(this.campaigns[i].start_date, 'DDMMYYYY')
          var end = moment(this.campaigns[i].end_date, 'DDMMYYYY')
          var now = moment(new Date())
          // Campaign duration
          let newNumber = {
            number: end.diff(start, 'days'),
          }
          this.duration.push(newNumber)
          //Campaign remaining days
          let newDay = {
            number: end.diff(now, 'days'), //moment.duration(now.diff(end))
          }
          this.remaining_day.push(newDay)

          if (now < start) {
            this.work = 0
          }

          if (now > start && now < end) {
            this.work = 1
          }

          if (now > end) {
            this.work = 2
          }

          let newDate = {
            day: this.work,
          }
          this.date.push(newDate)

          this.total_budget = this.campaigns[i].total_budget
          this.use_budget =
            this.total_budget - this.campaigns[i].remaining_budget

          if (this.total_budget == 0) this.budget.push({ remaining: 0 })
          else {
            let newPercent = {
              remaining: (this.use_budget * 100) / this.total_budget,
            }
            this.budget.push(newPercent)
          }
        }

        if (this.projects[this.project_index].brand == undefined) {
          this.alertService.pushErrorAlert('COMMON.NO_BRAND_INFORMATION')
        }

        this.isLoading = false
      })
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  showMembers(toShow) {
    this.membersAreVisibile = toShow
  }

  showSocialMedia(toShow) {
    this.socialMediaAreVisible = toShow
  }

  numberOfMembers() {
    if (this.projects && this.projects[this.project_index].members) {
      return this.projects[this.project_index].members.length
    } else return 0
  }

  numberOfSocialMediaAccounts() {
    if (
      this.projects &&
      this.projects[this.project_index].socialMedia &&
      this.projects[this.project_index].socialMedia.instagram
    ) {
      return this.projects[this.project_index].socialMedia.instagram.length
    } else return 0
  }

  getSocialMediaAccounts() {
    var accounts = []
    if (
      this.projects[this.project_index].socialMedia.instagram &&
      this.projects[this.project_index].socialMedia.instagram.length > 0
    )
      for (
        var i = 0;
        i < this.projects[this.project_index].socialMedia.instagram.length;
        i++
      ) {
        accounts.push(
          this.projects[this.project_index].socialMedia.instagram[i]
        )
      }
    return accounts
  }

  async removeSocialMedia(id, i) {
    var res = await this.apiService.unregisterInstagramAccount(this.id, id)
    if (res.status == 200) {
      this.projects[this.project_index].socialMedia.instagram.splice(i, 1)
    }
  }

  createCampaign() {
    if (
      this.campaignCreationBufferService.campaignBeingCreated().id != undefined
    )
      this.campaignCreationBufferService.init()
    this.router.navigate([
      '/projects/' + this.projects[this.project_index]._id + '/campaign/new',
    ])
  }

  getCampaignLink(campaign) {
    if (window.innerWidth > 991)
      return (
        '/projects/' +
        this.projects[this.project_index]._id +
        '/campaigns/' +
        campaign._id +
        '/brief'
      )
    else
      return (
        '/projects/' +
        this.projects[this.project_index]._id +
        '/campaigns/' +
        campaign._id +
        '/all'
      )
  }

  linkSocialAccount() {
    this.showThing('social-accounts')
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }

  getCampaignState(campaign) {
    return CampaignHelper.getCampaignState(campaign)
  }

  getShowMenu(i, show) {
    LeftMenuComponent.exportShowSubMenu(i, show)
  }

  getTotalTraffic() {
    if (this.data == undefined || this.data.web_traffic == undefined) return 0
    else
      return (
        this.data.web_traffic.organic +
        this.data.web_traffic.ads +
        this.data.web_traffic.referrals
      )
  }

  getReferralsTraffic() {
    if (this.data == undefined || this.data.web_traffic == undefined) return 0
    else return this.data.web_traffic.referrals
  }

  getSocialImpressions() {
    if (this.data == undefined || this.data.social_media == undefined) return 0
    else return this.data.social_media.impressions
  }

  getSocialMentions() {
    if (this.data == undefined || this.data.social_media == undefined) return 0
    else return this.data.social_media.mentions
  }
}
