import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../../_services/index'
import { DataService } from './../../../../_services/data.service'

import { DateHelper } from './../../../../_helpers/date.helpers'

import { PreferencesManager } from './../../../../_services/preferences.manager.service'

import { FakerHelper } from './../../../../_helpers/faker.helper'

@Component({
  templateUrl: 'campaign_publications.component.html',
  styleUrls: ['./campaign_publications.component.scss'],
})
export class CampaignPublicationsComponent implements OnInit, OnDestroy {
  isLoading: boolean
  id: string
  private sub: any

  campaign: any

  publications: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private preferencesManager: PreferencesManager
  ) {
    this.isLoading = true
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['campaignId']

      this.campaign = this.dataService.getCampaign(this.id)
      this.publications = FakerHelper.getCampaignData(
        this.id,
        this.campaign.target
      ).publications

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  getDateString(timestamp) {
    return DateHelper.convertDoubleToReadableDate(timestamp)
  }

  getImpressions(publication) {
    try {
      return publication.insights.data[1].values[0].value
    } catch (e) {
      return '-'
    }
  }

  getScaler(scaler) {
    return FakerHelper.getScaler(scaler)
  }
}
