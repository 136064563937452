import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core'
import { first } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { InternalAuthenticationService } from '../../../_services/authentication/internal.authentication.service'
import { AuthService } from '@cryptr/cryptr-angular'

import { environment } from './../../../../environments/environment'
import { APIService } from 'src/app/_services'
import { USER_ROLE } from 'src/app/_helpers/referential/user.role'

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  submitted = false
  loginForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  correctLogin = true
  isCryptrActivated = environment.isCryptrActivated || false
  inexistingRoleButConfirmed: boolean = false
  showConfirmationPopup: boolean = false

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: InternalAuthenticationService,
    private auth: AuthService,
    private apiService: APIService
  ) {}

  ngOnInit() {
    // verify if login and password are true
    this.correctLogin == true

    this.loginForm = this.formBuilder.group({
      emailForm: this.formBuilder.group({
        email: ['', Validators.required],
      }),
      passwordForm: this.formBuilder.group({
        password: ['', Validators.required],
      }),
    })

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls
  }

  async onSubmit() {
    this.submitted = true

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return
    }

    this.loading = true

    if (this.inexistingRoleButConfirmed == false) {
      let checkRole = await this.checkUserRole()

      if (checkRole.success == true) {
        if (checkRole.exists == false) {
          this.loading = false
          this.correctLogin = false
          return
        } else {
          if (checkRole.hasRole == false) {
            this.loading = false
            this.askConfirmationForLogin()
            return
          }
        }
      } else {
        this.loading = false
        this.correctLogin = false
        return
      }
    }

    this.performLogin()
  }

  signUpWithRedirect(): void {
    this.auth.signInWithRedirect()
  }

  async checkUserRole() {
    try {
      let result = await this.apiService.checkUseRole(
        this.loginForm.controls.emailForm['controls']['email'].value,
        environment.role
      )

      if (result.status == 200) {
        return {
          success: true,
          exists: result.body.exists,
          hasRole: result.body.hasRole,
        }
      } else {
        return {
          success: false,
        }
      }
    } catch (e) {
      return {
        success: false,
      }
    }
  }

  askConfirmationForLogin() {
    this.showConfirmationPopup = true
  }

  closeModal() {
    this.showConfirmationPopup = false
  }

  confirmLogin() {
    this.closeModal()
    this.performLogin()
  }

  getSiblingPlatformText() {
    if (environment.role === USER_ROLE.ADVERTISER)
      return 'No, redirect me to Foxy Nerds influencer platform'

    if (environment.role === USER_ROLE.PUBLISHER)
      return 'No, redirect me to Foxy Nerds brand platform'

    return undefined
  }

  redirectToSiblingPlatform() {
    if (environment.role === USER_ROLE.ADVERTISER)
      this.redirectToInfluencerPlatform()

    if (environment.role === USER_ROLE.PUBLISHER) this.redirectToBrandPlatform()
  }

  redirectToBrandPlatform() {
    this.document.location.href = 'https://app.foxynerds.studio'
  }

  redirectToInfluencerPlatform() {
    this.document.location.href = 'https://influencers.foxynerds.com'
  }

  performLogin() {
    this.loading = true
    this.authenticationService
      .login(
        this.loginForm.controls.emailForm['controls']['email'].value,
        this.loginForm.controls.passwordForm['controls']['password'].value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.router.navigate([this.returnUrl])
          this.loading = false
        },
        (error) => {
          this.error = error
          //this.loading = false;
          this.loading = false
          this.correctLogin = false
        }
      )
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
