<div class="display-block col-direction serparator">
  <div class="section-container-inner text-lightgrey text-xsmall">
    <div class="row full-width">
      <div class="col-8"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-4">
            <div class="clickable">
              <div class="d-flex avatar-menu float-right">
                <div>
                  <div
                    id="tasks"
                    title="click to change tasks filter"
                    (click)="toggleTaskMenu()"
                  >
                    <img
                      id="tasks"
                      class="icons"
                      src="../../../../../assets/images/icon-task.png"
                      alt="sort icon"
                    />
                    {{ selectedTaskStatusToShow.filter }}
                  </div>

                  <div
                    class="row submodal-down"
                    *ngIf="showTaskStatusMenu == true"
                  >
                    <div class="col-12 submenu">
                      <div
                        class="row"
                        *ngFor="let choice of taskMenu"
                        (click)="filterTaskStatus(choice)"
                      >
                        <div class="col-1">
                          <img
                            *ngIf="selectedTaskStatusToShow.key === choice.key"
                            class="icons-selected"
                            src="../../../../assets//images/icon-check.png"
                          />
                        </div>
                        <div
                          class="col-9 text-lato text-xsmall cursor submenu-title"
                        >
                          {{ choice.filter }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 padding-filter-title">
            <div class="clickable">
              <div class="d-flex avatar-menu justify-content-center">
                <div>
                  <div
                    id="filter"
                    title="Click to change filter"
                    (click)="toggleFilterMenu()"
                  >
                    <img
                      id="filter"
                      class="icons"
                      src="../../../../../assets/images/icon-filter.png"
                      alt="sort icon"
                    />
                    {{ selectedFilter.name }}
                  </div>

                  <div class="row submodal-down" *ngIf="showFilterMenu == true">
                    <div class="col-12 submenu">
                      <div
                        class="row"
                        *ngFor="let choice of filterMenu"
                        (click)="selectFilter(choice)"
                      >
                        <div class="col-1">
                          <img
                            *ngIf="selectedFilter.name === choice.name"
                            class="icons-selected"
                            src="../../../../assets//images/icon-check.png"
                          />
                        </div>
                        <div
                          class="col-9 text-lato text-xsmall cursor submenu-title"
                        >
                          {{ choice.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="clickable">
              <div class="d-flex avatar-menu">
                <div>
                  <div
                    id="brand"
                    title="Click to change brand filter"
                    (click)="toggleBrandMenu()"
                  >
                    <img
                      id="brand"
                      class="icons"
                      src="../../../../../assets/images/icon-sort.png"
                      alt="brand icon"
                    />
                    {{ selectedBrand }}
                  </div>

                  <div class="row submodal-down" *ngIf="showBrandMenu == true">
                    <div class="col-12 submenu">
                      <div
                        class="row"
                        *ngFor="let choice of brandMenu"
                        (click)="selectBrandToShow(choice)"
                      >
                        <div class="col-1">
                          <img
                            *ngIf="selectedBrand === choice.title"
                            class="icons-selected"
                            src="../../../../assets//images/icon-check.png"
                          />
                        </div>
                        <div
                          class="col-9 text-lato text-xsmall cursor submenu-title"
                        >
                          {{ choice.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--div class="col-4">
            <div class="clickable">
              <div class="d-flex avatar-menu">
                <div>
                  <div
                    id="sort"
                    title="Click to change sort filter"
                    (click)="toggleSortMenu()"
                  >
                    <img
                      id="sort"
                      class="icons"
                      src="../../../../../assets/images/icon-sort.png"
                      alt="sort icon"
                    />
                    {{ selectedSort }}
                  </div>

                  <div class="row submodal-down" *ngIf="showSortMenu == true">
                    <div class="col-12 submenu">
                      <div
                        class="row"
                        *ngFor="let choice of sortMenu"
                        (click)="selectSortToShow(choice.name)"
                      >
                        <div class="col-1">
                          <img
                            *ngIf="selectedSort === choice.name"
                            class="icons-selected"
                            src="../../../../assets//images/icon-check.png"
                          />
                        </div>
                        <div
                          class="col-9 text-lato text-xsmall cursor submenu-title"
                        >
                          {{ choice.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div-->
        </div>
      </div>
    </div>
  </div>
</div>
