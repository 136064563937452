import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { AdvertisersAudiencesAPIService } from '../../_services/api/advertisers.audiences.api.service'

@Injectable()
export class AdvertisersAudiencesResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private audiencesApiService: AdvertisersAudiencesAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const workspaceId = <string>route.paramMap.get('workspaceId')
      let request = await this.audiencesApiService.getAudiences(workspaceId)

      return request.body.plans
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
