<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Publications</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addPublication()"></add-button>
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddPublication"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddPublication"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato"
                    id="modalAddPublicationTitle"
                  >
                    {{ 'Add a publication' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="publicationsForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Title</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Title"
                      name="title"
                      relatedControlName="title"
                      formGroupName="titleForm"
                      [parentGroup]="publicationsForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Author</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Author"
                      name="author"
                      relatedControlName="author"
                      formGroupName="authorForm"
                      [parentGroup]="publicationsForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Date</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Date"
                      name="date"
                      relatedControlName="date"
                      formGroupName="dateForm"
                      [parentGroup]="publicationsForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Url</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Url"
                      name="url"
                      relatedControlName="url"
                      formGroupName="urlForm"
                      [parentGroup]="publicationsForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Summary</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Summary"
                      name="summary"
                      relatedControlName="summary"
                      formGroupName="summaryForm"
                      [parentGroup]="publicationsForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your publication have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your publication was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="validatePublication()"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
