import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class AdvertisersAudiencesAPIService {
  constructor(private http: HttpClient) {}

  async getAudiences(workspaceId) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/keywords/plans'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getAudience(workspaceId, planId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/keywords/plans/' +
      planId

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async createAudience(workspaceId, audienceJson) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/keywords/plans'

    var res = await this.http
      .post<any>(url, audienceJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateAudience(workspaceId, audienceId, audienceJson) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/keywords/plans/' +
      audienceId

    var res = await this.http
      .post<any>(url, audienceJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async deleteAudience(workspaceId, audienceId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/keywords/plans/' +
      audienceId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
