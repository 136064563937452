import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { PublishersTasksAPIService } from '../../_services/api/publishers.tasks.api.service'

@Injectable()
export class PublishersTasksResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private taskApiService: PublishersTasksAPIService
  ) {}
  async resolve(): Promise<any> {
    try {
      let user = await this.apiService.getMyProfile()
      let publisherId = user?.body?.publisher?._id
      let request = await this.taskApiService.getTasks(publisherId)

      let tasks = undefined
      let referential = undefined

      if (request.body != undefined && request.body.tasks != undefined)
        tasks = request.body.tasks

      if (request.body != undefined && request.body.referential != undefined)
        referential = request.body.referential

      return {
        tasks: tasks,
        referential: referential,
      }
    } catch (e) {
      return {
        tasks: undefined,
        referential: undefined,
      }
    }
  }
}
