import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from '../../../_services/api.service'
import { ModalService } from '../../../_services'

@Component({
  templateUrl: 'journey_start.component.html',
  styleUrls: ['./journey_start.component.scss'],
})
export class JourneyStartComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private modalService: ModalService
  ) {
    this.isLoading = true
    this.showSomething = false
  }

  async ngOnInit() {
    this.route.data.subscribe((data: { user: any }) => {
      if (
        data != undefined &&
        data.user != undefined &&
        data.user.body != undefined &&
        data.user.body.projects != undefined &&
        data.user.body.projects.length > 0
      ) {
        this.router.navigate(['/'])
      }
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {}

  ngAfterViewInit() {
    this.init()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  confirmLogout() {
    event.preventDefault()
    var modal = document.querySelector('.modal') // assuming you have only 1
    var html = document.querySelector('html')
    modal.classList.add('is-active')
    html.classList.add('has-activated-background')
  }

  logoutConfirmed() {
    this.logout()
  }

  logoutCancelled() {
    var modal = document.querySelector('.modal')
    var html = document.querySelector('html')
    modal.classList.remove('is-active')
    html.classList.remove('has-activated-background')
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }
}
