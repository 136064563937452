import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { ModalService } from '../../../_services/modal.service'
import { APIService } from '../../../_services/api.service'
import { TranslateService } from '@ngx-translate/core'

import { PreferencesManager } from './../../../_services/preferences.manager.service'

var languages = [
  {
    id: 0,
    name: 'LANGUAGE.ENGLISH',
    code: 'en',
  },
  {
    id: 1,
    name: 'LANGUAGE.FRENCH',
    code: 'fr',
  },
]

var currencies = [
  {
    id: 0,
    name: 'EUR - Euro',
    symbol: '€',
  },
  {
    id: 1,
    name: 'USD - US Dollar',
    symbol: '$',
  },
]

@Component({
  templateUrl: 'modal-profile.component.html',
  selector: 'modal-profile',
  styleUrls: ['./modal-profile.component.scss'],
})
export class ModalProfileComponent implements OnInit {
  me: any
  profileForm: FormGroup
  hasChangedProfilePicture: any

  passwordForm: FormGroup

  preferencesForm: FormGroup

  selectedProfile: File
  profileSrc: any

  missingFieldsInProfile: boolean
  incorrectEmail: boolean

  missingFieldsInPassword: boolean
  passwordsMismatched: boolean
  passwordTooShort: boolean
  wrongPassword: boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private preferencesManager: PreferencesManager
  ) {
    this.me = undefined
    this.hasChangedProfilePicture = false

    this.profileSrc = undefined

    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      profileUrl: [''],
      company: [''],
      phoneNumber: [''],
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    })

    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })

    var language = this.translate.currentLang
    this.preferencesForm = this.formBuilder.group({
      language: ['', Validators.required],
      currency: ['', Validators.required],
    })

    var langs = this.getLanguages()
    for (var i = 0; i < langs.length; i++) {
      if (langs[i].code === language) {
        this.preferencesForm.controls.language.setValue(langs[i].id)
      }
    }
  }

  async ngOnInit() {
    this.missingFieldsInProfile = false
    this.incorrectEmail = false

    this.missingFieldsInPassword = false
    this.passwordsMismatched = false
    this.passwordTooShort = false
    this.wrongPassword = false

    var res = await this.apiService.getMyProfile()
    if (res.body) {
      this.me = res.body
      this.profileForm.controls.lastName.setValue(this.me.user.infos.lastname)
      this.profileForm.controls.firstName.setValue(this.me.user.infos.firstname)
      this.profileForm.controls.email.setValue(this.me.user.infos.email)
      this.profileForm.controls.company.setValue(this.me.user.infos.company)
      this.profileForm.controls.phoneNumber.setValue(
        this.me.user.infos.phoneNumber
      )
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('profile')
  }

  async onSubmit() {
    this.missingFieldsInProfile = false
    this.incorrectEmail = false

    this.missingFieldsInPassword = false
    this.passwordsMismatched = false
    this.passwordTooShort = false
    this.wrongPassword = false

    // CHECK WHAT WE HAVE TO SUBMIT
    var hasToSubmitProfile = false
    var hasToSubmitPassword = false

    var profileCandidates = {}
    var passwordCandidates = {}
    var languageCandidate = undefined

    var isMissingFields = false
    var isValidEmail = true
    var isValidPassword = true
    var passwordMismatches = false

    if (this.hasChangedProfilePicture == true) hasToSubmitProfile = true

    if (this.profileForm.controls.lastName.value)
      profileCandidates[
        'lastName'
      ] = this.profileForm.controls.lastName.value.trim()

    if (this.profileForm.controls.firstName.value)
      profileCandidates[
        'firstName'
      ] = this.profileForm.controls.firstName.value.trim()

    if (this.profileForm.controls.email.value)
      profileCandidates[
        'email'
      ] = this.profileForm.controls.email.value.trim().toLowerCase()

    if (this.profileForm.controls.company.value)
      profileCandidates[
        'company'
      ] = this.profileForm.controls.company.value.trim()

    if (this.profileForm.controls.phoneNumber.value)
      profileCandidates[
        'phoneNumber'
      ] = this.profileForm.controls.phoneNumber.value.trim()

    var candidate_language = this.preferencesForm.controls.language.value
    languageCandidate = languages[candidate_language].code

    if (
      this.me.user.infos.lastName != profileCandidates['lastName'] ||
      this.me.user.infos.firstName != profileCandidates['firstName'] ||
      this.me.user.infos.company != profileCandidates['company'] ||
      this.me.user.phoneNumber != profileCandidates['phoneNumber'] ||
      this.me.user.infos.email != profileCandidates['email'] ||
      this.me.user.preferences.language != languageCandidate
    ) {
      hasToSubmitProfile = true
    }

    if (this.passwordForm.controls.currentPassword.value) {
      passwordCandidates[
        'currentPassword'
      ] = this.passwordForm.controls.currentPassword.value.trim()
    }

    if (this.passwordForm.controls.newPassword.value) {
      passwordCandidates[
        'newPassword'
      ] = this.passwordForm.controls.newPassword.value.trim()
    }

    if (this.passwordForm.controls.confirmPassword.value) {
      passwordCandidates[
        'confirmPassword'
      ] = this.passwordForm.controls.confirmPassword.value.trim()
    }

    if (
      passwordCandidates['currentPassword'] != undefined ||
      passwordCandidates['newPassword'] != undefined ||
      passwordCandidates['confirmPassword'] != undefined
    ) {
      hasToSubmitPassword = true
    }

    // CHECK IF FORMS ARE VALID
    if (hasToSubmitProfile) {
      if (this.profileForm.invalid) {
        if (this.profileForm.controls.email.invalid) {
          isValidEmail = false
          this.incorrectEmail = true
          return
        } else {
          if (
            this.profileForm.controls.firstName.invalid ||
            this.profileForm.controls.lastName.invalid
          ) {
            isMissingFields = false
            this.missingFieldsInProfile = true
            return
          }
        }
      }
    }

    if (hasToSubmitPassword) {
      if (this.passwordForm.invalid) {
        isMissingFields = false
        this.missingFieldsInPassword = true
        return
      } else {
        if (
          passwordCandidates['newPassword'] !=
          passwordCandidates['confirmPassword']
        ) {
          passwordMismatches = true
          this.passwordsMismatched = true
          return
        }
        if (passwordCandidates['newPassword'].length < 10) {
          this.passwordTooShort = true
          return
        }
      }
    }

    var infos = {}
    if (hasToSubmitProfile) {
      infos['email'] = profileCandidates['email']
      infos['firstname'] = profileCandidates['firstName']
      infos['lastname'] = profileCandidates['lastName']
      infos['company'] = profileCandidates['company']
      infos['phoneNumber'] = profileCandidates['phoneNumber']
      infos['language'] = languageCandidate
    }

    if (hasToSubmitPassword) {
      infos['old_password'] = passwordCandidates['currentPassword']
      infos['new_password'] = passwordCandidates['newPassword']
    }

    if (hasToSubmitProfile || hasToSubmitPassword) {
      await this.apiService.updateAccount(infos, this.selectedProfile)
      localStorage.setItem('language', languageCandidate)
    }
  }

  onFileChanged(event) {
    this.hasChangedProfilePicture = true
    this.selectedProfile = event.target.files[0]

    const reader = new FileReader()
    reader.onload = (e) => (this.profileSrc = reader.result)

    reader.readAsDataURL(this.selectedProfile)
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  getLanguages() {
    return languages
  }

  getCurrencies() {
    return currencies
  }

  updateLanguage() {
    var candidate_language = this.preferencesForm.controls.language.value

    if (candidate_language)
      this.translate.use(languages[candidate_language].code)
  }

  updateCurrency() {
    var candidate_currency = this.preferencesForm.controls.currency.value

    if (candidate_currency)
      this.preferencesManager.setPreferredCurrency(
        currencies[candidate_currency]
      )
  }
}
