import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
} from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'

import { IndustryHelper } from '../../../../_helpers/referential/industry.helper'

import { environment } from './../../../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Component({
  templateUrl: 'project_brand_edit.component.html',
  styleUrls: ['./project_brand_edit.component.scss'],
})
export class ProjectBrandEditComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any
  isValid: boolean
  hasBrandNameError: boolean
  myBrandForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasAccount = false

  hasSelectedAFile: boolean

  project_name: any
  project: any
  project_id: any

  selectedFile: File // For Brand Logo
  imageSrc: any // For Brand Logo

  selectedresourceFile: File // For resources
  resourceFileSrc: any // For resources

  brand_logo_url: any

  links: any[]
  advocates: any[]

  resources: any[]

  countries: any
  states: any

  locations: any[]

  all_countries: any
  all_states: any

  location_country: any
  location_area: any

  industries: any[] = IndustryHelper.getIndustries()

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private location: Location,
    private _ngZone: NgZone,
    public alertService: AlertService,
    private http: HttpClient
  ) {
    this.project_name = undefined
    this.project = undefined
    this.project_id = undefined
    this.loading = false
    this.hasAccount = false
    this.imageSrc = undefined
    this.links = []
    this.advocates = []
    this.resources = []

    this.locations = []

    this.location_country = undefined
    this.location_area = undefined

    this.isValid = true
    this.hasBrandNameError = false
    this.hasSelectedAFile = false
  }

  async ngOnInit() {
    this.alertService.resetAlerts()

    this.myBrandForm = this.formBuilder.group({
      brandNameForm: this.formBuilder.group({
        brandName: [''],
      }),
      baseline: [''],
      description: [''],
      industry: [''],
      values: [''],
      products: [''],
      salesTarget: [''],
      country: [''],
      area: [''],
      typeOfLink: ['choose'],
      linkForm: this.formBuilder.group({
        link: [''],
      }),
      typeOfAdvocateLink: ['choose'],
      advocateLinkForm: this.formBuilder.group({
        advocateLink: [''],
      }),
      resourceNameForm: this.formBuilder.group({
        resourceName: [''],
      }),
      resourceFile: [''],
      resourceIsPublic: [false],
    })

    await this.generateCountryList()

    this.countries = this.getCountries()
    this.states = this.all_states

    this.sub = this.route.params.subscribe(async (params) => {
      this.project_id = params['projectId']

      this.brand_logo_url = './assets/images/img-placeholder.png'

      this.project = this.dataService.getProject(this.project_id)

      this.initValues(this.project)

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  async generateCountryList() {
    try {
      var json = await this.http
        .get(BACKEND_URL + '/public/i18n/en.json')
        .toPromise()

      if (json != undefined) {
        this.all_countries = []
        this.all_states = []
        //console.log(json)
        if (
          json['COUNTRIES'] &&
          json['COUNTRIES']['LIST'] &&
          json['COUNTRIES']['STATES']
        ) {
          var i_country = 0
          var i_state = 0
          for (var key in json['COUNTRIES']['LIST']) {
            var countryToAdd = {
              id: i_country,
              name: 'COUNTRIES.LIST.' + key,
            }

            for (var state in json['COUNTRIES']['STATES']) {
              if (state.startsWith(key) || state === 'EVERYWHERE') {
                var stateToAdd = {
                  id: i_state,
                  name: 'COUNTRIES.STATES.' + state,
                  country: i_country,
                }
                this.all_states.push(stateToAdd)
                i_state++
              }
            }

            this.all_countries.push(countryToAdd)
            i_country++
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  initValues(project) {
    if (project.brand != undefined) {
      var product_description = ''
      var sales_target = ''

      if (
        this.project.brand.products &&
        this.project.brand.products.length > 0
      ) {
        product_description = this.project.brand.products[0].description
        sales_target = this.project.brand.products[0].target
        this.locations = this.project.brand.products[0].locations
      }

      this.myBrandForm = this.formBuilder.group({
        brandNameForm: this.formBuilder.group({
          brandName: [this.project.brand.name],
        }),
        baseline: [this.project.brand.baseline],
        description: [this.project.brand.description],
        industry: [this.project.brand.industry],
        values: [this.project.brand.values_description],
        products: [product_description],
        salesTarget: [sales_target],
        country: [''],
        area: [''],
        typeOfLink: ['choose'],
        linkForm: this.formBuilder.group({
          link: [''],
        }),
        typeOfAdvocateLink: ['choose'],
        advocateLinkForm: this.formBuilder.group({
          advocateLink: [''],
        }),
        resourceNameForm: this.formBuilder.group({
          resourceName: [''],
        }),
        resourceFile: [''],
        resourceIsPublic: [false],
      })

      if (project.brand.digital_accounts != undefined)
        this.links = project.brand.digital_accounts
      if (project.brand.advocates_accounts != undefined)
        this.advocates = project.brand.advocates_accounts
      if (project.brand.brand_logo_path != undefined)
        this.brand_logo_url = project.brand.brand_logo_path
      if (project.brand.marketing_resources != undefined)
        this.resources = project.brand.marketing_resources
    }
  }

  async onSubmit() {
    try {
      this.hasBrandNameError = false
      this.alertService.resetAlerts()

      var brand_name = undefined
      if (
        this.myBrandForm.controls.brandNameForm['controls'].brandName.value !=
        undefined
      )
        brand_name = this.myBrandForm.controls.brandNameForm[
          'controls'
        ].brandName.value.trim()

      var brand_baseline = undefined
      if (this.myBrandForm.controls.baseline.value != undefined)
        brand_baseline = this.myBrandForm.controls.baseline.value.trim()

      var brand_description = undefined
      if (this.myBrandForm.controls.description.value != undefined)
        brand_description = this.myBrandForm.controls.description.value.trim()

      var brand_industry = this.myBrandForm.controls.industry.value

      var brand_values = undefined
      if (this.myBrandForm.controls.values.value != undefined)
        brand_values = this.myBrandForm.controls.values.value.trim()

      var products = undefined
      if (this.myBrandForm.controls.products.value != undefined)
        products = this.myBrandForm.controls.products.value.trim()

      var targets = undefined
      if (this.myBrandForm.controls.salesTarget.value != undefined)
        targets = this.myBrandForm.controls.salesTarget.value.trim()

      if (brand_name != undefined) {
        if (brand_name.length > 2) {
          this.isValid = true

          var brand_products = []

          var toPush = {
            description: '',
            target: '',
            locations: [],
          }

          if (products.length > 0) {
            toPush['description'] = products
          }

          if (targets.length > 0) {
            toPush['target'] = targets
          }

          if (this.locations.length > 0) {
            toPush['locations'] = this.locations
          }

          brand_products.push(toPush)

          var brand = {
            brandName: brand_name,
            baseline: brand_baseline,
            description: brand_description,
            industry: brand_industry,
            values: brand_values,
            links: this.links,
            advocates: this.advocates,
            products: brand_products,
            marketing_resources: this.resources,
          }

          var res
          if (this.hasSelectedAFile == true) {
            res = await this.apiService.updateBrand(
              this.project_id,
              brand,
              this.selectedFile
            )
            if (res.status == 200) {
              this.alertService.pushMessageAlert(
                'PROJECT.MY_BRAND_EDIT.ALERT_OK'
              )

              this.project = res.body
              this.initValues(this.project)
            } else {
              this.alertService.pushErrorAlert(
                'PROJECT.MY_BRAND_EDIT.ALERT_NOK'
              )
            }
          } else {
            res = await this.apiService.updateBrand(
              this.project_id,
              brand,
              undefined
            )
            if (res.status == 200) {
              this.alertService.pushMessageAlert(
                'PROJECT.MY_BRAND_EDIT.ALERT_OK'
              )
            } else {
              this.alertService.pushErrorAlert(
                'PROJECT.MY_BRAND_EDIT.ALERT_NOK'
              )
            }
          }
        } else {
          this.hasBrandNameError = true
          this.isValid = false
          this.alertService.pushErrorAlert('PROJECT.MY_BRAND_EDIT.ALERT_NOK')
        }
      } else {
        this.isValid = false
        this.alertService.pushErrorAlert('PROJECT.MY_BRAND_EDIT.ALERT_NOK')
      }
    } catch (e) {
      this.alertService.pushErrorAlert('PROJECT.MY_BRAND_EDIT.ALERT_NOK')
    }
  }

  addWebLink() {
    var type = this.myBrandForm.controls.typeOfLink.value
    var link = this.myBrandForm.controls.linkForm['controls'].link.value.trim()

    if (type !== 'choose' && link != undefined && link.length > 0) {
      this.links.push({
        type: type,
        link: link,
      })

      this.myBrandForm.controls.typeOfLink.setValue('choose')
      this.myBrandForm.controls.linkForm['controls'].link.setValue('')
    }
  }

  removeWebLink(link) {
    var i = this.links.indexOf(link)
    this.links.splice(i, 1)
  }

  addAdvocate() {
    var type = this.myBrandForm.controls.typeOfAdvocateLink.value
    var link = this.myBrandForm.controls.advocateLinkForm[
      'controls'
    ].advocateLink.value.trim()

    if (type !== 'choose' && link != undefined && link.length > 0) {
      this.advocates.push({
        type: type,
        link: link,
      })

      this.myBrandForm.controls.typeOfAdvocateLink.setValue('choose')
      this.myBrandForm.controls.advocateLinkForm[
        'controls'
      ].advocateLink.setValue('')
    }
  }

  removeAdvocate(link) {
    var i = this.advocates.indexOf(link)
    this.advocates.splice(i, 1)
  }

  async registerEverything() {
    try {
    } catch (error) {
      this.loading = false
    }
  }

  onFileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.imageSrc = reader.result
      this.selectedFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
    this.hasSelectedAFile = true
  }

  stepBack() {
    this.location.back()
  }

  async addresource() {
    var name = this.myBrandForm.controls.resourceNameForm[
      'controls'
    ].resourceName.value.trim()
    var isPublic = this.myBrandForm.controls.resourceIsPublic.value

    if (
      name != undefined &&
      name.length > 0 &&
      this.selectedresourceFile != undefined
    ) {
      var res = await this.apiService.uploadDocument(
        this.project_id,
        this.selectedresourceFile
      )
      if (res.status == 200) {
        this.resources.push({
          name: name,
          link: res.body.document.location,
          isPublic: isPublic,
        })
      }
    }
  }

  downloadresource(resource_i) {}

  removeresource(resource_i) {
    var i = this.resources.indexOf(resource_i)
    this.resources.splice(i, 1)
  }

  onresourceChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.resourceFileSrc = reader.result
      this.selectedresourceFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
  }

  getCountries() {
    return this.all_countries
  }

  getStates() {
    if (this.all_states != undefined) {
      var statesList = this.all_states.filter(
        (state) => state.country == this.myBrandForm.controls.country.value
      )
      return statesList
    } else return []
  }

  resetState() {
    var candidate_country = this.myBrandForm.controls.country.value
    var candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country)
      this.location_country = this.countries[candidate_country].name
    if (candidate_area)
      this.location_area = this.all_states[candidate_area].name
    //this.newCampaignForm.controls.area.setValue(undefined);
  }

  changeCountry() {
    var candidate_country = this.myBrandForm.controls.country.value
    var candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country)
      this.location_country = this.countries[candidate_country].name
    if (candidate_area)
      this.location_area = this.all_states[candidate_area].name
  }

  addLocation() {
    var candidate_country = this.myBrandForm.controls.country.value
    var candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country) {
      if (candidate_area) {
      } else {
        candidate_area = 0
      }
      var toPush = {
        country: this.countries[candidate_country].name,
        area: this.states[candidate_area].name,
      }

      if (this.indexOfLocation(toPush) === -1) {
        this.locations.push(toPush)
      }
    }
  }

  indexOfLocation(location) {
    var index = -1
    for (var i = 0; i < this.locations.length; i++) {
      if (
        this.locations[i].country === location.country &&
        this.locations[i].area === location.area
      ) {
        index = i
        break
      }
    }
    return index
  }

  removeLocation(location) {
    var i = this.locations.indexOf(location)
    this.locations.splice(i, 1)
  }
}
