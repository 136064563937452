<div class="display-block section-container">
  <div class="section-container-inner margin-h-auto">
    <div class="row margin-h-auto">
      <div class="col-12 text-lato text-bold section-container-title">
        {{ title | translate }}
      </div>

      <!--div class="col-3 text-right">
        <edit-button></edit-button>
      </div-->
      <div
        class="col-12 text-lato section-container-description"
        *ngIf="description"
      >
        {{ description | translate }}
      </div>
      <div
        class="col-12 text-lato section-container-description no-margin-bottom"
        *ngIf="description == undefined"
      ></div>
    </div>
  </div>
</div>
