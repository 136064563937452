<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Social Accounts</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addNetwork()"></add-button>
        <edit-button
          class="edit-language"
          (click)="updateNetwork()"
          *ngIf="getNumberOfNetworks() > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center padding-network-section">
      <div class="no-language" *ngIf="getNumberOfNetworks() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>
          Share the social account you mainly use speaking with your audience
        </div>
        <div></div>
        <tiny-cta
          (click)="addNetwork()"
          value="ADD YOUR FIRST SOCIAL ACCOUNT"
        ></tiny-cta>
      </div>
      <div *ngIf="getNumberOfNetworks() != 0">
        <div
          class="text-lato text-small text-darkgrey networks-section"
          *ngFor="let network of getNetworks()"
        >
          <div
            class="network-img text-darkgrey text-medium text-bold network-type-container"
          >
            <!--img [src]="getNetworkImagePath(network.type)" /-->
            {{ network.type }}
          </div>
          <div class="text-darkgrey text-xsmall">
            {{ network.link }}
          </div>
        </div>
        <!--span
          class="text-lato text-small text-darkgrey networks-section"
          *ngFor="let network of getNetworks()"
        >
          <a [href]="network.link">
            <span class="network-img">
              <img [src]="getNetworkImagePath(network.type)" />
            </span>
          </a>
        </span-->
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddNetwork"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddNetwork"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddNetworkTitle">
                    {{ 'Add a social account' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="networksForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Social media</div>
                  <div class="input-value container-field">
                    <select
                      name="type"
                      formControlName="type"
                      id="language-proficiency-select"
                      class="custom-select content-width"
                    >
                      <option value="">-- Please choose an option --</option>
                      <option
                        [value]="network.id"
                        *ngFor="let network of suggestedNetworks"
                      >
                        {{ network.name | translate }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">URL or social account login</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="URL or social account login"
                      name="link"
                      relatedControlName="link"
                      formGroupName="linkForm"
                      [parentGroup]="networksForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your social account have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your social account was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateNetwork"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateNetwork"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato"
                    id="modalUpdateNetworkTitle"
                  >
                    {{ 'Delete a social account' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner network-edit-container">
            <form [formGroup]="networksForm">
              <div class="row center padding-network-section">
                <span
                  class="text-lato text-small text-darkgrey network-section"
                  *ngFor="let network of getNetworks()"
                >
                  <span class="network-field"
                    >{{ network.type }} -
                    {{ network.link }}
                    <span class="icon-delete" (click)="deleteNetwork(network)"
                      >x</span
                    ></span
                  >
                </span>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your social account have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your social account was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
