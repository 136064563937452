import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'dashboard-profile.component.html',
  selector: 'dashboard-profile',
  styleUrls: ['./dashboard-profile.component.scss'],
})
export class DashboardProfileComponent implements OnInit {
  @Input('state') state: any
  constructor(private router: Router) {}

  async ngOnInit() {}

  ngOnDestroy() {}

  getProfilePercentageFilled() {
    return this.state.profile.filled
  }

  goToProfile() {
    this.router.navigate(['/me'])
  }
}
