<div class="container-fluid" *ngIf="hasSent == false">
    <div class="row">
        <div class="col-12 col-lg-7 d-none d-lg-flex hv-center bg-default full-height">
            <img class="brand-logo" src="./assets/images/fns_logo_horizontal.png">
        </div>
        <div class="col-12 col-lg-5 d-flex flex-column v-align full-height" id="login-container">
            <div class="text-center center margin-h">
                <div>
                    <img class="brand-logo d-lg-none" src="./assets/images/fns_logo.png">
                    <h1 class="text-lato text-bold text-big text-darkgrey line-height-115">{{ 'PASSWORD.CHANGE.STEP_0_TITLE' | translate}}</h1>
                    <p class="text-lato text-small text-lightgrey margin-v-39">{{ 'PASSWORD.CHANGE.STEP_0_INSTRUCTIONS' | translate}}</p>
                    <form class="margin-v-39 max-w-400" [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-12">
                                <input type="password" placeholder="{{ 'PASSWORD.CHANGE.STEP_0_NEW_PASSWORD_PLACEHOLDER' | translate}}" class="input-text text-lato text-medium text-darkgrey"
                                    name="newPassword" formControlName="newPassword" autocomplete="off">
                            </div>
                            <div class="col-12">
                                <input type="password" placeholder="{{ 'PASSWORD.CHANGE.STEP_0_CONFIRM_PASSWORD_PLACEHOLDER' | translate}}" class="input-text text-lato text-medium text-darkgrey"
                                    name="confirmPassword" formControlName="confirmPassword" autocomplete="off">
                            </div>
                            <div class="col-12">
                                <p class="text-lato text-small text-lightgrey" *ngIf="matched == false">{{ 'PASSWORD.CHANGE.STEP_0_ERROR_PASSWORD_MISMATCH' | translate}}</p>
                            </div>
                            <div class="col-12">
                                <p class="text-lato text-small text-lightgrey" *ngIf="tooSmall == true">{{ 'PASSWORD.CHANGE.STEP_0_ERROR_PASSWORD_TOO_SHORT' | translate}}</p>
                            </div>
                            <div class="col-12" *ngIf="hasError">
                                <div class="alert alert-secondary text-lato text-bold margin-v-39">
                                    {{ 'PASSWORD.CHANGE.STEP_0_ERROR_UNKNOWN' | translate}}
                                </div>
                            </div>
                            <div class="col-12">
                                <input class="btn btn-primary btn-big text-lato text-bold" type="submit" value="{{ 'PASSWORD.CHANGE.STEP_0_SUBMIT_BUTTON' | translate}}">
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
            <copyright></copyright>
        </div>
    </div>
</div>

<div class="container-fluid" *ngIf="hasSent == true">
        <div class="row">
                <div class="col-12 col-lg-7 d-none d-lg-flex hv-center bg-default full-height">
                    <img class="brand-logo" src="./assets/images/fns_logo_horizontal.png">
                </div>
                <div class="col-12 col-lg-5 d-flex flex-column v-align full-height" id="login-container">
                    <div class="text-center center margin-h">
                        <div>
                            <img class="brand-logo d-lg-none" src="./assets/images/fns_logo.png">
                            <h1 class="text-lato text-bold text-big text-darkgrey line-height-115">{{ 'PASSWORD.CHANGE.STEP_1_TITLE' | translate}}</h1>
                            <p class="text-lato text-small text-lightgrey margin-v-39">{{ 'PASSWORD.CHANGE.STEP_1_INSTRUCTIONS' | translate}}</p>
                            <div class="text-center">
                                    <a [routerLink]="['/login']"><span class="input btn btn-primary btn-big text-lato text-bold">{{ 'PASSWORD.CHANGE.STEP_1_SUBMIT_BUTTON' | translate}}</span></a>
                                </div>
                        </div>
                    </div>
                    <copyright></copyright>
                </div>
            </div>
</div>

<loader *ngIf="loading == true"></loader>