import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class ModalService {
  private subject = new Subject()

  constructor() {}

  // subscribe to modals
  getModals(): Observable<any> {
    return this.subject.asObservable()
  }

  // main modal method
  toggleModal(modal) {
    this.subject.next(modal)
  }

  sendEvent(event) {
    this.subject.next({ event: event })
  }
}
