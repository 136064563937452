<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-wheel.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
            PARAMETRES
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png"
                    style="width: 16px" (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-5" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato text-bold" style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                        Notifications
                    </div>
                    <div class="col-12 text-lato" style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                        
                    </div>
                </div>
            </div>
            <div class="col-7" style="margin-left: 21px">
                <div class="row" style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                    <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                        Activer les notifications mail
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 20px; text-align: right">
                        <input type="checkbox" class="switch_1">
                    </div>
                </div>
                <div class="row" style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                    <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                        Activer les notifications SMS
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 20px; text-align: right">
                        <input type="checkbox" class="switch_1">
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
                <div class="col-5" style="margin-right: 21px">
                    <div class="row">
                        <div class="col-12 text-lato text-bold" style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                            Activer les notifications mail
                        </div>
                        <div class="col-12 text-lato" style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                            
                        </div>
                    </div>
                </div>
                <div class="col-7" style="margin-left: 21px">
                    <div class="row" style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            Activer les notifications SMS
                        </div>
                        <div class="col-6 text-lato" style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 20px; text-align: right">
                            <input type="checkbox" class="switch_1">
                        </div>
                    </div>
                    <div class="row" style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            Nouveau mot de passe
                        </div>
                        <div class="col-6 text-lato" style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 20px; text-align: right">
                            <input type="checkbox" class="switch_1">
                        </div>
                    </div>
                    <div class="row" style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            Contact d'urgence
                        </div>
                        <div class="col-6 text-lato" style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="email" placeholder="you@awesome.co" class="input-text" style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="emergencyContact">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div style="width: 100%; padding-left: 15px; padding-right: 15px; background-color: #e3e3e3; margin-top: -20px">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px; padding-top: 25px; padding-bottom: 25px; text-align: right; padding-right: 16px">
            <input class="btn btn-primary btn-big text-lato text-bold" type="submit" value="SAUVEGARDER LES CHANGEMENTS">
        </div>
    </div>
</div>