import { Component, OnInit, Input } from '@angular/core'

@Component({
  templateUrl: 'alerts.component.html',
  selector: 'alerts',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  @Input('alerts') alerts: any

  constructor() {}

  async ngOnInit() {}

  ngOnDestroy() {}
}
