// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { USER_ROLE } from '../app/_helpers/referential/user.role'

export const environment = {
  name: 'ADVERTISER_V2',
  production: false,
  role: USER_ROLE.ADVERTISER,
  apiUrl: 'https://api-dev.foxynerds.com',
  isCryptrActivated: false,
  cryptrConfig: {
    audience: 'http://localhost:4200',
    cryptr_base_url: 'https://auth.cryptr.eu/', // SERA OVERRIDE PAR LA REGION A TERME
    tenant_domain: 'foxy-nerds',
    client_id: '802345b6-5544-44bf-a7ec-d73ff17c04b7',
    region: 'eu',
    default_locale: 'en',
    default_redirect_uri: 'http://localhost:4200',
    httpInterceptor: {
      apiRequestsToSecure: [
        // any string/regex that requires token decoration
        // Your resource server url MUST BE THERE
        '*',
      ],
    },
    telemetry: false,
  },
}

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
