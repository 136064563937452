import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-profile-content-description.component.html',
  selector: 'publisher-profile-content-description',
  styleUrls: ['./publisher-profile-content-description.component.scss'],
})
export class PublisherProfileContentDescriptionComponent implements OnInit {
  @Input('publisher') publisher: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  isEditing: boolean
  myProfileContentForm: FormGroup
  isValid: boolean

  hasLanguagesError: boolean
  hasExpertisesError: boolean
  hasNetworksError: boolean
  hasHashtagsError: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.myProfileContentForm = this.formBuilder.group({
      languages: this.formBuilder.group({
        languages: { value: '', disabled: !this.isEditing },
      }),
      expertisesForm: this.formBuilder.group({
        expertises: { value: '', disabled: !this.isEditing },
      }),
      networksForm: this.formBuilder.group({
        networks: { value: '', disabled: !this.isEditing },
      }),
      hashtagsForm: this.formBuilder.group({
        hashtags: { value: '', disabled: !this.isEditing },
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.isEditing = false

    this.initValues()
  }

  initValues() {
    if (this.hasProfileContentInformation() == true) {
      this.myProfileContentForm = this.formBuilder.group({
        languagesForm: this.formBuilder.group({
          languages: {
            value: this.getProfileContentInformation().languages,
            disabled: !this.isEditing,
          },
        }),
        expertisesForm: this.formBuilder.group({
          expertises: {
            value: this.getProfileContentInformation().expertises,
            disabled: !this.isEditing,
          },
        }),
        networksForm: this.formBuilder.group({
          networks: {
            value: this.getProfileContentInformation().networks,
            disabled: !this.isEditing,
          },
        }),
        hashtagsForm: this.formBuilder.group({
          hashtags: {
            value: this.getProfileContentInformation().hashtags,
            disabled: !this.isEditing,
          },
        }),
      })
    } else {
      //this.makeEditable()
    }
  }

  ngOnDestroy() {}

  hasProfileContentInformation() {
    try {
      return this?.publisher?.publisher?.profileBioInformation != undefined
    } catch (e) {
      return false
    }
  }

  getProfileContentInformation() {
    if (this.hasProfileContentInformation() == true)
      return this.publisher.publisher.profileContentInformation
    else return undefined
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    this.myProfileContentForm.enable()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.myProfileContentForm.disable()
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.hasLanguagesError = false
      this.hasExpertisesError = false
      this.hasNetworksError = false
      this.hasHashtagsError = false

      this.alertService.resetAlerts()

      let languages_line = this.readTextValue(
        this.myProfileContentForm.controls.languagesForm['controls'].languages
          .value
      )

      let expertises_line = this.readTextValue(
        this.myProfileContentForm.controls.expertisesForm['controls'].expertises
          .value
      )

      let networks_line = this.readTextValue(
        this.myProfileContentForm.controls.networksForm['controls'].networks
          .value
      )

      let hashtags_line = this.readTextValue(
        this.myProfileContentForm.controls.hashtagsForm['controls'].hashtags
          .value
      )

      if (this.isValid == true) {
        this.isValid = true

        let profile_content_infos = {
          languages: languages_line,
          expertises: expertises_line,
          networks: networks_line,
          hashtags: hashtags_line,
        }

        /*let res = await this.apiService.updateProfileInformations(
          this.publisher.publisher._id,
          profile_content_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('')
          this.makeNotEditable()
          this.publisher.publisher = res.body.publisher
        } else {
          this.alertService.pushErrorAlert('')
        }*/
      } else {
        this.alertService.pushErrorAlert('')
      }
    } catch (e) {
      console.log(e)
      //this.alertService.pushAlert('')
    }
  }

  manageModalEvent(newValue) {
    this.showModalEvent.emit(newValue)
  }
}
