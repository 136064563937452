import { Component, OnInit, Input, NgZone } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'

import { ModalService } from '../../../_services/modal.service'
import { APIService } from '../../../_services/api.service'
import { CacheService } from '../../../_services/cache.service'

declare var FB: any

@Component({
  templateUrl: 'modal-accounts.component.html',
  selector: 'modal-accounts',
  styleUrls: ['./modal-accounts.component.scss'],
})
export class ModalAccountsComponent implements OnInit {
  @Input('projectId') projectId: string

  project: any
  accounts: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private _ngZone: NgZone,
    private cacheService: CacheService
  ) {
    this.accounts = {
      twitter: {
        infos: {},
        params: {},
        selected: false,
      },
      instagram: {
        infos: {},
        params: {},
        selected: false,
      },
    }
  }

  async ngOnInit() {
    this.initializeFbLogin()

    var projects = await this.cacheService.getProjects()
    for (var i = 0; i < projects.length; i++) {
      if (projects[i]._id == this.projectId) {
        this.project = projects[i]
        break
      }
    }

    if (
      this.project &&
      this.project.socialMedia &&
      this.project.socialMedia.instagram &&
      this.project.socialMedia.instagram.length > 0
    ) {
      this.accounts.instagram.selected = true

      this.accounts.instagram.infos = {
        token: null,
        account: '@' + this.project.socialMedia.instagram[0].login,
      }
      this.accounts.instagram.params = {
        token: {
          access_token: null,
          expires_in: null,
          token_type: null,
        },
        account: {
          social_id: this.project.socialMedia.instagram[0].social_id,
          display_name: this.project.socialMedia.instagram[0].infos
            .display_name,
          username: this.project.socialMedia.instagram[0].login,
          profile_picture_url: this.project.socialMedia.instagram[0].infos
            .profile_picture_url,
        },
      }
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('social-accounts-close')
  }

  async onSubmit() {}

  initializeFbLogin() {
    ;(window as any).fbAsyncInit = function () {
      FB.init({
        appId: '830528690628233',
        cookie: true,
        xfbml: true,
        version: 'v3.3',
      })
      FB.AppEvents.logPageView()
    }

    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  async connectInstagram() {
    if (this.accounts.instagram.selected == false) {
      var _this = this
      FB.login(
        async (response) => {
          if (response.authResponse) {
            //login success
            //login success code here
            //redirect to home page
            var token = response.authResponse.accessToken
            var result = await this.apiService.registerInstagramLonglifeToken(
              token
            )
            if (result.status == 200) {
              this._ngZone.run(async () => {
                var account_index = 0
                this.accounts.instagram.infos = {
                  token: result.body.token.access_token,
                  account:
                    '@' + result.body.accounts.accounts[account_index].username,
                }
                this.accounts.instagram.params = {
                  token: {
                    access_token: result.body.token.access_token,
                    expires_in: result.body.token.expires_in,
                    token_type: result.body.token.token_type,
                  },
                  account: {
                    social_id: result.body.accounts.accounts[account_index].id,
                    display_name:
                      result.body.accounts.accounts[account_index].name,
                    username:
                      result.body.accounts.accounts[account_index].username,
                    profile_picture_url:
                      result.body.accounts.accounts[account_index]
                        .profile_picture_url,
                  },
                }
                var r = await this.apiService.registerInstagramAccount(
                  this.projectId,
                  this.accounts
                )
                if (r.status == 200) this.accounts.instagram.selected = true
              })
            }
          } else {
            //console.log('User login failed');
          }
        },
        {
          scope:
            'public_profile,manage_pages,instagram_basic,instagram_manage_insights',
          return_scopes: true,
          enable_profile_selector: false,
        }
      )
    } else {
      var res = await this.apiService.unregisterInstagramAccount(
        this.projectId,
        this.accounts.instagram.params.account.social_id
      )

      if (res.status == 200) {
        this.accounts.instagram.selected = false
        this.accounts.instagram.infos = {}
      }
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
