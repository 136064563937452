<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div class="form-title">
      <publisher-section-intro
        title="{{ 'Brand Informations' | translate }}"
        description="{{ 'Informations about ' | translate }}"
      ></publisher-section-intro>
    </div>
    <div
      class="display-block col-direction section-container justify-content-center"
    >
      <div class="col-10 one-edge-shadow form-background-color">
        <div class="row">
          <div
            class="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-lato form-container-section-left"
          >
            <img
              class="brand-logo-thumbnail"
              [src]="campaign._owner.brand.brand_logo_path"
            />
          </div>
          <div class="col-9 padding-top-15">
            <div>
              <span class="input-title text-pink text-large">
                {{ campaign._owner.brand.name }}
              </span>
              <span
                *ngFor="let account of campaign._owner.brand.digital_accounts"
              >
                <img
                  [src]="getDigitalAccountImagePath(account.type)"
                  class="account-image"
                />
                <link href="" />
              </span>
            </div>
            <div
              *ngFor="let product of campaign._owner.brand.products"
              class="text-xsmall text-darkgrey padding-top-15 padding-bottom-15"
            >
              {{ product.description }}
            </div>
            <div class="text-small text-darkgrey padding-bottom-15">
              Industry : {{ campaign._owner.brand.industry }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="display-block col-direction section-container justify-content-center"
    >
      <div class="col-10 one-edge-shadow form-background-color">
        <div class="row justify-content-center padding-bottom-15">
          <div class="col-10 padding-top-15">
            <div class="separator-field padding-top-15 input-title">
              Description
            </div>
            <div class="left-text padding-top-15 text-small">
              {{ campaign._owner.brand.baseline }}
            </div>
            <div class="left-text padding-top-15 padding-bottom-15 text-small">
              {{ campaign._owner.brand.description }}
            </div>
            <div class="separator-field padding-top-15 input-title">
              Baseline
            </div>
            <div class="left-text padding-top-15 padding-bottom-15 text-small">
              {{ campaign._owner.brand.baseline }}
            </div>
            <div class="separator-field padding-top-15 input-title">Values</div>
            <div class="left-text padding-top-15 padding-bottom-15 text-small">
              {{ campaign._owner.brand.values_description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
