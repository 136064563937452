import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../../_services/index'
import { DataService } from './../../../../_services/data.service'
import { ModalService } from './../../../../_services/modal.service'

import { DateHelper } from './../../../../_helpers/date.helpers'

import { PreferencesManager } from './../../../../_services/preferences.manager.service'

import { FakerHelper } from './../../../../_helpers/faker.helper'

@Component({
  templateUrl: 'campaign_scalers.component.html',
  styleUrls: ['./campaign_scalers.component.scss'],
})
export class CampaignScalersComponent implements OnInit, OnDestroy {
  faker: FakerHelper

  isLoading: boolean
  id: string
  projectId: string
  private sub: any

  campaign: any
  action: any
  proposalId: any

  metrics: any

  contactedScalers: any
  confirmedScalers: any
  potentialScalers: any

  refreshInterval: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private preferencesManager: PreferencesManager,
    private modalService: ModalService
  ) {
    this.isLoading = true
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['campaignId']
      this.projectId = params['projectId']

      //this.apiService.initCampaignExtras(this.id)

      this.campaign = this.dataService.getCampaign(this.id)

      this.isLoading = false

      this.metrics = FakerHelper.getCampaignData(this.id, this.campaign.target)[
        'scalers'
      ]['metrics']

      this.confirmedScalers = FakerHelper.getCampaignData(
        this.id,
        this.campaign.target
      )['scalers']['profiles']['confirmed']
      this.potentialScalers = FakerHelper.getCampaignData(
        this.id,
        this.campaign.target
      )['scalers']['profiles']['potential']

      //this.confirmedScalers.push({'profile' : {'_id': 1, 'display_name': 'Leonie Sampson', 'pic_url': 'https://randomuser.me/api/portraits/women/68.jpg', 'networks': [{'channel': 'web', 'url': 'https://www.foxynerds.studio'}, {'channel': 'linkedin', 'url': 'https://www.foxynerds.studio'}, {'channel': 'twitter', 'url': 'https://www.foxynerds.studio'}]}, 'analysis': {'matching_score' : 'A+'}, 'proposal': { 'timestamp': 1597587449000, 'budget': { 'value': '300', 'currency' : 'EUR' }, 'publications': [{'type': 'post', 'channel': 'linkedin', 'quantity': 3}, {'type': 'post', 'channel': 'website', 'quantity': 1}] }})
      //this.confirmedScalers.push({'profile' : {'_id': 2, 'display_name': 'Lia Riggs', 'pic_url': 'https://randomuser.me/api/portraits/women/57.jpg', 'networks': [{'channel': 'web', 'url': 'https://www.foxynerds.studio'}, {'channel': 'linkedin', 'url': 'https://www.foxynerds.studio'}, {'channel': 'twitter', 'url': 'https://www.foxynerds.studio'}]}, 'analysis': {'matching_score' : 'A'}, 'proposal': { 'timestamp': 1597587449000, 'budget': { 'value': '250', 'currency' : 'EUR' }, 'publications': [{'type': 'post', 'channel': 'linkedin', 'quantity': 3}, {'type': 'post', 'channel': 'website', 'quantity': 1}] }})
      //this.potentialScalers.push({'profile' : {'_id': 3, 'display_name': 'Jasmine Patterson', 'pic_url': 'https://randomuser.me/api/portraits/women/66.jpg', 'networks': [{'channel': 'web', 'url': 'https://www.foxynerds.studio'}, {'channel': 'linkedin', 'url': 'https://www.foxynerds.studio'}, {'channel': 'twitter', 'url': 'https://www.foxynerds.studio'}]}, 'analysis': {'matching_score' : 'A+'}, 'proposal': { 'timestamp': 1597587449000, 'budget': { 'value': '400', 'currency' : 'EUR' }, 'publications': [{'type': 'post', 'channel': 'linkedin', 'quantity': 3}, {'type': 'post', 'channel': 'website', 'quantity': 1}] }})
      //this.potentialScalers.push({'profile' : {'_id': 4, 'display_name': 'Adrian Woodward', 'pic_url': 'https://randomuser.me/api/portraits/men/31.jpg', 'networks': [{'channel': 'web', 'url': 'https://www.foxynerds.studio'}, {'channel': 'linkedin', 'url': 'https://www.foxynerds.studio'}, {'channel': 'twitter', 'url': 'https://www.foxynerds.studio'}]}, 'analysis': {'matching_score' : 'A+'}, 'proposal': { 'timestamp': 1597587449000, 'budget': { 'value': '250', 'currency' : 'EUR' }, 'publications': [{'type': 'post', 'channel': 'linkedin', 'quantity': 3}, {'type': 'post', 'channel': 'website', 'quantity': 1}] }})
      //this.potentialScalers.push({'profile' : {'_id': 5, 'display_name': 'William Marsh', 'pic_url': 'https://randomuser.me/api/portraits/men/20.jpg', 'networks': [{'channel': 'web', 'url': 'https://www.foxynerds.studio'}, {'channel': 'linkedin', 'url': 'https://www.foxynerds.studio'}, {'channel': 'twitter', 'url': 'https://www.foxynerds.studio'}]}, 'analysis': {'matching_score' : 'A-'}, 'proposal': { 'timestamp': 1597587449000, 'budget': { 'value': '150', 'currency' : 'EUR' }, 'publications': [{'type': 'post', 'channel': 'linkedin', 'quantity': 3}, {'type': 'post', 'channel': 'website', 'quantity': 1}] }})
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  getDateString(timestamp) {
    return DateHelper.convertDoubleToReadableDate(timestamp)
  }

  getImpressions(publication) {
    try {
      return publication.insights.data[1].values[0].value
    } catch (e) {
      return '-'
    }
  }

  getNetworks(scaler) {
    return FakerHelper.getScaler(scaler.profile).networks
  }

  getNetworkPicture(network) {
    if (network === 'web') return './assets/images/socials-web.png'
    else if (network === 'linkedin')
      return './assets/images/socials-linkedin.png'
    else if (network === 'twitter') return './assets/images/socials-twitter.png'
    else return ''
  }

  getAvailableBudget() {
    let totalBudget = this.campaign.total_budget
    for (var i = 0; i < this.confirmedScalers.length; i++) {
      totalBudget -= this.confirmedScalers[i].proposal.budget.value
    }

    return totalBudget
  }

  getCampaignCurrency() {
    return 'EUR'
  }

  confirmScaler(scaler) {
    var newBudget = this.getAvailableBudget() - scaler.proposal.budget.value

    if (newBudget >= 0) {
      /*var i = this.potentialScalers.indexOf(scaler);
            this.potentialScalers.splice(i, 1);
    
            this.confirmedScalers.push(scaler)*/
      this.proposalId = scaler.profile
      this.action = 'accept'
      this.modalService.toggleModal('confirm')
    } else {
      this.modalService.toggleModal('info')
    }
  }

  declineScaler(scaler) {
    /*var i = this.potentialScalers.indexOf(scaler);
        this.potentialScalers.splice(i, 1);*/

    this.proposalId = scaler.profile
    this.action = 'decline'
    this.modalService.toggleModal('confirm')
  }

  cancelScaler(scaler) {
    /*var i = this.confirmedScalers.indexOf(scaler);
        this.confirmedScalers.splice(i, 1);*/

    this.proposalId = scaler.profile
    this.action = 'cancel'
    this.modalService.toggleModal('confirm')
  }

  toScalerProfile(scaler) {
    this.router.navigate([
      'projects/' +
        this.projectId +
        '/campaigns/' +
        this.id +
        '/scalers/' +
        scaler.profile,
    ])
  }

  getScaler(scaler) {
    return FakerHelper.getScaler(scaler.profile)
  }
}
