import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from '../../../../_services/api.service'
import { DataService } from '../../../../_services/data.service'
import { IndustryHelper } from './../../../../_helpers/referential/industry.helper'

import { Chart } from 'chart.js'

import { FakerHelper } from './../../../../_helpers/faker.helper'
import { Utils } from './../../../../_helpers/utils.helper'

@Component({
  templateUrl: 'project_brand_authority.component.html',
  styleUrls: ['./project_brand_authority.component.scss'],
})
export class ProjectBrandAuthorityComponent
  implements OnInit, OnDestroy, AfterViewInit {
  utils = Utils
  faker = FakerHelper
  data: any

  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  project: any
  campaigns: any

  hashtags: any
  keywords: any
  advocates: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService
  ) {
    this.isLoading = true
    this.showSomething = false

    this.hashtags = []

    this.data = FakerHelper.getBrandAuthorityData('')
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['projectId']

      this.data = FakerHelper.getBrandAuthorityData(this.id)
      this.hashtags = this.data['hashtags']
      this.keywords = this.data['keywords']
      this.advocates = this.data['advocates']

      this.project = this.dataService.getProject(this.id)

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()

    var _this = this
    var elts = document.getElementsByClassName('chartjs-doughnut')
    for (var i = 0; i < elts.length; i++) {
      _this.generateTrafficChart(elts[i], i)
    }

    var elts2 = document.getElementsByClassName('chartjs-timeline')
    for (var i = 0; i < elts2.length; i++) {
      _this.generateTrafficTimelineChart(elts2[i], i)
    }
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  editBrand() {
    this.router.navigate(['/projects/' + this.project._id + '/brand/edit'])
  }

  getIndustry(industry) {
    return IndustryHelper.getIndustryText(industry)
  }

  async downloadBrandPresentation() {
    let res = await this.apiService.getBrandReport(this.project._id)
    const blob = new Blob([res], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  generateTrafficChart(elt, i) {
    new Chart(elt, {
      type: 'doughnut',
      data: {
        labels: ['Organic', 'Ads', 'Referral'],
        datasets: [
          {
            data: [
              FakerHelper.getLastWeekOrganicTrafficPercentage(this.id),
              FakerHelper.getLastWeekAdsTrafficPercentage(this.id),
              FakerHelper.getLastWeekReferralsTrafficPercentage(this.id),
            ],
            backgroundColor: [
              'rgb(255,0,107)',
              'rgba(255, 255, 255, 1)',
              'rgb(255,174,0)',
            ],
            borderColor: [
              'rgb(255,0,107)',
              'rgba(255, 255, 255, 1)',
              'rgb(255,174,0)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        //animation: false,
        cutoutPercentage: 50,
        scales: {},
        legend: {
          display: false,
          labels: {
            fontColor: 'rgb(255, 255, 255)',
          },
          position: 'right',
        },
      },
    })
  }

  generateTrafficTimelineChart(elt, i) {
    const colors = {
      gold: {
        fill: 'rgba(255,174,0,0.7)',
        stroke: 'rgb(255,174,0)',
      },
      white: {
        fill: 'rgba(255, 255, 255, 0.7)',
        stroke: 'rgba(255, 255, 255, 1)',
      },
      pink: {
        fill: 'rgb(255,0,107, 0.7)',
        stroke: 'rgba(255,0,107, 1)',
      },
    }

    const organic = FakerHelper.getBrandAuthorityData(this.id).traffic_history
      .organic
    const ads = FakerHelper.getBrandAuthorityData(this.id).traffic_history.ads
    const referrals = FakerHelper.getBrandAuthorityData(this.id).traffic_history
      .referrals
    const xData = FakerHelper.getBrandAuthorityData(this.id).traffic_history
      .timestamps

    new Chart(elt, {
      type: 'line',
      data: {
        labels: xData,
        datasets: [
          {
            label: 'Organic',
            fill: true,
            backgroundColor: colors.pink.fill,
            pointBackgroundColor: colors.pink.stroke,
            borderColor: colors.pink.stroke,
            //pointHighlightStroke: colors.pink.stroke,
            borderCapStyle: 'butt',
            data: organic,
          },
          {
            label: 'Ads',
            fill: true,
            backgroundColor: colors.white.fill,
            pointBackgroundColor: colors.white.stroke,
            borderColor: colors.white.stroke,
            //pointHighlightStroke: colors.white.stroke,
            borderCapStyle: 'butt',
            data: ads,
          },
          {
            label: 'Referrals',
            fill: true,
            backgroundColor: colors.gold.fill,
            pointBackgroundColor: colors.gold.stroke,
            borderColor: colors.gold.stroke,
            //pointHighlightStroke: colors.gold.stroke,
            borderCapStyle: 'butt',
            data: referrals,
          },
        ],
      },
      options: {
        responsive: false,
        //animation: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                // You can change the color, the dash effect, the main axe color, etc.
                borderDash: [8, 4],
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'rgb(255, 255, 255)',
          },
          position: 'bottom',
        },
      },
    })
  }
}
