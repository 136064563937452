import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: 'publisher-section-intro.component.html',
  selector: 'publisher-section-intro',
  styleUrls: ['./publisher-section-intro.component.scss'],
})
export class PublisherSectionIntroComponent implements OnInit {
  @Input('title') title: string
  @Input('description') description: string
  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
