import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { PublishersOpportunitiesAPIService } from 'src/app/_services'

@Component({
  templateUrl: 'campaign-search.component.html',
  selector: 'campaign-search',
  styleUrls: ['./campaign-search.component.scss'],
})
export class CampaignSearchComponent implements OnInit {
  opportunities: any
  publisher: any

  searchForm: FormGroup

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private publisherOpportunitiesAPIService: PublishersOpportunitiesAPIService
  ) {
    this.publisher = route.snapshot.data.user.body.publisher
    this.opportunities = route.snapshot.data.opportunities

    this.searchForm = this.formBuilder.group({
      q: { value: '', disabled: false },
      selectActiveCampaigns: { value: true, disabled: false },
      selectUpcomingCampaigns: { value: true, disabled: false },
    })
  }

  async ngOnInit() {}

  ngOnDestroy() {}

  async searchCampaigns() {
    try {
      let q = this.searchForm.get('q').value
      let searchActiveCampaigns = this.searchForm.get('selectActiveCampaigns')
        .value
      let searchUpcomingCampaigns = this.searchForm.get(
        'selectUpcomingCampaigns'
      ).value

      let json = {
        active: searchActiveCampaigns ? 1 : 0,
        upcoming: searchUpcomingCampaigns ? 1 : 0,
      }

      if (q != undefined && q.trim() !== '') json['q'] = q.trim()

      let query = await this.publisherOpportunitiesAPIService.getOpportunities(
        this.publisher._id,
        json
      )

      if (query.status == 200) {
        this.opportunities = query.body.opportunities
      }
    } catch (e) {
      console.log(e)
    }
  }
}
