<div
  class="container-fluid full-height bg-default"
  [style.overflow]="getOverflow()"
  [style.height]="getHeight()"
>
  <div class="row">
    <div class="col-12 d-flex d-lg-none no-padding-right">
      <mobile-nav-bar
        [full]="true"
        title="{{ 'PROJECT.MY_BILLING.TITLE' | translate }}"
        class="width-100"
      >
      </mobile-nav-bar>
    </div>
  </div>
  <div class="row v-margin h-margin">
    <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
      <left-menu></left-menu>
    </div>
    <div class="col-12 col-lg-9 d-block" id="content">
      <div>
        <nav-bar
          class="display-none d-none d-lg-block"
          [full]="true"
          title="{{ 'PROJECT.MY_BILLING.TITLE' | translate }}"
        ></nav-bar>
        <div class="right-row no-padding margin-top-20"></div>
        <div
          class="row no-margin margin-top-25"
          *ngIf="isLoading == false && project.billingInformation == undefined"
        >
          <div class="col-12 no-margin">
            <div class="text-bold text-medium container-empty">
              {{ 'PROJECT.MY_BILLING.NOTHING_TO_SHOW' | translate }}
            </div>
            <div class="margin-top-20-center">
              <span
                class="btn text-lato text-bold card-title no-margin btn-edit"
                (click)="editBilling()"
                >{{
                  'PROJECT.MY_BILLING.FILL_THE_INFORMATION' | translate
                }}</span
              >
            </div>
          </div>
        </div>
        <!--div
          class="row no-margin margin-top-25"
          *ngIf="isLoading == false && project.billingInformation != undefined"
        >
          <div class="col-12 no-margin text-right content-btn">
            <span
              class="btn text-lato text-bold card-title no-margin btn-edit"
              (click)="editBilling()"
              >{{ 'PROJECT.MY_BILLING.EDIT' | translate }}</span
            >
          </div>

          <div
            class="col-12 light-box margin-top-25 content-section"
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-12 no-margin text-left margin-title-section">
                <div class="position-title-section">
                  <div
                    class="text-lato text-bold text-medium card-title no-margin size-title-section"
                  >
                    {{ 'PROJECT.MY_BILLING.CORPORATE_NAME' | translate }}
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="row margin-text-section">
                  <div class="col-12 text-lato position-text-section">
                    <div class="size-text-section">
                      {{ project.billingInformation.corporateName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25 content-section"
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-12 no-margin text-left margin-title-section">
                <div class="position-title-section">
                  <div
                    class="text-lato text-white text-bold text-medium card-title no-margin size-title-section"
                  >
                    {{ 'PROJECT.MY_BILLING.ADDRESS' | translate }}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row margin-text-section">
                  <div class="col-12 text-lato position-text-section">
                    <div class="size-text-section">
                      <span *ngIf="validAddress == true">
                        <span *ngFor="let address_line of address">
                          {{ address_line }}<br />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25 content-section"
            *ngIf="
              isLoading == false && project.billingInformation != undefined
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-12 no-margin text-left margin-title-section">
                <div class="position-title-section">
                  <div
                    class="text-lato text-white text-bold text-medium card-title no-margin size-title-section"
                  >
                    {{ 'PROJECT.MY_BILLING.CURRENCY' | translate }}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row margin-text-section">
                  <div class="col-12 text-lato position-text-section">
                    <div class="size-text-section">
                      <span
                        *ngIf="project.billingInformation.currency != undefined"
                        >{{
                          getCurrencyText(
                            project.billingInformation.currency?.code
                          )
                        }}</span
                      >
                      <span
                        *ngIf="project.billingInformation.currency == undefined"
                        >{{
                          'PROJECT.MY_BILLING.CURRENCY_NOT_AVAILABLE'
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25 content-section"
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-12 no-margin text-left margin-title-section">
                <div class="position-title-section">
                  <div
                    class="text-lato text-bold text-medium card-title no-margin size-title-section"
                  >
                    {{ 'PROJECT.MY_BILLING.VAT_NUMBER' | translate }}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row margin-text-section">
                  <div class="col-12 text-lato position-text-section">
                    <div class="size-text-section">
                      <span
                        *ngIf="
                          project.billingInformation.vatNumber != undefined
                        "
                        >{{ project.billingInformation.vatNumber }}</span
                      >
                      <span
                        *ngIf="
                          project.billingInformation.vatNumber == undefined
                        "
                        >{{
                          'PROJECT.MY_BILLING.VAT_NUMBER_NOT_AVAILABLE'
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25 content-section"
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-12 no-margin text-left margin-title-section">
                <div class="position-title-section">
                  <div
                    class="text-lato text-white text-bold text-medium card-title no-margin size-title-section"
                  >
                    {{ 'PROJECT.MY_BILLING.BILLING_EMAIL' | translate }}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row margin-text-section">
                  <div class="col-12 text-lato position-text-section">
                    <div class="size-text-section">
                      <span
                        *ngIf="
                          project.billingInformation?.contactEmail != undefined
                        "
                        >{{ project.billingInformation.contactEmail }}</span
                      >
                      <span
                        *ngIf="
                          project.billingInformation?.contactEmail == undefined
                        "
                        >{{
                          'PROJECT.MY_BILLING.BILLING_EMAIL_NOT_AVAILABLE'
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div-->
        <div
          class="content-page margin-top-30"
          *ngIf="project.billingInformation != undefined"
        >
          <div class="d-flex col-direction section-container row no-margin">
            <div class="col-12 margin-section-right">
              <section-title
                title="PROJECT.MY_BILLING_EDIT.PART_1"
                description="PROJECT.MY_BILLING_EDIT.INSTRUCTIONS"
              ></section-title>
              <span
                class="btn text-lato text-bold card-title no-margin btn-edit right-btn"
                (click)="editBilling()"
                >{{ 'PROJECT.MY_BILLING.EDIT' | translate }}</span
              >
            </div>
            <div class="col-12 no-margin text-right content-btn"></div>
            <div class="col-12 margin-section-left">
              <form [formGroup]="myBillingForm">
                <div class="row form-container">
                  <div
                    class="col-4 text-lato text-white form-container-section-left"
                  >
                    {{ 'PROJECT.MY_BILLING_EDIT.CORPORATE_NAME' | translate }}
                  </div>
                  <div class="col-8 text-lato container-field">
                    <div class="size-text-section">
                      {{ project.billingInformation?.corporateName }}
                    </div>
                  </div>
                </div>

                <div class="row form-container">
                  <div class="col-4 text-lato form-container-section-left">
                    {{ 'PROJECT.MY_BILLING_EDIT.ADDRESS' | translate }}
                  </div>

                  <div class="col-8 text-lato container-field">
                    <div class="size-text-section">
                      {{ address.addressLine1 }}<br />
                      <span
                        *ngIf="
                          address.addressLine2 != undefined &&
                          address.addressLine2 !== ''
                        "
                        >{{ address.addressLine2 }}<br
                      /></span>
                      {{ address.zipCode }} {{ address.city }}<br />
                      {{ address.state }}<br />
                      {{ address.country }}<br />
                    </div>
                  </div>
                </div>
                <div class="row form-container">
                  <div class="col-4 text-lato form-container-section-left">
                    {{
                      'PROJECT.MY_BILLING_EDIT.PREFERRED_CURRENCY_TITLE'
                        | translate
                    }}
                  </div>
                  <div class="col-8 text-lato container-field">
                    <div class="size-text-section">
                      {{
                        getCurrencyText(
                          project.billingInformation?.currency?.code
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div class="row form-container">
                  <div class="col-4 text-lato form-container-section-left">
                    {{ 'PROJECT.MY_BILLING_EDIT.VAT_NUMBER_TITLE' | translate }}
                  </div>

                  <div class="col-8 text-lato container-field">
                    <div class="size-text-section">
                      {{ project.billingInformation?.vatNumber }}
                    </div>
                  </div>
                </div>
                <div class="row form-container">
                  <div class="col-4 text-lato form-container-section-left">
                    {{ 'PROJECT.MY_BILLING_EDIT.BILLING_EMAIL' | translate }}
                  </div>
                  <div class="col-8 text-lato container-field">
                    <div class="size-text-section">
                      {{ project.billingInformation?.contactEmail }}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal projectId="{{ id }}"></modal>
