<div
  class="text-right close-view-container"
  *ngIf="campaignCreationBufferService.getStep() < 7"
>
  <span class="top-text text-lato text-grey text-small"
    ><a (click)="closeWindow()"
      ><span class="btn btn-outline-primary left-margin text-button">{{
        'COMMON.TEXT_CLOSE_WINDOW' | translate
      }}</span></a
    ></span
  >
</div>
