import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
} from '@angular/core'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { CacheService } from '../../../../_services/cache.service'
import { DataService } from '../../../../_services/data.service'

@Component({
  templateUrl: 'project_settings_edit.component.html',
  styleUrls: ['./project_settings_edit.component.scss'],
})
export class ProjectSettingsEditComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any
  isValid: boolean
  hasBrandNameError: boolean
  myBrandForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasAccount = false

  hasSelectedAFile: boolean

  project_name: any
  project: any
  project_id: any

  selectedFile: File // For Logo
  imageSrc: any // For Logo

  workspace_logo_url: any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private cacheService: CacheService,
    private dataService: DataService,
    private location: Location,
    private _ngZone: NgZone,
    public alertService: AlertService
  ) {
    this.project_name = undefined
    this.project = undefined
    this.project_id = undefined
    this.loading = false
    this.hasAccount = false
    this.imageSrc = undefined

    this.isValid = true
    this.hasBrandNameError = false
    this.hasSelectedAFile = false
  }

  ngOnInit() {
    this.alertService.resetAlerts()

    this.myBrandForm = this.formBuilder.group({
      brandNameForm: this.formBuilder.group({
        brandName: [''],
      }),
    })

    this.sub = this.route.params.subscribe(async (params) => {
      this.project_id = params['projectId']

      this.workspace_logo_url = './assets/images/img-placeholder.png'

      this.project = this.dataService.getProject(this.project_id)
      /*            var projects = await this.cacheService.getProjects();
                        for (var i = 0; i < projects.length; i++) {
                            if (projects[i]._id == this.project_id) {
                                this.project = projects[i]
                                break;
                            }
                        }*/

      this.initValues(this.project)

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  initValues(project) {
    this.myBrandForm = this.formBuilder.group({
      brandNameForm: this.formBuilder.group({
        brandName: [this.project.title],
      }),
    })

    if (project.pictureURL != undefined)
      this.workspace_logo_url = project.pictureURL
  }

  async onSubmit() {
    try {
      this.hasBrandNameError = false
      this.alertService.resetAlerts()

      var title = this.myBrandForm.controls.brandNameForm[
        'controls'
      ].brandName.value.trim()

      if (title != undefined) {
        if (title.length > 2) {
          this.isValid = true

          var brand = {
            title: title,
          }

          var res
          if (this.hasSelectedAFile == true) {
            res = await this.apiService.updateWorkspace(
              this.project_id,
              brand,
              this.selectedFile
            )
            if (res.status == 200) {
              this.alertService.pushMessageAlert(
                'PROJECT.SETTINGS_EDIT.ALERT_OK'
              )

              this.project = res.body
              this.initValues(this.project)
            } else {
              this.alertService.pushErrorAlert(
                'PROJECT.SETTINGS_EDIT.ALERT_NOK'
              )
            }
          } else {
            res = await this.apiService.updateWorkspace(
              this.project_id,
              brand,
              undefined
            )
            if (res.status == 200) {
              this.alertService.pushMessageAlert(
                'PROJECT.SETTINGS_EDIT.ALERT_OK'
              )
            } else {
              this.alertService.pushErrorAlert(
                'PROJECT.SETTINGS_EDIT.ALERT_NOK'
              )
            }
          }
        } else {
          this.hasBrandNameError = true
          this.isValid = false
          this.alertService.pushErrorAlert('PROJECT.SETTINGS_EDIT.ALERT_NOK')
        }
      } else {
        this.isValid = false
        this.alertService.pushErrorAlert('PROJECT.SETTINGS_EDIT.ALERT_NOK')
      }
    } catch (e) {
      this.alertService.pushErrorAlert('PROJECT.SETTINGS_EDIT.ALERT_NOK')
    }
  }

  onFileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.imageSrc = reader.result
      this.selectedFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
    this.hasSelectedAFile = true
  }

  stepBack() {
    this.location.back()
  }
}
