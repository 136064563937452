import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { DateHelper } from '../../../../../../_helpers/date.helpers'

import { PreferencesManager } from './../../../../../../_services/preferences.manager.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'
import { APIService } from './../../../../../../_services/api.service'
import { ExternalRequestService } from './../../../../../../_services/external.requests.service'

import { STATE } from './../../../../../../_models/validation/state'

@Component({
  selector: 'campaign-create-step-6',
  templateUrl: 'campaign-create-step-6.component.html',
  styleUrls: ['./campaign-create-step-6.component.scss'],
})
export class CampaignCreateStep6Component implements OnInit {
  newCampaignForm: FormGroup

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    public preferencesManager: PreferencesManager,
    private formBuilder: FormBuilder,
    private apiService: APIService,
    private externalRequestService: ExternalRequestService
  ) {}

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({})
  }

  onSubmitProject() {
    // CHECK IF CAMPAIGN IS VALID
    let fromStep = this.campaignCreationBufferService.getStep()
    let state = this.campaignCreationBufferService.checkCampaignState(
      fromStep,
      fromStep + 1
    )

    if (state == STATE.IS_VALID) this.registerCampaign()
    else this.campaignCreationBufferService.showAlert()
  }

  async registerCampaign() {
    var locations = []
    for (
      var i = 0;
      i <
      this.campaignCreationBufferService.campaignBeingCreated().locations
        .length;
      i++
    ) {
      locations.push({
        country: this.campaignCreationBufferService.campaignBeingCreated()
          .locations[i].country,
        area: this.campaignCreationBufferService.campaignBeingCreated()
          .locations[i].state,
      })
    }

    var networks = []
    for (
      var i = 0;
      i <
      this.campaignCreationBufferService.campaignBeingCreated().networks.length;
      i++
    ) {
      networks.push({
        identifier: this.campaignCreationBufferService.campaignBeingCreated()
          .networks[i].id,
      })
    }

    var infos = {
      project_id: this.campaignCreationBufferService.getProject()._id,
      title: this.campaignCreationBufferService.campaignBeingCreated().title,
      target: this.campaignCreationBufferService.campaignBeingCreated().type
        .info,
      networks: networks,
      filters: this.campaignCreationBufferService.campaignBeingCreated()
        .keywords,
      startDate: new Date(
        this.campaignCreationBufferService.campaignBeingCreated().date_start
      ).getTime(),
      endDate: new Date(
        this.campaignCreationBufferService.campaignBeingCreated().date_end
      ).getTime(),
      locations: locations,
      budget: this.campaignCreationBufferService.campaignBeingCreated().budget,
      compensation: this.campaignCreationBufferService.campaignBeingCreated()
        .compensation,
      numberOfFreeProducts: this.campaignCreationBufferService.campaignBeingCreated()
        .numberOfFreeProducts,
      productDescription: this.campaignCreationBufferService.campaignBeingCreated()
        .productDescription,
      do: this.campaignCreationBufferService.campaignBeingCreated().do,
      dont: this.campaignCreationBufferService.campaignBeingCreated().dont,
      brief: this.campaignCreationBufferService.campaignBeingCreated().brief,
      social_accounts: [],
      isDraft: this.campaignCreationBufferService.hasDraftToBeActivated(),
    }

    this.externalRequestService.setIsLoading(true)

    try {
      let res = undefined

      if (this.campaignCreationBufferService.getAction() == 'creation') {
        res = await this.apiService.createCampaign(
          infos,
          this.campaignCreationBufferService.getMoodboardFiles()
        )
      } else {
        infos[
          'moodboard'
        ] = this.campaignCreationBufferService.getMoodboardWithUrlImages()
        res = await this.apiService.updateCampaign(
          this.campaignCreationBufferService.campaignBeingCreated().id,
          infos,
          this.campaignCreationBufferService.getMoodboardFiles()
        )
      }

      if (res.status == 200) {
        this.externalRequestService.setIsLoading(false)
        this.campaignCreationBufferService.stepForward()
        this.campaignCreationBufferService.campaignBeingCreated().id =
          res.body._id
      } else {
        this.externalRequestService.setIsLoading(false)
      }
    } catch (e) {
      this.externalRequestService.setIsLoading(false)
    }
  }

  getDate(date) {
    return DateHelper.convertDoubleToReadableDate(date)
  }
}
