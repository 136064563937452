import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js'
import { Label, Color } from 'ng2-charts'

@Component({
  templateUrl: 'deal-forecast.component.html',
  selector: 'deal-forecast',
  styleUrls: ['./deal-forecast.component.scss'],
})
export class DealForecastComponent implements OnInit {
  isVisible: boolean

  public lineChartData: ChartDataSets[] = [
    { data: [200, 259, 180, 1200, 756, 455, 840], label: 'Monthly revenues' },
  ]
  public lineChartLabels: Label[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
  }
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(255,173,0,1)',
      backgroundColor: 'rgba(255,173,0,0.1)',
      //borderColor: 'rgba(255,0,108,1)',
      //backgroundColor: 'rgba(255,0,108,0.1)',
    },
  ]
  public lineChartLegend = true
  public lineChartType: ChartType = 'line'
  public lineChartPlugins = []

  constructor(private router: Router) {
    this.isVisible = false
  }

  async ngOnInit() {}

  ngOnDestroy() {}

  toggleForecast() {
    this.isVisible = !this.isVisible
  }
}
