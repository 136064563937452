<div class="container-fluid full-height bg-default">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'COMMON.PUBLICATIONS' | translate }}" style="width:100%"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{ 'COMMON.PUBLICATIONS' | translate }}"></nav-bar>

                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <campaign-summary></campaign-summary>
                    </div>
                    <div class="col-12 light-box margin-top-25"
                        style="">
                        <div class="row" style="margin: 0">
                            
                            <div col="col-12" style="width: 100%;">
                                <div class="row"
                                    style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px">
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center; color: #ff006c">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_calendar.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center; color: #ff006c">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_audience_pink.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_profile.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_impression.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon-bubble.png">
                                    </div>
                                    <div class="col-2 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); height: 80px; display: flex; justify-content: center; align-items: center">
                                        <img style="height: 30px; margin-top: 0px"
                                    src="./assets/images/icon_dollar.png">
                                    </div>

                                </div>
                                <div class="row"
                                    style="color: white; text-align: center; margin-top: 3px; margin-bottom: 3px" *ngFor="let publication of publications">
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.timestamp}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center; 
                                        padding-top: 20px;
                                        padding-bottom: 20px;">
                                        <div class="card" style="background-color: transparent">
                                            <img src='./assets/images/socials-linkedin.png' *ngIf="publication.network === 'linkedin'" style="width: 20px;">
                                            <img src='./assets/images/socials-twitter.png' *ngIf="publication.network === 'twitter'" style="width: 20px;"> 
                                            <img src='./assets/images/socials-web.png' *ngIf="publication.network === 'web'" style="width: 20px;"> 
                                        </div>
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        <img [src]="getScaler(publication.scaler).pic_url" style="width: 40px; border-radius: 50%; margin-right: 10px"> {{getScaler(publication.scaler).display_name}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.impressions}}
                                    </div>
                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.conversions}}
                                    </div>

                                    <div class="col-2 text-lato text-small"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; max-width: calc(16.666667% - 2px); display: flex; justify-content: center; align-items: center">
                                        {{publication.price}} €
                                    </div>

                                </div>
                                <div class="row" *ngIf="publications == undefined ||publications.length == 0">
                                    <div class="col-12 text-lato text-medium"
                                        style="background-color: #00000030; margin-left: 1px; margin-right: 1px; height: 150px; display: flex; justify-content: center; align-items: center; color: white">
                                        {{'CAMPAIGN.PUBLICATIONS.PUBLICATIONS_NO_PUBLICATION_TEXT' | translate}}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<modal campaignId="{{id}}"></modal>