import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class AdvertisersConversationsAPIService {
  constructor(private http: HttpClient) {}

  async getConversations(workspaceId) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/conversations/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
