<div id="anchor7">
  <div class="result-image">
    <img src="./assets/images/icon-pipe-created.png" />
  </div>
  <h1 class="text-lato text-bold text-big text-darkgrey line-height-115">
    {{ 'CAMPAIGN.CREATE.STEP_7_INSTRUCTIONS_1' | translate }}
    <span *ngIf="campaignCreationBufferService.getAction() === 'creation'">{{
      'CAMPAIGN.CREATE.STEP_7_INSTRUCTIONS_1_CREATED' | translate
    }}</span
    ><span *ngIf="campaignCreationBufferService.getAction() === 'update'">{{
      'CAMPAIGN.CREATE.STEP_7_INSTRUCTIONS_1_UPDATED' | translate
    }}</span>
    !
  </h1>
  <p class="text-lato text-medium text-lightgrey margin-v-30">
    {{ 'CAMPAIGN.CREATE.STEP_7_INSTRUCTIONS_2' | translate }}
  </p>
  <div class="row">
    <div class="col-12" id="goToDashboardButton">
      <input
        class="btn btn-primary btn-big text-lato text-bold"
        type="submit"
        value="{{ 'CAMPAIGN.CREATE.STEP_7_ACTION_BUTTON' | translate }}"
        (click)="goToDashboard()"
      />
    </div>
  </div>
</div>
