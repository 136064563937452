import { Deserializable } from '../deserializable/deserializable.model'
import { User } from '../user/user.model'
import { Credentials } from '../credentials/credentials.model'

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum AuthenticationType {
  BY_CREDENTIALS = 'credentials',
}

/**
 *
 *
 * @export
 * @class Authenticable
 * @implements {Deserializable}
 */
export class Authenticable implements Deserializable {
  /**
   * user is the instance of User that will contain all information to describe
   * the user - like first name, last name, profile picture url...
   * @private
   * @type {User}
   * @memberof Authenticable
   */
  private user: User

  /**
   * user credentials as an instance of Credentials
   * it contains access token & refresh token for the app to authentify user for each backend request
   * @private
   * @type {Credentials}
   * @memberof Authenticable
   */
  private credentials: Credentials

  /**
   * user password as string
   * This value should be empty unless user is being created or updated
   * @private
   * @type {string}
   * @memberof Authenticable
   */
  private password: string

  /**
   * user authentication type as AuthenticationType
   * it indicates what way the user is using for authentication (email + password, passwordless...)
   * @private
   * @type {AuthenticationType}
   * @memberof Authenticable
   */
  private authenticationType: AuthenticationType

  /**
   * Creates an instance of Authenticable.
   * @memberof Authenticable
   */
  constructor() {
    this.user = new User()
    this.credentials = new Credentials()
    this.password = undefined
    this.authenticationType = undefined
  }

  /**
   * Returns user instance stored as Authenticable
   * @return {*}  {User}
   * @memberof Authenticable
   */
  public getUser(): User {
    return this.user
  }

  /**
   * Sets user instance stored as Authenticable
   * @param {User} user
   * @memberof Authenticable
   */
  public setUser(user: User) {
    this.user = user
  }

  /**
   * Returns user credentials (access token & refresh token) stored as Credentials
   * @return {*}  {Credentials}
   * @memberof Authenticable
   */
  public getCredentials(): Credentials {
    return this.credentials
  }

  /**
   * Sets user credentials (access token & refresh token) stored as Credentials
   * @param {Credentials} credentials
   * @memberof Authenticable
   */
  public setCredentials(credentials: Credentials) {
    this.credentials = credentials
  }

  /**
   * Returns user password stored as string.
   * This value should be empty unless user is being created or updated
   * @return {*}  {string}
   * @memberof Authenticable
   */
  public getPassword(): string {
    return this.password
  }

  /**
   * Sets user password stored as string
   * @param {string} password
   * @memberof Authenticable
   */
  public setPassword(password: string) {
    this.password = password
  }

  /**
   * Returns user authentication type stored as AuthenticationType.
   * @return {*}  {AuthenticationType}
   * @memberof Authenticable
   */
  public getAuthenticationType(): AuthenticationType {
    return this.authenticationType
  }

  /**
   * Sets user authentication type stored as AuthenticationType
   * @param {AuthenticationType} authenticationType
   * @memberof Authenticable
   */
  public setAuthenticationType(authenticationType: AuthenticationType) {
    this.authenticationType = authenticationType
  }

  /**
   * Deserialize an Authenticable.
   * @param {*} input
   * @return {*}
   * @memberof Authenticable
   */
  public deserialize(input: any) {
    Object.assign(this, input)
    return this
  }
}
