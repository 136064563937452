import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { CurrencyHelper } from 'src/app/_helpers/referential/currency.helper'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersPreferencesAPIService } from 'src/app/_services/api/publishers.preferences.api.service'

var languages = [
  {
    id: 0,
    name: 'LANGUAGE.ENGLISH',
    code: 'en',
  },
  {
    id: 1,
    name: 'LANGUAGE.FRENCH',
    code: 'fr',
  },
]

@Component({
  templateUrl: 'publisher-preferences-form.component.html',
  selector: 'publisher-preferences-form',
  styleUrls: ['./publisher-preferences-form.component.scss'],
})
export class PublisherPreferencesFormComponent implements OnInit {
  @Input('publisher') publisher: any

  isEditing: boolean
  preferencesForm: FormGroup

  hasPlatformLanguageError: boolean
  isValid: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersPreferencesAPIService,
    private translate: TranslateService
  ) {
    this.preferencesForm = this.formBuilder.group({
      preferredLanguage: { value: '', disabled: !this.isEditing },
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    if (this.hasPreferences() == true) this.isEditing = false
    else this.isEditing = true

    this.initValues()
  }

  initValues() {
    if (this.hasPreferences() == true) {
      this.preferencesForm = this.formBuilder.group({
        preferredLanguage: {
          value:
            this.getPreferences().language != undefined
              ? this.getPreferences().language.code
              : '',
          disabled: !this.isEditing,
        },
      })
    }
  }

  ngOnDestroy() {}

  hasPreferences() {
    try {
      return this?.publisher?.publisher?.preferences != undefined
    } catch (e) {
      return false
    }
  }

  getPreferences() {
    if (this.hasPreferences() == true)
      return this.publisher.publisher.preferences
    else return undefined
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    this.preferencesForm.enable()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.preferencesForm.disable()
    return
  }

  getPreferredLanguage() {
    if (
      this.hasPreferences() == false ||
      this.getPreferences().language == undefined
    )
      return undefined

    let code = this.getPreferences().language?.code
    let text = undefined

    for (let i = 0; i < languages.length; i++) {
      if (languages[i].code === code) {
        text = languages[i].name
        break
      }
    }

    return text
  }

  async saveChanges() {
    try {
      this.hasPlatformLanguageError = false
      this.isValid = true

      this.alertService.resetAlerts()

      let language = this.preferencesForm.controls.preferredLanguage.value
      if (language == undefined || language === '') {
        this.hasPlatformLanguageError = true
        this.isValid = false
        this.alertService.pushErrorAlert('Language invalid')
      }

      if (this.isValid == true) {
        this.isValid = true

        let preferences = {
          language: language,
        }

        let res = await this.apiService.updatePreferences(
          this.publisher.publisher._id,
          preferences
        )
        if (res.status == 200) {
          this.alertService.pushMessageAlert(
            'Your preferences has been updated'
          )
          this.makeNotEditable()
          this.publisher.publisher = res.body.publisher
        } else {
          this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
        }
      } else {
        //this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
    }
  }

  getLanguages() {
    return languages
  }

  updateLanguage() {
    var candidate_language = this.preferencesForm.controls.preferredLanguage
      .value

    if (candidate_language) this.translate.use(candidate_language)
  }
}
