<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-12 text-lato">
        <div class="section-title">
          <div class="row">
            <div class="col-9 campaign-title text-pink text-bold text-large">
              <img
                class="brand-logo-thumbnail"
                [src]="opportunity._brand?.brand?.brand_logo_path"
                *ngIf="opportunity._brand?.brand?.brand_logo_path != undefined"
              />
              <img
                class="brand-logo-thumbnail"
                [src]="'./assets/images/fns_logo_head.png'"
                *ngIf="opportunity._brand?.brand?.brand_logo_path == undefined"
              />
              {{ getCampaign().title }}
              <span
                class="badge badge-primary"
                [ngClass]="{
                  'badge-primary': getCampaignStatus() === 'LIVE',
                  'badge-disabled':
                    getCampaignStatus() === 'CANCELLED' ||
                    getCampaignStatus() === 'DRAFT'
                }"
                >{{ getCampaignStatus() }}</span
              >
              <br />
              <span
                class="brand text-small text-darkgrey"
                (click)="loadBrandInfoPage()"
              >
                By {{ opportunity._brand?.brand?.name }}</span
              >
            </div>
            <div class="col-3 flex-end">
              <div>
                <tiny-cta
                  class="clickable"
                  value="MAKE AN OFFER"
                  (click)="makeAnOffer()"
                ></tiny-cta>
              </div>
            </div>
          </div>
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <i>{{ getCampaignDateSentence() }}</i>
        </div>
        <div class="row">
          <div class="col-6 section-target text-xsmall text-darkgrey">
            <span style="font-weight: bolder; margin-right: 10px"
              >Objective</span
            ><span class="text-pink">{{ getCampaignTarget() }}</span>
          </div>
          <div class="col-6 section-target text-xsmall text-darkgrey">
            <span style="font-weight: bolder; margin-right: 10px"
              >Target networks</span
            ><span
              *ngFor="let network of getCampaign().networks"
              class="network-logo"
            >
              <img [src]="getNetworkThumbnailImagePath(network.identifier)" />
            </span>
          </div>
        </div>
        <div
          class="section-subtitle text-xsmall text-darkgrey"
          *ngIf="
            this.getCampaign()?.locations != undefined &&
            this.getCampaign()?.locations.length > 0
          "
          style="margin-bottom: 0px"
        >
          <span style="font-weight: bolder; margin-right: 10px"
            >Target audience location</span
          ><span
            class="text-pink audience-location"
            *ngFor="let location of this.getCampaign()?.locations"
            >{{ location.country | translate }},
            {{ location.area | translate }}</span
          >
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <span style="font-weight: bolder; margin-right: 10px"
            >Expected campaign actions</span
          ><span class="text-pink audience-location"
            ><span class="text-pink">Blog Writing</span></span
          >
        </div>
        <div class="section-subtitle text-xsmall text-darkgrey">
          <span style="font-weight: bolder; margin-right: 10px"
            >Campaign Brief</span
          >
        </div>
        <div class="section-body text-small text-darkgrey">
          <div
            class="campaign-brief"
            [innerHTML]="getCampaign()?.brief?.text"
          ></div>
        </div>
        <div class="row text-xsmall text-darkgrey do-dont">
          <div class="col-6">
            <div class="do-dont-container one-edge-shadow">
              <div style="text-align: middle; margin-bottom: 10px">
                <b>Do</b>
              </div>
              <div
                *ngFor="let do of doList"
                style="text-align: left; margin-bottom: 5px; margin-left: 10px"
              >
                {{ do.text }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="do-dont-container one-edge-shadow">
              <div style="text-align: midlle; margin-bottom: 10px">
                <b>Don't</b>
              </div>
              <div
                *ngFor="let dont of dontList; let i = index"
                style="text-align: left; margin-bottom: 5px; margin-left: 10px"
              >
                {{ dont.text }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center section-moodboard"
          *ngIf="
            this.getCampaign()?.moodboard != undefined &&
            this.getCampaign()?.moodboard.length > 0
          "
        >
          <div class="col-10">
            <p>Moodboard</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
