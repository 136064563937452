<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="my-workspaces-title">
      <publisher-section-intro
        title="{{ 'SWITCH_WORKSPACE.TITLE' | translate }}"
      ></publisher-section-intro>
    </div>
    <div id="my-workspaces-list">
      <div class="row">
        <div
          class="col-12 col-md-3 workspace-wrapper"
          *ngFor="let workspace of workspaces"
          (click)="selectWorkspace(workspace)"
        >
          <div class="workspace-picture">
            <img [src]="workspace.pictureURL" />
          </div>
          <div class="workspace-title">{{ workspace.title }}</div>
          <div class="workspace-infos">
            {{ workspace.members.length }}
            {{ 'SWITCH_WORKSPACE.MEMBERS' | translate }}
          </div>
        </div>
        <div
          class="col-12 col-md-3 workspace-wrapper"
          (click)="createANewWorkspace()"
        >
          <div class="workspace-picture">
            <img src="./assets/images/img-placeholder.png" />
          </div>
          <div class="workspace-title">
            {{ 'SWITCH_WORKSPACE.CREATE_NEW_WORKSPACE_1' | translate }}<br />{{
              'SWITCH_WORKSPACE.CREATE_NEW_WORKSPACE_2' | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
