<div class="container-fluid">
    <div class="row">
        <div class="col-12 d-flex flex-column v-align full-height" id="journey-start-container">
            <div class="text-center center margin-h">
                <div>
                    <img class="brand-logo" src="./assets/images/fns_logo.png">
                    <h1 class="text-lato text-bold text-big text-darkgrey line-height-115">{{ 'JOURNEY_START.TITLE' | translate}}</h1>
                    <h2 class="text-lato text-bold text-center">{{ 'JOURNEY_START.SUBTITLE' | translate}}
                    </h2>
                    <p class="text-lato text-center" style='margin-top: 30px'>{{ 'JOURNEY_START.INSTRUCTIONS_1' | translate}}
                    </p>
                    <p class="text-lato text-center">{{ 'JOURNEY_START.INSTRUCTIONS_2' | translate}}
                    </p>
                    <div class="row" style="align-items: center">
                        <div class="col-12 col-md-4 button-journey-start">
                            <div class="text-center">
                                <a [routerLink]="['/me']">
                                    <span class="input btn btn-primary btn-big text-lato text-bold">{{ 'JOURNEY_START.ACTION_1' | translate}}</span></a>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 button-journey-start">
                            <div class="text-center">
                                <a [routerLink]="['/projects/create']"><span
                                        class="input btn btn-primary btn-big text-lato text-bold">{{ 'JOURNEY_START.ACTION_2' | translate}}</span></a>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 button-journey-start">
                            <div class="text-center" (click)="showThing('logout')">
                                <span class="input btn btn-primary btn-big text-lato text-bold">{{ 'JOURNEY_START.ACTION_3' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal></modal>