import { Component, OnInit, Input } from '@angular/core'

import { AlertService } from '../../../_services/alert.service'

@Component({
  templateUrl: 'alert.component.html',
  selector: 'alert',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input('alert') alert: any

  constructor(private alertService: AlertService) {}

  async ngOnInit() {}

  ngOnDestroy() {}

  removeAlert() {
    this.alertService.removeAlert(this.alert)
  }
}
