<div class="content-width" app-theme>
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="publisher-profile-biography-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'MY PROFILE' | translate }}"
        description="{{ 'Share what makes you unique' | translate }}"
      ></publisher-section-intro>
      <publisher-profile-biography
        [publisher]="publisher"
        (showModalEvent)="manageModalEvent($event)"
      ></publisher-profile-biography>
    </div>
    <div id="publisher-profile-content-description-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'CONTENT DESCRIPTION' | translate }}"
        description="{{
          'Explaining what kind of content you usually share with your followers to get most relevant campaigns'
            | translate
        }}"
      ></publisher-section-intro>
      <publisher-profile-content-description
        [publisher]="publisher"
        (showModalEvent)="manageModalEvent($event)"
      ></publisher-profile-content-description>
    </div>
    <div id="publisher-profile-professional-profile-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'PROFESSIONAL PROFILE' | translate }}"
        description="{{
          'Sharing your professional profile will help brands to see you as the expert they need'
            | translate
        }}"
      ></publisher-section-intro>
      <publisher-profile-professional-profile
        [publisher]="publisher"
        (showModalEvent)="manageModalEvent($event)"
      ></publisher-profile-professional-profile>
    </div>
  </div>
</div>
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 10"
  *ngIf="displayModalBackground == true"
>
  <div
    class="col-12 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
  ></div>
</div>
<!--div class="content-width" (click)="stopPropagation($event)" app-theme>
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page themed-container">
    <form [formGroup]="profileForm">
      <div class="display-block col-direction section-container">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-12 text-lato text-bold section-container-title">
              GENERAL INFORMATION
            </div>
            <div class="col-12 text-lato section-container-description">
              Description de la catégorie
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8">
          <div class="row display-none">
            <div class="col-12 display-none">
              <input
                class="display-none"
                type="file"
                (change)="onFileChanged($event)"
                accept="image/*"
                #fileInput
              />
            </div>
          </div>
          <div
            class="row form-container one-edge-shadow themed-form"
            id="form-1"
          >
            <div class="col-12">
              <img
                *ngIf="isDisabledForm1 == true"
                class="edit-img"
                src="../../../assets/images/noun_dots.png"
                (click)="toggleMenu(1)"
              />
              <div
                class="row submodal-down edit-menu-position"
                *ngIf="showMenuForm1 == true"
              >
                <div class="col-12 submenu">
                  <div (click)="switchEditing(1)">
                    <div class="submenu-icon">
                      <img src="../../../assets/images/icon_write.png" />
                    </div>
                    <div class="text-lato text-small cursor submenu-title">
                      {{ 'Edit' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-lato form-container-section-left center-text"
              *ngIf="
                me?.infos?.profilePictureURL == undefined &&
                hasChangedProfilePicture == false
              "
            >
              <img
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
              />
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-lato form-container-section-left center-text"
              *ngIf="
                me?.infos?.profilePictureURL &&
                hasChangedProfilePicture == false
              "
            >
              <img
                [src]="me?.infos?.profilePictureURL"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-lato form-container-section-left center-text"
              *ngIf="hasChangedProfilePicture == true"
            >
              <img
                [src]="profileSrc"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
            <div
              class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-lato form-container-section-right change-input-name themed-form"
            >
              <input
                type="text"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_LAST_NAME' | translate
                }}"
                class="input-text text-lato text-medium"
                name="fullName"
                relatedControlName="fullName"
                formGroupName="fullNameForm"
                [disabled]="isDisabledForm1"
                value="{{ fullName }}"
              />
              <div class="center-text">
                <input
                  class="input-sub-text center-text themed-form"
                  type="text"
                  placeholder="Location"
                  name="location"
                  relatedControlName="location"
                  formGroupName="locationForm"
                  [disabled]="isDisabledForm1"
                  value="{{ location }}"
                />
              </div>
              <div class="section-social-media">
                <table class="center-table">
                  <ul class="margin-social-media-img">
                    <div>
                      <div class="social-media-img-inline">
                        <img
                          class="social-media-img"
                          src="../../../assets/images/icons/icon_web_selected.png"
                        />
                        <img
                          *ngIf="isDisabledForm1 == false"
                          (click)="unslecteSocialMedia()"
                          class="remove-social-media"
                          src="../../../assets/images/icon-add.png"
                        />
                      </div>
                      <div class="social-media-img-inline">
                        <img
                          class="social-media-img"
                          src="../../../assets/images/icons/icon_twitter_selected.png"
                        />
                        <img
                          *ngIf="isDisabledForm1 == false"
                          (click)="unslecteSocialMedia()"
                          class="remove-social-media"
                          src="../../../assets/images/icon-add.png"
                        />
                      </div>
                      <div class="social-media-img-inline">
                        <img
                          class="social-media-img"
                          src="../../../assets/images/icons/icon_facebook_selected.png"
                        />
                        <img
                          *ngIf="isDisabledForm1 == false"
                          (click)="unslecteSocialMedia()"
                          class="remove-social-media"
                          src="../../../assets/images/icon-add.png"
                        />
                      </div>
                    </div>
                  </ul>
                </table>
              </div>
            </div>
            <div class="col-8 bio-title">
              <div class="separator-field input-title">Bio</div>
            </div>
            <textarea
              class="col-12 bio-text-field input-sub-text"
              type="text"
              placeholder="bio"
              name="bio"
              relatedControlName="bio"
              formGroupName="bioForm"
              [disabled]="isDisabledForm1"
              >{{ bio }}</textarea
            >

            <div
              class="section-btn"
              *ngIf="isDisabledForm1 == false"
              style="text-align: right"
            >
              <plain-button value="{{ 'SAVE CHANGES' }}" (click)="onSubmit()">
              </plain-button>
            </div>
          </div>
        </div>
      </div>
      <div class="display-block col-direction section-container">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-12 text-lato text-bold section-container-title">
              PUBLICATIONS
            </div>
            <div class="col-12 text-lato section-container-description">
              Description de la catégorie
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8">
          <div
            class="row form-container one-edge-shadow themed-form"
            id="form-2"
          >
            <div class="col-12">
              <img
                *ngIf="isDisabledForm2 == true"
                class="edit-img"
                src="../../../assets/images/noun_dots.png"
                (click)="toggleMenu(2)"
              />
              <div class="section-btn" *ngIf="isDisabledForm2 == false">
                <plain-button value="{{ 'sauvegarder' }}" (click)="onSubmit()">
                </plain-button>
              </div>
              <div
                class="row submodal-down edit-menu-position"
                *ngIf="showMenuForm2 == true"
              >
                <div class="col-12 submenu">
                  <div (click)="switchEditing(2)">
                    <div class="submenu-icon">
                      <img src="../../../assets/images/icon_write.png" />
                    </div>
                    <div class="text-lato text-small cursor submenu-title">
                      {{ 'Edit' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="sub-section-form-container">
                <div class="col-8 bio-title">
                  <div class="separator-field input-title">
                    Language publications
                  </div>
                </div>
                <input
                  class="col-12 bio-description-field input-sub-text"
                  type="text"
                  placeholder="Language"
                  name="language"
                  relatedControlName="language"
                  formGroupName="languageForm"
                  [disabled]="isDisabledForm2"
                  value="{{ language }}"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="sub-section-form-container">
                <div class="col-8 bio-title">
                  <div class="separator-field input-title">Categories</div>
                </div>
                <input
                  class="col-12 bio-description-field input-sub-text"
                  type="text"
                  placeholder="Categories"
                  name="categories"
                  relatedControlName="categories"
                  formGroupName="categoriesForm"
                  [disabled]="isDisabledForm2"
                  value="{{ categories }}"
                />
              </div>
            </div>
            <div class="col-12 bottom-padding-box">
              <div class="sub-section-form-container">
                <div class="col-8 bio-title">
                  <div class="separator-field input-title">
                    Specific Hashtag
                  </div>
                </div>
                <input
                  class="col-12 bio-description-field input-sub-text"
                  type="text"
                  placeholder="Hashtags"
                  name="hashtags"
                  relatedControlName="hashtags"
                  formGroupName="hashtagsForm"
                  [disabled]="isDisabledForm2"
                  value="{{ hashtags }}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div-->
