import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

@Component({
  templateUrl: 'publisher-faq.component.html',
  styleUrls: ['./publisher-faq.component.scss'],
})
export class PublisherFaqComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private router: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {}
  ngOnDestroy(): void {}
}
