import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  SimpleChanges,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { DateHelper } from 'src/app/_helpers/date.helpers'
import {
  ConversationEvent,
  ConversationEventType,
} from 'src/app/_models/conversation/conversation.event.model'
import { Conversation } from 'src/app/_models/conversation/conversation.model'
import { ConversationParticipant } from 'src/app/_models/conversation/conversation.participant.model'
import { ConversationsSynchronisationService } from 'src/app/_services/conversations/conversations.synchronisation.service'

@Component({
  templateUrl: 'collaborations-conversation.component.html',
  selector: 'collaborations-conversation',
  styleUrls: ['./collaborations-conversation.component.scss'],
})
export class CollaborationsConversationComponent implements OnInit, OnDestroy {
  @ViewChild('domElement') domElement: ElementRef
  @ViewChild('messagesElement') messagesElement: ElementRef
  scrolltop: number = null

  interval: any

  relationships: any
  me: any

  toControl = new FormControl()
  filteredOptions: Observable<any[]>

  textForm: FormGroup

  constructor(
    private conversationsSynchronisationService: ConversationsSynchronisationService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.scrolltop = 0
    this.me = this.route.snapshot.data.user.body
    this.relationships = this.route.snapshot.data.relationships
    console.log(this.relationships)

    this.textForm = this.formBuilder.group({
      message: [''],
    })
  }

  ngOnInit() {
    this.filteredOptions = this.toControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        console.log(value)
        return typeof value === 'string'
          ? value
          : value._publisher.user.infos.fullName
      }),
      map((name) => {
        console.log(name)
        return name ? this._filter(name) : this.relationships.slice()
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.domElement)
  }

  ngAfterViewInit() {
    this.interval = setInterval(() => {
      this.adjustScroll()
    }, 1000)
  }

  ngAfterViewChecked() {}

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval)
  }

  getConversationUser(conversation: Conversation) {
    return conversation.participants[1]
  }

  shouldDisplayAvatarLetters(conversation: Conversation) {
    let user = this.getConversationUser(conversation)
    if (
      user.pictureUrl != null &&
      user.pictureUrl !== '' &&
      user.pictureUrl !== './assets/images/blank.png'
    ) {
      return false
    }
    return true
  }

  getConversationUserFirstLetters(conversation: Conversation) {
    return this.getFirstLetters(
      this.getConversationUser(conversation).displayName
    )
  }

  getFirstLetters(text: string) {
    return text.split(' ').reduce((accumulator, currentValue) => {
      return accumulator + currentValue.substr(0, 1)
    }, '')
  }

  getConversationLastMessage(conversation: Conversation) {
    let lastEvent = conversation.events[conversation.events.length - 1]

    if (lastEvent.type == ConversationEventType.TEXT) {
      let text = ''
      if (lastEvent.authorId === '0') text = 'You'
      else text = this.getConversationUser(conversation).displayName

      return text + ': ' + lastEvent.text
    }

    return ''
  }

  getConversation() {
    let conversation = this.conversationsSynchronisationService
      .getConversation()
      .getValue()
    return conversation
  }

  getConversationEvents() {
    return this.conversationsSynchronisationService.getConversation().getValue()
      ?.events
  }

  getEventUser(event: ConversationEvent) {
    return this.conversationsSynchronisationService.getConversationEventAuthor(
      event.ownerId,
      event.authorId
    )
  }

  shouldDisplayAvatarLettersForUser(user: ConversationParticipant) {
    if (
      user.pictureUrl != null &&
      user.pictureUrl !== '' &&
      user.pictureUrl !== './assets/images/blank.png'
    ) {
      return false
    }
    return true
  }

  getConversationUserFirstLettersForUser(user: ConversationParticipant) {
    return this.getFirstLetters(user.displayName)
  }

  formatDate(date) {
    return DateHelper.convertDoubleToReadableDateForMessage(date)
  }

  adjustScroll() {
    if (this.messagesElement)
      this.scrolltop = this.messagesElement.nativeElement.scrollHeight
  }

  displayFn(relationship: any): string {
    console.log(relationship)
    return relationship && relationship._publisher
      ? relationship._publisher.user.infos.fullName
      : ''
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase()

    return this.relationships.filter((option) =>
      option._publisher.user.infos.fullName.toLowerCase().includes(filterValue)
    )
  }

  createConversation(relationship) {
    console.log('Create conversation')
    this.conversationsSynchronisationService.createConversation(
      relationship,
      this.me
    )
  }

  async sendTextEvent() {
    console.log('text event')
    let message = this.textForm.get('message').value
    console.log(message)

    if (message != null) {
      message = message.trim()
      console.log(message)
      if (message != null && message !== '') {
        await this.conversationsSynchronisationService.sendTextEvent(message)
        this.textForm.get('message').setValue('')
      }
    }
  }
}
