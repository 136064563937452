<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-cart.png" style="width: 40px; padding-left: 10px; padding-right: 10px;">
            <span style="position: absolute; top: 24px;">INVITER DES PERSONNES</span>
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png" style="width: 16px"
                    (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12" style="margin-right: 21px">
                <div class="row">
                    <div>
                            <h1 class="text-lato text-bold text-big text-darkgrey line-height-115" style="margin-bottom: 31px; ; font-size: 38px">Invitez
                                des personnes à
                                rejoindre votre projet</h1>
                            <p class="text-lato text-medium text-lightgrey margin-v-30">Les invités recevront un email de
                                confirmation</p>
                            <form class="margin-v-39 max-w-400">
                                <div class="row">
                                    <div class="col-12">
                                        <div style="display: flex; flex-direction: row">
                                            <input style="width:calc(100% - 46px)" type="text" placeholder="Adresse email de l'invité"
                                                class="input-text text-lato text-medium text-darkgrey" name="email"
                                                formControlName="email" autocomplete="off">
                                            <input style="width: 36px; height: 36px; margin-left: 10px; margin-right: 0px; padding: 0px"
                                                class="btn btn-primary btn-big text-lato text-bold" type="submit" value="+" readonly="readonly">
                                        </div>
                                    </div>
                                    <div class="col-12 text-center" style="margin-top: 31px">
                                        <input class="btn btn-primary btn-big text-lato text-bold" type="submit" value="SUIVANT" readonly="readonly">
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>

            </div>
        </div>

    </div>