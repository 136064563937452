<div id="anchor0">
  <p
    class="text-lato text-bold text-big text-darkgrey line-height-115 step-title"
  >
    {{ 'CAMPAIGN.CREATE.STEP_0_TITLE_1' | translate }}
  </p>
  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
  >
    <div class="row">
      <div class="col-12">
        <p class="text-lato text-bold text-darkgrey font-title">
          {{ 'Campaign Title' | translate }}
        </p>
      </div>
      <div class="col-12 col-input">
        <input
          type="text"
          placeholder="{{
            'CAMPAIGN.CREATE.STEP_0_CAMPAIGN_NAME_PLACEHOLDER' | translate
          }} *"
          class="input-text text-lato text-medium text-darkgrey field-width-mobile"
          name="title"
          formControlName="title"
          autocomplete="off"
          (change)="updateTitle()"
        />
      </div>
      <div class="col-12">
        <p class="text-lato text-bold text-darkgrey font-title">
          {{ 'CAMPAIGN.CREATE.STEP_0_TITLE_2' | translate }}
        </p>
      </div>
      <div class="col-12">
        <div class="flex-align-center network-logos-container">
          <div
            class="network-logo"
            *ngFor="let network of getNetworks(); let i = index"
          >
            <img
              class="network-logo-image"
              [src]="getNetworkImage(network)"
              (click)="toggleNetwork(network)"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="text-lato text-bold text-darkgrey font-title">
          {{ 'CAMPAIGN.CREATE.STEP_0_TITLE_3' | translate }}
        </p>
      </div>
      <div class="col-12">
        <div class="row flex-align-center campaign-types-container">
          <div
            class="col-12 col-md-4 campaign-type"
            *ngFor="
              let target of campaignReferentialService.getTargets();
              let i = index
            "
            (click)="selectTarget(target)"
          >
            <div
              class="select-bubble cursor"
              [class.selected-bubble]="activateSelectedBubbleClass(target)"
            >
              <img [src]="getCampaignTypeImage(target)" />
              <p class="text-lato">
                {{ target.text | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" id="form-validation-container">
        <input
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
        />
      </div>
    </div>
  </form>
</div>
