import { Theme } from './symbols'

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    backgroundColorPage: '#434456',
    backgroundColorForm: '#232a32',
    textColor: 'white!important',
  },
}
