import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  selector: 'campaign-create-step-4',
  templateUrl: 'campaign-create-step-4.component.html',
  styleUrls: ['./campaign-create-step-4.component.scss'],
})
export class CampaignCreateStep4Component implements OnInit {
  newCampaignForm: FormGroup

  brief: any

  public editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: ['#000', '#ff006c'] }],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['clean'],
      ['link', 'image', 'video'],
    ],
  }

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private formBuilder: FormBuilder
  ) {
    this.brief = this.campaignCreationBufferService.campaignBeingCreated().brief
  }

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({
      brief: [this.campaignCreationBufferService.campaignBeingCreated().brief],
    })
  }

  onSubmitProject() {
    this.campaignCreationBufferService.moveForward()
  }

  updateBrief() {
    this.campaignCreationBufferService.campaignBeingCreated().brief = this.newCampaignForm.controls[
      'brief'
    ].value
  }
}
