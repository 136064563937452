import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { AdvertisersTasksAPIService } from '../../_services/api/advertisers.tasks.api.service'

@Injectable()
export class AdvertisersTasksResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private taskApiService: AdvertisersTasksAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      let user = await this.apiService.getMyProfile()
      //let publisherId = user?.body?.publisher?._id
      const workspaceId = <string>route.paramMap.get('workspaceId')
      let request = await this.taskApiService.getTasks(workspaceId)

      let tasks = undefined
      let referential = undefined

      if (request.body != undefined && request.body.tasks != undefined)
        tasks = request.body.tasks

      if (request.body != undefined && request.body.referential != undefined)
        referential = request.body.referential

      return {
        tasks: tasks,
        referential: referential,
      }
    } catch (e) {
      return {
        tasks: undefined,
        referential: undefined,
      }
    }
  }
}
