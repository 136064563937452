import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

@Injectable()
export class DataService {
  private data = {}
  private subject = new Subject()

  constructor() {}

  getProfileData(): Observable<any> {
    return this.subject.asObservable()
  }

  sendProfileData(userName) {
    this.subject.next({
      userName,
    })
  }

  reset() {
    this.data = {}
  }

  registerMe(user: any) {
    if (this.data == undefined) this.data = {}
    this.data['me'] = user
  }

  getMe() {
    return this.data['me']
  }

  fetchProjects(projects: any[]) {
    if (this.data == undefined) this.data = {}

    if (this.data['all_projects'] == undefined) this.data['all_projects'] = []

    /*for (var i = 0 ; i < projects.length ; i ++)
        {
            var projectData = new Data(projects[i]._id, 'project', projects[i])
            var source = new BehaviorSubject<Data>(projectData)
            this.data['projects'].push()
        }*/
    this.data['all_projects'] = projects
  }

  getProjects() {
    return this.data['all_projects']
  }

  fetchProject(campaign: any, id: string) {
    if (this.data == undefined) this.data = {}

    if (this.data['projects'] == undefined) this.data['projects'] = {}

    this.data['projects'][id] = campaign
  }

  getProject(id: string) {
    return this.data['projects'][id]
  }

  fetchCampaign(campaign: any, id: string) {
    if (this.data == undefined) this.data = {}

    if (this.data['campaigns'] == undefined) this.data['campaigns'] = {}

    this.data['campaigns'][id] = campaign
  }

  getCampaign(id: string) {
    return this.data['campaigns'][id]
  }
}
