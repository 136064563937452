<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <form [formGroup]="myProfileBioForm">
      <div class="row">
        <div class="col-10 text-lato">
          <div class="section-title text-pink text-bold text-large">
            <div class="image-container">
              <div
                *ngIf="
                  publisher?.publisher?.infos?.profilePictureURL == undefined &&
                  hasChangedProfilePicture == false
                "
              >
                <img
                  class="profile-picture-thumbnail"
                  src="./assets/images/img-placeholder.png"
                />
              </div>
              <div
                *ngIf="
                  publisher?.publisher?.infos?.profilePictureURL &&
                  hasChangedProfilePicture == false
                "
              >
                <img
                  class="profile-picture-thumbnail"
                  [src]="publisher?.publisher?.infos?.profilePictureURL"
                />
              </div>
              <div *ngIf="hasChangedProfilePicture == true">
                <img class="profile-picture-thumbnail" [src]="profileSrc" />
              </div>
            </div>

            <div class="row">
              <!-- prettier-ignore -->
              <div class="col-12">{{ publisher.user.infos.fullName }}<br /><span class="brand text-small text-darkgrey">{{ publisher.user.infos.email }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 flex-end section-btn">
          <edit-button (click)="updateBiography()"></edit-button>
        </div>
      </div>
      <div class="row" *ngIf="hasFullProfileBioInformation() == true">
        <div class="col-12 text-lato" *ngIf="hasAbout() == true">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">About me</div>
            <div class="input-value container-field">
              <div>
                {{ getAbout() }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-lato" *ngIf="hasBiography() == true">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">Biography</div>
            <div class="input-value container-field">
              <div>
                {{ getBiography() }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-lato" *ngIf="hasValues() == true">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">Values</div>
            <div class="input-value container-field">
              <div>
                {{ getValues() }}
              </div>
            </div>
          </div>
        </div>
        <!--div class="col-12 text-lato">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">Interests</div>
            <div class="input-value container-field">
              <div>
                {{ getInterests() }}
              </div>
            </div>
          </div>
        </div-->
      </div>
      <div
        class="row text-align-center"
        *ngIf="hasFullProfileBioInformation() == false"
      >
        <div class="no-position center">
          <div>
            <img
              class="hint"
              src="./../../../../../../assets/images/icon_idea.png"
            />
          </div>
          <div>
            By filling in your profile, will allow you to get relevant campaigns
          </div>
          <div></div>
          <tiny-cta
            (click)="updateBiography()"
            value="FILL IN YOUR PROFILE INFORMATION"
          ></tiny-cta>
        </div>
      </div>
    </form>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div *ngIf="showModal == true">
  <div class="col-12 d-block" id="modal-menu">
    <div
      class="modal fade modal-overlay"
      id="modalEditBiography"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddAward"
      aria-hidden="false"
      (click)="closeModal()"
      style="overflow-x: hidden; overflow-y: auto; margin-top: 40px"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddAwardsTitle">
                    {{ 'Update my profile' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="myProfileBioForm">
              <div class="image-container-popup">
                <div
                  *ngIf="
                    publisher?.publisher?.infos?.profilePictureURL == undefined
                  "
                >
                  <img
                    class="profile-picture-thumbnail profile-picture-thumbnail-popup"
                    src="./assets/images/img-placeholder.png"
                    (click)="fileInput.click(); doNothing()"
                  />
                </div>
                <div *ngIf="publisher?.publisher?.infos?.profilePictureURL">
                  <img
                    class="profile-picture-thumbnail profile-picture-thumbnail-popup"
                    [src]="publisher?.publisher?.infos?.profilePictureURL"
                    (click)="fileInput.click(); doNothing()"
                  />
                </div>
              </div>
              <div class="row d-none">
                <div class="col-12 d-none">
                  <input
                    class="d-none"
                    type="file"
                    (change)="onFileChanged($event)"
                    accept="image/*"
                    #fileInput
                  />
                </div>
              </div>
              <div class="row">
                <div
                  class="col-6"
                  style="
                    margin-left: 60px;
                    margin-top: 8px;
                    max-width: calc(50% - 30px);
                  "
                >
                  <div class="text-small text-darkgrey">
                    <div class="input-title">First name*</div>
                    <div class="input-value container-field">
                      <input-field
                        class="input-field"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        relatedControlName="firstName"
                        formGroupName="firstNameForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-field>
                    </div>
                    <div
                      class="text-pink text-xxsmall"
                      *ngIf="firstNameField === ''"
                    >
                      This field cannot be empty
                    </div>
                  </div>
                </div>
                <div
                  class="col-6"
                  style="margin-top: 8px; max-width: calc(50% - 30px)"
                >
                  <div class="text-small text-darkgrey">
                    <div class="input-title">Last name*</div>
                    <div class="input-value container-field">
                      <input-field
                        class="input-field"
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        relatedControlName="lastName"
                        formGroupName="lastNameForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-field>
                    </div>
                    <div
                      class="text-pink text-xxsmall"
                      *ngIf="lastNameField === ''"
                    >
                      This field cannot be empty
                    </div>
                  </div>
                </div>
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">About me</div>
                    <div class="input-value container-field">
                      <input-text-area
                        class="input-field"
                        type="text"
                        placeholder="In one or two sentences, explain what kind of value you can bring"
                        name="about"
                        relatedControlName="about"
                        formGroupName="aboutForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-text-area>
                      <!--div *ngIf="isEditingAbout == false">
                        {{ getAbout() }}
                      </div-->
                    </div>
                  </div>
                </div>
                <!--div class="col-1 edit-btn">
                  <edit-button
                    id="about"
                    (click)="changeToEdit($event)"
                  ></edit-button>
                </div-->
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">Biography</div>
                    <div class="input-value container-field">
                      <input-text-area
                        class="input-field"
                        type="text"
                        placeholder="Your biography. Don't be shy. From your first years in school to your last professional experience, mention your accomplishments and experiences"
                        name="biography"
                        relatedControlName="biography"
                        formGroupName="biographyForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-text-area>
                      <!--div *ngIf="isEditingBio == false">
                        {{ getBiography() }}
                      </div-->
                    </div>
                  </div>
                </div>
                <!--div class="col-1 edit-btn">
                  <edit-button
                    id="bio"
                    (click)="changeToEdit($event)"
                  ></edit-button>
                </div-->
                <div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">Values</div>
                    <div class="input-value container-field">
                      <input-text-area
                        class="input-field"
                        type="text"
                        placeholder="Your values. Mention your personal traits to let brands what kind of people you are."
                        name="values"
                        relatedControlName="values"
                        formGroupName="valuesForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-text-area>
                      <!--div *ngIf="isEditingValues == false">
                        {{ getValues() }}
                      </div-->
                    </div>
                  </div>
                </div>
                <!--div class="col-1 edit-btn">
                  <edit-button
                    id="values"
                    (click)="changeToEdit($event)"
                  ></edit-button>
                </div-->
                <!--div class="col-12 text-lato">
                  <div class="section-body text-small text-darkgrey">
                    <div class="input-title">Interests</div>
                    <div class="input-value container-field">
                      <input-text-area
                        class="input-field"
                        type="text"
                        placeholder="You can add some interests here"
                        name="interests"
                        relatedControlName="interests"
                        formGroupName="interestsForm"
                        [parentGroup]="myProfileBioForm"
                      >
                      </input-text-area>
                    </div>
                  </div>
                </div-->
                <!--div class="col-1 edit-btn">
                  <edit-button
                    id="interests"
                    (click)="changeToEdit($event)"
                  ></edit-button>
                </div-->
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="one-btn-radius btn btn-primary text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
