import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'cryptr-button.component.html',
  selector: 'cryptr-button',
  styleUrls: ['./cryptr-button.component.scss'],
})
export class CryptrButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
