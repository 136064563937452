import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { DataService } from '../../../../_services/data.service'

import { CurrencyHelper } from '../../../../_helpers/referential/currency.helper'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

@Component({
  templateUrl: 'project_billing.component.html',
  styleUrls: ['./project_billing.component.scss'],
})
export class ProjectBillingComponent
  implements OnInit, OnDestroy, AfterViewInit {
  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  project: any

  validAddress: boolean
  address: any
  addressToDisplay: string = ''

  myBillingForm: FormGroup

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private formBuilder: FormBuilder
  ) {
    this.isLoading = true
    this.showSomething = false
    this.validAddress = true
    this.address = []
    this.project = undefined

    this.myBillingForm = this.formBuilder.group({
      corporateNameForm: this.formBuilder.group({
        corporateName: [''],
      }),
      address_1Form: this.formBuilder.group({
        address_1: [''],
      }),
      address_2Form: this.formBuilder.group({
        address_2: [''],
      }),
      zipCodeForm: this.formBuilder.group({
        zipCode: [''],
      }),
      cityForm: this.formBuilder.group({
        city: [''],
      }),
      stateForm: this.formBuilder.group({
        state: [''],
      }),
      countryForm: this.formBuilder.group({
        country: [''],
      }),
      preferredCurrency: [''],
      vatNumberForm: this.formBuilder.group({
        vatNumber: [''],
      }),
      contactEmailForm: this.formBuilder.group({
        contactEmail: new FormControl(
          '',
          Validators.compose([
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
    })
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['projectId']

      this.project = this.dataService.getProject(this.id)
      /*this.projects = await this.cacheService.getProjects();
            for (var i = 0; i < this.projects.length; i++) {
                if (this.projects[i]._id == this.id) {
                    this.project_index = i
                    break;
                }
            }*/

      if (
        this.project.billingInformation &&
        this.project.billingInformation.address
      ) {
        if (
          this.project.billingInformation.address.addressLine1 == undefined &&
          this.project.billingInformation.address.city == undefined &&
          this.project.billingInformation.address.country == undefined
        ) {
          this.validAddress = false
        } else {
          this.address = this.project.billingInformation.address
          //this.formatAddress(this.project.billingInformation.address)
          this.validAddress = true
        }
      } else {
        this.validAddress = false
      }

      if (this.project.billingInformation != undefined) {
        this.myBillingForm = this.formBuilder.group({
          corporateNameForm: this.formBuilder.group({
            corporateName: [this.project.billingInformation.corporateName],
          }),
          address_1Form: this.formBuilder.group({
            address_1: [this.project.billingInformation.address.addressLine1],
          }),
          address_2Form: this.formBuilder.group({
            address_2: [this.project.billingInformation.address.addressLine2],
          }),
          zipCodeForm: this.formBuilder.group({
            zipCode: [this.project.billingInformation.address.zipCode],
          }),
          cityForm: this.formBuilder.group({
            city: [this.project.billingInformation.address.city],
          }),
          stateForm: this.formBuilder.group({
            state: [this.project.billingInformation.address.state],
          }),
          countryForm: this.formBuilder.group({
            country: [this.project.billingInformation.address.country],
          }),
          preferredCurrency: [
            this.project.billingInformation.currency != undefined
              ? this.project.billingInformation.currency.code
              : '',
          ],
          vatNumberForm: this.formBuilder.group({
            vatNumber: [this.project.billingInformation.vatNumber],
          }),
          contactEmailForm: this.formBuilder.group({
            contactEmail: new FormControl(
              this.project.billingInformation.contactEmail,
              Validators.compose([
                Validators.required,
                Validators.pattern(
                  '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                ),
              ])
            ),
          }),
        })
      }

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  formatAddress(address) {
    if (address.addressLine1 != undefined && address.addressLine1.length > 0) {
      this.address.push(address.addressLine1)
      this.addressToDisplay += address.addressLine1 + '\n'
    }

    if (address.addressLine2 != undefined && address.addressLine2.length > 0) {
      this.addressToDisplay += address.addressLine2 + '\n'
      this.address.push(address.addressLine2)
    }

    if (
      address.zipCode != undefined &&
      address.zipCode.length > 0 &&
      address.city != undefined &&
      address.city.length > 0
    ) {
      this.address.push(address.zipCode + ' ' + address.city)
      this.addressToDisplay += address.zipCode + ' ' + address.city + '\n'
    }

    if (
      address.zipCode == undefined &&
      address.city != undefined &&
      address.city.length > 0
    ) {
      this.address.push(address.city)
      this.addressToDisplay += address.city + '\n'
    }

    if (address.state != undefined && address.state.length > 0) {
      this.address.push(address.state)
      this.addressToDisplay += address.state + '\n'
    }

    if (address.country != undefined && address.country.length > 0) {
      this.address.push(address.country)
      this.addressToDisplay += address.country + '\n'
    }
  }

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  editBilling() {
    this.router.navigate(['/projects/' + this.project._id + '/billing/edit'])
  }

  getCurrencyText(code) {
    return CurrencyHelper.getCurrencyText(code)
  }

  getCurrencies() {
    return CurrencyHelper.getCurrencies()
  }
}
