<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunity-title">
      <publisher-section-intro
        title="{{ 'CAMPAIGN BRIEF' | translate }}"
        description="{{
          'Fill in your profile to get relevant opportunities' | translate
        }}"
      ></publisher-section-intro>
    </div>
    <div id="opportunity-wrappers">
      <campaign-details
        [opportunity]="opportunity"
        (showModalEvent)="manageModalEvent($event)"
      ></campaign-details>
    </div>
    <div id="brand-title">
      <publisher-section-intro
        title="{{ 'ABOUT THE BRAND' | translate }}"
        description="{{
          'Fill in your profile to get relevant opportunities' | translate
        }}"
      ></publisher-section-intro>
    </div>
    <div id="brand-wrapper">
      <brand-details [opportunity]="opportunity"></brand-details>
    </div>
    <make-an-offer
      [opportunity]="opportunity"
      [showModal]="displayModalBackground"
      (showModalEvent)="manageModalEvent($event)"
    ></make-an-offer>
  </div>
</div>
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 10"
  *ngIf="displayModalBackground == true"
>
  <div
    class="col-12 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
  ></div>
</div>
<!--loader *ngIf="loading == true"></loader-->
