import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { APIService } from '../../../../../_services'
import { SignupBufferService } from './../../../../../_services/buffers/signup.buffer.service'
import { ExternalRequestService } from './../../../../../_services/external.requests.service'

@Component({
  selector: 'signup-step-3',
  templateUrl: 'signup-step-3.component.html',
  styleUrls: ['./signup-step-3.component.scss'],
})
export class SignupStep3Component implements OnInit, AfterViewInit, OnDestroy {
  passwordForm: FormGroup
  isValid: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private signupBufferService: SignupBufferService,
    private externalRequestService: ExternalRequestService
  ) {
    this.isValid = true
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  async createPassword() {
    if (this.passwordForm.valid) {
      var candidate = this.passwordForm.controls.password.value
      if (candidate != undefined) {
        candidate = candidate.trim()
        if (candidate.length >= 10) {
          this.signupBufferService.userBeingCreated().setPassword(candidate)
          await this.registerEverything()
        } else {
          this.isValid = false
        }
      } else {
        this.isValid = false
      }
    } else {
      this.isValid = false
    }
  }

  async registerEverything() {
    try {
      this.externalRequestService.setIsLoading(true)

      await this.signupBufferService.registerUser()

      if (
        this.signupBufferService.getState()['step_3']['registrationRequired'] ==
        true
      ) {
        if (
          this.signupBufferService.getState()['step_3'][
            'registrationHasError'
          ] == false
        ) {
          this.signupBufferService.initValues(false)
          this.signupBufferService.stepForward()
        } else {
        }
      }

      this.externalRequestService.setIsLoading(false)
    } catch (error) {
      this.externalRequestService.setIsLoading(false)
    }
  }

  stepBack() {
    this.signupBufferService.stepBack()
  }
}
