<publisher-profile-content-description-languages
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
>
</publisher-profile-content-description-languages>

<publisher-profile-content-description-expertises
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-content-description-expertises>

<publisher-profile-content-description-networks
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-content-description-networks>

<publisher-profile-content-description-hashtags
  [publisher]="publisher"
  [isEditing]="isEditing"
  (showModalEvent)="manageModalEvent($event)"
></publisher-profile-content-description-hashtags>
