import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CurrencyHelper } from 'src/app/_helpers/referential/currency.helper'
import { APIService, StorageManagementService } from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'brand-billing-informations.component.html',
  selector: 'brand-billing-informations',
  styleUrls: ['./brand-billing-informations.component.scss'],
})
export class BrandBillingInformationsComponent implements OnInit {
  @Input('brand') brand: any

  isEditing: boolean
  myBillingForm: FormGroup

  hasCorporateNameError: boolean
  hasContactEmailError: boolean
  hasAddressError: boolean
  hasCurrencyError: boolean
  isValid: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: APIService,
    private storageManagementService: StorageManagementService
  ) {
    this.myBillingForm = this.formBuilder.group({
      corporateNameForm: this.formBuilder.group({
        corporateName: { value: '', disabled: !this.isEditing },
      }),
      address_1Form: this.formBuilder.group({
        address_1: { value: '', disabled: !this.isEditing },
      }),
      address_2Form: this.formBuilder.group({
        address_2: { value: '', disabled: !this.isEditing },
      }),
      zipCodeForm: this.formBuilder.group({
        zipCode: { value: '', disabled: !this.isEditing },
      }),
      cityForm: this.formBuilder.group({
        city: { value: '', disabled: !this.isEditing },
      }),
      stateForm: this.formBuilder.group({
        state: { value: '', disabled: !this.isEditing },
      }),
      countryForm: this.formBuilder.group({
        country: { value: '', disabled: !this.isEditing },
      }),
      preferredCurrency: { value: '', disabled: !this.isEditing },
      vatNumberForm: this.formBuilder.group({
        vatNumber: { value: '', disabled: !this.isEditing },
      }),
      contactEmailForm: this.formBuilder.group({
        contactEmail: new FormControl(
          { value: '', disabled: !this.isEditing },
          Validators.compose([
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    if (this.hasBillingInformation() == true) this.isEditing = false
    else this.isEditing = true

    this.initValues()
  }

  initValues() {
    if (this.hasBillingInformation() == true) {
      this.myBillingForm = this.formBuilder.group({
        corporateNameForm: this.formBuilder.group({
          corporateName: {
            value: this.getBillingInformation().corporateName,
            disabled: !this.isEditing,
          },
        }),
        address_1Form: this.formBuilder.group({
          address_1: {
            value: this.getBillingInformation().address.addressLine1,
            disabled: !this.isEditing,
          },
        }),
        address_2Form: this.formBuilder.group({
          address_2: {
            value: this.getBillingInformation().address.addressLine2,
            disabled: !this.isEditing,
          },
        }),
        zipCodeForm: this.formBuilder.group({
          zipCode: {
            value: this.getBillingInformation().address.zipCode,
            disabled: !this.isEditing,
          },
        }),
        cityForm: this.formBuilder.group({
          city: {
            value: this.getBillingInformation().address.city,
            disabled: !this.isEditing,
          },
        }),
        stateForm: this.formBuilder.group({
          state: {
            value: this.getBillingInformation().address.state,
            disabled: !this.isEditing,
          },
        }),
        countryForm: this.formBuilder.group({
          country: {
            value: this.getBillingInformation().address.country,
            disabled: !this.isEditing,
          },
        }),
        preferredCurrency: {
          value:
            this.getBillingInformation().currency != undefined
              ? this.getBillingInformation().currency.code
              : '',
          disabled: !this.isEditing,
        },
        vatNumberForm: this.formBuilder.group({
          vatNumber: {
            value: this.getBillingInformation().vatNumber,
            disabled: !this.isEditing,
          },
        }),
        contactEmailForm: this.formBuilder.group({
          contactEmail: new FormControl(
            {
              value: this.getBillingInformation().contactEmail,
              disabled: !this.isEditing,
            },
            Validators.compose([
              Validators.required,
              Validators.pattern(
                '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
              ),
            ])
          ),
        }),
      })
    } else {
      this.makeEditable()
    }
  }

  ngOnDestroy() {}

  hasBillingInformation() {
    try {
      return this?.brand?.billingInformation != undefined
    } catch (e) {
      return false
    }
  }

  getBillingInformation() {
    if (this.hasBillingInformation() == true)
      return this.brand.billingInformation
    else return undefined
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    this.myBillingForm.enable()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.myBillingForm.disable()
    return
  }

  getCurrencyText(code) {
    return CurrencyHelper.getCurrencyText(code)
  }

  getCurrencies() {
    return CurrencyHelper.getCurrencies()
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.hasCorporateNameError = false
      this.hasContactEmailError = false
      this.hasAddressError = false
      this.hasCurrencyError = false
      this.isValid = true

      this.alertService.resetAlerts()

      let corporate_name = this.readTextValue(
        this.myBillingForm.controls.corporateNameForm['controls'].corporateName
          .value
      )

      let address_1 = this.readTextValue(
        this.myBillingForm.controls.address_1Form['controls'].address_1.value
      )

      let address_2 = this.readTextValue(
        this.myBillingForm.controls.address_2Form['controls'].address_2.value
      )
      let zipCode = this.readTextValue(
        this.myBillingForm.controls.zipCodeForm['controls'].zipCode.value
      )
      let city = this.readTextValue(
        this.myBillingForm.controls.cityForm['controls'].city.value
      )
      let state = this.readTextValue(
        this.myBillingForm.controls.stateForm['controls'].state.value
      )
      let country = this.readTextValue(
        this.myBillingForm.controls.countryForm['controls'].country.value
      )
      let currency = this.myBillingForm.controls.preferredCurrency.value
      if (currency == undefined || currency === '') {
        this.hasCurrencyError = true
        this.isValid = false
        this.alertService.pushErrorAlert(
          'PROJECT.MY_BILLING_EDIT.PREFERRED_CURRENCY_INVALID'
        )
      }

      let vatNumber = this.readTextValue(
        this.myBillingForm.controls.vatNumberForm['controls'].vatNumber.value
      )
      let contactEmail = this.readTextValue(
        this.myBillingForm.controls.contactEmailForm['controls'].contactEmail
          .value
      )
      if (contactEmail != undefined)
        this.myBillingForm.controls.contactEmailForm[
          'controls'
        ].contactEmail.setValue(contactEmail)
      if (
        contactEmail != undefined &&
        this.myBillingForm.controls.contactEmailForm['controls'].contactEmail
          .valid == false
      ) {
        this.hasContactEmailError = true
        this.isValid = false

        this.alertService.pushErrorAlert(
          'PROJECT.MY_BILLING_EDIT.ERROR_EMAIL_INVALID'
        )
      }

      if (corporate_name != undefined) {
        if (corporate_name.length > 2) {
        } else {
          this.hasCorporateNameError = true
          this.isValid = false
          this.alertService.pushErrorAlert(
            'PROJECT.MY_BILLING_EDIT.ERROR_CORPORATE_NAME_EMPTY'
          )
        }
      } else {
        this.isValid = false
        this.alertService.pushErrorAlert(
          'PROJECT.MY_BILLING_EDIT.ERROR_CORPORATE_NAME_EMPTY'
        )
      }

      if (this.isValid == true) {
        this.isValid = true

        let billing_infos = {
          corporateName: corporate_name,
          address: {
            addressLine1: address_1,
            addressLine2: address_2,
            zipCode: zipCode,
            city: city,
            state: state,
            country: country,
          },
          vatNumber: vatNumber,
          contactEmail: contactEmail,
        }

        if (currency != undefined && currency !== '')
          billing_infos['currency'] = {
            code: currency,
          }

        let res = await this.apiService.updateBillingInformations(
          this.storageManagementService.getCurrentBrandspaceId(),
          billing_infos
        )
        if (res.status == 200) {
          this.alertService.pushMessageAlert('PROJECT.MY_BILLING_EDIT.ALERT_OK')
          this.makeNotEditable()
          this.brand = res.body
        } else {
          this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
        }
      } else {
        //this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
    }
  }

  getCurrencyToDisplay() {
    const toDisplayVoid = 'N/A'
    let toDisplay = ''

    if (this.hasBillingInformation() == false) return toDisplayVoid
    else if (this.getBillingInformation().currency != undefined) {
      return this.getCurrencyText(this.getBillingInformation().currency.code)
    } else return toDisplayVoid
  }

  getAddressToDisplay() {
    const toDisplayVoid = 'N/A'
    let toDisplay = ''

    if (this.hasBillingInformation() == false) return toDisplayVoid
    else if (this.getBillingInformation().address != undefined) {
      let address = this.getBillingInformation().address
      if (this.textOrVoid(address.addressLine1) != undefined)
        toDisplay += this.textOrVoid(address.addressLine1) + '\n'

      if (this.textOrVoid(address.addressLine2) != undefined)
        toDisplay += this.textOrVoid(address.addressLine2) + '\n'

      let zipCodeAndCity = this.getZipCodeAndCity(address)
      if (zipCodeAndCity != undefined) toDisplay += zipCodeAndCity

      if (this.textOrVoid(address.state) != undefined)
        toDisplay += this.textOrVoid(address.state) + '\n'

      if (this.textOrVoid(address.country) != undefined)
        toDisplay += this.textOrVoid(address.country)

      return toDisplay
    } else return toDisplayVoid
  }

  textOrVoid(text) {
    if (text != undefined && text.length > 0) return text
    else return undefined
  }

  getZipCodeAndCity(address) {
    if (
      this.textOrVoid(address.zipCode) == undefined &&
      this.textOrVoid(address.city) == undefined
    )
      return undefined

    let result = ''

    if (this.textOrVoid(address.zipCode) != undefined)
      result += this.textOrVoid(address.zipCode) + ' '

    if (this.textOrVoid(address.city) != undefined)
      result += this.textOrVoid(address.city)

    result += '\n'

    return result
  }
}
