<div class="back-board board-columns box-shadow" cdkDropListGroup>
  <div
    class="small-board-column padding-columns"
    *ngFor="let column of board.columns"
  >
    <div class="title-column-container">
      <div class="container-title padding-top-15 text-bold">
        {{ getColumn(column).name }}
        <div
          class="add-task-embedded-button"
          (click)="showTaskDetails(undefined, getColumn(column).key)"
        >
          +
        </div>
      </div>
    </div>
  </div>
  <div
    id="add-task-overlay"
    class="overlay"
    *ngIf="showTaskDetailsPanel == true"
    (click)="closeAddTask()"
  ></div>
  <add-task
    id="task-details-container"
    class="container-add-task"
    *ngIf="showTaskDetailsPanel == true"
    (showModalEvent)="manageModalEvent($event)"
  ></add-task>
</div>

<div class="display-block col-direction height-board">
  <div
    class="back-board board-columns box-shadow padding-bottom-15"
    cdkDropListGroup
  >
    <div
      class="small-board-column padding-columns"
      *ngFor="let column of board.columns"
      cdkDropList
      #taskList="cdkDropList"
      [id]="getColumn(column).key"
      [cdkDropListData]="getColumn(column).tasks"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        class="blank-box"
        *ngIf="
          getColumn(column).tasks == undefined ||
          getColumn(column).tasks.length == 0
        "
      >
        <div
          style="
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            color: #444;
          "
        >
          No pending task
        </div>
        <div class="add-task-box">
          <div
            class="add-task"
            (click)="showTaskDetails(undefined, getColumn(column).key)"
          >
            + Add a task
          </div>
        </div>
      </div>
      <collaborations-kanban-item
        *ngFor="let item of getColumn(column).tasks"
        [task]="item"
        (click)="showTaskDetails(item.taskId, undefined)"
        [cdkDragData]="item"
        cdkDrag
      ></collaborations-kanban-item>
      <div
        class="add-task-box"
        *ngIf="getColumn(column).tasks && getColumn(column).tasks.length > 0"
      >
        <div
          class="add-task"
          (click)="showTaskDetails(undefined, getColumn(column).key)"
        >
          + Add a task
        </div>
      </div>
    </div>
  </div>
</div>
