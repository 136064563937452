<div class="content-width">
  <div class="top-menu">
    <div class="text-lato text-medium container-top-menu">
      <img src="./assets/images/icon_copyright.png" />{{
        'PROJECT.SETTINGS_EDIT.TITLE' | translate
      }}
      <span class="text-right">
        <span class="top-text text-lato text-grey text-small"
          ><a (click)="stepBack()"
            ><span
              class="btn btn-outline-primary left-margin text-button top-menu-close"
              >{{ 'COMMON.TEXT_CLOSE_WINDOW' | translate }}</span
            ></a
          ></span
        >
      </span>
    </div>
  </div>
  <alerts [alerts]="alertService.getAlerts()"></alerts>
  <div class="content-page">
    <div class="display-block col-direction section-container">
      <div class="col-12 col-md-3 margin-section-right">
        <section-title
          title="PROJECT.SETTINGS_EDIT.TITLE"
          description="PROJECT.SETTINGS_EDIT.INSTRUCTIONS"
        ></section-title>
      </div>
      <div class="col-12 col-md-9 .margin-section-left">
        <form class="margin-v-39" [formGroup]="myBrandForm">
          <div class="row form-container">
            <div
              class="col-8 text-lato container-top-menu form-container-section-left-darkgrey"
            >
              {{ 'PROJECT.SETTINGS_EDIT.LOGO' | translate }}
              <p
                class="text-lato text-small text-lightgrey form-container-section-right-picture"
              >
                {{
                  'PROJECT.SETTINGS_EDIT.PICTURE_INSTRUCTIONS_LINE_0'
                    | translate
                }}
                {{
                  'PROJECT.SETTINGS_EDIT.PICTURE_INSTRUCTIONS_LINE_1'
                    | translate
                }}
              </p>
            </div>
            <div class="col-4 text-lato form-container-section-right">
              <img
                class="no-picture"
                [src]="workspace_logo_url"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == false"
              />
              <img
                class="picture-size"
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == true && selectedFile == undefined"
              />
              <img
                class="no-picture"
                [src]="imageSrc"
                (click)="fileInput.click()"
                *ngIf="hasSelectedAFile == true && selectedFile != undefined"
              />
            </div>
          </div>
          <div class="row display-none">
            <div class="col-12 display-none">
              <input
                class="display-none"
                type="file"
                (change)="onFileChanged($event)"
                accept="image/*"
                #fileInput
              />
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left-darkgrey">
              {{ 'PROJECT.SETTINGS_EDIT.TITLE' | translate }}
              <p
                class="text-lato text-small text-pink"
                *ngIf="hasBrandNameError == true"
              >
                {{
                  'PROJECT.SETTINGS_EDIT.ERROR_WORKSPACE_NAME_EMPTY' | translate
                }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <input-field
                class="input-text"
                type="text"
                placeholder="{{
                  'PROJECT.MY_BRAND_EDIT.BRAND_NAME_PLACEHOLDER' | translate
                }}"
                name="brandName"
                relatedControlName="brandName"
                formGroupName="brandNameForm"
                [parentGroup]="myBrandForm"
              >
              </input-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-section-btn">
    <div class="text-lato text-medium section-btn">
      <plain-button
        value="{{ 'PROJECT.MY_BRAND_EDIT.SAVE_BUTTON' | translate }}"
        (click)="onSubmit()"
      >
      </plain-button>
    </div>
  </div>
</div>
<loader *ngIf="loading == true"></loader>
