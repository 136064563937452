<div id="searchbar-container">
  <div class="row">
    <div class="col-12">
      <!--div class="text-lato text-bold text-medium search-title">Find Work</div-->
      <form class="form-inline" [formGroup]="searchForm">
        <input
          type="text"
          class="form-control col-12 col-xl-6 mb-2"
          id="q"
          name="q"
          formControlName="q"
          placeholder="Search for keywords, company name..."
        />

        <div
          class="form-check col-4 col-xl-2 mb-2 custom-checkbox position-checkbox"
        >
          <input
            class="form-check-input custom-control-input"
            type="checkbox"
            id="activeCampaignsCheck"
            name="selectActiveCampaigns"
            formControlName="selectActiveCampaigns"
          />
          <label
            class="form-check-label custom-control-label"
            for="activeCampaignsCheck"
            id="active"
          >
            Active campaigns
          </label>
        </div>
        <div
          class="form-check col-4 col-xl-2 mb-2 custom-checkbox position-checkbox"
        >
          <input
            class="form-check-input custom-control-input"
            type="checkbox"
            id="upcomingCampaignsCheck"
            name="selectUpcomingCampaigns"
            formControlName="selectUpcomingCampaigns"
          />
          <label
            class="form-check-label custom-control-label"
            for="upcomingCampaignsCheck"
            id="upcoming"
          >
            Upcoming campaigns
          </label>
        </div>
        <!--div class="form-check col-4 col-xl-2 mb-2">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineFormCheck"
          />
          <label class="form-check-label" for="inlineFormCheck">
            Upcoming campaigns
          </label>
        </div-->

        <div class="col-4 col-xl-2 pr-0" id="searchButtonWrapper">
          <button
            type="submit"
            class="btn btn-primary mb-2"
            (click)="searchCampaigns()"
          >
            SEARCH
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<campaign-thumbnail
  *ngFor="let opportunity of opportunities"
  [opportunity]="opportunity"
></campaign-thumbnail>
