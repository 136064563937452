import { Component, OnInit, Input } from '@angular/core'

import { Router } from '@angular/router'

import { ModalService } from '../../../_services'
import { DataService } from '../../../_services/data.service'

@Component({
  templateUrl: 'nav-bar.component.html',
  selector: 'nav-bar',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input('full') isFull: boolean = false
  @Input('title') title: string

  campaignId: string
  private sub: any

  showMenu: boolean
  showSomething: boolean

  user: any

  modalSub: any

  constructor(
    private router: Router,
    private dataService: DataService,
    private modalService: ModalService
  ) {
    this.campaignId = undefined
    this.showMenu = false
    this.showSomething = false
    this.user = undefined
  }

  async ngOnInit() {
    //this.user = await this.cacheService.getMe()
    this.user = this.dataService.getMe()
    /*this.sub = this.route.params.subscribe(params => {
            if (params['pipeId'])
                this.campaignId = params['pipeId'];

            if (this.campaignId != undefined)
                this.navTitle = "Pipe";
            else
                this.navTitle = "Dashboard";

        });*/

    this.modalSub = this.modalService.getModals().subscribe(async (object) => {
      if (object === 'profile') {
        //this.user = await this.cacheService.getMe()
        this.user = this.dataService.getMe()
      }
    })
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
    if (this.modalSub) this.modalSub.unsubscribe()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  toggleUserMenu() {
    this.showMenu = !this.showMenu
  }

  confirmLogout() {
    this.toggleUserMenu()
    event.preventDefault()
    var modal = document.querySelector('.modal') // assuming you have only 1
    var html = document.querySelector('html')
    modal.classList.add('is-active')
    html.classList.add('has-activated-background')
  }

  logoutConfirmed() {
    this.logout()
  }

  logoutCancelled() {
    var modal = document.querySelector('.modal')
    var html = document.querySelector('html')
    modal.classList.remove('is-active')
    html.classList.remove('has-activated-background')
  }

  showThing(thing) {
    this.showMenu = false
    this.modalService.toggleModal(thing)
  }

  goToProfile() {
    this.router.navigate(['/me'], {
      queryParams: { returnUrl: this.router.routerState.snapshot.url },
    })
  }
}
