import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

@Component({
  templateUrl: 'publisher-offer.component.html',
  styleUrls: ['./publisher-offer.component.scss'],
})
export class PublisherOfferComponent
  implements OnInit, AfterViewInit, OnDestroy {
  offers: any[]
  constructor(private router: Router, private route: ActivatedRoute) {
    this.offers = route.snapshot.data.offers
  }

  ngOnInit() {}

  ngAfterViewInit() {}
  ngOnDestroy(): void {}
}
