import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { APIService, PublishersProfileAPIService } from 'src/app/_services'
import { DateHelper } from '../../../../../_helpers/date.helpers'

@Component({
  templateUrl: 'publisher-profile-professional-profile-patents.component.html',
  selector: 'publisher-profile-professional-profile-patents',
  styleUrls: [
    './publisher-profile-professional-profile-patents.component.scss',
  ],
})
export class PublisherProfileProfessionalProfilePatentsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  profileDataForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showAskConfirmation: boolean

  informations: any[]
  information: any
  informationId: any
  selectedData: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initCertificationForm()
    this.initValues()
    this.showAskConfirmation = false
  }

  initCertificationForm() {
    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      summaryForm: this.formBuilder.group({
        summary: '',
      }),
      numberForm: this.formBuilder.group({
        number: '',
      }),
      inventorsForm: this.formBuilder.group({
        inventors: '',
      }),
      date: [undefined],
      urlForm: this.formBuilder.group({
        url: '',
      }),
      officeForm: this.formBuilder.group({
        office: '',
      }),
      statusForm: this.formBuilder.group({
        status: '',
      }),
    })
  }

  initValues() {
    this.getInformations()
  }

  ngOnDestroy() {}

  hasInformations() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.patents != undefined
      )
    } catch (e) {
      return false
    }
  }

  getInformations() {
    if (this.hasInformations() == true)
      return this.publisher.publisher.professionalProfile.patents
    else return undefined
  }

  getDate(information) {
    let date = DateHelper.convertDoubleToReadableDate(information.date)
    return date
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.profileDataForm.controls.titleForm['controls'].title.value
      )

      let summaryField = this.readTextValue(
        this.profileDataForm.controls.summaryForm['controls'].summary.value
      )

      let numberField = this.readTextValue(
        this.profileDataForm.controls.numberForm['controls'].number.value
      )

      let inventorsField = this.readTextValue(
        this.profileDataForm.controls.inventorsForm['controls'].inventors.value
      )

      let dateField = new Date(
        this.profileDataForm.controls.date.value
      ).getTime()

      let urlField = this.readTextValue(
        this.profileDataForm.controls.urlForm['controls'].url.value
      )

      let officeField = this.readTextValue(
        this.profileDataForm.controls.officeForm['controls'].office.value
      )

      let statusField = this.readTextValue(
        this.profileDataForm.controls.statusForm['controls'].status.value
      )

      if (
        titleField != undefined &&
        this.profileDataForm.controls.titleForm['controls'].title.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        summaryField != undefined &&
        this.profileDataForm.controls.summaryForm['controls'].summary.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        numberField != undefined &&
        this.profileDataForm.controls.numberForm['controls'].number.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        inventorsField != undefined &&
        this.profileDataForm.controls.inventorsForm['controls'].inventors
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        urlField != undefined &&
        this.profileDataForm.controls.urlForm['controls'].url.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        officeField != undefined &&
        this.profileDataForm.controls.officeForm['controls'].office.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        statusField != undefined &&
        this.profileDataForm.controls.statusForm['controls'].status.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          title: titleField,
          summary: summaryField,
          number: numberField,
          inventors: {
            type: inventorsField,
          },
          date: dateField,
          url: urlField,
          office: officeField,
          status: statusField,
        }

        let res = await this.apiService.addPatentInformation(
          this.publisher.publisher._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteInformation(information) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      this.informationId = information._id

      let res = await this.apiService.deletePatentInformation(
        this.publisher.publisher._id,
        this.informationId
      )

      if (res.status == 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyRemoved = true
        this.loading = false
        this.closeModal()
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async updateChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.profileDataForm.controls.titleForm['controls'].title.value
      )

      let summaryField = this.readTextValue(
        this.profileDataForm.controls.summaryForm['controls'].summary.value
      )

      let numberField = this.readTextValue(
        this.profileDataForm.controls.numberForm['controls'].number.value
      )

      let inventorsField = this.readTextValue(
        this.profileDataForm.controls.inventorsForm['controls'].inventors.value
      )

      let dateField = new Date(
        this.profileDataForm.controls.date.value
      ).getTime()

      let urlField = this.readTextValue(
        this.profileDataForm.controls.urlForm['controls'].url.value
      )

      let officeField = this.readTextValue(
        this.profileDataForm.controls.officeForm['controls'].office.value
      )

      let statusField = this.readTextValue(
        this.profileDataForm.controls.statusForm['controls'].status.value
      )

      if (
        titleField != undefined &&
        this.profileDataForm.controls.titleForm['controls'].title.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        summaryField != undefined &&
        this.profileDataForm.controls.summaryForm['controls'].summary.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        numberField != undefined &&
        this.profileDataForm.controls.numberForm['controls'].number.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        inventorsField != undefined &&
        this.profileDataForm.controls.inventorsForm['controls'].inventors
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        urlField != undefined &&
        this.profileDataForm.controls.urlForm['controls'].url.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        officeField != undefined &&
        this.profileDataForm.controls.officeForm['controls'].office.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        statusField != undefined &&
        this.profileDataForm.controls.statusForm['controls'].status.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          title: titleField,
          summary: summaryField,
          number: numberField,
          inventors: {
            type: inventorsField,
          },
          date: dateField,
          url: urlField,
          office: officeField,
          status: statusField,
        }

        let res = await this.apiService.updatePatentInformation(
          this.publisher.publisher._id,
          this.selectedData._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addInformation() {
    this.selectedData = undefined
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateInformation(information) {
    this.selectedData = information
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  saveOrUpdateInformation() {
    if (this.selectedData != undefined) this.updateChanges()
    else this.saveChanges()
  }

  closeModal() {
    this.showModal = false
    this.showAskConfirmation = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  changeDate(event) {
    this.profileDataForm.controls.date.setValue(event.target.value)
  }

  updateModal() {
    if (this.selectedData == undefined) {
      // ADD
      this.prepareModalForAddition()
    } else {
      // UPDATE
      this.prepareModalForUpdate()
    }
  }

  confirmation() {
    this.showAskConfirmation = true
    document.body.style.overflow = 'hidden'
  }

  prepareModalForAddition() {
    this.initCertificationForm()
  }

  prepareModalForUpdate() {
    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: this.selectedData.title,
      }),
      summaryForm: this.formBuilder.group({
        summary: this.selectedData.summary,
      }),
      numberForm: this.formBuilder.group({
        summary: this.selectedData.summary,
      }),
      inventorsForm: this.formBuilder.group({
        inventors: this.selectedData.inventors,
      }),
      date: [new Date(this.selectedData.timePeriod.date)],
      degreeForm: this.formBuilder.group({
        degree: this.selectedData.degree,
      }),
      urlForm: this.formBuilder.group({
        url: this.selectedData.url,
      }),
      officeForm: this.formBuilder.group({
        office: this.selectedData.office,
      }),
      statusForm: this.formBuilder.group({
        status: this.selectedData.status,
      }),
    })
  }

  resetStates() {
    //this.getStates()
  }
}
