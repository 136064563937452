import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../_services/index'
import { DataService } from './../../../_services/data.service'

import { DateHelper } from './../../../_helpers/date.helpers'
import { FakerHelper } from './../../../_helpers/faker.helper'

import { PreferencesManager } from './../../../_services/preferences.manager.service'

@Component({
  templateUrl: 'scaler_profile.component.html',
  styleUrls: ['./scaler_profile.component.scss'],
})
export class ScalerProfileComponent implements OnInit, OnDestroy {
  isLoading: boolean
  id: string
  private sub: any

  campaign: any

  metrics: any

  contactedScalers: any
  confirmedScalers: any
  potentialScalers: any

  scalerId: any
  scaler: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private preferencesManager: PreferencesManager
  ) {
    this.isLoading = true
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['campaignId']
      this.scalerId = params['scalerId']

      this.scaler = FakerHelper.getScaler(this.scalerId)

      this.campaign = this.dataService.getCampaign(this.id)

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  getDateString(timestamp) {
    return DateHelper.convertDoubleToReadableDate(timestamp)
  }

  getImpressions(publication) {
    try {
      return publication.insights.data[1].values[0].value
    } catch (e) {
      return '-'
    }
  }

  getNetworkPicture(network) {
    if (network === 'web') return './assets/images/socials-web.png'
    else if (network === 'linkedin')
      return './assets/images/socials-linkedin.png'
    else if (network === 'twitter') return './assets/images/socials-twitter.png'
    else return ''
  }

  getAvailableBudget() {
    let totalBudget = this.campaign.total_budget
    for (var i = 0; i < this.confirmedScalers.length; i++) {
      totalBudget -= this.confirmedScalers[i].proposal.budget.value
    }

    return totalBudget
  }

  getCampaignCurrency() {
    return 'EUR'
  }

  confirmScaler(scaler) {
    var newBudget = this.getAvailableBudget() - scaler.proposal.budget.value

    if (newBudget >= 0) {
      var i = this.potentialScalers.indexOf(scaler)
      this.potentialScalers.splice(i, 1)

      this.confirmedScalers.push(scaler)
    }
  }

  declineScaler(scaler) {
    var i = this.potentialScalers.indexOf(scaler)
    this.potentialScalers.splice(i, 1)
  }

  cancelScaler(scaler) {
    var i = this.confirmedScalers.indexOf(scaler)
    this.confirmedScalers.splice(i, 1)
  }

  getCommunity() {
    return FakerHelper.getStandardCommunity()
  }

  getTwitterName() {
    try {
      return FakerHelper.getScalerTwitterName(this.scaler.display_name)
    } catch (e) {
      return ''
    }
  }

  getLinkedInName() {
    try {
      return FakerHelper.getScalerLinkdedInName(this.scaler.display_name)
    } catch (e) {
      return ''
    }
  }
}
