import { Component, OnInit, Input } from '@angular/core'

@Component({
  templateUrl: 'edit-button.component.html',
  selector: 'edit-button',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
