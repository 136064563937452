import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'

import { environment } from '../../environments/environment'

import { InternalAuthenticationService } from './authentication/internal.authentication.service'
import { Observable } from 'rxjs'

const BACKEND_URL = environment.apiUrl

const ROLE = environment.role

@Injectable()
export class StorageManagementService {
  constructor() {}

  reset() {
    this.resetCurrentUserId()
    this.resetCurrentPublisherId()
    this.resetCurrentBrandspaceId()
  }

  getCurrentUserId() {
    return localStorage.getItem('userId')
  }

  setCurrentUserId(userId) {
    localStorage.setItem('userId', userId)
  }

  resetCurrentUserId() {
    localStorage.removeItem('userId')
  }

  getCurrentPublisherId() {
    return localStorage.getItem('publisherId')
  }

  setCurrentPublisherId(publisherId) {
    localStorage.setItem('publisherId', publisherId)
  }

  resetCurrentPublisherId() {
    localStorage.removeItem('publisherId')
  }

  hasBrandspaceId() {
    let value = localStorage.getItem('brandspaceId')

    if (value == null || value == undefined || value === '') return false
    else return true
  }

  getCurrentBrandspaceId() {
    return localStorage.getItem('brandspaceId')
  }

  setCurrentBrandspaceId(brandspaceId) {
    localStorage.setItem('brandspaceId', brandspaceId)
  }

  resetCurrentBrandspaceId() {
    localStorage.removeItem('brandspaceId')
  }
}
