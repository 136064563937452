export class Task {
  id: string
  deal: string
  brand: string
  campaign: string
  campaignPictureURL: string
  name: string
  description: string
  dueDate: any
  priority: string
  assignee: string
  step: string
  status: string
  comments: any[]
  attachments: any[]
  events: any[]
  brandId: any

  constructor() {
    this.id = undefined
    this.deal = undefined
    this.brand = undefined
    this.campaign = undefined
    this.campaignPictureURL = undefined
    this.name = undefined
    this.description = undefined
    this.dueDate = undefined
    this.priority = 'not_set'
    this.assignee = undefined
    this.step = 'backlog'
    this.status = 'created'
    this.comments = []
    this.attachments = []
    this.events = []
    this.brandId = []
  }

  static buildTask(values: any) {
    let task = new Task()

    task['id'] = values['_id']
    task['deal'] = values['_deal']
    task['brand'] = values['_brand']
    task['brandId'] = values['_brand']?._id
    task['campaign'] = values['_scope']
    task['campaignPictureURL'] = values['_brand']?.pictureURL
    task['name'] = values['name']
    task['description'] = values['description']
    task['dueDate'] = values['dueDate']
    task['status'] = values['status']
    if (values['priority']) task['priority'] = values['priority']
    task['assignee'] = values['assignee']
    if (values['step']) task['step'] = values['step']
    if (values['comments']) task['comments'] = values['comments']
    if (values['attachments']) task['attachments'] = values['attachments']
    if (values['events']) task['events'] = values['events']

    return task
  }
}
