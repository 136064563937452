import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'
import { TranslateService } from '@ngx-translate/core'
import { PreferencesManager } from 'src/app/_services/preferences.manager.service'
import { ThemeService } from '../../../../_services/theme/theme.service'

var languages = [
  {
    id: 0,
    name: 'LANGUAGE.ENGLISH',
    code: 'en',
  },
  {
    id: 1,
    name: 'LANGUAGE.FRENCH',
    code: 'fr',
  },
]

var currencies = [
  {
    id: 0,
    name: 'EUR - Euro',
    symbol: '€',
  },
  {
    id: 1,
    name: 'USD - US Dollar',
    symbol: '$',
  },
]

@Component({
  templateUrl: 'profile-influencer.component.html',
  styleUrls: ['./profile-influencer.component.scss'],
})
export class ProfileInfluencerComponent implements OnInit {
  publisher: any

  displayModalBackground: boolean

  constructor(
    private route: ActivatedRoute,
    public alertService: AlertService,
    private themeService: ThemeService
  ) {
    this.displayModalBackground = false
    this.publisher = this.route.snapshot.data['user'].body
    console.log(this.publisher)
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
  }

  manageModalEvent(newValue) {
    this.displayModalBackground = newValue
  }
}
