import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'brand-info.component.html',
  styleUrls: ['./brand-info.component.scss'],
})
export class BrandInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  campaign: any

  networks: any[]
  constructor(
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.campaign = route.snapshot.data.campaign
  }

  ngOnInit() {
    this.alertService.resetAlerts()
    this.networks = this.campaign._owner.socialMedia
  }
  ngAfterViewInit() {}
  ngOnDestroy() {}

  getDigitalAccountImagePath(digitalAccount) {
    let path = 'assets/images/icons/'
    switch (digitalAccount) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }
}
