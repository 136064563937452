<div class="content-width">
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div
    class="content-page no-padding-bottom-board"
    *ngIf="hasCollaborations == true"
  >
    <div
      class="row one-edge-shadow sticky-deal-menu"
      style="background-color: white"
    >
      <!--div id="deals-title">
        <publisher-section-intro
          title="{{ 'DEALS' | translate }}"
          description="{{
            'Fill in your profile to get relevant opportunities' | translate
          }}"
        ></publisher-section-intro>
      </div-->
      <div class="col-12" id="deals-menu-wrappers">
        <div>
          <collaboration-menu
            (selectedMenuItemEvent)="manageMenuItem($event)"
          ></collaboration-menu>
        </div>
      </div>
    </div>
    <div
      id="collaborations-summary-wrapper"
      *ngIf="selectedMenuItem === 'summary'"
    >
      <!--collaborations-summary></collaborations-summary-->
    </div>
    <div class="row separator one-edge-shadow">
      <div
        id="collaborations-filter-wrapper"
        *ngIf="selectedMenuItem === 'board'"
      >
        <collaborations-filter
          [collaborationsData]="collaborationsData"
        ></collaborations-filter>
      </div>
    </div>
    <div
      id="collaborations-kanban-wrapper"
      *ngIf="selectedMenuItem === 'board'"
    >
      <collaborations-kanban
        [collaborationsData]="collaborationsData"
      ></collaborations-kanban>
    </div>
    <div
      id="collaborations-messages-wrapper"
      *ngIf="selectedMenuItem === 'conversations'"
      class="row collaborations-conversation center"
    >
      <div class="col-4 col-lg-3 no-padding">
        <collaborations-conversations-menu></collaborations-conversations-menu>
      </div>
      <div class="col-8 col-lg-9 no-padding">
        <collaborations-conversation></collaborations-conversation>
      </div>
    </div>
  </div>
  <div class="content-page" *ngIf="hasCollaborations == false">
    <div id="collaborations-title">
      <publisher-section-intro
        title="{{ 'COLLABORATIONS' | translate }}"
        description="{{
          'Here is a list of your past and active collaborations' | translate
        }}"
      ></publisher-section-intro>
    </div>
    <div id="collaborations-wrappers">
      <div class="content-page">
        <div class="max-w-80pt one-edge-shadow section-container-inner">
          <div class="row center padding-collaborations-section">
            <div class="no-collaborations">
              <div>
                <img
                  class="hint"
                  src="./../../../../../../assets/images/icon_idea.png"
                />
              </div>
              <div>
                You don't have any past or active collaborations. Check current
                opportunities and submit your offer to get accepted to a new
                collaboration
              </div>
              <div></div>
              <tiny-cta
                id="discover-opportunities-btn"
                value="DISCOVER OPPORTUNITIES"
                (click)="goToOpportunities()"
              ></tiny-cta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
