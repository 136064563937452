import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { AdvertisersPublishersAPIService } from '../../_services/api/advertisers.publishers.api.service'

@Injectable()
export class AdvertisersPublishersResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private publishersApiService: AdvertisersPublishersAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const workspaceId = <string>route.paramMap.get('workspaceId')
      let request = await this.publishersApiService.getPublishers(workspaceId)

      return request.body.publishers
    } catch (e) {
      return []
    }
  }
}
