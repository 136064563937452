import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

var languages = [
  {
    id: 0,
    name: 'LANGUAGE.ENGLISH',
    code: 'en',
  },
  {
    id: 1,
    name: 'LANGUAGE.FRENCH',
    code: 'fr',
  },
]

@Component({
  templateUrl: 'section-title.component.html',
  selector: 'section-title',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent implements OnInit {
  @Input('title') title: string
  @Input('description') description: string
  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
