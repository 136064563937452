import { Component } from '@angular/core'

@Component({
  templateUrl: 'loader.component.html',
  selector: 'loader',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor() {}
}
