import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { SignupBufferService } from './../../../../../_services/buffers/signup.buffer.service'

@Component({
  selector: 'signup-step-4',
  templateUrl: 'signup-step-4.component.html',
  styleUrls: ['./signup-step-4.component.scss'],
})
export class SignupStep4Component implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private router: Router,
    private signupBufferService: SignupBufferService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  goToDashboard() {
    this.router.navigate(['/home'])
    this.signupBufferService.initValues()
  }
}
