<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Experience</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addInformation()"></add-button>
      </div>
    </div>
    <div class="row center">
      <div class="no-position" *ngIf="getNumberOfInformations() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>
          Adding your professional experience in your profile will allow you to
          get relevant campaigns
        </div>
        <div></div>
        <tiny-cta
          (click)="addInformation()"
          value="ADD YOUR FIRST EXPERIENCE"
        ></tiny-cta>
      </div>
      <div class="positions-list" *ngIf="getNumberOfInformations() > 0"></div>
    </div>
    <div class="row" *ngIf="getNumberOfInformations() != 0">
      <div
        class="col-12 text-darkgrey information-container"
        *ngFor="let information of getInformations()"
      >
        <div class="col-12 text-bold no-padding">
          {{ information.title
          }}<edit-button
            class="edit-btn-position"
            (click)="updateInformation(information)"
          ></edit-button>
        </div>
        <span class="col-12 text-small no-padding"
          >At {{ information.company }}</span
        >
        <span class="text-small"
          >- Start date {{ getStartDate(information) }} -
          {{ getEndDate(information) }}</span
        >
        <div class="col-12 text-small no-padding">
          {{ information.summary }}
        </div>
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddInformation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddInformation"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="row-container">
              <div class="row">
                <div class="col-12">
                  <h5
                    *ngIf="selectedData == undefined"
                    class="modal-title text-lato"
                    id="modalAddInformationTitle"
                  >
                    {{ 'Add an experience' | translate }}
                  </h5>
                  <h5
                    *ngIf="selectedData != undefined"
                    class="modal-title text-lato"
                    id="modalAddInformationTitle"
                  >
                    {{ 'Update or delete an experience' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="profileDataForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Job title</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Job title"
                      name="title"
                      relatedControlName="title"
                      formGroupName="titleForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Company name</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Company name"
                      name="company"
                      relatedControlName="company"
                      formGroupName="companyForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-6 field date-picker-field">
                  <div class="input-title">Start date</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey container-field"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerStartDate"
                      name="startDate"
                      formControlName="startDate"
                      (dateChange)="changeStartDate($event)"
                      autocomplete="off"
                    />

                    <mat-placeholder class="placeholder"
                      >Start date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerStartDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-6 field date-picker-field">
                  <div class="input-title">End date</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey"
                    *ngIf="isInProgress == false"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerEndDate"
                      name="endDate"
                      formControlName="endDate"
                      formGroupName="endDate"
                      (dateChange)="changeEndDate($event)"
                      autocomplete="off"
                    />
                    <mat-placeholder class="placeholder"
                      >End date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerEndDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                  </mat-form-field>
                  <div
                    class="text-darkgrey text-small present-date"
                    *ngIf="isInProgress == true"
                  >
                    Present
                  </div>
                </div>
                <div class="col-12 check-box">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      (change)="changeToInProgress($event)"
                    />
                    <span class="check-box text-darkgrey text-small">{{
                      'I am currently working in this role' | translate
                    }}</span>
                  </label>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Summary</div>
                  <div class="input-value container-field">
                    <input-text-area
                      class="input-field"
                      type="text"
                      placeholder="Summary"
                      name="summary"
                      relatedControlName="summary"
                      formGroupName="summaryForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-text-area>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your experience have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your experience was not added, please try again
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your experience have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your experience was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              *ngIf="selectedData == undefined"
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveOrUpdateInformation() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
            <div class="row double-btn" *ngIf="selectedData != undefined">
              <button
                type="button"
                class="col-6 btn btn-primary two-btn-left-radius text-lato btn-text"
                (click)="!loading ? saveOrUpdateInformation() : ''"
              >
                {{ 'UPDATE' | translate }}
              </button>
              <button
                type="button"
                class="col-6 btn btn-delete two-btn-right-radius text-lato btn-text"
                (click)="confirmation()"
              >
                {{ 'DELETE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirmation -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showAskConfirmation == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalConfirmation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddinformation"
      aria-hidden="false"
      (click)="closeConfirmationModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato text-align-center"
                    id="modalAddInformationTitle"
                  >
                    {{
                      'are you sure you want to delete this information ?'
                        | translate
                    }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeConfirmationModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row confirmation-btn">
            <button
              type="button"
              class="col-6 btn btn-primary two-btn-left-radius text-lato btn-text"
              (click)="
                deleteInformation(selectedData); closeConfirmationModal()
              "
            >
              {{ 'YES' | translate }}
            </button>
            <button
              type="button"
              class="col-6 btn btn-delete two-btn-right-radius text-lato btn-text"
              (click)="closeConfirmationModal()"
            >
              {{ 'NO' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
