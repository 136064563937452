import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'

import { Router } from '@angular/router'

import { TranslateService } from '@ngx-translate/core'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl
var ROLE = environment.role

@Injectable()
export class InternalAuthenticationService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService
  ) {}

  login(username: string, password: string) {
    var url = BACKEND_URL + '/api/v1/login'
    return this.http
      .post<any>(
        url,
        { email: username, password: password, role: ROLE },
        { observe: 'response' }
      )
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            var token = res.headers.get('x-auth')
            var refresh_token = res.headers.get('h-auth')
            if (res.body) {
              localStorage.setItem(
                'currentUser',
                JSON.stringify({ username, token: token })
              )
              localStorage.setItem(
                'h-token',
                JSON.stringify({ token: refresh_token })
              )

              if (res.body.language)
                localStorage.setItem('language', res.body.language)
              this.translate.use(res.body.language)
            }
          }
        })
      )
  }

  async refreshToken(refresh_token) {
    var url = BACKEND_URL + '/api/v1/users/token'
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'h-auth': refresh_token,
    })
    var res = await this.http
      .post<any>(url, null, { observe: 'response', headers: header })
      .toPromise()
    return res
  }

  storeUserCredentials(email, auth_token, refresh_token, language) {
    localStorage.setItem(
      'currentUser',
      JSON.stringify({ email, token: auth_token })
    )
    localStorage.setItem('h-token', JSON.stringify({ token: refresh_token }))

    if (language) {
      localStorage.setItem('language', language)
      this.translate.use(language)
    }
  }

  logout(returnUrl = undefined) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')
    localStorage.removeItem('h-token')
    localStorage.removeItem('language')
    this.translate.use('en')

    if (returnUrl != undefined)
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: returnUrl },
      })
    else this.router.navigate(['/login'])
  }

  getUsername() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser && currentUser.username) {
      return currentUser.username
    } else return undefined
  }
}
