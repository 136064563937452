<div class="row">
  <div
    class="col-12 col-lg-5 d-flex flex-column v-align full-height"
    id="signup-container"
  >
    <div class="text-right">
      <span class="text-lato text-grey text-small"
        >{{ 'SIGNUP.ALREADY_AN_ACCOUNT' | translate
        }}<a [routerLink]="['/login']" id="login-btn"
          ><span class="btn btn-outline-primary right-margin text-button">{{
            'SIGNUP.TO_LOGIN' | translate
          }}</span></a
        ></span
      >
    </div>
    <div class="text-center center margin-h-public-form">
      <div>
        <img
          class="brand-logo-w240 d-lg-none"
          src="./assets/images/fns_logo.png"
        />
        <h1 class="text-lato text-bold text-big text-darkgrey line-height-115">
          {{ 'SIGNUP.TITLE_LINE_1_STEP_0' | translate }}
        </h1>
        <form
          class="margin-v-39 max-w-400"
          [formGroup]="signupForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-12">
              <input
                id="email"
                type="email"
                placeholder="{{ 'SIGNUP.INPUT_EMAIL_PLACEHOLDER' | translate }}"
                class="input-text text-lato text-medium text-darkgrey"
                name="email"
                formControlName="email"
                autocomplete="off"
              />
            </div>
            <div class="col-12">
              <input
                id="next-btn"
                class="btn btn-primary btn-big text-lato text-bold"
                type="submit"
                value="{{ 'SIGNUP.INPUT_NEXT_BUTTON' | translate }}"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <copyright></copyright>
  </div>
  <div class="col-12 col-lg-7 d-none d-lg-flex no-padding">
    <logo-center></logo-center>
  </div>
</div>
