import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js'
import { Label, Color } from 'ng2-charts'

@Component({
  templateUrl: 'plan-reach-summary.component.html',
  selector: 'plan-reach-summary',
  styleUrls: ['./plan-reach-summary.component.scss'],
})
export class AdvertiserPlanReachSummaryComponent implements OnInit {
  @Input('plan') plan: any
  @Input('keyword') keyword: any

  public showTooltip: boolean

  public lastValue: any
  public previousValue: any
  public totalVolume: any
  public cpc: any
  public competition: any

  public lineChartData: ChartDataSets[] = [{ data: [], label: '' }]
  public lineChartLabels: Label[] = [
    'M12',
    'M11',
    'M10',
    'M9',
    'M8',
    'M7',
    'M6',
    'M5',
    'M4',
    'M3',
    'M2',
    'M1',
  ]

  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false, //this will remove all the x-axis grid lines
        },
      ],
      yAxes: [
        {
          display: false, //this will remove all the x-axis grid lines
          ticks: {
            suggestedMin: 0, // minimum will be 0, unless there is a lower value.
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      position: 'center',
      mode: 'index',
    },
  }
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(255,0,108,1)',
      backgroundColor: 'rgba(255,0,108,1)',
      //backgroundColor: 'rgba(255,0,108,0.1)',
      //borderColor: 'rgba(255,0,108,1)',
      //backgroundColor: 'rgba(255,0,108,0.1)',
    },
  ]
  public lineChartLegend = true
  public lineChartType: ChartType = 'bar'
  public lineChartPlugins = []

  public values = []

  constructor(private router: Router) {
    this.showTooltip = false

    Chart.Tooltip.positioners.center = function (elements) {
      const { x, y, base } = elements[0]._model
      const height = base - y
      return { x, y: 5 }
    }
  }

  async ngOnInit() {
    this.generateLineChartLabels()

    let m1 = 0
    let m2 = 0
    let m3 = 0
    let m4 = 0
    let m5 = 0
    let m6 = 0
    let m7 = 0
    let m8 = 0
    let m9 = 0
    let m10 = 0
    let m11 = 0
    let m12 = 0
    let m1_date = ''
    let m2_date = ''
    let m3_date = ''
    let m4_date = ''
    let m5_date = ''
    let m6_date = ''
    let m7_date = ''
    let m8_date = ''
    let m9_date = ''
    let m10_date = ''
    let m11_date = ''
    let m12_date = ''
    this.totalVolume = 0
    this.cpc = 0
    this.competition = 0

    if (this.plan.data) {
      if (this.keyword == undefined) {
        // GLOBAL REACHm1_date +=
        m1_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m1_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m1_year
        m2_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m2_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m2_year
        m3_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m3_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m3_year
        m4_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m4_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m4_year
        m5_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m5_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m5_year
        m6_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m6_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m6_year
        m7_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m7_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m7_year
        m8_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m8_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m8_year
        m9_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m9_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m9_year
        m10_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m10_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m10_year
        m11_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m11_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m11_year
        m12_date +=
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m12_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[0]].lastValue.m12_year
      } else {
        // REACH FOR ONE KEYWORD
        m1_date +=
          this.plan.data[this.keyword].lastValue.m1_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m1_year
        m2_date +=
          this.plan.data[this.keyword].lastValue.m2_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m2_year
        m3_date +=
          this.plan.data[this.keyword].lastValue.m3_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m3_year
        m4_date +=
          this.plan.data[this.keyword].lastValue.m4_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m4_year
        m5_date +=
          this.plan.data[this.keyword].lastValue.m5_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m5_year
        m6_date +=
          this.plan.data[this.keyword].lastValue.m6_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m6_year
        m7_date +=
          this.plan.data[this.keyword].lastValue.m7_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m7_year
        m8_date +=
          this.plan.data[this.keyword].lastValue.m8_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m8_year
        m9_date +=
          this.plan.data[this.keyword].lastValue.m9_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m9_year
        m10_date +=
          this.plan.data[this.keyword].lastValue.m10_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m10_year
        m11_date +=
          this.plan.data[this.keyword].lastValue.m11_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m11_year
        m12_date +=
          this.plan.data[this.keyword].lastValue.m12_month +
          '/' +
          this.plan.data[this.keyword].lastValue.m12_year
      }
    }

    if (this.plan.data) {
      if (this.keyword == undefined) {
        for (let key in Object.keys(this.plan.data)) {
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m1)
            m1 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m1
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m2)
            m2 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m2
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m3)
            m3 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m3
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m4)
            m4 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m4
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m5)
            m5 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m5
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m6)
            m6 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m6
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m7)
            m7 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m7
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m8)
            m8 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m8
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m9)
            m9 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m9
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m10)
            m10 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue
              .m10
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m11)
            m11 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue
              .m11
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m12)
            m12 += this.plan.data[Object.keys(this.plan.data)[key]].lastValue
              .m12

          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.gms) {
            this.totalVolume += this.plan.data[
              Object.keys(this.plan.data)[key]
            ].lastValue.gms
            if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cpc)
              this.cpc =
                this.plan.data[Object.keys(this.plan.data)[key]].lastValue.gms *
                this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cpc

            if (
              this.plan.data[Object.keys(this.plan.data)[key]].lastValue
                .competition
            )
              this.competition =
                this.plan.data[Object.keys(this.plan.data)[key]].lastValue.gms *
                this.plan.data[Object.keys(this.plan.data)[key]].lastValue
                  .competition
          }
        }
      } else {
        if (this.plan.data[this.keyword].lastValue.m1)
          m1 += this.plan.data[this.keyword].lastValue.m1
        if (this.plan.data[this.keyword].lastValue.m2)
          m2 += this.plan.data[this.keyword].lastValue.m2
        if (this.plan.data[this.keyword].lastValue.m3)
          m3 += this.plan.data[this.keyword].lastValue.m3
        if (this.plan.data[this.keyword].lastValue.m4)
          m4 += this.plan.data[this.keyword].lastValue.m4
        if (this.plan.data[this.keyword].lastValue.m5)
          m5 += this.plan.data[this.keyword].lastValue.m5
        if (this.plan.data[this.keyword].lastValue.m6)
          m6 += this.plan.data[this.keyword].lastValue.m6
        if (this.plan.data[this.keyword].lastValue.m7)
          m7 += this.plan.data[this.keyword].lastValue.m7
        if (this.plan.data[this.keyword].lastValue.m8)
          m8 += this.plan.data[this.keyword].lastValue.m8
        if (this.plan.data[this.keyword].lastValue.m6)
          m9 += this.plan.data[this.keyword].lastValue.m9
        if (this.plan.data[this.keyword].lastValue.m10)
          m10 += this.plan.data[this.keyword].lastValue.m10
        if (this.plan.data[this.keyword].lastValue.m11)
          m11 += this.plan.data[this.keyword].lastValue.m11
        if (this.plan.data[this.keyword].lastValue.m12)
          m12 += this.plan.data[this.keyword].lastValue.m12

        if (this.plan.data[this.keyword].lastValue.gms) {
          this.totalVolume += this.plan.data[this.keyword].lastValue.gms
          if (this.plan.data[this.keyword].lastValue.cpc)
            this.cpc =
              this.plan.data[this.keyword].lastValue.gms *
              this.plan.data[this.keyword].lastValue.cpc

          if (this.plan.data[this.keyword].lastValue.competition)
            this.competition =
              this.plan.data[this.keyword].lastValue.gms *
              this.plan.data[this.keyword].lastValue.competition
        }
      }
    }

    if (this.totalVolume > 0) {
      this.cpc /= this.totalVolume
      this.competition /= this.totalVolume
    }

    this.lineChartData[0].data = [
      m12,
      m11,
      m10,
      m9,
      m8,
      m7,
      m6,
      m5,
      m4,
      m3,
      m2,
      m1,
    ]
    this.lastValue = m1
    this.previousValue = m2

    this.values = [
      { date: m12_date, value: m12 },
      { date: m11_date, value: m11 },
      { date: m10_date, value: m10 },
      { date: m9_date, value: m9 },
      { date: m8_date, value: m8 },
      { date: m7_date, value: m7 },
      { date: m6_date, value: m6 },
      { date: m5_date, value: m5 },
      { date: m4_date, value: m4 },
      { date: m3_date, value: m3 },
      { date: m2_date, value: m2 },
      { date: m1_date, value: m1 },
    ]
  }

  ngOnDestroy() {}

  getLastValueText() {
    if (this.lastValue >= 1000000000) {
      return (this.lastValue / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
    }
    if (this.lastValue >= 1000000) {
      return (this.lastValue / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (this.lastValue >= 1000) {
      return (this.lastValue / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return this.lastValue
  }

  getLastValueDifference() {
    if (this.previousValue == 0) return 'N/A'

    let result =
      ((this.lastValue - this.previousValue) / this.previousValue) * 100

    let prefix = ''
    if (result >= 0) prefix = '+'

    return prefix + result.toFixed(2) + '%'
  }

  generateLineChartLabels() {
    for (let key in Object.keys(this.plan.data)) {
      this.lineChartLabels = [
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m12_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m12_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m11_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m11_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m10_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m10_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m9_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m9_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m8_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m8_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m7_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m7_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m6_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m6_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m5_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m5_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m4_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m4_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m3_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m3_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m2_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m2_year,
        this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m1_month +
          '/' +
          this.plan.data[Object.keys(this.plan.data)[key]].lastValue.m1_year,
      ]
    }
  }

  mouseEnter() {
    this.showTooltip = true
  }

  mouseLeave() {
    this.showTooltip = false
  }
}
