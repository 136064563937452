import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Campaign } from '../../_models/campaign/campaign.model'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersPreferencesAPIService {
  constructor(private http: HttpClient) {}

  async updatePreferences(publisherId, preferences) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/preferences/'

    var res = await this.http
      .post<any>(url, preferences, { observe: 'response' })
      .toPromise()
    return res
  }
}
