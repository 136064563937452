export enum ConversationEventType {
  UNKNOWN,
  TEXT,
  ATTACHMENT,
}

export enum ConversationEventStatus {
  UNKNOWN,
  DRAFT,
  PUBLISHED,
  DELETED,
}

export class ConversationEvent {
  private _id: string
  private _createdAt: number
  private _updatedAt: number
  private _type: ConversationEventType
  private _text: string
  private _infos: any
  private _ownerId: string
  private _authorId: string
  private _status: ConversationEventStatus

  constructor() {}

  private static fillCommonInformations(
    event: ConversationEvent,
    eventInfos: any
  ) {
    event._id = eventInfos._id
    event._createdAt = eventInfos.createdAt
    event._updatedAt = eventInfos.updatedAt
    event._ownerId = eventInfos.owner
    event._authorId = eventInfos.author
    event.setStatus(eventInfos.status)
    event.setType(eventInfos.type)

    event._text = eventInfos.text
    event._infos = eventInfos.infos
  }

  public static buildEvent(eventInfos: any): ConversationEvent {
    let event = new ConversationEvent()
    ConversationEvent.fillCommonInformations(event, eventInfos)
    return event
  }

  public get id(): string {
    return this._id
  }

  public get type(): ConversationEventType {
    return this._type
  }

  public get text(): string {
    return this._text
  }

  public get createdAt(): number {
    return this._createdAt
  }

  public get updatedAt(): number {
    return this._updatedAt
  }

  public get infos(): any {
    return this._infos
  }

  public get ownerId(): string {
    return this._ownerId
  }

  public get authorId(): string {
    return this._authorId
  }

  public get status(): ConversationEventStatus {
    return this._status
  }

  public setStatus(status: string) {
    switch (status) {
      case 'draft':
        this._status = ConversationEventStatus.DRAFT
        break
      case 'published':
        this._status = ConversationEventStatus.PUBLISHED
        break
      case 'deleted':
        this._status = ConversationEventStatus.DELETED
        break
      default:
        this._status = ConversationEventStatus.UNKNOWN
    }
  }

  public setType(type: string) {
    switch (type) {
      case 'text':
        this._type = ConversationEventType.TEXT
        break
      case 'attachment':
        this._type = ConversationEventType.ATTACHMENT
        break
      default:
        this._type = ConversationEventType.UNKNOWN
    }
  }
}
