<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Skills</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button class="edit-skill" (click)="addSkill()"></add-button>
        <edit-button
          (click)="makeEditable()"
          (click)="updateSkill()"
          *ngIf="getNumberOfSkills() > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center">
      <div class="no-position" *ngIf="getNumberOfSkills() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>
          Which are the skills where you are considered as an expert by your
          audience?
        </div>
        <div></div>
        <tiny-cta (click)="addSkill()" value="ADD YOUR FIRST SKILL"></tiny-cta>
      </div>
      <div class="positions-list" *ngIf="getNumberOfSkills() > 0"></div>
    </div>
    <div
      class="row center padding-skill-section"
      *ngIf="getNumberOfSkills() != 0"
    >
      <span
        class="text-lato text-small text-darkgrey skills-section"
        *ngFor="let skill of getSkills()"
      >
        <span class="skill-field">{{ skill.name }}</span>
      </span>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddSkill"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddSkill"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddSkillTitle">
                    {{ 'Add a skill' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="skillsForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Skill name</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Skill name"
                      name="name"
                      relatedControlName="name"
                      formGroupName="nameForm"
                      [parentGroup]="skillsForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div class="row center skills-list-title">
                <div class="input-title">Popular skills</div>
              </div>
              <div class="row center skills-list">
                <div
                  class="text-lato text-small text-darkgrey skills-section"
                  *ngFor="let skill of suggestedSkills"
                >
                  <span class="skill-field" (click)="addSkillToProfile(skill)"
                    >{{ skill.name }} <span class="icon-add">+</span></span
                  >
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your skill have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your skill was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateSkill"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateSkill"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalUpdateSkillTitle">
                    {{ 'Delete skill' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner skill-edit-container">
            <form [formGroup]="skillsForm">
              <div class="row center padding-skill-section">
                <span
                  class="text-lato text-small text-darkgrey skills-section"
                  *ngFor="let skill of getSkills()"
                >
                  <span class="skill-field"
                    >{{ skill.name }}
                    <span class="icon-delete" (click)="deleteSkill(skill)"
                      >x</span
                    ></span
                  >
                </span>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your skill have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your language was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
