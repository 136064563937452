import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'

import {
  AdvertisersRelationshipsAPIService,
  APIService,
} from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'
import { TranslateService } from '@ngx-translate/core'
import { PreferencesManager } from 'src/app/_services/preferences.manager.service'

@Component({
  templateUrl: 'advertiser-publishers-list.component.html',
  styleUrls: ['./advertiser-publishers-list.component.scss'],
})
export class AdvertiserPublishersListComponent implements OnInit, OnDestroy {
  user: any
  publishers: any
  relationships: any
  partners: any
  friends: any
  requests: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private preferencesManager: PreferencesManager,
    private dataService: DataService,
    public alertService: AlertService,
    public apiRelationshipsService: AdvertisersRelationshipsAPIService
  ) {
    this.user = this.route.snapshot.data['user'].body
    this.publishers = this.route.snapshot.data['publishers']
    this.relationships = this.route.snapshot.data['relationships']
    this.requests = this.route.snapshot.data['requests']

    this.partners = this.relationships.filter((relationship) => {
      return relationship.status === 'partner'
    })

    this.friends = this.relationships.filter((relationship) => {
      return relationship.status === 'friend'
    })
  }

  async ngOnInit() {}

  ngOnDestroy() {}

  async sendRequest(publisher) {
    let workspaceId = this.route.snapshot.params['workspaceId']
    await this.apiRelationshipsService.sendRelationshipRequest(
      workspaceId,
      publisher._id
    )
  }

  async cancelRequest(request) {
    let workspaceId = this.route.snapshot.params['workspaceId']
    await this.apiRelationshipsService.cancelRelationshipRequest(
      workspaceId,
      request._id
    )
  }

  async turnToFriend(partner) {
    let workspaceId = this.route.snapshot.params['workspaceId']
    let request = await this.apiRelationshipsService.updateRelationship(
      workspaceId,
      partner._id,
      'friend'
    )

    if (request.status == 200) {
      let updatedRelationship = request.body.relationship
      this.updateRelationships(updatedRelationship)
    }
  }

  async turnToPartner(brand) {
    let workspaceId = this.route.snapshot.params['workspaceId']
    let request = await this.apiRelationshipsService.updateRelationship(
      workspaceId,
      brand._id,
      'partner'
    )

    if (request.status == 200) {
      let updatedRelationship = request.body.relationship
      this.updateRelationships(updatedRelationship)
    }
  }

  updateRelationships(updatedRelationship) {
    if (this.relationships == undefined) {
      this.relationships = [updatedRelationship]
    } else {
      let hasManaged = false
      for (let i = 0; i < this.relationships.length; i++) {
        if (this.relationships[i]._id === updatedRelationship._id) {
          this.relationships[i] = updatedRelationship
          hasManaged = true
        }
      }
      if (hasManaged == false) {
        this.relationships.push(updatedRelationship)
      }
    }

    this.partners = this.relationships.filter((relationship) => {
      return relationship.status === 'partner'
    })

    this.friends = this.relationships.filter((relationship) => {
      return relationship.status === 'friend'
    })
  }
}
