<div class="container-fluid">
    <div class="row">
        <div class="col-12 d-flex flex-column v-align full-height" id="onboarding-container">
            <div class="text-right" style="padding-top: 15px" *ngIf="step < 3">
                <!--span class="top-text text-lato text-grey text-small">Vous n'avez pas de compte ?<span class="btn btn-outline-primary left-margin text-button">CONTACTEZ
                                            NOUS</span></span-->
                <span class="top-text text-lato text-grey text-small" *ngIf="step == 0"><a (click)="stepBack()"><span
                            class="btn btn-outline-primary left-margin text-button"
                            style="border-width: 0!important;">{{ 'COMMON.TEXT_CLOSE_WINDOW' | translate}}</span></a></span>

                <span class="top-text text-lato text-grey text-small" *ngIf="step != 0"><a (click)="stepBack()"><span
                            class="btn btn-outline-primary left-margin text-button"
                            style="border-width: 0!important;">{{ 'COMMON.TEXT_GO_BACK' | translate}}</span></a></span>
            </div>
            <div class="text-center center margin-h">
                <div *ngIf="step == 0">
                    <h1 class="text-lato text-bold text-big text-darkgrey line-height-115"
                        style="margin-bottom: 50px; font-size: 38px">{{ 'PROJECT.CREATE.STEP_0_TITLE' | translate}}</h1>
                    <div style="margin-top: auto; margin-bottom: auto">
                        <img style="width: 120px;" src="./assets/images/img-placeholder.png" (click)="fileInput.click()"
                            *ngIf="selectedFile == undefined">
                        <img style="width: 120px; height: 120px; object-fit: cover; border-radius: 50%" [src]="imageSrc"
                            (click)="fileInput.click()" *ngIf="selectedFile != undefined">
                    </div>
                    <p class="text-lato text-medium text-lightgrey" style="margin-top: 23px; margin-bottom: 23px">
                        {{ 'PROJECT.CREATE.STEP_0_PICTURE_INSTRUCTIONS' | translate}}</p>
                    <form class="margin-v-39 max-w-400" [formGroup]="newProjectForm" (ngSubmit)="onSubmitProject()">
                        <div class="row">
                            <input style="display: none" type="file" (change)="onFileChanged($event)" accept="image/*"
                                #fileInput>
                            <div class="col-12" style="margin-top: 29px; margin-bottom: 0px;">
                                <input type="text" placeholder=" {{ 'PROJECT.CREATE.STEP_0_PROJECT_NAME_PLACEHOLDER' | translate}} *"
                                    class="input-text text-lato text-medium text-darkgrey" name="title"
                                    formControlName="title" autocomplete="off">
                            </div>
                            <div class="col-12" style="display:none">
                                <input type="text" placeholder="picUrl"
                                    class="input-text text-lato text-medium text-darkgrey" name="picUrl"
                                    formControlName="picUrl">
                            </div>
                            <div class="col-12" style="width: min-content" *ngIf="isValid == false">
                                <span class="text-lato text-medium" style="color: #949499; display: inline-block">
                                    {{ 'PROJECT.CREATE.STEP_0_ERROR_NAME_TOO_SHORT' | translate}}
                                </span>
                            </div>
                            <div class="col-12" style="margin-top: 72px">
                                <input class="btn btn-primary btn-big text-lato text-bold" type="submit"
                                    value="{{ 'PROJECT.CREATE.NEXT_BUTTON' | translate}}">
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="step == 1">
                    <h1 class="text-lato text-bold text-big text-darkgrey line-height-115"
                        style="margin-bottom: 31px; ; font-size: 38px">{{ 'PROJECT.CREATE.STEP_1_TITLE' | translate}}</h1>
                    <p class="text-lato text-small text-lightgrey margin-v-30">{{ 'PROJECT.CREATE.STEP_1_INSTRUCTIONS_1' | translate}}</p>
                    <p class="text-lato text-small text-lightgrey">{{ 'PROJECT.CREATE.STEP_1_INSTRUCTIONS_2' | translate}}</p>
                    <p class="text-lato text-small text-lightgrey">{{ 'PROJECT.CREATE.STEP_1_INSTRUCTIONS_3' | translate}}</p>
                    <p class="text-lato text-small text-lightgrey">{{ 'PROJECT.CREATE.STEP_1_INSTRUCTIONS_4' | translate}}</p>
                    <form class="margin-v-39" [formGroup]="newProjectForm" (ngSubmit)="onSubmitProject()">
                        <div class="row">
                            <div class="col-12">
                                <div style="display: flex; flex-direction: row;" class="max-w-400">
                                    <input style="width:calc(100% - 46px)" type="email"
                                        placeholder="{{ 'PROJECT.CREATE.STEP_1_INVITEE_EMAIL_PLACEHOLDER' | translate}}"
                                        class="input-text text-lato text-medium text-darkgrey" name="member_email"
                                        formControlName="member_email" autocomplete="off">
                                    <input
                                        style="width: 36px; height: 36px; margin-left: 10px; margin-right: 0px; padding: 0px"
                                        class="btn btn-primary btn-big text-lato text-bold" readonly="readonly"
                                        value="+" (click)="addMember()">
                                </div>
                                <div class="col-12" style="padding-left: 50px; padding-right: 50px">
                                    <div *ngIf="members.length > 0" style="border-radius: 4px;
                                            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
                                            padding-top: 37px;
                                            padding-bottom: 37px;
                                            display: flex; flex-direction: row;
                                            flex-wrap: wrap;
                                            justify-content: center;
                                            align-items: center;">
                                        <div *ngFor="let member of members">
                                            <div style="display: flex; flex-direction: row">
                                                <div class="col-auto"
                                                    style="
                                                    white-space: nowrap;
                                                    border-radius: 4px;
                                                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                                                    background-color: #f7f7f7; padding-left: 18px; padding-right: 18px; padding-top: 10px; padding-bottom: 10px; margin-left: 6px; margin-right: 6px; margin-top: 6px; margin-bottom: 6px">
                                                    <div style="display: flex; flex-direction: row; 
                                                        justify-content: center;
                                                        align-items: center;">
                                                        <div class="text-lato"
                                                            style="font-size: 15px; font-weight: bold; color: #30313e">
                                                            {{member}}
                                                        </div>
                                                        <div>
                                                            <input style="width: 20px; height: 20px; margin-left: 10px; margin-right: 0px; padding: 0px; font-size: 10px;
                                                                padding-bottom: 3px;
                                                                padding-left: 1px;"
                                                                class="btn btn-primary btn-big text-lato text-bold"
                                                                value="x" (click)="removeMember(member)">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" style="margin-top: 31px">
                                <input class="btn btn-primary btn-big text-lato text-bold" type="submit"
                                    value="{{ 'PROJECT.CREATE.STEP_2_CREATE_BUTTON' | translate}}">
                            </div>
                        </div>
                    </form>
                </div>
                
                <div *ngIf="step == 2">
                    <div style="margin-top: auto; margin-bottom: auto">
                        <img style="width: 240px;" src="./assets/images/icon-pipe-created.png">
                    </div>
                    <h1 class="text-lato text-bold text-big text-darkgrey line-height-115"
                        style="margin-left: 26.5px; margin-right: 26.5px; margin-top: 67px; margin-bottom: 31px; ; font-size: 38px">
                        {{ 'PROJECT.CREATE.STEP_3_TITLE' | translate}}</h1>
                    <p class="text-lato text-medium text-lightgrey margin-v-30">{{ 'PROJECT.CREATE.STEP_3_INSTRUCTIONS' | translate}}
                    </p>
                    <div class="row">
                        <div class="col-12" style="margin-top: 31px">
                            <input class="btn btn-primary btn-big text-lato text-bold" type="submit"
                                value="{{ 'PROJECT.CREATE.STEP_3_DASHBOARD_BUTTON' | translate}}" (click)="goToDashboard()">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<loader *ngIf="loading == true"></loader>