import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-profile-professional-profile.component.html',
  selector: 'publisher-profile-professional-profile',
  styleUrls: ['./publisher-profile-professional-profile.component.scss'],
})
export class PublisherProfileProfessionalProfileComponent implements OnInit {
  @Input('publisher') publisher: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  isEditing: boolean
  myProfileProfessionalForm: FormGroup
  isValid: boolean

  hasAwardsError: boolean
  hasCertificationsError: boolean
  hasEducationsError: boolean
  hasSpokenLanguagesError: boolean
  hasPatentsError: boolean
  hasPositionsError: boolean
  hasPublicationsError: boolean
  hasRecommendationsError: boolean
  hasSkillsError: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.myProfileProfessionalForm = this.formBuilder.group({
      awardsForm: this.formBuilder.group({
        awards: { value: '', disabled: !this.isEditing },
      }),
      certificationsForm: this.formBuilder.group({
        certifications: { value: '', disabled: !this.isEditing },
      }),
      educationsForm: this.formBuilder.group({
        educations: { value: '', disabled: !this.isEditing },
      }),
      spokenLanguagesForm: this.formBuilder.group({
        spokenLanguages: { value: '', disabled: !this.isEditing },
      }),
      patentsForm: this.formBuilder.group({
        patents: { value: '', disabled: !this.isEditing },
      }),
      positionsForm: this.formBuilder.group({
        positions: { value: '', disabled: !this.isEditing },
      }),
      publicationsForm: this.formBuilder.group({
        publications: { value: '', disabled: !this.isEditing },
      }),
      recommendationsForm: this.formBuilder.group({
        recommendations: { value: '', disabled: !this.isEditing },
      }),
      skillsForm: this.formBuilder.group({
        skills: { value: '', disabled: !this.isEditing },
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.isEditing = false

    this.initValues()
  }

  initValues() {
    if (this.hasProfileProfessionalInformation() == true) {
      this.myProfileProfessionalForm = this.formBuilder.group({
        awardsForm: this.formBuilder.group({
          awards: {
            value: this.getProfileProfessionalInformation().awards,
            disabled: !this.isEditing,
          },
        }),
        certificationsForm: this.formBuilder.group({
          certifications: {
            value: this.getProfileProfessionalInformation().certifications,
            disabled: !this.isEditing,
          },
        }),
        educationsForm: this.formBuilder.group({
          educations: {
            value: this.getProfileProfessionalInformation().educations,
            disabled: !this.isEditing,
          },
        }),
        spokenLanguagesForm: this.formBuilder.group({
          spokenLanguages: {
            value: this.getProfileProfessionalInformation().spokenLanguages,
            disabled: !this.isEditing,
          },
        }),
        patentsForm: this.formBuilder.group({
          patents: {
            value: this.getProfileProfessionalInformation().patents,
            disabled: !this.isEditing,
          },
        }),
        positionsForm: this.formBuilder.group({
          positions: {
            value: this.getProfileProfessionalInformation().positions,
            disabled: !this.isEditing,
          },
        }),
        publicationsForm: this.formBuilder.group({
          publications: {
            value: this.getProfileProfessionalInformation().publications,
            disabled: !this.isEditing,
          },
        }),
        recommendationsForm: this.formBuilder.group({
          recommendations: {
            value: this.getProfileProfessionalInformation().recommendations,
            disabled: !this.isEditing,
          },
        }),
        skillsForm: this.formBuilder.group({
          skills: {
            value: this.getProfileProfessionalInformation().skills,
            disabled: !this.isEditing,
          },
        }),
      })
    } else {
      //this.makeEditable()
    }
  }

  ngOnDestroy() {}

  hasProfileProfessionalInformation() {
    try {
      return (
        this?.publisher?.publisher?.profileProfessionalInformation != undefined
      )
    } catch (e) {
      return false
    }
  }

  getProfileProfessionalInformation() {
    if (this.hasProfileProfessionalInformation() == true)
      return this.publisher.publisher.profileProfessionalInformation
    else return undefined
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    this.myProfileProfessionalForm.enable()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.myProfileProfessionalForm.disable()
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.hasAwardsError = false
      this.hasCertificationsError = false
      this.hasEducationsError = false
      this.hasPatentsError = false
      this.hasPositionsError = false
      this.hasPublicationsError = false
      this.hasRecommendationsError = false
      this.hasSkillsError = false
      this.hasSpokenLanguagesError = false

      this.alertService.resetAlerts()

      let awards_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.awardsForm['controls'].awards
          .value
      )

      let certifications_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.certificationsForm['controls']
          .certifications.value
      )

      let educations_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.educationsForm['controls']
          .educations.value
      )

      let spoken_languages_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.spokenLanguagesForm['controls']
          .spokenLanguages.value
      )

      let patents_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.patentsForm['controls'].patents
          .value
      )

      let positions_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.positionsForm['controls']
          .positions.value
      )

      let publications_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.publicationsForm['controls']
          .publications.value
      )

      let recommendations_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.recommendationsForm['controls']
          .recommendations.value
      )

      let skills_line = this.readTextValue(
        this.myProfileProfessionalForm.controls.skillsForm['controls'].skills
          .value
      )

      if (this.isValid == true) {
        this.isValid = true

        let profile_professional_infos = {
          awards: awards_line,
          certifications: certifications_line,
          educations: educations_line,
          spokenLanguages: spoken_languages_line,
          patents: patents_line,
          positions: positions_line,
          publications: publications_line,
          recommendations: recommendations_line,
          skills: skills_line,
        }

        /*let res = await this.apiService.updateProfileInformations(
          this.publisher.publisher._id,
          profile_professional_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('')
          this.makeNotEditable()
          this.publisher.publisher = res.body.publisher
        } else {
          this.alertService.pushErrorAlert('')
        }*/
      } else {
        this.alertService.pushErrorAlert('')
      }
    } catch (e) {
      console.log(e)
      //this.alertService.pushAlert('')
    }
  }

  manageModalEvent(newValue) {
    this.showModalEvent.emit(newValue)
  }
}
