import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { Board } from 'src/app/_models/board/board.model'
import { DealColumn } from 'src/app/_models/column/deal.column.model'
import { DateHelper } from './../../../../_helpers/date.helpers'
import { Deal } from 'src/app/_models/deal/deal.model'
import { Column } from 'src/app/_models/column/column.model'

@Component({
  templateUrl: 'deal-kanban.component.html',
  selector: 'deal-kanban',
  styleUrls: ['./deal-kanban.component.scss'],
})
export class DealKanbanComponent implements OnInit {
  @Input('deals') deals: any

  constructor(private router: Router) {}

  board: Board = new Board('board', [
    new DealColumn('SELECTED', []),
    new DealColumn('DRAFT', []),
    new DealColumn('PENDING', []),
    new DealColumn('ACCEPTED', []),
    new DealColumn('REFUSED', []),
  ])

  loadDealPage(deal_id) {
    this.router.navigate(['/deals/' + deal_id])
  }

  async ngOnInit() {
    await this.getDeals()
  }

  buildDeal(dealJson: any) {
    let deal = new Deal()
    deal.id = dealJson._id
    deal._publisher = dealJson._publisher
    deal._brand = dealJson._brand
    deal._scope = dealJson._scope
    deal._opportunity = dealJson._opportunity
    deal.status = dealJson.status
    deal.proposal = dealJson.proposal

    return deal
  }

  async getDeals() {
    for (var j = 0; j < this.deals.length; j++) {
      let deal = this.buildDeal(this.deals[j])
      if (deal.status === '') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<DealColumn>this.board.columns[i]).name === 'SELECTED') {
            ;(<DealColumn>this.board.columns[i]).deals.push(deal)
          }
        }
      } else if (deal.status === 'draft') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<DealColumn>this.board.columns[i]).name === 'DRAFT') {
            ;(<DealColumn>this.board.columns[i]).deals.push(deal)
          }
        }
      } else if (deal.status === 'registered') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<DealColumn>this.board.columns[i]).name === 'PENDING') {
            ;(<DealColumn>this.board.columns[i]).deals.push(deal)
          }
        }
      } else if (deal.status === 'accepted') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<DealColumn>this.board.columns[i]).name === 'ACCEPTED') {
            ;(<DealColumn>this.board.columns[i]).deals.push(deal)
          }
        }
      } else if (deal.status === 'declined') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<DealColumn>this.board.columns[i]).name === 'REFUSED') {
            ;(<DealColumn>this.board.columns[i]).deals.push(deal)
            /*
              new Deal(
                this.deal[j]._id,
                this.deal[j].proposal.name,
                this.deal[j].proposal.description,
                DateHelper.convertDoubleToReadableDate(
                  this.deal[j].proposalDate
                ),
                DateHelper.convertDoubleToReadableDate(this.deal[j].expiryDate),
                this.deal[j].status
              )
            )*/
          }
        }
      }
    }
  }

  ngOnDestroy() {}

  getColumn(column: Column) {
    return <DealColumn>column
  }

  public convertDoubleToReadableDate(t) {
    if (t == 0) return 'N/A'
    return DateHelper.convertDoubleToReadableDate(t)
  }
}
