<!-- Modal Alert -->

<!-- Campaign name -->
<div
  *ngIf="campaignCreationService.getStep() == 0 && campaignName == undefined"
>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: block; opacity: 1"
    (click)="closeModal()"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-lato"
            id="exampleModalLongTitle"
            style="color: #ff006c"
          >
            {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModalAlert()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-lato">
          {{ 'MODAL_ALERT.MODAL_TEXT_1' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Social account -->
<div
  *ngIf="
    campaignCreationService.getStep() == 0 &&
    account == undefined &&
    campaignName != undefined
  "
>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: block; opacity: 1"
    (click)="closeModal()"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-lato"
            id="exampleModalLongTitle"
            style="color: #ff006c"
          >
            {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModalAlert()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-lato">
          {{ 'MODAL_ALERT.MODAL_TEXT_2' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Timming / Budget -->
<div *ngIf="campaignCreationService.getStep() == 2">
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: block; opacity: 1"
    (click)="closeModal()"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-lato"
            id="exampleModalLongTitle"
            style="color: #ff006c"
          >
            {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModalAlert()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-lato" *ngIf="difference < 7">
          {{ 'MODAL_ALERT.MODAL_TEXT_3_1' | translate }}
        </div>
        <div class="modal-body text-lato" *ngIf="date_start == ''">
          {{ 'MODAL_ALERT.MODAL_TEXT_3_2' | translate }}
        </div>
        <div class="modal-body text-lato" *ngIf="date_end == ''">
          {{ 'MODAL_ALERT.MODAL_TEXT_3_3' | translate }}
        </div>
        <div
          class="modal-body text-lato"
          *ngIf="budget == 0 && compensation === 'cash'"
        >
          {{ 'MODAL_ALERT.MODAL_TEXT_3_4' | translate }}
        </div>
        <div
          class="modal-body text-lato"
          *ngIf="
            (numberOfFreeProducts == 0 ||
              productDescription === '' ||
              productDescription == undefined) &&
            compensation === 'free_products'
          "
        >
          Afin de passer à l'étape suivante vous devez renseigner le nombre de
          produits gratuits disponibles et donner une description des produits
          pour les influenceurs.
        </div>
        <div
          class="modal-footer"
          *ngIf="
            (budget != 0 && compensation === 'cash') ||
            (numberOfFreeProducts != 0 && compensation === 'free_products')
          "
        >
          <button
            type="button"
            class="btn btn-secondary text-lato"
            data-dismiss="modal"
            style="background-color: #ff006c; border-color: #ff006c"
            (click)="
              closeModal(); closeModalAlert(); iUnderstand(); iUnderstandDate()
            "
          >
            {{ 'MODAL_ALERT.MODAL_OK_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Do / Dont -->
<div
  *ngIf="
    campaignCreationService.getStep() == 3 && do[0] == null && dont[0] == null
  "
>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: block; opacity: 1"
    (click)="closeModal()"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-lato"
            id="exampleModalLongTitle"
            style="color: #ff006c"
          >
            {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModalAlert()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-lato">
          {{ 'MODAL_ALERT.MODAL_TEXT_4' | translate }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary text-lato"
            data-dismiss="modal"
            style="background-color: #ff006c; border-color: #ff006c"
            (click)="closeModal(); closeModalAlert(); iUnderstand()"
          >
            {{ 'MODAL_ALERT.MODAL_OK_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Brief -->
<div *ngIf="campaignCreationService.getStep() == 4 && brief[0] == null">
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: block; opacity: 1"
    (click)="closeModal()"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      (click)="stopPropagation($event)"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-lato"
            id="exampleModalLongTitle"
            style="color: #ff006c"
          >
            {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModalAlert()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-lato">
          {{ 'MODAL_ALERT.MODAL_TEXT_5' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
