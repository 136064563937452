import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'

@Injectable()
export class ExternalRequestService {
  private isLoading = new BehaviorSubject(new Boolean(false))

  public constructor() {
    this.init()
  }

  private init() {
    this.isLoading.next(new Boolean(false))
  }

  // subscribe to modals
  public subscribeToIsLoading(): Observable<Boolean> {
    return this.isLoading.asObservable()
  }

  public setIsLoading(isLoading: Boolean) {
    this.isLoading.next(isLoading)
  }

  public isCurrentlyLoading(): Boolean {
    return this.isLoading.value
  }
}
