<div
  class="row"
  *ngIf="
    signupBufferService.getState()['step_0']['userCheckedHasAnAccount'] == false
  "
>
  <div class="col-12 d-flex flex-column hv-center v-align full-height">
    <div>
      <img class="brand-logo-w240" src="./assets/images/fns_logo.png" />
    </div>
    <h1
      id="form-title"
      class="text-lato text-bold text-big text-darkgrey line-height-115 text-center"
    >
      {{ 'SIGNUP.TITLE_LINE_1_STEP_1' | translate }}
    </h1>
    <p class="text-lato text-medium margin-v-30 text-center">
      {{ 'SIGNUP.TEXT_LINE_1_STEP_1' | translate }}<b>{{ email }}</b
      >. <br />{{ 'SIGNUP.TEXT_LINE_2_STEP_1' | translate }}
    </p>
    <div class="row text-center">
      <form class="max-w-400" [formGroup]="codeForm" (ngSubmit)="confirmCode()">
        <div class="row">
          <div class="col-12">
            <input
              type="text"
              name="confirmCode"
              id="confirmCode"
              formControlName="confirmCode"
              class="input-text text-lato text-medium text-darkgrey text-center"
              placeholder="123456"
            />
          </div>
          <div class="col-12">
            <input
              id="next-btn"
              class="btn btn-primary btn-big text-lato text-bold"
              type="submit"
              value="{{ 'SIGNUP.TEXT_BUTTON_NEXT_STEP_1' | translate }}"
            />
          </div>
          <div class="col-12">
            <p *ngIf="isValid == false" id="invalid-code">
              {{ 'SIGNUP.ERROR_STEP_1_WRONG_CODE' | translate }}
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="
    signupBufferService.getState()['step_0']['userCheckedHasAnAccount'] == true
  "
>
  <div class="col-12 d-flex hv-center flex-column v-align full-height">
    <div>
      <img class="brand-logo-w240" src="./assets/images/fns_logo.png" />
    </div>
    <h1
      class="text-lato text-bold text-big text-darkgrey line-height-115"
      id="form-title"
    >
      {{ 'SIGNUP.TITLE_LINE_1_STEP_1_NO_ACCOUNT' | translate }}
    </h1>
    <p class="text-lato text-medium margin-v-30">
      {{
        'SIGNUP.TEXT_LINE_1_STEP_1_NO_ACCOUNT' | translate: { email: email }
      }}
      <br />{{ 'SIGNUP.TEXT_LINE_1_STEP_2_NO_ACCOUNT' | translate }}
    </p>
    <div class="row text-center">
      <div class="col-12">
        <a [routerLink]="['/login']"
          ><input
            class="btn btn-primary btn-big text-lato text-bold text-uppercase"
            type="submit"
            value="{{
              'SIGNUP.BUTTON_TO_PLATFORM_1_STEP_2_NO_ACCOUNT' | translate
            }}"
        /></a>
      </div>
    </div>
  </div>
</div>
