import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../_services'
import { CacheService } from './../../../_services/cache.service'
import { DataService } from './../../../_services/data.service'

import { ProjectHelper } from './../../../_helpers/project.helper'

@Component({
  templateUrl: 'left-menu.component.html',
  selector: 'left-menu',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  name: any
  picUrl: any

  showMenu: boolean
  static showDropDown: any

  filter: any

  projects: any
  project_index: any
  project_id: any

  campaigns: any

  static staticCampaignOptions: any
  static projectId: any
  hasToReloadOptions: any

  id: string
  numberOfSavedCampaigns: Number
  private sub: any

  //me: any
  isAdmin: boolean

  getLink = LeftMenuComponent.getLink

  data: any
  me: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private cacheService: CacheService,
    private dataService: DataService
  ) {
    this.isAdmin = false
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false
    }
  }

  async ngOnInit() {
    this.projects = this.dataService.getProjects()
    this.me = this.dataService.getMe()

    this.name = ''
    this.projects = []
    this.campaigns = []
    this.numberOfSavedCampaigns = 0

    this.hasToReloadOptions = false

    this.project_index = 0

    this.sub = this.route.params.subscribe(async (params) => {
      if (params['projectId']) {
        this.id = params['projectId']
        if (this.id !== LeftMenuComponent.projectId)
          this.hasToReloadOptions = true
        LeftMenuComponent.projectId = this.id
      }

      await this.init()
    })
  }

  async ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe()
  }

  async init() {
    this.filter = ''

    if (
      LeftMenuComponent.staticCampaignOptions == undefined ||
      this.hasToReloadOptions == true
    )
      LeftMenuComponent.staticCampaignOptions = []

    this.showMenu = false

    if (
      LeftMenuComponent.showDropDown == undefined &&
      this.hasToReloadOptions == true
    )
      LeftMenuComponent.showDropDown = []

    //this.getCampaignOptions();

    if (this.data) {
      this.projects = this.data
    } else {
      this.projects = this.dataService.getProjects()
      //this.projects = await this.cacheService.getProjects();
    }
    for (var i = 0; i < this.projects.length; i++) {
      if (this.projects[i]._id == this.id) {
        this.project_index = i
        break
      }
    }

    if (this.me != undefined) {
      this.isAdmin = ProjectHelper.isRegularOwnerOrAdmin(
        this.me._id,
        this.projects[this.project_index]
      )
    } else {
      var res = await this.apiService.getMyProfile()
      if (res.body) {
        this.me = res.body
        this.isAdmin = ProjectHelper.isRegularOwnerOrAdmin(
          this.me.user._id,
          this.projects[this.project_index]
        )
      }
    }

    this.name = this.projects[this.project_index].title
    this.picUrl = this.projects[this.project_index].pictureURL
    this.campaigns = this.projects[this.project_index].campaigns

    var newNumberOfCampaignsisDifferent =
      this.numberOfSavedCampaigns !=
      LeftMenuComponent.staticCampaignOptions.length

    var hasToGenerate = LeftMenuComponent.staticCampaignOptions.length == 0

    if (
      LeftMenuComponent.staticCampaignOptions.length == 0 ||
      this.hasToReloadOptions == true ||
      newNumberOfCampaignsisDifferent == true
    ) {
      var l = this.campaigns.length
      this.numberOfSavedCampaigns = l
      for (var i = 0; i < l; i++) {
        var option = { visible: false, crisis: false, nbOfNotifications: 0 }
        LeftMenuComponent.staticCampaignOptions.push(option)
      }
    }

    if (hasToGenerate == true || this.hasToReloadOptions == true) {
      LeftMenuComponent.showDropDown = []
      var s = this.campaigns.length
      this.numberOfSavedCampaigns = s
      for (var j = 0; j < s; j++) {
        var detail = { visible: false }
        LeftMenuComponent.showDropDown.push(detail)
      }
    }
  }

  showSubMenu(i, toShow) {
    LeftMenuComponent.staticCampaignOptions[i].visible = toShow
    LeftMenuComponent.showDropDown[i].visible = toShow
  }

  static exportShowSubMenu(i, toShow) {
    LeftMenuComponent.staticCampaignOptions[i].visible = toShow
    LeftMenuComponent.showDropDown[i].visible = toShow
  }

  toggleMenu() {
    this.showMenu = !this.showMenu
  }

  toAProject(project_id) {
    this.showMenu = false
    this.router.navigate(['/projects/' + project_id + '/dashboard'])
  }

  toNewProject() {
    this.router.navigate(['/projects/create'])
  }

  onSearchChange(target: EventTarget) {
    let value = (target as HTMLInputElement).value
    if (value.length > 0) this.filter = value
    else this.filter = ''
  }

  createCampaign() {
    this.router.navigate([
      '/projects/' + this.projects[this.project_index]._id + '/campaign/new',
    ])
  }

  numberOfCampaigns() {
    if (this.campaigns) return this.campaigns.length
    else return '0'
  }

  static getLink(campaign, subroute) {
    return (
      '/projects/' +
      LeftMenuComponent.projectId +
      '/campaigns/' +
      campaign._id +
      '/' +
      subroute
    )
  }

  getCampaignOptions(i) {
    if (
      LeftMenuComponent.staticCampaignOptions != undefined &&
      LeftMenuComponent.staticCampaignOptions.length > i
    )
      return LeftMenuComponent.staticCampaignOptions[i]
    else return { visible: false, crisis: false, nbOfNotifications: 0 }
  }

  getShowDropDown(i) {
    if (
      LeftMenuComponent.showDropDown != undefined &&
      LeftMenuComponent.showDropDown.length > i
    )
      return LeftMenuComponent.showDropDown[i]
    else return { visible: false }
  }

  getCampaigns(filter) {
    if (filter == undefined || filter === '') return this.campaigns
    else {
      var result = []
      for (var i = 0; i < this.campaigns.length; i++) {
        if (this.campaigns[i].title && filter) {
          if (
            this.campaigns[i].title
              .toLowerCase()
              .indexOf(filter.toLowerCase()) != -1
          ) {
            result.push(this.campaigns[i])
          }
        }
      }
      return result
    }
  }

  goToDashboard() {
    this.router.navigate(['projects/' + this.id + '/dashboard'])
  }

  goToSettings() {
    this.router.navigate(['projects/' + this.id + '/settings'])
  }

  goToBrand() {
    this.router.navigate(['projects/' + this.id + '/brand'])
  }

  goToBrandAuthority() {
    this.router.navigate(['projects/' + this.id + '/authority'])
  }

  goToBilling() {
    this.router.navigate(['projects/' + this.id + '/billing'])
  }

  goToTimeline() {
    this.router.navigate(['projects/' + this.id + '/timeline'])
  }

  goToBrief(campaign) {
    this.router.navigate([
      'projects/' + this.id + '/campaigns/' + campaign._id + '/brief',
    ])
  }

  goToScalers(campaign) {
    this.router.navigate([
      'projects/' + this.id + '/campaigns/' + campaign._id + '/scalers',
    ])
  }

  goToAnalytics(campaign) {
    this.router.navigate([
      'projects/' + this.id + '/campaigns/' + campaign._id + '/analytics',
    ])
  }

  goToPublications(campaign) {
    this.router.navigate([
      'projects/' + this.id + '/campaigns/' + campaign._id + '/publications',
    ])
  }
}
