import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Campaign } from '../../_models/campaign/campaign.model'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersBillingAPIService {
  constructor(private http: HttpClient) {}

  async updateBillingInformations(publisherId, billingInfos) {
    var url = BACKEND_URL + '/api/v1/publishers/' + publisherId + '/billing/'

    var res = await this.http
      .post<any>(url, billingInfos, { observe: 'response' })
      .toPromise()
    return res
  }
}
