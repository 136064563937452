import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CurrencyHelper } from 'src/app/_helpers/referential/currency.helper'
import { APIService, StorageManagementService } from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'brand-billing-history.component.html',
  selector: 'brand-billing-history',
  styleUrls: ['./brand-billing-history.component.scss'],
})
export class BrandBillingHistoryComponent implements OnInit {
  @Input('brand') brand: any

  bills: any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private storageManagementService: StorageManagementService
  ) {}

  async ngOnInit() {
    this.getBills()
  }

  getBills() {
    this.bills = []
  }
}
