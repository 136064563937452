import { APIService } from './../_services/api.service'

export class FakerHelper {
  static refreshInterval = undefined

  static campaign = undefined
  static campaign_id = undefined

  static setRefreshInterval() {
    if (FakerHelper.campaign != undefined) {
      if (FakerHelper.refreshInterval)
        clearInterval(FakerHelper.refreshInterval)

      this.refreshInterval = undefined

      this.refreshInterval = setInterval(() => {
        FakerHelper.refreshCampaign()
      }, 15000)
    }
  }

  static initScalersForCampaign() {
    FakerHelper.i_order = 0
    FakerHelper.orders = FakerHelper.orders.sort(function (a, b) {
      return 0.5 - Math.random()
    })
  }

  static refreshCampaign() {
    if (FakerHelper.i_order < FakerHelper.orders.length) {
      var budget = FakerHelper.generateRandomBudget()
      if (FakerHelper.campaign['type'] === 'find_ambassadors') budget = 0

      FakerHelper.campaign['scalers']['profiles']['potential'].push({
        profile: FakerHelper.orders[FakerHelper.i_order],
        analysis: { matching_score: FakerHelper.generateRandomMatch() },
        proposal: {
          timestamp: 1597587449000,
          budget: { value: budget, currency: 'EUR' },
          publications: [
            {
              type: 'post',
              channel: 'linkedin',
              quantity: FakerHelper.getRandomNumber(1, 3),
            },
            {
              type: 'post',
              channel: 'website',
              quantity: FakerHelper.getRandomNumber(1, 3),
            },
          ],
        },
      })
      FakerHelper.campaign['scalers']['metrics']['total_scalers'] =
        FakerHelper.campaign['scalers']['metrics']['total_scalers'] +
        FakerHelper.getRandomNumber(1, 8)
      FakerHelper.campaign['scalers']['metrics']['total_answers'] =
        FakerHelper.campaign['scalers']['metrics']['total_answers'] + 1
      FakerHelper.i_order++
    }
  }

  static i_order = 0
  static orders = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
  ]

  static scalers = [
    {
      _id: 1,
      display_name: 'Lou Thornton',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url:
        'https://media-exp1.licdn.com/dms/image/C4D03AQHWUa7FEwoiHg/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=GhMQPLuNhh-lE41Jal37-DHZO-BbVbLAeBYGs8k4Cfw',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 2,
      display_name: 'Sean Cooke',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url:
        'https://media-exp1.licdn.com/dms/image/C4D03AQHCs_vYcTNwEw/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=ojanh5xQmD9P5ZDCq_nUvR8xfZ-6q_TwDNoAdbIyDts',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 3,
      display_name: 'Gawen Hawkins',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url:
        'https://media-exp1.licdn.com/dms/image/C4E03AQEyAs2ZC6pt3g/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=V5c3ZfYwBhipHemMRIN_DnZ2-EqZ8elHbCx2nz7v-HM',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 4,
      display_name: 'Donal Connolly',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url:
        'https://media-exp1.licdn.com/dms/image/C5103AQHlMqEtdp86aw/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=t0aZZBT7hFGGRmM5KIisc9xOo69blQgAjDs4zZASTIw',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 5,
      display_name: 'Leonie Sampson',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/68.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 6,
      display_name: 'Lia Riggs',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/57.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 7,
      display_name: 'Jasmine Patterson',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/66.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 8,
      display_name: 'Adrian Woodward',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/31.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 9,
      display_name: 'William Marsh',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/20.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 10,
      display_name: 'Katherine Banks',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/69.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 11,
      display_name: 'Miriam Dixon',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/59.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 12,
      display_name: 'Bernice Chambers',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/13.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 13,
      display_name: 'Brittany Twinsocks',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/5.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 14,
      display_name: 'Karl Stephens',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/5.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 15,
      display_name: 'Joseph Rose',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/7.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 16,
      display_name: 'Joe Adams',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/9.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 17,
      display_name: 'Gary Fernandez',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/10.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 18,
      display_name: 'Jacob Reid',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/11.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 19,
      display_name: 'Brent Weaver',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/67.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 20,
      display_name: 'Adrian Obrien',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/46.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 21,
      display_name: 'Jeffery Chavez',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/men/28.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 22,
      display_name: 'Katherine Peters',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/28.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 23,
      display_name: 'Elaine Morales',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/31.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 24,
      display_name: 'Ava Lane',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/11.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
    {
      _id: 25,
      display_name: 'Lena Lucas',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
      pic_url: 'https://randomuser.me/api/portraits/women/17.jpg',
      networks: [
        { channel: 'web', url: 'https://www.foxynerds.studio' },
        { channel: 'linkedin', url: 'https://www.foxynerds.studio' },
        { channel: 'twitter', url: 'https://www.foxynerds.studio' },
      ],
    },
  ]

  static generateRandomBudget() {
    return 125 + 25 * FakerHelper.getRandomNumber(1, 8)
  }

  static generateRandomMatch() {
    var random = FakerHelper.getRandomNumber(0, 100)
    if (random < 50) return 'A+'
    else if (random < 75) return 'A'
    else if (random < 85) return 'A-'
    else if (random < 90) return 'B+'
    else if (random < 96) return 'B'
    else return 'B-'
  }

  static getRandomNumber(first, last) {
    return Math.floor(Math.random() * (last - first + 1)) + first
  }

  static getStandardCommunity() {
    return {
      followers: '16.7K',
      follows: '198',
      hashtags: ['#b2bmarketing', '#productivity'],
      job: 'Brand enabler, Podcast animator',
      language: 'English',
      biography:
        'Strategic advisor matches your future! Enabling your international presence!',
      location: 'London, UK',
    }
  }

  static getScaler(id) {
    for (var i = 0; i < FakerHelper.scalers.length; i++) {
      if (FakerHelper.scalers[i]._id == id) return FakerHelper.scalers[i]
    }
    return undefined
  }

  static getScalerTwitterName(name) {
    var str = name.replace(/\s+/g, '')
    return str
  }

  static getScalerLinkdedInName(name) {
    var str = name.replace(/\s+/g, '-')
    return str.toLowerCase()
  }

  static getCampaignData(campaign, type) {
    var result = {
      type: type,
      campaign: campaign,
      scalers: {
        metrics: {
          total_scalers: 0,
          total_answers: 0,
        },
        profiles: {
          potential: [],
          confirmed: [],
        },
      },
      publications: [],
      metrics: {},
    }

    if (campaign === '5f306cc3e2ac4d97809566e0') {
      result['type'] = 'find_ambassadors'
      var metrics = {
        total_scalers: 17,
        total_answers: 10,
      }

      var confirmedScalers = []
      var potentialScalers = []

      confirmedScalers.push({
        profile: 1,
        analysis: { matching_score: 'A+' },
        proposal: {
          timestamp: 1597587449000,
          budget: { value: '0', currency: 'EUR' },
          publications: [
            { type: 'post', channel: 'linkedin', quantity: 3 },
            { type: 'post', channel: 'website', quantity: 1 },
          ],
        },
      })
      confirmedScalers.push({
        profile: 2,
        analysis: { matching_score: 'A' },
        proposal: {
          timestamp: 1597587449000,
          budget: { value: '0', currency: 'EUR' },
          publications: [
            { type: 'post', channel: 'linkedin', quantity: 2 },
            { type: 'post', channel: 'website', quantity: 1 },
          ],
        },
      })
      confirmedScalers.push({
        profile: 3,
        analysis: { matching_score: 'A+' },
        proposal: {
          timestamp: 1597587449000,
          budget: { value: '0', currency: 'EUR' },
          publications: [
            { type: 'post', channel: 'linkedin', quantity: 3 },
            { type: 'post', channel: 'website', quantity: 1 },
          ],
        },
      })
      confirmedScalers.push({
        profile: 4,
        analysis: { matching_score: 'A+' },
        proposal: {
          timestamp: 1597587449000,
          budget: { value: '0', currency: 'EUR' },
          publications: [{ type: 'post', channel: 'linkedin', quantity: 4 }],
        },
      })

      result['scalers']['metrics'] = metrics
      result['scalers']['profiles']['potential'] = potentialScalers
      result['scalers']['profiles']['confirmed'] = confirmedScalers

      result['publications'].push({
        timestamp: '23/04/2020',
        scaler: 1,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 376,
        conversions: 24,
        price: 0,
      })

      result['publications'].push({
        timestamp: '27/04/2020',
        scaler: 1,
        network: 'web',
        link: 'https://www.foxynerds.studio',
        impressions: 215,
        conversions: 9,
        price: 0,
      })

      result['publications'].push({
        timestamp: '07/05/2020',
        scaler: 2,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 373,
        conversions: 30,
        price: 0,
      })

      result['publications'].push({
        timestamp: '09/05/2020',
        scaler: 1,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 790,
        conversions: 89,
        price: 0,
      })

      result['publications'].push({
        timestamp: '17/05/2020',
        scaler: 2,
        network: 'web',
        link: 'https://www.foxynerds.studio',
        impressions: 421,
        conversions: 12,
        price: 0,
      })

      result['publications'].push({
        timestamp: '28/05/2020',
        scaler: 1,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 376,
        conversions: 24,
        price: 0,
      })

      result['publications'].push({
        timestamp: '06/06/2020',
        scaler: 2,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 376,
        conversions: 24,
        price: 0,
      })

      result['publications'].push({
        timestamp: '09/06/2020',
        scaler: 2,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 392,
        conversions: 23,
        price: 0,
      })

      result['publications'].push({
        timestamp: '15/06/2020',
        scaler: 3,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 436,
        conversions: 13,
        price: 0,
      })

      result['publications'].push({
        timestamp: '18/06/2020',
        scaler: 4,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 689,
        conversions: 22,
        price: 0,
      })

      result['publications'].push({
        timestamp: '23/06/2020',
        scaler: 3,
        network: 'web',
        link: 'https://www.foxynerds.studio',
        impressions: 376,
        conversions: 8,
        price: 0,
      })

      result['publications'].push({
        timestamp: '27/06/2020',
        scaler: 4,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 376,
        conversions: 44,
        price: 0,
      })

      result['publications'].push({
        timestamp: '04/07/2020',
        scaler: 3,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 129,
        conversions: 24,
        price: 0,
      })

      result['publications'].push({
        timestamp: '07/07/2020',
        scaler: 3,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 290,
        conversions: 14,
        price: 0,
      })

      result['publications'].push({
        timestamp: '11/07/2020',
        scaler: 4,
        network: 'linkedin',
        link: 'https://www.foxynerds.studio',
        impressions: 402,
        conversions: 13,
        price: 0,
      })
    } else if (campaign == FakerHelper.campaign_id) {
      result = FakerHelper.campaign
    } else {
      FakerHelper.campaign_id = campaign
      FakerHelper.initScalersForCampaign()
      FakerHelper.campaign = result
      FakerHelper.setRefreshInterval()
    }

    return result
  }

  static getBrandAuthorityData(project) {
    var result = {
      web_traffic: {
        organic: 0,
        ads: 0,
        referrals: 0,
      },
      traffic_history: {
        timestamps: [
          '17,05',
          '24/05',
          '31/05',
          '07/06',
          '14/06',
          '21/06',
          '28/06',
          '05/07',
          '12/07',
          '19/07',
          '26/07',
          '02/08',
          '09/08',
          '16/08',
        ],
        organic: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ads: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        referrals: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      social_media: {
        impressions: 0,
        mentions: 0,
      },
      analysis: {
        content: {
          available_content: {
            value: 0,
            evolution: 0,
          },
          referrals: {
            value: 0,
            evolution: 0,
          },
        },
        social_media: {
          number_of_publications: {
            value: 0,
            evolution: 0,
          },
          reach: {
            value: 0,
            evolution: 0,
          },
          impressions: {
            value: 0,
            evolution: 0,
          },
          referrals: {
            value: 0,
            evolution: 0,
          },
        },
      },
      keywords: [],
      hashtags: [],
      advocates: [],
    }

    if (project === '5f2fac28e9c2fa35c34de851') {
      result['web_traffic'] = {
        organic: 400,
        ads: 250,
        referrals: 700,
      }

      result['social_media'] = {
        impressions: 38287,
        mentions: 27,
      }

      const yOrganic = [
        24,
        40,
        80,
        100,
        117,
        173,
        222,
        300,
        357,
        490,
        450,
        381,
        367,
        400,
      ]
      const yAds = [
        0,
        0,
        80,
        124,
        134,
        0,
        180,
        222,
        204,
        201,
        274,
        260,
        240,
        250,
      ]
      const yReferrals = [
        0,
        300,
        250,
        335,
        634,
        812,
        504,
        484,
        548,
        621,
        523,
        690,
        668,
        700,
      ]
      const xData = [
        '17,05',
        '24/05',
        '31/05',
        '07/06',
        '14/06',
        '21/06',
        '28/06',
        '05/07',
        '12/07',
        '19/07',
        '26/07',
        '02/08',
        '09/08',
        '16/08',
      ]

      result['traffic_history'] = {
        timestamps: xData,
        organic: yOrganic,
        ads: yAds,
        referrals: yReferrals,
      }

      result['analysis'] = {
        content: {
          available_content: {
            value: 21,
            evolution: 3,
          },
          referrals: {
            value: 15,
            evolution: 3,
          },
        },
        social_media: {
          number_of_publications: {
            value: 135,
            evolution: 5,
          },
          reach: {
            value: 5345,
            evolution: 0.0345,
          },
          impressions: {
            value: 27654,
            evolution: 0.0324,
          },
          referrals: {
            value: 7,
            evolution: 3,
          },
        },
      }

      result['keywords'].push({
        description: { identifier: 'home office' },
        activity: {
          overall: '480.3K',
          me: '0.75%',
          competition: 'Very Intense',
          cpc: '$1.52',
        },
      })
      result['keywords'].push({
        description: { identifier: 'work at home' },
        activity: {
          overall: '409.9K',
          me: '0.25%',
          competition: 'Very Intense',
          cpc: '$2.55',
        },
      })

      result['hashtags'].push({
        description: { network: 'twitter', identifier: '#HomeOffice' },
        activity: { overall: '156 434', me: 323, competition: 'Intense' },
      })
      result['hashtags'].push({
        description: { network: 'twitter', identifier: '#WorkAtHome' },
        activity: { overall: '34 698', me: 323, competition: 'Moderate' },
      })
      result['hashtags'].push({
        description: { network: 'linkedin', identifier: '#HomeOffice' },
        activity: { overall: '45 098', me: 323, competition: 'Intense' },
      })
      result['hashtags'].push({
        description: { network: 'linkedin', identifier: '#WorkAtHome' },
        activity: { overall: '12 038', me: 323, competition: 'Moderate' },
      })

      result['advocates'].push({
        description: {
          network: 'linkedin',
          identifier: 'Lou Thornton',
          pic_url:
            'https://media-exp1.licdn.com/dms/image/C4D03AQHWUa7FEwoiHg/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=GhMQPLuNhh-lE41Jal37-DHZO-BbVbLAeBYGs8k4Cfw',
        },
        referrals: { links: 17, traffic: 134 },
      })
      result['advocates'].push({
        description: {
          network: 'linkedin',
          identifier: 'Sean Cooke',
          pic_url:
            'https://media-exp1.licdn.com/dms/image/C4D03AQHCs_vYcTNwEw/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=ojanh5xQmD9P5ZDCq_nUvR8xfZ-6q_TwDNoAdbIyDts',
        },
        referrals: { links: 13, traffic: 89 },
      })
      result['advocates'].push({
        description: {
          network: 'linkedin',
          identifier: 'Gawen Hawkins',
          pic_url:
            'https://media-exp1.licdn.com/dms/image/C4E03AQEyAs2ZC6pt3g/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=V5c3ZfYwBhipHemMRIN_DnZ2-EqZ8elHbCx2nz7v-HM',
        },
        referrals: { links: 9, traffic: 78 },
      })
      result['advocates'].push({
        description: {
          network: 'linkedin',
          identifier: 'Donal Connolly',
          pic_url:
            'https://media-exp1.licdn.com/dms/image/C5103AQHlMqEtdp86aw/profile-displayphoto-shrink_400_400/0?e=1603324800&v=beta&t=t0aZZBT7hFGGRmM5KIisc9xOo69blQgAjDs4zZASTIw',
        },
        referrals: { links: 7, traffic: 57 },
      })
    }

    return result
  }

  static getLastWeekTotalTraffic(id) {
    var data = FakerHelper.getBrandAuthorityData(id)
    if (data == undefined || data.web_traffic == undefined) return 0
    else
      return (
        data.web_traffic.organic +
        data.web_traffic.ads +
        data.web_traffic.referrals
      )
  }

  static getLastWeekOrganicTraffic(id) {
    var data = FakerHelper.getBrandAuthorityData(id)
    if (data == undefined || data.web_traffic == undefined) return 0
    else return data.web_traffic.organic
  }

  static getLastWeekAdsTraffic(id) {
    var data = FakerHelper.getBrandAuthorityData(id)
    if (data == undefined || data.web_traffic == undefined) return 0
    else return data.web_traffic.ads
  }

  static getLastWeekReferralsTraffic(id) {
    var data = FakerHelper.getBrandAuthorityData(id)
    if (data == undefined || data.web_traffic == undefined) return 0
    else return data.web_traffic.referrals
  }

  static getLastWeekOrganicTrafficPercentage(id) {
    var x = FakerHelper.getLastWeekOrganicTraffic(id)
    var y = FakerHelper.getLastWeekTotalTraffic(id)
    if (y == 0) return 0
    else return Math.round((x / y) * 1000) / 1000
  }

  static getLastWeekAdsTrafficPercentage(id) {
    var x = FakerHelper.getLastWeekAdsTraffic(id)
    var y = FakerHelper.getLastWeekTotalTraffic(id)
    if (y == 0) return 0
    else return Math.round((x / y) * 1000) / 1000
  }

  static getLastWeekReferralsTrafficPercentage(id) {
    var x = FakerHelper.getLastWeekReferralsTraffic(id)
    var y = FakerHelper.getLastWeekTotalTraffic(id)
    if (y == 0) return 0
    else return Math.round((x / y) * 1000) / 1000
  }

  static acceptOffer(campaign, scaler) {
    if (campaign === FakerHelper.campaign_id) {
      var i = -1
      var offer = undefined
      for (
        var j = 0;
        j < FakerHelper.campaign['scalers']['profiles']['potential'].length;
        j++
      ) {
        if (
          scaler ==
          FakerHelper.campaign['scalers']['profiles']['potential'][j]['profile']
        ) {
          i = j
          offer = FakerHelper.campaign['scalers']['profiles']['potential'][j]
          break
        }
      }

      if (i != -1) {
        FakerHelper.campaign['scalers']['profiles']['confirmed'].push(offer)
        FakerHelper.campaign['scalers']['profiles']['potential'].splice(i, 1)
      }
    }
  }

  static declineOffer(campaign, scaler) {
    if (campaign === FakerHelper.campaign_id) {
      var i = -1
      for (
        var j = 0;
        j < FakerHelper.campaign['scalers']['profiles']['potential'].length;
        j++
      ) {
        if (
          scaler ==
          FakerHelper.campaign['scalers']['profiles']['potential'][j]['profile']
        ) {
          i = j
          break
        }
      }

      if (i != -1)
        FakerHelper.campaign['scalers']['profiles']['potential'].splice(i, 1)
    }
  }

  static cancelOffer(campaign, scaler) {
    if (campaign === FakerHelper.campaign_id) {
      var i = -1
      for (
        var j = 0;
        j < FakerHelper.campaign['scalers']['profiles']['confirmed'].length;
        j++
      ) {
        if (
          scaler ==
          FakerHelper.campaign['scalers']['profiles']['confirmed'][j]['profile']
        ) {
          i = j
          break
        }
      }

      if (i != -1)
        FakerHelper.campaign['scalers']['profiles']['confirmed'].splice(i, 1)
    }
  }
}
