import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { ActivatedRoute } from '@angular/router'

import { DataService } from '../../../../_services/data.service'
import { CampaignCreationBufferService } from '../../../../_services/buffers/campaign.creation.buffer.service'
import { CampaignReferentialService } from '../../../../_services/referential/campaign.referential.service'
import { ExternalRequestService } from '../../../../_services/external.requests.service'

import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: 'campaign_create.component.html',
  styleUrls: ['./campaign_create.component.scss'],
})
export class CampaignCreateComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any

  project_id: any
  id: string

  returnUrl: string

  creation: any

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private translateService: TranslateService,
    public campaignCreationService: CampaignCreationBufferService,
    public campaignReferentialService: CampaignReferentialService,
    public externalRequestService: ExternalRequestService
  ) {
  }

  async ngOnInit() {

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'

    this.sub = this.route.params.subscribe(async (params) => {
      this.project_id = params['projectId']
      let project = this.dataService.getProject(this.project_id)

      this.campaignCreationService.setProject(project)

      if (params['campaignId']) {
        this.id = params['campaignId']
        let campaign = this.dataService.getCampaign(this.id)
        this.campaignCreationService.loadCampaign(campaign)
      } else {

      }
    })

  }

  ngAfterViewInit() { }
  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe()
  }
}
