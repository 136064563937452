/**
 *
 *
 * @export
 * @enum {string}
 */
export enum STATE {
  IS_VALID = 'is_valid',
  HAS_WARNINGS = 'has_warnings',
  HAS_ERRORS = 'has_errors',
}
