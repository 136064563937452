import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { DateHelper } from '../../../../_helpers/date.helpers'
import { CampaignActionHelper } from '../../../../_helpers/referential/campaign_action.helper'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersDealsAPIService } from 'src/app/_services'

import * as moment from 'moment'

@Component({
  templateUrl: 'deal-details.component.html',
  selector: 'deal-details',
  styleUrls: ['./deal-details.component.scss'],
})
export class DealDetailsComponent implements OnInit {
  @Input('deal') deal: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  showModal: boolean

  newActionForm: FormGroup
  offerForm: FormGroup
  isValid: boolean

  publisher: any

  availableActions: any[]
  actions: any[]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: PublishersDealsAPIService,
    private alertService: AlertService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)

    this.availableActions = CampaignActionHelper.getCampaignActions()
    this.actions = []

    this.publisher = this.route.snapshot.data.user.body

    this.offerForm = this.formBuilder.group({
      campaignNameForm: this.formBuilder.group({
        campaignName: { value: '', disabled: true },
      }),
      descriptionForm: this.formBuilder.group({
        description: { value: '', disabled: false },
      }),
      amount: { value: '', disabled: false },
      expiryDate: [undefined],
    })

    this.newActionForm = this.formBuilder.group({
      action: '',
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()

    console.log(this.deal)
  }

  initValues() {
    this.offerForm = this.formBuilder.group({
      campaignNameForm: this.formBuilder.group({
        campaignName: { value: this.deal.proposal.name, disabled: true },
      }),
      descriptionForm: this.formBuilder.group({
        description: { value: '', disabled: false },
      }),
      amount: { value: '', disabled: false },
      expiryDate: [undefined],
    })
  }

  ngOnDestroy() {}

  makeEditable() {
    this.alertService.resetAlerts()
    //this.myProfileBioForm.enable()
    return
  }

  makeNotEditable() {
    //this.myProfileBioForm.disable()
    return
  }

  getCreatedDate() {
    let convertedDate = DateHelper.convertDoubleToReadableDate(
      this.deal.createdAt
    )
    return convertedDate
  }

  getExpireDate() {
    let convertedDate = DateHelper.convertDoubleToReadableDate(
      this.deal.proposal.expiryDate
    )
    return convertedDate
  }

  getDealStatus() {
    if (this.deal.status === 'draft') return 'DRAFT'
    else if (this.deal.status === 'proposed') return 'PROPOSED'
    else if (this.deal.status === 'accepted') return 'ACCEPTED'
    else if (this.deal.status === 'declined') return 'DECLINED'
    else if (this.deal.status === 'finished') return 'FINISHED'
    else if (this.deal.status === 'cancelled') return 'CANCELLED'

    return undefined
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  changeExpiryDate(event) {}

  async updateOffer() {
    try {
      this.isValid = true
      this.alertService.resetAlerts()

      let description = this.readTextValue(
        this.offerForm.controls.descriptionForm['controls'].description.value
      )

      if (
        description != undefined &&
        this.offerForm.controls.descriptionForm['controls'].description.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Description can not be empty')
      }

      let amount = this.offerForm.controls.amount
      if (
        amount != undefined &&
        this.offerForm.controls.amount.valid == false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Amount not valid')
      }

      let expiry_date = new Date(
        this.offerForm.controls.expiryDate.value
      ).getTime()

      if (
        expiry_date != undefined &&
        this.offerForm.controls.expiryDate.valid == false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Expiry date not valid')
      }

      if (this.isValid == true) {
        this.isValid = true

        let offer_infos = {
          name: this.deal.proposal.name,
          description: description,
          expiryDate: expiry_date,
          status: 'proposed',
          scope: this.deal.proposal._id,
        }

        let res = await this.apiService.updateDeal(
          this.publisher.publisher._id,
          this.deal.proposal._id,
          offer_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your deal has been saveed')
          this.makeNotEditable()
        } else {
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  updateAmount(event) {
    let value = event.target.value

    let options = {
      maximumFractionDigits: 2,
      currency: 'EUR',
      style: 'currency',
      currencyDisplay: 'symbol',
    }

    /*this.offerForm.controls.amount.setValue(
      value || value === 0
        ? this.localStringToNumber(value).toLocaleString(undefined, options)
        : ''
    )*/
  }

  localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ''))
  }

  addCampaignAction() {
    let newActionType = this.newActionForm.get('action').value

    if (newActionType && newActionType !== '') {
      let action = this.findActionByKey(newActionType)
      if (action != undefined) {
        this.actions.push({
          type: action,
          form: this.formBuilder.group({
            amount: { value: 0, disabled: false },
            frequency: { value: '' },
            descriptionForm: this.formBuilder.group({
              description: { value: '', disabled: false },
            }),
          }),
        })
      }

      this.newActionForm.get('action').setValue('')
    }
  }

  prepareModalForUpdate() {
    this.offerForm = this.formBuilder.group({
      campaignNameForm: this.formBuilder.group({
        campaignName: { value: this.deal.proposal.name, disabled: true },
      }),
      descriptionForm: this.formBuilder.group({
        description: { value: this.deal.proposal.description, disabled: false },
      }),
      amount: { value: '', disabled: false },
      expiryDate: [new Date(this.deal.proposal.expiryDate)],
    })
  }

  removeCampaignAction(action) {
    var i = this.actions.indexOf(action)
    this.actions.splice(i, 1)
  }

  findActionByKey(key) {
    for (let i = 0; i < this.availableActions.length; i++) {
      if (key === this.availableActions[i].key) return this.availableActions[i]
    }

    return undefined
  }

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  updateDeal() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
    this.prepareModalForUpdate()
  }
}
