import { Component, OnInit, Input, HostListener } from '@angular/core'

import { ActivatedRoute, Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

import { ModalService } from '../../../../_services'
import { DataService } from '../../../../_services/data.service'
import { AuthenticationService } from '../../../../_services/authentication/authentication.service'

@Component({
  templateUrl: 'publisher-nav-bar.component.html',
  selector: 'publisher-nav-bar',
  styleUrls: ['./publisher-nav-bar.component.scss'],
})
export class PublisherNavBarComponent implements OnInit {
  campaignId: string
  private sub: any

  showMenu: boolean
  showSomething: boolean

  user: any
  publisher: any

  modalSub: any

  profileData: any
  profileSub: any
  userFirstName: any
  userLastName: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private modalService: ModalService,
    public alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {
    this.campaignId = undefined
    this.showMenu = false
    this.showSomething = false
    this.user = undefined

    this.publisher = this.route.snapshot.data['user'].body
  }

  async ngOnInit() {
    //this.user = await this.cacheService.getMe()
    this.user = this.dataService.getMe()
    /*this.sub = this.route.params.subscribe(params => {
            if (params['pipeId'])
                this.campaignId = params['pipeId'];

            if (this.campaignId != undefined)
                this.navTitle = "Pipe";
            else
                this.navTitle = "Dashboard";

        });*/

    this.modalSub = this.modalService.getModals().subscribe(async (object) => {
      if (object === 'profile') {
        //this.user = await this.cacheService.getMe()
        this.user = this.dataService.getMe()
      }
    })

    this.userFirstName = this.user.infos.firstname
    this.userLastName = this.user.infos.lastname

    this.profileSub = this.dataService
      .getProfileData()
      .subscribe(async (object) => {
        this.profileData = object
        this.userFirstName = this.profileData.userName.firstName
        this.userLastName = this.profileData.userName.lastName
      })
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
    if (this.modalSub) this.modalSub.unsubscribe()
    if (this.profileSub) this.profileSub.unsubscribe()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  toggleUserMenu(event) {
    this.showMenu = !this.showMenu
    event.preventDefault()
    event.stopPropagation()
  }

  closeUserMenu() {
    this.showMenu = false
  }

  confirmLogout() {
    this.toggleUserMenu(undefined)
    event.preventDefault()
    var modal = document.querySelector('.modal') // assuming you have only 1
    var html = document.querySelector('html')
    modal.classList.add('is-active')
    html.classList.add('has-activated-background')
  }

  logoutConfirmed() {
    //this.logout()
    console.log('logout confirmed')
    this.authenticationService.logout()
  }

  logoutCancelled() {
    var modal = document.querySelector('.modal')
    var html = document.querySelector('html')
    modal.classList.remove('is-active')
    html.classList.remove('has-activated-background')
  }

  showThing(thing) {
    this.showMenu = false
    this.modalService.toggleModal(thing)
  }

  goToProfile() {
    this.router.navigate(['/me'])
  }

  goToSettings() {
    this.router.navigate(['/settings'])
  }

  goToBill() {
    this.router.navigate(['/billing'])
  }

  goToSupport() {}

  loadOpportunities() {
    this.router.navigate(['/opportunities'])
  }

  loadDeals() {
    this.router.navigate(['/deals'])
  }

  loadCollaborations() {
    this.router.navigate(['/collaborations'])
  }

  loadDashboard() {
    this.router.navigate(['/dashboard'])
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    // your click logic
    this.closeUserMenu()
  }

  getFirstLetter(string) {
    return string.substr(0, 1)
  }

  shouldDisplayAvatarLetters() {
    if (
      this.publisher?.publisher?.infos?.profilePictureURL != null &&
      this.publisher?.publisher?.infos?.profilePictureURL !== '' &&
      this.publisher?.publisher?.infos?.profilePictureURL !==
        './assets/images/blank.png'
    ) {
      return false
    }
    return true
  }

  whatIsTheTab() {
    let result = this.router.routerState.snapshot.url
    if (result.includes('/dashboard') == true) return 'dashboard'
    if (result.includes('/opportunities') == true) return 'opportunities'
    if (result.includes('/deals') == true) return 'deals'
    if (result.includes('/collaborations') == true) return 'collaborations'

    return undefined
  }

  /*getProfileData() {
    this.dataService.profileData.subscribe({
      next(value) {
        console.log(value)
      },
    })
  }*/
}
