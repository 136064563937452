import { Deserializable } from './../deserializable/deserializable.model'

/**
 * User states for the person who will be logged in the platform.
 * As a result, user will contain all the informations about user identity: first name, last name, email...
 * The model should not contain any information about user credentials (password)
 * @export
 * @class User
 * @implements {Deserializable}
 */
export class User implements Deserializable {
  /**
   * id is a string corresponding to user id as in database
   * if empty, it means the user has not yet been registered in Foxy Nerds database
   * @type {string}
   * @memberof User
   */
  id: string

  /**
   * email is a string containing user email
   * as per construction, email format is user@domain.ext
   * registered users can not have an empty email
   * @type {string}
   * @memberof User
   */
  email: string

  /**
   * firstName is a string containing user's first name
   * registered users can not have an empty first name
   * @type {string}
   * @memberof User
   */
  firstName: string

  /**
   * lastName is a string containing user's last name
   * registered users can not have an empty last name
   * @type {string}
   * @memberof User
   */
  lastName: string

  /**
   * pictureProfileUrl is a string containing the url where the user's picture profile is accessible
   * this url should be publicly available
   * pictureProfileUrl can be empty
   * @type {string}
   * @memberof User
   */
  pictureProfileUrl: string

  /**
   * Deserialize a User.
   * @param {*} input
   * @return {*}
   * @memberof User
   */
  deserialize(input: any) {
    Object.assign(this, input)
    return this
  }
}
