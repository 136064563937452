import {
  Directive,
  OnInit,
  ElementRef,
  Input,
  HostBinding,
} from '@angular/core'

import { ThemeService } from './theme.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Theme } from './symbols'

@Directive({
  selector: '[app-theme]',
})
export class ThemeDirective implements OnInit {
  @Input('app-theme') @HostBinding('attr.theme') theme: string

  private unsubscribe = new Subject()
  constructor(private _themeService: ThemeService) {}

  ngOnInit() {
    this.theme = this._themeService.getActiveTheme().name
    this._themeService.themeChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((theme: Theme) => this.updateTheme(theme))
  }

  updateTheme(theme: Theme) {
    this.theme = theme.name
  }
}
