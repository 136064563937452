import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { StorageManagementService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'

@Component({
  templateUrl: 'advertiser-workspaces.component.html',
  styleUrls: ['./advertiser-workspaces.component.scss'],
})
export class AdvertiserWorkspacesComponent implements OnInit, OnDestroy {
  user: any
  workspaces: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    public storageManagementService: StorageManagementService
  ) {
    this.user = this.route.snapshot.data['user'].body
    this.workspaces = this.route.snapshot.data['user'].body.projects
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngOnDestroy() {}

  createANewWorkspace() {
    this.router.navigate(['/workspaces/create'])
  }

  selectWorkspace(workspace) {
    this.storageManagementService.setCurrentBrandspaceId(workspace._id)
    this.router.navigate(['/workspaces/' + workspace._id + '/keywords/plans'])
  }
}
