import jwt_decode from 'jwt-decode'

import { environment } from './../../environments/environment'

import { USER_ROLE } from './referential/user.role'

export class UserRoleHelper {
  static getPlatformUserRole() {
    return environment.role
  }

  static getTokenUserRole() {
    try {
      let currentUser = localStorage.getItem('currentUser')
      let user = JSON.parse(currentUser)
      let decoded_token = jwt_decode(user.token)

      if (decoded_token['role'] === USER_ROLE.PUBLISHER)
        return USER_ROLE.PUBLISHER
      else if (decoded_token['role'] === USER_ROLE.ADVERTISER)
        return USER_ROLE.ADVERTISER
      else if (decoded_token['role'] === USER_ROLE.ADMIN) return USER_ROLE.ADMIN
      else return USER_ROLE.VISITOR
    } catch (e) {
      console.log(e)
      return USER_ROLE.VISITOR
    }
  }
}
