import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { Opportunity } from '../../_models/opportunity/opportunity.model'
import { PublishersOpportunitiesAPIService } from '../../_services/api/publishers.opportunities.api.service'

@Injectable()
export class PublishersOpportunitiesResolver implements Resolve<Opportunity[]> {
  constructor(
    private apiService: APIService,
    private opportunitiesApiService: PublishersOpportunitiesAPIService
  ) {}
  async resolve(): Promise<Opportunity[]> {
    try {
      let user = await this.apiService.getMyProfile()
      let publisherId = user?.body?.publisher?._id
      let request = await this.opportunitiesApiService.getOpportunities(
        publisherId,
        undefined
      )

      if (request.body != undefined && request.body.opportunities != undefined)
        return request.body.opportunities
      return []
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
