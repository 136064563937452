import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { APIService } from '../../../../../_services'
import { SignupBufferService } from './../../../../../_services/buffers/signup.buffer.service'
import { ExternalRequestService } from './../../../../../_services/external.requests.service'

@Component({
  selector: 'signup-step-1',
  templateUrl: 'signup-step-1.component.html',
  styleUrls: ['./signup-step-1.component.scss'],
})
export class SignupStep1Component implements OnInit, AfterViewInit, OnDestroy {
  codeForm: FormGroup
  isValid: boolean
  email: string

  constructor(
    private formBuilder: FormBuilder,
    private apiService: APIService,
    public signupBufferService: SignupBufferService,
    private externalRequestService: ExternalRequestService
  ) {
    this.email = signupBufferService.userBeingCreated().getUser().email
  }

  ngOnInit() {
    this.isValid = true
    this.codeForm = this.formBuilder.group({
      confirmCode: ['', Validators.required],
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  async confirmCode() {
    try {
      this.externalRequestService.setIsLoading(true)

      // stop here if form is invalid
      if (this.codeForm.invalid) {
        this.externalRequestService.setIsLoading(false)
        return
      }

      var code = this.codeForm.controls.confirmCode.value

      await this.signupBufferService.confirmNewWorkspaceInvitation(code)

      this.externalRequestService.setIsLoading(false)

      if (
        this.signupBufferService.getState()['step_1']['codeChecked'] == true
      ) {
        if (
          this.signupBufferService.getState()['step_1']['codeIsValid'] == true
        ) {
          this.isValid = true
          this.signupBufferService.stepForward()
        } else {
          this.isValid = false
        }
      } else {
        this.isValid = false
      }
    } catch (error) {
      this.externalRequestService.setIsLoading(false)
      this.isValid = false
    }
  }

  stepBack() {
    this.signupBufferService.stepBack()
  }
}
