<div
  class="box one-edge-shadow"
  id="item"
  [ngClass]="{
    'item-completed': task._task.status === 'completed',
    'item-selected': isSelectedTask == true
  }"
>
  <div class="box-title text-small">
    <div class="item-margin-left">
      <div
        class="badge-priority hv-center text-align-center"
        [ngClass]="{
          'priority-no': task._task.priority === 'not_set',
          'priority-low': task._task.priority === 'low',
          'priority-medium': task._task.priority === 'medium',
          'priority-high': task._task.priority === 'high'
        }"
      >
        {{ task.task.priority }}
      </div>
    </div>
  </div>
  <div class="box-title text-small">
    <div class="item-margin-left">
      <span scroll="no" class="text-darkgrey">{{ task.name }}</span
      ><!--span scroll="no">
        <img
          *ngIf="task?.task?.assignee != undefined"
          class="campaign-picture"
          [src]="assignee"
        />
        <img
          *ngIf="task?.task?.assignee == undefined"
          class="campaign-picture"
          src="../../../../assets//images/img-placeholder.png"
        />
      </span-->
    </div>
  </div>
  <div class="box-title text-small">
    <div class="item-margin-left">
      <div class="completed" *ngIf="task._task.status === 'created'">
        {{ 'Incompleted' }}
      </div>
      <div class="incompleted" *ngIf="task._task.status === 'completed'">
        {{ 'Completed' }}
      </div>
    </div>
  </div>
  <!--div class="opportunity-date text-xxsmall" *ngIf="task.dueDate != undefined">
    Due date:
    {{ convertDoubleToReadableDate(task.dueDate) }}
  </div-->
  <div class="footer item-margin-left">
    <span>
      <img
        *ngIf="task?.task?.assignee != undefined"
        class="campaign-picture"
        [src]="assignee"
      />
      <img
        *ngIf="task?.task?.assignee == undefined"
        class="campaign-picture"
        src="../../../../assets//images/img-placeholder.png"
      />
    </span>
    <span
      class="opportunity-date text-xxsmall hv-center"
      *ngIf="task.task.dueDate != undefined"
      [ngClass]="[pastDueDate ? 'due-date-past' : 'due-date']"
    >
      {{ convertDoubleToReadableDate(task.task.dueDate) }}
    </span>
    <div class="message-img">
      <!--span
        class="badge badge-position"
        [ngClass]="{
          'badge-draft': task.priority === '',
          'badge-proposed': task.priority === 'low',
          'badge-negociated': task.priority === 'medium',
          'badge-selected': task.priority === 'high',
        }"
        style="text-transform: uppercase"
      >
        {{ task.priority }}
      </span-->
    </div>
  </div>
</div>
