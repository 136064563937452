<alerts [alerts]="alertService.getAlerts()"></alerts>
<div
  class="container-fluid full-height bg-default"
  [style.overflow]="getOverflow()"
  [style.height]="getHeight()"
>
  <div class="row">
    <div class="col-12 d-flex d-lg-none" style="padding-right: 0px">
      <mobile-nav-bar
        [full]="true"
        title="{{ 'COMMON.DASHBOARD' | translate }}"
        style="width: 100%"
      ></mobile-nav-bar>
    </div>
  </div>
  <div class="row v-margin h-margin">
    <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
      <left-menu></left-menu>
    </div>
    <div class="col-12 col-lg-9 d-block" id="content">
      <div>
        <nav-bar
          class="display-none d-none d-lg-block"
          [full]="true"
          title="{{ 'COMMON.DASHBOARD' | translate }}"
        ></nav-bar>
        <div class="right-row no-padding" style="margin-top: 20px"></div>
        <div class="row no-margin margin-top-25">
          <div
            class="col-12 light-box"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-6 no-margin"
                style="display: flex; align-items: center"
              >
                <span
                  class="text-lato text-bold text-medium card-title no-margin"
                  style="color: #ffffff"
                  >{{ projects[project_index].campaigns.length }}
                  {{ 'COMMON.CAMPAIGN_S' | translate }}</span
                >
              </div>
              <div
                class="col-6 no-margin text-right"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <span
                  class="btn text-lato text-bold card-title no-margin"
                  style="
                    background-color: #ff006c;
                    color: white;
                    margin-left: 5px !important;
                    margin-right: 5px !important;
                    font-size: 13px;
                    text-transform: uppercase;
                  "
                  (click)="createCampaign()"
                  >{{ 'LEFT_MENU.CREATE_NEW_CAMPAIGN' | translate }}</span
                >
              </div>
            </div>
          </div>

          <!--div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #FFFFFF">
                                    {{ 'Brand Awareness' | translate }}</span>
                            </div>
                            <div class="col-12 margin-top-25">
                                <div class="row">
                                    <div class="col-12 tab-4" style="color: #FFFFFF; text-align: left;">
                                        {{projects[project_index].brand.name}} is actually seen as an <span
                                            style="color: #ff006c;">Expert</span><br><br>
                                        Experts have typically a low-to-medium traffic to their website and have
                                        low-to-medium interactions on social networks<br>
                                        They have regularly shared content on very specific topics for a short period of
                                        time and this content is shared by industry experts and early adopters.
                                        <br><br>
                                        Keeping sharing high added-value content and increasing brand awareness allow
                                        <span style="color: #ff006c">Expert</span> to turn into <span
                                            style="color: #ff006c">Key Player</span>
                                        <br><br>You should run <span
                                        style="color: #ff006c;">an awareness campaign</span> to increase your brand authority and gain high qualified referral traffic
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        >
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-12 no-margin">
                                <span class="text-lato text-bold text-medium card-title"
                                    style="color: #ffffff">{{ 'Brand Authority' | translate }}</span>
                            </div>
                            <div class="col-12 margin-top-25">
                                <div class="row">
                                    <div class="col-6 tab-4 upper-left">
                                        <h1 class="text-lato text-bold text-small card-title">
                                            {{ 'TOTAL TRAFFIC' | translate}}
                                        </h1>
                                        <div class="row no-margin">
                                            <div class="col-12 text-lato text-small no-padding" style="color: #FFFFFF">
                                                <div class="margin-details">
                                                    <span style="color : #ffad00;font-size: 40px">{{utils.prettifyNumber(getTotalTraffic()).value}}</span>
                                                    <span style="font-size: 24px">{{utils.prettifyNumber(getTotalTraffic()).unit}}</span>
                                                </div>
                                                <div>
                                                    <span> visitors last week</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-6 tab-4 upper-right">
                                        <h1 class="text-lato text-bold text-small card-title">
                                            {{ 'TRAFFIC FROM REFFERALS' | translate}}
                                        </h1>
                                        <div class="row no-margin">
                                            <div class="col-12 text-lato text-small no-padding" style="color: #FFFFFF">
                                                <div class="margin-details" >
                                                    <span style="color : #ffad00;font-size: 40px">{{utils.prettifyNumber(getReferralsTraffic()).value}}</span>
                                                    <span style="font-size: 24px">{{utils.prettifyNumber(getReferralsTraffic()).unit}}</span>
                                                </div>
                                                <div>
                                                    <span> visitors last week</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 tab-4 lower-left">

                                        <h1 class="text-lato text-bold text-small card-title margin-details">
                                            {{ 'SOCIAL MEDIA WEEKLY IMPRESSIONS' | translate}}
                                        </h1>
                                        <div class="row no-margin">
                                            <div class="col-12 text-lato text-small no-padding" style="color: #FFFFFF">
                                                <div class="margin-details" >
                                                    <span style="color : #ffad00;font-size: 40px">{{utils.prettifyNumber(getSocialImpressions()).value}}</span>
                                                    <span style="font-size: 24px">{{utils.prettifyNumber(getSocialImpressions()).unit}}</span>
                                                </div>
                                                <div>
                                                    <span> weekly impressions</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 tab-4 lower-right">
                                        <h1 class="text-lato text-bold text-small card-title">
                                            {{ 'AVERAGE WEEKLY MENTIONS' | translate}}
                                        </h1>
                                        <div class="row no-margin">
                                            <div class="col-12 text-lato text-small no-padding" style="color: #FFFFFF">
                                                <div class="margin-details" >
                                                    <span style="color : #ffad00;font-size: 40px">{{utils.prettifyNumber(getSocialMentions()).value}}</span>
                                                    <span style="font-size: 24px">{{utils.prettifyNumber(getSocialMentions()).unit}}</span>
                                                </div>
                                                <div style="color: #FFFFFF">
                                                    <span> weekly mentions</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div-->

          <div
            class="col-12 col-md-6 col-lg-6 light-box margin-top-25 cursor"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngFor="let campaign of campaigns; let i = index"
            [class.left]="i % 2 == 0"
            [class.right]="i % 2 != 0"
            [class.dashboard-mobile-left]="i % 2 == 0"
            [class.dashboard-mobile-right]="i % 2 != 0"
            [routerLink]="[getCampaignLink(campaign)]"
            (click)="getShowMenu(i, true)"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-7 no-margin">
                <span
                  class="text-lato text-bold text-medium card-title"
                  style="color: #ffffff"
                  >{{ campaign.title }}</span
                >
              </div>
              <div
                class="col-5 no-margin text-lato text-bold text-medium text-right card-title"
                style="padding-right: 0px"
              >
                <span
                  class="text-lato text-bold text-medium text-right"
                  style="color: #ff006c"
                  >{{ getCampaignState(campaign) | translate }}
                  <img
                    style="width: 28px; margin-bottom: 2px"
                    src="./assets/images/noun_dots.png"
                  />
                </span>
              </div>
              <div class="col-12 margin-top-25">
                <div class="row">
                  <div class="col-6 tab-4 upper-left">
                    <h1 class="text-lato text-bold text-small card-title">
                      {{ 'PROJECT.DASHBOARD.BUDGET_TITLE' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-small no-padding"
                        style="color: #ffffff"
                      >
                        <div
                          class="margin-details"
                          *ngIf="campaign.compensation === 'free'"
                        >
                          <span style="color: #ffad00; font-size: 40px">0</span>
                          <span style="font-size: 24px">€</span>
                        </div>
                        <div
                          class="margin-details"
                          *ngIf="campaign.compensation !== 'free'"
                        >
                          <span style="color: #ffad00; font-size: 40px">{{
                            budget[i].remaining
                          }}</span>
                          <span style="font-size: 24px">%</span>
                        </div>
                        <div>
                          <span
                            >{{
                              'PROJECT.DASHBOARD.BUDGET_REMAINING_TEXT'
                                | translate
                            }}
                          </span>
                          <span
                            style="color: #ffad00"
                            *ngIf="campaign.compensation === 'free'"
                            >N/A</span
                          >
                          <span
                            style="color: #ffad00"
                            *ngIf="
                              campaign.compensation === 'cash' ||
                              campaign.compensation == undefined
                            "
                            >{{ campaign.total_budget }}
                            {{
                              preferencesManager.getPreferredCurrency().symbol
                            }}</span
                          >
                          <span
                            style="color: #ffad00"
                            *ngIf="campaign.compensation === 'free_products'"
                            >{{ campaign.total_budget }} produit(s)</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 tab-4 upper-right" *ngIf="date[i].day == 0">
                    <h1 class="text-lato text-bold text-small card-title">
                      {{ 'PROJECT.DASHBOARD.BEGINNING_TITLE' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-small no-padding"
                        style="color: #ffffff"
                      >
                        <div class="margin-details">
                          <span style="color: #ffad00; font-size: 40px">{{
                            remaining_day[i].number
                          }}</span>
                          <span style="font-size: 24px"
                            >&nbsp;{{ 'COMMON.DAYS' | translate }}</span
                          >
                        </div>
                        <div>
                          {{ campaign.end_date }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 tab-4 upper-right" *ngIf="date[i].day == 1">
                    <h1 class="text-lato text-bold text-small card-title">
                      {{ 'PROJECT.DASHBOARD.END_TITLE' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-small no-padding"
                        style="color: #ffffff"
                      >
                        <div class="margin-details">
                          <span style="color: #ffad00; font-size: 40px">{{
                            remaining_day[i].number
                          }}</span>
                          <span style="font-size: 24px"
                            >&nbsp;{{ 'COMMON.DAYS' | translate }}</span
                          >
                        </div>
                        <div>
                          {{ campaign.end_date }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 tab-4 upper-right" *ngIf="date[i].day == 2">
                    <h1 class="text-lato text-bold text-small card-title">
                      {{ 'PROJECT.DASHBOARD.STATUS_TITLE' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-small no-padding"
                        style="color: #ffffff"
                      >
                        <div class="margin-details">
                          <span
                            style="
                              color: #ffad00;
                              font-size: 30px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                            >{{
                              'PROJECT.DASHBOARD.STATUS_CLOSED' | translate
                            }}</span
                          >
                        </div>
                        <div>
                          {{ campaign.end_date }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6 tab-4 lower-left">
                    <h1
                      class="text-lato text-bold text-small card-title margin-details"
                    >
                      {{ 'PROJECT.DASHBOARD.PUBLICATIONS_TITLE' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-bold text-small card-details margin-details"
                      >
                        <span *ngIf="campaign.status !== 'finished'">{{
                          campaign.publications.length
                        }}</span>
                        <span *ngIf="campaign.status === 'finished'">15</span>
                      </div>
                      <div
                        class="text-lato text-bold text-small text-white"
                        style="margin: auto; margin-bottom: 0px"
                        *ngIf="campaign.status !== 'finished'"
                      >
                        + 0
                        {{
                          'PROJECT.DASHBOARD.NEW_PUBLICATIONS_TEXT' | translate
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 tab-4 lower-right">
                    <h1
                      class="text-lato text-bold text-small card-title"
                      *ngIf="campaign.status !== 'finished'"
                    >
                      {{
                        'PROJECT.DASHBOARD.WEEKLY_IMPRESSIONS_TITLE' | translate
                      }}
                    </h1>
                    <h1
                      class="text-lato text-bold text-small card-title"
                      *ngIf="campaign.status === 'finished'"
                    >
                      {{ 'TOTAL IMPRESSIONS' | translate }}
                    </h1>
                    <div class="row no-margin">
                      <div
                        class="col-12 text-lato text-small no-padding margin-details"
                      >
                        <span
                          class="card-details"
                          *ngIf="campaign.status !== 'finished'"
                          >0</span
                        >
                        <span
                          class="card-details"
                          *ngIf="campaign.status === 'finished'"
                          >187</span
                        >
                        <span style="color: #ffffff; font-size: 24px">K</span>
                      </div>
                      <div
                        class="col-12 text-lato text-small no-padding"
                        *ngIf="campaign.status !== 'finished'"
                      >
                        <!--<span class="progress-impression">&#9650;- 12%</span>-->
                        <!--span class="progress-impression">&#9660;- 12%</span-->
                        <span class="progress-impression">{{
                          'COMMON.NOT_AVAILABLE' | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal projectId="{{ id }}"></modal>
