import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { Conversation } from 'src/app/_models/conversation/conversation.model'

import { APIService } from 'src/app/_services'
import { AdvertisersConversationsAPIService } from '../api/advertisers.conversations.api.service'
import { ConversationSocketService } from '../sockets/conversations.socket.service'

@Injectable()
export class ConversationsSynchronisationService {
  //private conversations = new BehaviorSubject<Conversation[]>([])
  private currentConversationIndex: number

  constructor(
    private apiService: APIService,
    private advertisersConversationsAPIService: AdvertisersConversationsAPIService,
    private conversationSocketService: ConversationSocketService
  ) {
    this.initService()
  }

  initService() {
    this.currentConversationIndex = 0
  }

  disconnect() {
    this.conversationSocketService.disconnect()
  }

  /*  async updateConversations(conversations: any[]) {
    let currentConversations = []
    for (let i = 0; i < conversations.length; i++) {
      let conversation = Conversation.buildConversation(conversations[i])
      currentConversations.push(conversation)
    }

    this.getConversations().next(currentConversations)
    await this.conversationSocketService.joinSocketConversations(
      this.getConversations().getValue()
    )
  }*/

  getConversations(): BehaviorSubject<Conversation[]> {
    return this.conversationSocketService.getConversations()
  }

  getConversation(): BehaviorSubject<Conversation> {
    return this.conversationSocketService.getConversation()
  }

  createConversation(relationship, me) {
    let timestamp = moment().valueOf()
    let conversation = Conversation.buildConversationDataForRegistration(
      relationship,
      me,
      timestamp
    )
    this.conversationSocketService.createConversation(conversation, timestamp)
  }

  async updateConversations(conversations: any[]) {
    let newConversations = []
    for (let i = 0; i < conversations?.length; i++) {
      let conversation = new Conversation()
      conversation = Conversation.buildConversation(conversations[i])
      newConversations.push(conversation)
    }

    this.getConversations().next(newConversations)
    await this.conversationSocketService.joinConversationTasks(
      this.getConversations().getValue()
    )
  }

  selectConversation(index) {
    this.currentConversationIndex = index
    this.conversationSocketService
      .getConversation()
      .next(this.getConversations().getValue()[this.currentConversationIndex])
  }

  getConversationEventAuthor(conversationId: string, authorId: string) {
    return this.getConversations()
      .getValue()
      .filter((conversation) => conversation.id === conversationId)[0]
      .participants.filter((participant) => {
        return participant.user._id === authorId
      })[0]
  }

  async sendTextEvent(message) {
    console.log('sending sync')
    await this.conversationSocketService.sendTextEvent(message)
  }
}
