<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Patents</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addInformation()"></add-button>
      </div>
    </div>
    <div
      class="row center text-darkgrey information-section"
      *ngFor="let information of getInformations()"
    >
      <div *ngIf="information == ''" class="no-position">
        You don't have any patents
      </div>
      <div class="col-12 text-medium text-bold no-padding">
        {{ information.title }}
        <edit-button (click)="updateInformation(information)"></edit-button>
      </div>
      <div class="col-12 text-small no-padding">
        Delevered by {{ information.office }} {{ information.status }} - session
        {{ getDate(information) }} - number {{ information.number }}
      </div>
      <div class="col-12 text-small no-padding">
        Summary: {{ information.summary }}
      </div>
      <div class="col-12 text-small no-padding">
        Inventors: {{ information.inventors }}
      </div>
      <div class="col-12 text-small no-padding">Url: {{ information.url }}</div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddInformation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddInformation"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    *ngIf="selectedData == undefined"
                    class="modal-title text-lato"
                    id="modalAddInformationTitle"
                  >
                    {{ 'Add a patent' | translate }}
                  </h5>
                  <h5
                    *ngIf="selectedData != undefined"
                    class="modal-title text-lato"
                    id="modalAddInformationTitle"
                  >
                    {{ 'Update or delete a patent' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="profileDataForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Patent title</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Patent title"
                      name="title"
                      relatedControlName="title"
                      formGroupName="titleForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Office name</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Office name"
                      name="office"
                      relatedControlName="office"
                      formGroupName="officeForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-6 field">
                  <div class="input-title">Date</div>
                  <mat-form-field
                    class="input-text text-lato text-darkgrey container-field"
                  >
                    <input
                      class="text-xsmall"
                      matInput
                      [matDatepicker]="pickerDate"
                      name="date"
                      formControlName="date"
                      (dateChange)="changeDate($event)"
                      autocomplete="off"
                    />

                    <mat-placeholder class="placeholder"
                      >Start date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-6 field">
                  <div class="input-title">Status</div>
                  <select
                    id="status"
                    class="form-control custom-select input-text text-lato text-medium text-darkgrey"
                    name="status"
                    formControlName="status"
                    style="width: calc(100% - 46px)"
                    (change)="resetStates()"
                  >
                    <option value="" disabled selected>
                      {{ 'Status' | translate }}
                    </option>
                    <option>Pending</option>
                    <option>Granted</option>
                    <!--option
                      *ngFor="let status of statusArray"
                      [value]="status.id"
                    >
                      {{ status.name | translate }}
                    </option-->
                  </select>
                </div>

                <div class="col-6 text-lato field">
                  <div class="input-title">Inventors</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Inventors"
                      name="inventors"
                      relatedControlName="inventors"
                      formGroupName="inventorsForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-6 text-lato field">
                  <div class="input-title">Number</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Number"
                      name="number"
                      relatedControlName="number"
                      formGroupName="numberForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Url</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Url"
                      name="url"
                      relatedControlName="url"
                      formGroupName="urlForm"
                      [parentGroup]="profileDataForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your patent have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your patent was not added, please try again
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your patent have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your patent was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              *ngIf="selectedData == undefined"
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="saveOrUpdateInformation(); closeModal()"
            >
              {{ 'SAVE' | translate }}
            </button>
            <div class="row double-btn" *ngIf="selectedData != undefined">
              <button
                type="button"
                class="col-6 btn btn-primary two-btn-left-radius text-lato btn-text"
                (click)="saveOrUpdateInformation(); closeModal()"
              >
                {{ 'UPDATE' | translate }}
              </button>
              <button
                type="button"
                class="col-6 btn btn-delete two-btn-right-radius text-lato btn-text"
                (click)="confirmation()"
              >
                {{ 'DELETE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirmation -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showAskConfirmation == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalConfirmation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddinformation"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato text-align-center"
                    id="modalAddInformationTitle"
                  >
                    {{
                      'are you sure you want to delete this information ?'
                        | translate
                    }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row confirmation-btn">
            <button
              type="button"
              class="col-6 btn btn-primary two-btn-left-radius text-lato btn-text"
              (click)="deleteInformation(selectedData); closeModal()"
            >
              {{ 'YES' | translate }}
            </button>
            <button
              type="button"
              class="col-6 btn btn-delete two-btn-right-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'NO' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
