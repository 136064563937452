<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunities-title">
      <!--publisher-section-intro
        title="{{ 'OPPORTUNITIES' | translate }}"
        description="{{
          'Here is a list of current campaigns. Click on a campaign to have details about the underlying brand and its expectations.'
            | translate
        }}"
      ></publisher-section-intro-->
      <publisher-section-intro
        title="{{ 'KEYWORD_PLAN.PAGE_TITLE' | translate }}"
        description=" "
      ></publisher-section-intro>
      <div style="max-width: 100%; margin: auto">
        <div
          class="display-block col-direction section-container justify-content-center"
        >
          <div
            class="col-12 one-edge-shadow form-background-color"
            style="
              padding-left: 40px;
              padding-right: 40px;
              padding-bottom: 40px;
            "
          >
            <div
              id="brand-description"
              class="padding-top-15 d-flex flex-column"
            >
              <div class="d-flex flex-row">
                <div
                  class="mr-auto input-title text-lato text-pink text-large text-bold"
                  style="font-size: 20px !important"
                >
                  {{ getAudienceNameText(plan) }}
                </div>
                <div>
                  <div class="dropdown">
                    <button
                      class="btn text-lato text-bold card-title no-margin btn-primary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="
                        background-color: #ff006c;
                        color: white;
                        font-size: 13px;
                        margin-left: 20px !important;
                      "
                    >
                      {{ 'KEYWORD_PLAN.DROPDOWN_EDIT' | translate }}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      style="font-size: 13px"
                    >
                      <a class="dropdown-item" (click)="editPlan()">{{
                        'KEYWORD_PLAN.ACTION_EDIT_PLAN' | translate
                      }}</a>
                      <a class="dropdown-item" (click)="clonePlan()">{{
                        'KEYWORD_PLAN.ACTION_CLONE_PLAN' | translate
                      }}</a>
                      <a class="dropdown-item" (click)="deletePlan()">{{
                        'KEYWORD_PLAN.ACTION_DELETE_PLAN' | translate
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center padding-bottom-15">
              <div class="col-12 padding-top-15">
                <div class="input-title text-bold text-small">
                  {{ 'KEYWORD_PLAN.SUMMARY_LANGUAGE_TITLE' | translate }}
                </div>
                <div class="left-text text-xsmall text-input">
                  <!-- prettier-ignore -->
                  <div class="text-brand campaign-brief">{{ getAudienceLanguageText(plan) }}</div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center padding-bottom-15">
              <div class="col-12 padding-top-15">
                <div class="input-title text-bold text-small">
                  {{ 'KEYWORD_PLAN.SUMMARY_LOCATION_TITLE' | translate }}
                </div>
                <div class="left-text text-xsmall text-input">
                  <!-- prettier-ignore -->
                  <div class="text-brand campaign-brief">{{ getAudienceLocationText(plan) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="opportunities-wrappers">
      <div
        class="max-w-80pt"
        style="margin: auto; margin-bottom: 20px; margin-top: 30px"
      >
        <publisher-section-intro
          title="{{ 'KEYWORD_PLAN.SEO_DATA_SECTION_TITLE' | translate }}"
          description=" "
        ></publisher-section-intro>
      </div>
      <div class="display-block container-list" style="margin-top: 0">
        <div class="one-edge-shadow section-container-inner">
          <div class="row">
            <div class="col-3 figure-box">
              <div style="text-align: center">
                <div class="text-small text-pink metrics-up">
                  {{
                    'KEYWORD_PLAN.SEO_DATA_SUMMARY_NB_OF_KEYWORDS' | translate
                  }}
                </div>
                <div class="text-xlarge metrics-down">
                  {{ plan?.keywords?.length }}
                </div>
              </div>
            </div>
            <div class="col-3 figure-box">
              <div style="text-align: center">
                <div class="text-small text-pink metrics-up">
                  {{
                    'KEYWORD_PLAN.SEO_DATA_SUMMARY_TOTAL_SEARCHES' | translate
                  }}
                </div>
                <div class="text-xlarge metrics-down">
                  {{ getLastMonthTotalSearch() | number: '1.0-0' }}
                  <span *ngIf="isNaN(getLastMonthTotalVariation())">(N/A)</span
                  ><span *ngIf="!isNaN(getLastMonthTotalVariation())"
                    >({{ getLastMonthTotalVariation() > 0 ? '+' : null
                    }}{{
                      getLastMonthTotalVariation() | percent: '1.0-3'
                    }})</span
                  >
                </div>
              </div>
            </div>
            <div class="col-3 figure-box">
              <div style="text-align: center">
                <div class="text-small text-pink metrics-up">
                  {{
                    'KEYWORD_PLAN.SEO_DATA_SUMMARY_AVERAGE_COMPETITION'
                      | translate
                  }}
                </div>
                <div class="text-xlarge metrics-down">
                  {{ getAverageCompetition() | number: '1.0-4' }}
                </div>
              </div>
            </div>
            <div class="col-3 figure-box">
              <div style="text-align: center">
                <div class="text-small text-pink metrics-up">
                  {{ 'KEYWORD_PLAN.SEO_DATA_SUMMARY_AVERAGE_CPC' | translate }}
                </div>
                <div class="text-xlarge metrics-down">
                  {{ getAverageCpc() | number: '1.0-4' }}&nbsp;$
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="display-block container-list"
        style="margin-top: 40px"
        *ngIf="getAudienceKeywords().length > 0"
      >
        <div
          class="col-12 one-edge-shadow form-background-color"
          style="padding-left: 0px; padding-right: 0px"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="clickable"
                  (click)="sortKeywordsBy('name')"
                >
                  <div>
                    {{
                      'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_KEYWORD' | translate
                    }}
                    <span *ngIf="sortingInformations.key === 'name'"
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="clickable"
                  (click)="sortKeywordsBy('last_month_search')"
                >
                  <div>
                    {{
                      'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_LAST_MONTH_SEARCH'
                        | translate
                    }}
                    <span
                      *ngIf="sortingInformations.key === 'last_month_search'"
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="clickable d-none d-xl-table-cell"
                  (click)="sortKeywordsBy('average_monthly_search')"
                >
                  <div>
                    {{
                      'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_AVERAGE_MONTHLY_SEARCHES'
                        | translate
                    }}
                    <span
                      *ngIf="
                        sortingInformations.key === 'average_monthly_search'
                      "
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="clickable"
                  (click)="sortKeywordsBy('cpc')"
                >
                  <div>
                    {{ 'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_CPC' | translate }}
                    <span *ngIf="sortingInformations.key === 'cpc'"
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="clickable"
                  (click)="sortKeywordsBy('competition')"
                >
                  <div>
                    {{
                      'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_COMPETITION'
                        | translate
                    }}
                    <span *ngIf="sortingInformations.key === 'competition'"
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
                <th
                  scope="col"
                  style="max-width: 250px"
                  class="d-none d-lg-table-cell"
                >
                  {{
                    'KEYWORD_PLAN.SEO_DATA_TABLE_COL_NAME_IMPRESSIONS'
                      | translate
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let keyword of getAudienceKeywords()">
                <td>{{ keyword.summary.name }}</td>
                <td>
                  {{ keyword.summary.last_month_search | number: '1.0-0' }}
                  <span *ngIf="isNaN(getMonthlySearchVariation(keyword))"
                    >(N/A)</span
                  ><span *ngIf="!isNaN(getMonthlySearchVariation(keyword))"
                    >({{ getMonthlySearchVariation(keyword) > 0 ? '+' : null
                    }}{{ getMonthlySearchVariation(keyword) | percent }})</span
                  >
                </td>
                <td class="d-none d-xl-table-cell">
                  {{ keyword.summary.average_monthly_search | number: '1.0-0' }}
                </td>
                <td>
                  <span *ngIf="keyword.summary.cpc > 0"
                    >{{
                      getNumberText(keyword.summary.cpc) | number: '1.0-4'
                    }}&nbsp;$</span
                  >
                  <span *ngIf="keyword.summary.cpc < 0">N/A</span>
                </td>
                <td>
                  <span *ngIf="keyword.summary.competition > 0">{{
                    getNumberText(keyword.summary.competition) | number: '1.0-4'
                  }}</span>
                  <span *ngIf="keyword.summary.competition < 0">N/A</span>
                </td>
                <td style="max-width: 250px" class="d-none d-lg-table-cell">
                  <plan-reach-summary
                    [plan]="plan"
                    [keyword]="keyword.text"
                  ></plan-reach-summary>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="max-w-80pt"
        style="margin: auto; margin-bottom: 20px; margin-top: 40px"
      >
        <publisher-section-intro
          title="{{ 'KEYWORD_PLAN.SOCIAL_DATA_SECTION_TITLE' | translate }}"
          description=" "
        ></publisher-section-intro>
      </div>
      <div
        class="display-block container-list"
        style="margin-top: 0"
        *ngIf="getAudienceSocialKeywords().length > 0"
      >
        <div
          class="col-12 one-edge-shadow form-background-color"
          style="padding-left: 0px; padding-right: 0px"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  {{
                    'KEYWORD_PLAN.SOCIAL_DATA_TABLE_COL_NAME_KEYWORD'
                      | translate
                  }}
                </th>
                <th
                  scope="col"
                  class="clickable"
                  (click)="sortSocialKeywordsBy('volume')"
                >
                  <div>
                    {{
                      'KEYWORD_PLAN.SOCIAL_DATA_TABLE_COL_NAME_VOLUME'
                        | translate
                    }}
                    <span *ngIf="sortingInformations.key === 'volume'"
                      ><img
                        class="picto-sort picto-sort-up"
                        [class.picto-sort-up]="sortingInformations.order == 1"
                        [class.picto-sort-down]="
                          sortingInformations.order == -1
                        "
                    /></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let keyword of getAudienceSocialKeywords()">
                <td>{{ getKeywordText(keyword) }}</td>
                <td>{{ getKeywordVolume(keyword) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="display-block container-list" style="margin-top: 0">
        <div
          class="col-12 one-edge-shadow form-background-color"
          style="padding-left: 0px; padding-right: 0px"
        >
          <!--network-graph></network-graph-->
          <network-list [data]="plan?.socialData"></network-list>
        </div>
      </div>
    </div>
  </div>
</div>
