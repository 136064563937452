import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'dashboard-activities.component.html',
  selector: 'dashboard-activities',
  styleUrls: ['./dashboard-activities.component.scss'],
})
export class DashboardActivitiesComponent implements OnInit {
  constructor(private router: Router) {}

  async ngOnInit() {}

  ngOnDestroy() {}
}
