import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuard, RoleGuard } from './_guards'
import { USER_ROLE } from './_helpers/referential/user.role'

import { PublicMultipleColumnsLayoutComponent } from './_components/public/public-multiple-columns-layout'
import { LoginComponent } from './_components/public/login'
import { SignupComponent } from './_components/public/signup'
import { PasswordRecoveryComponent } from './_components/public/password/password_recovery'
import { PasswordChangeComponent } from './_components/public/password/password_change'

import { HomeComponent } from './_components/private/home'

import { ErrorComponent } from './_components/errors/error'

import { UserResolver } from './_resolvers/user.resolver'
import { ProfileComponent } from './_components/private/profile'
import { ProfileInfluencerComponent } from './_components/private/publishers/profile-influencer'

import { environment } from './../environments/environment'

let getProfileComponent = () => {
  if (environment.role === USER_ROLE.PUBLISHER)
    return ProfileInfluencerComponent
  else return ProfileComponent
}

const COMMON_ROUTES: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { requiresLogin: true },
    /*resolve: { user: UserResolver },*/
  },
  {
    path: 'me',
    component: getProfileComponent(),
    canActivate: [AuthGuard],
    data: { requiresLogin: true },
    resolve: { user: UserResolver },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: { requiresLogin: false },
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [AuthGuard],
    data: { requiresLogin: false },
  },
  {
    path: 'password/recover',
    component: PasswordRecoveryComponent,
    canActivate: [AuthGuard],
    data: { requiresLogin: false },
  },
  {
    path: 'recover',
    component: PasswordChangeComponent,
    canActivate: [AuthGuard],
    data: { requiresLogin: false },
  },
  { path: 'error', component: ErrorComponent, data: { error: 'COMMON.ERROR' } },
  {
    path: 'unauthorized',
    component: ErrorComponent,
    data: { error: 'COMMON.UNAUTHORIZED' },
  },
  {
    path: 'not-found',
    component: ErrorComponent,
    data: { error: 'COMMON.NOT_FOUND' },
  },

  // otherwise redirect to home
  //{ path: '', redirectTo: '/home', pathMatch: 'full' },
  // redirectTo can not be used to keep query url
  {
    path: '',
    component: HomeComponent,
    /*canActivate: [AuthGuard],*/
    data: { requiresLogin: true },
    /*resolve: { user: UserResolver },*/
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'not-found', data: { requiresLogin: true } },
]

@NgModule({
  imports: [
    RouterModule.forRoot(COMMON_ROUTES, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppCommonRoutingModule {}
