import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class TaskReferentialService {
  private taskCompletionStatuses: any[]
  private taskStepStatuses: any[]
  private taskPriorities: any[]
  private taskAssignees: any[]
  private taskFilterOptions: any[]
  private taskSortingOptions: any[]

  public constructor(private http: HttpClient) {
    this.initService()
  }

  public initService() {
    this.initTaskCompletionStatuses()
    this.initTaskStepStatuses()
    this.initTaskPriorities()
    this.initTaskAssignees()
    this.initTaskFilterOptions()
    this.initTaskSortingOptions()
  }

  public initTaskCompletionStatuses() {
    this.taskCompletionStatuses = [
      {
        key: 'all',
        filter: 'All tasks',
        options: {
          showInFilters: true,
          showInList: false,
        },
      },
      {
        key: 'created',
        name: 'Mark complete',
        filter: 'Incomplete tasks',
        options: {
          showInFilters: true,
          showInList: true,
        },
      },
      {
        key: 'completed',
        name: 'Completed',
        filter: 'Completed tasks',
        options: {
          showInFilters: true,
          showInList: true,
        },
      },
      {
        key: 'deleted',
        name: 'Deleted',
        filter: 'Deleted tasks',
        options: {
          showInFilters: false,
          showInList: true,
        },
      },
    ]
  }

  public getTaskCompletionStatuses() {
    return this.taskCompletionStatuses.filter(
      (task) => task.options.showInList == true
    )
  }

  public getTaskFilters() {
    return this.taskCompletionStatuses.filter(
      (task) => task.options.showInFilters == true
    )
  }

  public initTaskStepStatuses() {
    this.taskStepStatuses = [
      {
        name: 'Backlog',
        key: 'backlog',
      },
      {
        name: 'In progress',
        key: 'in_progress',
      },
      {
        name: 'To be validated',
        key: 'to_be_validated',
      },
      {
        name: 'Validated',
        key: 'validated',
      },
      {
        name: 'Published',
        key: 'published',
      },
    ]
  }

  public getTaskStepStatuses() {
    return this.taskStepStatuses
  }

  public initTaskPriorities() {
    this.taskPriorities = [
      {
        name: 'No priority',
        key: 'not_set',
      },
      {
        name: 'Low',
        key: 'low',
      },
      {
        name: 'Medium',
        key: 'medium',
      },
      {
        name: 'High',
        key: 'high',
      },
    ]
  }

  public getTaskPriorities() {
    return this.taskPriorities
  }

  public initTaskAssignees() {
    this.taskAssignees = [
      {
        name: undefined,
        key: 'publisher',
      },
      {
        name: 'Brand',
        key: 'brand',
      },
    ]
  }

  public setTaskAssigneePublisherName(name) {
    for (let i = 0; i < this.taskAssignees.length; i++) {
      if (this.taskAssignees[i].key === 'publisher') {
        this.taskAssignees[i].name = name
      }
    }
  }

  public getTaskAssignees() {
    return this.taskAssignees
  }

  getTaskStepText(key) {
    if (key != undefined) {
      let result = this.taskStepStatuses
        ?.filter((object) => {
          return object.key === key
        })
        ?.reduce((acc, object) => {
          return object.name
        })

      return result.name
    }
  }

  getTaskPriorityText(key) {
    if (key != undefined) {
      let result = this.taskPriorities
        ?.filter((object) => {
          return object.key === key
        })
        ?.reduce((acc, object) => {
          return object.name
        })

      return result.name
    }
  }

  getTaskAssigneeText(key) {
    if (key != undefined) {
      let result = this.taskAssignees
        ?.filter((object) => {
          return object.key === key
        })
        ?.reduce((acc, object) => {
          return object.name
        })

      return result.name
    }
  }

  initTaskSortingOptions() {
    this.taskSortingOptions = [
      {
        name: 'Due date',
        filter: {
          text: 'My tasks',
          key: 'owner',
          value: 'publisher',
        },
      },
      {
        name: 'Alphabetical',
      },
      {
        name: 'Priority',
      },
    ]
  }

  getTaskSortingOptions() {
    return this.taskSortingOptions
  }

  initTaskFilterOptions() {
    this.taskFilterOptions = [
      {
        name: 'My tasks',
        filter: {
          key: 'owner',
          value: 'publisher',
        },
      },
      {
        name: 'Due for this week',
        filter: {
          key: 'this_week',
          value: 'due_for_this_week',
        },
      },
      {
        name: 'Due for next week',
        filter: {
          key: 'next_week',
          value: 'due_for_next_week',
        },
      },
    ]
  }

  getTaskFilterOptions() {
    return this.taskFilterOptions
  }
}
