<div class="container-fluid full-height bg-default">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'SCALERS' | translate }}" style="width:100%"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{ 'SCALERS' | translate }}"></nav-bar>

                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <campaign-summary></campaign-summary>
                    </div>

                    <div class="col-12 light-box box margin-top-25">
                        <div class="flex-row" style="align-items: center;">
                            <div class="profile-picture">
                                <img [src]="scaler?.pic_url" alt="">
                            </div>
                            <div style="margin-left: 20px" class="flex-column flex-center">
                                <div class="text-title text-white">
                                    {{scaler?.display_name}}
                                </div>
                                <div class="text-subtitle text-white">
                                    <img class="icon-social"
                                        src="/assets/images/socials-twitter.png">@{{getTwitterName()}}
                                </div>
                                <div class="text-subtitle text-white">
                                    <img class="icon-social"
                                        src="/assets/images/socials-linkedin.png">{{getLinkedInName()}}
                                </div>
                            </div>
                            <div style="margin-left: auto">
                                <div class="flex-column flex-center" style="height: 100%">
                                    <div style="margin: 10px">
                                        <div class="text-mini text-uppercase text-pink">
                                            Community
                                        </div>
                                        <div class="text-subtitle text-white">
                                            {{getCommunity().followers}} Followers
                                        </div>
                                        <div class="text-subtitle text-white">
                                            {{getCommunity().follows}}
                                        </div>
                                    </div>
            
                                    <div style="margin: 10px">
                                        <div class="text-mini text-uppercase text-pink">
                                            TOP HASHTAGS
                                        </div>
                                        <div class="text-subtitle text-grey" style="margin-top: 4px">
                                            <span class="grey-box" *ngFor="let hashtag of getCommunity().hashtags" style="margin-left: 5px; margin-right: 5px">{{hashtag}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 light-box box margin-top-25">
                        <div class="text-title text-white">
                            Main characteristics
                        </div>
            
                        <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                            Biography
                        </div>
                        <div class="text-white">
                            {{getCommunity().biography}}
                        </div>
            
                        <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                            Location
                        </div>
            
                        <div class="text-white">
                            {{getCommunity().location}}
                        </div>
                        <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                            Job
                        </div>
            
                        <div class="text-white">
                            {{getCommunity().job}}
                        </div>
            
            
                        <!--div class="row" style="margin-top: 10px">
                            <div class="col-6">
                                <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                                    Website
                                </div>
            
                                <div style="margin-top: 10px" class="text-white">
                                    <span *ngIf="socialAccount?.website == undefined">N/A</span><a [href]="socialAccount?.website">{{socialAccount?.website}}</a>
                                </div>
                            </div>
                        </div-->
                        
            
                        <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                            Publication languages
                        </div>
            
                        <div class="text-white">
                            {{getCommunity().language}}
                        </div>
                    </div>
            
                    <div class="col-12 box">
            
            
                        <div class="text-title text-white">
                            Expertise
                        </div>
            
                        <div style="margin-top: 10px">
                            <span class="grey-box">Productivity</span>
                            <span class="grey-box" style="margin-left: 6px;">Marketing</span>
                            <span class="grey-box" style="margin-left: 6px;">FinTech</span>
                        </div>
                    </div>
            
                    <div class="col-12 box">
                        <div class="text-title text-white">
                            Audience
                        </div>
            
                        <div class="text-mini text-pink text-uppercase" style="margin-top: 10px">
                            Location
                        </div>
            
                        <div style="">
                            <div class="progress" style=" height: 20px; margin-top: 10px; margin-bottom: 10px">
                                <div class="progress-bar" role="progressbar" style="width: 85%;background-color: #ff006c" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">USA (50%)</div>
                                <div class="progress-bar" role="progressbar" style="width: 15%;background-color: rgba(0,48,87,0.1)" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">UK (15%)</div>
                                <div class="progress-bar" role="progressbar" style="width: 15%;background-color: #ff006c" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">France (15%)</div>
                                <div class="progress-bar" role="progressbar" style="width: 15%;background-color: rgba(0,48,87,0.1)" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">Rest of World (20%)</div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<modal campaignId="{{id}}"></modal>