import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { APIService } from 'src/app/_services'
import { AdvertisersConversationsAPIService } from '../../_services/api/advertisers.conversations.api.service'

@Injectable()
export class AdvertisersConversationsResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private conversationsApiService: AdvertisersConversationsAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const workspaceId = <string>route.paramMap.get('workspaceId')
      let request = await this.conversationsApiService.getConversations(
        workspaceId
      )

      console.log(request.body.conversations)
      return request.body.conversations
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
