import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'deal-thumbnail.component.html',
  selector: 'deal-thumbnail',
  styleUrls: ['./deal-thumbnail.component.scss'],
})
export class DealThumbnailComponent implements OnInit {
  @Input('deal') deal: any

  constructor(private router: Router) {}

  async ngOnInit() {}

  ngOnDestroy() {}

  loadDealPage() {
    this.router.navigate(['/deals/' + this.deal._id])
  }

  getCreatedDate() {
    let convertedDate = DateHelper.convertDoubleToReadableDate(
      this.deal.createdAt
    )
    return convertedDate
  }

  getExpireDate() {
    let convertedDate = DateHelper.convertDoubleToReadableDate(
      this.deal.proposal.expiryDate
    )
    return convertedDate
  }

  getDealStatus() {
    if (this.deal.status === 'draft') return 'DRAFT'
    else if (this.deal.status === 'registered') return 'PROPOSED'
    else if (this.deal.status === 'accepted') return 'ACCEPTED'
    else if (this.deal.status === 'declined') return 'DECLINED'
    else if (this.deal.status === 'finished') return 'FINISHED'
    else if (this.deal.status === 'cancelled') return 'CANCELLED'

    return undefined
  }
}
