import { Component } from '@angular/core'

@Component({
  templateUrl: 'copyright.component.html',
  selector: 'copyright',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent {
  constructor() {}

  getYear() {
    return new Date().getFullYear()
  }
}
