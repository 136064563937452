import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersProfileAPIService } from 'src/app/_services'

@Component({
  templateUrl: 'publisher-profile-professional-profile-skills.component.html',
  selector: 'publisher-profile-professional-profile-skills',
  styleUrls: ['./publisher-profile-professional-profile-skills.component.scss'],
})
export class PublisherProfileProfessionalProfileSkillsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  skillsForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  skills: any[]
  skill: any
  skillId: any

  suggestedSkills: any[]

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    this.suggestedSkills = []
    this.skillsForm = this.formBuilder.group({
      nameForm: this.formBuilder.group({
        name: '',
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getSkills()
    this.suggestedSkills = [
      { name: 'Digital Marketing' },
      { name: 'B2B Marketing' },
    ]
  }

  ngOnDestroy() {}

  hasSkills() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.skills != undefined
      )
    } catch (e) {
      return false
    }
  }

  getSkills() {
    if (this.hasSkills() == true) {
      this.skills = this.publisher.publisher.professionalProfile.skills
      return this.skills
    } else return undefined
  }

  getNumberOfSkills() {
    let positions = this.getSkills()
    if (positions) return positions.length
    return 0
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async addSkillToProfile(skill) {
    try {
      const index = this.suggestedSkills.indexOf(skill)
      if (index > -1) {
        this.suggestedSkills.splice(index, 1)
      }

      this.loading = true
      this.alertService.resetAlerts()
      let res = await this.apiService.addSkillInformation(
        this.publisher.publisher._id,
        skill
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.makeNotEditable()
        this.successfullyAdded = true
        this.loading = false
        this.publisher.publisher = res.body.publisher
      } else {
        this.successfullyAdded = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let skillField = this.readTextValue(
        this.skillsForm.controls.nameForm['controls'].name.value
      )

      if (
        skillField != undefined &&
        this.skillsForm.controls.nameForm['controls'].name.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Skill field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let skill_infos = {
          name: skillField,
        }

        let res = await this.apiService.addSkillInformation(
          this.publisher.publisher._id,
          skill_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeNotEditable()
          this.clearInputField()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteSkill(skill) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let skillId = skill._id

      let res = await this.apiService.deleteSkillInformation(
        this.publisher.publisher._id,
        skillId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.makeNotEditable()
        this.publisher.publisher = res.body.publisher
        this.closeModalIfNoExpertise()
        this.successfullyRemoved = true
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addSkill() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateSkill() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  clearInputField() {
    this.skillsForm = this.formBuilder.group({
      nameForm: this.formBuilder.group({
        name: '',
      }),
    })
  }

  closeModalIfNoExpertise() {
    if (this.getSkills().length == 0) {
      this.closeModal()
    }
  }
}
