<div style="width: 100%" (click)="stopPropagation($event)">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-settings.png"
                style="width: 40px; padding-left: 10px; padding-right: 10px">{{ 'MODAL_PROFILE.MODAL_TITLE' | translate }}
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png"
                    style="width: 16px" (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">

        <div class="display-block col-direction"
            style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12 col-lg-5" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato text-bold"
                        style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                        {{ 'MODAL_PROFILE.MODAL_TITLE_1' | translate }}
                    </div>
                    <div class="col-12 text-lato"
                        style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                        {{ 'MODAL_PROFILE.MODAL_TEXT_1' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7" style="margin-left: 21px">
                <form class="margin-v-39" [formGroup]="profileForm">
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px;">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_PROFILE_PICTURE' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #949499; font-size: 14px; padding: 20px;text-align: right"
                            *ngIf="me?.user?.infos?.profilePictureURL == undefined && hasChangedProfilePicture == false">
                            <img src="./assets/images/img-placeholder.png" (click)="fileInput.click()"
                                style="width: 85px; padding-left: 10px; padding-right: 10px; cursor: pointer;">
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #949499; font-size: 14px; padding: 20px;text-align: right"
                            *ngIf="me?.user?.infos?.profilePictureURL && hasChangedProfilePicture == false">
                            <img [src]="me?.user?.infos?.profilePictureURL" (click)="fileInput.click()"
                                style="width: 85px; height: 65px; object-fit: cover; border-radius: 50%; padding-left: 10px; padding-right: 10px; cursor: pointer;">
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #949499; font-size: 14px; padding: 20px;text-align: right"
                            *ngIf="hasChangedProfilePicture == true">
                            <img [src]="profileSrc" (click)="fileInput.click()"
                                style="width: 85px; height: 65px; object-fit: cover; border-radius: 50%; padding-left: 10px; padding-right: 10px; cursor: pointer;">
                        </div>
                    </div>
                    <div class="row" style="display:none">
                        <div class="col-12" style="display:none">
                            <input style="display: none" type="file" (change)="onFileChanged($event)" accept="image/*"
                                #fileInput>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_LAST_NAME' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="text"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_LAST_NAME' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="lastName" formControlName="lastName">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_FIRST_NAME' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="text"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_FIRST_NAME' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="firstName" formControlName="firstName">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_COMPANY' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="text"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_COMPANY' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="company" formControlName="company">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_EMAIL' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="email"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_EMAIL' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="email" formControlName="email">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_PHONE' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="tel"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_PHONE' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="phoneNumber" formControlName="phoneNumber">
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-12" *ngIf="missingFieldsInProfile">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_1' | translate }}
                            </p>
                        </div>
                        <div class="col-12" *ngIf="incorrectEmail">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_INCORRECT_EMAIL_FORMAT' | translate }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="display-block col-direction"
            style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12 col-lg-5" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato text-bold"
                        style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                        {{ 'MODAL_PROFILE.MODAL_TITLE_3' | translate }}
                    </div>
                    <div class="col-12 text-lato"
                        style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                        {{ 'MODAL_PROFILE.MODAL_TEXT_3' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7" style="margin-left: 21px">
                <form class="margin-v-39" [formGroup]="preferencesForm">
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-top: 11px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px;">
                                <select id="language"
                                    class="form-control custom-select text-lato" style="font-size: inherit; padding: 0px; border: 0px"
                                    name="language" formControlName="language"
                                    (change)="updateLanguage()">
                                    <option value="" disabled selected>{{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}</option>
                                    <option *ngFor="let language of getLanguages()" [value]="language.id">{{language.name | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-top: 11px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px;">
                                <select id="currency"
                                    class="form-control custom-select text-lato" style="font-size: inherit; padding: 0px; border: 0px"
                                    name="currency" formControlName="currency"
                                    (change)="updateCurrency()">
                                    <option value="" disabled selected>{{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}</option>
                                    <option *ngFor="let currency of getCurrencies()" [value]="currency.id">{{currency.name | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div-->
                </form>
            </div>
        </div>

        <div class="display-block col-direction"
            style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12 col-lg-5" style="margin-right: 21px">
                <div class="row">
                    <div class="col-12 text-lato text-bold"
                        style="color: #30313e; font-size: 26px; border-left-color: #ff006c; border-left-style: solid; border-left-width: 3px; margin-bottom: 12px">
                        {{ 'MODAL_PROFILE.MODAL_TITLE_2' | translate }}
                    </div>
                    <div class="col-12 text-lato"
                        style="color: #484c50; font-size: 15px; border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px; padding-left: 0px; padding-right: 0px; padding-top: 18px">
                        {{ 'MODAL_PROFILE.MODAL_TEXT_2' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7" style="margin-left: 21px">
                <form class="margin-v-39" [formGroup]="passwordForm">
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENT_PASSWORD' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="password"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_CURRENT_PASSWORD' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="currentPassword" formControlName="currentPassword">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_NEW_PASSWORD' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="password"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_NEW_PASSWORD' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="newPassword" formControlName="newPassword">
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_CONFIRM_PASSWORD' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px; padding-bottom: 9px">
                                <input type="password"
                                    placeholder="{{ 'MODAL_PROFILE.MODAL_PLACEHOLDER_CONFIRM_PASSWORD' | translate }}"
                                    class="input-text"
                                    style="margin-bottom: 0px; padding-bottom: 0px; border-bottom-width: 0px;"
                                    name="confirmPassword" formControlName="confirmPassword">
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 0px; margin-right: 20px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-12" *ngIf="missingFieldsInPassword">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_2' | translate }}
                            </p>
                        </div>
                        <div class="col-12" *ngIf="passwordsMismatched">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_PASSWORDS_NOT_MATCHING' | translate }}
                            </p>
                        </div>
                        <div class="col-12" *ngIf="wrongPassword">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_INCORRECT_PASSWORD' | translate }}
                            </p>
                        </div>
                        <div class="col-12" *ngIf="passwordTooShort">
                            <p class="text-lato text-medium" style="color: #949499; font-size: 12px">
                                {{ 'MODAL_PROFILE.ERROR_PASSWORD_TOO_SHORT' | translate }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div style="width: 100%; padding-left: 15px; padding-right: 15px; background-color: #e3e3e3; margin-top: -20px">
        <div class="text-lato text-medium;"
            style="color: #30313e; margin-top: 20px; padding-top: 25px; padding-bottom: 25px; text-align: right; padding-right: 16px">
            <input class="btn btn-primary btn-big text-lato text-bold" type="submit"
                value="{{ 'MODAL_PROFILE.MODAL_OK_BUTTON' | translate }}" (click)="onSubmit()">
        </div>
    </div>
</div>>