<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <form [formGroup]="preferencesForm">
      <div class="row">
        <div class="col-10 section-body text-small text-darkgrey">
          <div class="input-title">Platform Language</div>
          <div class="input-value container-field" *ngIf="isEditing == false">
            {{ getPreferredLanguage() | translate }}
          </div>
          <div class="input-value container-field" *ngIf="isEditing == true">
            <select
              name="preferredLanguage"
              formControlName="preferredLanguage"
              id="preferred-language-select"
              class="custom-select content-width"
              (change)="updateLanguage()"
            >
              <option value="">-- Please choose an option --</option>
              <option
                [value]="language.code"
                *ngFor="let language of getLanguages()"
              >
                {{ language.name | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-2 flex-end section-btn">
          <edit-button
            *ngIf="isEditing == false"
            (click)="makeEditable()"
          ></edit-button>
          <tiny-cta
            *ngIf="isEditing == true"
            (click)="saveChanges()"
            value="SAVE"
          ></tiny-cta>
        </div>
      </div>
    </form>
  </div>
</div>
