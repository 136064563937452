export class CampaignHelper {
  static getCampaignState(campaign) {
    var state = ''

    if (campaign.status === 'draft')
      state = 'CAMPAIGN_STATUS.CAMPAIGN_STATUS_DRAFT'
    if (campaign.status === 'launched')
      state = 'CAMPAIGN_STATUS.CAMPAIGN_STATUS_LAUNCHED'
    if (campaign.status === 'finished')
      state = 'CAMPAIGN_STATUS.CAMPAIGN_STATUS_FINISHED'
    if (campaign.status === 'cancelled')
      state = 'CAMPAIGN_STATUS.CAMPAIGN_STATUS_CANCELLED'

    return state
  }
}
