import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
} from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'

import { IndustryHelper } from '../../../../_helpers/referential/industry.helper'

import { environment } from '../../../../../environments/environment'

let BACKEND_URL = environment.apiUrl

import * as QuillNamespace from 'quill'
import * as ImageResizeNamespace from 'quill-image-resize'

const Quill: any = QuillNamespace
const ImageResize: any = ImageResizeNamespace
Quill.register('modules/imageResize', ImageResize.default)

@Component({
  templateUrl: 'advertiser-brand-edit.component.html',
  styleUrls: ['./advertiser-brand-edit.component.scss'],
})
export class AdvertiserBrandEditComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any
  isValid: boolean
  hasBrandNameError: boolean
  myBrandForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasAccount = false

  hasSelectedAFile: boolean

  project_name: any
  project: any
  project_id: any

  selectedFile: File // For Brand Logo
  imageSrc: any // For Brand Logo

  selectedresourceFile: File // For resources
  resourceFileSrc: any // For resources

  brand_logo_url: any

  links: any[]
  advocates: any[]

  resources: any[]
  externalLinks: any[]

  countries: any
  states: any

  locations: any[]

  all_countries: any
  all_states: any

  location_country: any
  location_area: any

  industries: any[] = IndustryHelper.getIndustries()
  currentIndustries: any[] = []

  descriptionQuillEditor: any
  valuesQuillEditor: any
  productsQuillEditor: any
  salesTargetQuillEditor: any

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private location: Location,
    public alertService: AlertService,
    private http: HttpClient
  ) {
    this.project_name = undefined
    this.project = undefined
    this.project_id = undefined
    this.loading = false
    this.hasAccount = false
    this.imageSrc = undefined
    this.links = []
    this.advocates = []
    this.resources = []
    this.externalLinks = []

    this.currentIndustries = []
    this.locations = []

    this.location_country = undefined
    this.location_area = undefined

    this.isValid = true
    this.hasBrandNameError = false
    this.hasSelectedAFile = false
  }

  async ngOnInit() {
    this.alertService.resetAlerts()

    this.myBrandForm = this.formBuilder.group({
      brandNameForm: this.formBuilder.group({
        brandName: [''],
      }),
      baseline: [''],
      description: [''],
      industry: [''],
      newIndustry: [''],
      values: [''],
      products: [''],
      salesTarget: [''],
      country: [''],
      area: [''],
      typeOfLink: ['choose'],
      linkForm: this.formBuilder.group({
        link: [''],
      }),
      typeOfAdvocateLink: ['choose'],
      advocateLinkForm: this.formBuilder.group({
        advocateLink: [''],
      }),
      resourceNameForm: this.formBuilder.group({
        resourceName: [''],
      }),
      resourceFile: [''],
      resourceIsPublic: [false],
      externalLinkNameForm: this.formBuilder.group({
        externalLinkName: [''],
      }),
      externalLinkForm: this.formBuilder.group({
        externalLink: [''],
      }),
      externalLinkIsPublic: [false],
    })

    await this.generateCountryList()

    this.countries = this.getCountries()
    this.states = this.all_states

    this.sub = this.route.params.subscribe(async (params) => {
      this.project_id = params['workspaceId']

      this.brand_logo_url = './assets/images/img-placeholder.png'

      let filtered = this.route.snapshot.data.user.body.projects.filter(
        (project) => project._id === this.project_id
      )

      if (filtered != undefined && filtered.length > 0)
        this.project = filtered[0]

      this.initValues(this.project)

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  async generateCountryList() {
    try {
      let json = await this.http
        .get(BACKEND_URL + '/public/i18n/en.json')
        .toPromise()

      if (json != undefined) {
        this.all_countries = []
        this.all_states = []
        //console.log(json)
        if (
          json['COUNTRIES'] &&
          json['COUNTRIES']['LIST'] &&
          json['COUNTRIES']['STATES']
        ) {
          let i_country = 0
          let i_state = 0
          for (let key in json['COUNTRIES']['LIST']) {
            let countryToAdd = {
              id: i_country,
              name: 'COUNTRIES.LIST.' + key,
            }

            for (let state in json['COUNTRIES']['STATES']) {
              if (state.startsWith(key) || state === 'EVERYWHERE') {
                let stateToAdd = {
                  id: i_state,
                  name: 'COUNTRIES.STATES.' + state,
                  country: i_country,
                }
                this.all_states.push(stateToAdd)
                i_state++
              }
            }

            this.all_countries.push(countryToAdd)
            i_country++
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  initValues(project) {
    if (project.brand != undefined) {
      let product_description = ''
      let sales_target = ''

      if (
        this.project.brand.products &&
        this.project.brand.products.length > 0
      ) {
        product_description = this.project.brand.products[0].description
        sales_target = this.project.brand.products[0].target
        this.locations = this.project.brand.products[0].locations
      }

      if (
        this.project.brand.industries &&
        this.project.brand.industries.length > 0
      ) {
        this.currentIndustries = this.project.brand.industries
      } else if (this.project.brand.industry) {
        this.currentIndustries = [{ key: this.project.brand.industry }]
      }

      this.myBrandForm = this.formBuilder.group({
        brandNameForm: this.formBuilder.group({
          brandName: [this.project.brand.name],
        }),
        baseline: [this.project.brand.baseline],
        description: [this.project.brand.description],
        industry: [this.project.brand.industry],
        newIndustry: [''],
        values: [this.project.brand.values_description],
        products: [product_description],
        salesTarget: [sales_target],
        country: [''],
        area: [''],
        typeOfLink: ['choose'],
        linkForm: this.formBuilder.group({
          link: [''],
        }),
        typeOfAdvocateLink: ['choose'],
        advocateLinkForm: this.formBuilder.group({
          advocateLink: [''],
        }),
        resourceNameForm: this.formBuilder.group({
          resourceName: [''],
        }),
        resourceFile: [''],
        resourceIsPublic: [false],
        externalLinkNameForm: this.formBuilder.group({
          externalLinkName: [''],
        }),
        externalLinkForm: this.formBuilder.group({
          externalLink: [''],
        }),
        externalLinkIsPublic: [false],
      })

      if (project.brand.digital_accounts != undefined)
        this.links = project.brand.digital_accounts
      if (project.brand.advocates_accounts != undefined)
        this.advocates = project.brand.advocates_accounts
      if (project.brand.brand_logo_path != undefined)
        this.brand_logo_url = project.brand.brand_logo_path
      if (project.brand.marketing_resources != undefined)
        this.resources = project.brand.marketing_resources
      if (project.brand.marketing_links != undefined)
        this.externalLinks = project.brand.marketing_links
    }
  }

  async onSubmit() {
    try {
      this.hasBrandNameError = false
      this.alertService.resetAlerts()

      let brand_name = undefined
      if (
        this.myBrandForm.controls.brandNameForm['controls'].brandName.value !=
        undefined
      )
        brand_name = this.myBrandForm.controls.brandNameForm[
          'controls'
        ].brandName.value.trim()

      let brand_baseline = undefined
      if (this.myBrandForm.controls.baseline.value != undefined)
        brand_baseline = this.myBrandForm.controls.baseline.value.trim()

      let brand_description = undefined
      if (this.myBrandForm.controls.description.value != undefined)
        brand_description = this.myBrandForm.controls.description.value.trim()

      let brand_industry = this.myBrandForm.controls.industry.value

      let brand_values = undefined
      if (this.myBrandForm.controls.values.value != undefined)
        brand_values = this.myBrandForm.controls.values.value.trim()

      let products = undefined
      if (this.myBrandForm.controls.products.value != undefined)
        products = this.myBrandForm.controls.products.value.trim()

      let targets = undefined
      if (this.myBrandForm.controls.salesTarget.value != undefined)
        targets = this.myBrandForm.controls.salesTarget.value.trim()

      if (brand_name != undefined) {
        if (brand_name.length > 2) {
          this.isValid = true

          let brand_products = []

          let toPush = {
            description: '',
            target: '',
            locations: [],
          }

          if (products.length > 0) {
            toPush['description'] = products
          }

          if (targets.length > 0) {
            toPush['target'] = targets
          }

          if (this.locations.length > 0) {
            toPush['locations'] = this.locations
          }

          brand_products.push(toPush)

          let brand = {
            brandName: brand_name,
            baseline: brand_baseline,
            description: brand_description,
            industry: brand_industry,
            industries: this.currentIndustries,
            values: brand_values,
            links: this.links,
            advocates: this.advocates,
            products: brand_products,
            marketing_resources: this.resources,
            marketing_links: this.externalLinks,
          }

          let res
          if (this.hasSelectedAFile == true) {
            res = await this.apiService.updateBrand(
              this.project_id,
              brand,
              this.selectedFile
            )

            if (res.status == 200) {
              this.alertService.pushMessageAlert('MY_BRAND_EDIT.ALERT_OK')

              this.project = res.body
              this.initValues(this.project)
            } else {
              this.alertService.pushErrorAlert('MY_BRAND_EDIT.ALERT_NOK')
            }
          } else {
            res = await this.apiService.updateBrand(
              this.project_id,
              brand,
              undefined
            )
            if (res.status == 200) {
              this.alertService.pushMessageAlert('MY_BRAND_EDIT.ALERT_OK')
            } else {
              this.alertService.pushErrorAlert('MY_BRAND_EDIT.ALERT_NOK')
            }
          }
        } else {
          this.hasBrandNameError = true
          this.isValid = false
          this.alertService.pushErrorAlert('MY_BRAND_EDIT.ALERT_NOK')
        }
      } else {
        this.isValid = false
        this.alertService.pushErrorAlert('MY_BRAND_EDIT.ALERT_NOK')
      }
    } catch (e) {
      this.alertService.pushErrorAlert('MY_BRAND_EDIT.ALERT_NOK')
    }
  }

  addWebLink() {
    let type = this.myBrandForm.controls.typeOfLink.value
    let link = this.myBrandForm.controls.linkForm['controls'].link.value.trim()

    if (type !== 'choose' && link != undefined && link.length > 0) {
      this.links.push({
        type: type,
        link: link,
      })

      this.myBrandForm.controls.typeOfLink.setValue('choose')
      this.myBrandForm.controls.linkForm['controls'].link.setValue('')
    }
  }

  removeWebLink(link) {
    let i = this.links.indexOf(link)
    this.links.splice(i, 1)
  }

  addAdvocate() {
    let type = this.myBrandForm.controls.typeOfAdvocateLink.value
    let link = this.myBrandForm.controls.advocateLinkForm[
      'controls'
    ].advocateLink.value.trim()

    if (type !== 'choose' && link != undefined && link.length > 0) {
      this.advocates.push({
        type: type,
        link: link,
      })

      this.myBrandForm.controls.typeOfAdvocateLink.setValue('choose')
      this.myBrandForm.controls.advocateLinkForm[
        'controls'
      ].advocateLink.setValue('')
    }
  }

  removeAdvocate(link) {
    let i = this.advocates.indexOf(link)
    this.advocates.splice(i, 1)
  }

  async registerEverything() {
    try {
    } catch (error) {
      this.loading = false
    }
  }

  onFileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.imageSrc = reader.result
      this.selectedFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
    this.hasSelectedAFile = true
  }

  stepBack() {
    this.location.back()
  }

  async addResource() {
    let name = this.myBrandForm.controls.resourceNameForm[
      'controls'
    ].resourceName.value.trim()
    let isPublic = this.myBrandForm.controls.resourceIsPublic.value

    if (
      name != undefined &&
      name.length > 0 &&
      this.selectedresourceFile != undefined
    ) {
      let res = await this.apiService.uploadDocument(
        this.project_id,
        this.selectedresourceFile
      )
      if (res.status == 200) {
        this.resources.push({
          name: name,
          link: res.body.document.location,
          isPublic: isPublic,
        })
      }
    }
  }

  downloadresource(resource_i) {}

  removeResource(resource_i) {
    let i = this.resources.indexOf(resource_i)
    this.resources.splice(i, 1)
  }

  onresourceChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.resourceFileSrc = reader.result
      this.selectedresourceFile = event.target.files[0]
    }
    reader.readAsDataURL(event.target.files[0])
  }

  async addExternalLink() {
    let name = this.myBrandForm.controls.externalLinkNameForm[
      'controls'
    ].externalLinkName.value.trim()
    let link = this.myBrandForm.controls.externalLinkForm[
      'controls'
    ].externalLink.value.trim()
    let isPublic = this.myBrandForm.controls.externalLinkIsPublic.value

    if (name != undefined && name.length > 0) {
      this.externalLinks.push({
        name: name,
        link: link,
        isPublic: isPublic,
      })
    }
  }

  removeExternalLink(link_i) {
    let i = this.externalLinks.indexOf(link_i)
    this.externalLinks.splice(i, 1)
  }

  getCountries() {
    return this.all_countries
  }

  getStates() {
    if (this.all_states != undefined) {
      let statesList = this.all_states.filter(
        (state) => state.country == this.myBrandForm.controls.country.value
      )
      return statesList
    } else return []
  }

  resetState() {
    let candidate_country = this.myBrandForm.controls.country.value
    let candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country)
      this.location_country = this.countries[candidate_country].name
    if (candidate_area)
      this.location_area = this.all_states[candidate_area].name
    //this.newCampaignForm.controls.area.setValue(undefined);
  }

  changeCountry() {
    let candidate_country = this.myBrandForm.controls.country.value
    let candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country)
      this.location_country = this.countries[candidate_country].name
    if (candidate_area)
      this.location_area = this.all_states[candidate_area].name
  }

  addLocation() {
    let candidate_country = this.myBrandForm.controls.country.value
    let candidate_area = this.myBrandForm.controls.area.value

    if (candidate_country) {
      if (candidate_area) {
      } else {
        candidate_area = 0
      }
      let toPush = {
        country: this.countries[candidate_country].name,
        area: this.states[candidate_area].name,
      }

      if (this.indexOfLocation(toPush) === -1) {
        this.locations.push(toPush)
      }
    }
  }

  indexOfLocation(location) {
    let index = -1
    for (let i = 0; i < this.locations.length; i++) {
      if (
        this.locations[i].country === location.country &&
        this.locations[i].area === location.area
      ) {
        index = i
        break
      }
    }
    return index
  }

  removeLocation(location) {
    let i = this.locations.indexOf(location)
    this.locations.splice(i, 1)
  }

  updateDescription() {}

  addIndustry() {
    let candidate_industry = this.myBrandForm.controls.newIndustry.value

    if (candidate_industry) {
      let toPush = {
        key: candidate_industry,
      }

      if (this.indexOfLocation(toPush) === -1) {
        this.currentIndustries.push(toPush)
      }
    }
  }

  indexOfIndustry(industry) {
    let index = -1
    for (let i = 0; i < this.currentIndustries.length; i++) {
      if (this.currentIndustries[i].key === industry.key) {
        index = i
        break
      }
    }
    return index
  }

  removeIndustry(industry) {
    let i = this.currentIndustries.indexOf(industry)
    this.currentIndustries.splice(i, 1)
  }

  getIndustry(industry) {
    return IndustryHelper.getIndustryText(industry)
  }

  async selectLocalImage(tag) {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    // Listen upload local image and save to server
    input.onchange = async () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        await this.saveToServer(file, tag)
      } else {
        console.warn('You could only upload images.')
      }
    }
  }

  async saveToServer(file: File, tag) {
    let res = await this.apiService.uploadBrandImage(this.project_id, file)
    if (res.status == 200) {
      let url = res.body.image.location
      this.insertToEditor(url, tag)
    }
  }

  insertToEditor(url: string, tag) {
    // push image url to rich editor.

    if (tag === 'description') {
      const range = this.descriptionQuillEditor.getSelection()
      this.descriptionQuillEditor.insertEmbed(range.index, 'image', url, 'user')
    }
    if (tag === 'products') {
      const range = this.productsQuillEditor.getSelection()
      this.productsQuillEditor.insertEmbed(range.index, 'image', url, 'user')
    }
    if (tag === 'salesTarget') {
      const range = this.salesTargetQuillEditor.getSelection()
      this.salesTargetQuillEditor.insertEmbed(range.index, 'image', url, 'user')
    }
    if (tag === 'values') {
      const range = this.valuesQuillEditor.getSelection()
      this.valuesQuillEditor.insertEmbed(range.index, 'image', url, 'user')
    }
  }

  initQuill(event, tag) {
    if (tag === 'description') this.descriptionQuillEditor = event
    if (tag === 'products') this.productsQuillEditor = event
    if (tag === 'salesTarget') this.salesTargetQuillEditor = event
    if (tag === 'values') this.valuesQuillEditor = event
  }

  getEditorModules(tag) {
    return {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: ['#000', '#ff006c'] }],
          ['blockquote', 'code-block'],
          [{ align: ['', 'center', 'right', 'justify'] }],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['clean'],
          ['link', 'image', 'video'],
        ],
        handlers: {
          image: async () => {
            await this.selectLocalImage(tag)
          },
        },
      },
      imageResize: true, // for image resize
    }
  }
}
