import { Component } from '@angular/core'

@Component({
  templateUrl: 'logo-center.component.html',
  selector: 'logo-center',
  styleUrls: ['./logo-center.component.scss'],
})
export class LogoCenterComponent {
  constructor() {}
}
