import { EditableTask } from '../task/editable.task.model'
import { Column } from './column.model'

export class TaskColumn implements Column {
  constructor(
    public name: string,
    public key: string,
    public tasks: EditableTask[]
  ) {
    this.name = name
    this.key = key
    this.tasks = tasks
  }
}
