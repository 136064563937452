<div class="container-fluid">
  <div class="row flex-ltr">
    <div class="col-12 col-lg-7 d-none d-lg-flex no-padding">
      <logo-center></logo-center>
    </div>
    <div class="col-12 col-lg-5 d-flex full-height no-padding hv-center">
      <div class="v-align full-height d-flex flex-column v-align padding-20">
        <div class="text-right">
          <!--span class="text-lato text-grey text-small"
            >{{ 'LOGIN.NO_ACCOUNT' | translate
            }}<a [routerLink]="['/signup']" id="sign-up-btn"
              ><span class="btn btn-outline-primary left-margin text-button">{{
                'LOGIN.CREATE_AN_ACCOUNT' | translate
              }}</span></a
            ></span-->
        </div>
        <div class="text-center center margin-h-public-form">
          <div>
            <img
              class="brand-logo d-lg-none"
              src="./assets/images/fns_logo.png"
            />
            <!--h1
              class="text-lato text-bold text-big text-darkgrey line-height-115"
            >
              {{ 'LOGIN.TITLE_LINE_1' | translate }}<br />{{
                'LOGIN.TITLE_LINE_2' | translate
              }}
            </h1-->
            <form
              class="margin-v-39"
              id="loginForm"
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="row">
                <div class="col-12">
                  <input-field
                    type="email"
                    id="email"
                    placeholder="{{
                      'LOGIN.INPUT_EMAIL_PLACEHOLDER' | translate
                    }}"
                    name="email"
                    relatedControlName="email"
                    formGroupName="emailForm"
                    autocomplete="off"
                    [parentGroup]="loginForm"
                    class="max-w-400"
                  >
                  </input-field>
                </div>
                <div class="col-12">
                  <input-field
                    id="password"
                    type="password"
                    placeholder="{{
                      'LOGIN.INPUT_PASSWORD_PLACEHOLDER' | translate
                    }}"
                    name="password"
                    relatedControlName="password"
                    formGroupName="passwordForm"
                    [parentGroup]="loginForm"
                    class="max-w-400"
                  >
                  </input-field>
                </div>
                <div class="col-12" *ngIf="correctLogin == false">
                  <p
                    class="text-lato text-medium"
                    style="color: #949499"
                    id="error-credential"
                  >
                    {{ 'LOGIN.ERROR_WRONG_CREDENTIALS' | translate }}
                  </p>
                </div>
                <div class="col-12 input-info">
                  <span class="text-lato text-small text-darkgrey"
                    ><a
                      class="fns-link text-lato text-small"
                      href="password/recover"
                      id="forgoten-password"
                    >
                      {{ 'LOGIN.TEXT_FORGOTTEN_PASSWORD' | translate }}</a
                    ><!--Rester connecté<input type="checkbox" class="input-checkbox">--></span
                  >
                </div>
                <div class="col-12">
                  <plain-button
                    id="login-btn"
                    value="{{ 'LOGIN.TEXT_BUTTON_LOGIN' | translate }}"
                  >
                  </plain-button>
                </div>
                <div class="col-12" *ngIf="isCryptrActivated == true">
                  <div class="separator">
                    <div class="separator-line separator-line-left"></div>
                    <div class="separator-text">or</div>
                    <div class="separator-line separator-line-right"></div>
                  </div>
                </div>
                <div
                  class="col-12"
                  *ngIf="isCryptrActivated == true"
                  style="font-weight: 600; font-size: 16px"
                >
                  SIGN IN WITHOUT PASSWORD
                </div>
                <div
                  class="col-12"
                  *ngIf="isCryptrActivated == true"
                  style="font-weight: 400; font-size: 12px; margin-top: 10px"
                >
                  We will email you a login link that<br />will connect you
                  instantly & securely
                </div>
                <div
                  class="col-12"
                  id="magic-link-button"
                  *ngIf="isCryptrActivated == true"
                >
                  <cryptr-button (click)="signUpWithRedirect()">
                  </cryptr-button>
                </div>
                <div class="col-12" *ngIf="isCryptrActivated == true">
                  <a href="https://www.cryptr.co/" target="_blank">
                    <object
                      data="/assets/images/cryptr/cryptr_badge_wihout_symbol_H.svg"
                      width="111"
                      height="13"
                    ></object>
                  </a>
                </div>
              </div>
            </form>
            <span class="text-lato text-small text-pink"
              ><a
                href=""
                class="fns-link text-lato text-small text-bold text-pink"
                id="terms-and-conditions"
              >
                <!--p>{{ 'LOGIN.TEXT_TERMS_AND_CONDITIONS' | translate }}</p-->
                <p>{{ 'Beta Program' | translate }}</p>
              </a></span
            >
          </div>
        </div>
        <copyright></copyright>
      </div>
    </div>
  </div>
</div>

<loader id="loader" *ngIf="loading == true"></loader>

<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 10"
  *ngIf="showConfirmationPopup == true"
>
  <div
    class="col-12 col-lg-3 d-none d-lg-flex left-menu"
    id="modal-left"
    style="flex: 1; opacity: 0.65; background-color: #000000; z-index: 98"
    (click)="closeModal()"
  ></div>
  <div
    class="col-12 col-lg-9 d-block"
    id="modal-menu"
    style="background-color: rgba(0, 0, 0, 0.65); height: 100vh; padding: 0"
  >
    <div
      class="modal fade modal-overlay"
      id="modalLogin"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLogin"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalLoginTitle">
                    {{ 'Log in' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-lato">
            The provided email (<span class="text-pink">{{
              loginForm?.controls?.emailForm['controls']['email'].value
            }}</span
            >) is associated to another Foxy Nerds service
          </div>
          <div class="modal-body text-lato modal-text">
            Do you want to want to expand your account to this service?
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-secondary two-btn-left-radius text-lato btn-text"
                  (click)="redirectToSiblingPlatform()"
                >
                  {{ getSiblingPlatformText() }}
                </button>
              </div>
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-primary two-btn-right-radius text-lato btn-text"
                  (click)="confirmLogin()"
                >
                  Yes, expand my account to this service
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: absolute; top: 0; width: 100vw; height: 100vh; z-index: 9"
  *ngIf="showConfirmationPopup == true"
  (click)="closeModal()"
></div>
