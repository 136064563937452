let currencies = [
  { code: 'EUR', value: 'Euro (€)' },
  { code: 'USD', value: 'US Dollar (US$)' },
]

export class CurrencyHelper {
  static getCurrencies() {
    return currencies
  }

  static getCurrencyText(code) {
    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i].code === code) return currencies[i].value
    }
    return ''
  }
}
