import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersProfileAPIService } from 'src/app/_services'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'

@Component({
  templateUrl: 'publisher-profile-content-description-networks.component.html',
  selector: 'publisher-profile-content-description-networks',
  styleUrls: [
    './publisher-profile-content-description-networks.component.scss',
  ],
})
export class PublisherProfileContentDescriptionNetworksComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  networksForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  networks: any[]
  network: any
  networkId: any

  networkTypes: any[]
  suggestedNetworks: any[]

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService,
    private campaignReferentialService: CampaignReferentialService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)

    this.suggestedNetworks = []
    ;(this.networksForm = this.formBuilder.group({
      type: '',
      linkForm: this.formBuilder.group({
        link: '',
      }),
    })),
      (this.networkTypes = this.campaignReferentialService.getNetworks())
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getNetworks()
    this.suggestedNetworks = this.getCorrespondingSocialMediaTypeChecked()
  }

  ngOnDestroy() {}

  hasNetworks() {
    try {
      return (
        this?.publisher?.publisher?.contentDescription?.networks != undefined
      )
    } catch (e) {
      return false
    }
  }

  getNetworks() {
    if (this.hasNetworks() == true) {
      this.networks = this.publisher.publisher.contentDescription.networks
      return this.networks
    } else return undefined
  }

  getNumberOfNetworks() {
    let networks = this.getNetworks()
    if (networks == undefined) return 0

    return networks.length
  }

  getCorrespondingSocialMediaType(code: string) {
    let result = undefined
    for (let i = 0; i < this.networkTypes.length; i++) {
      result = this.networkTypes[i].text
      return result
    }

    return result
  }

  getCorrespondingSocialMediaTypeChecked() {
    let suggestedNetworks = this.networkTypes

    let networks = this.getNetworks()
    if (networks == undefined) {
      return suggestedNetworks
    }

    let checker = []
    for (let i = 0; i < networks.length; i++) {
      checker.push(networks[i].type)
    }

    let result = []
    for (let i = 0; i < suggestedNetworks.length; i++) {
      const index = checker.indexOf(suggestedNetworks[i].id)
      if (index == -1) {
        result.push(suggestedNetworks[i])
      }
    }

    return result
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let networkField = this.readTextValue(
        this.networksForm.controls.linkForm['controls'].link.value
      )

      if (
        networkField != undefined &&
        this.networksForm.controls.linkForm['controls'].link.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Network field can not be empty')
      }

      let type = this.networksForm.get('type').value

      if (type === '') {
        this.isValid = false
        this.alertService.pushErrorAlert(
          'You have to select a language proficiency'
        )
      }

      if (this.isValid == true) {
        this.isValid = true

        let network_infos = {
          type: type,
          link: networkField,
        }

        let res = await this.apiService.addNetworkInformation(
          this.publisher.publisher._id,
          network_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeNotEditable()
          this.clearInputField()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteNetwork(network) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let networkId = network._id

      let res = await this.apiService.deleteNetworkInformation(
        this.publisher.publisher._id,
        networkId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.makeNotEditable()
        this.publisher.publisher = res.body.publisher
        this.closeModalIfNoExpertise()
        this.successfullyRemoved = true
        this.loading = false
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addNetwork() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
    this.suggestedNetworks = this.getCorrespondingSocialMediaTypeChecked()
  }

  updateNetwork() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  validateNetwork() {}

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyRemoved = undefined
    this.successfullyAdded = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  clearInputField() {
    this.networksForm = this.formBuilder.group({
      type: '',
      linkForm: this.formBuilder.group({
        link: '',
      }),
    })
  }

  getNetworkImagePath(network) {
    let path = 'assets/images/icons/'
    switch (network) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  closeModalIfNoExpertise() {
    if (this.getNetworks().length == 0) {
      this.closeModal()
    }
  }
}
