import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { DateHelper } from '../../../../_helpers/date.helpers'

import * as moment from 'moment'
import {
  AdvertisersOffersAPIService,
  PublishersOpportunitiesAPIService,
  StorageManagementService,
} from 'src/app/_services'

@Component({
  templateUrl: 'opportunity-thumbnail.component.html',
  selector: 'opportunity-thumbnail',
  styleUrls: ['./opportunity-thumbnail.component.scss'],
})
export class AdvertiserOpportunityThumbnailComponent implements OnInit {
  @Input('opportunity') opportunity: any

  networks: any[]
  createdAt: any

  publisher: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private advertisersOffersAPIService: AdvertisersOffersAPIService,
    private storageManagementService: StorageManagementService
  ) {
    this.publisher = this.route.snapshot.data.user.body
  }

  async ngOnInit() {
    //    this.networks = this.opportunity._scope.networks
    console.log(this.opportunity)
  }

  ngOnDestroy() {}

  editOffer() {
    this.router.navigate([
      'workspaces/' +
        this.storageManagementService.getCurrentBrandspaceId() +
        '/campaigns/' +
        this.opportunity._id +
        '/edit',
    ])
  }

  getOpportunityDateSentence() {
    return (
      DateHelper.convertDoubleToReadableDate(this.opportunity.startDate) +
      ' - ' +
      DateHelper.convertDoubleToReadableDate(this.opportunity.endDate)
    )
  }

  getCampaignStatus() {
    if (this.opportunity.status === 'draft') return 'DRAFT'
    else if (
      this.opportunity.status === 'created' ||
      this.opportunity.status === 'live'
    ) {
      let now = moment().valueOf()
      if (now < this.opportunity.startDate) {
        return 'UPCOMING'
      } else if (now > this.opportunity.endDate) {
        return 'FINISHED'
      } else {
        return 'LIVE'
      }
    } else if (this.opportunity.status === 'cancelled') return 'CANCELLED'
    else if (this.opportunity.status === 'expired') return 'FINISHED'

    return undefined
  }

  getNetworkThumbnailImagePath(network) {
    let path = 'assets/images/icons/'
    switch (network) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  getCampaign() {
    return this.opportunity._scope
  }

  isFavorite() {
    return this.opportunity?.favorite
  }

  async toggleFavorite() {
    let isFavorite = this.isFavorite()
    await this.makeFavorite(!isFavorite)
  }

  async makeFavorite(favorite) {}
}
