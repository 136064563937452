<div id="anchor3">
  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
  >
    <div class="row">
      <div class="form-group col-md-6 separator">
        <p
          class="text-lato text-bold text-big text-darkgrey text-left center-mobile"
        >
          {{ "CAMPAIGN.CREATE.STEP_3_TITLE_1" | translate }}
        </p>
        <div class="max-w-400 flex-align">
          <input
            type="text"
            placeholder="{{
              'CAMPAIGN.CREATE.STEP_3_DO_PLACEHOLDER' | translate
            }}"
            class="input-text text-lato text-medium text-darkgrey"
            name="do"
            formControlName="do"
            autocomplete="off"
          />
          <input
            class="btn btn-primary btn-big text-lato text-bold circle-plus"
            (click)="addDo()"
            value="+"
            readonly="readonly"
          />
        </div>
        <div class="col-12 no-padding">
          <div *ngIf="do.length > 0" class="box-container">
            <div *ngFor="let do_i of do">
              <div class="flex-align">
                <div class="col-auto box">
                  <div class="flex-align-center">
                    <div class="text-lato text-box">
                      {{ do_i }}
                    </div>
                    <div>
                      <input
                        class="btn btn-primary btn-big text-lato text-bold input-box"
                        value="x"
                        (click)="removeDo(do_i)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<p class="text-lato text-bold text-big text-darkgrey text-left">Do</p>
                        <textarea id="campaign_do"
                            class="form-control input-text text-lato text-medium text-darkgrey" name="do"
                            formControlName="do" style="width:calc(100% - 46px)" rows="3">
                            </textarea>-->
      </div>
      <div class="form-group col-md-6">
        <p
          class="text-lato text-bold text-big text-darkgrey text-right center-mobile"
        >
          {{ "CAMPAIGN.CREATE.STEP_3_TITLE_2" | translate }}
        </p>
        <div class="max-w-400 flex-align">
          <input
            type="text"
            placeholder="{{
              'CAMPAIGN.CREATE.STEP_3_DONT_PLACEHOLDER' | translate
            }}"
            class="input-text text-lato text-medium text-darkgrey"
            name="dont"
            formControlName="dont"
            autocomplete="off"
          />
          <input
            class="btn btn-primary btn-big text-lato text-bold circle-plus"
            (click)="addDont()"
            value="+"
            readonly="readonly"
          />
        </div>
        <div class="col-12 no-padding">
          <div *ngIf="dont.length > 0" class="box-container">
            <div *ngFor="let dont_i of dont">
              <div class="flex-align">
                <div class="col-auto box">
                  <div class="flex-align-center">
                    <div class="text-lato text-box">
                      {{ dont_i }}
                    </div>
                    <div>
                      <input
                        class="btn btn-primary btn-big text-lato text-bold input-box"
                        value="x"
                        (click)="removeDont(dont_i)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<textarea id="campaign_dont"
                            class="form-control input-text text-lato text-medium text-darkgrey" name="dont"
                            formControlName="dont" style="width:calc(100% - 46px)" rows="3">
                            </textarea>-->
      </div>

      <div class="col-12">
        <input
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
          (click)="addDo(); addDont()"
        />
      </div>
    </div>
  </form>
</div>
