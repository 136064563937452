export class ProjectHelper {
  static isRegularOwnerOrAdmin(user_id, project) {
    var result = false
    for (var i = 0; i < project.members.length; i++) {
      if (
        project.members[i].right === 'admin' ||
        project.members[i].right === 'owner'
      ) {
        if (project.members[i].user._id === user_id) {
          result = true
        }
      }
    }

    return result
  }
}
