import { Component, OnInit, Input } from '@angular/core'

@Component({
  templateUrl: 'network-list.component.html',
  selector: 'network-list',
  styleUrls: ['./network-list.component.scss'],
})
export class NetworkListComponent implements OnInit {
  @Input('data') data: any

  constructor() {}

  async ngOnInit() {}

  discoverInfluencer(node) {}

  getInfluencerLink(influencer) {
    //return 'https://twitter.com/' + influencer?._source?.profile?.screen_name
    return 'https://twitter.com/' + influencer?.screen_name
  }
}
