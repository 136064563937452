import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms'
import { Location } from '@angular/common'

import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

import { STATE } from './../../../../../../_models/validation/state'

@Component(
    {
        selector: 'campaign-create-progress-bar',
        templateUrl: 'campaign-create-progress-bar.component.html',
        styleUrls: ['./campaign-create-progress-bar.component.scss']
    }
)
export class CampaignCreationProgressBar implements OnInit {

    sections: any[]
    index_click: number
    id_css: any
    progress_bars: any[]

    constructor(public campaignReferentialService: CampaignReferentialService, public campaignCreationBufferService: CampaignCreationBufferService, private location: Location,
        private formBuilder: FormBuilder) {
        this.sections = ['0', '1', '2', '3', '4', '5', '6']
        this.index_click = undefined
        this.id_css = undefined
        this.progress_bars = []

        this.index_click = 0

        this.progress_bars = this.campaignReferentialService.getProgressSteps()
    }

    ngOnInit() {

    }

    navigate(i) {
        let state = this.campaignCreationBufferService.checkCampaignState(this.campaignCreationBufferService.getStep(), i)

        if (state == STATE.IS_VALID)
            this.campaignCreationBufferService.setStep(i)
        else
            this.campaignCreationBufferService.showAlert()

    }

}