import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms'
import { Location } from '@angular/common'

import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component(
    {
        selector: 'draft-mode-manager',
        templateUrl: 'draft-mode-manager.component.html',
        styleUrls: ['./draft-mode-manager.component.scss']
    }
)
export class DraftModeManagerComponent implements OnInit {

    draftModeForm: FormGroup

    constructor(public campaignCreationBufferService: CampaignCreationBufferService, private location: Location,
        private formBuilder: FormBuilder) {

    }

    ngOnInit() {
        this.draftModeForm = this.formBuilder.group({
            isDraft: [this.campaignCreationBufferService.hasDraftToBeActivated()],
        })
    }

    refreshDraftMode() {
        //this.campaignCreationBufferService.set
        let newStatus = !this.campaignCreationBufferService.hasDraftToBeActivated()
        this.campaignCreationBufferService.shouldActivateDraft(newStatus)
    }

}