import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { Campaign } from './../../../../../../_models/campaign/campaign.model'

import { ModalService } from '../../../../../../_services/modal.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'campaign-create-step-2',
  templateUrl: 'campaign-create-step-2.component.html',
  styleUrls: ['./campaign-create-step-2.component.scss'],
})
export class CampaignCreateStep2Component implements OnInit {
  newCampaignForm: FormGroup

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({
      date_start: [
        this.campaignCreationBufferService.campaignBeingCreated().date_start,
      ],
      date_end: [
        this.campaignCreationBufferService.campaignBeingCreated().date_end,
      ],
      budget: [
        this.campaignCreationBufferService.campaignBeingCreated().budget,
      ],
      budgetField: [
        this.campaignCreationBufferService.campaignBeingCreated().budget,
      ],
      numberOfFreeProducts: [
        this.campaignCreationBufferService.campaignBeingCreated()
          .numberOfFreeProducts,
      ],
      productDescription: [
        this.campaignCreationBufferService.campaignBeingCreated()
          .productDescription,
      ],
    })
  }

  onSubmitProject() {
    this.campaignCreationBufferService.campaignBeingCreated().date_start = this.newCampaignForm.controls.date_start.value
    this.campaignCreationBufferService.campaignBeingCreated().date_end = this.newCampaignForm.controls.date_end.value

    this.campaignCreationBufferService.moveForward()
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }

  changeDateStart(event) {
    this.campaignCreationBufferService.campaignBeingCreated().date_start =
      event.target.value
  }

  changeDateEnd(event) {
    this.campaignCreationBufferService.campaignBeingCreated().date_end =
      event.target.value
  }

  selectCompensation(compensation) {
    this.campaignCreationBufferService.campaignBeingCreated().compensation = compensation
  }

  changeBudget(event) {
    this.campaignCreationBufferService.campaignBeingCreated().budget = this.newCampaignForm.controls[
      'budget'
    ].value
    this.newCampaignForm.controls['budgetField'].setValue(
      this.newCampaignForm.controls['budget'].value
    )
  }

  changeBudgetField(event) {
    this.campaignCreationBufferService.campaignBeingCreated().budget = this.newCampaignForm.controls[
      'budgetField'
    ].value
    this.newCampaignForm.controls['budget'].setValue(
      this.newCampaignForm.controls['budgetField'].value
    )
  }

  updateNumberOfFreeProducts() {
    this.campaignCreationBufferService.campaignBeingCreated().numberOfFreeProducts = this.newCampaignForm.controls[
      'numberOfFreeProducts'
    ].value
  }

  updateProductDescription() {
    this.campaignCreationBufferService.campaignBeingCreated().productDescription = this.newCampaignForm.controls[
      'productDescription'
    ].value
  }

  getCampaignMinimalBudget() {
    return Campaign.getCampaignMinimalBudget()
  }

  getCampaignMaximalBudget() {
    return Campaign.getCampaignMaximalBudget()
  }

  getCampaignBudgetStep() {
    return Campaign.getCampaignBudgetStep()
  }
}
