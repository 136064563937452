<div class="content-page shadowed">
  <!--div class="row top-menu shadowed">
    <div class="col-8 title">Conversations</div>
    <div class="col-2 add-message">
      <img src="../../../../../assets/images/icon-add.png" alt="New message" />
    </div>
    <div class="col-2">
      <img src="../../../../../assets/images/icon-settings.png" alt="Options" />
    </div>
  </div-->

  <div class="row search-bar">
    <div class="col-12">
      Discussions
      <span
        _ngcontent-fnk-c284=""
        class="btn text-lato text-bold card-title no-margin"
        style="
          background-color: #ff006c;
          color: white;
          font-size: 13px;
          border-radius: 20px;
        "
        >+</span
      >
    </div>
  </div>
  <!--div class="row search-bar">
    <div class="col-12">
      <input
        type="text"
        class="form-control input-search"
        id="q"
        placeholder="Search conversations"
      />
    </div>
  </div-->
  <div
    class="row items-conversations"
    *ngFor="let conversation of getConversations(); let i = index"
    (click)="selectConversation(i)"
  >
    <div class="col-12 conversation-summary">
      <div class="user-avatar">
        <img
          [src]="getConversationUser(conversation).pictureUrl"
          *ngIf="shouldDisplayAvatarLetters(conversation) == false"
        />
        <div
          class="avatar-letters img-circle avatar-dark"
          *ngIf="shouldDisplayAvatarLetters(conversation) == true"
        >
          {{ getConversationUserFirstLetters(conversation) }}
        </div>
      </div>
      <div class="user-infos">
        <div class="user-name">
          {{ getConversationUser(conversation).displayName }}
        </div>
        <div class="user-message">
          {{ getConversationLastMessage(conversation) }}
        </div>
      </div>
    </div>
  </div>
</div>
