import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CurrencyHelper } from 'src/app/_helpers/referential/currency.helper'
import { APIService, StorageManagementService } from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'

import * as d3 from 'd3'
import { ForceLink, SimulationLinkDatum, SimulationNodeDatum } from 'd3'
import { TwitterNetworksMockService } from 'src/app/_services/mock/networks.mock.service'

@Component({
  templateUrl: 'network-graph.component.html',
  selector: 'network-graph',
  styleUrls: ['./network-graph.component.scss'],
})
export class NetworkGraphComponent implements OnInit {
  private data = {}
  private svg
  private tip
  private margin = {
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
  }
  private width = 700
  private height = 700

  constructor(private twitterNetworksMockService: TwitterNetworksMockService) {}

  async ngOnInit() {
    await this.twitterNetworksMockService.initService()
    this.data = this.twitterNetworksMockService.getNetwork('ai')
    this.createSvg()
    this.drawChart()
  }

  private createSvg(): void {
    this.svg = d3
      .select('figure#network')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.top + ',' + this.margin.left + ')'
      )
  }

  private drawChart(): void {
    class Node implements SimulationNodeDatum {
      public x: number
      public y: number

      constructor(
        public id: string,
        public colour: string,
        public twitter: string,
        public name: string
      ) {
        this.id = id
        this.colour = colour
        this.twitter = twitter
        this.name = name
      }
    }

    class Link implements SimulationLinkDatum<Node> {
      constructor(public source: Node, public target: Node) {
        this.source = source
        this.target = target
      }
    }

    let simulation = d3
      .forceSimulation<Node, Link>()
      // pull nodes together based on the links between them
      .force(
        'link',
        d3
          .forceLink()
          .id(function (d) {
            return d.index
          })
          .strength(0.025)
      )
      // push nodes apart to space them out
      .force('charge', d3.forceManyBody().strength(-400))
      // add some collision detection so they don't overlap
      .force('collide', d3.forceCollide().radius(12))
      // and draw them around the centre of the space
      .force('center', d3.forceCenter(this.width / 2, this.height / 2))

    // set the nodes
    // add the nodes to the simulation and
    // tell it what to do on each tick

    let nodes = this.data['nodes'].map(
      (node) => new Node(node.id, node.infos.colour, node.twitter, node.name)
    )
    // links between nodes
    let links = this.data['links'].map(
      (link) =>
        new Link(
          nodes.filter((node) => link.source === node.id)[0],
          nodes.filter((node) => link.target === node.id)[0]
        )
    )

    // add the curved links to our graphic
    var link = this.svg
      .selectAll('.link')
      .data(links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('stroke', function (d) {
        return '#ddd'
      })

    // add the nodes to the graphic
    var node = this.svg.selectAll('.node').data(nodes).enter().append('g')
    var _this = this
    // a circle to represent the node
    node
      .append('circle')
      .attr('class', 'node')
      .attr('r', 8)
      .attr('id', function (d) {
        return d.id
      })
      .attr('fill', function (d) {
        return d.colour
      })
      .attr('stroke-width', 2)
      .attr('stroke', function (d) {
        return d.colour
      })
      .on('mouseover', mouseOver(0.1))
      .on('mouseout', mouseOut)
      .on('click', function (d) {
        let originNode = nodes.filter((node) => node.id === d.target.id)[0]

        if (_this.tip) _this.tip.remove()

        _this.tip = _this.svg
          .append('g')
          .attr(
            'transform',
            'translate(' + (d.x - 150) + ',' + (d.y - 40) + ')'
          )

        var rect = _this.tip
          .append('rect')
          .style('fill', 'white')
          .style('stroke', 'steelblue')

        _this.tip
          .append('text')
          .text('Name: ' + originNode.name)
          .attr('dy', '1em')
          .attr('x', 5)

        _this.tip
          .append('text')
          .text('Twitter: @' + originNode.twitter)
          .attr('dy', '2em')
          .attr('x', 5)

        /*var con = _this.data['links']
          .filter(function (d1) {
            return d1.source.id === d.id
          })
          .map(function (d1) {
            return d1.target.name + ' with weight ' + d1.weight
          })

        _this.tip
          .append('text')
          .text('Connected to: ' + con.join(','))
          .attr('dy', '3em')
          .attr('x', 5)*/

        var bbox = _this.tip.node().getBBox()
        rect.attr('width', bbox.width + 5).attr('height', bbox.height + 5)
      })

    // hover text for the node
    node.append('title').text(function (d) {
      return '@' + d.twitter
    })

    // add a label to each node
    node
      .append('text')
      .attr('dx', 12)
      .attr('dy', '.35em')
      .text(function (d) {
        return d.name
      })
      .style('stroke', 'black')
      .style('stroke-width', 0.5)
      .style('fill', function (d) {
        return d.colour
      })

    simulation.nodes(nodes).on('tick', ticked)

    // add the links to the simulation
    simulation.force<ForceLink<Node, Link>>('link').links(links)

    // on each tick, update node and link positions
    function ticked() {
      link.attr('d', positionLink)
      node.attr('transform', positionNode)
    }

    // links are drawn as curved paths between nodes
    function positionLink(d) {
      var offset = 30

      var midpoint_x = (d.source.x + d.target.x) / 2
      var midpoint_y = (d.source.y + d.target.y) / 2

      var dx = d.target.x - d.source.x
      var dy = d.target.y - d.source.y

      var normalise = Math.sqrt(dx * dx + dy * dy)

      var offSetX = midpoint_x + offset * (dy / normalise)
      var offSetY = midpoint_y - offset * (dx / normalise)

      return (
        'M' +
        d.source.x +
        ',' +
        d.source.y +
        'S' +
        offSetX +
        ',' +
        offSetY +
        ' ' +
        d.target.x +
        ',' +
        d.target.y
      )
    }

    // move the node based on forces calculations
    function positionNode(d) {
      // keep the node within the boundaries of the svg
      if (d.x < 0) {
        d.x = 0
      }
      if (d.y < 0) {
        d.y = 0
      }
      if (d.x > this.width) {
        d.x = this.width
      }
      if (d.y > this.height) {
        d.y = this.height
      }
      return 'translate(' + d.x + ',' + d.y + ')'
    }

    // build a dictionary of nodes that are linked
    var linkedByIndex = {}
    links.forEach(function (d) {
      linkedByIndex[d.source.id + ',' + d.target.id] = 1
    })

    // check the dictionary to see if nodes are linked
    function isConnected(a, b) {
      let result =
        linkedByIndex[a.id + ',' + b.id] ||
        linkedByIndex[b.id + ',' + a.id] ||
        a.id == b.id

      return result
    }

    // fade nodes on hover
    function mouseOver(opacity) {
      return function (d) {
        // check all other nodes to see if they're connected
        // to this one. if so, keep the opacity at 1, otherwise
        // fade
        let originNode = nodes.filter((node) => node.id === d.target.id)[0]
        node.style('stroke-opacity', function (o) {
          let thisOpacity = isConnected(originNode, o) ? 1 : opacity
          return thisOpacity
        })
        node.style('fill-opacity', function (o) {
          let thisOpacity = isConnected(originNode, o) ? 1 : opacity
          return thisOpacity
        })
        // also style link accordingly
        link.style('stroke-opacity', function (o) {
          return o.source.id === originNode.id || o.target.id === originNode.id
            ? 1
            : opacity
        })
      }
    }

    function mouseOut() {
      node.style('stroke-opacity', 1)
      node.style('fill-opacity', 1)
      link.style('stroke-opacity', 1)
      link.style('stroke', '#ddd')
    }
  }
}
