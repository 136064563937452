import { User } from '../user/user.model'

export enum ConversationParticipantStatus {
  UNKNOWN = 'unknown',
  PRESENT = 'present',
  LEFT = 'left',
}

export enum ConversationParticipantRole {
  UNKNOWN = 'unknown',
  ADMIN = 'admin',
  ADVERTISER = 'advertiser',
  PUBLISHER = 'publisher',
}

export class ConversationParticipant {
  private _id: string
  /*private _displayName: string
  private _pictureUrl: string*/
  private _user: any
  private _joinedAt: number
  private _awareness: number
  private _status: ConversationParticipantStatus
  private _role: ConversationParticipantRole

  constructor(
    id: string,
    user: any,
    joinedAt: number,
    awareness: number,
    status: ConversationParticipantStatus,
    role: ConversationParticipantRole
  ) {
    this._id = id
    /*this._displayName = displayName
    this._pictureUrl = pictureUrl*/
    this._user = user
    this._joinedAt = joinedAt
    this._awareness = awareness
    this._status = status
    this._role = role
  }

  public get id(): string {
    return this._id
  }

  public get user(): any {
    return this._user
  }

  public get displayName(): string {
    return this._user.infos.fullName
  }

  /*public set displayName(displayName: string) {
    this._displayName = displayName
  }*/

  public get pictureUrl(): string {
    return this._user.infos.profilePictureURL
  }

  /*public set pictureUrl(pictureUrl: string) {
    this._pictureUrl = pictureUrl
  }*/

  public get joinedAt(): number {
    return this._joinedAt
  }

  public set joinedAt(joinedAt: number) {
    this._joinedAt = joinedAt
  }

  public get awareness(): number {
    return this._awareness
  }

  public set awareness(awareness: number) {
    this._awareness = awareness
  }

  public get status(): ConversationParticipantStatus {
    return this._status
  }

  public set status(status: ConversationParticipantStatus) {
    this._status = status
  }

  public setStatus(status: string) {
    switch (status) {
      case 'present':
        this._status = ConversationParticipantStatus.PRESENT
        break
      case 'left':
        this._status = ConversationParticipantStatus.LEFT
        break
      default:
        this._status = ConversationParticipantStatus.UNKNOWN
    }
  }

  public get role(): ConversationParticipantRole {
    return this._role
  }

  public set role(role: ConversationParticipantRole) {
    this._role = role
  }

  public setRole(role: string) {
    switch (role) {
      case 'admin':
        this._role = ConversationParticipantRole.ADMIN
        break
      case 'advertiser':
        this._role = ConversationParticipantRole.ADVERTISER
        break
      case 'publisher':
        this._role = ConversationParticipantRole.PUBLISHER
        break
      default:
        this._role = ConversationParticipantRole.UNKNOWN
    }
  }
}
