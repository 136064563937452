import { Component, HostListener, Input } from '@angular/core'
import { TaskSynchronisationService } from 'src/app/_services/collaborations/task.synchronisation.service'
import { DateHelper } from '../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'collaborations-kanban-item.component.html',
  selector: 'collaborations-kanban-item',
  styleUrls: ['./collaborations-kanban-item.component.scss'],
})
export class CollaborationsKanbanItemComponent {
  @Input('task') task: any

  pastDueDate: boolean
  assignee: any
  isSelectedTask: boolean

  constructor(private taskSynchronisationService: TaskSynchronisationService) {
    this.pastDueDate = false
    this.isSelectedTask = false
  }

  public convertDoubleToReadableDate(t) {
    if (t == 0) return 'N/A'
    return DateHelper.convertDoubleToReadableDate(t)
  }

  ngOnInit() {
    this.taskSynchronisationService.getEditableTasks().subscribe((tasks) => {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].taskId === this.task.id) {
          this.task = tasks[i].task
        }
      }
    })
    this.checkIfDueDateIsPast(this.task._task.dueDate)
    this.assigneeTo(this.task._task.assignee, this.task._task)

    // Add overlay to selected task
    this.taskSynchronisationService.getEditedTask().subscribe((task) => {
      let currentTask = task
      if (currentTask != undefined) {
        if (currentTask.taskId == this.task.taskId) {
          this.isSelectedTask = true
        } else {
          this.isSelectedTask = false
        }
      }
    })
  }

  // if due date is past => date = red
  checkIfDueDateIsPast(date) {
    let currentDate = moment().format('x')
    if (date < currentDate) {
      this.pastDueDate = true
    }
  }

  // Check if owner is brand or publisher
  async assigneeTo(owner, task) {
    if (owner === 'brand') {
      this.assignee = task.campaignPictureURL
    } else if (owner === 'publisher') {
      let publisher = await this.taskSynchronisationService.getPublisher()
      this.assignee = publisher.profilePictureURL
    }
  }
}
