import { Component, OnInit } from '@angular/core'

import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router'

import { APIService } from '../../../_services/api.service'
import { AuthenticationService } from '../../../_services/authentication/authentication.service'
import { CryptrAuthenticationService } from '../../../_services/authentication/cryptr.authentication.service'

import { USER_ROLE } from './../../../_helpers/referential/user.role'
import { UserRoleHelper } from '../../../_helpers/user.role.helper'
import { AuthService } from '@cryptr/cryptr-angular'
import { TokenService } from 'src/app/_services/token.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { environment } from 'src/environments/environment'
import {
  PublishersProfileAPIService,
  StorageManagementService,
} from 'src/app/_services'

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  state: any

  isLoading: boolean = true

  isRequesting: boolean = false
  profileForm: FormGroup

  isValid: boolean

  selectedProfile: File
  profileSrc: any

  user: any

  hasCheckedCryptrAuth: boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private authService: AuthService,
    private tokenService: TokenService,
    private formBuilder: FormBuilder,
    private publishersProfileAPIService: PublishersProfileAPIService,
    private cryptrAuthenticationService: CryptrAuthenticationService,
    private authenticationService: AuthenticationService,
    private storageManagementService: StorageManagementService
  ) {
    this.state = {
      authProvider: undefined,
      authState: undefined,
    }

    this.profileSrc = undefined

    this.isValid = true

    this.hasCheckedCryptrAuth = false
  }

  async ngOnInit() {
    try {
      this.profileForm = this.formBuilder.group({
        firstname: ['', Validators.required],
        name: ['', Validators.required],
        profileUrl: ['', Validators.required],
      })

      this.guessAuthProvider()
      if (this.state['authProvider'] === 'fns') {
        await this.checkFNSAuth()
      } else {
        console.log('Cryptr auth')
        await this.checkCryptrAuth()
      }
    } catch (e) {
      console.log(e)

      this.authenticationService.logout()
      this.router.navigate(['/login'])
    }
  }

  ngOnDestroy() {}

  guessAuthProvider() {
    // IF HAS A FNS TOKEN IT'S FNS THAT AUTHS
    this.state['authProvider'] = this.authenticationService.guessAuthProvider()
  }

  async checkFNSAuth() {
    this.state['authState'] = 'loggedOut'

    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      var result = await this.tokenService.examineToken()
      if (result == true) this.state['authState'] = 'loggedIn'
    }

    await this.afterManagement()
  }

  async checkCryptrAuth() {
    //console.log(this.authService.canHandleAuthentication())
    //let isAuthenticated = await this.authService.isAuthenticated()
    //console.log(isAuthenticated)
    //await this.authService.checkAuthentication()
    this.state['authState'] = 'loggedOut'

    let self = this
    await this.cryptrAuthenticationService.checkCryptrAuth(
      self,
      this.afterCryptrManagement
    )
    /*this.authService
      .authenticationInProgress()
      .subscribe(async (isInProgress) => {
        if (isInProgress == true) {
          // WAIT
          console.log('Cryptr auth in progress')
        } else {
          if (this.hasCheckedCryptrAuth == false) {
            this.hasCheckedCryptrAuth = true
            console.log('Cryptr auth done')
            let isAuthenticated = await this.authService.isAuthenticated()
            if (isAuthenticated == true) this.state['authState'] = 'loggedIn'

            console.log('isLogged: ' + this.state['authState'])
            await this.afterManagement()
          }
        }
      })*/
  }

  async afterCryptrManagement(self, isAuthenticated) {
    if (isAuthenticated == true) self.state['authState'] = 'loggedIn'
    console.log('isLogged: ' + self.state['authState'])
    await self.afterManagement()
  }

  async afterManagement() {
    try {
      if (this.state['authState'] === 'loggedIn') {
        let res = await this.apiService.getMyProfile()
        this.storageManagementService.setCurrentUserId(res.body.user?._id)
        if (UserRoleHelper.getTokenUserRole() === USER_ROLE.ADVERTISER) {
          if (res.body.projects.length > 0) {
            if (this.storageManagementService.hasBrandspaceId() == false) {
              this.storageManagementService.setCurrentBrandspaceId(
                res.body.projects[0]._id
              )
            }
          }
        } else if (UserRoleHelper.getTokenUserRole() === USER_ROLE.PUBLISHER) {
          this.storageManagementService.setCurrentPublisherId(
            res.body.publisher?._id
          )
        }
        if (this.state['authProvider'] === 'fns') {
          if (UserRoleHelper.getTokenUserRole() === USER_ROLE.ADVERTISER) {
            if (res.body && res.body.projects) {
              if (res.body.projects.length > 0) {
                if (environment.name === 'ADVERTISER') {
                  this.router.navigate([
                    '/projects/' +
                      this.storageManagementService.getCurrentBrandspaceId() +
                      '/dashboard',
                  ])
                } else {
                  if (this.storageManagementService.getCurrentBrandspaceId()) {
                    this.router.navigate([
                      '/workspaces/' +
                        this.storageManagementService.getCurrentBrandspaceId() +
                        '/keywords/plans',
                    ])
                  } else {
                    this.router.navigate(['/journey/start'])
                  }
                }
                return
              } else {
                if (
                  environment.name === 'ADVERTISER' ||
                  environment.name === 'ADVERTISER_V2'
                )
                  this.router.navigate(['/journey/start'])
                else this.router.navigate(['/welcome'])
                return
              }
            }
          }

          if (UserRoleHelper.getTokenUserRole() === USER_ROLE.PUBLISHER) {
            this.user = res.body
            if (this.shouldDisplayInfoForm(this.user) == true) {
              this.profileForm
                .get('firstname')
                .setValue(this.getUserFirstName(this.user))
              this.profileForm
                .get('name')
                .setValue(this.getUserLastName(this.user))
              this.isLoading = false
            } else this.router.navigate(['/dashboard'])
          }
        } else {
          // cryptr authentication - only for influencers
          this.user = res.body
          if (this.shouldDisplayInfoForm(this.user) == true) {
            this.profileForm
              .get('firstname')
              .setValue(this.getUserFirstName(this.user))
            this.profileForm
              .get('name')
              .setValue(this.getUserLastName(this.user))
            this.isLoading = false
          } else {
            this.router.navigate(['/dashboard'])
            console.log('has navigated')
          }
        }
      } else {
        const returnURL: string =
          '/' + this.route.snapshot.url.map((segment) => segment.path).join('/')
        this.router.navigate(['/login'], {
          queryParams: { returnUrl: returnURL },
        })
      }
    } catch (e) {
      //console.log(e)
      this.authenticationService.logout()
      this.router.navigate(['/login'])
    }
  }

  shouldDisplayInfoForm(user): boolean {
    if (
      user?.user?.infos == undefined ||
      user?.user?.infos.firstname == undefined ||
      user?.user?.infos.firstname === '' ||
      user?.user?.infos.lastname == undefined ||
      user?.user?.infos.lastname === ''
    )
      return true
    return false
  }

  getPublisherId(user) {
    console.log(user)
    return user?.publisher?._id || undefined
  }

  getUserFirstName(user) {
    return user?.user?.infos?.firstname || ''
  }

  getUserLastName(user) {
    return user?.user?.infos?.lastname || ''
  }

  getUserEmail(user) {
    return user?.user?.infos?.email
  }

  getUserProfilePictureUrl(user) {}

  async onSubmitUser() {
    try {
      this.isRequesting = true
      var candidate_firstname = this.profileForm.controls.firstname.value
      var candidate_name = this.profileForm.controls.name.value

      this.isValid = true

      if (candidate_firstname != undefined) {
        candidate_firstname = candidate_firstname.trim()
        if (candidate_firstname.length > 0) {
        } else {
          this.isValid = false
        }
      } else {
        this.isValid = false
      }

      if (candidate_name != undefined) {
        candidate_name = candidate_name.trim()
        if (candidate_name.length > 0) {
        } else {
          this.isValid = false
        }
      } else {
        this.isValid = false
      }

      if (this.isValid) {
        if (environment.role === USER_ROLE.PUBLISHER) {
          // set user infos with this.selectedProfile, candidate_firstname & candidate_name
          let updateRequest = await this.publishersProfileAPIService.updateGeneralInformation(
            this.getPublisherId(this.user),
            {
              email: this.getUserEmail(this.user),
              firstname: candidate_firstname,
              lastname: candidate_name,
            },
            this.selectedProfile
          )

          if (updateRequest.status == 200) this.router.navigate(['/dashboard'])
          else {
            this.isRequesting = false
          }
        }

        if (environment.role === USER_ROLE.ADVERTISER) {
        }
      } else {
        this.isRequesting = false
      }
    } catch (e) {
      console.log(e)
      this.isRequesting = false
    }
  }

  onProfileChanged(event) {
    const reader = new FileReader()
    reader.onload = (e) => {
      this.profileSrc = reader.result

      this.selectedProfile = event.target.files[0]
    }

    reader.readAsDataURL(event.target.files[0])
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
