import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from '../../../../_services/api.service'
import { DataService } from '../../../../_services/data.service'
import { IndustryHelper } from '../../../../_helpers/referential/industry.helper'

@Component({
  templateUrl: 'project_brand.component.html',
  styleUrls: ['./project_brand.component.scss'],
})
export class ProjectBrandComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  project: any
  campaigns: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService
  ) {
    this.isLoading = true
    this.showSomething = false
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['projectId']

      this.project = this.dataService.getProject(this.id)

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  editBrand() {
    this.router.navigate(['/projects/' + this.project._id + '/brand/edit'])
  }

  getIndustry(industry) {
    return IndustryHelper.getIndustryText(industry)
  }

  async downloadBrandPresentation() {
    let res = await this.apiService.getBrandReport(this.project._id)
    const blob = new Blob([res], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }
}
