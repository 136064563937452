import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { USER_ROLE } from './_helpers/referential/user.role'
import { AuthGuard, RoleGuard } from './_guards'

import { JourneyStartComponent } from './_components/private/journey_start'
import { ProjectCreateComponent } from './_components/private/projects/project_create'
import { ProjectDashboardComponent } from './_components/private/projects/project_dashboard'
import { ProjectSettingsComponent } from './_components/private/projects/project_settings'
import { ProjectSettingsEditComponent } from './_components/private/projects/project_settings_edit'
import { ProjectBrandComponent } from './_components/private/projects/project_brand'
import { ProjectBrandEditComponent } from './_components/private/projects/project_brand_edit'
import { ProjectBrandAuthorityComponent } from './_components/private/projects/project_brand_authority'
import { ProjectBillingComponent } from './_components/private/projects/project_billing'
import { ProjectBillingEditComponent } from './_components/private/projects/project_billing_edit'

import { CampaignCreateComponent } from './_components/private/campaigns/campaign_create'
import { CampaignAnalysisComponent } from './_components/private/campaigns/campaign_analysis'
import { CampaignBriefComponent } from './_components/private/campaigns/campaign_brief'
import { CampaignPublicationsComponent } from './_components/private/campaigns/campaign_publications'
import { CampaignScalersComponent } from './_components/private/campaigns/campaign_scalers'
import { CampaignAllComponent } from './_components/private/campaigns/campaign_all'
import { CampaignPdfComponent } from './_components/private/campaigns/campaign_pdf'

import { ScalerProfileComponent } from './_components/private/scaler_profile'

import { UserResolver } from './_resolvers/user.resolver'

const BRANDS_ROUTES: Routes = [
  {
    path: 'journey/start',
    component: JourneyStartComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/create',
    component: ProjectCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/dashboard',
    component: ProjectDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/settings',
    component: ProjectSettingsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/settings/edit',
    component: ProjectSettingsEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/brand',
    component: ProjectBrandComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/brand/edit',
    component: ProjectBrandEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/authority',
    component: ProjectBrandAuthorityComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/billing',
    component: ProjectBillingComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      members: ['owner', 'admin'],
    },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/billing/edit',
    component: ProjectBillingEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      requiresLogin: true,
      requiredRole: USER_ROLE.ADVERTISER,
      members: ['owner', 'admin'],
    },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaign/new',
    component: CampaignCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/brief',
    component: CampaignBriefComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/edit',
    component: CampaignCreateComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/analytics',
    component: CampaignAnalysisComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/publications',
    component: CampaignPublicationsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/scalers',
    component: CampaignScalersComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/scalers/:scalerId',
    component: ScalerProfileComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/all',
    component: CampaignAllComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
  {
    path: 'projects/:projectId/campaigns/:campaignId/pdf',
    component: CampaignPdfComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.ADVERTISER },
    resolve: { user: UserResolver },
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(BRANDS_ROUTES, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppBrandsRoutingModule {}
