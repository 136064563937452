/**
 *
 * @export
 * @class CampaignCreationNavigation
 */
export class CampaignCreationNavigation {
  /**
   * hasToShowAlert is an instance that will contain the value to show or not alert
   * @private
   * @type {boolean}
   * @memberof CampaignCreationNavigation
   */
  private hasToShowAlert: boolean

  /**
   * fromStep is an instance of the step where you from in campaign creation
   * @private
   * @type {number}
   * @memberof CampaignCreationNavigation
   */
  private fromStep: number

  /**
   * toStep is an instance of the step where you move to in campaign creation
   * @private
   * @type {number}
   * @memberof CampaignCreationNavigation
   */
  private toStep: number

  /**
   * Creates an instance of CampaignCreationNavigation.
   * @memberof CampaignCreationNavigation
   */
  public constructor() {
    this.hasToShowAlert = false
    this.fromStep = 0
    this.toStep = 0
  }

  /**
   * Sets hasToShowAlert instance as a boolean
   * @param {boolean} hasToShowAlert
   * @memberof CampaignCreationNavigation
   */
  public showAlert(hasToShowAlert: boolean): void {
    this.hasToShowAlert = hasToShowAlert
  }

  /**
   * Returns hasToShowAlert instance as a boolean
   * @return {*}  {boolean}
   * @memberof CampaignCreationNavigation
   */
  public shouldShowAlert(): boolean {
    return this.hasToShowAlert
  }

  /**
   * Sets fromStep instance as a number
   * @param {number} fromStep
   * @memberof CampaignCreationNavigation
   */
  public setFromStep(fromStep: number): void {
    this.fromStep = fromStep
  }

  /**
   * Returns fromStep instance as a number
   * @return {*}  {number}
   * @memberof CampaignCreationNavigation
   */
  public getFromStep(): number {
    return this.fromStep
  }

  /**
   * Sets toStep instance as a number
   * @param {number} toStep
   * @memberof CampaignCreationNavigation
   */
  public setToStep(toStep: number): void {
    this.toStep = toStep
  }

  /**
   * Returns toStep instance as a number
   * @return {*}  {number}
   * @memberof CampaignCreationNavigation
   */
  public getToStep(): number {
    return this.toStep
  }
}
