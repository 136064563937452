import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Observable, from } from 'rxjs'

import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(public translateService: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next))
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    // GET LANGUAGE

    // ADD LANGUAGE HEADER
    request = request.clone({
      setHeaders: {
        language: `${this.translateService.currentLang}`,
      },
    })

    return next.handle(request).toPromise()
  }
}
