import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { AdvertisersOffersAPIService } from 'src/app/_services'

@Injectable()
export class AdvertisersOffersResolver implements Resolve<any> {
  constructor(
    private advertisersOffersAPIService: AdvertisersOffersAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const workspaceId = <string>route.paramMap.get('workspaceId')
      let request = await this.advertisersOffersAPIService.getOffers(
        workspaceId
      )

      return request.body.campaigns
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
