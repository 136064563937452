<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Expertises</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addExpertise()"></add-button>
        <edit-button
          class="edit-expertise"
          (click)="updateExpertise()"
          *ngIf="getNumberOfExpertises() > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center padding-expertise-section">
      <div class="no-expertise" *ngIf="getNumberOfExpertises() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>
          Which are the industries where you are considered as an expert by your
          audience?
        </div>
        <div></div>
        <tiny-cta
          (click)="addExpertise()"
          value="ADD YOUR FIRST EXPERTISE"
        ></tiny-cta>
      </div>
      <span
        class="text-lato text-small text-darkgrey expertises-section"
        *ngFor="let expertise of getExpertises()"
      >
        <span class="expertise-field">{{
          getCorrespondingExpertiseName(expertise.key)
        }}</span>
      </span>
    </div>
  </div>
</div>

<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddExpertise"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddExpertise"
      aria-hidden="false"
      (click)="closeModal()"
      style="overflow-x: hidden; overflow-y: auto; margin-top: 40px"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddExpertiseTitle">
                    {{ 'Add a expertise' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="section-container-inner"
            style="
              overflow-x: hidden;
              overflow-y: auto;
              max-height: calc(100vh - 220px);
            "
          >
            <form [formGroup]="expertisesForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Expertise name</div>
                  <div class="input-value container-field">
                    <input
                      class="input-field autocomplete-field"
                      type="text"
                      placeholder="Expertise name"
                      name="expertiseName"
                      formControlName="expertiseName"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let expertise of filteredOptions | async"
                        [value]="expertise"
                      >
                        {{ expertise }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <div
                    class="text-pink text-xxsmall"
                    *ngIf="expertiseField === ''"
                  >
                    Expertise name cannot be empty when you save
                  </div>
                </div>
              </div>
              <div
                class="row center expertises-list-title"
                *ngIf="suggestedExpertises?.length > 0"
              >
                <div class="input-title">Popular expertises</div>
              </div>
              <div
                class="row center expertises-list"
                *ngIf="suggestedExpertises?.length > 0"
              >
                <div
                  class="text-lato text-small text-darkgrey expertises-section"
                  *ngFor="let expertise of suggestedExpertises"
                >
                  <span
                    class="expertise-field expertise-field-cursor"
                    (click)="addExpertiseToProfile(expertise)"
                    >{{ expertise.value }}
                    <span class="icon-delete">+</span></span
                  >
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your expertise have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your expertise was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateExpertise"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateExpertise"
      aria-hidden="false"
      (click)="closeModal()"
      style="overflow-x: hidden; overflow-y: auto; margin-top: 40px"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato"
                    id="modalUpdateExpertiseTitle"
                  >
                    {{ 'Delete expertise' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner expertise-edit-container">
            <form [formGroup]="expertisesForm">
              <div class="row center padding-expertise-section">
                <span
                  class="text-lato text-small text-darkgrey expertises-section"
                  *ngFor="let expertise of getExpertises()"
                >
                  <span class="expertise-field"
                    >{{ getCorrespondingExpertiseName(expertise.key) }}
                    <span
                      class="icon-delete"
                      (click)="deleteExpertise(expertise)"
                      >x</span
                    ></span
                  >
                </span>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your expertise have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your expertise was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
