import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from './../../../_services/api.service'
import { CacheService } from './../../../_services/cache.service'
import { DataService } from './../../../_services/data.service'
import { ModalService } from './../../../_services/modal.service'
import { CampaignCreationBufferService } from './../../../_services/buffers/campaign.creation.buffer.service'
import { CampaignHelper } from 'src/app/_helpers/campaign.helper'

@Component({
  templateUrl: 'campaign-summary.component.html',
  selector: 'campaign-summary',
  styleUrls: ['./campaign-summary.component.scss'],
})
export class CampaignSummaryComponent implements OnInit {
  campaignId: any
  campaign: any

  project_id: any

  modalSub: any

  status: string

  sub: any

  constructor(
    private apiService: APIService,
    private cacheService: CacheService,
    private dataService: DataService,
    private modalService: ModalService,
    private campaignCreationService: CampaignCreationBufferService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.campaignId = params['campaignId']
      //this.campaign = await this.cacheService.getCampaign(this.campaignId)

      var projects = this.dataService.getProjects()
      this.campaign = undefined
      for (var i = 0; i < projects.length; i++) {
        for (var j = 0; j < projects[i].campaigns.length; j++) {
          if (projects[i].campaigns[j]._id === this.campaignId) {
            this.campaign = projects[i].campaigns[j]
            break
          }
        }
      }

      if (params['projectId']) {
        this.project_id = params['projectId']
      }

      if (this.campaign)
        this.status = CampaignHelper.getCampaignState(this.campaign)
    })

    this.modalSub = this.modalService.getModals().subscribe(async (object) => {
      if (object.event && object.event === 'campaign-killed') {
        if (this.campaign) {
          this.campaign.status = 'finished'
          this.status = CampaignHelper.getCampaignState(this.campaign)
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.modalSub) this.modalSub.unsubscribe()

    if (this.sub) this.sub.unsubscribe()
  }

  async cutCampaign() {
    //this.apiService.deleteCampaign(this.campaign._id)
    this.modalService.toggleModal('kill-campaign')
  }

  async editCampaign() {
    //console.log('/projects/' + this.project_id + '/campaigns/' +this.campaignId + '/edit')
    if (this.campaignCreationService.campaignBeingCreated().id == undefined)
      this.campaignCreationService.init()

    if (
      this.campaignCreationService.campaignBeingCreated().id != undefined &&
      this.campaignId !== this.campaignCreationService.campaignBeingCreated().id
    )
      this.campaignCreationService.init()

    this.router.navigate([
      '/projects/' +
        this.project_id +
        '/campaigns/' +
        this.campaignId +
        '/edit',
    ])
  }

  getCampaignName() {
    if (this.campaign) return this.campaign.title
    else return ''
  }

  getCampaignStatus() {
    if (this.campaign) return this.campaign.status
    else return ''
  }

  getCampaignStatusText() {
    if (this.campaign) return CampaignHelper.getCampaignState(this.campaign)
    else return ''
  }
}
