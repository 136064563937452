import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class AdvertisersRelationshipsAPIService {
  constructor(private http: HttpClient) {}

  async getRelationships(workspaceId) {
    var url =
      BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/relationships/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateRelationship(workspaceId, relationshipId, status) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/relationships/' +
      relationshipId

    var res = await this.http
      .post<any>(url, { status: status }, { observe: 'response' })
      .toPromise()
    return res
  }

  async getRelationshipRequests(workspaceId) {
    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/requests/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getPendingRelationshipRequests(workspaceId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/requests?status=created'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async sendRelationshipRequest(workspaceId, publisherId) {
    const requestJson = {
      publisherId: publisherId,
    }

    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/requests/'

    var res = await this.http
      .post<any>(url, requestJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async cancelRelationshipRequest(workspaceId, requestId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/requests/' +
      requestId

    var res = await this.http
      .delete<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }
}
