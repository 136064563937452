import { Component, OnInit } from '@angular/core';

import { Location } from '@angular/common'

import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component(
    {
        selector: 'close-view',
        templateUrl: 'close-view.component.html',
        styleUrls: ['./close-view.component.scss']
    }
)
export class CloseViewComponent implements OnInit {


    constructor(public campaignCreationBufferService: CampaignCreationBufferService, private location: Location) {

    }

    ngOnInit() {

    }

    closeWindow() {
        this.location.back()
    }

}