import { Injectable } from '@angular/core'

@Injectable()
export class AlertService {
  private alerts: any

  constructor() {
    this.alerts = []
  }

  getAlerts() {
    return this.alerts
  }

  resetAlerts() {
    this.alerts = []
  }

  pushAlert(type, message) {
    this.alerts.push({
      type: type,
      message: message,
    })
  }

  pushErrorAlert(message) {
    this.pushAlert('error', message)
  }

  pushMessageAlert(message) {
    this.pushAlert('message', message)
  }

  removeAlert(alert) {
    var i = this.alerts.indexOf(alert)
    this.alerts.splice(i, 1)
  }
}
