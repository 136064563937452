<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-cart.png" style="width: 40px; padding-left: 10px; padding-right: 10px;">
            <span style="position: absolute; top: 24px;">FACTURATION</span>
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png" style="width: 16px"
                    (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="col-12" style="margin-right: 21px">
                <div class="row">
                    <div class="col-6 text-lato" style="color: #484c50; font-size: 26px; margin-bottom: 12px">
                        Toutes mes factures
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 26px; margin-bottom: 12px">
                        <div style="width: 100%; margin-top: -20px">
                            <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px; text-align: right;">
                                <input class="btn btn-primary btn-big text-lato text-bold" type="submit" value="TELECHARGER TOUTES LES FACTURES" style="font-size: 13px;padding-left: 32.5px;padding-right: 32.5px; font-weight: 100">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

                <div class="row" style="margin-left: 0px; margin-right: 0px; margin-top: 5px; margin-bottom: 5px; 
                border-top-color: #a5a5a5; border-top-style: solid; border-top-width: 1px">
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 18px; padding: 20px; padding-left: 0px; padding-right: 0px;">
                        <span style="font-weight: 100">Décembre 2018 / </span><span style="font-weight: 500">2860,00€</span>
                    </div>
                    <div class="col-6 text-lato" style="color: #30313e; font-size: 16px; padding: 20px; padding-left: 0px; padding-right: 0px; text-align: right">
                        <span>Télécharger la facture PDF </span>
                        <img src="./assets/images/icon-download.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                    </div>
                </div>

            </div>
        </div>

    </div>