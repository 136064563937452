import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-deals.component.html',
  styleUrls: ['./publisher-deals.component.scss'],
})
export class PublisherDealsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  deals: any[]

  selectedMenuItem: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.deals = route.snapshot.data.deals
    this.selectedMenuItem = 'board'
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  manageMenuItem(newValue) {
    this.selectedMenuItem = newValue
  }
}
