<div style="width: 100%">
    <div style="height: 40px; width: 100%; padding-left: 15px; padding-right: 15px; background-color: white; ">
        <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
            <img src="./assets/images/icon-help-white.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
            <span style="position: absolute; top: 22px;">FAQ / SUPPORT</span>
            <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png" style="width: 16px"
                    (click)="closeModal()"></span>
        </div>
    </div>
    <div style="width: 100%; padding-top: 25px; padding-bottom: 25px; padding-left: 42px; padding-right: 42px; background-image: url('./assets/images/bg-faq.jpg'); background-position: center; background-size: cover">
        <div class="row">
            <div class="col-6 text-lato" style="color: #FFFFFF; font-size: 25px; margin: auto; font-weight: 100">
                Vous pouvez également consulter notre FAQ
            </div>
            <div class="col-6 text-lato" style="color: #484c50; font-size: 15px; padding-left: 0px; padding-right: 0px;">
                <div class="row" style="">
                    <div class="col-12" style="text-align: right; color: #FFFFFF; font-size: 20px; padding-bottom: 20px">
                        Besoin d'assistance ?
                    </div>
                    <div class="col-12">
                        <div style="width: 100%; margin-top: -20px">
                            <div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px; text-align: right;">
                                <input class="btn btn-primary btn-big text-lato text-bold" type="submit" value="CONTACTEZ-NOUS"
                                    style="font-size: 13px;padding-left: 32.5px;padding-right: 32.5px; font-weight: 100; padding-top: 10px; padding-bottom: 10px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--div class="text-lato text-medium;" style="color: #30313e; margin-top: 20px">
                <img src="./assets/images/icon-help-white.png" style="width: 40px; padding-left: 10px; padding-right: 10px">
                <span style="position: absolute; top: 22px;">FAQ / SUPPORT</span>
                <span style="position: absolute; top: 20px; right: 32px;"><img src="./assets/images/icon-close.png" style="width: 16px"
                        (click)="closeModal()"></span>
            </div>
        </div-->
    </div>
    <div style="width: 100%;  padding-left: 15px; padding-right: 15px; background-color: #f7f7f7; padding-bottom: 50px">
        <div style="display: flex; padding-top: 40px; margin-left: 25px; margin-right: 25px">
            <div class="row">
                <div class="col-12" style="margin-right: 21px; margin-top: 9px; margin-bottom: 9px">
                    <div style="background-color: #FFFFFF; padding: 25px">
                        <div class="row">
                            <div class="col-9">
                                <div class="text-lato" style="color: #484c50; font-size: 20px; text-align: left">Lorem
                                    ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod
                                    tempor incididunt ut labore eterum nosistisa amedo
                                </div>
                                <div class="text-lato" style="color: #a5a5a5; font-size: 15px; text-align: justify; margin-top: 19px">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliqua quis nostrud exercitation. Lorem ipsum
                                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliqua quis nostrud exercitation.
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="text-lato" style="color: #ff006c; font-size: 40px; text-align: right; font-weight: 100">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" style="margin-right: 21px; margin-top: 9px; margin-bottom: 9px">
                    <div style="background-color: #FFFFFF; padding: 25px">
                        <div class="row">
                            <div class="col-9">
                                <div class="text-lato" style="color: #484c50; font-size: 20px; text-align: left">Lorem
                                    ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod
                                    tempor incididunt ut labore eterum nosistisa amedo
                                </div>
                                <div class="text-lato" style="color: #a5a5a5; font-size: 15px; text-align: justify; margin-top: 19px">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliqua quis nostrud exercitation. Lorem ipsum
                                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliqua quis nostrud exercitation.
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="text-lato" style="color: #ff006c; font-size: 40px; text-align: right; font-weight: 100">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" style="margin-right: 21px; margin-top: 9px; margin-bottom: 9px">
                    <div style="background-color: #FFFFFF; padding: 25px">
                        <div class="row">
                            <div class="col-9">
                                <div class="text-lato" style="color: #484c50; font-size: 20px; text-align: left">Lorem
                                    ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod
                                    tempor incididunt ut labore eterum nosistisa amedo
                                </div>
                                <div class="text-lato" style="color: #a5a5a5; font-size: 15px; text-align: justify; margin-top: 19px">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliqua quis nostrud exercitation. Lorem ipsum
                                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliqua quis nostrud exercitation.
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="text-lato" style="color: #ff006c; font-size: 40px; text-align: right; font-weight: 100">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>