<div class="container-fluid">
  <div class="row">
    <div
      class="col-12 d-flex flex-column v-align full-height"
      id="onboarding-container"
    >
      <div id="onboarding-top-menu">
        <close-view></close-view>
        <campaign-create-progress-bar
          class="margin-center"
          *ngIf="campaignCreationService.getStep() < 7"
        ></campaign-create-progress-bar>
        <draft-mode-manager></draft-mode-manager>
      </div>

      <div class="text-center center margin-h">
        <campaign-create-step-0
          *ngIf="campaignCreationService.getStep() == 0"
        ></campaign-create-step-0>
        <campaign-create-step-1
          *ngIf="campaignCreationService.getStep() == 1"
        ></campaign-create-step-1>
        <campaign-create-step-2
          *ngIf="campaignCreationService.getStep() == 2"
        ></campaign-create-step-2>
        <campaign-create-step-3
          *ngIf="campaignCreationService.getStep() == 3"
        ></campaign-create-step-3>
        <campaign-create-step-4
          *ngIf="campaignCreationService.getStep() == 4"
        ></campaign-create-step-4>
        <campaign-create-step-5
          *ngIf="campaignCreationService.getStep() == 5"
        ></campaign-create-step-5>
        <campaign-create-step-6
          *ngIf="campaignCreationService.getStep() == 6"
        ></campaign-create-step-6>
        <campaign-create-step-7
          *ngIf="campaignCreationService.getStep() == 7"
        ></campaign-create-step-7>
      </div>
    </div>
  </div>
</div>

<alert-field-not-correct
  projectId="{{ project_id }}"
  *ngIf="campaignCreationService.shouldShowAlert()"
></alert-field-not-correct>
<loader *ngIf="externalRequestService.isCurrentlyLoading() == true"></loader>
