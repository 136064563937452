import { Component, OnInit, Input } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { ModalService } from '../../../_services/modal.service'

@Component({
  templateUrl: 'modal.component.html',
  selector: 'modal',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input('projectId') projectId: string
  @Input('campaignId') campaignId: string
  @Input('proposalId') proposalId: string
  @Input('action') action: string

  showSomething: boolean
  toShow: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.showSomething = false
    this.toShow = undefined
  }

  ngOnInit() {
    this.modalService.getModals().subscribe(
      (x) => {
        //console.log('Observer got a next value z: ' + x)
        this.toShow = x
        this.showSomething = !this.showSomething
      },
      (err) => {
        //console.error('Observer got an error: ' + err)
      },
      () => {
        //console.log('Observer got a complete notification')
      }
    )
  }

  ngOnDestroy() {}

  stopPropagation(event) {
    event.stopPropagation()
  }

  closeModal() {
    this.modalService.toggleModal(this.toShow)
  }

  closeModalAlert() {
    this.modalService.toggleModal('alert')
  }
}
