<div class="row" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
  <div class="col-12">
    <div>
      <canvas
        baseChart
        width="180"
        height="40"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
      >
      </canvas>
    </div>
    <div class="text-center" style="margin-top: 12px; font-size: 11px">
      {{ getLastValueText() }} ({{ getLastValueDifference() }})
    </div>
    <div
      class="custom-tooltip"
      [class.custom-tooltip-visible]="showTooltip == true"
    >
      <div class="tooltip-text"><b>Search volumes</b></div>
      <div *ngFor="let point of values" class="tooltip-text">
        {{ point.date }}&nbsp;-&nbsp;{{ point.value | number: '1.0-0' }}
      </div>
    </div>
  </div>
</div>
