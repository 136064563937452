import { Component, OnInit } from '@angular/core'

import { ModalService } from '../../../_services/modal.service'

@Component({
  templateUrl: 'modal-workspace-invitations.component.html',
  selector: 'modal-workspace-invitations',
  styleUrls: ['./modal-workspace-invitations.component.scss'],
})
export class ModalWorkspaceInvitationsComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('profile')
  }
}
