<div
  class="d-flex justify-content-center"
  style="
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(252, 255, 255, 0);
  "
>
  <div class="d-flex justify-content-center align-items-center">
    <div
      style="
        background-color: white;
        border-radius: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
      "
    >
      <div
        class="spinner-border"
        role="status"
        style="width: 3rem; height: 3rem"
      >
        <span class="sr-only">{{ 'LOADING.LOADING_TEXT' | translate }}...</span>
      </div>
      <div
        id="loader-text"
        class="text-lato text-small"
        style="margin-top: 10px"
      >
        {{ 'LOADING.LOADING_TEXT' | translate }}
      </div>
    </div>
  </div>
</div>
