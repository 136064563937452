<div class="row margin-center">
  <div class="col-12 progress-bar-container">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="16.7"
        aria-valuemax="100"
      ></div>
      <div
        *ngFor="let progress_bar of progress_bars; let i = index"
        class="progress-bar-segment"
        [class.progress-bar-past]="campaignCreationBufferService.getStep() > i"
      >
        <div
          (click)="navigate(i)"
          class="circle-bar-target margin-center circle-bar"
          [class.circle-bar-past]="campaignCreationBufferService.getStep() > i"
          [class.circle-bar-now]="campaignCreationBufferService.getStep() == i"
        >
          {{ progress_bar.step }}
          <div
            class="title-progress-bar text-lightgrey"
            [class.step-number-color]="
              campaignCreationBufferService.getStep() == i
            "
            (click)="navigate(i)"
          >
            {{ progress_bar.name | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
