<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <form [formGroup]="opportunityForm">
      <div class="row" style="margin-bottom: 25px">
        <div class="col-12 text-lato">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">
              {{ 'OPPORTUNITIES.CAMPAIGN_TYPE' | translate }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row flex-align-center campaign-types-container">
            <div
              class="col-12 col-md-6 campaign-type"
              *ngFor="let target of getTargets(); let i = index"
              (click)="selectTarget(target)"
            >
              <div
                class="select-bubble cursor"
                [class.selected-bubble]="activateSelectedBubbleClass(target)"
                style="max-width: 250px"
              >
                <img [src]="getCampaignTypeImage(target)" />
                <p class="text-lato">
                  {{ target.text | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'OPPORTUNITIES.CAMPAIGN_NAME' | translate }}
          </div>
          <div class="input-value container-field">
            <input-field
              class="input-field"
              identifier="street_number"
              type="text"
              placeholder="{{ 'OPPORTUNITIES.CAMPAIGN_NAME' | translate }}"
              name="title"
              relatedControlName="title"
              formGroupName="titleForm"
              [parentGroup]="opportunityForm"
              [change]="updateTitle()"
            >
            </input-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'OPPORTUNITIES.CAMPAIGN_AUDIENCE' | translate }}
          </div>
          <div class="input-title text-xsmall" style="font-weight: 400">
            {{ 'OPPORTUNITIES.CAMPAIGN_AUDIENCE_SELECT' | translate }}
          </div>
          <div class="input-value container-field">
            <select
              name="plan"
              formControlName="plan"
              id="audience-select"
              class="custom-select content-width"
            >
              <option value="">
                {{ 'OPPORTUNITIES.DROPDOWN_NO_VALUE' | translate }}
              </option>
              <option [value]="plan._id" *ngFor="let plan of plans">
                {{ plan.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-lato">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">
              {{ 'OPPORTUNITIES.CAMPAIGN_DATE_RANGE' | translate }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row flex-align-center campaign-types-container">
            <div class="col-md-6 col-12 center-mobile text-center">
              <mat-form-field
                class="input-text text-lato text-small text-darkgrey"
                style="border-radius: 0px; border-bottom-width: 1px"
              >
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="{{
                    'CAMPAIGN.CREATE.STEP_2_START_DATE' | translate
                  }}"
                  name="date_start"
                  formControlName="date_start"
                  [min]="getCampainMinimalLaunchDate()"
                  (dateChange)="changeDateStart($event)"
                  autocomplete="off"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group col-md-6 col-12 center-mobile text-center">
              <mat-form-field
                class="input-text text-lato text-small text-darkgrey"
                style="border-radius: 0px; border-bottom-width: 1px"
              >
                <input
                  matInput
                  [matDatepicker]="pickerEnd"
                  placeholder="{{
                    'CAMPAIGN.CREATE.STEP_2_END_DATE' | translate
                  }}"
                  name="date_end"
                  formControlName="date_end"
                  [min]="getCampainMinimalFinishDate()"
                  (dateChange)="changeDateEnd($event)"
                  autocomplete="off"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerEnd"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="row flex-align-center campaign-types-container">
            <div class="col-12 section-body text-small text-darkgrey">
              <div class="input-title" style="margin-top: 30px">
                {{ 'OPPORTUNITIES.CAMPAIGN_BRIEF' | translate }}
              </div>
              <div class="input-value container-field">
                <input-text-area
                  class="input-field"
                  identifier="street_number"
                  type="text"
                  placeholder="{{ 'OPPORTUNITIES.CAMPAIGN_BRIEF' | translate }}"
                  name="brief"
                  relatedControlName="brief"
                  formGroupName="briefForm"
                  [parentGroup]="opportunityForm"
                >
                </input-text-area>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 flex-end section-btn">
          <tiny-cta
            (click)="saveChanges()"
            value="{{ 'OPPORTUNITIES.SAVE' | translate }}"
          ></tiny-cta>
        </div>
      </div>
    </form>
  </div>
</div>
