<div class="container-fluid full-height bg-default" *ngIf="isLoading == true">
  <div class="row full-height d-flex hv-center flex-column">
    <img src="./assets/images/logo_fns_head.png" class="error-image" />
    <h3 class="text-lato text-big error-title">
      {{ 'COMMON.LOADING' | translate }}
    </h3>
  </div>
</div>
<div class="container-fluid" *ngIf="isLoading == false">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-12 d-flex flex-column v-align full-height"
        id="onboarding-container"
      >
        <div class="text-center center margin-h-public-form">
          <div>
            <h1
              class="text-lato text-bold text-big text-darkgrey line-height-115"
              style="margin-bottom: 50px; font-size: 38px"
            >
              {{ 'Welcome to Foxy Nerds Influencer Application,' | translate }}
              <br />

              {{ "let's meet!" | translate }}
            </h1>
            <div style="margin-top: auto; margin-bottom: auto">
              <img
                style="width: 120px"
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
                *ngIf="selectedProfile == undefined"
              />
              <img
                style="
                  width: 120px;
                  height: 120px;
                  object-fit: cover;
                  border-radius: 50%;
                "
                [src]="profileSrc"
                (click)="fileInput.click()"
                *ngIf="selectedProfile != undefined"
              />
            </div>
            <p
              class="text-lato text-medium text-lightgrey"
              style="margin-top: 23px; margin-bottom: 23px"
            >
              {{ 'SIGNUP.TEXT_LINE_1_STEP_2' | translate }}
            </p>
            <form
              class="margin-v-39 max-w-400"
              [formGroup]="profileForm"
              (ngSubmit)="onSubmitUser()"
            >
              <div class="row">
                <input
                  class="d-none"
                  type="file"
                  (change)="onProfileChanged($event)"
                  accept="image/*"
                  #fileInput
                />
                <div
                  class="col-12"
                  style="margin-top: 29px; margin-bottom: 0px"
                >
                  <input
                    type="text"
                    placeholder="{{
                      'SIGNUP.INPUT_FIRST_NAME_PLACEHOLDER' | translate
                    }}"
                    class="input-text text-lato text-medium text-darkgrey"
                    name="firstname"
                    formControlName="firstname"
                    autocomplete="off"
                  />
                </div>
                <div
                  class="col-12"
                  style="margin-top: 29px; margin-bottom: 0px"
                >
                  <input
                    type="text"
                    placeholder="{{
                      'SIGNUP.INPUT_LAST_NAME_PLACEHOLDER' | translate
                    }}"
                    class="input-text text-lato text-medium text-darkgrey"
                    name="name"
                    formControlName="name"
                    autocomplete="off"
                  />
                </div>
                <div class="col-12 d-none">
                  <input
                    type="text"
                    placeholder="profileUrl"
                    class="input-text text-lato text-medium text-darkgrey"
                    name="profileUrl"
                    formControlName="profileUrl"
                  />
                </div>
                <div class="col-12" style="margin-top: 72px">
                  <input
                    class="btn btn-primary btn-big text-lato text-bold"
                    type="submit"
                    value="{{ 'SIGNUP.TEXT_BUTTON_NEXT_STEP_2' | translate }}"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<loader *ngIf="isLoading == false && isRequesting == true"></loader>

<modal></modal>
