import { Component, OnInit, Input } from '@angular/core'

import { ModalService } from '../../../_services/modal.service'
import { DataService } from '../../../_services/data.service'

import { FakerHelper } from '../../../_helpers/faker.helper'

@Component({
  templateUrl: 'modal-confirm.component.html',
  selector: 'modal-confirm',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  @Input('action') action: string
  @Input('campaign') campaign: string
  @Input('topic') topic: string

  currentCampaign: any
  currentScaler: any

  modalTitle: any
  modalText: any

  modalCancelText: any
  modalAcceptText: any

  constructor(
    private modalService: ModalService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    var campaign = this.dataService.getCampaign(this.campaign)
    this.currentCampaign = FakerHelper.getCampaignData(
      this.campaign,
      campaign.target
    )
    this.currentScaler = FakerHelper.getScaler(this.currentScaler)

    if (this.action === 'accept') {
      this.modalTitle = 'Accept scaler offer'
      this.modalAcceptText = 'Yes, accept offer'
    }

    if (this.action === 'decline') {
      this.modalTitle = 'Decline scaler offer'
      this.modalAcceptText = 'Yes, decline offer'
    }

    if (this.action === 'cancel') {
      this.modalTitle = 'Cancel scaler contract'
      this.modalAcceptText = 'Yes, cancel contract'
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('confirm')
  }

  doAction() {
    this.closeModal()

    if (this.action === 'accept') {
      FakerHelper.acceptOffer(this.campaign, this.topic)
    }

    if (this.action === 'decline') {
      FakerHelper.declineOffer(this.campaign, this.topic)
    }

    if (this.action === 'cancel') {
      FakerHelper.cancelOffer(this.campaign, this.topic)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
