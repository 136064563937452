import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersProfileAPIService } from 'src/app/_services'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  templateUrl: 'publisher-profile-content-description-languages.component.html',
  selector: 'publisher-profile-content-description-languages',
  styleUrls: [
    './publisher-profile-content-description-languages.component.scss',
  ],
})
export class PublisherProfileContentDescriptionLanguagesComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  languagesForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showModalUpdate: boolean

  languages: any[]
  language: any
  languageId: any

  suggestedLanguages: any[]
  languageNameControl = new FormControl()
  filteredOptions: Observable<any[]>

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService,
    private campaignReferentialService: CampaignReferentialService
  ) {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    this.suggestedLanguages = []
    this.languagesForm = new FormGroup({
      languageName: new FormControl(''),
    })

    /*this.filteredOptions = this.languagesForm
      .get('nameForm')
      .get('languageName')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value))
      )*/
    this.filteredOptions = this.languagesForm
      .get('languageName')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      )
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()
  }

  initValues() {
    this.getLanguages()
    this.suggestedLanguages = this.getSuggestedLanguages()
  }

  ngOnDestroy() {}

  hasLanguages() {
    try {
      return (
        this?.publisher?.publisher?.contentDescription?.languages != undefined
      )
    } catch (e) {
      return false
    }
  }

  getLanguages() {
    if (this.hasLanguages() == true) {
      this.languages = this.publisher.publisher.contentDescription.languages
      return this.languages
    } else return undefined
  }

  getSuggestedLanguages() {
    let suggestedLanguages = this.campaignReferentialService.getLanguages()

    let languages = this.getLanguages()
    if (languages == undefined) {
      return suggestedLanguages
    }

    let checker = []
    for (let i = 0; i < languages.length; i++) {
      checker.push(languages[i].code)
    }

    let result = []
    for (let i = 0; i < suggestedLanguages.length; i++) {
      const index = checker.indexOf(suggestedLanguages[i].key)
      if (index == -1) {
        result.push(suggestedLanguages[i])
      }
    }

    return result
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.initValues()
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async addLanguageToProfile(language) {
    try {
      const index = this.suggestedLanguages.indexOf(language)
      if (index > -1) {
        this.suggestedLanguages.splice(index, 1)
      }

      this.loading = true
      this.alertService.resetAlerts()
      let res = await this.apiService.addLanguageInformation(
        this.publisher.publisher._id,
        { code: language.key }
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyAdded = true
        this.loading = false
        this.makeNotEditable()
      } else {
        this.successfullyAdded = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let languageField = this.readTextValue(
        this.languagesForm.get('languageName').value
      )

      if (
        languageField != undefined &&
        this.languagesForm.get('languageName').valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Language field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let language_infos = {
          code: this.getCorrespondingLanguageKey(languageField),
        }

        let res = await this.apiService.addLanguageInformation(
          this.publisher.publisher._id,
          language_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.publisher.publisher = res.body.publisher
          this.successfullyAdded = true
          this.loading = false
          this.makeNotEditable()
          this.clearInputField()
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteLanguage(language) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      let languageId = language._id

      let res = await this.apiService.deleteLanguageInformation(
        this.publisher.publisher._id,
        languageId
      )

      if (res.status === 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyRemoved = true
        this.loading = false
        this.makeNotEditable()
        this.closeModalIfNoExpertise()
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addLanguage() {
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateLanguage() {
    this.showModalUpdate = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  getNumberOfLanguages() {
    let languages = this.getLanguages()
    if (languages == undefined) return 0

    return languages.length
  }

  validateLanguage() {}

  closeModal() {
    this.showModal = false
    this.showModalUpdate = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyRemoved = undefined
    this.successfullyAdded = undefined
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  private _filter(value: string): string[] {
    let filteredLanguages = this.getSuggestedLanguages()
    let result = []
    for (let i = 0; i < filteredLanguages.length; i++) {
      result.push(filteredLanguages[i].text)
    }
    const filterValue = value.toLowerCase()
    return result.filter(
      (language) => language.toLowerCase().indexOf(filterValue) === 0
    )
  }

  changeLanguage(e) {
    console.log(e)
  }

  getCorrespondingLanguageKey(language: string) {
    let languages = this.campaignReferentialService.getLanguages()
    let result = undefined
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].text.toLowerCase() === language.toLowerCase()) {
        result = languages[i].key
        return result
      }
    }

    return result
  }

  getCorrespondingLanguageName(language: string) {
    let languages = this.campaignReferentialService.getLanguages()
    let result = undefined
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].key.toLowerCase() === language.toLowerCase()) {
        result = languages[i].text
        return result
      }
    }

    return result
  }

  closeModalIfNoExpertise() {
    if (this.getLanguages().length == 0) {
      this.closeModal()
    }
  }

  clearInputField() {
    this.languagesForm = new FormGroup({
      languageName: new FormControl(''),
    })
  }
}
