<div class="container-fluid full-height bg-default">
  <div class="row">
    <div class="col-12 d-flex d-lg-none" style="padding-right: 0px">
      <mobile-nav-bar
        [full]="true"
        title="{{ 'COMMON.BRIEF' | translate }}"
        style="width: 100%; text-transform: uppercase"
      ></mobile-nav-bar>
    </div>
  </div>
  <div class="row v-margin h-margin">
    <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
      <left-menu></left-menu>
    </div>
    <div class="col-12 col-lg-9 d-block" id="content">
      <div>
        <nav-bar
          class="display-none d-none d-lg-block"
          [full]="true"
          title="{{ 'COMMON.BRIEF' | translate }}"
        ></nav-bar>
        <div class="right-row no-padding" style="margin-top: 20px"></div>
        <div class="row no-margin margin-top-25">
          <div
            class="col-12 light-box"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <campaign-summary></campaign-summary>
          </div>
          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-4 no-margin"
                style="display: flex; align-items: center"
              >
                <div
                  class="text-lato text-bold text-medium no-margin"
                  style="color: white"
                >
                  {{ 'CAMPAIGN.BRIEF.BRIEF_TARGET' | translate }}
                </div>
              </div>
              <div
                class="col-4 no-margin text-center"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <span
                  class="text-lato text-bold text-medium no-margin"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD' | translate }}</span
                >
              </div>
              <div
                class="col-4 no-margin text-right"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <span
                  class="text-lato text-bold text-medium no-margin"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_BUDGET' | translate }}</span
                >
              </div>
              <div
                class="col-12"
                style="margin-top: 5px; margin-bottom: 5px"
              ></div>
              <div
                class="col-4 no-margin"
                style="display: flex; align-items: center"
              >
                <div
                  class="text-lato text-bold text-small no-margin"
                  style="color: #ffffff"
                >
                  {{ target | translate }}
                </div>
              </div>
              <div
                class="col-4 no-margin text-center"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div
                  class="text-lato text-bold text-small no-margin"
                  style="color: #ffad00"
                >
                  {{ 'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD_TEXT_START' | translate }}
                  {{ date_start }}<br />{{
                    'CAMPAIGN.BRIEF.BRIEF_TIMEPERIOD_TEXT_END' | translate
                  }}
                  {{ date_end }}
                </div>
              </div>
              <div
                class="col-4 no-margin text-right"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <span
                  class="text-lato text-bold text-small card-title no-margin"
                  style="color: #ffad00"
                  *ngIf="
                    campaign.compensation === 'free' ||
                    campaign.compensation == undefined
                  "
                  >{{ campaign.total_budget }}</span
                >
                <span
                  class="text-lato text-bold text-small card-title no-margin"
                  style="color: #ffad00"
                  *ngIf="
                    campaign.compensation === 'cash' ||
                    campaign.compensation == undefined
                  "
                  >{{ campaign.total_budget }}
                  {{ preferencesManager.getPreferredCurrency().symbol }}</span
                >
                <span
                  class="text-lato text-bold text-small card-title no-margin"
                  style="color: #ffad00"
                  *ngIf="campaign.compensation === 'free_products'"
                  >{{ campaign.total_budget }}
                  {{ 'COMMON.PRODUCT_S' | translate }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false && campaign != ''"
          >
            <div
              class="row margin-v-15 margin-h-9"
              style="display: flex; justify-content: center"
            >
              <div
                class="text-lato text-bold text-medium no-margin"
                style="color: #ffffff; padding-bottom: 15px"
              >
                Target channels
              </div>
              <div
                class="col-12"
                style="display: flex; justify-content: center"
              >
                <ul
                  *ngFor="let network of campaign.networks"
                  style="
                    text-align: center;
                    margin-right: 6px;
                    margin-left: 6px;
                    margin-bottom: 6px;
                    padding-inline-start: 0px;
                  "
                >
                  <img
                    style="width: 50px; margin-bottom: 6px"
                    *ngIf="network.identifier === 'web'"
                    src="../../../assets/images/icons/icon_web_unselected.png"
                  />
                  <img
                    style="width: 50px; margin-bottom: 6px"
                    *ngIf="network.identifier === 'twitter'"
                    src="../../../assets/images/icons/icon_twitter_unselected.png"
                  />
                  <img
                    style="width: 50px; margin-bottom: 6px"
                    *ngIf="network.identifier === 'linkedin'"
                    src="../../../assets/images/icons/icon_linkedin_unselected.png"
                  />
                  <img
                    style="width: 50px; margin-bottom: 6px"
                    *ngIf="network.identifier === 'facebook'"
                    src="../../../assets/images/icons/icon_facebook_unselected.png"
                  />
                  <img
                    style="width: 50px"
                    *ngIf="network.identifier === 'youtube'"
                    src="../../../assets/images/icons/icon_youtube_unselected.png"
                  />
                  <img
                    style="width: 50px"
                    *ngIf="network.identifier === 'medium'"
                    src="../../../assets/images/icons/icon_medium_unselected.png"
                  />
                  <img
                    style="width: 50px"
                    *ngIf="network.identifier === 'podcast'"
                    src="../../../assets/images/icons/icon_podcast_unselected.png"
                  />
                  <img
                    style="width: 50px"
                    *ngIf="network.identifier === 'instagram'"
                    src="../../../assets/images/icons/icon_instagram_unselected.png"
                  />
                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-12 light-box margin-top-25 dashboard-mobile-right"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: #ffffff"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_TITLE' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div class="row" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="
                      color: white;
                      text-align: left;
                      white-space: pre-wrap;
                    "
                  >
                    <div class="ql-container ql-snow" style="border-width: 0">
                      <div
                        class="ql-editor"
                        [innerHTML]="campaign.brief.text"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
              margin-right: 12.5px;
              max-width: calc(50% - 12.5px);
            "
            *ngIf="isLoading == false && campaign.do != ''"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_DO' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div class="row no-margin" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="color: #ffad00; text-align: left"
                  >
                    <ul>
                      <li *ngFor="let do of campaign.do">
                        {{ do.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-left"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
              max-width: calc(50% - 12.5px);
            "
            *ngIf="isLoading == false && campaign.dont != ''"
            [ngClass]="
              campaign.do == '' ? 'change-margin-right' : 'change-margin-left'
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_DONT' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div class="row no-margin" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="color: #ff006c; text-align: left"
                  >
                    <ul>
                      <li *ngFor="let dont of campaign.dont">
                        {{ dont.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
              max-width: calc(50% - 12.5px);
            "
            *ngIf="
              isLoading == false &&
              campaign.locations != '' &&
              campaign.do == '' &&
              campaign.dont == ''
            "
            [ngClass]="
              (campaign.do == '' && campaign.dont != '') ||
              (campaign.dont == '' && campaign.do != '') ||
              (campaign.dont != '' && campaign.do != '')
                ? 'change-margin-left'
                : 'change-margin-right'
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_LOCATION' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div class="row no-margin" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="color: white; text-align: left"
                  >
                    <div
                      class="row no-margin margin-5"
                      *ngIf="
                        isLoading == false &&
                        campaign.locations != undefined &&
                        campaign.locations.length > 0
                      "
                    >
                      <div
                        class="col keyword"
                        *ngFor="let location of campaign.locations"
                      >
                        {{ location.country | translate }},
                        {{ location.area | translate }}
                      </div>
                    </div>
                    <ul
                      *ngIf="
                        isLoading == false &&
                        (campaign.locations == undefined ||
                          (campaign.locations != undefined &&
                            campaign.locations.length == 0))
                      "
                    >
                      <li>
                        {{
                          'CAMPAIGN.BRIEF.BRIEF_LOCATION_COUNTRY' | translate
                        }}
                        {{ campaign.location.country | translate }}
                      </li>
                      <li>
                        {{ 'CAMPAIGN.BRIEF.BRIEF_LOCATION_STATE' | translate }}
                        {{ campaign.location.area | translate }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 light-box margin-top-25 dashboard-mobile-right change-margin-right"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
              max-width: calc(50% - 12.5px);
            "
            *ngIf="
              isLoading == false &&
              campaign.locations != '' &&
              campaign.do != '' &&
              campaign.dont != ''
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_LOCATION' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div class="row no-margin" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="color: white; text-align: left"
                  >
                    <div
                      class="row no-margin margin-5"
                      *ngIf="
                        isLoading == false &&
                        campaign.locations != undefined &&
                        campaign.locations.length > 0
                      "
                    >
                      <div
                        class="col keyword"
                        *ngFor="let location of campaign.locations"
                      >
                        {{ location.country | translate }},
                        {{ location.area | translate }}
                      </div>
                    </div>
                    <ul
                      *ngIf="
                        isLoading == false &&
                        (campaign.locations == undefined ||
                          (campaign.locations != undefined &&
                            campaign.locations.length == 0))
                      "
                    >
                      <li>
                        {{
                          'CAMPAIGN.BRIEF.BRIEF_LOCATION_COUNTRY' | translate
                        }}
                        {{ campaign.location.country | translate }}
                      </li>
                      <li>
                        {{ 'CAMPAIGN.BRIEF.BRIEF_LOCATION_STATE' | translate }}
                        {{ campaign.location.area | translate }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-12 light-box margin-top-25 dashboard-mobile-left"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
              max-width: calc(50% - 12.5px);
            "
            *ngIf="isLoading == false && campaign.filters != ''"
            [ngClass]="
              campaign.locations == ''
                ? 'change-margin-right'
                : 'change-margin-left'
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: #ffffff"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_AUDIENCE' | translate }}
                </span>
                <!--img style="width: 20px; height: 20px; margin-left: 6px; margin-top: 0px" src="./assets/images/icon-help.png"-->
              </div>
              <div class="col-3 no-margin" style="margin: auto">
                <!--div style="margin-right: 0px; display: flex; flex-direction: row-reverse">
                                        <img class="option-image" src="./assets/images/icon-star-pink.png">
                                    </div-->
              </div>
              <div class="col-12">
                <div class="row no-margin" *ngIf="isLoading == false">
                  <div
                    class="col-12 tab-4"
                    style="color: white; text-align: left"
                  >
                    <div
                      class="row no-margin margin-5"
                      *ngIf="isLoading == false"
                    >
                      <div
                        class="col keyword"
                        *ngFor="let tag of campaign.filters"
                      >
                        <span *ngIf="tag.type == 'hashtag'">@</span
                        >{{ tag.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="
              isLoading == false &&
              moodboard != undefined &&
              moodboard.length > 0
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="col-9 no-margin">
                <span
                  class="text-lato text-bold text-medium"
                  style="color: white"
                  >{{ 'CAMPAIGN.BRIEF.BRIEF_MOODBOARD' | translate }}</span
                >
              </div>
              <div class="col-3 no-margin" style="margin: auto"></div>
              <div class="col-12">
                <div
                  class="row v-align margin-v-15"
                  style="width: 70%; margin-left: auto; margin-right: auto"
                >
                  <div class="card-columns" style="">
                    <!--div class="card text-center">
                                            <div class="card-body">
                                                <p class="card-text">Ajoutez des photos<br> à votre moodboard</p>
                                                <input
                                                    style="width: 36px; height: 36px; margin-left: 10px; margin-right: 0px; padding: 0px"
                                                    class="btn btn-primary btn-big text-lato text-bold" value="+"
                                                    readonly="readonly" (click)="addPicture()">
                                            </div>
                                        </div-->
                    <div *ngFor="let image of moodboard" class="card" style="">
                      <img [src]="image.path" class="card-img-top" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal campaignId="{{ id }}"></modal>
</div>
