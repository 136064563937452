import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class AdvertisersOffersAPIService {
  constructor(private http: HttpClient) {}

  async getOffers(workspaceId) {
    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/campaigns/'

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async getOffer(workspaceId, offerId) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/campaigns/' +
      offerId

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async createOffer(workspaceId, offerJson) {
    var url = BACKEND_URL + '/api/v1/workspaces/' + workspaceId + '/campaigns/'

    var res = await this.http
      .post<any>(url, offerJson, { observe: 'response' })
      .toPromise()
    return res
  }

  async updateOffer(workspaceId, campaignId, offerJson) {
    var url =
      BACKEND_URL +
      '/api/v1/workspaces/' +
      workspaceId +
      '/campaigns/' +
      campaignId

    var res = await this.http
      .post<any>(url, offerJson, { observe: 'response' })
      .toPromise()
    return res
  }
}
