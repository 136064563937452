<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Hashtags</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addHashtag()"></add-button>
        <edit-button
          class="edit-hashtag"
          (click)="updateHashtag()"
          *ngIf="getNumberOfHashtags() > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center padding-hashtag-section">
      <div class="no-language" *ngIf="getNumberOfHashtags() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>Share the hashtags you often use in your publications</div>
        <div></div>
        <tiny-cta
          (click)="addHashtag()"
          value="ADD YOUR FIRST HASHTAG"
        ></tiny-cta>
      </div>
      <span
        class="text-lato text-small text-darkgrey hashtags-section"
        *ngFor="let hashtag of getHashtags()"
      >
        <span class="hashtag-field">{{ hashtag.tag }}</span>
      </span>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddHashtag"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddHashtag"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddHashtagTitle">
                    {{ 'Add a hashtag' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="hashtagsForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Hashtag</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Hashtag"
                      name="name"
                      relatedControlName="name"
                      formGroupName="nameForm"
                      [parentGroup]="hashtagsForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyAdded == true"
              >
                Your hashtag have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyAdded == false"
              >
                Your hashtag was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateHashtag"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateHashtag"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5
                    class="modal-title text-lato"
                    id="modalUpdateHashtagTitle"
                  >
                    {{ 'Delete hashtag' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="hashtagsForm">
              <div class="row center padding-hashtag-section">
                <span
                  class="text-lato text-small text-darkgrey hashtags-section"
                  *ngFor="let hashtag of getHashtags()"
                >
                  <span class="hashtag-field"
                    >{{ hashtag.tag }}
                    <span class="icon-delete" (click)="deleteHashtag(hashtag)"
                      >x</span
                    ></span
                  >
                </span>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your hashtag have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your hashtag was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
