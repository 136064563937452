import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'
import { PublishersOpportunitiesAPIService } from 'src/app/_services'

@Component({
  templateUrl: 'campaign-thumbnail.component.html',
  selector: 'campaign-thumbnail',
  styleUrls: ['./campaign-thumbnail.component.scss'],
})
export class CampaignThumbnailComponent implements OnInit {
  @Input('opportunity') opportunity: any

  networks: any[]
  createdAt: any

  publisher: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private publishersOpportunitiesAPIService: PublishersOpportunitiesAPIService
  ) {
    this.publisher = this.route.snapshot.data.user.body
  }

  async ngOnInit() {
    this.networks = this.opportunity._scope.networks
  }

  ngOnDestroy() {}

  loadCampaignPage() {
    this.router.navigate(['/opportunities/' + this.opportunity._id])
  }

  getCampaignDateSentence() {
    if (this.opportunity._scope.status === 'draft')
      return 'This campaign is still in draft mode'
    else if (this.opportunity._scope.status === 'launched') {
      let now = moment().valueOf()
      if (now < this.opportunity._scope.startDate) {
        return (
          'Influencer selection process will start on ' +
          DateHelper.convertDoubleToReadableDate(
            this.opportunity._scope.startDate
          )
        )
      } else if (now > this.opportunity._scope.endDate) {
        return 'Influencer selection process for this campaign is over'
      } else {
        return (
          'Influencer selection process is available until ' +
          DateHelper.convertDoubleToReadableDate(
            this.opportunity._scope.endDate
          )
        )
      }
    } else if (this.opportunity._scope.status === 'finished') {
      return 'Influencer selection process for this campaign is over'
    } else if (this.opportunity._scope.status === 'cancelled') {
      return 'This campaign has been cancelled by the associated brand'
    }

    return null
  }

  getCampaignStatus() {
    if (this.opportunity._scope.status === 'draft') return 'DRAFT'
    else if (this.opportunity._scope.status === 'launched') {
      let now = moment().valueOf()
      if (now < this.opportunity._scope.startDate) {
        return 'UPCOMING'
      } else if (now > this.opportunity._scope.endDate) {
        return 'FINISHED'
      } else {
        return 'LIVE'
      }
    } else if (this.opportunity._scope.status === 'cancelled')
      return 'CANCELLED'
    else if (this.opportunity._scope.status === 'finished') return 'FINISHED'

    return undefined
  }

  getNetworkThumbnailImagePath(network) {
    let path = 'assets/images/icons/'
    switch (network) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  getCampaign() {
    return this.opportunity._scope
  }

  isFavorite() {
    return this.opportunity?.favorite
  }

  async toggleFavorite() {
    let isFavorite = this.isFavorite()
    await this.makeFavorite(!isFavorite)
  }

  async makeFavorite(favorite) {
    let opportunity = await this.publishersOpportunitiesAPIService.makeOpportunityFavorite(
      this.publisher.publisher._id,
      this.opportunity._id,
      favorite
    )

    this.opportunity = opportunity
  }
}
