import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { APIService } from '../_services'
import { InternalAuthenticationService } from '../_services/authentication/internal.authentication.service'
import { DataService } from '../_services/data.service'
import { map, catchError } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(
    private apiService: APIService,
    private dataService: DataService,
    private authenticationService: InternalAuthenticationService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    //return this.apiService.getMyProjects();
    this.dataService.reset()

    return this.apiService.getMe().pipe(
      map((response) => {
        this.dataService.registerMe(response.body.user)
        this.dataService.fetchProjects(response.body.projects)

        const projectId = <string>route.paramMap.get('projectId')
        if (projectId != undefined) {
          var projects = response.body.projects
          var project = undefined
          for (var i = 0; i < projects.length; i++) {
            if (projects[i]._id === projectId) {
              project = projects[i]
              break
            }
          }

          if (project == undefined) {
            this.router.navigate(['/not-found'])
            return of(null)
          }

          this.dataService.fetchProject(project, projectId)
        }

        /*const campaignId = <string>route.paramMap.get('campaignId')
        if (campaignId != undefined) {
          var projects = response.body.projects
          var campaign = undefined
          for (var i = 0; i < projects.length; i++) {
            if (projects[i].campaigns != undefined) {
              for (var j = 0; j < projects[i].campaigns.length; j++) {
                if (projects[i].campaigns[j]._id === campaignId) {
                  campaign = projects[i].campaigns[j]
                  break
                }
              }
            }
          }

          if (campaign == undefined) {
            this.router.navigate(['/not-found'])
            return of(null)
          }

          this.dataService.fetchCampaign(campaign, campaignId)
        }*/

        var isAuthorized = true
        if (projectId != undefined && projectId !== '')
          isAuthorized = this.checkMembership(route, response, projectId)

        if (isAuthorized == true) return response
        else {
          //this.router.navigate(['/unauthorized'])
          return of(null)
        }
      }),
      catchError((errorResponse) => this.handleError(route, errorResponse))
    )
  }

  checkMembership(route: ActivatedRouteSnapshot, response: any, projectId) {
    var members = route.data.members

    if (members == undefined) {
      return true
    }

    var userId = response.body.user._id

    var projects = response.body.projects
    var project = undefined
    for (var i = 0; i < projects.length; i++) {
      if (projects[i]._id === projectId) {
        project = projects[i]
        break
      }
    }

    if (project == undefined) return false
    else {
      var isAuthorized = false
      for (var i = 0; i < project.members.length; i++) {
        if (project.members[i].user._id === userId) {
          for (var j = 0; j < members.length; j++) {
            if (project.members[i].right === members[j]) {
              isAuthorized = true
            }
          }
        }
      }

      return isAuthorized
    }
  }

  handleError(route: ActivatedRouteSnapshot, errorResponse: HttpErrorResponse) {
    console.log('error')
    switch (errorResponse.status) {
      case 404: {
        this.router.navigate(['/not-found'])
        return of(null)
      }
      case 401: {
        const returnURL: string =
          '/' + route.url.map((segment) => segment.path).join('/')
        //this.router.navigate(['/login'], { queryParams: { returnUrl: returnURL } });
        this.authenticationService.logout(returnURL)
        return of(null)
      }
      case 403: {
        this.router.navigate(['/unauthorized'])
        return of(null)
      }
      case 400: {
        this.router.navigate(['/error'])
        return of(null)
      }
      default: {
        this.router.navigate(['/error'])
        return of(null)
      }
    }
  }
}
