/**
 *
 *
 * @export
 * @enum {number}
 */
export enum WARNING_LEVEL {
  INFO = 'info',
  ERROR = 'error',
}

/**
 *
 *
 * @export
 * @class Warning
 */
export class Warning {
  /**
   * slug is an unique id who defines the warning
   * @private
   * @type {string}
   * @memberof Warning
   */
  private slug: string

  /**
   * text is an instance of the text you want have to show when the warning appear
   * @private
   * @type {string}
   * @memberof Warning
   */
  private text: string

  /**
   * level is an instance of the warning level
   * @private
   * @type {WARNING_LEVEL}
   * @memberof Warning
   */
  private level: WARNING_LEVEL

  /**
   * Creates an instance of Warning.
   * @param {*} [values=undefined]
   * @memberof Warning
   */
  constructor(values = undefined) {
    if (values == undefined) {
      this.slug = ''
      this.text = ''
      this.level = WARNING_LEVEL.INFO
    } else {
      this.slug = values['slug']
      this.text = values['text']
      this.level = values['level']
    }
  }

  /**
   * Returns slug instance as a string
   * @return {*}  {string}
   * @memberof Warning
   */
  public getSlug(): string {
    return this.slug
  }

  /**
   * Returns text instance as a string
   * @return {*}  {string}
   * @memberof Warning
   */
  public getText(): string {
    return this.text
  }

  /**
   * Returns level instance as a string
   * @return {*}  {WARNING_LEVEL}
   * @memberof Warning
   */
  public getLevel(): WARNING_LEVEL {
    return this.level
  }

  /**
   * Returns a builder instance, build with slug, text and level
   * @static
   * @return {*}
   * @memberof Warning
   */
  static getBuilder() {
    class Builder {
      values: any

      constructor() {}
      public init() {
        this.values = {}
        return this
      }
      withSlug(slug: string): Builder {
        this.values.slug = slug
        return this
      }
      withText(text: string): Builder {
        this.values.text = text
        return this
      }
      withLevel(level: WARNING_LEVEL): Builder {
        this.values.level = level
        return this
      }
      build(): Warning {
        return new Warning(this.values)
      }
    }
    return Builder
  }
}
