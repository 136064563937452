import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PublisherDashboardComponent } from './_components/private/publishers/dashboard'
import { PublisherSettingsComponent } from './_components/private/publishers/settings'
import { PublisherBillingComponent } from './_components/private/publishers/billing'
import { PublisherFaqComponent } from './_components/private/publishers/faq'
import { PublisherOpportunitiesComponent } from './_components/private/publishers/opportunities'
import { PublisherOpportunityComponent } from './_components/private/publishers/opportunity'
import { PublisherOfferComponent } from './_components/private/publishers/offer'
import { PublisherDealsComponent } from './_components/private/publishers/deals'
import { PublisherDealComponent } from './_components/private/publishers/deal'
import { PublisherCollaborationsComponent } from './_components/private/publishers/collaborations'
import { BrandInfoComponent } from './_components/private/publishers/brand'

import { USER_ROLE } from './_helpers/referential/user.role'

import { AuthGuard, RoleGuard } from './_guards'
import { UserResolver } from './_resolvers/user.resolver'
import { PublishersOpportunitiesResolver } from './_resolvers/publishers/publishers.opportunities.resolver'
import { PublishersOpportunityResolver } from './_resolvers/publishers/publishers.opportunity.resolver'
import { PublishersDealsResolver } from './_resolvers/publishers/publishers.deals.resolver'
import { PublishersDealResolver } from './_resolvers/publishers/publishers.deal.resolver'
import { PublishersTasksResolver } from './_resolvers/publishers/publishers.tasks.resolver'

const INFLUENCERS_ROUTES: Routes = [
  {
    path: 'dashboard',
    component: PublisherDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver },
  },
  {
    path: 'settings',
    component: PublisherSettingsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver },
  },
  {
    path: 'billing',
    component: PublisherBillingComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver },
  },
  {
    path: 'faq',
    component: PublisherFaqComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver },
  },
  {
    path: 'opportunities',
    component: PublisherOpportunitiesComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: {
      user: UserResolver,
      opportunities: PublishersOpportunitiesResolver,
    },
  },
  {
    path: 'opportunities/:opportunityId',
    component: PublisherOpportunityComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver, opportunity: PublishersOpportunityResolver },
  },
  {
    path: 'opportunities/:opportunityId/offer',
    component: PublisherOfferComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver },
  },
  {
    path: 'deals',
    component: PublisherDealsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver, deals: PublishersDealsResolver },
  },
  {
    path: 'deals/:dealId',
    component: PublisherDealComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver, deal: PublishersDealResolver },
  },
  {
    path: 'collaborations',
    component: PublisherCollaborationsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver, taskData: PublishersTasksResolver },
  },
  {
    path: 'opportunities/:opportunityId/brandInfo',
    component: BrandInfoComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { requiresLogin: true, requiredRole: USER_ROLE.PUBLISHER },
    resolve: { user: UserResolver, opportunity: PublishersOpportunityResolver },
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(INFLUENCERS_ROUTES, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppPublishersRoutingModule {}
