import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { DateHelper } from '../../../../_helpers/date.helpers'

import * as moment from 'moment'
import { IndustryHelper } from 'src/app/_helpers/referential/industry.helper'

@Component({
  templateUrl: 'brand-details.component.html',
  selector: 'brand-details',
  styleUrls: ['./brand-details.component.scss'],
})
export class BrandDetailsComponent implements OnInit {
  @Input('opportunity') opportunity: any

  networks: any[]
  constructor(private route: ActivatedRoute) {
    this.opportunity = route.snapshot.data.opportunity
  }

  ngOnInit() {
    this.networks = this.opportunity?._brand?.brand?.socialMedia
  }
  ngAfterViewInit() {}
  ngOnDestroy() {}

  getDigitalAccountImagePath(digitalAccount) {
    let path = 'assets/images/icons/'
    switch (digitalAccount) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  getIndustry(industry) {
    return IndustryHelper.getIndustryText(industry)
  }

  getMarketingResources() {
    if (this.opportunity?._brand?.marketing_resources) {
      return this.opportunity?._brand.marketing_resources.filter((resource) => {
        return resource.isPublic == true
      })
    } else return []
  }

  getCampaign() {
    return this.opportunity?._scope
  }
}
