import { Injectable } from '@angular/core'
import { EditableTask } from 'src/app/_models/task/editable.task.model'

import { CollaborationsSocket } from 'src/app/_sockets/collaborations.socket'
import { StorageManagementService } from '../storage.management.service'
import { environment } from 'src/environments/environment'
import { USER_ROLE } from 'src/app/_helpers/referential/user.role'
import { BehaviorSubject } from 'rxjs'
import * as moment from 'moment'

const ROLE = environment.role

@Injectable()
export class TaskSocketService {
  private editableTasks = new BehaviorSubject<EditableTask[]>([])
  private editedTask = new BehaviorSubject<EditableTask>(undefined)

  private socket: CollaborationsSocket

  constructor(private storageManagementService: StorageManagementService) {
    this.socket = new CollaborationsSocket(
      JSON.parse(localStorage.getItem('currentUser')).token
    )
  }

  getEditableTasks(): BehaviorSubject<EditableTask[]> {
    return this.editableTasks
  }

  getEditedTask(): BehaviorSubject<EditableTask> {
    return this.editedTask
  }

  async joinSocketTasks(tasks: EditableTask[]) {
    this.socket.connect()

    this.socket.on('collab:task:get', (data) => {})

    this.socket.on('collab:task:update', async (data) => {
      console.log('collab:task:update')
      console.log(data)
      if (data != undefined && data.object != undefined) {
        let success = data.object.success
        let timestamp = data.object.timestamp

        if (data.action === 'post') {
          let updatedTask = data.object.task

          if (ROLE === USER_ROLE.PUBLISHER) {
            let publisherId = this.storageManagementService.getCurrentPublisherId()

            this.socket.emit('publisher:collabs:tasks:join', {
              taskId: updatedTask._id,
              publisherId: publisherId,
              token: JSON.parse(localStorage.getItem('currentUser')).token,
            })
          } else if (ROLE === USER_ROLE.ADVERTISER) {
            let brandspaceId = this.storageManagementService.getCurrentBrandspaceId()

            this.socket.emit('advertiser:collabs:tasks:join', {
              taskId: updatedTask._id,
              brandspaceId: brandspaceId,
              token: JSON.parse(localStorage.getItem('currentUser')).token,
            })
          }

          await this.updateNewTask(updatedTask, timestamp, success)
        } else if (data.action === 'put') {
          let updatedTask = data.object.task
          await this.updateExistingTask(updatedTask, timestamp, success)
        }
      }
    })

    if (ROLE === USER_ROLE.PUBLISHER) {
      let publisherId = this.storageManagementService.getCurrentPublisherId()
      this.socket.emit('publisher:collabs:publishers:join', {
        publisherId: publisherId,
        token: JSON.parse(localStorage.getItem('currentUser')).token,
      })
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].taskId) {
          this.socket.emit('publisher:collabs:tasks:join', {
            taskId: tasks[i].taskId,
            publisherId: publisherId,
            token: JSON.parse(localStorage.getItem('currentUser')).token,
          })
        }
      }
    } else if (ROLE === USER_ROLE.ADVERTISER) {
      let brandspaceId = this.storageManagementService.getCurrentBrandspaceId()
      this.socket.emit('advertiser:collabs:brandspaces:join', {
        brandspaceId: brandspaceId,
        token: JSON.parse(localStorage.getItem('currentUser')).token,
      })
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].taskId) {
          this.socket.emit('advertiser:collabs:tasks:join', {
            taskId: tasks[i].taskId,
            brandspaceId: brandspaceId,
            token: JSON.parse(localStorage.getItem('currentUser')).token,
          })
        }
      }
    }
  }

  disconnect() {
    this.socket.disconnect()
  }

  buildDataFromOveriddenValues(task: EditableTask) {
    let data = {
      name: task.name,
      description: task.description,
      dueDate: task.dueDate,
      priority: task.priority,
      assignee: task.assignee,
      deal: task.task.deal,
      step: task.step,
      status: task.status,
    }

    return data
  }

  createTask(editedTask: EditableTask, timestamp: any) {
    try {
      let data = this.buildDataFromOveriddenValues(editedTask)

      if (ROLE === USER_ROLE.PUBLISHER) {
        this.socket.emit('publisher:collabs:tasks:add', {
          taskId: editedTask.taskId,
          publisherId: this.storageManagementService.getCurrentPublisherId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      } else if (ROLE === USER_ROLE.ADVERTISER) {
        this.socket.emit('advertiser:collabs:tasks:add', {
          taskId: editedTask.taskId,
          brandspaceId: this.storageManagementService.getCurrentBrandspaceId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  updateTask(editedTask: EditableTask, timestamp: any) {
    try {
      let data = this.buildDataFromOveriddenValues(editedTask)

      if (ROLE === USER_ROLE.PUBLISHER) {
        this.socket.emit('publisher:collabs:tasks:edit', {
          taskId: editedTask.taskId,
          publisherId: this.storageManagementService.getCurrentPublisherId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      } else if (ROLE === USER_ROLE.ADVERTISER) {
        console.log('advertiser:collabs:tasks:edit')
        this.socket.emit('advertiser:collabs:tasks:edit', {
          taskId: editedTask.taskId,
          brandspaceId: this.storageManagementService.getCurrentBrandspaceId(),
          timestamp: timestamp,
          taskInfos: data,
          token: JSON.parse(localStorage.getItem('currentUser')).token,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  async updateNewTask(updatedTask, timestamp, success) {
    this.getEditedTask().getValue().flushOverridenValues(updatedTask, timestamp)

    this.editedTask.next(this.getEditedTask().getValue())
    this.editableTasks.getValue().push(this.editedTask.getValue())
    this.editableTasks.next(this.editableTasks.getValue())
    this.getEditedTask().getValue().unlockTaskForSave()
  }

  async updateExistingTask(updatedTask, timestamp, success) {
    this.getEditedTask().getValue().flushOverridenValues(updatedTask, timestamp)
    this.editedTask.next(this.getEditedTask().getValue())
    this.editableTasks.next(this.editableTasks.getValue())
    this.getEditedTask().getValue().unlockTaskForSave()

    if (success == true) {
      if (this.editedTask.getValue().shouldBeSave() == true)
        await this.synchronize()
    }
  }

  async synchronize() {
    // CASE #1: DEAL WITH A NEW TASK
    // CASE #2: UPDATE ON AN EXISTING TASK
    let success = false

    if (this.getEditedTask().getValue().canBeSave() == true) {
      if (this.getEditedTask().getValue().taskId) {
        // UPDATE TASK
        let timestamp = moment().valueOf()

        this.getEditedTask().getValue().lockTaskForSave()
        let data = this.buildDataFromOveriddenValues(
          this.getEditedTask().getValue()
        )

        this.updateTask(this.getEditedTask().getValue(), timestamp)
      } else {
        // CREATE TASK
        let timestamp = moment().valueOf()
        this.getEditedTask().getValue().lockTaskForSave()
        let data = this.buildDataFromOveriddenValues(
          this.getEditedTask().getValue()
        )

        this.createTask(this.getEditedTask().getValue(), timestamp)
      }
    } else {
      // Do nothing
    }
  }
}
