<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="opportunities-title">
      <!--publisher-section-intro
        title="{{ 'OPPORTUNITIES' | translate }}"
        description="{{
          'Here is a list of current campaigns. Click on a campaign to have details about the underlying brand and its expectations.'
            | translate
        }}"
      ></publisher-section-intro-->
      <publisher-section-intro
        title="{{ 'OPPORTUNITIES.TITLE' | translate }}"
        description=" "
      ></publisher-section-intro>
    </div>
    <div id="opportunities-wrappers">
      <div class="display-block container-list">
        <div class="row">
          <div
            class="col-12"
            *ngIf="opportunities == undefined || opportunities.length == 0"
          >
            <div class="text-wrapper">
              {{ 'OPPORTUNITIES.NO_CAMPAIGN' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="display-block container-list" style="margin-top: 0">
        <span
          class="btn text-lato text-bold card-title no-margin"
          style="background-color: #ff006c; color: white; font-size: 13px"
          (click)="addOpportunity()"
          >{{ 'OPPORTUNITIES.CREATE_NEW_CAMPAIGN' | translate }}</span
        >
      </div>

      <div
        class="display-block container-list"
        style="margin-top: 0"
        *ngIf="opportunities?.length > 0"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{ 'OPPORTUNITIES.COL_1' | translate }}</th>
              <th scope="col">{{ 'OPPORTUNITIES.COL_2' | translate }}</th>
              <th scope="col">{{ 'OPPORTUNITIES.COL_3' | translate }}</th>
              <th scope="col">{{ 'OPPORTUNITIES.COL_4' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let opportunity of opportunities"
              class="audience-row"
              (click)="visitOpportunity(opportunity)"
              style="cursor: pointer"
            >
              <td>{{ getOpportunityName(opportunity) }}</td>
              <td>{{ getOpportunityType(opportunity) }}</td>
              <td>{{ getOpportunityAudience(opportunity) }}</td>
              <td>{{ getOpportunityDates(opportunity) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--opportunity-thumbnail
        *ngFor="let opportunity of opportunities"
        [opportunity]="opportunity"
      ></opportunity-thumbnail-->
    </div>
  </div>
</div>
