import * as moment from 'moment'

export class Utils {
  static DEFAULT_FORMAT = 'DD/MM/YYYY'

  static convertDoubleToReadableDate(date, format = undefined) {
    if (format == undefined) format = this.DEFAULT_FORMAT

    var dt = moment(date).format()

    return moment(date).format(format)
  }

  static prettifyNumber(number) {
    if (number >= 1000000)
      return { value: '' + (number / 1000000).toFixed(1), unit: 'M' }
    if (number >= 10000)
      return { value: '' + (number / 1000).toFixed(1), unit: ' K' }
    return { value: '' + number, unit: '' }
  }
}
