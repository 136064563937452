import { Injectable } from '@angular/core'

import { Subject } from 'rxjs'

import { APIService } from './index'

@Injectable()
export class CacheService {
  projects: any
  user: any

  private observable = new Subject<void>()
  public observableEvent = this.observable.asObservable()

  constructor(private apiService: APIService) {
    this.reset()
  }

  reset() {
    this.user = undefined
    this.projects = []
  }

  init() {}

  async getUserInformation() {
    var res = await this.apiService.getMyProfile()
    if (res && res.body) {
      if (res.body.user) this.user = res.body.user
      if (res.body.projects) this.projects = res.body.projects
    }
  }

  async getMe() {
    await this.getUserInformation()
    return this.user
  }

  async getProjects() {
    await this.getUserInformation()
    return this.projects
  }

  async getProject(id) {
    await this.getUserInformation()
    for (var i = 0; i < this.projects.length; i++) {
      if (this.projects[i]._id === id) return this.projects[i]
    }
    return undefined
  }

  async getCampaign(id) {
    await this.getUserInformation()
    for (var i = 0; i < this.projects.length; i++) {
      for (var j = 0; j < this.projects[i].campaigns.length; j++) {
        if (this.projects[i].campaigns[j]._id === id)
          return this.projects[i].campaigns[j]
      }
    }
    return undefined
  }
}
