import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { CampaignActionHelper } from '../../../../_helpers/referential/campaign_action.helper'

import * as moment from 'moment'
import { IndustryHelper } from 'src/app/_helpers/referential/industry.helper'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { AlertService } from 'src/app/_services/alert.service'
import { PublishersDealsAPIService } from 'src/app/_services'

@Component({
  templateUrl: 'make-an-offer.component.html',
  selector: 'make-an-offer',
  styleUrls: ['./make-an-offer.component.scss'],
})
export class MakeAnOfferComponent implements OnInit {
  @Input('opportunity') opportunity: any
  @Input('showModal') showModal: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  newActionForm: FormGroup
  offerForm: FormGroup
  isValid: boolean

  publisher: any

  availableActions: any[]
  frequencies: any[]
  actions: any[]

  now: number
  minOpportunityDate: Date
  maxOpportunityDate: Date

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersDealsAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)

    this.availableActions = CampaignActionHelper.getCampaignActions()
    this.frequencies = CampaignActionHelper.getActionFrequencies()
    this.actions = []

    this.publisher = this.route.snapshot.data.user.body

    this.offerForm = this.formBuilder.group({
      campaignNameForm: this.formBuilder.group({
        campaignName: { value: '', disabled: true },
      }),
      descriptionForm: this.formBuilder.group({
        description: { value: '', disabled: false },
      }),
      amount: { value: '', disabled: false },
      expiryDate: [undefined],
    })

    this.newActionForm = this.formBuilder.group({
      action: '',
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initValues()

    this.now = moment().valueOf()
    this.minOpportunityDate = this.getOpportunityMinDate()
    this.maxOpportunityDate = this.getOpportunityMaxDate()
  }

  initValues() {
    this.offerForm = this.formBuilder.group({
      campaignNameForm: this.formBuilder.group({
        campaignName: { value: this.opportunity._scope.title, disabled: true },
      }),
      descriptionForm: this.formBuilder.group({
        description: { value: '', disabled: false },
      }),
      amount: { value: '', disabled: false },
      expiryDate: [undefined],
    })
  }

  ngOnDestroy() {}

  makeEditable() {
    this.alertService.resetAlerts()
    //this.myProfileBioForm.enable()
    return
  }

  makeNotEditable() {
    //this.myProfileBioForm.disable()
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  changeExpiryDate(event) {}

  changeActionDateRange(event) {}

  async saveQuote(status) {
    try {
      this.isValid = true
      this.alertService.resetAlerts()

      let description = this.readTextValue(
        this.offerForm.controls.descriptionForm['controls'].description.value
      )

      if (
        description != undefined &&
        this.offerForm.controls.descriptionForm['controls'].description.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Description can not be empty')
      }

      let amount = this.offerForm.controls.amount
      if (
        amount != undefined &&
        this.offerForm.controls.amount.valid == false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Amount not valid')
      }

      let expiry_date = new Date(
        this.offerForm.controls.expiryDate.value
      ).getTime()

      if (
        expiry_date != undefined &&
        this.offerForm.controls.expiryDate.valid == false
      ) {
        this.isValid = false

        this.alertService.pushErrorAlert('Expiry date not valid')
      }

      let actions = []
      for (let i = 0; i < this.actions.length; i++) {
        actions.push({
          type: this.actions[i].type.key,
          currency: this.actions[i].currency,
          budget: this.actions[i].form.get('amount').value,
          frequency: this.actions[i].form.get('frequency').value,
          dateRange: {
            start: new Date(
              this.actions[i].form.get('actionDateRangeStart').value
            ).getTime(),
            end: new Date(
              this.actions[i].form.get('actionDateRangeEnd').value
            ).getTime(),
          },
          description: this.actions[i].form
            .get('descriptionForm')
            .get('description').value,
        })
      }

      console.log(actions)

      if (this.isValid == true) {
        this.isValid = true

        let offer_infos = {
          name: this.opportunity._scope.title,
          description: description,
          expiryDate: expiry_date,
          status: status,
          opportunity: this.opportunity._id,
          campaignActions: actions,
        }

        let res = await this.apiService.postDeal(
          this.publisher.publisher._id,
          offer_infos
        )

        if (res.status === 200) {
          this.alertService.pushMessageAlert('Your deal has been saved')
          this.makeNotEditable()
          this.closeModal()
        } else {
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async saveDraft() {
    await this.saveQuote('draft')
  }

  async sendOffer() {
    await this.saveQuote('registered')
  }

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  updateAmount(event) {
    let value = event.target.value

    let options = {
      maximumFractionDigits: 2,
      currency: 'EUR',
      style: 'currency',
      currencyDisplay: 'symbol',
    }

    /*this.offerForm.controls.amount.setValue(
      value || value === 0
        ? this.localStringToNumber(value).toLocaleString(undefined, options)
        : ''
    )*/
  }

  localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ''))
  }

  addCampaignAction() {
    let newActionType = this.newActionForm.get('action').value

    if (newActionType && newActionType !== '') {
      let action = this.findActionByKey(newActionType)
      if (action != undefined) {
        this.actions.push({
          type: action,
          frequency: this.frequencies[0],
          currency: '€',
          form: this.formBuilder.group({
            amount: { value: 0, disabled: false },
            frequency: { value: this.frequencies[0].key },
            actionDateRangeStart: [undefined],
            actionDateRangeEnd: [undefined],
            descriptionForm: this.formBuilder.group({
              description: { value: '', disabled: false },
            }),
          }),
        })
      }

      this.newActionForm.get('action').setValue('')
    }
  }

  removeCampaignAction(action) {
    var i = this.actions.indexOf(action)
    this.actions.splice(i, 1)
  }

  findActionByKey(key) {
    for (let i = 0; i < this.availableActions.length; i++) {
      if (key === this.availableActions[i].key) return this.availableActions[i]
    }

    return undefined
  }

  getOpportunityMinDate() {
    let minDate = this.now
    if (
      this.opportunity &&
      this.opportunity._scope &&
      this.opportunity._scope.startDate
    ) {
      if (this.opportunity._scope.startDate > minDate)
        minDate = this.opportunity._scope.startDate
    }

    return new Date(minDate)
  }

  getOpportunityMaxDate() {
    let maxDate = this.now
    if (
      this.opportunity &&
      this.opportunity._scope &&
      this.opportunity._scope.endDate
    ) {
      if (this.opportunity._scope.endDate > maxDate)
        maxDate = this.opportunity._scope.endDate
    }

    return new Date(maxDate)
  }

  closeDatePicker(eventData: any, dp?: any) {
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    dp.close()
  }

  actionHasRange(action) {
    try {
      return action.frequency.hasRange
    } catch (e) {
      console.log(e)
      return false
    }
  }

  changeActionFrequency(action, index) {
    this.actions[
      index
    ].frequency = CampaignActionHelper.getActionFrequencyByKey(
      action.form.get('frequency').value
    )
  }
}
