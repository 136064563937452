<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <div class="row">
      <div class="col-9 text-lato">
        <div class="section-title text-pink text-bold text-large">
          <div class="input-title">Awards & honors</div>
        </div>
      </div>
      <div class="col-3 flex-end section-btn">
        <add-button (click)="addAward()"></add-button>
        <edit-button
          class="edit-information"
          (click)="makeEditable()"
          (click)="updateAward()"
          *ngIf="getAwards() != undefined && getAwards().length > 0"
        ></edit-button>
      </div>
    </div>
    <div class="row center">
      <div class="no-position" *ngIf="getNumberOfAwards() == 0">
        <div>
          <img
            class="hint"
            src="./../../../../../../assets/images/icon_idea.png"
          />
        </div>
        <div>
          Adding your awards and honors in your profile will allow you to get
          relevant campaigns
        </div>
        <div></div>
        <tiny-cta
          (click)="addAward()"
          value="ADD YOUR FIRST AWARD OR HONOR"
        ></tiny-cta>
      </div>
      <div class="positions-list" *ngIf="getNumberOfAwards() > 0"></div>
    </div>
    <div
      class="row center padding-award-section text-darkgrey award-separator"
      *ngFor="let award of getAwards()"
    >
      <div class="col-12 text-medium text-bold no-padding">
        {{ award.name }}
      </div>
      <div class="col-12 text-small no-padding">
        {{ getDate(award) }} - {{ award.description }}
      </div>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>

<!-- Modal -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModal == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalAddAward"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddAward"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalAddAwardsTitle">
                    {{ 'Add an award or honor' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="awardsForm">
              <div class="row">
                <div class="col-12 text-lato field">
                  <div class="input-title">Award or honor title</div>
                  <div class="input-value container-field">
                    <input-field
                      class="input-field"
                      type="text"
                      placeholder="Awards title"
                      name="title"
                      relatedControlName="title"
                      formGroupName="titleForm"
                      [parentGroup]="awardsForm"
                    >
                    </input-field>
                  </div>
                </div>
                <div class="col-3 text-lato field">
                  <div class="input-title">Award date</div>
                  <mat-form-field
                    class="input-text text-lato text-medium text-darkgrey"
                  >
                    <input
                      class="test-xsmall"
                      matInput
                      [matDatepicker]="picker"
                      name="date"
                      formControlName="date"
                      (dateChange)="changeDate($event)"
                      autocomplete="off"
                      parentGroup="awardsForm"
                    />
                    <mat-placeholder class="placeholder"
                      >Award date</mat-placeholder
                    >
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-12 text-lato field">
                  <div class="input-title">Description</div>
                  <div class="input-value container-field">
                    <input-text-area
                      class="input-field"
                      type="text"
                      placeholder="Description"
                      name="description"
                      relatedControlName="description"
                      formGroupName="descriptionForm"
                      [parentGroup]="awardsForm"
                    >
                    </input-text-area>
                  </div>
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your award or honor have been successfully added
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your award or honor was not added, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary one-btn-radius text-lato btn-text"
              (click)="!loading ? saveChanges() : ''"
            >
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal update -->
<div
  style="position: fixed; top: 0; width: 100%; display: flex; z-index: 20"
  *ngIf="showModalUpdate == true"
>
  <div class="col-12 d-block" id="modal-menu" style="height: 100vh; padding: 0">
    <div
      class="modal fade modal-overlay"
      id="modalUpdateAward"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdateAward"
      aria-hidden="false"
      (click)="closeModal()"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: calc(80% - 75px)"
        role="document"
        (click)="stopPropagation($event)"
      >
        <div class="modal-content modal-container">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h5 class="modal-title text-lato" id="modalUpdateAwardTitle">
                    {{ 'Delete award' | translate }}
                  </h5>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="section-container-inner">
            <form [formGroup]="awardsForm">
              <div class="row" *ngFor="let award of getAwards()">
                <span class="col-5 text-medium text-bold">{{
                  award.name
                }}</span>
                <span class="col-5 text-xxsmall">{{ getDate(award) }}</span>
                <div class="col-10 text-small award-separator">
                  {{ award.description }}
                </div>
                <div class="col-2 text-center">
                  <img
                    class="icon-delete"
                    (click)="deleteAward(award)"
                    src="./../../../../assets/images/icon-close.png"
                  />
                </div>
              </div>
              <div
                class="text-darkgrey text-xxsmall"
                *ngIf="successfullyRemoved == true"
              >
                Your award or honor have been successfully removed
              </div>
              <div
                class="text-pink text-xxsmall"
                *ngIf="successfullyRemoved == false"
              >
                Your award or honor was not removed, please try again
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-delete one-btn-radius text-lato btn-text"
              (click)="closeModal()"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
