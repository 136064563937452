import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

@Component({
  templateUrl: 'publisher-collaborations.component.html',
  styleUrls: ['./publisher-collaborations.component.scss'],
})
export class PublisherCollaborationsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  selectedMenuItem: string
  hasCollaborations: boolean

  collaborationsData: any

  constructor(
    private router: Router,
    public alertService: AlertService,
    private route: ActivatedRoute
  ) {
    this.selectedMenuItem = 'summary'
    this.collaborationsData = route.snapshot.data.taskData
    this.hasCollaborations = true
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  goToOpportunities() {
    this.router.navigate(['/opportunities'])
  }

  manageMenuItem(newValue) {
    this.selectedMenuItem = newValue
  }
}
