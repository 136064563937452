<div class="display-block col-direction section-container">
  <div class="one-edge-shadow section-container-inner">
    <form [formGroup]="myBillingForm">
      <div class="row">
        <div class="col-10 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'MY_BILLING.CORPORATE_NAME' | translate }}
          </div>
          <div class="input-value container-field">
            <input-field
              class="input-field"
              type="text"
              placeholder="{{
                'MY_BILLING_EDIT.CORPORATE_NAME_PLACEHOLDER' | translate
              }}"
              name="corporateName"
              relatedControlName="corporateName"
              formGroupName="corporateNameForm"
              [parentGroup]="myBillingForm"
            >
            </input-field>
          </div>
        </div>
        <div class="col-2 flex-end section-btn">
          <tiny-cta
            *ngIf="isEditing == false"
            (click)="makeEditable()"
            value="{{ 'MY_BILLING.EDIT' | translate }}"
          ></tiny-cta>
          <tiny-cta
            *ngIf="isEditing == true"
            (click)="saveChanges()"
            value="{{ 'MY_BILLING.SAVE' | translate }}"
          ></tiny-cta>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-lato">
          <div class="section-body text-small text-darkgrey">
            <div class="input-title">
              {{ 'MY_BILLING.ADDRESS' | translate }}
            </div>
            <div class="input-value" *ngIf="isEditing == false">
              <div class="row">
                <!-- prettier-ignore -->
                <div class="col-10 text-lato container-field multiline-text">{{ getAddressToDisplay() }}
                </div>
              </div>
            </div>
            <div class="input-value" *ngIf="isEditing == true">
              <div class="row">
                <div class="col-10 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="street_number"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.ADDRESS_LINE_1_TITLE' | translate
                    }}"
                    name="address_1"
                    relatedControlName="address_1"
                    formGroupName="address_1Form"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>
                <div class="col-2"></div>

                <div class="col-10 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="route"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.ADDRESS_LINE_2_TITLE' | translate
                    }}"
                    name="address_2"
                    relatedControlName="address_2"
                    formGroupName="address_2Form"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>

                <div class="col-2"></div>
                <div class="col-5 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="postal_code"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.ZIP_CODE_TITLE' | translate
                    }}"
                    name="zipCode"
                    relatedControlName="zipCode"
                    formGroupName="zipCodeForm"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>
                <div class="col-5 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="locality"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.CITY_TITLE' | translate
                    }}"
                    name="city"
                    relatedControlName="city"
                    formGroupName="cityForm"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>
                <div class="col-2"></div>
                <div class="col-5 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="administrative_area_level_1"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.STATE_TITLE' | translate
                    }}"
                    name="state"
                    relatedControlName="state"
                    formGroupName="stateForm"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>

                <div class="col-5 text-lato container-field inner-form">
                  <input-field
                    class="input-field"
                    identifier="country"
                    type="text"
                    placeholder="{{
                      'PROJECT.MY_BILLING_EDIT.COUNTRY_TITLE' | translate
                    }}"
                    name="country"
                    relatedControlName="country"
                    formGroupName="countryForm"
                    [parentGroup]="myBillingForm"
                  >
                  </input-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'PROJECT.MY_BILLING_EDIT.PREFERRED_CURRENCY_TITLE' | translate }}
          </div>
          <div class="input-value" *ngIf="isEditing == false">
            <div class="row">
              <!-- prettier-ignore -->
              <div class="col-10 text-lato container-field multiline-text">{{ getCurrencyToDisplay() }}
              </div>
            </div>
          </div>
          <div class="input-value container-field" *ngIf="isEditing == true">
            <select
              name="preferredCurrency"
              formControlName="preferredCurrency"
              id="preferred-currency-select"
              class="custom-select content-width"
            >
              <option value="">
                {{ 'MY_BILLING_EDIT.DROPDOWN_NO_VALUE' | translate }}
              </option>
              <option
                [value]="currency.code"
                *ngFor="let currency of getCurrencies()"
              >
                {{ currency.value }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-10 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'PROJECT.MY_BILLING_EDIT.VAT_NUMBER_TITLE' | translate }}
          </div>
          <div class="input-value container-field">
            <input-field
              class="input-field"
              type="text"
              placeholder="{{
                'PROJECT.MY_BILLING_EDIT.VAT_NUMBER_PLACEHOLDER' | translate
              }}"
              name="vatNumber"
              relatedControlName="vatNumber"
              formGroupName="vatNumberForm"
              [parentGroup]="myBillingForm"
            >
            </input-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 section-body text-small text-darkgrey">
          <div class="input-title">
            {{ 'PROJECT.MY_BILLING_EDIT.BILLING_EMAIL' | translate }}
          </div>
          <div class="input-value container-field">
            <input-field
              class="input-field"
              type="text"
              placeholder="{{
                'PROJECT.MY_BILLING_EDIT.BILLING_EMAIL_PLACEHOLDER' | translate
              }}"
              name="contactEmail"
              relatedControlName="contactEmail"
              formGroupName="contactEmailForm"
              [parentGroup]="myBillingForm"
            >
            </input-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
