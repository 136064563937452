import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'tiny-cta.component.html',
  selector: 'tiny-cta',
  styleUrls: ['./tiny-cta.component.scss'],
})
export class TinyCTAComponent implements OnInit {
  @Input('value') value: string

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
