import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'

enum PublicLayout {
  SingleColumn = 'public_layout_single_column',
  MultipleColumns = 'public_layout_multi_column',
}

@Component({
  selector: 'public-multiple-columns-layout',
  templateUrl: 'public-multiple-columns-layout.component.html',
  styleUrls: ['./public-multiple-columns-layout.component.scss'],
})
export class PublicMultipleColumnsLayoutComponent
  implements OnInit, AfterViewInit, OnDestroy {
  ltr: boolean = true

  constructor(private route: ActivatedRoute) {
    this.ltr = route.snapshot.data['ltr']
  }

  ngOnInit() {}

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  public get publicLayout(): typeof PublicLayout {
    return PublicLayout
  }
}
