<div class="content-width" app-theme>
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="advertiser-billing-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'MY_BILLING.INFO_TITLE' | translate }}"
      ></publisher-section-intro>
      <brand-billing-informations
        [brand]="project"
      ></brand-billing-informations>
    </div>
    <div id="advertiser-billing-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'MY_BILLING.HISTORY_TITLE' | translate }}"
      ></publisher-section-intro>
      <brand-billing-history [brand]="project"></brand-billing-history>
    </div>
  </div>
</div>
<loader *ngIf="loading == true"></loader>
