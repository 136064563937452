<div class="content-width" app-theme>
  <publisher-nav-bar class="d-block bg-publisher-nav-bar"></publisher-nav-bar>
  <modal></modal>
  <div class="content-page">
    <div id="publisher-settings-wrapper" class="max-w-80pt">
      <publisher-section-intro
        title="{{ 'PREFERENCES' | translate }}"
        description="{{ '' | translate }}"
      ></publisher-section-intro>
      <publisher-preferences-form
        [publisher]="publisher"
      ></publisher-preferences-form>
    </div>
  </div>
</div>

<loader *ngIf="loading == true"></loader>
