import { Component, OnInit } from '@angular/core'

import { ModalService } from '../../../_services/modal.service'

@Component({
  templateUrl: 'modal-workspace-members.component.html',
  selector: 'modal-workspace-members',
  styleUrls: ['./modal-workspace-members.component.scss'],
})
export class ModalWorkspaceMembersComponent implements OnInit {
  workspace: any

  members: any

  constructor(private modalService: ModalService) {
    this.workspace = undefined
    this.members = []
  }

  async ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('profile')
  }
}
