import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { DateHelper } from 'src/app/_helpers/date.helpers'
import { StorageManagementService } from 'src/app/_services'
import { AlertService } from '../../../../_services/alert.service'

@Component({
  templateUrl: 'advertiser-opportunities.component.html',
  styleUrls: ['./advertiser-opportunities.component.scss'],
})
export class AdvertiserOpportunitiesComponent implements OnInit, OnDestroy {
  opportunities: any[]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    public storageManagementService: StorageManagementService
  ) {
    this.opportunities = route.snapshot.data.campaigns
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  addOpportunity() {
    this.router.navigate([
      'workspaces/' +
        this.storageManagementService.getCurrentBrandspaceId() +
        '/campaigns/new',
    ])
  }

  visitOpportunity(opportunity) {
    console.log(opportunity)
    let url =
      'workspaces/' +
      this.storageManagementService.getCurrentBrandspaceId() +
      '/campaigns/' +
      opportunity._id
    this.router.navigate([url])
  }

  getOpportunityName(opportunity) {
    return opportunity.name
  }

  getOpportunityType(opportunity) {
    let result = ''
    if (opportunity.target === 'discovery') result = 'Discovery'
    else if (opportunity.target === 'collaboration') result = 'Collaboration'
    return result
  }

  getOpportunityAudience(opportunity) {
    return opportunity._scope.name
  }

  getOpportunityDates(opportunity) {
    let startDate = DateHelper.convertDoubleToReadableDate(
      opportunity.startDate
    )

    let endDate = DateHelper.convertDoubleToReadableDate(opportunity.endDate)

    return startDate + ' - ' + endDate
  }
}
