import { Task } from './task.model'
import * as moment from 'moment'
import { DateHelper } from 'src/app/_helpers/date.helpers'

export class EditableTask {
  private _isBeingEdited: boolean
  private _isBeingSaved: boolean
  private _overiddenValues: any
  private _task: Task

  constructor() {
    this._isBeingEdited = false
    this._isBeingSaved = false
    this._overiddenValues = {}
    this._task = new Task()
  }

  public flushOverridenValues(task: any, timestamp: number) {
    this._task = Task.buildTask(task)
    let flushedValues = {}
    for (const property in this._overiddenValues) {
      if (timestamp < this._overiddenValues[property].timestamp) {
        flushedValues[property] = this._overiddenValues[property]
      }
    }
    this._overiddenValues = flushedValues
  }

  public get isBeingEdited() {
    return this._isBeingEdited
  }

  public set isBeingEdited(isBeingEdited: boolean) {
    this._isBeingEdited = isBeingEdited
  }

  public get overiddenValues() {
    return this._isBeingEdited
  }

  public set overiddenValues(overiddenValues: any) {
    this._overiddenValues = overiddenValues
  }

  public get task() {
    return this._task
  }

  public set task(task: Task) {
    this._task = task
  }

  public get taskId() {
    return this._task.id
  }

  public set taskId(id: string) {
    this._task.id = id
  }

  public get name() {
    if (this._overiddenValues['name'])
      return this._overiddenValues['name'].new_value
    return this._task.name
  }

  public set name(name: string) {
    this._overiddenValues['name'] = {
      old_value: this._task.name,
      new_value: name,
      timestamp: moment().valueOf(),
    }
  }

  public get step() {
    if (this._overiddenValues['step'])
      return this._overiddenValues['step'].new_value
    return this._task.step
  }

  public set step(step: string) {
    this._overiddenValues['step'] = {
      old_value: this._task.step,
      new_value: step,
      timestamp: moment().valueOf(),
    }
  }

  public get priority() {
    if (this._overiddenValues['priority'])
      return this._overiddenValues['priority'].new_value
    return this._task.priority
  }

  public set priority(priority: string) {
    this._overiddenValues['priority'] = {
      old_value: this._task.priority,
      new_value: priority,
      timestamp: moment().valueOf(),
    }
  }

  public get dueDate() {
    if (this._overiddenValues['dueDate'])
      return this._overiddenValues['dueDate'].new_value
    return this._task.dueDate
  }

  public set dueDate(dueDate: string) {
    this._overiddenValues['dueDate'] = {
      old_value: this._task.dueDate,
      new_value: moment(dueDate).valueOf(),
      timestamp: moment().valueOf(),
    }
  }

  public get assignee() {
    if (this._overiddenValues['assignee'])
      return this._overiddenValues['assignee'].new_value
    return this._task.assignee
  }

  public set assignee(assignee: string) {
    this._overiddenValues['assignee'] = {
      old_value: this._task.assignee,
      new_value: assignee,
      timestamp: moment().valueOf(),
    }
  }

  public get description() {
    if (this._overiddenValues['description'])
      return this._overiddenValues['description'].new_value
    return this._task.description
  }

  public set description(description: string) {
    this._overiddenValues['description'] = {
      old_value: this._task.description,
      new_value: description,
      timestamp: moment().valueOf(),
    }
  }

  public get comments() {
    if (this._overiddenValues['comments'])
      return this._overiddenValues['comments'].new_value
    return this._task.comments
  }

  public set comments(comments: any) {
    this._overiddenValues['comments'] = {
      old_value: this._task.comments,
      new_value: comments,
      timestamp: moment().valueOf(),
    }
  }

  public get deal() {
    return this._task.deal
  }

  public set deal(deal: any) {
    this._task.deal = deal
  }

  public get brand() {
    return this._task.brand
  }

  public set brand(brand: any) {
    this._task.brand = brand
  }

  public get status() {
    return this._task.status
  }

  public set status(status: any) {
    this._task.status = status
  }

  public canBeSave() {
    if (this._isBeingSaved == true) return false
    else return this.name != undefined /*&& this.deal != undefined*/
  }

  public shouldBeSave() {
    return Object.keys(this._overiddenValues).length != 0
  }

  public lockTaskForSave() {
    this._isBeingSaved = true
  }

  public unlockTaskForSave() {
    this._isBeingSaved = false
  }

  public get isLockedForSave() {
    return this._isBeingSaved
  }

  public static filterTasks(
    tasks: EditableTask[],
    key,
    value,
    operator,
    brands
  ): EditableTask[] {
    return tasks.filter((task) => {
      if (operator === 'eq') {
        if (value !== Object(value)) return task[key] == value
        else return task[key] === value
      }

      if (operator === 'lt') {
        return task[key] < value
      }

      if (operator === 'lte') {
        return task[key] <= value
      }

      if (operator === 'gt') {
        return task[key] > value
      }

      if (operator === 'gte') {
        return task[key] >= value
      }

      if (key === 'dueDateThisWeek') {
        for (let i = 0; i < DateHelper.getCurrentWeek().length; i++) {
          if (task._task.dueDate == DateHelper.getCurrentWeek()[i]) {
            return task._task.dueDate
          }
        }
      }

      if (key === 'dueDateNextWeek') {
        for (let i = 0; i < DateHelper.getNextWeek().length; i++) {
          if (task._task.dueDate == DateHelper.getNextWeek()[i]) {
            return task._task.dueDate
          }
        }
      }

      if (key === 'brand') {
        for (let i = 0; i < brands.length; i++) {
          if (task._task.brandId == brands[i]._id) {
            return task._task.brand
          }
        }
      }

      return false
    })
  }
}
