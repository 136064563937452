<div [formGroup]="parentGroup">
  <div [formGroupName]="formGroupName">
    <input
      [type]="type"
      [placeholder]="placeholder"
      class="input-text text-lato text-medium text-darkgrey"
      [id]="identifier"
      [name]="name"
      [formControlName]="relatedControlName"
      autocomplete="off"
      (change)="change != undefined ? change($event) : doNothing()"
      [step]="step"
      [id]="id"
    />
  </div>
</div>
