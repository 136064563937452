import { Injectable } from '@angular/core'
import { AuthService } from '@cryptr/cryptr-angular'

import { CryptrAuthenticationService } from '../../_services/authentication/cryptr.authentication.service'
import { InternalAuthenticationService } from '../../_services/authentication/internal.authentication.service'
import { StorageManagementService } from '../storage.management.service'

@Injectable()
export class AuthenticationService {
  constructor(
    private authService: AuthService,
    private cryptrAuthenticationService: CryptrAuthenticationService,
    private internalAuthenticationService: InternalAuthenticationService,
    private storageManagementService: StorageManagementService
  ) {}

  guessAuthProvider() {
    if (localStorage.getItem('h-token')) {
      return 'fns'
    } else {
      return 'cryptr'
    }
  }

  logout() {
    this.storageManagementService.reset()
    if (this.guessAuthProvider() === 'fns') {
      this.internalAuthenticationService.logout()
    } else {
      this.cryptrAuthenticationService.logout(() => {})
    }
  }
}
