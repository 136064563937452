import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'plain-button.component.html',
  selector: 'plain-button',
  styleUrls: ['./plain-button.component.scss'],
})
export class PlainButtonComponent implements OnInit {
  @Input('value') value: string

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
