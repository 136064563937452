import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ThemeService } from './../../../_services/theme/theme.service'

@Component({
  selector: 'theme-switcher',
  templateUrl: 'theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {
  themeSwitcherForm: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.themeSwitcherForm = this.formBuilder.group({
      isDark: [true],
    })
  }

  refreshTheme() {
    let value = this.themeSwitcherForm.controls.isDark.value
    if (value == true) {
      this.themeService.setTheme('dark')
    } else {
      this.themeService.setTheme('light')
    }
  }
}
