import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

@Component(
    {
        templateUrl: 'error.component.html',
        styleUrls: ['./error.component.scss']
    }
)
export class ErrorComponent implements OnInit {

    error: string;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.error = route.snapshot.data['error'] || 'COMMON.ERROR'
    }

    async ngOnInit() {
    }

    ngOnDestroy() {

    }
}