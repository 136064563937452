<div class="display-block col-direction section-container">
  <div class="max-w-80pt one-edge-shadow section-container-inner">
    <div class="row hv-center">
      <div class="col-4 section-percent text-align-center">
        <div
          id="subtitle-profile"
          class="text-darkgrey text-large text-bold text-align-center"
        >
          PROFILE
        </div>
        <div class="padding-top" style="max-width: 180px; margin: auto">
          <circle-progress
            class="percent-circle-pink"
            [percent]="getProfilePercentageFilled() * 100"
            [outerStrokeColor]="'#ff006c'"
            [responsive]="true"
            [renderOnClick]="false"
          ></circle-progress>
        </div>
        <div class="text-xsmall padding-top">
          Enriching your profile will allow you to get more relevant campaigns
        </div>
        <div class="padding-top"></div>
        <tiny-cta
          id="profile-btn-1"
          value="VISIT MY PROFILE"
          (click)="goToProfile()"
        ></tiny-cta>
      </div>
      <div class="col-4 section-percent text-align-center">
        <div
          id="subtitle-kyc"
          class="text-darkgrey text-large text-bold text-align-center"
        >
          KYB
        </div>
        <div class="padding-top" style="max-width: 180px; margin: auto">
          <circle-progress
            class="percent-circle-gold"
            [percent]="getProfilePercentageFilled() * 100"
            [outerStrokeColor]="'#ffad00'"
            [responsive]="true"
            [renderOnClick]="false"
          ></circle-progress>
        </div>
        <div class="text-xsmall padding-top">
          Enriching your profile will allow you to get more relevant campaigns
        </div>
        <div class="padding-top"></div>
        <tiny-cta
          id="profile-btn-2"
          value="VISIT MY PROFILE"
          (click)="goToProfile()"
        ></tiny-cta>
      </div>
      <div class="col-4 section-percent text-align-center">
        <div
          id="subtitle-contract"
          class="text-darkgrey text-large text-bold text-align-center"
        >
          MERCHANT CONTRACT
        </div>
        <div class="padding-top" style="max-width: 180px; margin: auto">
          <circle-progress
            class="percent-circle-blue"
            [percent]="getProfilePercentageFilled() * 100"
            [outerStrokeColor]="'#09cced'"
            [responsive]="true"
            [renderOnClick]="false"
          ></circle-progress>
        </div>
        <div class="text-xsmall padding-top">
          Enriching your profile will allow you to get more relevant campaigns
        </div>
        <div class="padding-top"></div>
        <tiny-cta
          id="profile-btn-3"
          value="VISIT MY PROFILE"
          (click)="goToProfile()"
        ></tiny-cta>
      </div>
    </div>
  </div>
</div>
