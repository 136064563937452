<div
  class="row"
  *ngIf="alert.type === 'message'"
  style="
    background-color: #30313e;
    border-color: #30313e;
    margin-right: 0px;
    z-index: 100;
  "
>
  <div class="col-12">
    <div
      class="row alert alert-primary"
      role="alert"
      style="
        margin-bottom: 0px;
        border-radius: 0px;
        color: white;
        background-color: #30313e;
        border-color: #30313e;
      "
    >
      <div class="col-10 text-lato" style="margin-left: 10px">
        <i class="fa fa-check" style="margin-right: 15px"></i
        >{{ alert.message | translate }}
      </div>
      <div
        class="col-2"
        style="
          position: absolute;
          right: 40px;
          top: 0px;
          padding-top: 12px;
          height: 100%;
          width: 48px;
          text-align: right;
        "
        (click)="removeAlert()"
      >
        <i class="fa fa-times" style="margin-right: 5px"></i>
      </div>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="alert.type === 'error'"
  style="background-color: #ff006c; border-color: #ff006c; z-index: 100"
>
  <div class="col-12">
    <div
      class="row alert alert-danger"
      role="alert"
      style="
        margin-bottom: 0px;
        border-radius: 0px;
        color: white;
        background-color: #ff006c;
        border-color: #ff006c;
      "
    >
      <div class="col-10 text-lato" style="margin-left: 10px">
        <i class="fa fa-exclamation-triangle" style="margin-right: 15px"></i
        >{{ alert.message | translate }}
      </div>
      <div
        class="col-2"
        style="
          position: absolute;
          right: 40px;
          top: 0px;
          padding-top: 12px;
          height: 100%;
          width: 48px;
          text-align: right;
        "
        (click)="removeAlert()"
      >
        <i class="fa fa-times" style="margin-right: 5px"></i>
      </div>
    </div>
  </div>
</div>
