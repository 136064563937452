/**

 *
 * @export
 * @class CampaignType
 */
export class CampaignType {
  /**
   * id is an instance will contain the id of the campaign
   * @type {string}
   * @memberof CampaignType
   */
  id: string

  /**
   * info is an unique id who define the campaign
   * @type {string}
   * @memberof CampaignType
   */
  info: string

  /**
   * text is an instance will contain the description of types
   * @type {string}
   * @memberof CampaignType
   */
  text: string

  /**
   * img is an instance will contain images for types
   * @type {string}
   * @memberof CampaignType
   */
  img: string

  /**
   * img_selected is an instance will contain the image selected
   * @type {string}
   * @memberof CampaignType
   */
  img_selected: string

  /**
   * Creates an instance of CampaignType.
   * @param {*} [values=undefined]
   * @memberof CampaignType
   */
  constructor(values = undefined) {
    if (values == undefined) {
      this.id = ''
      this.info = ''
      this.text = ''
      this.img = ''
      this.img_selected = ''
    } else {
      this.id = values['id']
      this.info = values['info']
      this.text = values['text']
      this.img = values['img']
      this.img_selected = values['img_selected']
    }
  }
}
