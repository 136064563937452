import { Component, OnInit, Input } from '@angular/core'

import { ModalService } from '../../../_services/modal.service'

@Component({
  templateUrl: 'modal-info.component.html',
  selector: 'modal-info',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {
  @Input('action') action: string
  @Input('campaign') campaign: string
  @Input('topic') topic: string

  currentCampaign: any
  currentScaler: any

  modalTitle: any
  modalText: any

  modalCancelText: any
  modalAcceptText: any

  constructor(private modalService: ModalService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  closeModal() {
    this.modalService.toggleModal('confirm')
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
