let campaignActions = [
  { key: 'guest-blogging', value: 'Guest blogging' },
  {
    key: 'webinar-co-hosting',
    value: 'Webinar Co-hosting',
  },
  { key: 'podcast-interview', value: 'Podcast interview' },
  {
    key: 'sponsored-podcast',
    value: 'Sponsored podcast',
  },
]

let actionFrequencies = [
  { key: 'one-time', value: 'One-time', hasRange: false },
  { key: 'weekly', value: 'Weekly', hasRange: true },
  { key: 'monthly', value: 'Monthly', hasRange: true },
]

export class CampaignActionHelper {
  static getCampaignActions() {
    return campaignActions
  }

  static getActionFrequencies() {
    return actionFrequencies
  }

  static getActionFrequencyByKey(key) {
    for (let i = 0; i < actionFrequencies.length; i++) {
      if (actionFrequencies[i].key === key) return actionFrequencies[i]
    }
    return undefined
  }
}
