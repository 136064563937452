<div id="anchor1">
  <p
    class="text-lato text-bold text-darkgrey font-title"
    style="margin-top: 10px !important"
  >
    {{ 'CAMPAIGN.CREATE.STEP_1_TITLE_1' | translate }}
  </p>

  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
  >
    <div class="row" style="">
      <div
        class="form-group col-md-6"
        style="text-align: right; margin-bottom: 0px"
      >
        <select
          id="country"
          class="form-control custom-select input-text text-lato text-medium text-darkgrey"
          name="country"
          formControlName="country"
          style="width: calc(100% - 46px)"
          (change)="resetStates()"
        >
          <option value="" disabled selected>
            {{ 'CAMPAIGN.CREATE.STEP_1_COUNTRY' | translate }}
          </option>
          <!--option>France</option>
                            <option>Belgique</option-->
          <option *ngFor="let country of countries" [value]="country.id">
            {{ country.name | translate }}
          </option>
        </select>
      </div>
      <div
        class="form-group col-md-6"
        style="text-align: left; margin-bottom: 0px"
      >
        <select
          id="state"
          class="form-control custom-select input-text text-lato text-medium text-darkgrey"
          name="area"
          formControlName="area"
          style="width: calc(100% - 46px)"
        >
          <option value="" disabled selected>
            {{ 'CAMPAIGN.CREATE.STEP_1_AREA' | translate }}
          </option>
          <!--option>Hauts de France</option>
                            <option>Ile de France</option-->
          <option *ngFor="let state of statesToIterate" [value]="state.id">
            {{ state.name | translate }}
          </option>
        </select>
        <input
          style="
            width: 36px;
            height: 36px;
            margin-left: 10px;
            margin-right: 0px;
            padding: 0px;
            font-size: 24px;
            padding-bottom: 3px;
            margin-bottom: 20px;
          "
          class="btn btn-primary btn-big text-lato text-bold"
          (click)="addLocation()"
          value="+"
          readonly="readonly"
        />
      </div>
      <!--div
        class="col-12"
        style="padding-left: 50px; padding-right: 50px; margin-bottom: 1rem"
      >
        <div
          class="btn text-lato text-bold card-title no-margin"
          style="background-color: #ff006c; color: white; font-size: 13px"
          (click)="importLocations()"
          *ngIf="
            project?.brand?.products &&
            project?.brand?.products.length > 0 &&
            project?.brand?.products[0].locations &&
            project?.brand?.products[0].locations.length > 0
          "
        >
          {{ 'IMPORT LOCATIONS DEFINED IN YOUR BRAND' | translate }}
        </div>
        <div
          class="text-lato text-bold card-title no-margin"
          style="color: #ff006c; font-size: 13px"
          *ngIf="
            project?.brand?.products == undefined ||
            project?.brand?.products.length == 0 ||
            project?.brand?.products[0].locations == undefined ||
            project?.brand?.products[0].locations.length == 0
          "
        >
          {{
            'Set your preferred locations in your Brand tab to import them and create your campaign even faster'
              | translate
          }}
        </div>
      </div-->
      <div class="col-12" style="padding-left: 50px; padding-right: 50px">
        <div
          *ngIf="locations.length > 0"
          style="
            border-radius: 4px;
            padding-bottom: 37px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          "
        >
          <div *ngFor="let location of locations">
            <div style="display: flex; flex-direction: row">
              <div
                class="col-auto"
                style="
                  white-space: nowrap;
                  border-radius: 4px;
                  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                  background-color: #f7f7f7;
                  padding-left: 18px;
                  padding-right: 18px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  margin-left: 6px;
                  margin-right: 6px;
                  margin-top: 6px;
                  margin-bottom: 6px;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div
                    class="text-lato"
                    style="font-size: 15px; font-weight: bold; color: #30313e"
                  >
                    {{ location.country | translate }},
                    {{ location.state | translate }}
                  </div>
                  <div>
                    <input
                      style="
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                        margin-right: 0px;
                        padding: 0px;
                        font-size: 10px;
                        padding-bottom: 2px;
                      "
                      class="btn btn-primary btn-big text-lato text-bold"
                      value="x"
                      (click)="removeLocation(location)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p class="text-lato text-bold text-darkgrey font-title" style="">
    {{ 'CAMPAIGN.CREATE.STEP_1_TITLE_2' | translate }}
  </p>
  <p class="text-lato text-medium text-lightgrey margin-v-30">
    {{ 'CAMPAIGN.CREATE.STEP_1_INSTRUCTIONS_2' | translate }}
  </p>
  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
  >
    <div class="row">
      <div class="col-12">
        <div style="flex-direction: row" class="max-w-400">
          <input
            style="width: calc(100% - 46px)"
            type="text"
            placeholder="{{
              'CAMPAIGN.CREATE.STEP_1_PLACEHOLDER_AUDIENCE' | translate
            }}"
            class="input-text text-lato text-medium text-darkgrey"
            name="keyword"
            formControlName="keyword"
            autocomplete="off"
          />
          <input
            style="
              width: 36px;
              height: 36px;
              margin-left: 10px;
              margin-right: 0px;
              padding: 0px;
              font-size: 24px;
              padding-bottom: 3px;
            "
            class="btn btn-primary btn-big text-lato text-bold"
            (click)="addKeyword()"
            value="+"
            readonly="readonly"
          />
        </div>
        <div class="col-12" style="padding-left: 50px; padding-right: 50px">
          <div
            *ngIf="keywords.length > 0"
            style="
              border-radius: 4px;
              padding-bottom: 37px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
            "
          >
            <div *ngFor="let keyword of keywords">
              <div style="display: flex; flex-direction: row">
                <div
                  class="col-auto"
                  style="
                    white-space: nowrap;
                    border-radius: 4px;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                    background-color: #f7f7f7;
                    padding-left: 18px;
                    padding-right: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-left: 6px;
                    margin-right: 6px;
                    margin-top: 6px;
                    margin-bottom: 6px;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div
                      class="text-lato"
                      style="font-size: 15px; font-weight: bold; color: #30313e"
                    >
                      {{ keyword }}
                    </div>
                    <div>
                      <input
                        style="
                          width: 20px;
                          height: 20px;
                          margin-left: 10px;
                          margin-right: 0px;
                          padding: 0px;
                          font-size: 10px;
                          padding-bottom: 2px;
                        "
                        class="btn btn-primary btn-big text-lato text-bold"
                        value="x"
                        (click)="removeKeyword(keyword)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" style="margin-top: 31px">
        <input
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          (click)="addKeyword()"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
        />
      </div>
    </div>
  </form>
</div>
