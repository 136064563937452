import { Deserializable } from '../deserializable/deserializable.model'

export class Credentials implements Deserializable {
  private authToken: string
  private refreshToken: string

  constructor() {
    this.authToken = undefined
    this.refreshToken = undefined
  }

  public getAuthToken(): string {
    return this.authToken
  }

  public setAuthToken(authToken: string) {
    this.authToken = authToken
  }

  public getRefreshToken(): string {
    return this.refreshToken
  }

  public setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken
  }

  public deserialize(input: any) {
    Object.assign(this, input)
    return this
  }
}
