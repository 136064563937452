<div
  class="container-fluid full-height bg-default"
  [style.overflow]="getOverflow()"
  [style.height]="getHeight()"
>
  <div class="row">
    <div class="col-12 d-flex d-lg-none no-padding-right">
      <mobile-nav-bar
        [full]="true"
        title="{{ 'PROJECT.MY_BRAND.TITLE' | translate }}"
        class="width-100"
      ></mobile-nav-bar>
    </div>
  </div>
  <div class="row v-margin h-margin">
    <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
      <left-menu></left-menu>
    </div>
    <div class="col-12 col-lg-9 d-block" id="content">
      <div>
        <nav-bar
          class="display-none d-none d-lg-block"
          [full]="true"
          title="{{ 'PROJECT.MY_BRAND.TITLE' | translate }}"
        ></nav-bar>
        <div class="right-row no-padding margin-top-20"></div>
        <div
          class="row no-margin margin-top-25"
          *ngIf="isLoading == false && project.brand == undefined"
        >
          <div class="col-12 no-margin">
            <div class="text-bold text-medium container-empty">
              {{ 'PROJECT.MY_BRAND.NOTHING_TO_SHOW' | translate }}
            </div>
            <div class="margin-top-20-center">
              <span
                class="btn text-lato text-bold card-title no-margin btn-edit"
                (click)="editBrand()"
                >{{ 'PROJECT.MY_BRAND.FILL_THE_INFORMATION' | translate }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="row no-margin margin-top-25"
          *ngIf="isLoading == false && project.brand != undefined"
        >
          <div class="col-12 no-margin text-right content-btn">
            <span
              class="btn text-lato text-bold card-title btn-download btn-download-margin no-margin"
              (click)="downloadBrandPresentation()"
              >{{ 'DOWNLOAD BRAND PRESENTATION' | translate }}</span
            >
            <span
              class="btn text-lato text-bold card-title no-margin btn-download"
              (click)="editBrand()"
              >{{ 'PROJECT.MY_BRAND.EDIT' | translate }}</span
            >
          </div>
          <div
            class="col-12 light-box content-section-margin-top"
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div class="cursor top-container">
                <div class="margin-v-auto">
                  <img
                    class="brand-picture"
                    [src]="project.brand.brand_logo_path"
                    *ngIf="project.brand.brand_logo_path != undefined"
                  />
                  <img
                    style="
                      margin-top: -100px;
                      width: 120px;
                      height: 120px;
                      object-fit: cover;
                      border-style: solid;
                      border-width: 2px;
                      border-color: #ffffff;
                    "
                    src="./assets/images/no_brand_pic_pink_grey.png"
                    *ngIf="project.brand.brand_logo_path == undefined"
                  />
                </div>
                <div
                  style="
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 0px;
                    padding-left: 16px;
                    padding-right: 16px;
                    max-width: calc(100% - 68px);
                    width: calc(100% - 68px);
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-bold text-large"
                    style="
                      white-space: normal;
                      overflow: hidden;
                      color: #ff006c;
                    "
                  >
                    {{ project.brand.name }}
                  </div>
                  <div
                    class=""
                    style="
                      white-space: normal;
                      overflow: hidden;
                      color: #ffffff;
                      margin-top: 10px;
                      text-align: justify;
                      font-size: medium;
                      line-height: 1.5rem;
                    "
                    *ngIf="project.brand.baseline != undefined"
                  >
                    {{ project.brand.baseline }}
                  </div>
                  <div
                    class=""
                    style="
                      white-space: normal;
                      overflow: hidden;
                      color: #ffffff;
                      margin-top: 10px;
                      text-align: justify;
                      font-size: medium;
                      line-height: 1.5rem;
                    "
                    *ngIf="project.brand.baseline == undefined"
                  >
                    {{ 'PROJECT.MY_BRAND.BASELINE_NOT_AVAILABLE' | translate }}
                  </div>
                </div>
              </div>
              <!--div class="col-12 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-medium card-title no-margin"
                                    style="color: #FFFFFF">{{projects[project_index].title}}</span>
                            </!--div-->
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false && project.brand.industry !== ''"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'Industry' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="project.brand.industry != undefined"
                    >
                      {{ getIndustry(project.brand.industry) }}
                    </div>
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="project.brand.industry == undefined"
                    >
                      N/A
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false && project.brand.description !== ''"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                    *ngIf="project.brand.description != undefined"
                  >
                    {{ 'PROJECT.MY_BRAND.DESCRIPTION' | translate }}
                  </div>
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                    *ngIf="project.brand.description == undefined"
                  >
                    {{
                      'PROJECT.MY_BRAND.DESCRIPTION_NOT_AVAILABLE' | translate
                    }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                    >
                      {{ project.brand.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false && project.brand.values_description != ''"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'Core values' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="project.brand.values_description != undefined"
                    >
                      {{ project.brand.values_description }}
                    </div>
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="project.brand.values_description == undefined"
                    >
                      N/A
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="
              (isLoading == false &&
                project.brand.products[0].description != '') ||
              project.brand.products[0].target != '' ||
              project.brand.products[0].location != undefined
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'Products, services, solutions' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="
                        project.brand.products != undefined &&
                        project.brand.products.length > 0
                      "
                    >
                      <div style="margin-bottom: 35px">
                        <div class="product-subsection-title">Description</div>
                        <div>{{ project.brand.products[0].description }}</div>
                      </div>
                      <div style="margin-bottom: 35px">
                        <div class="product-subsection-title">Target</div>
                        <div>{{ project.brand.products[0].target }}</div>
                      </div>
                      <div style="">
                        <div
                          class="product-subsection-title"
                          style="margin-bottom: 5px"
                        >
                          Market location
                        </div>
                        <div style="height: 1.8rem !important">
                          <div
                            class="col keyword"
                            *ngFor="
                              let location of project.brand.products[0]
                                .locations
                            "
                            style="display: inline"
                          >
                            {{ location.country | translate }},
                            {{ location.area | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class=""
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        color: #ffffff;
                        margin-top: 10px;
                        text-align: justify;
                        font-size: medium;
                        line-height: 1.5rem;
                      "
                      *ngIf="
                        project.brand.products == undefined ||
                        project.brand.products.length == 0
                      "
                    >
                      N/A
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'PROJECT.MY_BRAND.ON_THE_WEB' | translate }}
                  </div>

                  <!--div style="opacity: 0.4" *ngIf="socialMediaAreVisible == false"
                                        (click)="showSocialMedia(true)">
                                        <img class="cursor" style="width: 24px;" src="./assets/images/icon-expand-white.png">
                                    </div>
                                    <div style="opacity: 0.4" *ngIf="socialMediaAreVisible == true"
                                        (click)="showSocialMedia(false)">
                                        <img class="cursor" style="width: 24px;" src="./assets/images/icon-collapse-white.png">
                                    </div-->
                </div>

                <div
                  class="row"
                  *ngIf="
                    project.brand.digital_accounts == undefined ||
                    project.brand.digital_accounts.length == 0
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      font-size: 14px;
                      padding-bottom: 0px;
                      margin-top: 10px;
                    "
                  >
                    {{ 'PROJECT.MY_BRAND.NO_LINK' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                  *ngIf="
                    project.brand.digital_accounts != undefined &&
                    project.brand.digital_accounts.length > 0
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      font-size: 18px;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.NETWORK' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.ACCOUNT' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let link of project.brand.digital_accounts">
                          <td
                            scope="row"
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-web.png"
                              *ngIf="link.type === 'web'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-twitter.png"
                              *ngIf="link.type === 'twitter'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-facebook.png"
                              *ngIf="link.type === 'facebook'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-instagram.png"
                              *ngIf="link.type === 'instagram'"
                            />
                            <img
                              style="
                                width: 15px;
                                margin-left: 2.5px;
                                margin-right: 14px;
                              "
                              src="./assets/images/socials-youtube.png"
                              *ngIf="link.type === 'youtube'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-linkedin.png"
                              *ngIf="link.type === 'linkedin'"
                            />
                          </td>
                          <td
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <a target="_blank">{{ link.link }}</a>
                          </td>
                          <td
                            class="text-lato text-small"
                            style="
                              vertical-align: middle;
                              text-align: right;
                              color: #fd006a;
                            "
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="isLoading == false"
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'Brand Advocates' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="
                    project.brand.advocates_accounts == undefined ||
                    project.brand.advocates_accounts.length == 0
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      font-size: 14px;
                      padding-bottom: 0px;
                      margin-top: 10px;
                    "
                  >
                    {{ 'PROJECT.MY_BRAND.NO_LINK' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                  *ngIf="
                    project.brand.advocates_accounts != undefined &&
                    project.brand.advocates_accounts.length > 0
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      font-size: 18px;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.NETWORK' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.ACCOUNT' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let link of project.brand.advocates_accounts"
                        >
                          <td
                            scope="row"
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-web.png"
                              *ngIf="link.type === 'web'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-twitter.png"
                              *ngIf="link.type === 'twitter'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-facebook.png"
                              *ngIf="link.type === 'facebook'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-instagram.png"
                              *ngIf="link.type === 'instagram'"
                            />
                            <img
                              style="
                                width: 15px;
                                margin-left: 2.5px;
                                margin-right: 14px;
                              "
                              src="./assets/images/socials-youtube.png"
                              *ngIf="link.type === 'youtube'"
                            />
                            <img
                              style="width: 20px; margin-right: 14px"
                              src="./assets/images/socials-linkedin.png"
                              *ngIf="link.type === 'linkedin'"
                            />
                          </td>
                          <td
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <a target="_blank">{{ link.link }}</a>
                          </td>
                          <td
                            class="text-lato text-small"
                            style="
                              vertical-align: middle;
                              text-align: right;
                              color: #fd006a;
                            "
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 light-box margin-top-25"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 8px;
              padding-right: 8px;
            "
            *ngIf="
              isLoading == false &&
              project.brand.marketing_resources[0] != undefined
            "
          >
            <div class="row margin-v-15 margin-h-9">
              <div
                class="col-12 no-margin text-left"
                style="
                  margin-top: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <!-- Test Color title-->
                  <div
                    class="text-lato text-bold text-medium card-title no-margin"
                    style="color: #ff006c; width: calc(100% - 26px)"
                  >
                    {{ 'PROJECT.MY_BRAND.MARKETING_MATERIALS' | translate }}
                  </div>
                </div>

                <div
                  class="row"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                  "
                >
                  <div
                    class="col-12 text-lato"
                    style="
                      color: #ffffff;
                      font-size: 18px;
                      padding: 20px;
                      padding-left: 0px;
                      padding-right: 0px;
                    "
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.FILE_TITLE' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'Visibility' | translate }}
                          </th>
                          <th
                            scope="col"
                            class="text-lato text-small"
                            style="border-width: 0px"
                          >
                            {{ 'PROJECT.MY_BRAND.FILE' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let resource of project.brand?.marketing_resources
                          "
                        >
                          <td
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            {{ resource.name }}
                          </td>
                          <td
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <span *ngIf="resource.isPublic == true"
                              >Public</span
                            >
                            <span *ngIf="resource.isPublic == false"
                              >Private</span
                            >
                          </td>
                          <td
                            scope="row"
                            class="text-lato text-small"
                            style="vertical-align: middle"
                          >
                            <a
                              download="filename"
                              target="_blank"
                              [href]="resource.link"
                              ><img
                                style="width: 40px; margin-right: 14px"
                                src="./assets/images/icon_download.png"
                            /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal projectId="{{ id }}"></modal>
