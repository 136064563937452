import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'input-field.component.html',
  selector: 'input-field',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  @Input('type') type: string
  @Input('placeholder') placeholder: string
  @Input('name') name: string
  @Input('relatedControlName') relatedControlName: string
  @Input('formGroupName') formGroupName: string
  @Input('parentGroup') parentGroup: FormGroup
  @Input('identifier') identifier: string
  @Input('change') change: any
  @Input('step') step: any = 'any'
  @Input('id') id: string
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  doNothing() {}
}
