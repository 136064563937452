<div class="content-width">
  <advertiser-nav-bar class="d-block bg-publisher-nav-bar"></advertiser-nav-bar>
  <modal></modal>
  <div class="content-page no-padding-bottom-board">
    <div
      id="collaborations-messages-wrapper"
      class="row collaborations-conversation center"
    >
      <div class="col-4 col-lg-3 no-padding">
        <collaborations-conversations-menu></collaborations-conversations-menu>
      </div>
      <div class="col-8 col-lg-9 no-padding">
        <collaborations-conversation></collaborations-conversation>
      </div>
    </div>
  </div>
</div>
