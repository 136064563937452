/**
 *
 *
 * @export
 * @class Network
 */
export class Network {
  /**
   * id is an instance will contain the id
   * @type {string}
   * @memberof Network
   */
  id: string

  /**
   * id is an instance will contain the id
   * @type {string}
   * @memberof Network
   */
  name: string

  /**
   * img is an instance will contain the image
   * @type {string}
   * @memberof Network
   */
  img: string

  /**
   * img_selected is an instance will contain the image selected
   * @type {string}
   * @memberof Network
   */
  img_selected: string

  /**
   * Creates an instance of Network.
   * @param {*} [values=undefined]
   * @memberof Network
   */
  constructor(values = undefined) {
    if (values == undefined) {
      this.id = ''
      this.img = ''
      this.img_selected = ''
      this.name = ''
    } else {
      this.id = values['id']
      this.img = values['img']
      this.img_selected = values['img_selected']
      this.name = values['name']
    }
  }

  static indexOf(network, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id == network.id) {
        return i
      }
    }
    return -1
  }
}
