import { Injectable } from '@angular/core'
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { AuthService } from '@cryptr/cryptr-angular'
import { AuthenticationService } from '../_services'

import { TokenService } from '../_services/token.service'

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private authenticationService: AuthenticationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requiredRole = route.data.requiredRole
    if (requiredRole == undefined || requiredRole === 'all') return true

    // if authenticated by cry
    let roleInToken = undefined

    if (this.authenticationService.guessAuthProvider() === 'fns') {
      roleInToken = this.tokenService.getRoleInToken()
    } else {
      roleInToken = 'publisher'
    }

    //return true
    if (roleInToken == undefined) return false

    return requiredRole === roleInToken
  }
}
