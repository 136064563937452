<div class="container-fluid full-height bg-default">
    <div class="row">
        <div class="col-12 d-flex d-lg-none" style="padding-right:0px">
            <mobile-nav-bar [full]="true" title="{{ 'SCALERS' | translate }}" style="width:100%"></mobile-nav-bar>
        </div>
    </div>
    <div class="row v-margin h-margin">
        <div class="col-12 col-lg-3 d-none d-lg-flex" id="left-menu">
            <left-menu></left-menu>
        </div>
        <div class="col-12 col-lg-9 d-block" id="content">
            <div>
                <nav-bar class="display-none d-none d-lg-block" [full]="true" title="{{ 'SCALERS' | translate }}"></nav-bar>

                <div class="right-row no-padding" style="margin-top: 20px">

                </div>
                <div class="row no-margin margin-top-25">
                    <div class="col-12 light-box"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        *ngIf="isLoading == false">
                        <campaign-summary></campaign-summary>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px">
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF">
                                    {{ 'Contacted scalers' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00">
                                    {{metrics.total_scalers}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        >
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF">
                                    {{ 'Answer rate' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00">
                                    {{metrics.total_answers / metrics.total_scalers | percent:'.2'}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 left mobile-left"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        >
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF">
                                    {{ 'Selected scalers' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00">
                                    {{confirmedScalers.length}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6 light-box margin-top-25 right mobile-right"
                        style="padding-top: 5px; padding-bottom: 5px; padding-left: 8px; padding-right: 8px"
                        >
                        <div class="row margin-v-15 margin-h-9">
                            <div class="col-6 no-margin" style="display: flex; align-items: center">
                                <span class="text-lato text-bold text-small card-title no-margin"
                                    style="color: #FFFFFF">
                                    {{ 'Available budget' | translate }}</span>
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <span class="text-lato text-bold card-title text-xxl no-margin"
                                    style="color: #ffad00">
                                    {{getAvailableBudget()}} {{getCampaignCurrency()}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="isLoading == false">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; background-color: #30313e; padding-top: 5px; padding-bottom: 5px; padding-left: 18px; padding-right: 18px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;">{{ 'CONFIRMED SCALERS' | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="confirmedScalers == undefined || confirmedScalers.length == 0">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; ">No confimed scaler for this campaign</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25 card-container">
                        <div class="scaler" *ngFor="let scaler of confirmedScalers">
                            <div class="wrapper">
                                <!---div class="top-icons">
                                    <i class="fas fa-long-arrow-alt-left"></i>
                                    <i class="fas fa-ellipsis-v"></i>
                                    <i class="far fa-heart"></i>
                                </div--->
                                
                                <div class="profile">
                                    <img [src]="getScaler(scaler).pic_url" class="thumbnail">
                                    <!---div class="check"><i class="fas fa-check"></i></div--->
                                    <h3 class="name">{{getScaler(scaler).display_name}}</h3>
                                    <!--p class="title">Javascript Developer</p-->
                                    <!--p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque aliquam aliquid porro!</p-->
                                    <!--button type="button" class="btn">Hire Me</button-->
                                    <p class="description"> 
                                        <a *ngFor='let network of getNetworks(scaler)' [href]="network.url">
                                            <img class="social-icon" [src]="getNetworkPicture(network.channel)"></a>
                                    </p>
                                    <button type="button" class="btn" (click)="toScalerProfile(scaler)">SEE PROFILE</button>
                                </div>

                                <div class="profile">
                                    <div class="row">
                                        <div class="col-6">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">MATCHING</h3>
                                            <h3 style="color:white; text-align: center; font-size: 1.8rem">{{scaler.analysis.matching_score}}</h3>
                                        </div>
                                        <div class="col-6">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">PRICE</h3>
                                            <h3 style="color:white; text-align: center; font-size: 1.8rem">{{scaler.proposal.budget.value}} {{scaler.proposal.budget.currency}} </h3>
                                        </div>
                                        <div class="col-12">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">DEAL DETAILS</h3>
                                            <ul style="padding-inline-start: 0px;
                                            list-style-type: none;">
                                                <li style="color:white; text-align: left; font-size: 0.8rem" *ngFor="let publication of scaler.proposal.publications">
                                                    {{publication.quantity}} {{publication.type}}(s) on {{publication.channel}} 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="actions" *ngIf="isLoading == false && campaign.status !== 'finished'">
                                    <button type="button" class="btn btn-rounded" (click)="cancelScaler(scaler)">CANCEL</button>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="isLoading == false && campaign.status !== 'finished'">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; background-color: #30313e; padding-top: 5px; padding-bottom: 5px; padding-left: 18px; padding-right: 18px; font-size: 25px; height: fit-content; width: fit-content; text-transform: uppercase;">{{ 'POTENTIAL SCALERS' | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25" style=" padding-left: 0px; padding-right: 0px"
                        *ngIf="potentialScalers == undefined ||potentialScalers.length == 0 && campaign.status !== 'finished'">
                        <div class="row no-margin">
                            <div class="col-12 no-margin text-left"
                                style="margin-top: 20px!important; margin-bottom: 20px!important; padding-left: 0px; padding-right: 0px">
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <!--div class="text-lato text-bold text-medium card-title no-margin"
                                        style="color: #ffffff; width:calc(100% - 26px)">{{ 'Web' | translate }}
                                    </div-->
                                    <div class="text-bold" style="color: #FFFFFF; ">You don't have pending offer for this campaign</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 margin-top-25 card-container" *ngIf="campaign.status !== 'finished'">
                        <div class="scaler" *ngFor="let scaler of potentialScalers">
                            <div class="wrapper">
                                <!---div class="top-icons">
                                    <i class="fas fa-long-arrow-alt-left"></i>
                                    <i class="fas fa-ellipsis-v"></i>
                                    <i class="far fa-heart"></i>
                                </div--->
                                
                                <div class="profile">
                                    <img [src]="getScaler(scaler).pic_url" class="thumbnail">
                                    <!---div class="check"><i class="fas fa-check"></i></div--->
                                    <h3 class="name">{{getScaler(scaler).display_name}}</h3>
                                    <!--p class="title">Javascript Developer</p-->
                                    <!--p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque aliquam aliquid porro!</p-->
                                    <!--button type="button" class="btn">Hire Me</button-->
                                    <p class="description"> 
                                        <a *ngFor='let network of getNetworks(scaler)' [href]="network.url">
                                            <img class="social-icon" [src]="getNetworkPicture(network.channel)"></a>
                                    </p>
                                    <button type="button" class="btn" (click)="toScalerProfile(scaler)">SEE PROFILE</button>
                                </div>

                                <div class="profile">
                                    <div class="row">
                                        <div class="col-6">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">MATCHING</h3>
                                            <h3 style="color:white; text-align: center; font-size: 1.8rem">{{scaler.analysis.matching_score}}</h3>
                                        </div>
                                        <div class="col-6">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">PRICE</h3>
                                            <h3 style="color:white; text-align: center; font-size: 1.8rem">{{scaler.proposal.budget.value}} {{scaler.proposal.budget.currency}} </h3>
                                        </div>
                                        <div class="col-12">
                                            <h3 style="color:#ff006c; text-align: center; font-size: 1rem;">DEAL DETAILS</h3>
                                            <ul style="padding-inline-start: 0px;
                                            list-style-type: none;">
                                                <li style="color:white; text-align: left; font-size: 0.8rem" *ngFor="let publication of scaler.proposal.publications">
                                                    {{publication.quantity}} {{publication.type}}(s) on {{publication.channel}} 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="actions">
                                    <button type="button" class="btn" (click)="confirmScaler(scaler)">ACCEPT</button>
                                    <button type="button" class="btn white btn-rounded" (click)="declineScaler(scaler)">DECLINE</button>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
<modal campaignId="{{id}}" proposalId="{{proposalId}}" action="{{action}}"></modal>