import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

import { ModalService } from '../../../../../../_services/modal.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  selector: 'campaign-create-step-3',
  templateUrl: 'campaign-create-step-3.component.html',
  styleUrls: ['./campaign-create-step-3.component.scss'],
})
export class CampaignCreateStep3Component implements OnInit {
  newCampaignForm: FormGroup

  do: any[]
  dont: any[]

  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {
    this.do = this.campaignCreationBufferService.campaignBeingCreated().do
    this.dont = this.campaignCreationBufferService.campaignBeingCreated().dont
  }

  async ngOnInit() {
    this.newCampaignForm = this.formBuilder.group({
      do: [''],
      dont: [''],
    })
  }

  onSubmitProject() {
    this.addDo()
    this.addDont()

    this.campaignCreationBufferService.moveForward()
  }

  showThing(thing) {
    this.modalService.toggleModal(thing)
  }

  addDo() {
    var candidate = this.newCampaignForm.controls.do.value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.do.indexOf(candidate) === -1) {
          this.do.push(candidate)
          this.newCampaignForm.controls['do'].reset()
        }
      }
    }

    this.campaignCreationBufferService.campaignBeingCreated().do = this.do
  }

  removeDo(do_i) {
    var i = this.do.indexOf(do_i)
    this.do.splice(i, 1)

    this.campaignCreationBufferService.campaignBeingCreated().do = this.do
  }

  addDont() {
    var candidate = this.newCampaignForm.controls.dont.value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.dont.indexOf(candidate) === -1) {
          this.dont.push(candidate)
          this.newCampaignForm.controls['dont'].reset()
        }
      }
    }

    this.campaignCreationBufferService.campaignBeingCreated().dont = this.dont
  }

  removeDont(dont_i) {
    var i = this.dont.indexOf(dont_i)
    this.dont.splice(i, 1)

    this.campaignCreationBufferService.campaignBeingCreated().dont = this.dont
  }
}
