import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute, Params } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../../_services/api.service'

@Component({
  templateUrl: 'password_change.component.html',
  styleUrls: ['./password_change.component.scss'],
})
export class PasswordChangeComponent
  implements OnInit, AfterViewInit, OnDestroy {
  submitted = false
  recoveryForm: FormGroup
  returnUrl: string
  error = ''
  loading = false
  hasSent = false

  matched = true
  tooSmall = false
  token: any
  hasError = false

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService
  ) {
    this.hasSent = false
    this.route.queryParams.subscribe((params: Params) => {
      this.token = params['token']
    })
  }

  ngOnInit() {
    this.hasSent = false
    this.matched = true
    this.tooSmall = false
    this.recoveryForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f() {
    return this.recoveryForm.controls
  }

  async onSubmit() {
    try {
      this.submitted = true
      this.matched = true
      this.tooSmall = false
      this.hasError = false

      var candidate = undefined
      // stop here if form is invalid
      if (this.recoveryForm.invalid) {
        return
      }

      if (this.f.newPassword.value != this.f.confirmPassword.value) {
        candidate = this.f.newPassword.value
        if (candidate != undefined) {
          candidate = candidate.trim()
          if (candidate.length >= 10) {
            this.tooSmall = false
          } else {
            this.tooSmall = true
            return
          }
        }
        this.matched = false
        return
      }

      this.loading = true

      var res = await this.apiService.changePassword(
        this.f.newPassword.value,
        this.token
      )
      if (res.status == 200) {
        this.loading = false
        this.hasSent = true
      }
      if (res.status == 400) {
        this.loading = false
        this.hasSent = false
        this.hasError = false
      }
    } catch (e) {
      this.loading = false
      this.hasSent = false
      this.hasError = true
    }
  }
}
