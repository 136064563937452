<!--  Board with 5 columns  -->

<!--div class="back-board board-columns box-shadow">
  <div
    class="small-board-column padding-columns"
    *ngFor="let column of small_board.columns"
  >
    <div class="title-column-container">
      <div class="container-title padding-top-15">
        {{ column.name }}
      </div>
    </div>
  </div>
</div>

<div
  class="display-block col-direction section-container no-margin height-board"
>
  <div class="scroll back-board board-columns box-shadow padding-bottom-15">
    <div
      class="small-board-column padding-columns"
      *ngFor="let column of small_board.columns"
    >
      <div class="box one-edge-shadow" *ngFor="let item of column.tasks">
        <div class="box-title text-pink text-small">
          {{ item.name }} - {{ item.text }}
        </div>
        <div class="opportunity-date text-xxsmall">
          Start : {{ item.date_start }} - end : {{ item.date_end }}
        </div>
        <div class="box-description text-darkgrey text-xsmall">
          Potential earning : {{ item.earning }}
        </div>
        <div class="message-img">
          <span
            class="badge badge-position"
            [ngClass]="{
              'badge-selected': item.status === 'SELECTED',
              'badge-draft': item.status === 'DRAFT',
              'badge-proposed': item.status === 'PROPOSED',
              'badge-negociated': item.status === 'NEGOCIATED',
              'badge-accepted': item.status === 'ACCEPTED',
              'badge-finished': item.status === 'FINISHED',
              'badge-refused': item.status === 'REFUSED'
            }"
          >
            {{ item.status }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div-->
<div class="back-board board-columns box-shadow">
  <div
    class="small-board-column padding-columns"
    *ngFor="let column of board.columns"
  >
    <div class="title-column-container">
      <div class="container-title padding-top-15 text-bold">
        {{ getColumn(column).name }}
      </div>
    </div>
  </div>
</div>

<div class="display-block col-direction height-board">
  <div class="back-board board-columns box-shadow padding-bottom-15">
    <div
      class="small-board-column padding-columns"
      *ngFor="let column of board.columns"
    >
      <div
        class="blank-box"
        *ngIf="
          getColumn(column).deals == undefined ||
          getColumn(column).deals.length == 0
        "
      >
        <div
          style="
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            color: #444;
          "
        >
          No corresponding deal
        </div>
      </div>
      <deal-kanban-item
        *ngFor="let item of getColumn(column).deals"
        [deal]="item"
      >
      </deal-kanban-item>
    </div>
  </div>
</div>
<!--div class="display-block col-direction section-container clickable">
  <div class="max-w-80pt one-edge-shadow section-container-inner"></div>
</div-->
