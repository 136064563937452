<div class="content-width">
  <div class="top-menu">
    <div class="text-lato text-medium container-top-menu">
      <img src="./assets/images/icon-bills.png" />{{
        'PROJECT.MY_BILLING_EDIT.TITLE' | translate
      }}
      <span class="text-right">
        <span class="top-text text-lato text-grey text-small"
          ><a (click)="stepBack()"
            ><span
              class="btn btn-outline-primary left-margin text-button top-menu-close"
              >{{ 'COMMON.TEXT_CLOSE_WINDOW' | translate }}</span
            ></a
          ></span
        >
      </span>
    </div>
  </div>
  <alerts [alerts]="alertService.getAlerts()"></alerts>
  <div class="content-page">
    <div class="display-block col-direction section-container">
      <div class="col-12 col-md-3 margin-v-39 margin-section-right">
        <section-title
          title="PROJECT.MY_BILLING_EDIT.PART_1"
          description="PROJECT.MY_BILLING_EDIT.INSTRUCTIONS"
        ></section-title>
      </div>
      <div class="col-12 col-md-9 margin-section-left">
        <form class="margin-v-39" [formGroup]="myBillingForm">
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left">
              {{ 'PROJECT.MY_BILLING_EDIT.CORPORATE_NAME' | translate }}
              <p
                class="text-lato text-small text-pink"
                *ngIf="hasCorporateNameError == true"
              >
                {{
                  'PROJECT.MY_BILLING_EDIT.ERROR_CORPORATE_NAME_EMPTY'
                    | translate
                }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="{{
                  'PROJECT.MY_BILLING_EDIT.CORPORATE_NAME_PLACEHOLDER'
                    | translate
                }}"
                name="corporateName"
                relatedControlName="corporateName"
                formGroupName="corporateNameForm"
                [parentGroup]="myBillingForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left">
              {{ 'PROJECT.MY_BILLING_EDIT.ADDRESS' | translate }}
            </div>

            <div class="col-8 text-lato container-form">
              <div class="container">
                <!--div class="row">
                  <div
                    class="col-12 text-lato container-field container-field-padding"
                  >
                    <div class="separator-field" id="locationField">
                      <input
                        type="text"
                        class="input-text"
                        id="autocomplete"
                        onFocus="geolocate()"
                        placeholder="Veulliez indiquer une adresse"
                      />
                    </div>
                  </div>
                </div-->

                <div class="row" id="address">
                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="street_number"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.ADDRESS_LINE_1_TITLE'
                          | translate
                      }}"
                      name="address_1"
                      relatedControlName="address_1"
                      formGroupName="address_1Form"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>

                  <div class="col-9 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="route"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.ADDRESS_LINE_2_TITLE'
                          | translate
                      }}"
                      name="address_2"
                      relatedControlName="address_2"
                      formGroupName="address_2Form"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="postal_code"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.ZIP_CODE_TITLE' | translate
                      }}"
                      name="zipCode"
                      relatedControlName="zipCode"
                      formGroupName="zipCodeForm"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="locality"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.CITY_TITLE' | translate
                      }}"
                      name="city"
                      relatedControlName="city"
                      formGroupName="cityForm"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>
                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="administrative_area_level_1"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.STATE_TITLE' | translate
                      }}"
                      name="state"
                      relatedControlName="state"
                      formGroupName="stateForm"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>

                  <div class="col-3 text-lato container-field">
                    <input-field
                      class="input-field"
                      identifier="country"
                      type="text"
                      placeholder="{{
                        'PROJECT.MY_BILLING_EDIT.COUNTRY_TITLE' | translate
                      }}"
                      name="country"
                      relatedControlName="country"
                      formGroupName="countryForm"
                      [parentGroup]="myBillingForm"
                    >
                    </input-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left">
              {{
                'PROJECT.MY_BILLING_EDIT.PREFERRED_CURRENCY_TITLE' | translate
              }}
              <p
                class="text-lato text-small text-pink"
                *ngIf="hasCurrencyError == true"
              >
                {{
                  'PROJECT.MY_BILLING_EDIT.PREFERRED_CURRENCY_INVALID'
                    | translate
                }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <select
                name="preferredCurrency"
                formControlName="preferredCurrency"
                id="preferred-currency-select"
                class="custom-select content-width"
              >
                <option value="">-- Please choose an option --</option>
                <option
                  [value]="currency.code"
                  *ngFor="let currency of getCurrencies()"
                >
                  {{ currency.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left">
              {{ 'PROJECT.MY_BILLING_EDIT.VAT_NUMBER_TITLE' | translate }}
            </div>
            <div class="col-8 text-lato container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="{{
                  'PROJECT.MY_BILLING_EDIT.VAT_NUMBER_PLACEHOLDER' | translate
                }}"
                name="vatNumber"
                relatedControlName="vatNumber"
                formGroupName="vatNumberForm"
                [parentGroup]="myBillingForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-4 text-lato form-container-section-left">
              {{ 'PROJECT.MY_BILLING_EDIT.BILLING_EMAIL' | translate }}
              <p
                class="text-lato text-small text-pink"
                *ngIf="hasContactEmailError == true"
              >
                {{ 'PROJECT.MY_BILLING_EDIT.ERROR_EMAIL_INVALID' | translate }}
              </p>
            </div>
            <div class="col-8 text-lato container-field">
              <input-field
                class="input-field"
                type="email"
                placeholder="{{
                  'PROJECT.MY_BILLING_EDIT.BILLING_EMAIL_PLACEHOLDER'
                    | translate
                }}"
                name="contactEmail"
                relatedControlName="contactEmail"
                formGroupName="contactEmailForm"
                [parentGroup]="myBillingForm"
              >
              </input-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-section-btn">
    <div class="section-btn">
      <plain-button
        value="{{ 'MODAL_PROFILE.MODAL_OK_BUTTON' | translate }}"
        (click)="onSubmit()"
      >
      </plain-button>
    </div>
  </div>
</div>
<loader *ngIf="loading == true"></loader>
