<div id="alert-campaign-creation">
  <div
    class="col-12 col-lg-3 d-none d-lg-flex left-menu"
    id="alert-campaign-creation-overlay"
    (click)="closeModal()"
  ></div>

  <div class="col-12 col-lg-9 d-block" id="alert-campaign-creation-menu">
    <div>
      <div
        class="modal fade d-block opaque"
        tabindex="-1"
        role="dialog"
        aria-hidden="false"
        (click)="closeModal()"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          role="document"
          (click)="stopPropagation($event)"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-lato text-pink"
                id="exampleModalLongTitle"
              >
                {{ 'MODAL_ALERT.MODAL_TITLE' | translate }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="closeModal()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              *ngFor="
                let warning of campaignCreationBufferService.getWarnings()
              "
            >
              <div
                class="modal-body text-lato"
                *ngIf="warning['activated'] == true"
              >
                {{ warning['warning'].getText() | translate }}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary text-lato btn-ok"
                data-dismiss="modal"
                (click)="closeModal()"
              >
                {{ 'MODAL_ALERT.MODAL_OK_BUTTON' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="alert-campaign-creation-full-height-overlay"
  (click)="closeModal()"
></div>
