import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  templateUrl: 'input-date.component.html',
  selector: 'input-date',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnInit {
  @Input('placeholder') placeholder: string
  @Input('name') name: string
  @Input('relatedName') relatedName: string
  @Input('formGroupName') formGroupName: string
  @Input('parentGroup') parentGroup: FormGroup
  @Input('changeDate') changeDate: any
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
