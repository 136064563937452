import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

import { SignupBufferService } from './../../../../../_services/buffers/signup.buffer.service'
import { ExternalRequestService } from './../../../../../_services/external.requests.service'

@Component({
  selector: 'signup-step-0',
  templateUrl: 'signup-step-0.component.html',
  styleUrls: ['./signup-step-0.component.scss'],
})
export class SignupStep0Component implements OnInit, AfterViewInit, OnDestroy {
  signupForm: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private signupBufferService: SignupBufferService,
    private externalRequestService: ExternalRequestService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    })
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  async onSubmit() {
    this.externalRequestService.setIsLoading(true)

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      this.externalRequestService.setIsLoading(false)
      return
    }

    this.signupBufferService
      .userBeingCreated()
      .getUser().email = this.signupForm.controls.email.value

    await this.signupBufferService.checkUser()

    this.externalRequestService.setIsLoading(false)
    if (
      this.signupBufferService.getState()['step_0']['usedCheckedHasError'] ==
      false
    ) {
      if (
        this.signupBufferService.getState()['step_0'][
          'userCheckedHasAnAccount'
        ] == false
      ) {
        await this.signupBufferService.launchRequestInvitation()
      }
      this.signupBufferService.stepForward()
    } else {
    }
  }
}
