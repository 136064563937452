<div
  class="content-page shadowed"
  #domElement
  *ngIf="getConversation() == undefined"
>
  <div class="row conversation-title">
    <div class="col-12 conversation-summary">
      <div class="user-header">To:</div>
      <div class="user-infos" style="width: 100%">
        <div class="user-name">
          <div class="col-12">
            <form class="to-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  type="text"
                  matInput
                  [formControl]="toControl"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFn"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                    (click)="createConversation(option)"
                  >
                    {{ option._publisher.user.infos.fullName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="content-page shadowed"
  #domElement
  *ngIf="getConversation() != undefined"
>
  <div class="row conversation-title">
    <div class="col-12 conversation-summary">
      <div class="user-avatar">
        <img
          [src]="getConversationUser(getConversation()).pictureUrl"
          *ngIf="shouldDisplayAvatarLetters(getConversation()) == false"
        />
        <div
          class="avatar-letters img-circle avatar-dark"
          *ngIf="shouldDisplayAvatarLetters(getConversation()) == true"
        >
          {{ getConversationUserFirstLetters(getConversation()) }}
        </div>
      </div>
      <div class="user-infos">
        <div class="user-name">
          {{ getConversationUser(getConversation()).displayName }}
        </div>
        <div class="user-details">Last seen: today</div>
      </div>
    </div>
  </div>
  <div
    class="row conversation-history"
    [scrollTop]="scrolltop"
    #messagesElement
  >
    <div
      class="col-12 conversation-message-wrapper"
      *ngFor="let event of getConversationEvents()"
    >
      <div class="user-avatar">
        <img
          [src]="getEventUser(event).pictureUrl"
          *ngIf="
            shouldDisplayAvatarLettersForUser(getEventUser(event)) == false
          "
        />
        <div
          class="avatar-letters img-circle avatar-dark"
          *ngIf="shouldDisplayAvatarLettersForUser(getEventUser(event)) == true"
        >
          {{ getConversationUserFirstLettersForUser(getEventUser(event)) }}
        </div>
      </div>
      <div class="user-infos">
        <div class="user-name">
          {{ getEventUser(event).displayName }}
          <span class="message-infos">- {{ formatDate(event.createdAt) }}</span>
        </div>
        <div class="conversation-message">{{ event.text }}</div>
      </div>
    </div>
  </div>
  <div class="row conversation-input">
    <div class="col-12">
      <form [formGroup]="textForm" style="margin-top: 0px !important">
        <textarea
          type="text"
          placeholder="Write a message..."
          class="input-text text-lato text-medium text-darkgrey"
          autocomplete="off"
          rows="3"
          formControlName="message"
        ></textarea>
      </form>
    </div>
    <div class="col-12 right">
      <span class="btn text-lato text-bold" (click)="sendTextEvent()"
        >SEND</span
      >
    </div>
  </div>
</div>
