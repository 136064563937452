import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'

import { Router } from '@angular/router'

import { ModalService } from '../../../../../../_services/modal.service'
import { CampaignReferentialService } from '../../../../../../_services/referential/campaign.referential.service'
import { CampaignCreationBufferService } from '../../../../../../_services/buffers/campaign.creation.buffer.service'

@Component({
  selector: 'campaign-create-step-7',
  templateUrl: 'campaign-create-step-7.component.html',
  styleUrls: ['./campaign-create-step-7.component.scss'],
})
export class CampaignCreateStep7Component implements OnInit {
  constructor(
    public campaignReferentialService: CampaignReferentialService,
    public campaignCreationBufferService: CampaignCreationBufferService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  async ngOnInit() {}

  goToDashboard() {
    this.router.navigate([
      'projects/' +
        this.campaignCreationBufferService.getProject()._id +
        '/dashboard',
    ])
    //this.campaignCreationBufferService.init()
  }
}
