import { Component, OnInit, Input } from '@angular/core'
import { Output, EventEmitter } from '@angular/core'
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'
import { APIService, PublishersProfileAPIService } from 'src/app/_services'
import { DateHelper } from '../../../../../_helpers/date.helpers'

@Component({
  templateUrl:
    'publisher-profile-professional-profile-positions.component.html',
  selector: 'publisher-profile-professional-profile-positions',
  styleUrls: [
    './publisher-profile-professional-profile-positions.component.scss',
  ],
})
export class PublisherProfileProfessionalProfilePositionsComponent
  implements OnInit {
  @Input('publisher') publisher: any
  @Input('isEditing') isEditing: boolean

  @Output() showModalEvent = new EventEmitter<boolean>()

  profileDataForm: FormGroup
  isValid: boolean
  loading = false
  showModal: boolean
  showAskConfirmation: boolean

  informations: any[]
  information: any
  informationId: any
  selectedData: any

  isInProgress: any

  successfullyAdded: boolean
  successfullyRemoved: boolean

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apiService: PublishersProfileAPIService
  ) {
    this.showModal = false
    this.showModalEvent.emit(false)

    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      summaryForm: this.formBuilder.group({
        summary: '',
      }),
      startDate: [undefined],
      endDate: [undefined],
      isCurrentForm: this.formBuilder.group({
        isCurrent: '',
      }),
      companyForm: this.formBuilder.group({
        company: '',
      }),
    })
  }

  async ngOnInit() {
    this.alertService.resetAlerts()
    this.initCertificationForm()
    this.initValues()
    this.showAskConfirmation = false
    this.isInProgress = false
  }

  initCertificationForm() {
    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      summaryForm: this.formBuilder.group({
        summary: '',
      }),
      startDate: [undefined],
      endDate: [undefined],
      isCurrentForm: this.formBuilder.group({
        isCurrent: '',
      }),
      companyForm: this.formBuilder.group({
        company: '',
      }),
    })
  }

  initValues() {
    this.getInformations()
  }

  ngOnDestroy() {}

  hasPositions() {
    try {
      return (
        this?.publisher?.publisher?.professionalProfile?.positions != undefined
      )
    } catch (e) {
      return false
    }
  }

  getInformations() {
    if (this.hasPositions() == true)
      return this.publisher.publisher.professionalProfile.positions
    else return undefined
  }

  getNumberOfInformations() {
    let objects = this.getInformations()
    if (objects) return objects.length
    return 0
  }

  getStartDate(information) {
    let startDate = DateHelper.convertDoubleToReadableDate(
      information.timePeriod.startDate
    )
    return startDate
  }

  getEndDate(information) {
    if (information.timePeriod.endDate != undefined) {
      var endDate = DateHelper.convertDoubleToReadableDate(
        information.timePeriod.endDate
      )
      return 'End Date ' + endDate
    } else {
      endDate = 'In Progress'
      return endDate
    }
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    return
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.profileDataForm.controls.titleForm['controls'].title.value
      )

      let summaryField = this.readTextValue(
        this.profileDataForm.controls.summaryForm['controls'].summary.value
      )

      let startDateField
      if (this.profileDataForm.controls.startDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('Start date field can not be empty')
      } else {
        startDateField = new Date(
          this.profileDataForm.controls.startDate.value
        ).getTime()
      }

      let endDateField
      if (this.profileDataForm.controls.endDate.value == null) {
        this.isValid = false
        this.alertService.pushErrorAlert('End date field can not be empty')
      } else {
        endDateField = new Date(
          this.profileDataForm.controls.endDate.value
        ).getTime()
      }

      if (this.isInProgress == true) {
        endDateField == undefined
        this.profileDataForm.controls.endDate.valid == true
      } else {
        endDateField = new Date(
          this.profileDataForm.controls.endDate.value
        ).getTime()
      }

      let isCurrentField = this.isInProgress

      let companyField = this.readTextValue(
        this.profileDataForm.controls.companyForm['controls'].company.value
      )

      if (
        titleField != undefined &&
        this.profileDataForm.controls.titleForm['controls'].title.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        summaryField != undefined &&
        this.profileDataForm.controls.summaryForm['controls'].summary.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Summary field can not be empty')
      }

      if (
        isCurrentField != undefined &&
        this.profileDataForm.controls.isCurrentForm['controls'].isCurrent
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        companyField != undefined &&
        this.profileDataForm.controls.companyForm['controls'].company.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Company field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          title: titleField,
          summary: summaryField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          isCurrent: isCurrentField,
          company: companyField,
        }
        console.log(profile_infos)

        let res = await this.apiService.addPositionInformation(
          this.publisher.publisher._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
      this.closeModal()
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async deleteInformation(information) {
    try {
      this.alertService.resetAlerts()
      this.loading = true

      this.informationId = information._id

      let res = await this.apiService.deletePositionInformation(
        this.publisher.publisher._id,
        this.informationId
      )

      if (res.status == 200) {
        this.alertService.pushMessageAlert('Your profile has been updated')
        this.publisher.publisher = res.body.publisher
        this.successfullyRemoved = true
        this.loading = false
        this.closeModal()
      } else {
        this.successfullyRemoved = false
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  async updateChanges() {
    try {
      this.isValid = true
      this.loading = true
      this.alertService.resetAlerts()

      let titleField = this.readTextValue(
        this.profileDataForm.controls.titleForm['controls'].title.value
      )

      let summaryField = this.readTextValue(
        this.profileDataForm.controls.summaryForm['controls'].summary.value
      )

      let startDateField = new Date(
        this.profileDataForm.controls.startDate.value
      ).getTime()

      let endDateField

      if (this.isInProgress == true) {
        endDateField == undefined
        this.profileDataForm.controls.endDate.valid == true
      } else {
        endDateField = new Date(
          this.profileDataForm.controls.endDate.value
        ).getTime()
      }

      let isCurrentField = this.isInProgress

      let companyField = this.readTextValue(
        this.profileDataForm.controls.companyForm['controls'].company.value
      )

      if (
        titleField != undefined &&
        this.profileDataForm.controls.titleForm['controls'].title.valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        summaryField != undefined &&
        this.profileDataForm.controls.summaryForm['controls'].summary.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        isCurrentField != undefined &&
        this.profileDataForm.controls.isCurrentForm['controls'].isCurrent
          .valid == false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (
        companyField != undefined &&
        this.profileDataForm.controls.companyForm['controls'].company.valid ==
          false
      ) {
        this.isValid = false
        this.alertService.pushErrorAlert('Title field can not be empty')
      }

      if (this.isValid == true) {
        this.isValid = true

        let profile_infos = {
          title: titleField,
          summary: summaryField,
          timePeriod: {
            startDate: startDateField,
            endDate: endDateField,
          },
          isCurrent: isCurrentField,
          company: companyField,
        }

        let res = await this.apiService.updatePositionInformation(
          this.publisher.publisher._id,
          this.selectedData._id,
          profile_infos
        )

        if (res.status == 200) {
          this.alertService.pushMessageAlert('Your profile has been updated')
          this.makeEditable()
          this.successfullyAdded = true
          this.loading = false
          this.publisher.publisher = res.body.publisher
        } else {
          this.successfullyAdded = false
          this.alertService.pushErrorAlert(
            'Oops! Something wrong happened. Your profile has not been updated. Please retry'
          )
        }
      } else {
        this.alertService.pushErrorAlert(
          'Oops! Something wrong happened. Your profile has not been updated. Please retry'
        )
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert(
        'Oops! Something wrong happened. Your profile has not been updated. Please retry'
      )
    }
  }

  addInformation() {
    this.selectedData = undefined
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  updateInformation(information) {
    this.selectedData = information
    this.updateModal()
    this.showModal = true
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  saveOrUpdateInformation() {
    if (this.selectedData != undefined) this.updateChanges()
    else this.saveChanges()
    this.closeModal()
  }

  validatePosition() {}

  closeModal() {
    this.showModal = false
    this.showModalEvent.emit(false)
    document.body.style.overflow = ''
    this.successfullyAdded = undefined
    this.successfullyRemoved = undefined
    try {
      this.clearInputField()
    } catch (e) {
      console.log(e)
    }
  }

  closeConfirmationModal() {
    try {
      this.showAskConfirmation = false
    } catch (e) {
      console.log(e)
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  changeStartDate(event) {
    this.profileDataForm.controls.startDate.setValue(event.target.value)
  }

  changeEndDate(event) {
    this.profileDataForm.controls.endDate.setValue(event.target.value)
  }

  updateModal() {
    if (this.selectedData == undefined) {
      // ADD
      this.prepareModalForAddition()
    } else {
      // UPDATE
      this.prepareModalForUpdate()
    }
  }

  confirmation() {
    this.showAskConfirmation = true
    document.body.style.overflow = 'hidden'
  }

  prepareModalForAddition() {
    this.initCertificationForm()
  }

  prepareModalForUpdate() {
    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: this.selectedData.title,
      }),
      summaryForm: this.formBuilder.group({
        summary: this.selectedData.summary,
      }),
      startDate: [new Date(this.selectedData.timePeriod.startDate)],
      endDate: [new Date(this.selectedData.timePeriod.endDate)],
      isCurrentForm: this.formBuilder.group({
        isCurrent: this.selectedData.isCurrent,
      }),
      companyForm: this.formBuilder.group({
        company: this.selectedData.company,
      }),
    })
  }

  changeToInProgress(event) {
    this.isInProgress = event.target.checked
  }

  clearInputField() {
    this.profileDataForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: '',
      }),
      summaryForm: this.formBuilder.group({
        summary: '',
      }),
      startDate: [undefined],
      endDate: [undefined],
      isCurrentForm: this.formBuilder.group({
        isCurrent: '',
      }),
      companyForm: this.formBuilder.group({
        company: '',
      }),
    })
  }
}
