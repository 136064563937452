import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { Router, ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import {
  AdvertisersAudiencesAPIService,
  SEOKeywordsCSVFileParsingService,
  StorageManagementService,
} from 'src/app/_services'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'
import { AlertService } from '../../../../_services/alert.service'

@Component({
  templateUrl: 'advertiser-audience-edit.component.html',
  styleUrls: ['./advertiser-audience-edit.component.scss'],
})
export class AdvertiserAudienceEditComponent implements OnInit, OnDestroy {
  plan: any

  showAudienceForm: boolean
  newAudienceForm: FormGroup
  keywords: any[]
  socialKeywords: any[]
  showError: boolean
  errorText: string

  // Language
  suggestedLanguages: any[]
  languageNameControl = new FormControl()
  filteredLanguagesOptions: Observable<any[]>

  // Location
  suggestedLocations: any[]
  locationNameControl = new FormControl()
  filteredLocationsOptions: Observable<any[]>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private formBuilder: FormBuilder,
    public storageManagementService: StorageManagementService,
    private campaignReferentialService: CampaignReferentialService,
    private audienceApiService: AdvertisersAudiencesAPIService,
    private seoKeywordsCSVFileParsingService: SEOKeywordsCSVFileParsingService
  ) {
    this.showAudienceForm = false
    this.plan = route.snapshot.data.plan
    this.keywords = []
    this.socialKeywords = []

    let correspondingLanguage = this.getSuggestedLanguages()
      .filter((languageJson) => languageJson.key === this.plan.language)
      .map((languageJson) => languageJson.text)

    let correspondingLocation = this.getSuggestedLocations()
      .filter((locationJson) => locationJson.key === this.plan.location)
      .map((locationJson) => locationJson.text)

    if (this.plan?.keywords) {
      for (let i = 0; i < this.plan.keywords.length; i++) {
        this.keywords.push(this.plan.keywords[i].text)
      }
    }

    if (this.plan?.social_keywords) {
      for (let i = 0; i < this.plan.social_keywords.length; i++) {
        this.socialKeywords.push(this.plan.social_keywords[i])
      }
    }

    this.newAudienceForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: { value: this.plan.name, disabled: false },
      }),
      keywordForm: this.formBuilder.group({
        keyword: { value: '', disabled: false },
      }),
      socialKeywordForm: this.formBuilder.group({
        socialKeyword: { value: '', disabled: false },
      }),
      language: {
        value:
          correspondingLanguage != null && correspondingLanguage.length > 0
            ? correspondingLanguage[0]
            : '',
        disabled: false,
      },
      location: {
        value:
          correspondingLocation != null && correspondingLocation.length > 0
            ? correspondingLocation[0]
            : '',
        disabled: false,
      },
    })

    this.filteredLanguagesOptions = this.newAudienceForm
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filterLanguage(value))
      )

    this.filteredLocationsOptions = this.newAudienceForm
      .get('location')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filterLocations(value))
      )

    this.showError = false
    this.errorText = ''
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  addAudience() {
    this.showAudienceForm = true
  }

  // KEYWORDS
  addKeyword() {
    //if (this.newPipeForm.valid) {
    var candidate = this.newAudienceForm.get('keywordForm').get('keyword').value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.keywords.indexOf(candidate) === -1) {
          this.keywords.push(candidate)
          this.newAudienceForm.controls['keywordForm'].reset()
        }
      }
      //}
    }
  }

  removeKeyword(keyword) {
    var i = this.keywords.indexOf(keyword)
    this.keywords.splice(i, 1)
  }

  // SOCIAL KEYWORDS
  addSocialKeyword() {
    //if (this.newPipeForm.valid) {
    var candidate = this.newAudienceForm
      .get('socialKeywordForm')
      .get('socialKeyword').value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.socialKeywords.indexOf(candidate) === -1) {
          this.socialKeywords.push({ text: candidate, channel: 'twitter' })
          this.newAudienceForm.controls['socialKeywordForm'].reset()
        }
      }
      //}
    }
  }

  removeSocialKeyword(keyword) {
    var i = this.socialKeywords.indexOf(keyword)
    this.socialKeywords.splice(i, 1)
  }

  // LANGUAGES
  private _filterLanguage(value: string): string[] {
    let filteredLanguages = this.getSuggestedLanguages()

    let result = []
    for (let i = 0; i < filteredLanguages.length; i++) {
      result.push(filteredLanguages[i].text)
    }
    const filterValue = value.toLowerCase()
    return result.filter(
      (language) => language.toLowerCase().indexOf(filterValue) === 0
    )
  }

  getSuggestedLanguages() {
    let suggestedLanguages = this.campaignReferentialService.getLanguages()
    return suggestedLanguages
  }

  // LOCATIONS
  private _filterLocations(value: string): string[] {
    let filteredLocations = this.getSuggestedLocations()

    let result = []
    for (let i = 0; i < filteredLocations.length; i++) {
      result.push(filteredLocations[i].text)
    }

    const filterValue = value.toLowerCase()

    let toSend = result.filter(
      (location) => location.toLowerCase().indexOf(filterValue) === 0
    )

    return toSend
  }

  getSuggestedLocations() {
    let suggestedLocations = this.campaignReferentialService.getLocations()
    return suggestedLocations
  }

  closeNewAudienceForm() {
    this.showAudienceForm = false
  }

  async updateAudience() {
    this.showError = false
    this.errorText = ''

    let title = this.newAudienceForm.get('titleForm').get('title').value

    if (title == undefined || title.trim() === '') {
      this.errorText = 'You need to add a name to your audience'
      this.showError = true
      return
    }

    this.addKeyword()
    let keywords = this.keywords

    if (keywords == undefined || keywords.length == 0) {
      this.errorText = 'You need to add some keywords to your audience'
      this.showError = true
      return
    }

    this.addSocialKeyword()
    let socialKeywords = this.socialKeywords

    if (socialKeywords == undefined || socialKeywords.length == 0) {
      this.errorText = 'You need to add some social keywords to your audience'
      this.showError = true
      return
    }

    let language = this.newAudienceForm.get('language').value

    if (
      language == undefined ||
      language.trim() === '' ||
      this.getSuggestedLanguages()
        .map((language) => language.text)
        .indexOf(language) == -1
    ) {
      this.errorText = 'Please select a language for your audience'
      this.showError = true
      return
    }

    let languageCode = this.getSuggestedLanguages()
      .filter((languageJson) => languageJson.text === language)
      .map((languageJson) => languageJson.key)

    let location = this.newAudienceForm.get('location').value

    if (
      location == undefined ||
      location.trim() === '' ||
      this.getSuggestedLocations()
        .map((location) => location.text)
        .indexOf(location) == -1
    ) {
      this.errorText = 'Please select a location for your audience'
      this.showError = true
      return
    }

    let locationCode = this.getSuggestedLocations()
      .filter((locationJson) => locationJson.text === location)
      .map((locationJson) => locationJson.key)

    let audienceJson = {
      name: title,
      keywords: keywords,
      social_keywords: socialKeywords,
      language:
        languageCode && languageCode.length > 0 ? languageCode[0] : undefined,
      location:
        locationCode && locationCode.length > 0 ? locationCode[0] : undefined,
    }

    let result = await this.audienceApiService.updateAudience(
      this.storageManagementService.getCurrentBrandspaceId(),
      this.plan._id,
      audienceJson
    )

    if (result.status == 200) this.visitAudience(result.body.plan)
  }

  getAudienceNameText(audience) {
    return audience.name
  }

  getAudienceKeywordsText(audience) {
    try {
      let result = ''
      if (audience.keywords) {
        for (let i = 0; i < audience.keywords.length; i++) {
          result += audience.keywords[i].text

          if (i < audience.keywords.length - 1) result += ', '
        }
      }

      return result
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  getAudienceLanguageText(audience) {
    try {
      let language = audience.language

      return this.campaignReferentialService
        .getLanguages()
        .filter((languageJson) => languageJson.key === language)
        .map((languageJson) => languageJson.text)[0]
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  visitAudience(plan) {
    let url =
      '/workspaces/' +
      this.storageManagementService.getCurrentBrandspaceId() +
      '/keywords/plans/' +
      plan._id
    this.router.navigate([url])
  }

  getAverageCpc(plan) {
    let totalVolume = 0
    let cpc = 0
    for (let key in Object.keys(plan.data)) {
      if (plan.data[Object.keys(plan.data)[key]].gms) {
        totalVolume += plan.data[Object.keys(plan.data)[key]].gms
        if (plan.data[Object.keys(plan.data)[key]].cpc)
          cpc =
            plan.data[Object.keys(plan.data)[key]].gms *
            plan.data[Object.keys(plan.data)[key]].cpc
      }
    }

    if (totalVolume > 0) {
      cpc /= totalVolume
    }

    return cpc
  }

  getAverageCompetition(plan) {
    let totalVolume = 0
    let competition = 0

    for (let key in Object.keys(plan.data)) {
      if (plan.data[Object.keys(plan.data)[key]].gms) {
        totalVolume += plan.data[Object.keys(plan.data)[key]].gms
        if (plan.data[Object.keys(plan.data)[key]].competition)
          competition =
            plan.data[Object.keys(plan.data)[key]].gms *
            plan.data[Object.keys(plan.data)[key]].competition
      }
    }

    if (totalVolume > 0) {
      competition /= totalVolume
    }

    return competition
  }

  changeSEOKeywordFileListener($event): void {
    this.uploadKeywordsFile($event.target)
  }

  async uploadKeywordsFile(input: any) {
    let file: File = input.files[0]
    let fileReader: FileReader = new FileReader()
    let fileType = input.parentElement.id
    let _this = this
    fileReader.onloadend = async function (e) {
      //fileReader.result is a String of the uploaded file

      let data: string
      if (typeof fileReader.result === 'string') {
        data = fileReader.result
      } else {
        data = String.fromCharCode.apply(
          null,
          new Uint16Array(fileReader.result)
        )
      }

      let keywords = await _this.seoKeywordsCSVFileParsingService.parseData(
        data
      )

      for (let i = 0; i < keywords.length; i++) {
        if (_this.keywords.indexOf(keywords[i]) === -1) {
          _this.keywords.push(keywords[i])
        }
      }

      //fileString = fileReader.result would not work,
      //because it is not in the scope of the callback
    }

    fileReader.readAsText(file)
  }
}
