<div
  class="display-block container-list"
  style="margin-top: 0"
  *ngIf="data?.globalInfluencers?.length > 0"
>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">
          {{
            'KEYWORD_PLAN.SOCIAL_DATA_USER_TABLE_COL_NAME_PROFILE' | translate
          }}
        </th>
        <th scope="col">
          {{
            'KEYWORD_PLAN.SOCIAL_DATA_USER_TABLE_COL_NAME_DESCRIPTION'
              | translate
          }}
        </th>
        <th scope="col">
          {{ 'KEYWORD_PLAN.SOCIAL_DATA_USER_TABLE_COL_NAME_SOV' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let result of data?.globalInfluencers"
        class="audience-row"
        (click)="discoverInfluencer(result)"
      >
        <td>
          <a [href]="getInfluencerLink(result)" target="_blank">
            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
              "
            >
              <div>
                <!--img
                  [src]="result?._source?.profile?.pic_url"
                  style="border-radius: 50pt; margin-right: 15px"
                /--><img
                  [src]="result?.user?.profile_image_url_https"
                  style="border-radius: 50pt; margin-right: 15px"
                />
              </div>
              <!--div>
                {{ result?._source?.profile?.display_name }}<br /><span
                  class="text-lato text-xsmall text-pink"
                  >@{{ result?._source?.profile?.screen_name }}</span
                >
              </div-->
              <div>
                {{ result?.user?.name }}<br /><span
                  class="text-lato text-xsmall text-pink"
                  >@{{ result?.user?.screen_name }}</span
                >
              </div>
            </div>
          </a>
        </td>
        <td>{{ result?.user?.description }}</td>
        <td>{{ result?.reach / data.totalInfluencerReach | percent }}</td>
      </tr>
    </tbody>
  </table>
</div>
