<div class="row margin-v-15 margin-h-9">
  <div
    class="col-md-4 col-6 no-margin d-flex align-items-center justify-content-start"
  >
    <span
      class="text-lato text-bold text-medium card-title no-margin text-white"
      >{{ getCampaignName() }}</span
    >
  </div>
  <div
    class="col-md-4 col-6 no-margin text-center align-items-center justify-content-center"
  >
    <span
      class="text-lato text-bold text-medium card-title no-margin margin-bottom text-white"
      >{{ getCampaignStatusText() | translate }}</span
    >
  </div>
  <div
    class="col-md-4 col-12 no-margin text-right align-items-center justify-content-end"
  >
    <!--span class="btn text-lato text-bold card-title no-margin"
            style="background-color: #ffad00; color: white; margin-left: 5px!important; margin-right: 5px!important; font-size: 13px" (click)="copyCampaign()">COPIER</span-->
    <span
      class="btn text-lato text-bold card-title no-margin nav-btn"
      (click)="editCampaign()"
      *ngIf="
        getCampaignStatus() === 'draft' || getCampaignStatus() === 'launched'
      "
      >{{ 'CAMPAIGN_STATUS.CAMPAIGN_EDIT_BUTTON' | translate }}</span
    >
    <span
      class="btn text-lato text-bold card-title no-margin nav-btn"
      (click)="cutCampaign()"
      *ngIf="
        getCampaignStatus() === 'draft' || getCampaignStatus() === 'launched'
      "
      >{{ 'CAMPAIGN_STATUS.CAMPAIGN_STOP_BUTTON' | translate }}</span
    >
  </div>
</div>
