import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, from, zip } from 'rxjs'

import { Campaign } from '../../_models/campaign/campaign.model'

import { Warning, WARNING_LEVEL } from '../../_models/validation/warning'
import { STATE } from '../../_models/validation/state'
import { CampaignCreationNavigation } from '../../_models/navigation/campaign.creation.navigation.model'
import { Network } from 'src/app/_models/referential/network.model'

@Injectable()
export class CampaignCreationBufferService {
  private campaign = new BehaviorSubject(new Campaign())
  private moodboardFiles: File[]
  private moodboardSourcePaths: any[]
  private step: number = 0

  private warnings: any[]

  private campaignCreationNavigation: CampaignCreationNavigation

  private project: any

  private isDraftActivated: boolean

  private action: string

  constructor() {
    this.init()
  }

  public init() {
    this.isDraftActivated = true
    this.action = 'creation'
    this.initValues()
    this.initState()
    this.moodboardFiles = []
    this.moodboardSourcePaths = []
    this.campaignCreationNavigation = new CampaignCreationNavigation()
  }

  public initValues() {
    this.campaign.next(new Campaign())
    this.step = 0
  }

  public initState() {
    let warningBuilder = new (Warning.getBuilder())()
    this.warnings = []

    // CHECK CAMPAIGN NAME
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignHasANameWhoseLengthIsEnough')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_NAME_IS_TOO_SHORT')
        .withLevel(WARNING_LEVEL.ERROR)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkCampaignNameLength,
      step: 0,
    })

    // CHECK CHANNELS
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignHasAtLeastASelectedNetwork')
        .withText('MODAL_ALERT.MODAL_TEXT_NO_NETWORK_SELECTED')
        .withLevel(WARNING_LEVEL.ERROR)
        .build(),
      activated: false,
      activationFunction:
        CampaignCreationBufferService.checkCampaignHasAAtLeastSelectedNetwork,
      step: 0,
    })

    // CHECK CAMPAIGN TYPE
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignHasACampaignType')
        .withText('MODAL_ALERT.MODAL_TEXT_NO_CAMPAIGN_TYPE_SELECTED')
        .withLevel(WARNING_LEVEL.ERROR)
        .build(),
      activated: false,
      activationFunction:
        CampaignCreationBufferService.checkCampaignHasACampaignType,
      step: 0,
    })

    // CHECK LOCATION
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignHasLocations')
        .withText('MODAL_ALERT.MODAL_TEXT_NO_LOCATION_SELECTED')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkLocations,
      step: 1,
    })

    // CHECK KEYWORDS
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignHasKeywords')
        .withText('MODAL_ALERT.MODAL_TEXT_NO_AUDIENCE_SELECTED')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkKeywords,
      step: 1,
    })

    // CHECK START DATE
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckStartDate')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_START_DATE_NOT_SET')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkStartDate,
      step: 2,
    })

    // CHECK END DATE
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckEndDate')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_END_DATE_NOT_SET')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkEndDate,
      step: 2,
    })

    // CHECK CAMPAIGN DURATION
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckDuration')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_DURATION_TOO_SHORT')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkDuration,
      step: 2,
    })

    // CHECK CAMPAIGN BUDGET
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckBudget')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_BUDGET_NOT_SET')
        .withLevel(WARNING_LEVEL.ERROR)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkBudget,
      step: 2,
    })

    // CHECK CAMPAIGN DO / DON'T
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckDoDont')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_DO_DONT_NOT_SET')
        .withLevel(WARNING_LEVEL.INFO)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkDoDont,
      step: 3,
    })

    // CHECK CAMPAIGN BRIEF
    this.warnings.push({
      warning: warningBuilder
        .init()
        .withSlug('campaignCheckBrief')
        .withText('MODAL_ALERT.MODAL_TEXT_CAMPAIGN_BRIEF_NOT_SET')
        .withLevel(WARNING_LEVEL.ERROR)
        .build(),
      activated: false,
      activationFunction: CampaignCreationBufferService.checkBrief,
      step: 4,
    })
  }

  public subscribeToCampaignBeingCreated(): Observable<Campaign> {
    return this.campaign.asObservable()
  }

  public campaignBeingCreated(): Campaign {
    return this.campaign.value
  }

  public setCampaignBeingCreated(campaign: Campaign) {
    this.campaign.next(campaign)
  }

  public loadCampaign(campaign: any) {
    this.init()
    if (campaign._id != undefined) this.action = 'update'
    let currentCampaign = Campaign.adaptCampaign(campaign)

    this.setCampaignBeingCreated(currentCampaign)

    // Moodboard
    let imagesSrc = []
    let selectedFiles = []
    for (var i = 0; i < campaign.moodboard.length; i++) {
      imagesSrc.push({ imgPath: campaign.moodboard[i].path })
      selectedFiles.push(campaign.moodboard[i].path)
    }
    this.moodboardSourcePaths = imagesSrc
    this.moodboardFiles = selectedFiles

    if (campaign.status === 'draft') this.shouldActivateDraft(true)
    else this.shouldActivateDraft(false)
  }

  public isNetworkSelected(network: Network) {
    return this.campaign.value.isNetworkSelected(network)
  }

  public toggleNetwork(network: Network) {
    if (this.isNetworkSelected(network) == true) {
      this.campaign.value.unselectNetwork(network)
    } else {
      this.campaign.value.selectNetwork(network)
    }
  }

  public setCampaignType(campaignType) {
    this.campaign.value.type = campaignType
  }

  public getCampaignType() {
    return this.campaign.value.type
  }

  public getStep(): number {
    return this.step
  }

  public setStep(step: number) {
    this.step = step
  }

  public stepForward(): void {
    this.step++
  }

  public stepBack(): void {
    this.step--
  }

  public moveForward(): void {
    let fromStep = this.getStep()
    let state = this.checkCampaignState(fromStep, fromStep + 1)

    if (state == STATE.IS_VALID) this.stepForward()
    else this.showAlert()
  }

  public goToNextStep(): void {
    this.step = this.campaignCreationNavigation.getToStep()
  }

  static checkCampaignNameLength(campaign: Campaign) {
    if (campaign.title == undefined || campaign.title.trim().length <= 2)
      return true
    else return false
  }

  static checkCampaignHasAAtLeastSelectedNetwork(campaign: Campaign) {
    if (campaign.networks == undefined || campaign.networks.length == 0)
      return true
    else return false
  }

  static checkCampaignHasACampaignType(campaign: Campaign) {
    if (campaign.type == undefined || campaign.type.id === '') return true
    else return false
  }

  static checkLocations(campaign: Campaign) {
    if (campaign.locations == undefined || campaign.locations.length == 0)
      return true
    else return false
  }

  static checkKeywords(campaign: Campaign) {
    if (campaign.keywords == undefined || campaign.keywords.length == 0)
      return true
    else return false
  }

  static checkDuration(campaign: Campaign) {
    let duration = campaign.getDuration()
    if (!isNaN(duration) && duration < 7) return true
    return false
  }

  static checkStartDate(campaign: Campaign) {
    if (campaign.date_start == undefined) return true
    return false
  }

  static checkEndDate(campaign: Campaign) {
    if (campaign.date_end == undefined) return true
    return false
  }

  static checkBudget(campaign: Campaign) {
    if (campaign.compensation == undefined) return true
    else if (campaign.compensation === 'free_products') {
      if (
        campaign.numberOfFreeProducts == undefined ||
        campaign.numberOfFreeProducts == 0 ||
        campaign.productDescription == undefined ||
        campaign.productDescription === ''
      )
        return true
    } else if (campaign.compensation === 'cash') {
      if (campaign.budget == undefined || campaign.budget == 0) return true
    }

    return false
  }

  static checkDoDont(campaign: Campaign) {
    if (campaign.do.length == 0 && campaign.dont.length == 0) return true
    return false
  }

  static checkBrief(campaign: Campaign) {
    let candidate = campaign.brief
    if (candidate == undefined) return true
    else if (candidate.trim().length == 0) return true
    return false
  }

  getCampainMinimalLaunchDate() {
    return new Date()
  }

  getCampainMinimalFinishDate() {
    let thisDay = new Date()
    if (this.campaignBeingCreated().date_start != undefined)
      thisDay = this.campaignBeingCreated().date_start

    let theDayAfter = new Date(thisDay)
    theDayAfter.setDate(theDayAfter.getDate() + 1)

    return theDayAfter
  }

  public getMoodboardFiles() {
    return this.moodboardFiles
  }

  public getMoodboardSourcePaths() {
    return this.moodboardSourcePaths
  }

  public shouldShowAlert() {
    return this.campaignCreationNavigation.shouldShowAlert()
  }

  public showAlert() {
    this.campaignCreationNavigation.showAlert(true)
  }

  public hideAlert() {
    this.campaignCreationNavigation.showAlert(false)
  }

  public checkValidity(step) {
    for (let i = 0; i < this.warnings.length; i++) {
      if (this.warnings[i]['step'] == step) {
        let shouldBeActivated = this.warnings[i]['activationFunction'](
          this.campaign.value
        )
        this.warnings[i]['activated'] = shouldBeActivated
      }
    }
  }

  public getWarnings() {
    return this.warnings
  }

  public checkCampaignState(from_step = undefined, to_step = undefined): STATE {
    this.campaignCreationNavigation.showAlert(false)
    if (from_step != undefined)
      this.campaignCreationNavigation.setFromStep(from_step)
    if (to_step != undefined) this.campaignCreationNavigation.setToStep(to_step)

    let initial_step = this.campaignCreationNavigation.getFromStep()
    let final_step = this.campaignCreationNavigation.getToStep()

    if (initial_step >= final_step) return STATE.IS_VALID

    for (let i = 0; i < this.warnings.length; i++) {
      this.warnings[i]['activated'] = false
    }

    for (let i = initial_step; i < final_step; i++) {
      this.checkValidity(i)
    }

    let hasErrors = false
    let hasWarnings = false
    for (let i = 0; i < this.warnings.length; i++) {
      if (this.warnings[i]['activated'] == true) {
        if (this.warnings[i]['warning']['level'] == WARNING_LEVEL.INFO)
          hasWarnings = true
        if (this.warnings[i]['warning']['level'] == WARNING_LEVEL.ERROR)
          hasErrors = true
      }
    }

    if (hasErrors == true) return STATE.HAS_ERRORS
    if (hasWarnings == true) return STATE.HAS_WARNINGS

    return STATE.IS_VALID
  }

  public getProject(): any {
    return this.project
  }

  public setProject(project: any) {
    this.project = project
  }

  public getMoodboardWithUrlImages() {
    var result = []

    for (var i = 0; i < this.moodboardSourcePaths.length; i++) {
      if (this.moodboardSourcePaths[i].imgPath != undefined) {
        result.push(this.moodboardSourcePaths[i].imgPath)
      }
    }
    return result
  }

  public shouldActivateDraft(status) {
    this.isDraftActivated = status
  }

  public hasDraftToBeActivated() {
    return this.isDraftActivated
  }

  public getAction() {
    return this.action
  }
}
