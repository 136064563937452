import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { AdvertisersOffersAPIService } from 'src/app/_services'

@Injectable()
export class AdvertisersOfferResolver implements Resolve<any> {
  constructor(
    private advertisersOffersAPIService: AdvertisersOffersAPIService
  ) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const workspaceId = <string>route.paramMap.get('workspaceId')
      const campaignId = <string>route.paramMap.get('campaignId')
      let request = await this.advertisersOffersAPIService.getOffer(
        workspaceId,
        campaignId
      )

      return request.body.campaign
    } catch (e) {
      console.log(e)
      return undefined
    }
  }
}
