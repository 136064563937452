import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../_services'
import { AlertService } from '../../../_services/alert.service'
import { DataService } from '../../../_services/data.service'
import { TranslateService } from '@ngx-translate/core'
import { PreferencesManager } from 'src/app/_services/preferences.manager.service'

var languages = [
  {
    id: 0,
    name: 'LANGUAGE.ENGLISH',
    code: 'en',
  },
  {
    id: 1,
    name: 'LANGUAGE.FRENCH',
    code: 'fr',
  },
]

var currencies = [
  {
    id: 0,
    name: 'EUR - Euro',
    symbol: '€',
  },
  {
    id: 1,
    name: 'USD - US Dollar',
    symbol: '$',
  },
]

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  me: any
  profileForm: FormGroup
  hasChangedProfilePicture: any

  passwordForm: FormGroup

  preferencesForm: FormGroup

  selectedProfile: File
  profileSrc: any

  missingFieldsInProfile: boolean
  incorrectEmail: boolean

  missingFieldsInPassword: boolean
  passwordsMismatched: boolean
  passwordTooShort: boolean
  wrongPassword: boolean

  sub: any

  returnUrl: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private preferencesManager: PreferencesManager,
    private dataService: DataService,
    public alertService: AlertService
  ) {
    this.me = undefined
    this.hasChangedProfilePicture = false

    this.profileSrc = undefined

    /*this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      profileUrl: [''],
      company: [''],
      phoneNumber: [''],
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
    })*/
    this.profileForm = this.formBuilder.group({
      firstNameForm: this.formBuilder.group({
        firstName: ['', Validators.required],
      }),
      lastNameForm: this.formBuilder.group({
        lastName: ['', Validators.required],
      }),
      profileUrlForm: this.formBuilder.group({
        profileUrl: [''],
      }),
      companyForm: this.formBuilder.group({
        company: ['', Validators.required],
      }),
      phoneNumberForm: this.formBuilder.group({
        phoneNumber: [''],
      }),
      emailForm: this.formBuilder.group({
        email: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
    })

    this.passwordForm = this.formBuilder.group({
      currentPasswordForm: this.formBuilder.group({
        currentPassword: ['', Validators.required],
      }),
      newPasswordForm: this.formBuilder.group({
        newPassword: ['', Validators.required],
      }),
      confirmPasswordForm: this.formBuilder.group({
        confirmPassword: ['', Validators.required],
      }),
    })

    var language = this.translate.currentLang
    this.preferencesForm = this.formBuilder.group({
      language: ['', Validators.required],
      currency: ['', Validators.required],
    })

    var langs = this.getLanguages()
    for (var i = 0; i < langs.length; i++) {
      if (langs[i].code === language) {
        this.preferencesForm.controls.language.setValue(langs[i].id)
      }
    }
  }

  async ngOnInit() {
    this.alertService.resetAlerts()

    this.missingFieldsInProfile = false
    this.incorrectEmail = false

    this.missingFieldsInPassword = false
    this.passwordsMismatched = false
    this.passwordTooShort = false
    this.wrongPassword = false

    this.me = this.dataService.getMe()
    this.profileForm.controls.lastNameForm['controls'].lastName.setValue(
      this.me.infos.lastname
    )
    this.profileForm.controls.firstNameForm['controls'].firstName.setValue(
      this.me.infos.firstname
    )
    this.profileForm.controls.emailForm['controls'].email.setValue(
      this.me.infos.email
    )
    this.profileForm.controls.companyForm['controls'].company.setValue(
      this.me.infos.company
    )
    this.profileForm.controls.phoneNumberForm['controls'].phoneNumber.setValue(
      this.me.infos.phoneNumber
    )

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe()
  }

  closeModal() {
    //this.modalService.toggleModal('profile');
    //console.log(this.location)
    //this.location.back();
    this.router.navigate([this.returnUrl])
  }

  async onSubmit() {
    this.alertService.resetAlerts()

    this.missingFieldsInProfile = false
    this.incorrectEmail = false

    this.missingFieldsInPassword = false
    this.passwordsMismatched = false
    this.passwordTooShort = false
    this.wrongPassword = false

    // CHECK WHAT WE HAVE TO SUBMIT
    var hasToSubmitProfile = false
    var hasToSubmitPassword = false

    var profileCandidates = {}
    var passwordCandidates = {}
    var languageCandidate = undefined

    var isMissingFields = false
    var isValidEmail = true
    var isValidPassword = true
    var passwordMismatches = false

    if (this.hasChangedProfilePicture == true) hasToSubmitProfile = true

    if (this.profileForm.controls.lastNameForm['controls'].lastName.value)
      profileCandidates['lastName'] = this.profileForm.controls.lastNameForm[
        'controls'
      ].lastName.value.trim()

    if (this.profileForm.controls.firstNameForm['controls'].firstName.value)
      profileCandidates['firstName'] = this.profileForm.controls.firstNameForm[
        'controls'
      ].firstName.value.trim()

    if (this.profileForm.controls.emailForm['controls'].email.value)
      profileCandidates['email'] = this.profileForm.controls.emailForm[
        'controls'
      ].email.value
        .trim()
        .toLowerCase()

    if (this.profileForm.controls.companyForm['controls'].company.value)
      profileCandidates['company'] = this.profileForm.controls.companyForm[
        'controls'
      ].company.value.trim()

    if (this.profileForm.controls.phoneNumberForm['controls'].phoneNumber.value)
      profileCandidates[
        'phoneNumber'
      ] = this.profileForm.controls.phoneNumberForm[
        'controls'
      ].phoneNumber.value.trim()

    var candidate_language = this.preferencesForm.controls.language.value
    languageCandidate = languages[candidate_language].code

    if (
      this.me.infos.lastName != profileCandidates['lastName'] ||
      this.me.infos.firstName != profileCandidates['firstName'] ||
      this.me.infos.company != profileCandidates['company'] ||
      this.me.infos.phoneNumber != profileCandidates['phoneNumber'] ||
      this.me.infos.email != profileCandidates['email'] ||
      this.me.infos.language != languageCandidate
    ) {
      hasToSubmitProfile = true
    }

    if (
      this.passwordForm.controls.currentPasswordForm['controls'].currentPassword
        .value
    ) {
      passwordCandidates[
        'currentPassword'
      ] = this.passwordForm.controls.currentPasswordForm[
        'controls'
      ].currentPassword.value.trim()
    }

    if (
      this.passwordForm.controls.newPasswordForm['controls'].newPassword.value
    ) {
      passwordCandidates[
        'newPassword'
      ] = this.passwordForm.controls.newPasswordForm[
        'controls'
      ].newPassword.value.trim()
    }

    if (
      this.passwordForm.controls.confirmPasswordForm['controls'].confirmPassword
        .value
    ) {
      passwordCandidates[
        'confirmPassword'
      ] = this.passwordForm.controls.confirmPasswordForm[
        'controls'
      ].confirmPassword.value.trim()
    }

    if (
      passwordCandidates['currentPassword'] != undefined ||
      passwordCandidates['newPassword'] != undefined ||
      passwordCandidates['confirmPassword'] != undefined
    ) {
      hasToSubmitPassword = true
    }

    // CHECK IF FORMS ARE VALID
    if (hasToSubmitProfile) {
      if (this.profileForm.invalid) {
        if (this.profileForm.controls.emailForm['controls'].email.invalid) {
          isValidEmail = false
          this.incorrectEmail = true
          return
        } else {
          if (
            this.profileForm.controls.firstNameForm['controls'].firstName
              .invalid ||
            this.profileForm.controls.lastNameForm['controls'].lastName.invalid
          ) {
            isMissingFields = false
            this.missingFieldsInProfile = true
            return
          }
        }
      }
    }

    if (hasToSubmitPassword) {
      if (this.passwordForm.invalid) {
        isMissingFields = false
        this.missingFieldsInPassword = true
        return
      } else {
        if (
          passwordCandidates['newPassword'] !=
          passwordCandidates['confirmPassword']
        ) {
          passwordMismatches = true
          this.passwordsMismatched = true
          return
        }
        if (passwordCandidates['newPassword'].length < 10) {
          this.passwordTooShort = true
          return
        }
      }
    }

    var infos = {}
    if (hasToSubmitProfile) {
      infos['email'] = profileCandidates['email']
      infos['firstname'] = profileCandidates['firstName']
      infos['lastname'] = profileCandidates['lastName']
      infos['company'] = profileCandidates['company']
      infos['phoneNumber'] = profileCandidates['phoneNumber']
      infos['language'] = languageCandidate
    }

    if (hasToSubmitPassword) {
      infos['old_password'] = passwordCandidates['currentPassword']
      infos['new_password'] = passwordCandidates['newPassword']
    }

    if (hasToSubmitProfile || hasToSubmitPassword) {
      var res = await this.apiService.updateAccount(infos, this.selectedProfile)
      localStorage.setItem('language', languageCandidate)
      if (res.status == 200) {
        this.alertService.pushMessageAlert('MODAL_PROFILE.ALERT_OK')
      } else {
        this.alertService.pushErrorAlert('MODAL_PROFILE.ALERT_NOK')
      }
    }
  }

  onFileChanged(event) {
    this.hasChangedProfilePicture = true
    this.selectedProfile = event.target.files[0]

    const reader = new FileReader()
    reader.onload = (e) => (this.profileSrc = reader.result)

    reader.readAsDataURL(this.selectedProfile)
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  getLanguages() {
    return languages
  }

  getCurrencies() {
    return currencies
  }

  updateLanguage() {
    var candidate_language = this.preferencesForm.controls.language.value

    if (candidate_language)
      this.translate.use(languages[candidate_language].code)
  }

  updateCurrency() {
    var candidate_currency = this.preferencesForm.controls.currency.value

    if (candidate_currency)
      this.preferencesManager.setPreferredCurrency(
        currencies[candidate_currency]
      )
  }
}
