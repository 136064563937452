<div class="content-width" (click)="stopPropagation($event)">
  <div class="top-menu">
    <div class="text-lato text-medium container-top-menu">
      <img src="./assets/images/icon-settings.png" />{{
        'MODAL_PROFILE.MODAL_TITLE' | translate
      }}
      <span
        ><img src="./assets/images/icon-close.png" (click)="closeModal()"
      /></span>
    </div>
  </div>
  <alerts [alerts]="alertService.getAlerts()"></alerts>
  <div class="content-page">
    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <section-title
          title="MODAL_PROFILE.MODAL_TITLE_1"
          description="MODAL_PROFILE.MODAL_TEXT_1"
        ></section-title>
      </div>

      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="profileForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_PROFILE_PICTURE' | translate }}
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="
                me?.infos?.profilePictureURL == undefined &&
                hasChangedProfilePicture == false
              "
            >
              <img
                src="./assets/images/img-placeholder.png"
                (click)="fileInput.click()"
              />
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="
                me?.infos?.profilePictureURL &&
                hasChangedProfilePicture == false
              "
            >
              <img
                [src]="me?.infos?.profilePictureURL"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
            <div
              class="col-6 text-lato form-container-section-right"
              *ngIf="hasChangedProfilePicture == true"
            >
              <img
                [src]="profileSrc"
                (click)="fileInput.click()"
                class="no-picture"
              />
            </div>
          </div>
          <div class="row display-none">
            <div class="col-12 display-none">
              <input
                class="display-none"
                type="file"
                (change)="onFileChanged($event)"
                accept="image/*"
                #fileInput
              />
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_LAST_NAME' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_LAST_NAME' | translate
                }}"
                name="lastName"
                relatedControlName="lastName"
                formGroupName="lastNameForm"
                [parentGroup]="profileForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_FIRST_NAME' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_FIRST_NAME' | translate
                }}"
                name="firstName"
                relatedControlName="firstName"
                formGroupName="firstNameForm"
                [parentGroup]="profileForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_COMPANY' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="text"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_COMPANY' | translate
                }}"
                name="company"
                relatedControlName="company"
                formGroupName="companyForm"
                [parentGroup]="profileForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_EMAIL' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="email"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_EMAIL' | translate
                }}"
                name="email"
                relatedControlName="email"
                formGroupName="emailForm"
                [parentGroup]="profileForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_PHONE' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="tel"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_PHONE' | translate
                }}"
                name="phoneNumber"
                relatedControlName="phoneNumber"
                formGroupName="phoneNumberForm"
                [parentGroup]="profileForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row margin-section">
            <div class="col-12" *ngIf="missingFieldsInProfile">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_1' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="incorrectEmail">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_INCORRECT_EMAIL_FORMAT' | translate }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <section-title
          title="MODAL_PROFILE.MODAL_TITLE_3"
          description="MODAL_PROFILE.MODAL_TEXT_3"
        ></section-title>
      </div>
      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="preferencesForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}
            </div>
            <div class="col-6 text-lato container-field-language">
              <div class="separator-field-no-padding">
                <select
                  id="language"
                  class="form-control custom-select text-lato font-style-laguage"
                  name="language"
                  formControlName="language"
                  (change)="updateLanguage()"
                >
                  <option value="" disabled selected>
                    {{ 'MODAL_PROFILE.MODAL_TITLE_LANGUAGE' | translate }}
                  </option>
                  <option
                    *ngFor="let language of getLanguages()"
                    [value]="language.id"
                  >
                    {{ language.name | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--div class="row"
                        style="background-color: #FFFFFF; margin-left: 0px; margin-top: 5px; margin-bottom: 5px">
                        <div class="col-6 text-lato" style="color: #949499; font-size: 14px; padding: 20px">
                            {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}
                        </div>
                        <div class="col-6 text-lato"
                            style="color: #30313e; font-size: 13px; padding: 20px; padding-top: 11px; padding-bottom: 11px">
                            <div
                                style="border-bottom-color: #ff006c; border-bottom-style: solid; border-bottom-width: 1px;">
                                <select id="currency"
                                    class="form-control custom-select text-lato" style="font-size: inherit; padding: 0px; border: 0px"
                                    name="currency" formControlName="currency"
                                    (change)="updateCurrency()">
                                    <option value="" disabled selected>{{ 'MODAL_PROFILE.MODAL_TITLE_CURRENCY' | translate }}</option>
                                    <option *ngFor="let currency of getCurrencies()" [value]="currency.id">{{currency.name | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div-->
        </form>
      </div>
    </div>

    <div class="display-block col-direction section-container">
      <div class="col-12 col-lg-5">
        <section-title
          title="MODAL_PROFILE.MODAL_TITLE_2"
          description="MODAL_PROFILE.MODAL_TEXT_2"
        ></section-title>
      </div>
      <div class="col-12 col-lg-7">
        <form class="margin-v-39" [formGroup]="passwordForm">
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_CURRENT_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="password"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_CURRENT_PASSWORD' | translate
                }}"
                name="currentPassword"
                relatedControlName="currentPassword"
                formGroupName="currentPasswordForm"
                [parentGroup]="passwordForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_NEW_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="password"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_NEW_PASSWORD' | translate
                }}"
                name="newPassword"
                relatedControlName="newPassword"
                formGroupName="newPasswordForm"
                [parentGroup]="passwordForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row form-container">
            <div class="col-6 text-lato form-container-section-left">
              {{ 'MODAL_PROFILE.MODAL_TITLE_CONFIRM_PASSWORD' | translate }}
            </div>
            <div class="col-6 text-lato container-field">
              <input-field
                class="input-field"
                type="password"
                placeholder="{{
                  'MODAL_PROFILE.MODAL_PLACEHOLDER_CONFIRM_PASSWORD' | translate
                }}"
                name="confirmPassword"
                relatedControlName="confirmPassword"
                formGroupName="confirmPasswordForm"
                [parentGroup]="passwordForm"
              >
              </input-field>
            </div>
          </div>
          <div class="row margin-section">
            <div class="col-12" *ngIf="missingFieldsInPassword">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_EMPTY_FIELDS_2' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="passwordsMismatched">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_PASSWORDS_NOT_MATCHING' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="wrongPassword">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_INCORRECT_PASSWORD' | translate }}
              </p>
            </div>
            <div class="col-12" *ngIf="passwordTooShort">
              <p class="text-lato text-medium text-missing-field">
                {{ 'MODAL_PROFILE.ERROR_PASSWORD_TOO_SHORT' | translate }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-section-btn">
    <div class="section-btn">
      <plain-button
        value="{{ 'MODAL_PROFILE.MODAL_OK_BUTTON' | translate }}"
        (click)="onSubmit(); passwordForm.reset()"
      >
      </plain-button>
    </div>
  </div>
</div>
