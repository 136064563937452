import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

var BACKEND_URL = environment.apiUrl

@Injectable()
export class PublishersOpportunitiesAPIService {
  constructor(private http: HttpClient) {}

  async getOpportunities(publisherId, searchParams) {
    var url =
      BACKEND_URL + '/api/v1/publishers/' + publisherId + '/opportunities/'

    var res = await this.http
      .get<any>(url, { params: searchParams, observe: 'response' })
      .toPromise()
    return res
  }

  async getOpportunity(publisherId, opportunityId) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/opportunities/' +
      opportunityId

    var res = await this.http
      .get<any>(url, { observe: 'response' })
      .toPromise()
    return res
  }

  async makeOpportunityFavorite(publisherId, opportunityId, favorite) {
    var url =
      BACKEND_URL +
      '/api/v1/publishers/' +
      publisherId +
      '/opportunities/' +
      opportunityId

    var res = await this.http
      .post<any>(url, { favorite: favorite }, { observe: 'response' })
      .toPromise()
    return res
  }
}
