<div id="anchor4">
  <form
    class="margin-v-39"
    [formGroup]="newCampaignForm"
    (ngSubmit)="onSubmitProject()"
  >
    <div class="row" style="width: 80vw">
      <div class="form-group col-md-12 center-mobile">
        <p
          class="text-lato text-bold text-big text-darkgrey text-left center-mobile"
        >
          {{ 'CAMPAIGN.CREATE.STEP_4_TITLE_1' | translate }}
        </p>

        <quill-editor
          formControlName="brief"
          placeholder="{{
            'CAMPAIGN.CREATE.STEP_4_BRIEF_PLACEHOLDER' | translate
          }}"
          (onContentChanged)="updateBrief()"
          class="ql-container"
          [modules]="editorModules"
        >
        </quill-editor>
      </div>

      <div class="col-12">
        <input
          style="margin-top: 100px"
          class="btn btn-primary btn-big text-lato text-bold"
          type="submit"
          value="{{ 'CAMPAIGN.CREATE.NEXT_STEP_BUTTON' | translate }}"
        />
      </div>
    </div>
  </form>
</div>
