import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Observable, from } from 'rxjs'

import { TokenService } from './../_services/token.service'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next))
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    // EXAMINE TOKEN
    await this.tokenService.examineToken()

    // add authorization header with jwt token if available
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))

    if (currentUser && currentUser.token) {
      // USER IS AUTHENTICATED
      request = request.clone({
        setHeaders: {
          'x-auth': `${currentUser.token}`,
        },
      })
    }

    return next.handle(request).toPromise()
  }
}
