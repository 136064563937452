<div class="display-block col-direction">
  <div class="section-container-inner text-darkgrey">
    <div class="row">
      <!--div class="col-12" style="margin-bottom: 5px; text-align: center">
        <div class="text-xlarge text-bold">Deals</div>
      </div-->
      <div
        class="col-12"
        style="
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
        "
      >
        <div
          id="summay"
          class="text-menu text-medium clickable"
          (click)="select('summary')"
          [ngClass]="
            selectedItem == 'summary' ? 'menu-selected' : 'menu-unselected'
          "
        >
          Summary
        </div>
        <div
          id="board"
          class="text-menu text-medium clickable"
          (click)="select('board')"
          [ngClass]="
            selectedItem == 'board' ? 'menu-selected' : 'menu-unselected'
          "
        >
          Board
        </div>
        <!--div
          class="text-menu text-medium clickable"
          (click)="select('conversations')"
          [ngClass]="
            selectedItem == 'conversations'
              ? 'menu-selected'
              : 'menu-unselected'
          "
        >
          Conversations
        </div-->
      </div>
    </div>
  </div>
</div>
