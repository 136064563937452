import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { DataService } from './../../../../_services/data.service'

import { DateHelper } from './../../../../_helpers/date.helpers'

import { PreferencesManager } from './../../../../_services/preferences.manager.service'

@Component({
  templateUrl: 'campaign_brief.component.html',
  styleUrls: ['./campaign_brief.component.scss'],
})
export class CampaignBriefComponent implements OnInit, OnDestroy {
  isLoading: boolean
  id: string
  private sub: any

  campaign: any

  target: any
  date_start: any
  date_end: any

  moodboard: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    public preferencesManager: PreferencesManager
  ) {
    this.isLoading = true

    this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_NOT_DEFINED'
  }

  ngOnInit() {
    this.init()
  }

  async init() {
    this.route.data.subscribe((data: { user: any }) => {
      //this.dataService.registerMe(data.user.body.user)
      //this.dataService.fetchProjects(data.user.body.projects)

      this.sub = this.route.params.subscribe(async (params) => {
        this.id = params['campaignId']

        this.campaign = this.dataService.getCampaign(this.id)

        if (this.campaign.target === 'audience_growth') {
          this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_AUDIENCE_GROWTH'
        }

        if (this.campaign.target === 'audience_conversion') {
          this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_AUDIENCE_CONVERSION'
        }

        if (this.campaign.target === 'sales_boost') {
          this.target = 'CAMPAIGN.BRIEF.BRIEF_TARGET_SALES_BOOST'
        }

        if (this.campaign.target === 'find_ambassadors') {
          this.target = 'Early Advocacy'
        }

        this.date_start = DateHelper.convertDoubleToReadableDate(
          this.campaign.startDate
        )
        this.date_end = DateHelper.convertDoubleToReadableDate(
          this.campaign.endDate
        )

        this.isLoading = false

        this.moodboard = this.campaign.moodboard
      })
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  destroy() {
    if (this.sub) this.sub.unsubscribe()
  }
}
