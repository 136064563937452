import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { SignupBufferService } from './../../../_services/buffers/signup.buffer.service'
import { ExternalRequestService } from './../../../_services/external.requests.service'

@Component({
  templateUrl: 'signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public signupBufferService: SignupBufferService,
    public externalRequestService: ExternalRequestService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.signupBufferService.init()
    // get return url from route parameters or default to '/'
    this.signupBufferService.setUrlToLoadAfterSignup(
      this.route.snapshot.queryParams['returnUrl'] || '/'
    )
    this.signupBufferService.setStep(0)
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}
}
