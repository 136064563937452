import { Component, OnInit, Input, HostListener } from '@angular/core'

import { ActivatedRoute, Router } from '@angular/router'
import { AlertService } from 'src/app/_services/alert.service'

import { ModalService, StorageManagementService } from '../../../../_services'
import { DataService } from '../../../../_services/data.service'
import { AuthenticationService } from '../../../../_services/authentication/authentication.service'

@Component({
  templateUrl: 'advertiser-nav-bar.component.html',
  selector: 'advertiser-nav-bar',
  styleUrls: ['./advertiser-nav-bar.component.scss'],
})
export class AdvertiserNavBarComponent implements OnInit {
  workspaceId: string
  campaignId: string
  private sub: any

  showMenu: boolean
  showSomething: boolean

  user: any

  profileData: any
  userFirstName: any
  userLastName: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private modalService: ModalService,
    public alertService: AlertService,
    private authenticationService: AuthenticationService,
    private storageManagementService: StorageManagementService
  ) {
    this.workspaceId = undefined
    this.campaignId = undefined
    this.showMenu = false
    this.showSomething = false
    this.user = undefined

    this.user = this.route.snapshot.data['user'].body.user
    this.userFirstName = this.user.infos.firstname
    this.userLastName = this.user.infos.lastname

    this.workspaceId = this.storageManagementService.getCurrentBrandspaceId() //this.route.snapshot.params['workspaceId']
  }

  ngOnInit() {}

  ngOnDestroy() {}

  logout() {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

  toggleUserMenu(event) {
    this.showMenu = !this.showMenu
    event.preventDefault()
    event.stopPropagation()
  }

  closeUserMenu() {
    this.showMenu = false
  }

  confirmLogout() {
    this.toggleUserMenu(undefined)
    event.preventDefault()
    var modal = document.querySelector('.modal') // assuming you have only 1
    var html = document.querySelector('html')
    modal.classList.add('is-active')
    html.classList.add('has-activated-background')
  }

  logoutConfirmed() {
    //this.logout()
    console.log('logout confirmed')
    this.authenticationService.logout()
  }

  logoutCancelled() {
    var modal = document.querySelector('.modal')
    var html = document.querySelector('html')
    modal.classList.remove('is-active')
    html.classList.remove('has-activated-background')
  }

  showThing(thing) {
    this.showMenu = false
    this.modalService.toggleModal(thing)
  }

  goToProfile() {
    this.router.navigate(['/me'])
  }

  goToSettings() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/settings'])
  }

  goToBilling() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/billing'])
  }

  goToMyBrand() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/brand'])
  }

  goToSupport() {}

  goToWorkspaces() {
    this.router.navigate(['/workspaces'])
  }

  loadAudiences() {
    this.router.navigate([
      '/workspaces/' + this.workspaceId + '/keywords/plans',
    ])
  }

  loadOpportunities() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/campaigns'])
  }

  loadDeals() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/deals'])
  }

  loadCollaborations() {
    this.router.navigate([
      '/workspaces/' + this.workspaceId + '/collaborations',
    ])
  }

  loadMessages() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/messages'])
  }

  loadPartners() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/partners'])
  }

  loadDashboard() {
    this.router.navigate(['/workspaces/' + this.workspaceId + '/dashboard'])
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    // your click logic
    this.closeUserMenu()
  }

  shouldDisplayWorkspaceName() {
    return this.workspaceId != undefined
  }

  getWorkspaceName() {
    let workspaces = this.route.snapshot.data['user'].body.projects

    let workspace = workspaces.filter(
      (workspace) => workspace._id === this.workspaceId
    )[0]

    return workspace.title
  }

  getFirstLetter(string) {
    return string.substr(0, 1)
  }

  shouldDisplayAvatarLetters() {
    if (
      this.user?.infos?.profilePictureURL != null &&
      this.user?.infos?.profilePictureURL !== '' &&
      this.user?.infos?.profilePictureURL !== './assets/images/blank.png'
    ) {
      return false
    }
    return true
  }

  whatIsTheTab() {
    let result = this.router.routerState.snapshot.url
    if (result.includes('/dashboard') == true) return 'dashboard'
    if (result.includes('/keywords/plans') == true) return 'plans'
    if (result.includes('/campaigns') == true) return 'campaigns'
    if (result.includes('/deals') == true) return 'deals'
    if (result.includes('/collaborations') == true) return 'planning'
    if (result.includes('/messages') == true) return 'messages'
    if (result.includes('/partners') == true) return 'partners'

    return undefined
  }

  /*getProfileData() {
    this.dataService.profileData.subscribe({
      next(value) {
        console.log(value)
      },
    })
  }*/
}
