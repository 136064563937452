import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { Board } from 'src/app/_models/board/board.model'
import { DealColumn } from 'src/app/_models/column/deal.column.model'
import { DateHelper } from '../../../../_helpers/date.helpers'
import { Deal } from 'src/app/_models/deal/deal.model'
import { Column } from 'src/app/_models/column/column.model'

@Component({
  templateUrl: 'deal-kanban-item.component.html',
  selector: 'deal-kanban-item',
  styleUrls: ['./deal-kanban-item.component.scss'],
})
export class DealKanbanItemComponent {
  @Input('deal') deal: any

  constructor(private router: Router) {}

  loadDealPage() {
    this.router.navigate(['/deals/' + this.deal.id])
  }

  computeQuote() {
    return this.deal._scope.total_budget + ' €'
  }

  public convertDoubleToReadableDate(t) {
    if (t == 0) return 'N/A'
    return DateHelper.convertDoubleToReadableDate(t)
  }
}
