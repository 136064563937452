import { Injectable } from '@angular/core'

@Injectable()
export class PreferencesManager {
  currency: any

  constructor() {}

  setPreferredCurrency(currency) {
    this.currency = currency
  }

  getPreferredCurrency() {
    if (this.currency != undefined) return this.currency
    else return this.getDefaultCurrency()
  }

  getDefaultCurrency() {
    /*return {
            id: 1,
            name: 'USD - US Dollar',
            symbol: '$'
        }*/
    return {
      id: 1,
      name: 'EUR - EURO',
      symbol: '€',
    }
  }
}
