<div class="text-lato field" [formGroup]="parentGroup">
  <mat-form-field class="input-text text-lato text-medium text-darkgrey">
    <input
      matInput
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [name]="name"
      [formGroupName]="formGroupName"
      autocomplete="off"
      (change)="changeDate()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
