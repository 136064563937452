import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { Router, ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import {
  AdvertisersAudiencesAPIService,
  StorageManagementService,
} from 'src/app/_services'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'
import { AlertService } from '../../../../_services/alert.service'

@Component({
  templateUrl: 'advertiser-audience.component.html',
  styleUrls: ['./advertiser-audience.component.scss'],
})
export class AdvertiserAudienceComponent implements OnInit, OnDestroy {
  plan: any

  // Language
  suggestedLanguages: any[]
  languageNameControl = new FormControl()
  filteredOptions: Observable<any[]>

  // Sorting informations
  sortingInformations: {
    key: string
    order: number
  }

  socialSortingInformations: {
    key: string
    order: number
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private formBuilder: FormBuilder,
    public storageManagementService: StorageManagementService,
    private campaignReferentialService: CampaignReferentialService,
    private audienceApiService: AdvertisersAudiencesAPIService
  ) {
    this.sortingInformations = {
      key: null,
      order: 0,
    }

    this.socialSortingInformations = {
      key: null,
      order: 0,
    }

    this.plan = route.snapshot.data.plan
    //this.mockKeywords()
    this.route.params.subscribe((routeParams) => {
      this.plan = this.fillPlansWithDetails(route.snapshot.data.plan)
    })
  }

  ngOnInit() {
    this.alertService.resetAlerts()
  }

  ngAfterViewInit() {}
  ngOnDestroy(): void {}

  getAudienceKeywords() {
    return this.plan.keywords
  }

  getAudienceSocialKeywords() {
    return this.plan.social_keywords
  }

  getAudienceNameText(plan) {
    return plan.name
  }

  getAudienceKeywordsText(plan) {
    try {
      let result = ''
      for (let i = 0; i < plan.keywords.length; i++) {
        result += result + plan.keywords[i].text

        if (i < plan.keywords.length - 1) result += ', '
      }
      return result
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  getAudienceLanguageText(plan) {
    try {
      let language = plan.language

      return this.campaignReferentialService
        .getLanguages()
        .filter((languageJson) => languageJson.key === language)
        .map((languageJson) => languageJson.text)[0]
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  getAudienceLocationText(plan) {
    try {
      let location = plan.location

      return this.campaignReferentialService
        .getLocations()
        .filter((locationJson) => locationJson.key === location)
        .map((locationJson) => locationJson.text)[0]
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  /*mockKeywords() {
    for (let i = 0; i < this.plan.keywords.length; i++) {
      this.plan.keywords[i]['search_volume'] =
        450000 + Math.floor(Math.random() * 45000)
      this.plan.keywords[i]['cpc'] = 0.80133 + Math.random() * 0.1
      this.plan.keywords[i]['competition'] =
        0.19101640486314 + Math.random() * 0.1
      this.plan.keywords[i]['volumes'] = {
        '1M': 368000 + Math.floor(Math.random() * 45000),
        '2M': 301000 + Math.floor(Math.random() * 45000),
        '3M': 301000 + Math.floor(Math.random() * 45000),
        '4M': 246000 + Math.floor(Math.random() * 45000),
        '5M': 246000 + Math.floor(Math.random() * 45000),
        '6M': 823000 + Math.floor(Math.random() * 45000),
        '7M': 550000 + Math.floor(Math.random() * 45000),
        '8M': 550000 + Math.floor(Math.random() * 45000),
        '9M': 673000 + Math.floor(Math.random() * 45000),
        '10M': 450000 + Math.floor(Math.random() * 45000),
        '11M': 368000 + Math.floor(Math.random() * 45000),
        '12M': 368000 + Math.floor(Math.random() * 45000),
      }
    }
  }*/

  getKeywordText(keyword) {
    return keyword['text']
  }
  getKeywordCPC(keyword) {
    if (this.plan.data && this.plan.data[keyword.text].lastValue['cpc'])
      return this.plan.data[keyword.text].lastValue['cpc']
    else return -1
  }

  getNumberText(value) {
    if (value == -1) return 'N/A'
    else return value
  }

  getKeywordCompetition(keyword) {
    if (this.plan.data && this.plan.data[keyword.text].lastValue['cmp'])
      return this.plan.data[keyword.text].lastValue['cmp']
    else return -1
  }

  getLastMonthVolume(keyword) {
    let m1 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m1
        ? this.plan.data[keyword.text].lastValue.m1
        : 0
    return m1
  }

  isNaN(value) {
    return Number.isNaN(value)
  }

  getMonthlySearchVariation(keyword) {
    let m1 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m1
        ? this.plan.data[keyword.text].lastValue.m1
        : 0

    let m2 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m2
        ? this.plan.data[keyword.text].lastValue.m2
        : 0

    if (m1 != 0) return (m1 - m2) / m1
    else return NaN
  }

  getLast3MonthsAvgVolume(keyword) {
    let m1 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m1
        ? this.plan.data[keyword.text].lastValue.m1
        : 0
    let m2 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m2
        ? this.plan.data[keyword.text].lastValue.m2
        : 0
    let m3 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m3
        ? this.plan.data[keyword.text].lastValue.m3
        : 0
    return (m1 + m2 + m3) / 3
  }

  getLast6MonthsAvgVolume(keyword) {
    let m1 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m1
        ? this.plan.data[keyword.text].lastValue.m1
        : 0
    let m2 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m2
        ? this.plan.data[keyword.text].lastValue.m2
        : 0
    let m3 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m3
        ? this.plan.data[keyword.text].lastValue.m3
        : 0
    let m4 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m4
        ? this.plan.data[keyword.text].lastValue.m4
        : 0
    let m5 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m5
        ? this.plan.data[keyword.text].lastValue.m5
        : 0
    let m6 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m6
        ? this.plan.data[keyword.text].lastValue.m6
        : 0
    return (m1 + m2 + m3 + m4 + m5 + m6) / 6
  }

  getLast12MonthsAvgVolume(keyword) {
    let m1 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m1
        ? this.plan.data[keyword.text].lastValue.m1
        : 0
    let m2 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m2
        ? this.plan.data[keyword.text].lastValue.m2
        : 0
    let m3 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m3
        ? this.plan.data[keyword.text].lastValue.m3
        : 0
    let m4 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m4
        ? this.plan.data[keyword.text].lastValue.m4
        : 0
    let m5 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m5
        ? this.plan.data[keyword.text].lastValue.m5
        : 0
    let m6 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m6
        ? this.plan.data[keyword.text].lastValue.m6
        : 0
    let m7 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m7
        ? this.plan.data[keyword.text].lastValue.m7
        : 0
    let m8 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m8
        ? this.plan.data[keyword.text].lastValue.m8
        : 0
    let m9 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m9
        ? this.plan.data[keyword.text].lastValue.m9
        : 0
    let m10 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m10
        ? this.plan.data[keyword.text].lastValue.m10
        : 0
    let m11 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m11
        ? this.plan.data[keyword.text].lastValue.m11
        : 0
    let m12 =
      this.plan.data && this.plan.data[keyword.text].lastValue.m12
        ? this.plan.data[keyword.text].lastValue.m12
        : 0
    return (m1 + m2 + m3 + m4 + m5 + m6 + m7 + m8 + m9 + m10 + m11 + m12) / 12
  }

  editPlan() {
    let url =
      '/workspaces/' +
      this.storageManagementService.getCurrentBrandspaceId() +
      '/keywords/plans/' +
      this.plan._id +
      '/edit'

    this.router.navigate([url])
  }

  getKeywordVolume(keyword) {
    return this.plan.socialData.keywordsData[keyword.text].volume
  }

  getKeywordVolumeSummary(volume) {
    switch (volume) {
      case 'Atomic': {
        return 0
      }
      case 'Very Low': {
        return 1
      }
      case 'Low': {
        return 2
      }
      case 'Medium': {
        return 3
      }
      case 'High': {
        return 4
      }
      case 'Very High': {
        return 5
      }
      default: {
        return -1
      }
    }
  }

  async clonePlan() {
    let keywords = []
    for (let i = 0; i < this.plan.keywords.length; i++) {
      keywords.push(this.plan.keywords[i].text)
    }

    let socialKeywords = []
    for (let i = 0; i < this.plan.social_keywords.length; i++) {
      keywords.push(this.plan.social_keywords[i].text)
    }

    let audienceJson = {
      name: this.plan.name + ' (1)',
      keywords: keywords,
      social_keywords: socialKeywords,
      language: this.plan.language,
    }

    let result = await this.audienceApiService.createAudience(
      this.storageManagementService.getCurrentBrandspaceId(),
      audienceJson
    )

    if (result.status == 200) this.visitAudience(result.body.plan)
  }

  async deletePlan() {
    let result = await this.audienceApiService.deleteAudience(
      this.storageManagementService.getCurrentBrandspaceId(),
      this.plan._id
    )

    if (result.status == 200) {
      let url =
        '/workspaces/' +
        this.storageManagementService.getCurrentBrandspaceId() +
        '/keywords/plans/'
      this.router.navigate([url])
    }
  }

  visitAudience(plan) {
    let url =
      '/workspaces/' +
      this.storageManagementService.getCurrentBrandspaceId() +
      '/keywords/plans/' +
      plan._id
    this.router.navigate([url])
  }

  sortKeywordsBy(key) {
    if (this.sortingInformations.key === key) {
      this.sortingInformations.order = -this.sortingInformations.order
    } else {
      this.sortingInformations.key = key
      this.sortingInformations.order = 1
    }

    this.plan.keywords = this.plan.keywords.sort((a, b) => {
      if (a.summary[key] == null && b.summary[key] == null) return 0
      else if (a.summary[key] == null)
        return -1 * this.sortingInformations.order
      else if (b.summary[key] == null) return 1 * this.sortingInformations.order

      if (a.summary[key] < b.summary[key]) {
        return -1 * this.sortingInformations.order
      }
      if (a.summary[key] > b.summary[key]) {
        return 1 * this.sortingInformations.order
      }
      return 0
    })
  }

  sortSocialKeywordsBy(key) {
    if (this.socialSortingInformations.key === key) {
      this.socialSortingInformations.order = -this.socialSortingInformations
        .order
    } else {
      this.socialSortingInformations.key = key
      this.socialSortingInformations.order = 1
    }

    this.plan.social_keywords = this.plan.social_keywords.sort((a, b) => {
      if (a.summary[key] == null && b.summary[key] == null) return 0
      else if (a.summary[key] == null)
        return -1 * this.socialSortingInformations.order
      else if (b.summary[key] == null)
        return 1 * this.socialSortingInformations.order

      if (a.summary[key] < b.summary[key]) {
        return -1 * this.socialSortingInformations.order
      }
      if (a.summary[key] > b.summary[key]) {
        return 1 * this.socialSortingInformations.order
      }
      return 0
    })
  }

  fillPlansWithDetails(plan) {
    for (let i = 0; i < this.plan?.keywords?.length; i++) {
      plan.keywords[i].summary = {
        name: this.getKeywordText(plan.keywords[i]),
        last_month_search: this.getLastMonthVolume(plan.keywords[i]),
        average_monthly_search: this.getLast12MonthsAvgVolume(plan.keywords[i]),
        cpc: this.getKeywordCPC(plan.keywords[i]),
        competition: this.getKeywordCompetition(plan.keywords[i]),
      }
    }

    for (let i = 0; i < this.plan?.social_keywords?.length; i++) {
      plan.social_keywords[i].summary = {
        volume_text: this.getKeywordVolume(plan.social_keywords[i]),
        volume: this.getKeywordVolumeSummary(
          this.getKeywordVolume(plan.social_keywords[i])
        ),
      }
    }

    return plan
  }

  getLastMonthTotalSearch() {
    let result = 0

    for (let i = 0; i < this.plan?.keywords?.length; i++) {
      result += this.plan.keywords[i].summary.last_month_search
    }

    return result
  }

  getLastMonthTotalVariation() {
    let m1 = this.getLastMonthTotalSearch()

    let m2 = 0

    for (let i = 0; i < this.plan?.keywords?.length; i++) {
      m2 +=
        this.plan.data &&
        this.plan.data[this.plan?.keywords[i].text].lastValue.m2
          ? this.plan.data[this.plan?.keywords[i].text].lastValue.m2
          : 0
    }

    if (m1 != 0) return (m1 - m2) / m1
    else return NaN
  }

  getAverageCpc() {
    let totalVolume = 0
    let cpc = 0
    if (this.plan.data) {
      for (let key in Object.keys(this.plan.data)) {
        if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.volume) {
          totalVolume += this.plan.data[Object.keys(this.plan.data)[key]]
            .lastValue.volume
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cpc)
            cpc =
              this.plan.data[Object.keys(this.plan.data)[key]].lastValue
                .volume *
              this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cpc
        }
      }
    }

    if (totalVolume > 0) {
      cpc /= totalVolume
    }

    return cpc
  }

  getAverageCompetition() {
    let totalVolume = 0
    let competition = 0

    if (this.plan.data) {
      for (let key in Object.keys(this.plan.data)) {
        if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.volume) {
          totalVolume += this.plan.data[Object.keys(this.plan.data)[key]]
            .lastValue.volume
          if (this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cmp)
            competition =
              this.plan.data[Object.keys(this.plan.data)[key]].lastValue
                .volume *
              this.plan.data[Object.keys(this.plan.data)[key]].lastValue.cmp
        }
      }
    }

    if (totalVolume > 0) {
      competition /= totalVolume
    }

    return competition
  }
}
