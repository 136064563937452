import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CurrencyHelper } from 'src/app/_helpers/referential/currency.helper'
import { CampaignType } from 'src/app/_models/referential/campaign_type.model'
import {
  AdvertisersOffersAPIService,
  StorageManagementService,
} from 'src/app/_services'
import { AlertService } from 'src/app/_services/alert.service'
import { CampaignCreationBufferService } from 'src/app/_services/buffers/campaign.creation.buffer.service'
import { CampaignReferentialService } from 'src/app/_services/referential/campaign.referential.service'

@Component({
  templateUrl: 'opportunity-informations.component.html',
  selector: 'opportunity-informations',
  styleUrls: ['./opportunity-informations.component.scss'],
})
export class AdvertiserOpportunityInformationsComponent implements OnInit {
  @Input('brand') brand: any
  @Input('offer') offer: any

  plans: any[]

  isEditing: boolean
  opportunityForm: FormGroup

  keywords: any[]
  locations: any[]

  countries: any
  states: any

  statesToIterate: any

  hasCorporateNameError: boolean
  hasContactEmailError: boolean
  hasAddressError: boolean
  hasCurrencyError: boolean
  isValid: boolean

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private advertisersOffersAPIService: AdvertisersOffersAPIService,
    private storageManagementService: StorageManagementService,
    private route: ActivatedRoute,
    private campaignReferentialService: CampaignReferentialService,
    private campaignCreationBufferService: CampaignCreationBufferService
  ) {
    this.plans = this.route.snapshot.data.plans
    console.log(this.plans)

    this.keywords = this.campaignCreationBufferService.campaignBeingCreated().keywords
    this.locations = this.campaignCreationBufferService.campaignBeingCreated().locations

    this.opportunityForm = this.formBuilder.group({
      titleForm: this.formBuilder.group({
        title: { value: '', disabled: false },
      }),
      briefForm: this.formBuilder.group({
        brief: { value: '', disabled: false },
      }),
      keyword: [''],
      country: [''],
      area: [''],
      date_start: [
        this.campaignCreationBufferService.campaignBeingCreated().date_start,
      ],
      date_end: [
        this.campaignCreationBufferService.campaignBeingCreated().date_end,
      ],
      plan: { value: '', disabled: false },
    })
  }

  async ngOnInit() {
    this.countries = await this.getCountries()

    this.states = this.campaignReferentialService.getStates()
    this.statesToIterate = await this.getStates()
    this.alertService.resetAlerts()
    this.isEditing = this.route.snapshot.data.isNew || false
    /*    if (this.hasBillingInformation() == true) this.isEditing = false
    else this.isEditing = true*/

    this.initValues()
  }

  initValues() {
    if (this.hasOffer() == true) {
      this.campaignCreationBufferService.campaignBeingCreated().title = this.offer.name
      this.campaignCreationBufferService.campaignBeingCreated().brief = this.offer.brief
      this.selectTarget(this.getTarget(this.offer.target))
      this.campaignCreationBufferService.campaignBeingCreated().date_start = new Date(
        this.offer.startDate
      )
      this.campaignCreationBufferService.campaignBeingCreated().date_end = new Date(
        this.offer.endDate
      )

      let scope = this.hasOffer() ? this.offer?._scope?._id : ''

      this.opportunityForm = this.formBuilder.group({
        titleForm: this.formBuilder.group({
          title: {
            value: this.campaignCreationBufferService.campaignBeingCreated()
              .title,
            disabled: !this.isEditing,
          },
        }),
        briefForm: this.formBuilder.group({
          brief: {
            value: this.campaignCreationBufferService.campaignBeingCreated()
              .brief,
            disabled: !this.isEditing,
          },
        }),
        keyword: [''],
        country: [''],
        area: [''],
        date_start: [
          this.campaignCreationBufferService.campaignBeingCreated().date_start,
        ],
        date_end: [
          this.campaignCreationBufferService.campaignBeingCreated().date_end,
        ],
        plan: { value: scope, disabled: false },
      })
    }
  }

  ngOnDestroy() {}

  hasOffer() {
    try {
      return this?.offer != undefined
    } catch (e) {
      return false
    }
  }

  getBillingInformation() {
    if (this.hasOffer() == true) return this.offer
    else return undefined
  }

  makeEditable() {
    this.isEditing = true
    this.alertService.resetAlerts()
    this.opportunityForm.enable()
    return
  }

  makeNotEditable() {
    this.isEditing = false
    this.opportunityForm.disable()
    return
  }

  getCurrencyText(code) {
    return CurrencyHelper.getCurrencyText(code)
  }

  getCurrencies() {
    return CurrencyHelper.getCurrencies()
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async saveChanges() {
    try {
      this.isValid = true

      this.alertService.resetAlerts()

      let name = this.opportunityForm.get('titleForm').get('title').value
      let plan = this.opportunityForm.get('plan').value
      let date_start = new Date(
        this.opportunityForm.get('date_start').value
      ).getTime()
      let date_end = new Date(
        this.opportunityForm.get('date_end').value
      ).getTime()
      let brief = this.opportunityForm.get('briefForm').get('brief').value
      let target = this.campaignCreationBufferService.getCampaignType().info

      if (this.isValid == true) {
        this.isValid = true

        let opportunity_infos = {
          name: name,
          planId: plan,
          startDate: date_start,
          endDate: date_end,
          brief: brief,
          target: target,
        }

        let res = undefined

        if (this.hasOffer()) {
          res = await this.advertisersOffersAPIService.updateOffer(
            this.storageManagementService.getCurrentBrandspaceId(),
            this.route.snapshot.params['campaignId'],
            opportunity_infos
          )
        } else {
          res = await this.advertisersOffersAPIService.createOffer(
            this.storageManagementService.getCurrentBrandspaceId(),
            opportunity_infos
          )
        }

        if (res.status == 200) {
          this.alertService.pushMessageAlert(
            'Your opportunity has been published'
          )
          this.makeNotEditable()
          this.brand = res.body
        } else {
          this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
        }
      } else {
        //this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
      }
    } catch (e) {
      console.log(e)
      this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
    }
  }

  // TITLE
  updateTitle() {
    let candidate = this.opportunityForm.get('titleForm').get('title').value

    if (candidate != undefined) {
      candidate = candidate.trim()
      this.campaignCreationBufferService.campaignBeingCreated().title = candidate
    }
  }

  // BRIEF
  updateBrief() {
    let candidate = this.opportunityForm.get('briefForm').get('brief').value

    if (candidate != undefined) {
      candidate = candidate.trim()
      this.campaignCreationBufferService.campaignBeingCreated().brief = candidate
    }
  }

  // TARGET
  selectTarget(target) {
    this.campaignCreationBufferService.setCampaignType(target)
  }

  getTargets() {
    return this.campaignReferentialService.getTargets()
  }

  getTarget(name) {
    let filtered = this.campaignReferentialService
      .getTargets()
      .filter((target) => target.info === name)
    if (filtered) return filtered[0]
    else return undefined
  }

  getCampaignTypeImage(campaignType: CampaignType) {
    return campaignType.info ===
      this.campaignCreationBufferService.getCampaignType().info
      ? campaignType.img_selected
      : campaignType.img
  }

  activateSelectedBubbleClass(campaignType: CampaignType) {
    return (
      campaignType.info ===
      this.campaignCreationBufferService.getCampaignType().info
    )
  }

  // LOCATION
  addLocation() {
    var candidate_country = this.opportunityForm.controls.country.value
    var candidate_area = this.opportunityForm.controls.area.value

    if (candidate_country) {
      if (candidate_area) {
      } else {
        candidate_area = 0
      }
      var toPush = {
        country: this.countries[candidate_country].name,
        state: this.states[candidate_area].name,
      }

      if (this.indexOfLocation(toPush) === -1) {
        this.locations.push(toPush)
      }
    }
  }

  indexOfLocation(location) {
    var index = -1
    for (var i = 0; i < this.locations.length; i++) {
      if (
        this.locations[i].country === location.country &&
        this.locations[i].state === location.state
      ) {
        index = i
        break
      }
    }
    return index
  }

  removeLocation(location) {
    var i = this.locations.indexOf(location)
    this.locations.splice(i, 1)
  }

  addKeyword() {
    //if (this.newPipeForm.valid) {
    var candidate = this.opportunityForm.controls.keyword.value
    if (candidate == '') {
    } else {
      if (candidate != undefined) {
        candidate = candidate.trim()
      }
      if (candidate != undefined) {
        if (this.keywords.indexOf(candidate) === -1) {
          this.keywords.push(candidate)
          this.opportunityForm.controls['keyword'].reset()
        }
      }
      //}
    }
  }

  removeKeyword(keyword) {
    var i = this.keywords.indexOf(keyword)
    this.keywords.splice(i, 1)
  }

  async getCountries() {
    console.log(await this.campaignReferentialService.getCountries())
    return await this.campaignReferentialService.getCountries()
  }

  async getStates() {
    if (this.opportunityForm.controls.country.value === '') return

    let states = await this.campaignReferentialService.getStates()
    var statesList = states.filter((state) => {
      return state.country.id == this.opportunityForm.controls.country.value
    })

    this.statesToIterate = statesList
  }

  resetStates() {
    this.getStates()
  }

  // DATES
  changeDateStart(event) {
    this.campaignCreationBufferService.campaignBeingCreated().date_start =
      event.target.value
  }

  changeDateEnd(event) {
    this.campaignCreationBufferService.campaignBeingCreated().date_end =
      event.target.value
  }

  getCampainMinimalLaunchDate() {
    return this.campaignCreationBufferService.getCampainMinimalLaunchDate()
  }

  getCampainMinimalFinishDate() {
    return this.campaignCreationBufferService.getCampainMinimalFinishDate()
  }

  getSelectedPlan() {
    return this.opportunityForm.get('plan').value
  }

  shouldSelectPlan(plan) {
    console.log(this.getSelectedPlan())
    console.log(plan._id)
    console.log(this.getSelectedPlan() === plan._id)
    return this.getSelectedPlan() === plan._id
  }
}
