import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

import { DateHelper } from './../../../../_helpers/date.helpers'

import * as moment from 'moment'

@Component({
  templateUrl: 'campaign-details.component.html',
  selector: 'campaign-details',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetailsComponent implements OnInit {
  @Input('opportunity') opportunity: any

  @Output() showModalEvent = new EventEmitter<boolean>()

  doList: any[]
  dontList: any[]

  constructor(private router: Router) {}

  async ngOnInit() {
    this.doList = this.opportunity._scope.do
    this.dontList = this.opportunity._scope.dont
  }

  ngOnDestroy() {}

  loadBrandInfoPage() {
    //this.router.navigate(['/opportunities/' + this.campaign._id + '/brandInfo'])
  }

  getCampaignDateSentence() {
    if (this.opportunity._scope.status === 'draft')
      return 'This campaign is still in draft mode'
    else if (this.opportunity._scope.status === 'launched') {
      let now = moment().valueOf()
      if (now < this.opportunity._scope.startDate) {
        return (
          'Influencer selection process will start on ' +
          DateHelper.convertDoubleToReadableDate(
            this.opportunity._scope.startDate
          )
        )
      } else if (now > this.opportunity._scope.endDate) {
        return 'Influencer selection process for this campaign is over'
      } else {
        return (
          'Influencer selection process is available until ' +
          DateHelper.convertDoubleToReadableDate(
            this.opportunity._scope.endDate
          )
        )
      }
    } else if (this.opportunity._scope.status === 'finished') {
      return 'Influencer selection process for this campaign is over'
    } else if (this.opportunity._scope.status === 'cancelled') {
      return 'This campaign has been cancelled by the associated brand'
    }

    return null
  }

  getCampaignStatus() {
    if (this.opportunity._scope.status === 'draft') return 'DRAFT'
    else if (this.opportunity._scope.status === 'launched') {
      let now = moment().valueOf()
      if (now < this.opportunity._scope.startDate) {
        return 'UPCOMING'
      } else if (now > this.opportunity._scope.endDate) {
        return 'FINISHED'
      } else {
        return 'LIVE'
      }
    } else if (this.opportunity._scope.status === 'cancelled')
      return 'CANCELLED'
    else if (this.opportunity._scope.status === 'finished') return 'FINISHED'

    return undefined
  }

  getCampaignTarget() {
    if (this.opportunity._scope.target === 'audience_growth')
      return 'Audience growth'
    else if (this.opportunity._scope.target === 'find_ambassadors')
      return 'Find ambassadors'
    else if (this.opportunity._scope.target === 'sales_boost')
      return 'Sales Boost'
    else if (this.opportunity._scope.target === 'awareness') return 'Awareness'
    else if (this.opportunity._scope.target === 'consideration')
      return 'Consideration'
    else if (this.opportunity._scope.target === 'conversions')
      return 'Conversions'
    else {
      return undefined
    }
  }

  getCampaignLocation() {
    if (this.opportunity._scope?.locations?.country == undefined) {
      return 'This campaign has no location'
    } else {
      return (
        'Campaign location : ' + this.opportunity?._scope?.locations?.country
      )
    }
  }

  getNetworkThumbnailImagePath(network) {
    let path = 'assets/images/icons/'
    switch (network) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  makeAnOffer() {
    this.showModalEvent.emit(true)
    document.body.style.overflow = 'hidden'
  }

  getCampaign() {
    return this.opportunity?._scope
  }
}
