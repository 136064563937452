import { Component, OnInit, Input } from '@angular/core'

@Component({
  templateUrl: 'add-button.component.html',
  selector: 'add-button',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
