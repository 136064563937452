<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="false" style="display: block; opacity: 1;" (click)="closeModal()">
        <div class="modal-dialog modal-dialog-centered" role="document" (click)="stopPropagation($event)">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-lato" id="exampleModalLongTitle">{{ 'Budget exceeded' | translate }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-lato">
                {{ 'Unfortunately, scaler proposal is above remaining budget' | translate }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary text-lato" data-dismiss="modal" (click)="closeModal()">{{ 'Ok' | translate }}</button>
            </div>
          </div>
        </div>
      </div>