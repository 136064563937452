import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core'

import { Location } from '@angular/common'

import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

import { APIService } from '../../../../_services'
import { AlertService } from '../../../../_services/alert.service'
import { DataService } from '../../../../_services/data.service'

import { CurrencyHelper } from './../../../../_helpers/referential/currency.helper'

//let autocomplete: google.maps.places.Autocomplete

const componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
}

@Component({
  templateUrl: 'project_billing_edit.component.html',
  styleUrls: ['./project_billing_edit.component.scss'],
})
export class ProjectBillingEditComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sub: any
  isValid: boolean
  hasCorporateNameError: boolean
  hasAddressError: boolean
  hasCurrencyError: boolean
  hasContactEmailError: boolean
  myBillingForm: FormGroup
  returnUrl: string
  error = ''
  loading = false

  hasSelectedAFile: boolean

  project_name: any
  project: any
  project_id: any

  @ViewChild('search')
  public searchElementRef: ElementRef

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService,
    private location: Location,
    public alertService: AlertService
  ) {
    this.project_name = undefined
    this.project = undefined
    this.project_id = undefined
    this.loading = false

    this.isValid = true
    this.hasCorporateNameError = false
    this.hasSelectedAFile = false
    this.hasCurrencyError = false
  }

  ngOnInit() {
    this.alertService.resetAlerts()

    this.myBillingForm = this.formBuilder.group({
      corporateNameForm: this.formBuilder.group({
        corporateName: [''],
      }),
      address_1Form: this.formBuilder.group({
        address_1: [''],
      }),
      address_2Form: this.formBuilder.group({
        address_2: [''],
      }),
      zipCodeForm: this.formBuilder.group({
        zipCode: [''],
      }),
      cityForm: this.formBuilder.group({
        city: [''],
      }),
      stateForm: this.formBuilder.group({
        state: [''],
      }),
      countryForm: this.formBuilder.group({
        country: [''],
      }),
      preferredCurrency: [''],
      vatNumberForm: this.formBuilder.group({
        vatNumber: [''],
      }),
      contactEmailForm: this.formBuilder.group({
        contactEmail: new FormControl(
          '',
          Validators.compose([
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ])
        ),
      }),
    })

    this.sub = this.route.params.subscribe(async (params) => {
      this.project_id = params['projectId']

      this.project = this.dataService.getProject(this.project_id)
      /*            var projects = await this.cacheService.getProjects();
                        for (var i = 0; i < projects.length; i++) {
                            if (projects[i]._id == this.project_id) {
                                this.project = projects[i]
                                break;
                            }
                        }*/

      this.initValues(this.project)

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    })
  }

  ngAfterViewInit() {
    //this.initAutocomplete()
    //this.geolocate()
  }

  ngOnDestroy(): void {}

  initValues(project) {
    if (project.billingInformation != undefined) {
      this.myBillingForm = this.formBuilder.group({
        corporateNameForm: this.formBuilder.group({
          corporateName: [this.project.billingInformation.corporateName],
        }),
        address_1Form: this.formBuilder.group({
          address_1: [this.project.billingInformation.address.addressLine1],
        }),
        address_2Form: this.formBuilder.group({
          address_2: [this.project.billingInformation.address.addressLine2],
        }),
        zipCodeForm: this.formBuilder.group({
          zipCode: [this.project.billingInformation.address.zipCode],
        }),
        cityForm: this.formBuilder.group({
          city: [this.project.billingInformation.address.city],
        }),
        stateForm: this.formBuilder.group({
          state: [this.project.billingInformation.address.state],
        }),
        countryForm: this.formBuilder.group({
          country: [this.project.billingInformation.address.country],
        }),
        preferredCurrency: [
          this.project.billingInformation.currency != undefined
            ? this.project.billingInformation.currency.code
            : '',
        ],
        vatNumberForm: this.formBuilder.group({
          vatNumber: [this.project.billingInformation.vatNumber],
        }),
        contactEmailForm: this.formBuilder.group({
          contactEmail: new FormControl(
            this.project.billingInformation.contactEmail,
            Validators.compose([
              Validators.required,
              Validators.pattern(
                '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
              ),
            ])
          ),
        }),
      })
    }
  }

  readTextValue(field) {
    if (field == undefined) {
      return field
    } else return field.trim()
  }

  async onSubmit() {
    try {
      this.hasCorporateNameError = false
      this.hasContactEmailError = false
      this.hasAddressError = false
      this.hasCurrencyError = false
      this.isValid = true

      this.alertService.resetAlerts()

      var corporate_name = this.readTextValue(
        this.myBillingForm.controls.corporateNameForm['controls'].corporateName
          .value
      )

      var address_1 = /**/ this.readTextValue(
        this.myBillingForm.controls.address_1Form['controls'].address_1.value
      )

      var address_2 = this.readTextValue(
        this.myBillingForm.controls.address_2Form['controls'].address_2.value
      )
      var zipCode = this.readTextValue(
        this.myBillingForm.controls.zipCodeForm['controls'].zipCode.value
      )
      var city = this.readTextValue(
        this.myBillingForm.controls.cityForm['controls'].city.value
      )
      var state = this.readTextValue(
        this.myBillingForm.controls.stateForm['controls'].state.value
      )
      var country = this.readTextValue(
        this.myBillingForm.controls.countryForm['controls'].country.value
      )
      var currency = this.myBillingForm.controls.preferredCurrency.value
      if (currency == undefined || currency === '') {
        this.hasCurrencyError = true
        this.isValid = false
      }

      var vatNumber = this.readTextValue(
        this.myBillingForm.controls.vatNumberForm['controls'].vatNumber.value
      )
      var contactEmail = this.readTextValue(
        this.myBillingForm.controls.contactEmailForm['controls'].contactEmail
          .value
      )
      if (
        contactEmail != undefined &&
        this.myBillingForm.controls.contactEmailForm['controls'].contactEmail
          .valid == false
      ) {
        this.hasContactEmailError = true
        this.isValid = false
      }

      if (corporate_name != undefined) {
        if (corporate_name.length > 2) {
        } else {
          this.hasCorporateNameError = true
          this.isValid = false
        }
      } else {
        this.isValid = false
      }

      if (this.isValid == true) {
        this.isValid = true

        var billing_infos = {
          corporateName: corporate_name,
          address: {
            addressLine1: address_1,
            addressLine2: address_2,
            zipCode: zipCode,
            city: city,
            state: state,
            country: country,
          },
          vatNumber: vatNumber,
          contactEmail: contactEmail,
        }

        if (currency != undefined && currency !== '')
          billing_infos['currency'] = {
            code: currency,
          }

        var res
        res = await this.apiService.updateBillingInformations(
          this.project_id,
          billing_infos
        )
        if (res.status == 200) {
          this.alertService.pushMessageAlert('PROJECT.MY_BILLING_EDIT.ALERT_OK')
        } else {
          this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
        }
      } else {
        this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
      }
    } catch (e) {
      this.alertService.pushErrorAlert('PROJECT.MY_BILLING_EDIT.ALERT_NOK')
    }
  }

  stepBack() {
    this.location.back()
  }

  // Google map autocomplete
  /*initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete') as HTMLInputElement,
      { types: ['geocode'] }
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['address_component'])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', () => {
      this.fillInAddress()
    })
  }

  fillInAddress() {
    // Get the place details from the autocomplete object.

    const place = autocomplete.getPlace()

    for (const component in componentForm) {
      ;(document.getElementById(component) as HTMLInputElement).value = ''
      ;(document.getElementById(component) as HTMLInputElement).disabled = false
    }
    // set value of myBillingForm with autocomplete
    this.myBillingForm.controls.address_1Form['controls'].address_1.setValue(
      place.address_components[0].long_name
    )
    this.myBillingForm.controls.address_2Form['controls'].address_2.setValue(
      place.address_components[1].long_name
    )
    this.myBillingForm.controls.cityForm['controls'].city.setValue(
      place.address_components[2].long_name
    )
    this.myBillingForm.controls.stateForm['controls'].state.setValue(
      place.address_components[4].long_name
    )
    this.myBillingForm.controls.countryForm['controls'].country.setValue(
      place.address_components[5].long_name
    )
    this.myBillingForm.controls.zipCodeForm['controls'].zipCode.setValue(
      place.address_components[6].long_name
    )
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const addressType = component.types[0]

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]]
        //;(document.getElementById(addressType) as HTMLInputElement).value = val
      }
    }
  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        })
        autocomplete.setBounds(circle.getBounds())
      })
    }
  }*/

  getCurrencies() {
    return CurrencyHelper.getCurrencies()
  }
}
