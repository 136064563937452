import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
} from '@angular/core'
import { Board } from 'src/app/_models/board/board.model'
import { TaskColumn } from 'src/app/_models/column/task.column.model'
import { TaskSynchronisationService } from '../../../../_services/collaborations/task.synchronisation.service'
import { Column } from 'src/app/_models/column/column.model'
import { EditableTask } from 'src/app/_models/task/editable.task.model'
import { TasksFilterService } from '../../../../_services/collaborations/tasks.filter.service'

import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'

@Component({
  templateUrl: 'collaborations-kanban.component.html',
  selector: 'collaborations-kanban',
  styleUrls: ['./collaborations-kanban.component.scss'],
})
export class CollaborationsKanbanComponent implements OnInit, OnDestroy {
  @Input('collaborationsData') collaborationsData: any

  board: Board = this.initBoard()

  editableTasksSubscription: any

  filterTasksSubscription: any
  filters: any

  showTaskDetailsPanel: boolean

  targetElement: HTMLElement | null

  constructor(
    private taskSynchronisationService: TaskSynchronisationService,
    private tasksFilterService: TasksFilterService
  ) {
    this.showTaskDetailsPanel = false
  }

  async ngOnInit() {
    await this.taskSynchronisationService.updateEditableTasks(
      this.collaborationsData.tasks
    )

    this.taskSynchronisationService.updateDeals(this.collaborationsData.deals)
    this.taskSynchronisationService.updateBrands(
      this.updateBrands(this.collaborationsData.referential.deals)
    )

    this.taskSynchronisationService.updateBrands(
      this.updateBrands(this.collaborationsData.referential.deals)
    )

    this.editableTasksSubscription = this.taskSynchronisationService
      .getEditableTasks()
      .subscribe((editableTasks) => {
        this.board = this.initBoard()
        this.getCollaborations()
      })

    this.filterTasksSubscription = this.tasksFilterService
      .getFilters()
      .subscribe((filters) => {
        this.filters = filters
        this.board = this.initBoard()
        this.getCollaborations()
      })
  }

  initBoard() {
    return new Board('board', [
      new TaskColumn('TO-DO', 'backlog', []),
      new TaskColumn('IN PROGRESS', 'in_progress', []),
      new TaskColumn('TO BE VALIDATED', 'to_be_validated', []),
      new TaskColumn('VALIDATED', 'validated', []),
      new TaskColumn('PUBLISHED', 'published', []),
    ])
  }

  async getCollaborations() {
    let tasksArray = this.taskSynchronisationService
      .getEditableTasks()
      .getValue()

    // Example of filter tasks
    // If you want to keep only tasks whose step is 'back_log'
    // tasksArray = this.filterTasks(tasksArray, 'step', 'backlog', 'eq')
    // Example of multiple filters
    /*tasksArray = EditableTask.filterTasks(
      EditableTask.filterTasks(tasksArray, 'step', 'backlog', 'eq'),
      'status',
      'created',
      'eq'
    )*/

    if (this.filters != undefined) {
      for (let i = 0; i < this.filters.length; i++) {
        if (tasksArray == undefined && tasksArray.length == 0) break

        tasksArray = EditableTask.filterTasks(
          tasksArray,
          this.filters[i].key,
          this.filters[i].value,
          this.filters[i].operator,
          this.taskSynchronisationService.getBrands().getValue()
        )
      }

      this.buildColumns(tasksArray)
    }
  }

  buildColumns(tasksArray) {
    for (var j = 0; j < tasksArray.length; j++) {
      let task = tasksArray[j]

      if (task.step === 'backlog' || task.step === '') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<TaskColumn>this.board.columns[i]).name === 'TO-DO') {
            ;(<TaskColumn>this.board.columns[i]).tasks.push(task)
          }
        }
      } else if (task.step === 'in_progress') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<TaskColumn>this.board.columns[i]).name === 'IN PROGRESS') {
            ;(<TaskColumn>this.board.columns[i]).tasks.push(task)
          }
        }
      } else if (task.step === 'to_be_validated') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<TaskColumn>this.board.columns[i]).name === 'TO BE VALIDATED') {
            ;(<TaskColumn>this.board.columns[i]).tasks.push(task)
          }
        }
      } else if (task.step === 'validated') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<TaskColumn>this.board.columns[i]).name === 'VALIDATED') {
            ;(<TaskColumn>this.board.columns[i]).tasks.push(task)
          }
        }
      } else if (task.step === 'published') {
        for (var i = 0; i < this.board.columns.length; i++) {
          if ((<TaskColumn>this.board.columns[i]).name === 'PUBLISHED') {
            ;(<TaskColumn>this.board.columns[i]).tasks.push(task)
          }
        }
      }
    }
  }

  getColumn(column: Column) {
    return <TaskColumn>column
  }

  ngOnDestroy() {
    if (this.editableTasksSubscription)
      this.editableTasksSubscription.unsubscribe()
    if (this.filterTasksSubscription) this.filterTasksSubscription.unsubscribe()
  }

  closeAddTask() {
    this.showTaskDetailsPanel = false
  }

  manageModalEvent(newValue) {
    this.showTaskDetailsPanel = newValue
  }

  showTaskDetails(taskId = undefined, key = undefined) {
    let data = {
      taskId: taskId ? taskId : '',
      step: key,
    }
    this.taskSynchronisationService.editTask(data)
    this.showTaskDetailsPanel = true
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )

      this.taskSynchronisationService.editTask({
        taskId: event.item.data.taskId,
        step: event.container.id,
      })
    }
  }

  noReturnPredicate() {
    return true
  }

  // Fixed add-task directive if scroll down and reach nav-bar
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.showTaskDetailsPanel == true) {
      if (
        document.body.scrollTop > 85 ||
        document.documentElement.scrollTop > 85
      ) {
        document.getElementById('task-details-container').classList.add('fixed')
        document.getElementById('add-task-overlay').classList.add('fixed')
      }
    }
  }

  // fixed add-task directive if item clicked is down the screen
  @HostListener('click', [])
  clickAddTask() {
    if (this.showTaskDetailsPanel == true) {
      let target = document.getElementById('task-details-container')
      let res = target.getBoundingClientRect()
      //document.getElementById('task-details-container').classList.add('fixed')
      //document.getElementById('add-task-overlay').classList.add('fixed')
      //xdocument.body.classList.add('no-scroll')
      document.body.style.overflowY = 'hidden'
      /*if (res.top <= 70) {
        document.getElementById('task-details-container').classList.add('fixed')
        document.getElementById('add-task-overlay').classList.add('fixed')
      }*/
    } else {
      document.body.style.overflowY = 'scroll'
    }
  }

  @HostListener('document:click', ['$event.target.id'])
  documentClickArea(id: string) {
    if (this.showTaskDetailsPanel == true && id === 'topnav') {
      document.body.style.overflowY = 'scroll'
    }
  }

  resetFilters() {
    this.tasksFilterService.resetFilters()
  }

  updateBrands(data) {
    let brands = []
    for (let i = 0; i < data.length; i++) {
      brands.push(data[i]._brand)
      const unique = Array.from(new Set(brands.map((a) => a.id))).map((id) => {
        return brands.find((a) => a.id === id)
      })
      brands = unique
    }
    return brands
  }
}
