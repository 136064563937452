import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'

import { APIService } from '../../../../_services/api.service'
import { DataService } from '../../../../_services/data.service'
import { IndustryHelper } from '../../../../_helpers/referential/industry.helper'

@Component({
  templateUrl: 'advertiser-brand-view.component.html',
  styleUrls: ['./advertiser-brand-view.component.scss'],
})
export class AdvertiserBrandViewComponent
  implements OnInit, OnDestroy, AfterViewInit {
  isLoading: boolean

  showSomething: boolean

  id: string
  private sub: any
  private modalSub: any

  project: any
  campaigns: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: APIService,
    private dataService: DataService
  ) {
    this.isLoading = true
    this.showSomething = false
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.id = params['workspaceId']

      let filtered = this.route.snapshot.data.user.body.projects.filter(
        (project) => project._id === this.id
      )

      if (filtered != undefined && filtered.length > 0)
        this.project = filtered[0]

      this.isLoading = false
    })
  }

  ngOnDestroy() {
    this.destroy()
  }

  init() {}

  destroy() {
    if (this.sub) this.sub.unsubscribe()

    if (this.modalSub) this.modalSub.unsubscribe()
  }

  ngAfterViewInit() {
    this.init()
  }

  getOverflow() {
    if (this.showSomething == true) return 'hidden'
    else return 'initial'
  }

  getHeight() {
    if (this.showSomething == true) return '100vh'
    else return 'initial'
  }

  editBrand() {
    this.router.navigate(['/workspaces/' + this.project._id + '/brand/edit'])
  }

  getIndustry(industry) {
    return IndustryHelper.getIndustryText(industry)
  }

  getIndustryList(brand) {
    if (brand == null) return 'N/A'

    if (brand.industries == null || brand.industries.length == 0) {
      if (brand.industry != null)
        return IndustryHelper.getIndustryText(brand.industry)
      else return 'N/A'
    } else {
      let result = ''
      for (let i = 0; i < brand.industries.length; i++) {
        if (i > 0) result += ', '
        let thisIndustry = IndustryHelper.getIndustryText(
          brand.industries[i].key
        )
        result += thisIndustry
      }
      return result
    }
  }

  async downloadBrandPresentation() {
    let res = await this.apiService.getBrandReport(this.project._id)
    const blob = new Blob([res], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  getDigitalAccountImagePath(digitalAccount) {
    let path = 'assets/images/icons/'
    switch (digitalAccount) {
      case 'facebook':
        return path + 'icon_facebook.png'
      case 'instagram':
        return path + 'icon_instagram.png'
      case 'linkedin':
        return path + 'icon_linkedin.png'
      case 'medium':
        return path + 'icon_medium.png'
      case 'podcast':
        return path + 'icon_podcast.png'
      case 'twitter':
        return path + 'icon_twitter.png'
      case 'web':
        return path + 'icon_web.png'
      case 'youtube':
        return path + 'icon_youtube.png'
      default:
        return ''
    }
  }

  getMarketingResources() {
    return this.project?.brand.marketing_resources
      .filter((resource) => {
        return true // resource.isPublic == true
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
  }

  getMarketingPublicResources() {
    return this.project?.brand.marketing_resources
      .filter((resource) => {
        return resource.isPublic == true
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
  }

  getMarketingPrivateResources() {
    return this.project?.brand.marketing_resources
      .filter((resource) => {
        return resource.isPublic == false
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
  }

  getMarketingResourceAvailabilityText(resource) {
    if (resource.isPublic) return 'Public'
    else return 'Private'
  }

  getMarketingLinks() {
    return this.project?.brand.marketing_links
      .filter((resource) => {
        return true // resource.isPublic == true
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
  }
}
